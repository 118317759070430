import { createSelector } from 'reselect'

import {
  fetchPubmedInfo,
  searchPublications,
  updatePublication,
  uploadSponsoredPaper,
} from 'mednet-cns/src/reducers/publication'
import { createDataHook } from 'mednet-cns/src/hook-factory'
import { useDispatch } from 'react-redux'

export const [usePubmedInfo, usePubmedInfoLazy] = createDataHook(
  fetchPubmedInfo,
  createSelector(
    (state) => state.publication.pubmedInfo,
    (_state, { requestId }) => requestId,
    (pubmedInfo, requestId) => {
      return pubmedInfo[requestId] || {}
    }
  )
)

export const useSponsoredPaperUpload = () => {
  const dispatch = useDispatch()

  return (formData, callback) => {
    dispatch(uploadSponsoredPaper(formData, callback))
  }
}

export const usePaperUpdate = () => {
  const dispatch = useDispatch()

  return (formData, callback) => {
    dispatch(updatePublication(formData, callback))
  }
}

export const [usePublicationSearch, usePublicationSearchLazy] = createDataHook(
  searchPublications,
  createSelector(
    (state) => state.publication.publications,
    (state) => state.publication.searchResult,
    (publications, searchResult) =>
      searchResult.map((publicationId) => publications[publicationId])
  )
)
