import { useMemo } from 'react'
import { createSelector } from 'reselect'
import { createDataHook } from 'mednet-cns/src/hook-factory'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

import { fetchAssociateEditors, fetchDeputyEditors } from '../reducers/editor'

import { useSpecialties } from './specialty'

export const [useDeputyEditors, useDeputyEditorsLazy] = createDataHook(
  fetchDeputyEditors,
  createSelector(
    (state) => state.editor.deputyEditors,
    (deputyEditors) => {
      return deputyEditors
    }
  )
)

export const [
  useSpecialtiesAssociateEditors,
  useSpecialtiesAssociateEditorsLazy,
] = createDataHook(
  fetchAssociateEditors,
  createSelector(
    (state) => state.editor.specialtiesAssociateEditors,
    (specialtiesAssociateEditors) => {
      return specialtiesAssociateEditors
    }
  )
)

export const useSpecialtiesPreviewEditors = () => {
  const [deputyEditorsIds, deputyEditorsRequest] = useDeputyEditors()
  const [specialtiesAssociateEditorsIds, associateEditorsRequest] =
    useSpecialtiesAssociateEditors()
  const [specialties, specialtiesRequest] = useSpecialties()

  const selectUsers = useMemo(
    () =>
      createSelector(
        (state) => state.user.users,
        (users) => users
      ),
    []
  )

  const usersDetails = useSelector((state) => selectUsers(state))

  return useMemo(() => {
    if (
      deputyEditorsRequest.isError ||
      associateEditorsRequest.isError ||
      specialtiesRequest.isError
    ) {
      return [{ specialties: {}, specialtiesEditorsIds: {} }, { isError: true }]
    }

    if (
      deputyEditorsRequest.isLoading ||
      associateEditorsRequest.isLoading ||
      specialtiesRequest.isLoading
    ) {
      return [
        { specialties: {}, specialtiesEditorsIds: {} },
        { isLoading: true },
      ]
    }

    if (
      !deputyEditorsRequest.isLoaded ||
      !associateEditorsRequest.isLoaded ||
      !specialtiesRequest.isLoaded
    ) {
      return [{ specialties: {}, specialtiesEditorsIds: {} }, {}]
    }

    const specialtiesEditorsIds = { ...specialtiesAssociateEditorsIds }

    deputyEditorsIds.forEach((editorId) => {
      const editor = usersDetails[editorId]

      if (
        !specialtiesEditorsIds[editor.specialtyId] ||
        !specialtiesEditorsIds[editor.specialtyId].includes(editorId)
      ) {
        specialtiesEditorsIds[editor.specialtyId] = [
          editorId,
          ...(specialtiesEditorsIds[editor.specialtyId] || []),
        ]
      }
    })

    let oncologyEditorsIds = []
    const notOncologyEditorsIds = {}

    Object.entries(specialtiesEditorsIds).forEach(
      ([specialtyId, editorsIds]) => {
        if (/oncology/i.test(specialties[specialtyId].specialty)) {
          oncologyEditorsIds = [...oncologyEditorsIds, ...editorsIds]
        } else {
          notOncologyEditorsIds[specialtyId] = editorsIds
        }
      }
    )

    oncologyEditorsIds.sort((editorId1, editorId2) => {
      if (
        deputyEditorsIds.includes(editorId1) &&
        !deputyEditorsIds.includes(editorId2)
      ) {
        return -1
      }

      if (
        deputyEditorsIds.includes(editorId2) &&
        !deputyEditorsIds.includes(editorId1)
      ) {
        return 1
      }

      const editor1 = usersDetails[editorId1]
      const editor2 = usersDetails[editorId2]

      const lastNameCompare = editor1.lastName.localeCompare(editor2.lastName)

      if (lastNameCompare === 0) {
        return editor1.firstName.localeCompare(editor2.firstName)
      }

      return lastNameCompare
    })

    const finalSpecialtiesEditorsIds = {
      ...notOncologyEditorsIds,
      oncology_merged: oncologyEditorsIds,
    }

    const modifiedSpecialties = [
      {
        specialtyId: 'oncology_merged',
        specialty: 'Oncology',
        active: true,
      },
      ...Object.values(specialties).filter(
        (specialty) =>
          specialty.active &&
          !isEmpty(finalSpecialtiesEditorsIds[specialty.specialtyId])
      ),
    ]

    return [
      {
        specialties: modifiedSpecialties,
        specialtiesEditorsIds: finalSpecialtiesEditorsIds,
      },
      { isLoaded: true },
    ]
  }, [
    deputyEditorsIds,
    deputyEditorsRequest,
    specialtiesAssociateEditorsIds,
    associateEditorsRequest,
    specialties,
    specialtiesRequest,
    usersDetails,
  ])
}
