import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { createCampaignImage } from 'mednet-cns/src/reducers/campaign'
import { ButtonUpload } from 'pharmacy/src/input/button'

import * as css from './imageUpload.scss'

const ImageUpload = ({ onChange }) => {
  const [uploadLink, setUploadLink] = useState(' ')

  const [description, setDescription] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [aspectRatio, setAspectRatio] = useState(null)

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleWidthChange = (e) => {
    if (aspectRatio) {
      setHeight(Math.floor(e.target.value / aspectRatio))
    }
    setWidth(e.target.value)
  }

  const handleHeightChange = (e) => {
    setHeight(e.target.value)
    if (aspectRatio) {
      setWidth(Math.floor(e.target.value * aspectRatio))
    }
    setHeight(e.target.value)
  }

  const handleFileUpload = (selectedFile) => {
    if (!selectedFile) {
      setUploadLink('')
      setDescription('')
      setWidth('')
      setHeight('')
      setAspectRatio(null)
      return
    }

    const formData = new FormData()

    formData.append('file', selectedFile)
    const fileName = selectedFile.name
    const extension = selectedFile.type
    formData.append('filename', fileName)
    formData.append('extension', extension)

    dispatch(
      createCampaignImage(formData, (response) => {
        setUploadLink(response?.url)
      })
    )
  }

  useEffect(() => {
    if (uploadLink) {
      const img = new Image()
      img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
        setAspectRatio(img.width / img.height)
      }

      img.src = uploadLink
    }
  }, [uploadLink])

  useEffect(() => {
    onChange && onChange(uploadLink, description, height, width)
  }, [uploadLink, description, height, width])

  const dispatch = useDispatch()

  return (
    <div>
      <h1>File Upload</h1>
      <div>
        <p className={css.instructionText}>
          Choose file and then press Upload to use file in email
        </p>
        <div className={css.imageUploadFormContainer}>
          <div className={css.imageUploadButtonRow}>
            <ButtonUpload
              inputProps={{
                name: 'Select File',
                id: 'file',
                accept: '.png, .jpg, .jpeg, .gif',
                onChange: handleFileUpload,
                maxFileSize: 300, // 300kb
              }}
              buttonProps={{ type: 'secondary' }}
            />
          </div>
          <div className={css.imageUploadRow}>
            <p>Link to uploaded file</p>
            <div className={css.uploadLinkContainer}>
              <p>{uploadLink}</p>
            </div>
          </div>
          <div className={css.imageUploadRow}>
            <p>Image Description</p>
            <textarea
              className={css.imageUploadDescriptionInput}
              type="text"
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className={css.imageUploadRow}>
            <p>Width:</p>
            <input
              className={css.imageUploadDimensionsInput}
              type="text"
              value={width}
              onChange={handleWidthChange}
            />
            <p>Height:</p>
            <input
              className={css.imageUploadDimensionsInput}
              type="text"
              value={height}
              onChange={handleHeightChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { ImageUpload }
