import React from 'react'
import { connect } from 'react-redux'

import BaseUserLink from 'pharmacy/src/navigation/userLink/baseUserLink'

import { getUserName } from 'mednet-util/src/string'

class BaseUserNameLink extends React.Component {
  render() {
    const { user } = this.props

    return <BaseUserLink {...this.props}>{getUserName(user)}</BaseUserLink>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: ownProps.user || state.user.users[ownProps.userId],
  }
}

export default connect(mapStateToProps)(BaseUserNameLink)
