import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { connect } from 'react-redux'

import {
  fetchSponsorships,
  fetchSponsorshipReach,
  fetchSponsorshipsStats,
  FETCH_SPONSORSHIPS,
  getFetchSponsorshipReachIsLoaded,
  getFetchSponsorshipReachIsLoading,
  getFetchSponsorshipsStatsIsLoaded,
  getFetchSponsorshipsStatsIsLoading,
  getSponsorshipReach,
  getSponsorshipsStats,
} from 'mednet-cns/src/reducers/sponsorship'
import { getRequest } from 'mednet-cns/src/api/v1'
import { openModal } from 'mednet-cns/src/reducers/modal'

import { SPONSORSHIP_CREATE_MODAL } from 'mednet-util/src/constants/modal'

import { CenteredContent } from 'pharmacy/src/display/content'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import {
  Header1,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from 'pharmacy/src/typography'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { Card } from 'pharmacy/src/display/card'
import { Page } from 'pharmacy/src/display/page'
import { Link } from 'pharmacy/src/navigation/link'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { PAGES } from 'constants/pages'
import { ROUTES } from 'constants/routes'
import { EXTERNAL_LINKS } from 'constants/links'

import CreateSponsorshipModal from './modals/create'
import StatsList from './statList'

import css from './dashboard.scss'

class SponsorshipStats extends React.PureComponent {
  render() {
    const { stats, reach, isStatsLoading, isReachLoading } = this.props

    const DAILYDIGEST = '3'
    const NEWSLETTER = 'N'

    const dailydigestsReach = _.max(reach.map((r) => r.dailydigests)) || 0
    const newslettersReach = _.max(reach.map((r) => r.newsletters)) || 0

    const dailyDigestsStats = stats.filter(
      (s) => String(s.type) === DAILYDIGEST
    )
    const newsletterStats = stats.filter((s) => String(s.type) === NEWSLETTER)

    return (
      <div className={css.statsContainer}>
        {isStatsLoading ? (
          <StarLoader size="small" isVerticalMargin={false} />
        ) : (
          <>
            <div className={css.statsPart}>
              <Subtitle2>Daily digests:</Subtitle2>
              <StatsList stats={dailyDigestsStats} />
            </div>
            <div className={css.statsPart}>
              <Subtitle2>Newsletters:</Subtitle2>
              <StatsList stats={newsletterStats} />
            </div>
          </>
        )}
        <div className={css.reach}>
          <Subtitle2>Max reach:</Subtitle2>
          {isReachLoading ? (
            <StarLoader size="small" isVerticalMargin={false} />
          ) : (
            <ul className={css.statsList}>
              <li>
                <Subtitle2>Daily digests: </Subtitle2>
                <Subtitle2 className={css.num}>{dailydigestsReach}</Subtitle2>
              </li>
              <li>
                <Subtitle2>Newsletters: </Subtitle2>
                <Subtitle2 className={css.num}>{newslettersReach}</Subtitle2>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  }
}

class SponsorshipCard extends React.Component {
  state = {
    showMore: false,
  }

  componentDidMount = () => {
    const { sponsorshipsReachIsLoaded, sponsorshipsReachIsLoading } = this.props

    if (!sponsorshipsReachIsLoaded && !sponsorshipsReachIsLoading) {
      fetchSponsorshipReach()
    }
  }

  handleClickMore = () => {
    const {
      fetchSponsorshipReach,
      sponsorshipsReachIsLoaded,
      sponsorshipsReachIsLoading,
    } = this.props

    this.setState((state) => ({ showMore: !state.showMore }))

    if (!sponsorshipsReachIsLoaded && !sponsorshipsReachIsLoading) {
      fetchSponsorshipReach()
    }
  }

  render() {
    const {
      sponsorship,
      className,
      reach,
      stats,
      isStatsLoading,
      sponsorshipsReachIsLoaded,
      sponsorshipsReachIsLoading,
    } = this.props
    const {
      description,
      sponsor,
      startDate,
      releaseDate,
      endDate,
      sponsorshipId,
    } = sponsorship
    const { sponsor: sponsorName } = sponsor
    const { showMore } = this.state

    const isReachLoading =
      !sponsorshipsReachIsLoaded || sponsorshipsReachIsLoading

    return (
      <Card className={classNames(css.card, className)}>
        <div>
          <Subtitle1>
            <Link
              route={ROUTES[PAGES.VIEW_SPONSORSHIP]}
              routeParams={{ sponsorshipId }}
              className={css.sponsorshipLink}
            >
              {description}
            </Link>
          </Subtitle1>
          <Subtitle2>{sponsorName}</Subtitle2>
        </div>
        <Subtitle3>
          {moment(startDate).format('L')} -{' '}
          <span className={css.num}>{moment(releaseDate).format('L')}</span> -{' '}
          {moment(endDate).format('L')}
        </Subtitle3>
        <div className={css.footer}>
          {!showMore ? null : (
            <SponsorshipStats
              stats={stats}
              reach={reach}
              isStatsLoading={isStatsLoading}
              isReachLoading={isReachLoading}
            />
          )}
          <div className={css.footerOpener}>
            <Link className={css.showMore} onClick={this.handleClickMore}>
              {!showMore ? 'show more' : 'hide'}
            </Link>
          </div>
        </div>
      </Card>
    )
  }
}

const cardMapStateToProps = (state, props) => {
  const { sponsorship } = props
  const { sponsorshipId } = sponsorship

  const reach = getSponsorshipReach(state, sponsorshipId)
  const sponsorshipsReachIsLoaded = getFetchSponsorshipReachIsLoaded(
    state,
    sponsorshipId
  )
  const sponsorshipsReachIsLoading = getFetchSponsorshipReachIsLoading(
    state,
    sponsorshipId
  )

  const stats = getSponsorshipsStats(state, sponsorshipId)

  return {
    stats,
    reach,
    sponsorshipsReachIsLoaded,
    sponsorshipsReachIsLoading,
  }
}

const cardMapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorship } = ownProps
  const { sponsorshipId } = sponsorship

  return {
    fetchSponsorshipReach: () => dispatch(fetchSponsorshipReach(sponsorshipId)),
  }
}

const SponsorshipCardConnected = connect(
  cardMapStateToProps,
  cardMapDispatchToProps
)(SponsorshipCard)

class SponsorshipDashboardPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.sponsorshipsIsLoaded) {
      props.fetchSponsorships()
    }
  }

  state = {
    hideInactive: false,
  }

  componentDidMount() {
    const {
      sponsorshipsStatsIsLoaded,
      sponsorshipsStatsIsLoading,
      fetchSponsorshipsStats,
    } = this.props

    if (!sponsorshipsStatsIsLoaded && !sponsorshipsStatsIsLoading) {
      fetchSponsorshipsStats()
    }
  }

  handleToggleInactive = () => {
    this.setState(({ hideInactive }) => ({ hideInactive: !hideInactive }))
  }

  render() {
    const {
      sponsorships,
      sponsorshipsIsLoaded,
      orderedSponsorships,
      openCreateModal,
      sponsorshipsStatsIsLoading,
      sponsorshipsStats,
    } = this.props

    const { hideInactive } = this.state

    const sponsorshipToShow = !hideInactive
      ? orderedSponsorships
      : orderedSponsorships.filter((id) => {
          const sponsorship = sponsorships[id]

          return (
            new Date(sponsorship.releaseDate) <= new Date() &&
            new Date() < new Date(sponsorship.endDate)
          )
        })

    if (!sponsorshipsIsLoaded) {
      return <StarLoader />
    }

    return (
      <Page>
        <CenteredContent>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
            ]}
          />
          <div className={css.header}>
            <Header1>Manage sponsored topics</Header1>
            <div className={css.actions}>
              <Button
                className={css.action}
                icon="syringe"
                type="neutral"
                pathname="/sponsorship/inject"
              >
                Inject
              </Button>
              <Button
                className={css.action}
                icon="table"
                type="neutral"
                pathname="/sponsorship/stats"
              >
                Stats
              </Button>
              <Button
                className={css.action}
                icon="plus"
                type="neutral"
                onClick={openCreateModal}
              >
                Add
              </Button>
            </div>
          </div>
          <div className={css.filters}>
            <ButtonCheckbox
              buttonProps={{
                size: 'small',
                onClick: this.handleToggleInactive,
              }}
              selectText="Hide inactive"
              unselectText="Hide inactive"
            />
          </div>
          {_.map(sponsorshipToShow, (sponsorshipId) => {
            const sponsorship = sponsorships[sponsorshipId]

            return (
              <SponsorshipCardConnected
                key={sponsorshipId}
                sponsorship={sponsorship}
                className={css.sponsorshipCard}
                stats={sponsorshipsStats[sponsorshipId]}
                isStatsLoading={sponsorshipsStatsIsLoading}
              />
            )
          })}
        </CenteredContent>
        <CreateSponsorshipModal />
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  const sponsorshipsRequest = getRequest(state, FETCH_SPONSORSHIPS)
  const sponsorshipsStatsIsLoaded = getFetchSponsorshipsStatsIsLoaded(state)
  const sponsorshipsStatsIsLoading = getFetchSponsorshipsStatsIsLoading(state)
  const sponsorshipsStats = getSponsorshipsStats(state)

  return {
    sponsorships: state.sponsorship.sponsorships,
    orderedSponsorships: state.sponsorship.orderedSponsorships,
    sponsorshipsIsLoaded: sponsorshipsRequest.isLoaded === true,
    sponsorshipsStatsIsLoaded,
    sponsorshipsStatsIsLoading,
    sponsorshipsStats,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSponsorships: () => dispatch(fetchSponsorships()),
    openCreateModal: () =>
      dispatch(openModal(SPONSORSHIP_CREATE_MODAL.modalId)),
    fetchSponsorshipsStats: () => dispatch(fetchSponsorshipsStats()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorshipDashboardPage)
