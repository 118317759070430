import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  useTable,
  useRowSelect,
  useBlockLayout,
  useFilters,
  useSortBy,
  useExpanded,
} from 'react-table'
import { FixedSizeList } from 'react-window'
import classNames from 'classnames'
import noop from 'lodash/noop'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Icon } from 'pharmacy/src/display/icon'

import useScrollbarWidth from 'mednet-cns/src/hooks/useScrollbarWidth'

import useTableSelect from './hooks/useTableSelect'

import * as css from './table.scss'

const TableBodySpinner = ({ bodyHeight }) => (
  <div className={css.tableSpinner} style={{ height: `${bodyHeight}px` }}>
    <StarLoader isVerticalMargin={false} />
  </div>
)

/**
 * When 'selected' prop is passed to the table, it's used as the source of truth for the table selection
 * Otherwise react-table state takes care of storing selected rows
 */
const Table = React.memo(
  ({
    columns,
    data,
    isLoading,
    loadingDataFailed,
    errorMessage,
    bodyHeight,
    onSelect,
    onDeselectAll,
    onSelectAll,
    selected,
    shadeOddRows,
    withBorders,
    itemSize = 42,
    sortBy = [],
    removeFooter,
    disabledRowsIds = {},
    emptyDataMessage,
    dynamicWidth,
    DisabledCheckboxReplacement,
    selectOnRowClick,
    defaultAllSelected,
    glowRowsIds = {},
    className,
    expandable,
  }) => {
    const [
      preselectedRowIds,
      useSelectionCheckboxes,
      useTableSelectionControlledState,
      handleRowSelect,
    ] = useTableSelect(
      selected,
      disabledRowsIds,
      data,
      isLoading,
      onSelect,
      onSelectAll,
      onDeselectAll,
      DisabledCheckboxReplacement,
      defaultAllSelected
    )

    const scrollbarWidth = useScrollbarWidth()
    const scrollableElementRef = useRef()
    const [isScrollbarVisible, setScrollbarVisibility] = useState(false)

    useEffect(() => {
      if (scrollableElementRef.current) {
        setScrollbarVisibility(
          scrollableElementRef.current?.scrollHeight >
            scrollableElementRef.current?.clientHeight
        )
      }
    }, [
      scrollableElementRef.current,
      scrollableElementRef.current?.scrollHeight,
      scrollableElementRef.current?.clientHeight,
    ])

    const defaultColumn = React.useMemo(
      () => ({
        width: dynamicWidth ? 'unset' : 30,
      }),
      []
    )

    const shouldUseFilters = columns.some(
      (column) => column.filter || column.Filter
    )

    const params = [
      {
        columns,
        data,
        defaultColumn,
        autoResetSortBy: false,
        initialState: {
          selectedRowIds: preselectedRowIds,
          sortBy,
        },
        useControlledState: (state) => {
          return useTableSelectionControlledState(state)
        },
      },
      shouldUseFilters && useFilters,
      useSortBy,
      expandable && useExpanded,
      useRowSelect,
      useBlockLayout,
      (hooks) => {
        useSelectionCheckboxes(hooks)
      },
    ]

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      totalColumnsWidth,
      state,
    } = useTable(...params.filter((s) => s))
    Table.displayName = 'Table'

    const { selectedRowIds: selectedRowIds = {} } = state
    const selectedRowsCount = Object.keys(selectedRowIds).length

    const RenderRow = useCallback(
      ({ index, style }) => {
        const row = rows[index]
        prepareRow(row)
        return (
          <div
            className={classNames(
              disabledRowsIds[row.id]
                ? css.trDisabled
                : shadeOddRows && index % 2
                ? css.trShaded
                : css.trNotShaded,
              {
                [css.glow]: glowRowsIds[row.id],
                [css.expanded]: row.depth && row.depth > 0,
              }
            )}
            {...row.getRowProps({
              style,
            })}
            key={index}
            onClick={
              selectOnRowClick && !disabledRowsIds[row.original.id]
                ? handleRowSelect(
                    row.getToggleRowSelectedProps().onChange,
                    onSelect,
                    data,
                    row.original,
                    selectedRowIds
                  )
                : noop
            }
          >
            {row.cells.map((cell, i) => (
              <div
                className={classNames(css.td, cell.column.className)}
                {...cell.getCellProps()}
                key={`${index}-${i}`}
              >
                <div className={css.cell}>{cell.render('Cell')}</div>
              </div>
            ))}
          </div>
        )
      },
      [prepareRow, rows, selectedRowIds, disabledRowsIds]
    )

    const renderContents = () => {
      if (loadingDataFailed) {
        return (
          <div
            className={css.errorWrapper}
            style={{ height: `${bodyHeight}px` }}
          >
            <span className={css.error}>
              <Icon icon={['fas', 'exclamation-triangle']} />
              {errorMessage ? errorMessage : 'Error occured'}
            </span>
          </div>
        )
      }

      if (isLoading) {
        return <TableBodySpinner bodyHeight={bodyHeight} />
      }

      if (emptyDataMessage && !data.length) {
        return (
          <div style={{ height: `${bodyHeight}px` }}>
            <div className={css.emptyData}>{emptyDataMessage}</div>
          </div>
        )
      }
      return (
        <FixedSizeList
          height={bodyHeight}
          itemCount={rows.length}
          itemSize={itemSize}
          width={dynamicWidth ? 'unset' : totalColumnsWidth + scrollbarWidth}
          outerRef={scrollableElementRef}
        >
          {RenderRow}
        </FixedSizeList>
      )
    }

    return (
      <>
        <div
          className={classNames(className, css.table, {
            [css.withBorders]: withBorders,
            [css.removeFooter]: removeFooter,
          })}
          {...getTableProps()}
          style={dynamicWidth && { minWidth: '100%' }}
        >
          <div className={classNames(css.tableHeader, 'table-header')}>
            <div
              style={{
                maxWidth: isScrollbarVisible
                  ? `calc(100% - ${scrollbarWidth}px)`
                  : '100%',
              }}
            >
              {headerGroups.map((headerGroup, i) => (
                <div
                  className={css.tr}
                  {...headerGroup.getHeaderGroupProps()}
                  key={i}
                >
                  {headerGroup.headers.map((column, j) => (
                    <div
                      className={classNames(css.th, column.headerClassName, {
                        [css.clickableHeader]: column.sortable,
                      })}
                      {...column.getHeaderProps()}
                      key={`${i}-${j}`}
                      onClick={() =>
                        column.sortable &&
                        column.toggleSortBy(!column.isSortedDesc)
                      }
                    >
                      {column.render('Header')}
                      {column.sortable &&
                        (column.isSortedDesc ? (
                          <Icon icon="caret-down" className={css.sortingIcon} />
                        ) : (
                          <Icon icon="caret-up" className={css.sortingIcon} />
                        ))}
                      <div>
                        {column.canFilter && column.Filter
                          ? column.render('Filter')
                          : null}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div
            className={classNames(css.tableBody, 'table-body')}
            {...getTableBodyProps()}
          >
            {renderContents()}
          </div>

          {!removeFooter && (
            <div className={classNames(css.tableFooter, 'table-footer')}>
              {isLoading || loadingDataFailed ? null : (
                <>
                  {onSelect ? (
                    <>
                      Selected {selectedRowsCount} from {rows.length} records
                    </>
                  ) : (
                    <>{rows.length} records</>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </>
    )
  }
)

export default Table
