import React from 'react'

import { Subtitle3 } from 'pharmacy/src/typography'

import css from './questionDisclaimer.scss'

class QuestionDisclaimer extends React.Component {
  render() {
    return (
      <Subtitle3 className={[css.questionDisclaimerText, css.print].join(' ')}>
        The content of theMednet is for general knowledge sharing and dialogue.
        Clinicians should base their decision-making on independent medical
        judgment in the context of individual clinical circumstances of a
        specific patient’s care or treatment. In no event shall theMednet or its
        members be liable for damages of any kind arising out of or in
        connection with the use of theMednet content.
      </Subtitle3>
    )
  }
}

export default QuestionDisclaimer
