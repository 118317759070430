import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Body1, Link2 } from 'pharmacy/src/typography'
import { authItems } from 'mednet-util/src/constants/permission'
import { mouseEnter, mouseLeave } from 'mednet-cns/src/reducers/mention'
import { SOURCE_TYPE } from 'mednet-util/src/constants/mention'

import css from './rawBody.scss'

class RawBody extends React.Component {
  static defaultProps = {
    TextComponent: Body1,
    transformLink: (link) => {
      link.target = '_blank'
    },
    ExpandComponent: Link2,
    preview: false,
  }

  constructor(props) {
    super(props)

    this.text = React.createRef()
    this.state = {
      isExpanded: false,
    }
  }

  componentDidMount() {
    const { transformLink, mouseEnter, mouseLeave, canViewProfile } = this.props

    _.each(this.text.current.getElementsByTagName('a'), (link) => {
      transformLink(link)

      if (link.className === 'mention' && canViewProfile) {
        const userId = link.href.match(/user\/user\/view\/id\/(\d+)/)

        if (!userId) {
          return
        }

        link.onmouseenter = () => {
          const rect = link.getBoundingClientRect()
          mouseEnter(userId[1], rect, SOURCE_TYPE.MENTION)
        }

        link.onmouseleave = () => {
          mouseLeave()
        }
      }
    })

    this.setTextStyle()
  }

  setTextStyle = () => {
    const { preview } = this.props
    const { isExpanded } = this.state

    if (!preview) {
      return
    }

    const lineHeight = window.getComputedStyle(this.text.current).lineHeight
    const maxHeight = parseInt(lineHeight.slice(0, -2)) * preview - 2
    this.text.current.style.maxHeight = isExpanded ? null : `${maxHeight}px`
  }

  toggleExpand = () => {
    const { isExpanded } = this.state
    this.setState(
      {
        isExpanded: !isExpanded,
      },
      this.setTextStyle
    )
  }

  render() {
    const { body, className, TextComponent, preview, ExpandComponent } =
      this.props
    const { isExpanded } = this.state

    const containerClasses = classNames(className, css.container)
    const textClasses = classNames({
      [css.textPreview]: preview,
    })

    return (
      <TextComponent className={containerClasses}>
        <div
          dangerouslySetInnerHTML={{ __html: body }}
          ref={this.text}
          className={textClasses}
        />
        {preview && (
          <ExpandComponent className={css.expand} onClick={this.toggleExpand}>
            {isExpanded ? 'Less' : 'More'}
          </ExpandComponent>
        )}
      </TextComponent>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    canViewProfile: _.includes(
      _.get(state.user, 'permissions.items', []),
      authItems.viewProfile
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mouseEnter: (userId, rect, sourceType) =>
      dispatch(mouseEnter(userId, rect, sourceType)),
    mouseLeave: () => dispatch(mouseLeave()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RawBody)
