import Feed from './feed'
import InstitutionFeed from './institutionFeed'
import QuestionFeed from './questionFeed'
import UserActivityFeed from './userActivityFeed'
import UserFeed from './userFeed'
import UserPublicationFeed from './userPublicationFeed'

export {
  Feed,
  InstitutionFeed,
  QuestionFeed,
  UserActivityFeed,
  UserFeed,
  UserPublicationFeed,
}
