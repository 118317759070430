import React from 'react'
import { makeAssetURL } from 'mednet-util/src/router'

import { mednetAPI } from 'mednet-cns/src/api/v1'

import css from './questionImages.scss'

export const QuestionImages = ({ images, onClick }) => {
  return (
    <>
      {images?.map(({ imagePath, clickTrackPath }, index) => (
        <div key={index}>
          <img
            className={css.questionImage}
            src={makeAssetURL(imagePath)}
            onClick={() => {
              clickTrackPath && mednetAPI(clickTrackPath)
              onClick && onClick(imagePath)
            }}
          />
        </div>
      ))}
    </>
  )
}
