import React from 'react'
import { connect } from 'react-redux'

import { Subtitle1, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { actionVerbNames } from 'mednet-util/src/constants/questionUpdate'
import { UpdatePreview } from 'pharmacy/src/question/updatePreview'

import css from './userActivityItem.scss'

class UserActivityItem extends React.Component {
  render() {
    const { item, question, itemIndex } = this.props

    const { actionId, questionId } = item

    return (
      <div>
        <Subtitle1>
          <QuestionLink
            className={css.questionLink}
            questionId={questionId}
            search={{ src: 'profile', pos: itemIndex + 1 }}
          >
            {question}
          </QuestionLink>
        </Subtitle1>
        <Subtitle3 className={css.action}>
          {actionVerbNames[actionId]}
        </Subtitle3>
        <UpdatePreview showMore={false} TextComponent={Subtitle2} item={item} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { question } = state.question.questions[ownProps.item.questionId]

  return {
    question,
  }
}

export default connect(mapStateToProps)(UserActivityItem)
