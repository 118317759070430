import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { BaseTableRow } from './baseTableRow'
import { BaseTableHeader } from './baseTableHeader'

import css from './baseTable.scss'

export class BaseTable extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    getRowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    readOnly: PropTypes.bool,
    responsive: PropTypes.bool,
  }

  static defaultProps = {
    emptyState: 'There is no data to display',
    data: [],
    responsive: true,
  }

  getRowKey(datum) {
    const { getRowKey } = this.props

    if (getRowKey) {
      if (_.isFunction(getRowKey)) {
        return getRowKey(datum)
      }

      return datum[getRowKey]
    }

    if (process.env.DEBUG) {
      // eslint-disable-next-line no-console
      console.error(
        'You should provide getRowKey to avoid expensive computation'
      )
    }

    return JSON.stringify(datum)
  }

  renderBody = () => {
    const {
      cellProps,
      columns,
      data,
      emptyState,
      extraRow,
      highlightRow,
      isLoading,
      readOnly,
      responsive,
    } = this.props

    if (isLoading === true) {
      return (
        <tr>
          <td colSpan={columns.length}>
            <StarLoader />
          </td>
        </tr>
      )
    }

    if (data.length === 0) {
      return (
        <tr className={css.row}>
          <td className={css.cell} colSpan={columns.length}>
            <div className={css.empty}>{emptyState}</div>
          </td>
        </tr>
      )
    }

    return data
      .map((datum, index) => {
        const highlight = highlightRow && highlightRow(datum) === true

        return (
          <BaseTableRow
            columns={columns}
            datum={datum}
            highlight={highlight}
            key={this.getRowKey(datum)}
            first={index === 0}
            responsive={responsive}
            cellProps={cellProps}
            readOnly={readOnly}
          />
        )
      })
      .concat(
        this.props.extraRow
          ? [
              <tr key="row" className={css.row}>
                <td className={css.cell}>
                  <div className={css.separator}>. . .</div>
                </td>
              </tr>,
              <BaseTableRow
                columns={columns}
                key={this.getRowKey(extraRow)}
                datum={extraRow}
                highlight
                responsive={responsive}
                readOnly={readOnly}
                cellProps={cellProps}
              />,
            ]
          : []
      )
  }

  render() {
    const { columns, sortOrder, sortKey, onSort, readOnly, responsive } =
      this.props

    const tableClasses = classNames(css.table, this.props.className, {
      [css.table_responsive]: responsive,
    })

    const theadClasses = classNames(css.thead, {
      [css.thead_responsive]: responsive,
    })

    const tbodyClasses = classNames(css.tbody, {
      [css.tbody_responsive]: responsive,
    })

    return (
      <table className={tableClasses}>
        <thead className={theadClasses}>
          <BaseTableHeader
            columns={columns}
            sortOrder={sortOrder}
            sortKey={sortKey}
            readOnly={readOnly}
            onSort={onSort}
          />
        </thead>
        <tbody className={tbodyClasses}>{this.renderBody()}</tbody>
      </table>
    )
  }
}
