import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { Subtitle3, Header4 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { Publication } from 'pharmacy/src/display/publication'
import { mixpanel } from 'mednet-util/src/tracking'
import {
  cclickPlace,
  publicationAccess,
} from 'mednet-util/src/constants/publication'
import { mednetAPI } from 'mednet-cns/src/api/v1'

import Stub from 'pharmacy/src/navigation/stub/stub'

import css from './citedPapers.scss'

class CitedPapers extends React.Component {
  static defaultProps = {
    type: 'text',
  }

  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false,
    }
  }

  toggleExpand = () => {
    const { isExpanded } = this.state

    this.setState({
      isExpanded: !isExpanded,
    })
  }

  handleClick = (citedPaper) => () => {
    const { onClick } = this.props
    const { citeId, place } = citedPaper

    if (place === cclickPlace.question) {
      mednetAPI(`questionCitedPaper/clickTrackJSON/${citeId}`)
    } else if (place === cclickPlace.answer) {
      mednetAPI(`answerCitedpaper/clickTrackJSON/${citeId}`)
    } else {
      mednetAPI(`questionSponsorshipCitedPaper/clickTrackJSON/${citeId}`)
    }
    mixpanel.track('Clicked Citation', { id: citeId })

    if (onClick) {
      onClick(citedPaper)
    }
  }

  render() {
    const { isExpanded } = this.state
    const {
      citedPapers,
      className,
      type,
      LinkComponent,
      VideoComponent,
      questionId,
      userData,
      stubbed,
    } = this.props

    if (!citedPapers || !citedPapers.length) {
      return null
    }

    /**
     * Sort in place (array) for citations by this order:
     * - sponsored publications, with file hosted internally in S3, and with video -> sum =  100 + 40 + 50 = 190
     * - sponsored publications, with file hosted externally via public url, and with video -> sum = 100 + 30 + 50 = 180
     * - sponsored publications, without file - i.e. public url which is not file or is a pubmed link, and with video -> sum = 100 + 50 = 150
     * - sponsored publications, with file hosted internally in S3 -> sum = 100 + 40 = 140
     * - sponsored publications, with file hosted externally via public url -> sum = 100 + 30 = 130
     * - sponsored publications, without file - i.e. public url which is not file or pubmed link -> sum = 100
     * - publications with open access, with file hosted internally, and with video -> 40 + 50  -> sum = 90
     * - publications with open access, with file hosted externally via public url, and with video -> 30 + 50  -> sum = 80
     * - publications with video -> sum = 50
     * - publications with open access, with file hosted internally, -> sum = 40
     * - publications with open access, with file hosted externally via public url ->sum = 30
     * - The rest of publications -> sum = 0
     */

    const getPublicationWeight = (access, hasVideo) => {
      const weightDict = {
        sponsored: 100,
        hasVideo: 50,
        hasFileHostedInternally: 40,
        hasFileHostedExternally: 30,
      }

      const accessDict = {
        [publicationAccess.SPONSORED_S3_FILE]: {
          sponsored: true,
          hasFileHostedInternally: true,
          hasFileHostedExternally: false,
        },
        [publicationAccess.SPONSORED_PUBLIC_URL_FILE]: {
          sponsored: true,
          hasFileHostedInternally: false,
          hasFileHostedExternally: true,
        },
        [publicationAccess.SPONSORED_NO_FILE]: {
          sponsored: true,
          hasFileHostedInternally: false,
          hasFileHostedExternally: false,
        },
        [publicationAccess.OPEN_S3_FILE]: {
          sponsored: false,
          hasFileHostedInternally: true,
          hasFileHostedExternally: false,
        },
        [publicationAccess.PUBLIC_URL_FILE]: {
          sponsored: false,
          hasFileHostedInternally: false,
          hasFileHostedExternally: true,
        },
        [publicationAccess.CLOSED]: {
          sponsored: false,
          hasFileHostedInternally: false,
          hasFileHostedExternally: false,
        },
      }

      const videoWeight = hasVideo ? weightDict.hasVideo : 0
      const accessWeight = Object.keys(accessDict[access] || {}).reduce(
        (sum, key) => sum + (accessDict[access][key] ? weightDict[key] : 0),
        0
      )
      return videoWeight + accessWeight
    }

    citedPapers.sort(
      ({ publication: publicationA }, { publication: publicationB }) => {
        const sumA = getPublicationWeight(
          publicationA.access,
          !isEmpty(publicationA.videoURL)
        )

        const sumB = getPublicationWeight(
          publicationB.access,
          !isEmpty(publicationB.videoURL)
        )

        return sumB - sumA
      }
    )
    const citedPapersToShow = isExpanded ? citedPapers : citedPapers.slice(0, 3)
    const headerClasses = classNames(css.header, css[`header_${type}`])

    return (
      <div className={className}>
        <Header4 className={headerClasses}>Cited Papers</Header4>
        {citedPapersToShow.map((citedPaper) => (
          <div className={css.paperContainer} key={citedPaper.citeId}>
            <Publication
              userId={userData.userId}
              item={citedPaper.publication}
              questionId={questionId}
              citeId={citedPaper.citeId}
              place={citedPaper.place}
              LinkComponent={stubbed ? Stub : LinkComponent}
              VideoComponent={VideoComponent}
              onClick={this.handleClick(citedPaper)}
            />
          </div>
        ))}
        {citedPapers.length > 3 && (
          <Subtitle3>
            <Button
              className={css.toggleExpand}
              icon={isExpanded ? 'minus' : 'plus'}
              onClick={this.toggleExpand}
              type="text"
            >
              Show {isExpanded ? 'less' : 'more'}
            </Button>
          </Subtitle3>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { citedPapers } = state.question.questions[ownProps.questionId] || {}

  return {
    userData: state.user.data,
    citedPapers,
  }
}

export default connect(mapStateToProps)(CitedPapers)
