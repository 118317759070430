import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Formik } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router'

import {
  useEditCampaignJob,
  useCreateCampaignJob,
  useSystemJobDetailsLazy,
  useCreateInvitationJob,
} from 'mednet-cns/src/hooks/systemJob'
import { useSponsorshipLazy } from 'mednet-cns/src/hooks/sponsorship'
import {
  JOB_TYPE,
  REMINDER_INTERVAL_TYPE,
} from 'mednet-cns/src/reducers/systemJob'
import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import CenteredContent from 'pharmacy/src/display/content/centeredContent'
import { Page } from 'pharmacy/src/display/page'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { ConfirmationModal } from 'pharmacy/src/display/modal'

import { CAMPAIGN_SAVED_MODAL } from 'mednet-util/src/constants/modal'

import { CampaignForm, campaignSchema } from './campaignForm'

import * as css from './campaign.scss'

const CampaignPage = () => {
  const { action, id } = useParams()
  const { firstName, lastName, emailAddress } = useSelector(
    (state) => state.user.data
  )
  const isView = action === 'view'

  const [isInitialized, setInitialized] = useState(action === 'new')
  const [openCampaignSavedModal, closeCampaignSavedModal] = useModalDrivers(
    CAMPAIGN_SAVED_MODAL.modalId
  )

  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const sponsorshipId =
    new URLSearchParams(location.search).get('sponsorshipId') || ''

  const [
    systemJobDetails = {},
    { isLoading: areDetailsLoading, isLoaded: areDetailsLoaded },
    loadDetails,
  ] = useSystemJobDetailsLazy({ id, requestId: id })
  const [
    sponsorship,
    { isLoading: isSponsorshipLoading, isLoaded: isSponsorshipLoaded },
    loadSponsorship,
  ] = useSponsorshipLazy(sponsorshipId)

  const isLoading = !isInitialized || areDetailsLoading || isSponsorshipLoading

  const campaignType =
    systemJobDetails.type ||
    (pathname.toLocaleLowerCase().indexOf('invitation') > -1
      ? JOB_TYPE.INVITE
      : JOB_TYPE.CAMPAIGN)

  const now = moment.tz('America/New_York').add(1, 'hours').add(1, 'days')
  const [initialValues, setInitialValues] = useState({
    description: '',
    schedule: now.format('YYYY-MM-DD HH:mm'),
    sender: '',
    subject: '',
    recipients: [],
    template: false,
    body: '',
    sendPreviewTo: false,
    sendPreview: false,
    sponsorshipId,
    status: 'draft',
    batchSize: null,
    sendReminders: campaignType === JOB_TYPE.INVITE,
    reminders: campaignType === JOB_TYPE.INVITE ? 1 : null,
    intervalType:
      campaignType === JOB_TYPE.INVITE ? REMINDER_INTERVAL_TYPE.FIXED : null,
    interval: campaignType === JOB_TYPE.INVITE ? 7 : null,
    intervals: campaignType === JOB_TYPE.INVITE ? [7] : null,
    remindersBodies: [],
    replyTo: null,
    ipPool: null,
  })

  useEffect(() => {
    if (!id && emailAddress) {
      setInitialValues((initialValues) => ({
        ...initialValues,
        sender: `${firstName} ${lastName} <${emailAddress}>`,
      }))
    }
    setInitialValues((initialValues) => ({
      ...initialValues,
      sendPreviewTo: `${emailAddress}; `,
    }))
  }, [firstName, lastName, emailAddress])

  useEffect(() => {
    if (action !== 'new' && id && !areDetailsLoading) {
      loadDetails()
    }
    if (action === 'new' && sponsorshipId) {
      loadSponsorship()
    }
  }, [])

  useEffect(() => {
    if (areDetailsLoaded) {
      const {
        subject: oldSubject,
        description: oldDescription,
        sender,
        recipients,
        template,
        body,
        text,
        sponsorshipId: sponsorshipIdFromCampaign,
        remindersBodies,
        replyTo,
        ipPool,
      } = systemJobDetails.campaign

      const subject = action === 'reply' ? `Re: ${oldSubject}` : oldSubject
      let description = oldDescription
      let schedule = systemJobDetails.schedule
      let status = systemJobDetails.status
      const batchSize = systemJobDetails.attributes?.batchSize
      const reminders = systemJobDetails.attributes?.reminders
      const intervalType =
        systemJobDetails.attributes?.intervalType ??
        REMINDER_INTERVAL_TYPE.FIXED
      const interval = systemJobDetails.attributes?.interval
      const intervals = systemJobDetails.attributes?.intervals

      if (action === 'copy') {
        description = `Copy - ${oldDescription}`
        schedule = now.format('YYYY-MM-DD HH:mm')
        status = 'draft'
      } else if (action === 'reply') {
        description = `Re: ${oldDescription}`
        schedule = moment(schedule).add(7, 'days').format('YYYY-MM-DD HH:mm')
      }

      setInitialized(true)
      setInitialValues((initialValues) => ({
        ...initialValues,
        sponsorshipId: sponsorshipIdFromCampaign,
        subject,
        description,
        sender,
        recipients,
        body,
        text,
        template: Boolean(template),
        schedule,
        status,
        batchSize,
        reminders,
        intervalType,
        interval,
        intervals,
        sendReminders: Boolean(parseInt(reminders)),
        remindersBodies,
        replyTo,
        useInvitationIpPool: ipPool === 'invitation',
      }))
    }
  }, [action, id, systemJobDetails, areDetailsLoading, areDetailsLoaded])

  useEffect(() => {
    if (action === 'new' && sponsorship) {
      setInitialValues((initialValues) => {
        return {
          ...initialValues,
          description: `${sponsorship.description} Campaign`,
        }
      })
    }
  }, [sponsorship, isSponsorshipLoaded, isSponsorshipLoading])

  const [createCampaignJob] = useCreateCampaignJob()
  const [createInvitationJob] = useCreateInvitationJob()
  const [editCampaignJob] = useEditCampaignJob()
  const handleSubmit = async (values, { setSubmitting }) => {
    const { recipients, ...rest } = values

    if (action === 'edit') {
      await editCampaignJob({
        id,
        ...rest,
        recipients: recipients.map((recipient) => ({
          id:
            recipient.id && recipient.id.toString().indexOf('new') === -1
              ? recipient.id
              : '',
          name: recipient.name,
          userIds: JSON.stringify(Object.keys(recipient.selected)),
          tree: recipient.tree,
          isFileImport: recipient.isFileImport,
          filterUsersWithoutRecentActivity:
            recipient.filterUsersWithoutRecentActivity,
          file: recipient.file ?? '',
        })),
      })
    } else {
      const createJob =
        campaignType === JOB_TYPE.INVITE
          ? createInvitationJob
          : createCampaignJob
      await createJob({
        ...rest,
        sponsorshipId,
        recipients: recipients.map((recipient) => ({
          name: recipient.name,
          userIds: JSON.stringify(Object.keys(recipient.selected)),
          tree: recipient.tree,
          isFileImport: recipient.isFileImport,
          filterUsersWithoutRecentActivity:
            recipient.filterUsersWithoutRecentActivity,
          file: recipient.file ?? '',
        })),
      })
    }
    setSubmitting(false)

    if (values.saveAs === 'preview') {
      openCampaignSavedModal()
    } else {
      history.push(`/system/jobs`)
    }
  }

  if (isLoading) {
    return <StarLoader />
  }

  return (
    <Page className={css.page}>
      <CenteredContent size="xlarge" className={css.contents}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={campaignSchema}
          onSubmit={handleSubmit}
        >
          {({ values, ...props }) => (
            <CampaignForm
              {...props}
              values={values}
              sponsorshipId={values.sponsorshipId}
              campaignType={campaignType}
              isDisabled={isView}
              action={action}
            />
          )}
        </Formik>
      </CenteredContent>
      <ConfirmationModal
        header="Campaign saved"
        modalId={CAMPAIGN_SAVED_MODAL.modalId}
        onSubmit={() => {
          closeCampaignSavedModal()
        }}
        submitText="Ok!"
        submitType="primary"
        size="larger"
      />
    </Page>
  )
}

export default CampaignPage
