import React from 'react'

import { jobType, jobDescriptions } from 'mednet-util/src/constants/job'
import { Header3, Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import UserJob from './userJob'

import css from './userTraining.scss'

const jobTypeSections = [
  jobType.FELLOWSHIP,
  jobType.RESIDENCY,
  jobType.INTERNSHIP,
  jobType.UNKNOWN,
  jobType.SCHOOL,
]

class UserTraining extends React.Component {
  renderSection = (section) => {
    const { jobs, showEdit } = this.props

    if (!jobs[section]) {
      return null
    }

    return (
      <div key={section} className={css.sectionContainer}>
        <Header3 className={css.sectionHeader}>
          {jobDescriptions[section]}
        </Header3>
        {jobs[section].map((job, index) => (
          <div key={index} className={css.jobContainer}>
            <UserJob job={job} showEdit={showEdit} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { showEdit } = this.props

    return (
      <div>
        {jobTypeSections.map(this.renderSection)}
        {showEdit && (
          <Subtitle3>
            <Button
              type="secondaryText"
              pathname="/job/createEducation"
              icon="plus"
              external
            >
              Add a program
            </Button>
          </Subtitle3>
        )}
      </div>
    )
  }
}

export default UserTraining
