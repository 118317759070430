import React from 'react'
import { Field } from 'formik'

import { Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import css from './requestForm.scss'

class RequestForm extends React.Component {
  render() {
    const {
      handleSubmit,
      handleReset,
      innerRef,
      values,
      closeModal,
      isSubmitting,
      questionId,
      options,
      requestType,
    } = this.props

    const submitEnabled =
      values.reason === 'other' ? values.comment : values.reason

    return (
      <form ref={innerRef} onSubmit={handleSubmit} onReset={handleReset}>
        <Field type="hidden" name="question_id" value={questionId} />
        <Field type="hidden" name="type" value={requestType} />
        {options.map((option) => (
          <div className={css.option} key={option.value}>
            <Field
              type="radio"
              name="reason"
              id={`reason_${option.value}`}
              value={option.value}
            />
            <label htmlFor={`reason_${option.value}`}>
              <Subtitle2>{option.label}</Subtitle2>
            </label>
          </div>
        ))}
        <div className={css.commentContainer}>
          <Subtitle2>Comments:</Subtitle2>
          <Field
            className={css.comment}
            component="textarea"
            name="comment"
            value={values.comment}
          />
        </div>
        <div className={css.buttonContainer}>
          <Button type="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={css.submitButton}
            isDisabled={!submitEnabled}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

export default RequestForm
