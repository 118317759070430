import React from 'react'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import css from './pollPrompt.scss'

class PollPrompt extends React.Component {
  render() {
    const { questionId } = this.props.response.answer

    return (
      <div>
        <Header2>Poll</Header2>
        <hr />
        <Subtitle2>
          Do you want to create a poll related to this question?
        </Subtitle2>
        <div className={css.buttonGroup}>
          <Button
            type="primary"
            className={css.button}
            onClick={this.props.closeModal}
          >
            No
          </Button>
          <Button
            type="secondary"
            className={css.button}
            pathname={`/poll/edit?question_id=${questionId}&action=new_poll`}
            external
          >
            Yes
          </Button>
        </div>
      </div>
    )
  }
}

export default PollPrompt
