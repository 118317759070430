import React, { useEffect, useState, useMemo } from 'react'
import { find, flatMap, isEqual, map, isFunction, concat } from 'lodash'

import { ContainedSelect } from 'pharmacy/src/input/select'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

const formatGroupLabel = (data) => {
  return <span>{data.title}</span>
}

const optionLabel = (element) => element.title
const optionValue = (element) => element.value

const QueryBuilderSelect = ({
  type,
  setValue,
  listValues,
  value,
  asyncFetch,
  getOptionLabel = optionLabel,
  getOptionValue = optionValue,
  ...props
}) => {
  props.getOptionLabel = getOptionLabel
  props.getOptionValue = getOptionValue

  const [isReady, setIsReady] = useState(true)
  const [options, setOptions] = useState(listValues)
  const load = async () => {
    setIsReady(false)
    setOptions(await asyncFetch())
    setIsReady(true)
  }

  const currentValues = Array.isArray(value) ? value : value ? [value] : []

  const flattenedOptions = useMemo(
    () => flatMap(options, (option) => concat(option.options || [], [option])),
    [options]
  )
  const valueOptions = useMemo(
    () =>
      currentValues.map((currentValue) =>
        find(flattenedOptions, (option) => isEqual(currentValue, option.value))
      ),
    [options, value]
  )

  useEffect(() => {
    if (isFunction(asyncFetch)) {
      load()
    }
  }, [])

  if (type === 'multiselect') {
    props.isMulti = true
    props.onChange = (elements) => {
      setValue(map(elements, 'value'))
    }
  } else {
    props.isMulti = false
    props.onChange = (element) => setValue(element.value)
  }

  if (!isReady) {
    return <StarLoader size="small" isVerticalMargin={false} />
  }

  return (
    <ContainedSelect
      {...props}
      options={options}
      value={valueOptions}
      formatGroupLabel={formatGroupLabel}
      components={{
        DropdownIndicator: null,
      }}
      styles={{
        valueContainer: (provided) => ({
          ...provided,
          width: '750px',
          padding: '0',
        }),
      }}
    />
  )
}

export default QueryBuilderSelect
