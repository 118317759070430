import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'

import { Link } from 'pharmacy/src/navigation/link'

import { makeAssetURL } from 'mednet-util/src/router'

import { createSelector } from 'reselect'

import { useSelector } from 'react-redux'

import { ROUTES } from 'constants/routes'
import { EXTERNAL_LINKS } from 'constants/links'

import { getAboutNavigationLinksHierarchy } from 'anterior-util/aboutNavigationLinksHierarchy'

import css from './footer.scss'

const assetsPath = 'images/socialIcons/'

export const Footer = () => {
  const isGuest = useSelector(
    createSelector(
      (state) => (state.user.data ? state.user.data.userId : undefined),
      (userId) => !userId
    )
  )
  const getInternalPageLink = useCallback((page) => {
    return { label: page, pathname: ROUTES[page].path, external: false }
  }, [])

  const footerLists = useMemo(() => {
    const menu = [
      ...getAboutNavigationLinksHierarchy(getInternalPageLink, isGuest),
    ]
    const companyList = menu.find((list) => list.header === 'Company')
    companyList.children.push(EXTERNAL_LINKS.sitemap)
    return menu
  }, [getInternalPageLink, isGuest])

  return (
    <div className={css.blueFooter}>
      <div className={css.blueFooterInnerContainer}>
        <div className={css.mednetLogoContainer}>
          <div>
            <img
              src={makeAssetURL('images/white-mednet-logo.svg')}
              alt="theMednet"
            />
          </div>
          <div
            className={classNames(css.copyRightText, css.displayForLargeScreen)}
          >
            &copy; {`${new Date().getFullYear()} theMednet`}
          </div>
        </div>
        {footerLists.map((footerList) => (
          <div key={footerList.header} className={css.footerList}>
            <div className={css.footerListTitle}>{footerList.header}</div>
            {footerList.children.map((item) => {
              const { label, external, ...restProps } = item
              return (
                <Link key={label} external={external !== false} {...restProps}>
                  <div className={css.footerListItem}>{item.label}</div>
                </Link>
              )
            })}
          </div>
        ))}

        <div className={css.footerList}>
          <div className={css.footerListTitle}>Follow Us</div>
          <div>
            <a
              href="https://www.facebook.com/themednet"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={makeAssetURL(`${assetsPath}icon-facebook-borderline.svg`)}
                className={css.socialIcon}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/themednet/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={makeAssetURL(`${assetsPath}icon-linkedin-borderline.svg`)}
                className={css.socialIcon}
              />
            </a>

            <a
              href="https://twitter.com/themednet"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={makeAssetURL(`${assetsPath}icon-twitter-borderline.svg`)}
                className={css.socialIcon}
              />
            </a>
          </div>
        </div>

        <div
          className={classNames(css.copyRightText, css.displayForSmallScreen)}
        >
          &copy; {`${new Date().getFullYear()} theMednet`}
        </div>
      </div>
    </div>
  )
}
