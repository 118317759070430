import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'

import { createQuestionRequest } from 'mednet-cns/src/reducers/question'
import { QUESTION_REQUEST_MODAL } from 'mednet-util/src/constants/modal'

import RequestForm from './requestForm'

import css from './formScreen.scss'

class FormScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    const screenFormData = new FormData(this.form.current)
    this.props.createQuestionRequest(screenFormData, () => {
      actions.setSubmitting(false)
      this.props.changeModalScreen(QUESTION_REQUEST_MODAL.screens.confirmation)
    })
  }

  render() {
    const { closeModal, questionId, options, subtitle, requestType } =
      this.props

    return (
      <div className={css.container}>
        <Header2>Describe your request</Header2>
        <hr />
        <Subtitle2 className={css.subtitle}>{subtitle}</Subtitle2>
        <Formik
          initialValues={{
            comment: '',
          }}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <RequestForm
              closeModal={closeModal}
              questionId={questionId}
              innerRef={this.form}
              options={options}
              requestType={requestType}
              {...props}
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createQuestionRequest: (formData, callback) =>
      dispatch(createQuestionRequest(ownProps.questionId, formData, callback)),
  }
}

export default connect(null, mapDispatchToProps)(FormScreen)
