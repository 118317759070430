import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'
import { Header2, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { SPONSORSHIP_CME_MODAL } from 'mednet-util/src/constants/modal'

import { useCmeAndMocAvailabilityCheck } from 'mednet-cns/src/hooks/cme'

import css from './sposorshipCmeModal.scss'

const CmePrompt = (props) => {
  const { onSkip, onConfirm } = props
  const { isMocEnabledForUser: shouldDisplayMoc } =
    useCmeAndMocAvailabilityCheck()

  return (
    <div>
      <Header2>Redeem CME {shouldDisplayMoc && ' & MOC'}</Header2>
      <hr />
      <Subtitle2 className={css.question}>
        Would you like to claim CME {shouldDisplayMoc && ' & MOC'} for your
        answer(s)?
      </Subtitle2>
      <div className={css.content}>
        <div className={css.buttonGroup}>
          <Button type="secondary" className={css.button} onClick={onSkip}>
            Skip & Submit Answer
          </Button>
          <Button type="primary" className={css.button} onClick={onConfirm}>
            Yes
          </Button>
        </div>
        <Subtitle3 className={css.clarificationText}>
          You can claim CME credit at any time directly through your Mednet
          profile
        </Subtitle3>
      </div>
    </div>
  )
}

export const SposorshipCmeModal = (props) => {
  return (
    <Modal
      modalId={SPONSORSHIP_CME_MODAL.modalId}
      closeOnBackdrop={false}
      {...props}
    >
      <CmePrompt />
    </Modal>
  )
}
