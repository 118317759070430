import { components } from 'react-select'

import ContainedSelect from './containedSelect'
import SimpleSelect from './simpleSelect'
import TagSelect from './tagSelect'
import ProximaNovaSelect from './proximaNovaSelect'

export {
  SimpleSelect,
  TagSelect,
  ContainedSelect,
  ProximaNovaSelect,
  components,
}
