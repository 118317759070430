import { useCallback, useMemo } from 'react'
import upperFirst from 'lodash/upperFirst'

import { useSpecialtiesTreeOptionsLazy } from 'mednet-cns/src/hooks/specialty'
import { useTopicsTreeOptionsLazy } from 'mednet-cns/src/hooks/topic'
import { userStatus, userJobType } from 'mednet-util/src/constants/user'
import {
  useRecentCampaignsLazy,
  useRecentInvitationCampaignsLazy,
  useUnsentCampaignsLazy,
  useUnsentInvitationCampaignsLazy,
} from 'mednet-cns/src/hooks/campaign'
import { reverse, sortBy } from 'lodash'

export default function useQueryBuilderFields(queryUserStatus = 'default') {
  const [
    topicsTree,
    { isLoading: isTopicsTreeLoading, isLoaded: isTopicsTreeLoaded },
    loadTopicsTree,
  ] = useTopicsTreeOptionsLazy()
  const [
    specialtiesTree,
    { isLoading: isSpecialtiesTreeLoading, isLoaded: isSpecialtiesTreeLoaded },
    loadSpecialtiesTree,
  ] = useSpecialtiesTreeOptionsLazy()
  const [
    recentCampaigns,
    {
      isLoading: areRecentCampaignsLoading,
      isLoaded: areRecentCampaignsLoaded,
    },
    loadRecentCampaigns,
  ] = useRecentCampaignsLazy()
  const [
    unsentCampaigns,
    {
      isLoading: areUnsentCampaignsLoading,
      isLoaded: areUnsentCampaignsLoaded,
    },
    loadUnsentCampaigns,
  ] = useUnsentCampaignsLazy()
  const [
    recentInvitationCampaigns,
    {
      isLoading: areRecentInvitationCampaingsLoading,
      isLoaded: areRecentInvitationCampaingsLoaded,
    },
    loadRecentInvitationCampaigns,
  ] = useRecentInvitationCampaignsLazy()
  const [
    unsentInvitationCampaigns,
    {
      isLoading: areUnsentInvitationCampaingsLoading,
      isLoaded: areUnsentInvitationCampaingsLoaded,
    },
    loadUnsentInvitationCampaigns,
  ] = useUnsentInvitationCampaignsLazy()

  const mapCampaigns = useCallback((recentCampaigns) => {
    return reverse(
      sortBy(
        recentCampaigns.map((campaign) => ({
          title: `(id: ${campaign.campaignId})${
            !campaign.sponsorshipId
              ? ''
              : ` [sponsorshipId: ${campaign.sponsorshipId}]`
          } ${campaign.description}`,
          value: campaign.campaignId,
        })),
        ['campaignId']
      )
    )
  })

  const mapInvitationCampaigns = useCallback((recentCampaigns) => {
    return reverse(
      sortBy(
        recentCampaigns.map((campaign) => ({
          title: `(id: ${campaign.campaignId}) ${campaign.description}`,
          value: campaign.campaignId,
        })),
        ['campaignId']
      )
    )
  })

  const userStatusOptions = useMemo(() =>
    Object.entries(userStatus).map(([key, value]) => ({
      title: upperFirst(key.toLowerCase()).replace(/_/g, ' '),
      value,
    }))
  )

  const userJobTypeOptions = useMemo(() =>
    Object.entries(userJobType).map(([key, value]) => ({
      title: upperFirst(key.toLowerCase()).replace(/_/g, ' '),
      value,
    }))
  )

  const institutionTypeOptions = useMemo(() => [
    { title: 'Academic', value: '1' },
    { title: 'Not academic', value: '0' },
    { title: 'Undefined', value: null },
  ])

  const fields = {
    user: {
      label: 'User',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        id: {
          label: 'User ID',
          type: 'multicreatabletext',
          valueSources: ['value'],
        },
        firstname: {
          label: 'Firstname',
          type: 'text',
          valueSources: ['value'],
          operators: ['equal', 'like', 'not_like'],
          defaultOperator: 'like',
        },
        lastname: {
          label: 'Lastname',
          type: 'text',
          valueSources: ['value'],
          operators: ['equal', 'like', 'not_like'],
          defaultOperator: 'like',
        },
        email: {
          label: 'Email',
          type: 'text',
          valueSources: ['value'],
          operators: ['equal', 'like', 'not_like'],
          defaultOperator: 'like',
        },
        country: {
          label: 'Country code',
          type: 'text',
          valueSources: ['value'],
          operators: ['equal', 'not_equal'],
          defaultOperator: 'equal',
        },
        specialty: {
          label: '(Sub)Specialty',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isSpecialtiesTreeLoading && isSpecialtiesTreeLoaded)
                return specialtiesTree
              return await loadSpecialtiesTree()
            },
          },
        },
        expertTopicId: {
          label: 'Expert in topics',
          type: 'select',
          valueSources: ['value'],
          operators: [
            'select_any_in',
            'select_not_any_in',
            'select_all_in',
            'is_specified',
            'is_not_specified',
          ],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isTopicsTreeLoading && isTopicsTreeLoaded) return topicsTree
              return await loadTopicsTree()
            },
          },
        },
        idParity: {
          label: 'ID Parity',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_equals'],
          defaultValue: 'even',
          fieldSettings: {
            listValues: [
              { value: '0', title: 'Even' },
              { value: '1', title: 'Odd' },
            ],
          },
        },
      },
    },
    job: {
      label: 'Primary job',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        jobType: {
          label: 'Primary job type',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            listValues: userJobTypeOptions,
          },
        },
        institutionType: {
          label: 'Primary job institution type',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            listValues: institutionTypeOptions,
          },
        },
      },
    },
    poll: {
      label: 'Poll votes',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        questionId: {
          label: 'Voted on question',
          type: 'multicreatabletext',
          valueSources: ['value'],
        },
        date: {
          label: 'Vote date',
          type: 'date',
          operators: ['equal', 'less', 'greater'],
          valueSources: ['value'],
        },
        topicId: {
          label: 'Voted on question from topic',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isTopicsTreeLoading && isTopicsTreeLoaded) return topicsTree
              return await loadTopicsTree()
            },
          },
        },
      },
    },
    ask: {
      label: 'Questions asked',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        questionId: {
          label: 'Asked question ID',
          type: 'multicreatabletext',
          valueSources: ['value'],
        },
        topicId: {
          label: 'Asked in topics',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isTopicsTreeLoading && isTopicsTreeLoaded) return topicsTree
              return await loadTopicsTree()
            },
          },
        },
        date: {
          label: 'Ask date',
          type: 'date',
          operators: ['equal', 'less', 'greater'],
          defaultOperator: 'greater',
          valueSources: ['value'],
        },
      },
    },
    qview: {
      label: 'Questions viewed',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        questionId: {
          label: 'Viewed question ID',
          type: 'multicreatabletext',
          valueSources: ['value'],
        },
        date: {
          label: 'Question view date',
          type: 'date',
          operators: ['equal', 'less', 'greater'],
          defaultOperator: 'greater',
          valueSources: ['value'],
        },
        count: {
          label: 'Number of question views',
          type: 'number',
          preferWidgets: ['slider', 'rangeslider'],
          valueSources: ['value'],
          operators: ['equal', 'less', 'greater'],
        },
      },
    },
    qviewtopic: {
      label: 'Question topics viewed',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        topicId: {
          label: 'Viewed topics',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isTopicsTreeLoading && isTopicsTreeLoaded) return topicsTree
              return await loadTopicsTree()
            },
          },
        },
        date: {
          label: 'View topics date',
          type: 'date',
          operators: ['equal', 'less', 'greater'],
          defaultOperator: 'greater',
          valueSources: ['value'],
        },
        count: {
          label: 'Unique question views of topics',
          type: 'number',
          preferWidgets: ['slider', 'rangeslider'],
          valueSources: ['value'],
          operators: ['equal', 'less', 'greater'],
        },
      },
    },
    follow: {
      label: 'Follow',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        topicId: {
          label: 'Followed topics',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!isTopicsTreeLoading && isTopicsTreeLoaded) return topicsTree
              return await loadTopicsTree()
            },
          },
        },
      },
    },
    campaign: {
      label: 'Campaign',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        campaignId: {
          label: 'Campaign',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!areRecentCampaignsLoading && areRecentCampaignsLoaded)
                return mapCampaigns(recentCampaigns)
              const campaigns = await loadRecentCampaigns()
              return mapCampaigns(campaigns)
            },
          },
        },
        unsentCampaignId: {
          label: 'Unsent campaign',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (!areUnsentCampaignsLoading && areUnsentCampaignsLoaded)
                return mapCampaigns(unsentCampaigns)
              const campaigns = await loadUnsentCampaigns()
              return mapCampaigns(campaigns)
            },
          },
        },
        opened: {
          label: 'Campaign opened email',
          type: 'boolean',
          valueSources: ['value'],
        },
        clicked: {
          label: 'Campaign clicked email',
          type: 'boolean',
          valueSources: ['value'],
        },
      },
    },
    invite: {
      label: 'Invite',
      tooltip: 'Group of fields',
      type: '!struct',
      subfields: {
        inviteId: {
          label: 'Invite',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (
                !areRecentInvitationCampaingsLoading &&
                areRecentInvitationCampaingsLoaded
              )
                return mapInvitationCampaigns(recentInvitationCampaigns)
              const campaigns = await loadRecentInvitationCampaigns()
              return mapInvitationCampaigns(campaigns)
            },
          },
        },
        unsentInviteId: {
          label: 'Unsent invite',
          type: 'select',
          valueSources: ['value'],
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
          defaultOperator: 'select_any_in',
          fieldSettings: {
            asyncFetch: async () => {
              if (
                !areUnsentInvitationCampaingsLoading &&
                areUnsentInvitationCampaingsLoaded
              )
                return mapInvitationCampaigns(unsentInvitationCampaigns)
              const campaigns = await loadUnsentInvitationCampaigns()
              return mapInvitationCampaigns(campaigns)
            },
          },
        },
        opened: {
          label: 'Invite opened email',
          type: 'boolean',
          valueSources: ['value'],
        },
        clicked: {
          label: 'Invite clicked email',
          type: 'boolean',
          valueSources: ['value'],
        },
      },
    },
    limit: {
      label: 'Limit',
      type: 'number',
      operators: ['equal'],
      valueSources: ['value'],
    },
    omit: {
      label: 'Omit first X users',
      type: 'number',
      operators: ['equal'],
      valueSources: ['value'],
    },
  }

  if (queryUserStatus === 'default') {
    fields.user.subfields.status = {
      label: 'Status',
      type: 'select',
      valueSources: ['value'],
      operators: ['select_any_in', 'select_not_any_in'],
      defaultOperator: 'select_any_in',
      fieldSettings: {
        listValues: userStatusOptions,
      },
    }
  }

  return fields
}
