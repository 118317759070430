import React, { useState } from 'react'

import { Header1 } from 'pharmacy/src/typography'
import { Modal } from 'pharmacy/src/display/modal'
import { Icon } from 'pharmacy/src/display/icon'
import { Button } from 'pharmacy/src/input/button'

import { SPONSORSHIP_UPLOAD_PAPER_MODAL } from 'mednet-util/src/constants/modal'

import PaperForm from '../forms/paperForm'
import AddPaperForm from '../forms/addPaperForm'

import css from './addPaper.scss'

const CreateScreen = (props) => {
  const { closeModal } = props
  const [uploadNewPaper, setUploadNewPaper] = useState(false)

  return (
    <div>
      <Header1>Add Paper</Header1>
      <hr />
      {!uploadNewPaper && <AddPaperForm {...props} />}

      {uploadNewPaper && <PaperForm {...props} />}

      <div className={css.footer}>
        <div
          className={css.link}
          onClick={() => setUploadNewPaper(!uploadNewPaper)}
        >
          <Icon
            icon={['fas', uploadNewPaper ? 'arrow-circle-left' : 'plus-circle']}
            className={css.icon}
          />
          {uploadNewPaper ? 'Search existing papers' : 'Upload a new paper'}
        </div>
        <Button type="neutral" onClick={closeModal} className={css.button}>
          Close
        </Button>
      </div>
    </div>
  )
}

const AddPaperModal = (props) => {
  return (
    <Modal
      closeOnBackdrop={false}
      modalId={SPONSORSHIP_UPLOAD_PAPER_MODAL.modalId}
      size="large"
      contentClassName={css.modalContainer}
      {...props}
    >
      <CreateScreen />
    </Modal>
  )
}

export default AddPaperModal
