import { createSelector } from 'reselect'

import { createDataHook, createPostDataHook } from 'mednet-cns/src/hook-factory'

import {
  fetchSystemJobs,
  createCampaignJob,
  abandonSystemJob,
  fetchSystemJobDetails,
  editCampaignJob,
  createInvitationJob,
  sendPreview,
  JOB_TYPE,
} from '../reducers/systemJob'

export const useCreateCampaignJob = createPostDataHook(createCampaignJob)
export const useCreateInvitationJob = createPostDataHook(createInvitationJob)
export const useAbandonSystemJob = createPostDataHook(abandonSystemJob)
export const useSendPreviewTo = createPostDataHook(sendPreview)
export const useEditCampaignJob = createPostDataHook(editCampaignJob)

export const [useSystemJobDetails, useSystemJobDetailsLazy] = createDataHook(
  fetchSystemJobDetails,
  createSelector(
    (state) => state.systemJob?.details || {},
    (_state, { id }) => id,
    (details, id) => details[id]
  )
)

export const [useSystemJobs, useSystemJobsLazy] = createDataHook(
  fetchSystemJobs,
  createSelector(
    (state) => state.systemJob || {},
    (systemJob) => {
      const systemJobs = systemJob.systemjobs ?? []
      const mapping = systemJobs.reduce((mapping, job) => {
        mapping[job.id] = job
        return mapping
      }, {})

      systemJobs.forEach((job) => {
        if (
          job.type === JOB_TYPE.CHILDINVITE &&
          job.parentId &&
          mapping[job.parentId] &&
          !(
            mapping[job.parentId].subRowsIds &&
            mapping[job.parentId].subRowsIds[job.id]
          )
        ) {
          mapping[job.parentId].subRowsIds =
            mapping[job.parentId].subRowsIds ?? {}
          mapping[job.parentId].subRowsIds[job.id] = true
          mapping[job.parentId].subRows = mapping[job.parentId].subRows ?? []
          mapping[job.parentId].subRows.push(job)
        }
      })

      return systemJobs.filter((job) => job.type !== JOB_TYPE.CHILDINVITE)
    }
  )
)
