import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Field } from 'formik'

import { Header3, Header4, Subtitle2 } from 'pharmacy/src/typography'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { Icon } from 'pharmacy/src/display/icon'
import { getUserName } from 'mednet-util/src/string'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { RawBody } from 'pharmacy/src/display/rawBody'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { EXPERT_EMAIL_MODAL } from 'mednet-util/src/constants/modal'

import css from './updatesForm.scss'

class UpdatesForm extends React.Component {
  constructor(props) {
    super(props)

    this.selectedUpdates = React.createRef()
  }

  componentDidMount() {
    if (_.filter(this.props.values.updateId).length) {
      this.updateHeight()
    }
  }

  componentDidUpdate(prevProps) {
    const prevSelectedValues = _.filter(prevProps.values.updateId)
    const thisSelectedValues = _.filter(this.props.values.updateId)

    if (
      thisSelectedValues.length &&
      prevSelectedValues.length !== thisSelectedValues.length
    ) {
      this.updateHeight()
    }
  }

  updateHeight = () => {
    if (!this.selectedUpdates.current) {
      return
    }

    const updatesRect = this.selectedUpdates.current.getBoundingClientRect()
    this.props.innerRef.current.style.paddingBottom = `${updatesRect.height}px`
  }

  render() {
    const {
      existing,
      innerRef,
      isSubmitting,
      openEditEmailModal,
      setFieldValue,
      updates,
      values,
    } = this.props

    if (!updates.length) {
      return 'Nothing found'
    }

    const selectedUpdates = _.filter(
      _.map(values.updateId, (value, index) =>
        value === true ? [updates[index], index] : null
      )
    )

    return (
      <form ref={innerRef}>
        {updates.map((update, index) => (
          <div
            className={css.container}
            key={update.lastUpdate.questionUpdateId}
          >
            <div className={css.headerContainer}>
              <div>
                <div className={css.statsContainer}>
                  <div className={css.viewsStat}>
                    <Header3 component="span">Views: </Header3>
                    {update.numViews}
                  </div>
                  <div>
                    <Header3 component="span">Reach: </Header3>
                    {update.numReach}
                  </div>
                </div>
                <QuestionLink
                  className={css.questionLink}
                  target="_blank"
                  questionId={update.questionId}
                >
                  <Header3 className={css.header}>{update.question}</Header3>
                </QuestionLink>
              </div>

              <div className={css.selectContainer}>
                <Field name={`updateId[${index}]`}>
                  {({ field }) => (
                    <ButtonCheckbox
                      inputProps={{
                        ...field,
                        value: update.lastUpdate.questionUpdateId,
                        checked: field.value,
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
            <UserUpdateInformation {...update.lastUpdate} />
            <RawBody body={update.lastUpdate.answer.answer} />
          </div>
        ))}
        <Button
          onClick={openEditEmailModal}
          isLoading={isSubmitting}
          className={css.createButton}
        >
          {existing ? 'Update' : 'Create'}
        </Button>
        {selectedUpdates.length > 0 && (
          <div
            className={css.selectedUpdatesContainer}
            ref={this.selectedUpdates}
          >
            <Header4>Selected updates:</Header4>
            {selectedUpdates.map((updateObj) => {
              const [update, index] = updateObj
              const handleRemove = () =>
                setFieldValue(`updateId[${index}]`, false)
              return (
                <div
                  key={update.lastUpdate.questionUpdateId}
                  className={css.selectedUpdateWrapper}
                >
                  <Button
                    type="neutral"
                    size="small"
                    className={css.removeButton}
                    onClick={handleRemove}
                  >
                    <Icon icon={['far', 'trash-alt']} />
                  </Button>
                  <div>
                    <div>{update.question}</div>
                    <Subtitle2 className={css.selectedAuthor}>
                      Answer by {getUserName(update.lastUpdate.user)}
                    </Subtitle2>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </form>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openEditEmailModal: () => dispatch(openModal(EXPERT_EMAIL_MODAL.modalId)),
  }
}

export default connect(null, mapDispatchToProps)(UpdatesForm)
