export const getEventPath = (event) => {
  const path = []
  let currentElem = event.target

  while (currentElem) {
    path.push(currentElem)
    currentElem = currentElem.parentElement
  }

  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
    path.push(document)
  }

  if (path.indexOf(window) === -1) {
    path.push(window)
  }

  return path
}
