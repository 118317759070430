import React from 'react'

import { Card } from 'pharmacy/src/display/card'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'

import css from './userCard.scss'

class UserCard extends React.Component {
  render() {
    const { item } = this.props

    return (
      <Card>
        <div className={css.content}>
          <UserUpdateInformation user={item} />
        </div>
      </Card>
    )
  }
}

export default UserCard
