import React from 'react'

import { TextEditor } from 'pharmacy/src/input/textEditor'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'
import { Subtitle2 } from 'pharmacy/src/typography'

import css from './campaignsForm.scss'

class CampaignsForm extends React.Component {
  render() {
    const { values, isSubmitting, handleSubmit, formRef } = this.props
    const { restBody, selectedBody, selectedSubject, restSubject } = values

    return (
      <form ref={formRef}>
        <Subtitle2>Top askers:</Subtitle2>
        <div className={css.inputContainer}>
          <TextInput
            value={selectedSubject}
            name="selectedSubject"
            showClear={false}
          />
        </div>
        <div className={css.inputContainer}>
          <TextEditor value={selectedBody} name="selectedBody" />
        </div>

        <Subtitle2>Rest of users:</Subtitle2>
        <div className={css.inputContainer}>
          <TextInput value={restSubject} name="restSubject" showClear={false} />
        </div>
        <div className={css.inputContainer}>
          <TextEditor value={restBody} name="restBody" />
        </div>
        <Button onClick={handleSubmit} isLoading={isSubmitting}>
          Submit
        </Button>
      </form>
    )
  }
}

export default CampaignsForm
