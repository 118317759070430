import React from 'react'

import { ErrorPage as BaseErrorPage } from 'pharmacy/src/pages/error'

class ErrorPage extends React.Component {
  render() {
    return (
      <BaseErrorPage
        homepageProps={() => ({
          pathname: '/home/index',
          external: true,
        })}
        backProps={(history) => ({
          onClick: history.goBack,
        })}
        {...this.props}
      />
    )
  }
}

export default ErrorPage
