import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Link1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { withPermissions } from 'mednet-util/src/permission'
import { FollowQuestionButton } from 'pharmacy/src/question/followQuestionButton'
import { GoodQuestion } from 'pharmacy/src/question/goodQuestion'

import { SHARE_MODAL } from 'mednet-util/src/constants/modal'
import { authItems } from 'mednet-util/src/constants/permission'

import { FETCH_QUESTION_METADATA } from 'mednet-cns/src/reducers/question'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

import RequestAnswersButton from '../questionInformation/requestAnswersButton'

import css from './questionActions.scss'

const ShareButton = withPermissions(authItems.share)((props) => (
  <Link1>
    <Button
      type="text"
      icon={['far', 'share']}
      onClick={props.openShareModal}
      className={props.className}
    >
      Share
    </Button>
  </Link1>
))

class QuestionActions extends React.Component {
  render() {
    const { questionId, openShareModal, stubbed, useStarFoFollow } = this.props

    return (
      <div>
        <div className={css.buttonsContainer}>
          <div className={css.buttonWrapper}>
            <GoodQuestion
              questionId={questionId}
              buttonClassName={css.button}
            />
          </div>
          <div className={css.buttonWrapper}>
            <FollowQuestionButton
              questionId={questionId}
              type="button"
              className={css.button}
              stubbed={stubbed}
              useStar={useStarFoFollow}
            />
          </div>

          <div className={css.buttonWrapper}>
            <RequestAnswersButton
              questionId={questionId}
              className={css.button}
            />
          </div>

          <div className={css.buttonWrapper}>
            <ShareButton
              openShareModal={openShareModal}
              className={css.button}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const questionData = state.question.questions[questionId]
  const { isFollowed } = questionData
  const metadataRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_QUESTION_METADATA, questionId)
    ) || {}

  return {
    isFollowed,

    metadataIsLoaded: metadataRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId } = ownProps

  return {
    openShareModal: () =>
      dispatch(openModal(SHARE_MODAL.modalId, { questionId })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionActions)
