import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import './icon.scss'

class Icon extends React.Component {
  static defaultProps = {
    prefix: 'fas',
  }

  render() {
    let { prefix, icon } = this.props
    const { className, size, border, rotate, isFixedWidth, ...restProps } =
      this.props

    if (_.isArray(icon)) {
      ;[prefix, icon] = icon
    }

    const iconClasses = classNames(className, prefix, `fa-${icon}`, {
      [`fa-${size}`]: size,
      [`fa-border`]: border,
      [`fa-rotate-${rotate}`]: rotate,
      [`fa-fw`]: isFixedWidth,
    })

    return <i className={iconClasses} {...restProps} />
  }
}

export default Icon
