import React from 'react'

import { programTypesConfig } from '../configs'

import classes from './programChip.scss'

export default function ProgramChip({ programType }) {
  const programChipConfig = programTypesConfig[programType]

  if (!programChipConfig) {
    return null
  }

  return (
    <div
      style={{
        backgroundColor: programChipConfig.bgColor,
        color: programChipConfig.textColor,
      }}
      className={classes.programChip}
    >
      <programChipConfig.Icon {...programChipConfig.iconProps} />

      <div>{programChipConfig.text}</div>
    </div>
  )
}
