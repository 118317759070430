import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { Button } from 'pharmacy/src/input/button'

import { MIN_ACTIVE_ACTIVITY_DATE } from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'

import {
  useAvailableCmeActivitiesLazy,
  useCmeActivitiesCountLazy,
} from 'mednet-cns/src/hooks/cme'

import { makeQueryString } from 'mednet-util/src/router'

import CmeActivitiesTable from 'components/tables/cmeActivitiesTable'

import { CmeDateRangePicker } from './cmeFilters'
import { CmeAssessmentPage } from './cmeAssessmentPage'
import { useAvailableActivitiesSelector } from './hooks/availableActivitiesSelector'

import css from './cmeAvailableTab.scss'

export const CmeAvailableTab = (props) => {
  const {
    creditsPerActivity,
    maxCreditsPerYear,
    minDate,
    reloadFlag,
    setReloadFlag,
    isMocEnabledForUser,
    history,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
  } = props
  const [assessmentState, setAssessmentState] = useState({
    active: false,
    activities: [],
  })
  const [
    { redeemedMaxForCurrentYear, availableCount, redeemedCount },
    cmeActivitiesCountRequest,
    loadCmeActivitiesCount,
  ] = useCmeActivitiesCountLazy()

  const [
    { activities: availableCmeActivities, fromDate, toDate },
    cmeAvailableActivitiesRequest,
    loadAvailableCmeActivities,
  ] = useAvailableCmeActivitiesLazy()

  const minFilterDate = useMemo(() => {
    return minDate > MIN_ACTIVE_ACTIVITY_DATE
      ? minDate
      : MIN_ACTIVE_ACTIVITY_DATE
  }, [minDate, MIN_ACTIVE_ACTIVITY_DATE])

  /* 
    Later, should default to this only if no available reports
    Otherwise, this should be the date of the last certificate. 
    Waiting for Varsha and Liza resnpone on whether there is a constraint on that.
    For example, if the date of last certificate is today, yesterday, or last week.
  */
  const defaultFromDate = minFilterDate
  const defaultToDate = new Date()

  const loadActivities = useCallback(
    (fromDate, toDate) => {
      const formDateStr =
        typeof fromDate === 'string'
          ? fromDate
          : moment(fromDate).format('YYYY-MM-DD')

      const toDateStr =
        typeof toDate === 'string'
          ? toDate
          : moment(toDate).format('YYYY-MM-DD')

      loadAvailableCmeActivities(formDateStr, toDateStr)
    },
    [loadAvailableCmeActivities]
  )

  useEffect(() => {
    if (reloadFlag) {
      loadActivities(fromDate ?? defaultFromDate, toDate ?? defaultToDate)
      loadCmeActivitiesCount()
      setReloadFlag(false)
    }
  }, [reloadFlag])

  useEffect(() => {
    mixpanel.track('CME Available Tab Load', {})
  }, [])

  const {
    selectedRowsIds,
    disabledRowsIds,
    getSelectedCount,
    handleSelect,
    handleSelectAll,
    handleDeselectAll,
  } = useAvailableActivitiesSelector({
    availableActivities: availableCmeActivities,
    maxCreditsPerYear,
    creditsPerActivity,
    redeemedCount,
  })

  const handleDateRangeChange = useCallback(
    (range) => {
      loadActivities(range.from, range.to)
      handleDeselectAll()
    },
    [loadActivities, handleDeselectAll]
  )

  const tableActivities = useMemo(() => {
    return availableCmeActivities.map((activity, index) => ({
      ...activity,
      id: index,
    }))
  }, [availableCmeActivities])

  const handleContinueButtonClick = useCallback(
    () =>
      setAssessmentState({
        active: true,
        activities: availableCmeActivities.filter(
          (_, index) => selectedRowsIds[index]
        ),
      }),
    [availableCmeActivities, selectedRowsIds, setAssessmentState]
  )

  if (assessmentState.active) {
    return (
      <div className={css.assessmentPageContainer}>
        <CmeAssessmentPage
          activities={assessmentState.activities}
          location="Cme Summary Page"
          withPreview
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          showNextStepAsCard
          history={history}
          onExit={() => {
            history.push({
              pathname: '/cme',
              search: makeQueryString({ tab: 'Redeemed' }),
            })
          }}
        />
      </div>
    )
  }

  return (
    <div className={css.mainContainer}>
      {cmeActivitiesCountRequest.isLoaded && (
        <div className={css.activitiesCountsSummary}>
          <div>
            Total Credits Redeemed in {new Date().getFullYear()}:{' '}
            {redeemedCount * creditsPerActivity} Credits
          </div>
          <div>
            Total Available Credits in {new Date().getFullYear()}:{' '}
            {availableCount * creditsPerActivity} Credits
            {redeemedMaxForCurrentYear &&
              `. You have already redeemed the maximum ${maxCreditsPerYear} AMA credits for this year`}
          </div>
        </div>
      )}
      <div className={css.title}>{`Activity Summary Available for CME ${
        isMocEnabledForUser ? '& MOC ' : ''
      }Credit`}</div>

      <div className={css.tableHeader}>
        <div className={css.displayForLargeScreen}>
          <Button
            type="dark_blue"
            className={css.button}
            onClick={handleContinueButtonClick}
            isDisabled={getSelectedCount() <= 0}
          >
            <div className={css.buttonText}>
              {getSelectedCount() > 0 ? 'Redeem' : 'Select activity to redeem'}
            </div>
          </Button>
        </div>

        <CmeDateRangePicker
          minDate={minFilterDate}
          // Don't lose filter value when moving between tabs as the data and displayed filter value will be inconsistent then
          defaultFrom={
            fromDate ? moment(fromDate, 'YYYY-MM-DD').toDate() : defaultFromDate
          }
          defaultTo={
            toDate ? moment(toDate, 'YYYY-MM-DD').toDate() : defaultToDate
          }
          handleChange={handleDateRangeChange}
        />
      </div>

      <div className={css.tableContainer}>
        <CmeActivitiesTable
          cmeActivities={tableActivities}
          isLoading={
            cmeAvailableActivitiesRequest.isLoading ||
            cmeActivitiesCountRequest.isLoading
          }
          loadingDataError={
            cmeAvailableActivitiesRequest.isError ||
            cmeActivitiesCountRequest.isError
          }
          selectedRowsIds={selectedRowsIds}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          disabledRowsIds={disabledRowsIds}
          emptyDataMessage={
            redeemedMaxForCurrentYear
              ? `You have already redeemed the maximum ${maxCreditsPerYear} AMA credits for this year`
              : 'You have no available activities in the selected date range'
          }
        />
      </div>

      <div className={css.tableFooter}>
        <div className={css.selectedCreditsText}>
          <div className={css.credits}>
            {getSelectedCount() * creditsPerActivity}
            &nbsp;&nbsp;
          </div>
          <div>Credits Selected</div>
        </div>
        <div className={css.displayForSmallScreen}>
          <Button
            type="dark_blue"
            className={css.button}
            onClick={handleContinueButtonClick}
            isDisabled={getSelectedCount() <= 0}
          >
            <div className={css.buttonText}>
              {getSelectedCount() > 0 ? 'Redeem' : 'Select activity to redeem'}
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}
