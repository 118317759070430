import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { ONBOARDING_MODAL } from 'mednet-util/src/constants/modal'

import IntroScreen from './introScreen'
import TopicsScreen from './topicsScreen'

const screens = {
  [ONBOARDING_MODAL.screens.intro]: IntroScreen,
  [ONBOARDING_MODAL.screens.topics]: TopicsScreen,
}

class OnboardingModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        defaultScreen={ONBOARDING_MODAL.screens.intro}
        modalId={ONBOARDING_MODAL.modalId}
        screens={screens}
      />
    )
  }
}

export default OnboardingModal
