import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

export const isUserPermittedTo = (permissions, requestedPermissions) =>
  !_.some(
    _.isArray(requestedPermissions)
      ? requestedPermissions
      : [requestedPermissions],
    (requestedPermission) => !_.includes(permissions.items, requestedPermission)
  )

export const withPermissionsWithConnect =
  (connect) => (connectedRequestedPermissions) => (WrappedComponent) => {
    class WithPermissions extends React.Component {
      static displayName = `WithPermissions(${
        WrappedComponent.displayName || WrappedComponent.name
      })`

      render() {
        const { permissions, forwardedRef, ...passThroughProps } = this.props

        if (!permissions) {
          return null
        }

        if (!isUserPermittedTo(permissions, connectedRequestedPermissions)) {
          return null
        }

        return (
          <WrappedComponent
            ref={forwardedRef}
            isUserPermittedTo={isUserPermittedTo.bind(this, permissions)}
            {...passThroughProps}
          />
        )
      }
    }

    const mapStateToProps = (state) => {
      return {
        permissions: state.user.permissions,
      }
    }

    const ConnectedWithPermissions = connect(mapStateToProps, null, null, {
      forwardRef: true,
    })(WithPermissions)

    // eslint-disable-next-line prefer-arrow-callback
    return React.forwardRef(function ForwardedComponent(props, ref) {
      return <ConnectedWithPermissions {...props} forwardedRef={ref} />
    })
  }

export const withPermissions = withPermissionsWithConnect(connect)
