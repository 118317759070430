import React from 'react'
import classNames from 'classnames'
import { Field } from 'formik'

import { Label1, Body1, Subtitle3 } from 'pharmacy/src/typography'

import css from './pollOptions.scss'

class PollOptions extends React.Component {
  constructor(props) {
    super(props)

    this.other = React.createRef()
  }

  handleChange = (optionObject) => (evt) => {
    const { arrayHelpers, isMultiple } = this.props
    const { values } = arrayHelpers.form
    const { optionId, isOther } = optionObject

    if (isMultiple) {
      if (evt.target.checked) {
        arrayHelpers.push(optionId)
      } else {
        const idx = values.vote.indexOf(optionId)
        arrayHelpers.remove(idx)
      }
    } else {
      arrayHelpers.replace(0, optionId)
    }

    if (isOther) {
      setTimeout(() => this.other.current.focus(), 0)
    }
  }

  render() {
    const { arrayHelpers, options, showResults, showVote, isMultiple } =
      this.props
    const { values } = arrayHelpers.form

    const resultClasses = classNames(css.resultContainer, {
      [css.resultContainer_withVote]: showVote,
    })

    const inputClasses = classNames({
      [css.optionInput_hidden]: !showVote,
    })

    return (
      <div className={css.optionsContainer}>
        {showResults && <Field type="hidden" name="same" />}

        {!showResults && isMultiple ? (
          <div className={css.optionContainer}>
            <Subtitle3 className={css.selectAllInfoText}>
              Select all that apply:
            </Subtitle3>
          </div>
        ) : null}

        {options.map((optionObject, index) => {
          const { count, percent } = optionObject

          const showOther =
            optionObject.isOther &&
            showVote &&
            values.vote.includes(optionObject.optionId)

          const containerClassNames = classNames({
            [css.optionContainer]: true,
            [css.otherOptionContainer]: showOther,
          })

          return (
            <div key={optionObject.optionId} className={containerClassNames}>
              <div className={css.option}>
                <Field
                  type={isMultiple ? 'checkbox' : 'radio'}
                  className={inputClasses}
                  name={isMultiple ? `vote[${index}]` : 'vote[0]'}
                  value={optionObject.optionId}
                  id={optionObject.optionId}
                  checked={values.vote.includes(optionObject.optionId)}
                  onChange={this.handleChange(optionObject)}
                />
                <label htmlFor={optionObject.optionId}>
                  <Body1>{optionObject.option}</Body1>
                </label>
              </div>
              {showResults && (
                <div className={resultClasses}>
                  <div className={css.percentContainer}>
                    <Label1>{percent}%</Label1>
                    <div
                      className={css.percentBar}
                      style={{ width: `${percent}%` }}
                    />
                  </div>
                  <div className={css.voteCount}>
                    <Subtitle3>{count} votes</Subtitle3>
                  </div>
                </div>
              )}
              {showOther && (
                <Field
                  id="optionOther"
                  name="other"
                  value={values.other}
                  className={css.other}
                  innerRef={this.other}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

export default PollOptions
