import React from 'react'
import { initialize, set, pageview, event as gaEvent } from 'react-ga'

import {
  mixpanel,
  setMixpanelAgentProperties,
  setMixpanelReferrerProperties,
  updateMixpanelSessionProps,
  smartlookClient,
} from 'mednet-util/src/tracking'

class Tracking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPath: props.history.location.pathname,
    }
  }

  componentDidMount() {
    const { history } = this.props

    try {
      if (process.env.MIXPANEL_TOKEN) {
        mixpanel.init(process.env.MIXPANEL_TOKEN, {
          loaded: () => {
            if (document) document.mixpanel_loaded = true
          },
        })

        const userId = sessionStorage.getItem('user_id')

        if (userId) {
          mixpanel.identify(userId)
          mixpanel.register({ user_id: userId }) // It is recommended by mixpanel to set user id as super property
        } else if (
          mixpanel.get_distinct_id() &&
          // if mixpanel distinct id is a number, this means it is an id from our system and not anonymous
          !isNaN(mixpanel.get_distinct_id())
        ) {
          // Clears super properties and generates a new random distinct_id for this instance.
          mixpanel.reset()
        }

        setMixpanelReferrerProperties()
        setMixpanelAgentProperties()

        const mpLocationListener = (newLocation) => {
          //  doing this becasue in server rendering this listener is triggered on first render
          if (this.state.currentPath === newLocation.pathname) {
            return
          }

          this.setState({ currentPath: newLocation.pathname })

          updateMixpanelSessionProps({
            changedPageInSession: true,
          })
          const params = new URLSearchParams(newLocation.search)
          const src = params.get('src')

          // since these changes are in react router, we can assume it is alway an internal react page
          // This is also needed as document.referrer does not change when react router changes the page
          if (src === 'popover-search') {
            mixpanel.register({
              direct_referrer_label: 'theMednet Popover Search',
              direct_referrer_search_query: params.get('query'),
            })
          } else {
            mixpanel.register({
              direct_referrer_label: 'theMednet',
              direct_referrer_search_query: null,
            })
          }
        }

        history.listen(mpLocationListener)

        this.beforeUnloadListener = () => {
          updateMixpanelSessionProps({
            changedPageInSession: true,
          })
        }

        window.addEventListener('beforeunload', this.beforeUnloadListener)
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      smartlookClient.init(process.env.SMARTLOOK_API_KEY)
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (process.env.GOOGLE_ANALYTICS_ID) {
      initialize(process.env.GOOGLE_ANALYTICS_ID)

      const trackRegisteredIfNeeded = () => {
        const history = this.props.history
        const searchParams = new URLSearchParams(history.location.search)

        const trackGaRegistered = searchParams.get('trackGaRegistered')

        if (trackGaRegistered === 'true') {
          gaEvent({
            category: 'Registration',
            action: 'NewCompletedRegistration',
          })

          searchParams.delete('trackGaRegistered')
          history.replace(
            `${history.location.pathname}?${searchParams.toString()}${
              history.location.hash ? `#${history.location.hash}` : ''
            }`
          )
        }
      }

      trackRegisteredIfNeeded()
      const locationListener = (location) => {
        set({ page: location.pathname })
        pageview(location.pathname)
        trackRegisteredIfNeeded()
      }

      locationListener(window.location)

      history.listen(locationListener)
    }

    // Track page views for pixels
    if (!process.env.DEBUG) {
      const locationListener = () => {
        if (typeof window !== 'undefined' && window.fbq) {
          // fbq is defined in the head of the html in hemlet
          window.fbq?.('track', 'PageView')
        }

        if (typeof window !== 'undefined' && window.twq) {
          // twq is defined in the head of the html in hemlet
          window.twq('track', 'PageView')
        }
      }

      locationListener(window.location)

      history.listen(locationListener)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener)
  }

  render() {
    return null
  }
}

export default Tracking
