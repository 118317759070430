import classNames from 'classnames'
import React from 'react'

import { createTabs } from './creators'

import css from './tabs.scss'

const HeaderContainer = ({ children, underlined }) => (
  <div
    className={classNames(css.headerContainer, {
      [css.underlinedHeader]: underlined,
    })}
  >
    {children}
  </div>
)

const TabContainer = ({ children, fullWidthTabs, tabsCount }) => (
  <div
    className={css.tab}
    style={fullWidthTabs && { width: `${100 / tabsCount}%` }}
  >
    {children}
  </div>
)

export default createTabs({
  HeaderContainer,
  TabContainer,
  ComponentsContainer: 'div',
})
