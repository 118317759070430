import React from 'react'

import QuestionFeedItem from './questionFeedItem'
import Feed from './feed'

class QuestionFeed extends React.Component {
  render() {
    return (
      <Feed
        FeedItem={QuestionFeedItem}
        itemKey="questionId"
        feedURL="question/getFeedJSON"
        {...this.props}
      />
    )
  }
}

export default QuestionFeed
