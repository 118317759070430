import React from 'react'
import classNames from 'classnames'

import css from './highlightsBar.scss'

export const HighlightsBar = (props) => {
  const { children, header, noLine, className } = props
  return (
    <div className={classNames(css.highlightsBar, className)}>
      <div className={css.highlightsHeader}>{header}</div>
      {!noLine && <hr className={css.highlightsHeaderUnderLine} />}
      {children}
    </div>
  )
}
