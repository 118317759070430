export const specialtyId = {
  RADIATION: 1,
  MEDICAL: 2,
  SURGICAL: 3,
  GYN: 4,
}

export const specialtyDescription = {
  [specialtyId.RADIATION]: 'Radiation Oncologist',
  [specialtyId.MEDICAL]: 'Medical Oncologist',
  [specialtyId.SURGICAL]: 'Surgical Oncologist',
  [specialtyId.GYN]: 'Gynecologic Oncologist',
}

export const specialtyName = {
  [specialtyId.RADIATION]: 'Radiation Oncology',
  [specialtyId.MEDICAL]: 'Medical Oncology',
  [specialtyId.SURGICAL]: 'Surgical Oncology',
  [specialtyId.GYN]: 'Gynecologic Oncology',
}
