import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_PRESS_RELEASES = 'pressRelease/FETCH_PRESS_RELEASES'

export function fetchPressReleases(callback) {
  return {
    type: FETCH_PRESS_RELEASES,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_PRESS_RELEASES,
    'pressRelease/GetPublicPressReleasesJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  pressReleases: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_PRESS_RELEASES): {
      return receiveReducer(state, action, () => ({
        ...state,
        pressReleases: action.response,
      }))
    }

    default:
      return state
  }
}
