import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

export class StatefulFeedItem extends React.Component {
  render() {
    const { item, feedName, itemIndex, trackViewedItems } = this.props

    return (
      <this.props.itemComponent
        item={item}
        feedName={feedName}
        itemIndex={itemIndex}
        trackViewedItems={trackViewedItems}
        {...this.props}
      />
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const { indexPath, itemIndex, objectPath } = ownProps
  const item = _.get(state, indexPath)[itemIndex]
  if (objectPath) {
    return {
      item: _.get(state, objectPath)[item],
    }
  }

  return {
    item,
  }
}

export default connect(mapStateToProps)(StatefulFeedItem)
