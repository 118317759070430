import React from 'react'
import { connect } from 'react-redux'

import { closeModal, changeModalScreen } from 'mednet-cns/src/reducers/modal'

import BaseModal from './base/baseModal'

class Modal extends React.Component {
  renderContent = () => {
    const {
      changeModalScreen,
      children,
      closeModal,
      data,
      defaultScreen,
      isOpen,
      screenName,
      screens,
      ...restProps
    } = this.props

    if (children) {
      return React.cloneElement(children, {
        closeModal,
        changeModalScreen,
        ...restProps,
        ...data,
      })
    }

    if (screens && isOpen) {
      const Screen = screens[screenName || defaultScreen]

      return (
        <Screen
          changeModalScreen={changeModalScreen}
          closeModal={closeModal}
          {...restProps}
          {...data}
        />
      )
    }

    return null
  }

  render() {
    const {
      changeModalScreen,
      closeModal,
      data,
      defaultScreen,
      isOpen,
      screenName,
      screens,
      ...modalProps
    } = this.props

    return (
      <BaseModal isOpen={isOpen} closeModal={closeModal} {...modalProps}>
        {this.renderContent()}
      </BaseModal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const modalObject = state.modal[ownProps.modalId] || {}
  const { isOpen, screenName, data } = modalObject
  return {
    isOpen: ownProps.isOpen || isOpen,
    screenName,
    data,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => dispatch(closeModal(ownProps.modalId)),
    changeModalScreen: (screenName) =>
      dispatch(changeModalScreen(ownProps.modalId, screenName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
