import noop from 'lodash/noop'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const createPostDataHook =
  (action, selector = noop) =>
  () => {
    const dispatch = useDispatch()
    const request = useCallback((...data) => (resolve, _reject) => {
      dispatch(
        action(...data, (_a, _b, state) => {
          resolve(selector(state, data))
        })
      )
    })
    return [(...data) => new Promise(request(...data))]
  }

export default createPostDataHook
