import React from 'react'

// copied from Mui repo, if we started using more Mui icon we should consider installing the Mui icons package
// https://github.com/mui/material-ui/blob/38e3d6517c10442d6a67dc6bc73bc6417509e8bf/packages/mui-icons-material/material-icons/dynamic_feed_24px.svg?short_path=9bf753f
export default function MultiSpecialtySpotlightIcon({ size = 18, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      style={style}
    >
      <g>
        <rect fill="none" height={size} width={size} />
      </g>
      <g>
        <g />
        <g>
          <path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z" />
          <path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z" />
          <path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z" />
        </g>
      </g>
      <g display="none">
        <g display="inline" />
        <g display="inline">
          <path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z" />
          <path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z" />
          <path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z" />
        </g>
      </g>
    </svg>
  )
}
