import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import colors from 'pharmacy/src/style/colors'
import {
  Header3,
  Header2,
  Subtitle1,
  Subtitle2,
  Link2,
} from 'pharmacy/src/typography'
import { UserImage } from 'pharmacy/src/user/userImage'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { Star } from 'pharmacy/src/style/logo'
import { roundStringNumber } from 'mednet-util/src/string'
import { activeMention, mouseLeave } from 'mednet-cns/src/reducers/mention'
import { statName } from 'mednet-util/src/constants/user'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader/'

import css from './mention.scss'

const MARGIN = 8

class Mention extends React.Component {
  getPosition = () => {
    const { current } = this.props
    const { rect, scrollTop } = current

    if (!rect) {
      return null
    }

    const { top, left, height } = rect

    return {
      left,
      top: top + height + scrollTop + MARGIN,
    }
  }

  renderStat(title, stat) {
    if (stat === 0) {
      return null
    }

    return (
      <div className={css.statContainer}>
        <Link2 className={css.statTitle}>{title}</Link2>
        <Header2 className={css.statNum}>{roundStringNumber(stat)}</Header2>
      </div>
    )
  }

  renderStats = () => {
    const { user } = this.props
    if (!user) {
      return null
    }

    const { stats } = user
    if (!stats) {
      return null
    }

    const { numAnswers, numViews, numReach } = stats

    return (
      <div className={css.statsContainer}>
        {this.renderStat(statName.ANSWERS, numAnswers)}
        {this.renderStat(statName.VIEWS, numViews)}
        {this.renderStat(statName.REACH, numReach)}
      </div>
    )
  }

  render() {
    const { user = {}, activeMention, mouseLeave, isOpen, current } = this.props
    const { isLoaded } = current
    const { userId, imageVersion, title, institutionName, specialty } = user

    return (
      <CSSTransition
        classNames={{
          enter: css.container_enter,
          enterActive: css.container_enter_active,
          enterDone: css.container_enter_done,
          exit: css.container_exit,
          exitActive: css.container_exit_active,
        }}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        timeout={250}
      >
        <div
          className={css.container}
          style={this.getPosition()}
          onMouseEnter={activeMention}
          onMouseLeave={mouseLeave}
        >
          <div className={css.arrow} />
          <div className={css.content}>
            {isLoaded && user && (
              <>
                <UserImage
                  className={css.image}
                  userId={userId}
                  imageVersion={imageVersion}
                />
                <Header3 className={css.name}>
                  <UserNameLink
                    userId={userId}
                    target="_blank"
                    className={css.nameLink}
                    disableMention
                  />
                </Header3>
                {title && <Subtitle2 className={css.title}>{title}</Subtitle2>}
                {institutionName && (
                  <Subtitle2 className={css.institution}>
                    {institutionName}
                  </Subtitle2>
                )}

                {specialty && specialty.specialty && (
                  <Subtitle1 className={css.specialty}>
                    {specialty.specialty}
                  </Subtitle1>
                )}

                {this.renderStats()}
              </>
            )}
            <br />
            {!isLoaded && (
              <div className={css.loaderContainer}>
                <StarLoader isVerticalMargin={false} size="small" />
              </div>
            )}

            <Star className={css.star} color={colors.colorGrayBackground} />
          </div>
        </div>
      </CSSTransition>
    )
  }
}

const mapStateToProps = (state) => {
  const { current = {}, isOpen } = state.mention
  const { userId } = current
  const user = current && state.user.users[userId]

  return {
    current,
    user,
    isOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeMention: () => dispatch(activeMention()),
    mouseLeave: () => dispatch(mouseLeave(true)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mention)
