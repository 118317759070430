import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { POST_ANSWER_MODAL } from 'mednet-util/src/constants/modal'

import DisclosurePrompt from './disclosurePrompt'
import DisclosureForm from './disclosureForm'
import PollPrompt from './pollPrompt'

const screens = {
  [POST_ANSWER_MODAL.screens.disclosurePrompt]: DisclosurePrompt,
  [POST_ANSWER_MODAL.screens.disclosureForm]: DisclosureForm,
  [POST_ANSWER_MODAL.screens.pollPrompt]: PollPrompt,
}

class PostAnswerModal extends React.Component {
  render() {
    return (
      <Modal
        modalId={POST_ANSWER_MODAL.modalId}
        screens={screens}
        defaultScreen={POST_ANSWER_MODAL.screens.disclosurePrompt}
        closeOnBackdrop={false}
        {...this.props}
      />
    )
  }
}

export default PostAnswerModal
