import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { Subtitle2, Subtitle4 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'

import { CME_ACTIVITY_TYPE_LABEL } from 'mednet-util/src/constants/cme'

import Table from 'components/tables/table'

import { CmeActivityLink } from '../cmeActivityLink'

import css from './cmeActivitiesTable.scss'

const DisabledCheckboxReplacement = () => {
  return (
    <Icon
      icon="check-circle"
      className={classNames(css.checkIcon, css.displayForSmallScreen)}
    />
  )
}

export default function CmeActivitiesTable({
  cmeActivities,
  loadingDataError,
  isLoading,
  bodyHeight: bodyHeight = 438,
  selectedRowsIds,
  handleSelect,
  handleSelectAll,
  handleDeselectAll,
  disabledRowsIds,
  isRedeemed,
  emptyDataMessage,
}) {
  const sortFunction = useCallback(
    (rowA, rowB, _columnId, desc) => {
      const aIndex = rowA.index
      const bIndex = rowB.index
      let aDate = undefined
      let bDate = undefined

      if (isRedeemed) {
        const aHasCertificate = rowA.original.createdCertificate
        const bHasCertificate = rowB.original.createdCertificate

        if (aHasCertificate && !bHasCertificate) {
          return desc ? -1 : 1
        }

        if (!aHasCertificate && bHasCertificate) {
          return desc ? 1 : -1
        }

        aDate = moment(rowA.original.redeemDate, 'YYYY-MM-DD')
        bDate = moment(rowB.original.redeemDate, 'YYYY-MM-DD')
      } else {
        aDate = moment(rowA.original.activityDate, 'YYYY-MM-DD')
        bDate = moment(rowB.original.activityDate, 'YYYY-MM-DD')
      }

      /*
       On equality sort original data index (cannot result in equality). do this because IE sorting is not stable.
       Not stable sorting will cause selected rows to be mixed with disabled rows on select all. Index sorting is  
       reversed (1 when smaller) becasue the cme apis return original activities data in descending order
      */
      return aDate.isAfter(bDate)
        ? 1
        : bDate.isAfter(aDate)
        ? -1
        : aIndex > bIndex
        ? -1
        : 1
    },
    [isRedeemed]
  )

  const columns = useMemo(
    () => [
      {
        Header: isRedeemed ? 'Redeemed' : 'Date',
        accessor: isRedeemed ? 'redeemDate' : 'activityDate',
        sortable: true,
        sortType: sortFunction,
        headerClassName: css.dateTableCell,
        className: css.dateTableCell,
        Cell: ({ value, row }) => {
          const isDisabled = disabledRowsIds[row.original.id]
          return (
            <div className={isDisabled && css.disabledText}>
              {moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')}
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: 'contentId',
        headerClassName: css.activityTableCell,
        className: css.activityTableCell,
        Cell: ({ row }) => {
          const isDisabled = disabledRowsIds[row.original.id]
          return (
            <div
              className={classNames(css.activityCellContent, {
                [css.disabledText]: isDisabled,
              })}
            >
              <Subtitle2 className={css.activityType}>
                {CME_ACTIVITY_TYPE_LABEL[row.original.contentType]}
              </Subtitle2>

              {isRedeemed && row.original.createdCertificate && (
                <Subtitle4 className={css.rightTopText}>
                  Certificate Created
                  <Icon icon="check-circle" className={css.checkIcon} />
                </Subtitle4>
              )}

              <CmeActivityLink
                activity={row.original}
                onClick={(e) => e.stopPropagation()}
                position={row.original.id + 1}
              >
                <div className={css.description}>
                  <div
                    className={classNames(css.summary, {
                      [css.longSummary]: !row.original.subSummary,
                      [css.shortSummary]: row.original.subSummary,
                    })}
                  >
                    {row.original.summary}
                  </div>
                  <div className={css.subSummary}>
                    {row.original.subSummary}
                  </div>
                </div>
              </CmeActivityLink>

              <Subtitle4 className={css.rightBottomText}>
                <div>Credits: 0.5</div>
              </Subtitle4>
            </div>
          )
        },
      },
    ],
    [disabledRowsIds, isRedeemed]
  )

  const tableProps = {
    columns,
    data: cmeActivities,
    isLoading,
    bodyHeight,
    loadingDataError,
    errorMessage: "Couldn't load activites. Please try again",
    selected: selectedRowsIds,
    emptyDataMessage,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    onDeselectAll: handleDeselectAll,
    shadeOddRows: true,
    withBorders: true,
    sortBy: [
      {
        id: isRedeemed ? 'redeemDate' : 'activityDate',
        desc: true,
      },
    ],
    removeFooter: true,
    disabledRowsIds,
    dynamicWidth: true,
    DisabledCheckboxReplacement: isRedeemed
      ? DisabledCheckboxReplacement
      : undefined,
    selectOnRowClick: true,
    defaultAllSelected: true,
  }

  return (
    <>
      {/*
        Selcted rows are maintained when changing screen size, 
        but selected All (the checkbox in header is not which is fine) 
       */}
      <div className={css.displayWhenLargeScreen}>
        <Table {...tableProps} itemSize={120} />
      </div>
      <div className={css.displayWhenSmallScreen}>
        <Table {...tableProps} itemSize={150} />
      </div>
    </>
  )
}
