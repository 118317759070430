import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'
import { CmeAssessmentForm } from 'pharmacy/src/cme/cmeAssessmentForm'
import { Link2 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { MocAssessmentModal } from 'pharmacy/src/cme/mocAssessmentModal'

import {
  CME_ACTIVITY_TYPE,
  CME_ACTIVITY_TYPE_LABEL,
} from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'
import { MOC_ASSESSMENT_MODAL } from 'mednet-util/src/constants/modal'

import {
  useQuestionCmeRedeemableCheck,
  useRedeemCmeActivity,
  useCmeAndMocAvailabilityCheck,
  useCmeConfig,
} from 'mednet-cns/src/hooks/cme'

import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import css from './questionViewCme.scss'

const getClasses = (isDisabled, className) => {
  return classNames(className, { [css.disabled]: isDisabled })
}

const RedeemedMessage = ({ redeemedMessage }) => {
  return (
    <div>
      <Link
        className={css.redeemedMessage}
        pathname="/cme"
        search={{ tab: 'Redeemed' }}
      >
        {redeemedMessage}
        <Icon icon="check-circle" className={css.checkIcon} />
      </Link>
    </div>
  )
}

export const QuestionViewCme = ({
  questionId,
  vertical,
  scrollToCenterWhenRedeemded,
  largeTitle,
}) => {
  const {
    isCmeOrMocAvailableForUser,
    isMocEnabledForUser,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
  } = useCmeAndMocAvailabilityCheck()
  const [isExpanded, setExpanded] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const redeemCmeActivity = useRedeemCmeActivity()

  const containerRef = useRef()

  // An activity can be available to one of CME or MOC at the same time, cause if CME is not yet redeemed, then it is not available for MOC
  const [
    {
      isQuestionRedeemableForCme,
      isQuestionRedeemableForMoc,
      questionActivityId,
    },
    questionRedeemableCheckRequest,
  ] = useQuestionCmeRedeemableCheck(questionId)
  const [cmeConfig, cmeConfigRequest] = useCmeConfig()
  const [openMocAssessmentModal, closeMocAssessmentModal] = useModalDrivers(
    MOC_ASSESSMENT_MODAL.modalId
  )

  const [questionInitialRedeemableState, setQuestionInitialRedeemableState] =
    useState({
      cme: isQuestionRedeemableForCme,
      isQuestionRedeemableForMoc: false,
      activityId: questionActivityId,
    })

  const reset = useCallback(() => {
    return () => {
      setExpanded(false)
      setDisabled(false)
      setQuestionInitialRedeemableState({
        cme: false,
        moc: false,
        activityId: undefined,
      })
    }
  }, [
    clearTimeout,
    setExpanded,
    setDisabled,
    setQuestionInitialRedeemableState,
  ])

  const enableButton = useCallback(() => {
    if (
      questionRedeemableCheckRequest.isLoaded &&
      (isQuestionRedeemableForCme || isQuestionRedeemableForMoc)
    ) {
      setDisabled(false)
    } else {
      setExpanded(false)
      setDisabled(true)
    }
  }, [
    isQuestionRedeemableForCme,
    isQuestionRedeemableForMoc,
    questionRedeemableCheckRequest.isLoaded,
  ])

  const getSubmissionTrackingProps = useCallback(
    (data, { impacts, goals, applications }, res) => {
      return {
        question_id: questionId,
        publication_id: null,
        activity_type: CME_ACTIVITY_TYPE_LABEL[CME_ACTIVITY_TYPE.QUESTION_VIEW],
        location: 'Question Page',
        success: res.success,
        goal_selected_id: parseInt(data.goalId),
        application_selected_id: parseInt(data.applicationId),
        impact_selected_id: parseInt(data.impactId),
        goal_selected_text: goals.find(
          (goal) => goal.goalId === parseInt(data.goalId)
        ).description,
        application_selected_text: applications.find(
          (application) =>
            application.applicationId === parseInt(data.applicationId)
        ).description,
        impact_selected_text: impacts.find(
          (impact) => impact.impactId === parseInt(data.impactId)
        ).description,
      }
    },
    [questionId]
  )

  useEffect(reset, [questionId])

  useEffect(enableButton, [
    isQuestionRedeemableForCme,
    isQuestionRedeemableForMoc,
    questionRedeemableCheckRequest.isLoaded,
  ])

  useEffect(() => {
    if (questionRedeemableCheckRequest.isLoaded) {
      setQuestionInitialRedeemableState({
        cme: isQuestionRedeemableForCme,
        moc: isQuestionRedeemableForMoc,
        activityId: questionActivityId,
      })
    }
  }, [questionRedeemableCheckRequest.isLoaded, questionId])

  // scroll to center when redeemed if the scrollToCenterWhenRedeemded flag is set
  useEffect(() => {
    if (
      questionInitialRedeemableState.cme &&
      !isQuestionRedeemableForCme &&
      scrollToCenterWhenRedeemded
    ) {
      containerRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [isQuestionRedeemableForCme])

  const toggleExpansion = () => {
    if (!isDisabled) {
      setExpanded(!isExpanded)
    }
  }

  const redeemedMessage = useMemo(() => {
    if (isMocEnabledForUser) {
      if (
        !isQuestionRedeemableForMoc &&
        !isQuestionRedeemableForCme &&
        (questionInitialRedeemableState.moc ||
          questionInitialRedeemableState.cme)
      ) {
        return 'CME & MOC Redeemed'
      }

      if (!isQuestionRedeemableForCme && isQuestionRedeemableForMoc) {
        return 'CME Redeemed'
      }
    }

    if (!isQuestionRedeemableForCme && questionInitialRedeemableState.cme) {
      return 'Redeemed'
    }

    return null
  }, [
    isQuestionRedeemableForCme,
    isQuestionRedeemableForMoc,
    questionInitialRedeemableState,
    isMocEnabledForUser,
  ])

  const mocAssessmentTitle = useCallback(
    (board) => {
      return (
        <div>
          Redeem{' '}
          <span className={css.mocCredits}>
            {cmeConfig.credits_per_activity}
          </span>
          {board} MOC points
        </div>
      )
    },
    [cmeConfig.credits_per_activity]
  )

  const claimMocHandler = useCallback(() => {
    // scroll before openning modal, since the code in base modal scrolls to last offset before the modal was opened
    containerRef.current.scrollIntoView({
      block: 'center',
      inline: 'nearest',
    })
    openMocAssessmentModal({})
  }, [openMocAssessmentModal])

  const formClasses = classNames({
    [css.formCard]: true,
    [css.formCardExpanded]: isExpanded,
    [css.formCardCollapsed]: !isExpanded,
  })

  // We want to show something if question is redeemable or was redeemable when the user visited the page
  if (
    !isCmeOrMocAvailableForUser ||
    (!questionInitialRedeemableState.cme &&
      !questionInitialRedeemableState.moc &&
      !isQuestionRedeemableForMoc &&
      !isQuestionRedeemableForCme)
  ) {
    return null
  }

  return (
    <>
      <div ref={containerRef}>
        <Link2
          onClick={toggleExpansion}
          className={classNames({ [css.largeTitle]: largeTitle })}
        >
          <Button
            type="text"
            icon="award"
            isDisabled={isDisabled}
            iconProps={{ className: getClasses(isDisabled) }}
            className={getClasses(isDisabled)}
          >
            Redeem CME {isMocEnabledForUser && '& MOC'}
          </Button>
          <Icon
            icon="angle-down"
            prefix="fal"
            className={getClasses(isDisabled, {
              [css.arrowDown]: true,
              [css.arrowUp]: isExpanded,
            })}
          />
        </Link2>

        {!isQuestionRedeemableForCme &&
          (!isQuestionRedeemableForMoc || !isMocEnabledForUser) &&
          (questionInitialRedeemableState.cme ||
            questionInitialRedeemableState.moc) && (
            <div className={css.cmeRedeemedMessageContainer}>
              <RedeemedMessage redeemedMessage={redeemedMessage} />
            </div>
          )}

        {isMocEnabledForUser && isQuestionRedeemableForMoc && isExpanded && (
          <div className={css.cmeRedeemedMessageContainer}>
            <RedeemedMessage redeemedMessage={redeemedMessage} />
            {isMocEnabledForUser && isQuestionRedeemableForMoc && (
              <Button
                className={css.mocButton}
                // Not doing this will send the event as the modal data, and will cause warning from redux devtools about large redux action
                onClick={claimMocHandler}
              >
                <div className={css.mocButtonText}>Claim MOC</div>
              </Button>
            )}
          </div>
        )}
        {isQuestionRedeemableForCme && (
          <div className={formClasses}>
            {!isDisabled && (
              <Icon
                icon={['fal', 'times']}
                size="lg"
                className={css.closeWindowIcon}
                onClick={toggleExpansion}
              />
            )}
            <CmeAssessmentForm
              key={questionId} // Force form re-render when question changes (this is needed to reset filled values when question changes)
              vertical={vertical}
              handleFormSubmit={(
                formData,
                { goals, impacts, applications },
                callback
              ) => {
                redeemCmeActivity(formData, questionActivityId, (res) => {
                  callback(res)
                  mixpanel.track(
                    'redeemed_cme',
                    getSubmissionTrackingProps(
                      formData,
                      { goals, impacts, applications },
                      res
                    )
                  )
                })
              }}
            />
          </div>
        )}
      </div>
      {isMocEnabledForUser && cmeConfigRequest.isLoaded && (
        <MocAssessmentModal
          activities={[
            {
              cmeActivityId: questionInitialRedeemableState.activityId, // read it from initial state because once MOC is redeemed the Id will be lost
              contentType: CME_ACTIVITY_TYPE.QUESTION_VIEW,
              contentId: questionId,
            },
          ]}
          creditsPerActivity={cmeConfig.credits_per_activity}
          maxCreditsPerYear={cmeConfig.max_credits_per_year}
          location="Question Page"
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          isAfterCmeRedemption={questionInitialRedeemableState.cme}
          showRelatedQuestions
          title={mocAssessmentTitle}
          onExit={closeMocAssessmentModal}
        />
      )}
    </>
  )
}
