import _ from 'lodash'
import qs from 'qs'

export const IMAGE_SIZE = {
  HEADER: '',
  BIG: '',
  MEDIUM: '_50x50',
  SMALL: '_30x30',
}

export const makeQueryString = (newParams, existingParams = {}) => {
  const queryParameters = {
    ...existingParams,
    ...newParams,
  }

  const filteredParameters = _.pickBy(
    queryParameters,
    (value) => value !== undefined && value !== null
  )

  if (_.isEmpty(filteredParameters)) {
    return ''
  }

  return `?${qs.stringify(filteredParameters)}`
}

export const parseQueryString = (queryString) => {
  if (_.isEmpty(queryString)) {
    return {}
  }

  return qs.parse(queryString.slice(1))
}

export const makeDomainURL = (url = '') => {
  return `${process.env.WEB_SCHEME}://${process.env.WEB_DOMAIN}/${url}`
}

export const makeURL = (pathname, params, absolute = false, hash = '') => {
  const url = pathname + makeQueryString(params) + hash
  return absolute ? makeDomainURL(url) : url
}

export const updateURL = (pathname, params, existingParams) => {
  return pathname + makeQueryString(params, existingParams)
}

export const makeAssetURL = (asset) => {
  // this helps babel-plugin-transform-inline-environment-variables somehow
  const scheme = process.env.WEB_SCHEME
  return `${scheme}://${process.env.ASSET_HOSTNAME}/${asset}`
}

export const tagReleaseURL = (url) => {
  const [pathname, existingParams] = url.split('?')
  const releaseParams = { release: process.env.RELEASE_HASH }
  return updateURL(pathname, releaseParams, existingParams)
}

export const makeImageURL = (
  userId,
  imageVersion = null,
  imageSize = IMAGE_SIZE.BIG,
  imagePath = 'images/profile/'
) => {
  if (!imageVersion) {
    return makeAssetURL(`${imagePath}${userId}${imageSize}.jpg`)
  }
  return makeAssetURL(`${imagePath}${userId}_${imageVersion}${imageSize}.jpg`)
}

export const linkFromRoute = (route) => ({
  pathname: route.path,
  label: route.label,
})

export const refresh = () => {
  window.location.reload()
}

export const getPathnameFromRoute = (route, routeParams) => {
  return _.reduce(
    routeParams,
    (acc, val, key) => {
      return acc.replace(`:${key}`, val)
    },
    route.path
  )
}
