import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Link1, Header4, PageHeader } from 'pharmacy/src/typography'

import { mixpanel } from 'mednet-util/src/tracking'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  FETCH_RELATED_QUESTIONS,
  FETCH_CAMPAIGN_RELATED_QUESTIONS,
} from 'mednet-cns/src/reducers/question'
import { QuestionCard } from 'pharmacy/src/question/questionCard'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'

import Stub from '../../navigation/stub/stub'

import css from './relatedQuestions.scss'

class RelatedQuestions extends React.Component {
  renderTextItem = (stubbed) => (questionObject, questionPosition) => {
    const { question, questionId } = questionObject

    if (stubbed) {
      return <Stub className={css.textQuestion}>{question}</Stub>
    }

    return (
      <QuestionLink
        className={css.textQuestion}
        onClick={stubbed ? _.noop : this.handleClick}
        questionId={questionId}
        search={{ src: 'related_question_sidebar', pos: questionPosition }}
      >
        <Link1>{question}</Link1>
      </QuestionLink>
    )
  }

  renderFeedItem =
    (stubbed, useStarForFollow, enableProgramHighlight) =>
    (questionObject, questionPosition) => {
      return (
        <QuestionCard
          useStarForFollow={useStarForFollow}
          item={questionObject}
          enableProgramHighlight={enableProgramHighlight}
          questionLinkProps={{
            target: null,
            onClick: stubbed ? _.noop : this.handleClick,
            search: { src: 'related_question_feed', pos: questionPosition },
          }}
        />
      )
    }

  handleClick = (questionId) => {
    mixpanel.track('Clicked on Related Question', {
      to_question_id: questionId,
      from_question_id: this.props.questionId,
      type: this.props.type,
    })
  }

  render() {
    const {
      relatedQuestions,
      type,
      relatedQuestionsIsLoaded,
      stubbed,
      useStarForFollow,
      enableProgramHighlight,
    } = this.props

    const HeaderComponent = type === 'feed' ? PageHeader : Header4
    const headerClasses = classNames(css.header, css[`header_${type}`])
    const typeRenderer = {
      text: this.renderTextItem(stubbed),
      feed: this.renderFeedItem(
        stubbed,
        useStarForFollow,
        enableProgramHighlight
      ),
    }

    if (
      !relatedQuestionsIsLoaded ||
      !relatedQuestions ||
      !relatedQuestions.length
    ) {
      return null
    }

    return (
      <div>
        <HeaderComponent className={headerClasses}>
          Related Questions
        </HeaderComponent>
        {relatedQuestions.map((relatedQuestion, index) => {
          return (
            <ErrorBoundary key={relatedQuestion.questionId}>
              <div className={css[`questionContainer_${type}`]}>
                {typeRenderer[type](relatedQuestion, index + 1)}
              </div>
            </ErrorBoundary>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId, stubbed, hash } = ownProps
  const { relatedQuestions } = state.question.questions[questionId] || {}
  const relatedQuestionsRequest =
    (stubbed
      ? _.get(
          state.request.requests,
          makeRequestName(FETCH_CAMPAIGN_RELATED_QUESTIONS, hash)
        )
      : _.get(
          state.request.requests,
          makeRequestName(FETCH_RELATED_QUESTIONS, questionId)
        )) || {}

  return {
    relatedQuestions:
      relatedQuestions &&
      relatedQuestions.map(
        (questionId) => state.question.questions[questionId]
      ),
    relatedQuestionsIsLoaded: relatedQuestionsRequest.isLoaded,
  }
}

export default connect(mapStateToProps)(RelatedQuestions)
