import React from 'react'
import { connect } from 'react-redux'

import { questionStatus } from 'mednet-util/src/constants/question'
import { Body1 } from 'pharmacy/src/typography'

import css from './questionCreatedWarning.scss'

class QuestionCreatedWarning extends React.Component {
  render() {
    const { status, questionUserId, userId } = this.props
    if (status !== questionStatus.PENDING || questionUserId !== userId) {
      return null
    }

    return (
      <div className={css.container}>
        <Body1>Thank you, your question has been submitted for review.</Body1>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { status, userId: questionUserId } =
    state.question.questions[ownProps.questionId]

  const userData = state.user.data || {}

  return {
    status,
    questionUserId,
    userId: userData.userId,
  }
}

export default connect(mapStateToProps)(QuestionCreatedWarning)
