import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import {
  fetchSpecialties,
  fetchSpecialtiesTree,
} from 'mednet-cns/src/reducers/specialty'
import { createDataHook } from 'mednet-cns/src/hook-factory'

const grabSpecialty = (state) => state.specialty || {}

const renameTreeParams = (parent) => (element) => {
  const node = {}

  if (parent) {
    node.title = `${element.subspecialty} (${parent.specialty})`
    node.value = {
      subspecialtyId: element.subspecialtyId,
      specialtyId: parent.specialtyId,
    }
  } else {
    node.title = element.specialty
    node.value = {
      specialtyId: element.specialtyId,
    }

    if (element.subspecialties?.length) {
      node.options = sortBy(
        element.subspecialties.map(renameTreeParams(element)),
        'title'
      )

      node.options.unshift({
        title: node.title,
        value: node.value,
      })
    }
  }

  return node
}

export const [useSpecialtiesTreeOptions, useSpecialtiesTreeOptionsLazy] =
  createDataHook(
    fetchSpecialtiesTree,
    createSelector(grabSpecialty, (specialty) => {
      const tree = specialty.tree || []
      return sortBy(tree.map(renameTreeParams()), 'title')
    })
  )

export const [useSpecialties, useSpecialtiesLazy] = createDataHook(
  fetchSpecialties,
  createSelector(grabSpecialty, (specialty) => specialty.specialties || {})
)
