import { makeURL } from 'mednet-util/src/router'
import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_SUBSPECIALTY_TOPIC_MAPPINGS =
  'subspecialtyTopic/FETCH_SUBSPECIALTY_TOPIC_MAPPINGS'

export function fetchSubspecialtyTopics(callback, specialtyId) {
  return {
    type: FETCH_SUBSPECIALTY_TOPIC_MAPPINGS,
    callback,
    requestId: specialtyId,
    specialtyId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_SUBSPECIALTY_TOPIC_MAPPINGS,
    (action) =>
      makeURL(
        `specialty/getAvailableSubspecialtyTopicJSON/${action.specialtyId}`
      )
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  subspecialtyTopic: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_SUBSPECIALTY_TOPIC_MAPPINGS): {
      return receiveReducer(state, action, () => ({
        ...state.subspecialtyTopic,
        [action.specialtyId]: action.response.mappings,
      }))
    }

    default:
      return state
  }
}
