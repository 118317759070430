import { takeLatest, all } from 'redux-saga/effects'
import moment from 'moment-timezone'
import { isString, trim } from 'lodash'

import { makeURL } from 'mednet-util/src/router'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'
import { makePOSTHeadersFromParams } from '../api/v1'

export const JOB_TYPE = {
  CAMPAIGN: 'send-campaign',
  INVITE: 'main-invite',
  CHILDINVITE: 'send-invite',
  REMINDERINVITE: 'reminder-invite',
}

export const REMINDER_INTERVAL_TYPE = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
}

export const FETCH_SYSTEM_JOBS = 'systemjob/FETCH_SYSTEM_JOBS'
export const FETCH_SYSTEM_JOBS_DETAILS = 'systemjob/FETCH_SYSTEM_JOBS_DETAILS'
export const CREATE_CAMPAIGN_JOB = 'systemjob/CREATE_CAMPAIGN_JOB'
export const CREATE_INVITATION_JOB = 'systemjob/CREATE_INVITATION_JOB'
export const SEND_PREVIEW = 'systemjob/SEND_PREVIEW'
export const ABANDON_SYSTEM_JOB = 'systemjob/ABANDON_SYSTEM_JOB'
export const EDIT_SYSTEM_JOB = 'systemjob/EDIT_SYSTEM_JOB'

export function fetchSystemJobs(callback) {
  return {
    type: FETCH_SYSTEM_JOBS,
    callback,
  }
}

export function abandonSystemJob(id, callback) {
  return {
    type: ABANDON_SYSTEM_JOB,
    id,
    callback,
  }
}

export function sendPreview(id, to, callback) {
  return {
    type: SEND_PREVIEW,
    id,
    to,
    callback,
  }
}

export function editCampaignJob(data, callback) {
  return {
    type: EDIT_SYSTEM_JOB,
    data,
    callback,
  }
}

export function fetchSystemJobDetails({ id, requestId }, callback) {
  return {
    type: FETCH_SYSTEM_JOBS_DETAILS,
    id,
    requestId,
    callback,
  }
}

export function createCampaignJob(data, callback) {
  return {
    type: CREATE_CAMPAIGN_JOB,
    data,
    callback,
  }
}

export function createInvitationJob(data, callback) {
  return {
    type: CREATE_INVITATION_JOB,
    data,
    callback,
  }
}

function prepareActionData(action) {
  action.data.sendPreviewTo =
    !action.data.sendPreview || !isString(action.data.sendPreviewTo)
      ? []
      : action.data.sendPreviewTo
          .split(';')
          .map(trim)
          .filter((i) => i)
  action.data.schedule =
    action.data.schedule &&
    moment
      .tz(action.data.schedule, 'America/New_York')
      .utc()
      .format('YYYY-MM-DD HH:mm')
  action.data.recipients = action.data.recipients.map((recipient) => ({
    ...recipient,
    tree: JSON.stringify(recipient.tree),
  }))

  return action.data
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_SYSTEM_JOBS, () =>
    makeURL(`system/jobsJSON`)
  )

  yield makeFetchEffect(takeLatest, FETCH_SYSTEM_JOBS_DETAILS, ({ id }) =>
    makeURL(`system/jobFullDetailsJSON/${id}`)
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_CAMPAIGN_JOB,
    () => makeURL(`system/addCampaignJobJSON`),
    (action) => {
      return makePOSTHeadersFromParams(prepareActionData(action))
    }
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_INVITATION_JOB,
    () => makeURL(`system/addInvitationJobJSON`),
    (action) => {
      return makePOSTHeadersFromParams(prepareActionData(action))
    }
  )

  yield makeFetchEffect(
    takeLatest,
    EDIT_SYSTEM_JOB,
    (action) => makeURL(`system/editCampaignJobJSON/${action.data.id}`),
    (action) => {
      return makePOSTHeadersFromParams(prepareActionData(action))
    }
  )

  yield makeFetchEffect(
    takeLatest,
    ABANDON_SYSTEM_JOB,
    (action) => makeURL(`system/abandonJobJSON/${action.id}`),
    () => makePOSTHeadersFromParams({})
  )

  yield makeFetchEffect(takeLatest, SEND_PREVIEW, (action) =>
    makeURL(`system/sendPreviewJSON/${action.id}`, { to: action.to })
  )
}
export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  systemjobs: [],
  details: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(CREATE_CAMPAIGN_JOB):
    case receiveAction(CREATE_INVITATION_JOB):
    case receiveAction(EDIT_SYSTEM_JOB):
    case receiveAction(FETCH_SYSTEM_JOBS): {
      return receiveReducer(state, action, () => ({
        systemjobs: action.response.systemjobs.map((systemjob) => {
          systemjob.schedule = !systemjob.schedule
            ? ''
            : moment
                .utc(systemjob.schedule)
                .tz('America/New_York')
                .format('YYYY-MM-DD HH:mm')
          systemjob.start = !systemjob.start
            ? ''
            : moment
                .utc(systemjob.start)
                .tz('America/New_York')
                .format('YYYY-MM-DD HH:mm')
          systemjob.end = !systemjob.end
            ? ''
            : moment
                .utc(systemjob.end)
                .tz('America/New_York')
                .format('YYYY-MM-DD HH:mm')
          return systemjob
        }),
      }))
    }

    case receiveAction(FETCH_SYSTEM_JOBS_DETAILS): {
      return receiveReducer(state, action, () => {
        const systemjob = action.response.systemjob
        systemjob.schedule = !systemjob.schedule
          ? ''
          : moment
              .utc(systemjob.schedule)
              .tz('America/New_York')
              .format('YYYY-MM-DD HH:mm')
        systemjob.start = !systemjob.start
          ? ''
          : moment
              .utc(systemjob.start)
              .tz('America/New_York')
              .format('YYYY-MM-DD HH:mm')
        systemjob.end = !systemjob.end
          ? ''
          : moment
              .utc(systemjob.end)
              .tz('America/New_York')
              .format('YYYY-MM-DD HH:mm')
        systemjob.campaign.recipients = systemjob.campaign.recipients.map(
          (group) => ({
            ...group,
            label: `${group.name} (${group.userIds.length})`,
            id: group.recipientGroupId,
            selected: group.userIds.reduce((mapped, id) => {
              mapped[id] = true
              return mapped
            }, {}),
          })
        )
        return {
          details: {
            ...state.details,
            [action.response.systemjob.id]: systemjob,
          },
        }
      })
    }

    case receiveAction(ABANDON_SYSTEM_JOB): {
      return receiveReducer(state, action, () => {
        const stateSystemJobs = state.systemjobs
        const index = stateSystemJobs.findIndex((job) => job.id === action.id)

        if (index > -1) {
          stateSystemJobs[index].status = 'abandoned'
        }

        return { systemjobs: [...stateSystemJobs] }
      })
    }

    default:
      return state
  }
}
