import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connectAutoComplete } from 'react-instantsearch-dom'

import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'

import css from './questionAutoComplete.scss'

class QuestionAutoComplete extends React.Component {
  state = {
    value: this.props.currentRefinement,
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  onSuggestionsClearRequested = () => {
    this.props.refine()
  }

  getSuggestionValue(hit) {
    return hit.question
  }

  onSuggestionClick = (hit) => () => {
    this.setState({
      value: hit.question,
    })
    this.props.onSuggestionSelect(hit)
  }

  renderSuggestion = (hit) => {
    const highlightResult = hit._highlightResult
    const hitContent = highlightResult.question.value

    return (
      <Link
        className={css.suggestionLink}
        onClick={this.onSuggestionClick(hit)}
      >
        <Subtitle2>
          <div
            className={css.suggestionLinkContent}
            dangerouslySetInnerHTML={{ __html: hitContent }}
          />
        </Subtitle2>
      </Link>
    )
  }

  getSectionSuggestions(section) {
    return section.hits
  }

  renderInputComponent = (inputProps) => {
    return (
      <div className={css.inputContainer}>
        <Icon className={css.inputIcon} icon="search" />
        <input {...inputProps} />
      </div>
    )
  }

  render() {
    const { hits } = this.props
    const { value } = this.state

    const inputProps = {
      placeholder: 'Search for a question',
      onChange: this.onChange,
      value,
    }

    const theme = {
      sectionContainer: css.sectionContainer,
      sectionContainerFirst: css.sectionContainerFirst,
      sectionTitle: css.sectionTitle,
      suggestion: css.suggestion,
      suggestionsContainer: css.suggestionsContainer,
      suggestionsList: css.suggestionsList,
      input: css.input,
    }

    return (
      <Autosuggest
        focusInputOnSuggestionClick={false}
        getSectionSuggestions={this.getSectionSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderInputComponent={this.renderInputComponent}
        renderSuggestion={this.renderSuggestion}
        suggestions={hits}
        theme={theme}
      />
    )
  }
}

export default connectAutoComplete(QuestionAutoComplete)
