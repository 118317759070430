/* eslint-disable react/display-name */
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useTable, useGroupBy, useExpanded, useSortBy } from 'react-table'
import { useDispatch } from 'react-redux'

import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { openModal } from 'mednet-cns/src/reducers/modal'
import {
  SPONSORSHIP_NOTIFICATIONS_MODAL,
  SPONSORSHIP_PREVIEW_UPDATE_MODAL,
} from 'mednet-util/src/constants/modal'

import PreviewUpdateModal from '../modals/previewUpdate'
import PreviewNotificationListModal from '../modals/previewNotificationList'

import css from './injectedContentStats.scss'

function Table({ columns, data, isLoading, isHeaderLoading }) {
  const initialState = useMemo(() => ({
    groupBy: ['sponsorshipId', 'questionId'],
    sortBy: [
      { id: 'releaseDate', desc: false },
      { id: 'order', desc: false },
    ],
    hiddenColumns: ['releaseDate', 'order'],
  }))

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState,
        autoResetExpanded: false,
        autoResetSortBy: false,
      },
      useGroupBy,
      useSortBy,
      useExpanded
    )

  return (
    <>
      <table className={css.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={i}
              className={headerGroup.className}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, j) => (
                <th
                  key={j}
                  className={column.className}
                  {...column.getHeaderProps()}
                >
                  {i === 0 && j === 0 && isHeaderLoading && (
                    <StarLoader size="small" className={css.headerSpinner} />
                  )}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <tr
            className={classNames(
              { [css.isNotLoading]: !isLoading },
              css.bodySpinnerWrapper
            )}
          >
            <td>
              <StarLoader className={css.bodySpinner} />
            </td>
          </tr>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr className={css.row} key={i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td className={css.cell} key={j} {...cell.getCellProps()}>
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          {cell.column.id === 'questionId' &&
                          row.subRows.length === 1 ? (
                            ''
                          ) : (
                            <span
                              className={css.expander}
                              {...row.getToggleRowExpandedProps()}
                            >
                              {row.isExpanded ? (
                                <Icon icon="caret-up" />
                              ) : (
                                <Icon icon="caret-down" />
                              )}
                            </span>
                          )}{' '}
                          {cell.render('Cell')}
                        </>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? (
                        cell.render('Aggregated') // For cells with repeated values, render Aggregated and decide what to do there
                      ) : (
                        // Otherwise, just render the regular cell
                        cell.render('Cell')
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

function UpdateCell({ value, isPoll }) {
  const dispatch = useDispatch()

  const openPreviewUpdateModal = (value) => () =>
    dispatch(
      openModal(SPONSORSHIP_PREVIEW_UPDATE_MODAL.modalId, { updateId: value })
    )

  return !value ? (
    'question'
  ) : (
    <>
      <Link onClick={openPreviewUpdateModal(value)}>
        {!isPoll ? null : <Icon icon="poll" />} {value}
      </Link>
    </>
  )
}

function QuestionCell({ value }) {
  return (
    <Link pathname={`/question/${value}`} target="_blank">
      {value}
    </Link>
  )
}

function Total({
  date,
  sponsorshipId,
  questionId,
  updateId,
  value,
  notificationType,
  groupByID,
  params,
}) {
  const dispatch = useDispatch()
  const paramsToSend = {
    sponsorshipId,
    date,
    notificationType,
    ...params,
  }

  if (groupByID === 'questionId' || !groupByID) {
    paramsToSend.questionId = questionId
  }

  if (!groupByID) {
    paramsToSend.updateId = updateId
  }

  const openNotificationsModal = () =>
    dispatch(openModal(SPONSORSHIP_NOTIFICATIONS_MODAL.modalId, paramsToSend))

  return <Link onClick={openNotificationsModal}>{value}</Link>
}

export default function InjectedContentStats({
  from,
  data,
  headerData,
  sponsorships,
  notificationType,
  isLoading,
  isHeaderLoading,
  noOfDays,
  params,
}) {
  const reducer = useCallback(
    (dateStr) =>
      _.reduce(
        data,
        ([total, opened, clicked], row) => {
          if (row.dates && row.dates[dateStr]) {
            return [
              total + row.dates[dateStr].total,
              opened + row.dates[dateStr].openings,
              clicked + row.dates[dateStr].clicks,
            ]
          }
          return [total, opened, clicked]
        },
        [0, 0, 0]
      ),
    [data]
  )
  const notificationTypeStr =
    `${notificationType}` === '3' ? 'digests' : 'newsletters'
  const { volume } = headerData
  const columns = [
    {
      Header: '',
      className: css.mainHeader,
      id: 'main',
      columns: [
        {
          Header: 'Release Date',
          accessor: 'releaseDate',
          aggregate: (leafValues) => leafValues[0],
        },
        {
          Header: 'Order',
          accessor: 'order',
          aggregate: (leafValues) => leafValues[0],
        },
        {
          Header: 'Sponsorship',
          accessor: 'sponsorshipId',
          className: classNames(css.headerCell, css.sponsorship),
          Aggregated: () => null,
          Cell: ({ value }) =>
            !(sponsorships && sponsorships[value]) ? (
              value
            ) : (
              <span>
                <Link
                  title={sponsorships[value].description}
                  target="_blank"
                  pathname={`/sponsorship/view/${sponsorships[value].sponsorshipId}`}
                >
                  {sponsorships[value].description}
                </Link>
                <br />
                <span
                  className={css.releaseDate}
                  title={sponsorships[value].releaseDate}
                >
                  {moment(sponsorships[value].releaseDate).format('YYYY-MM-DD')}
                </span>
              </span>
            ),
        },
        {
          Header: 'Question',
          accessor: 'questionId',
          className: classNames(css.headerCell, css.question),
          Cell: ({ value }) => <QuestionCell value={value} />,
          aggregate: 'uniqueCount',
          Aggregated: ({ row: { leafRows, values } }) => {
            if (!leafRows) {
              return null
            }

            const rows = _.uniqBy(leafRows, 'values.questionId')
            return rows.length === 1 ? (
              <QuestionCell value={values.questionId} />
            ) : (
              `${rows.length} questions`
            )
          },
        },
        {
          Cell: ({
            value,
            row: {
              original: { poll },
            },
          }) => <UpdateCell value={value} isPoll={poll} />,
          Header: 'Update',
          accessor: 'updateId',
          className: classNames(css.headerCell, css.update),
          aggregate: 'count',
          Aggregated: ({ value, row: { leafRows } }) =>
            leafRows.length === 1 ? (
              <UpdateCell value={leafRows[0].values.updateId} />
            ) : (
              `${value} updates`
            ),
        },
      ],
    },
  ]

  const calculatePercentage = (count, total) =>
    `${_.round((count * 100) / total, 2)}%`
  const getData = (date, name) => (row) => _.get(row, ['dates', date, name], '')
  const computeRate = (date, name) => (row) => {
    const count = getData(date, name)(row)
    const total = getData(date, 'total')(row)
    return {
      count,
      total,
      rate: count === '' ? '' : calculatePercentage(count, total),
    }
  }

  const aggregateTotal = (leafValues) => {
    const values = leafValues.filter((value) => value !== '')
    return !values.length ? '' : _.sum(values)
  }

  const aggregateRates = (leafValues, aggregatedValues) => {
    const values = aggregatedValues.filter(
      (value) => value && value.rate !== ''
    )

    if (!values.length) {
      return { count: '', total: '', rate: '' }
    }

    const total = _.sum(values.map((value) => value.total))
    const count = _.sum(values.map((value) => value.count))

    return {
      total,
      count,
      rate: calculatePercentage(count, total),
    }
  }

  const validatedNoOfDays = Math.max(Math.min(noOfDays, 7), 0)
  for (let i = 0; i < validatedNoOfDays; i++) {
    const headerDate = new Date(from)
    const date = moment(headerDate.setDate(from.getDate() + i)).format(
      'YYYY-MM-DD'
    )
    const [total, opened, clicked] = reducer(date)
    columns.push({
      Header: (
        <>
          <span>{date}</span>
          {volume &&
            !isHeaderLoading &&
            volume[date] &&
            volume[date][notificationTypeStr] !== undefined && (
              <>
                <br />
                <span className={css.totalVol}>
                  Total volume: {volume[date][notificationTypeStr]}
                </span>
              </>
            )}
        </>
      ),
      id: date,
      className: css.date,
      columns: [
        {
          className: classNames(css.headerCell, css.first),
          Header: (
            <>
              <span>#</span>
              <br />
              <span className={css.subheader}>{!total ? null : total}</span>
            </>
          ),
          accessor: getData(date, 'total'),
          Cell: ({ row: { values, groupByID }, value }) => {
            return (
              <Total
                value={value}
                questionId={values.questionId}
                date={date}
                sponsorshipId={values.sponsorshipId}
                updateId={values.updateId}
                notificationType={notificationType}
                groupByID={groupByID}
                params={params}
              />
            )
          },
          id: `${date}-total`,
          aggregate: aggregateTotal,
        },
        {
          className: classNames(css.headerCell),
          Cell: ({ value }) => (!value || !value.rate ? null : value.rate),
          Header: (
            <>
              <span>OR%</span>
              <br />
              <span className={css.subheader}>
                {!total ? null : calculatePercentage(opened, total)}
              </span>
            </>
          ),
          accessor: computeRate(date, 'openings'),
          id: `${date}-openRate`,
          aggregate: aggregateRates,
        },
        {
          className: classNames(css.headerCell, css.last),
          Cell: ({ value }) => (!value || !value.rate ? null : value.rate),
          Header: (
            <>
              <span>CR%</span>
              <br />
              <span className={css.subheader}>
                {!total ? null : calculatePercentage(clicked, total)}
              </span>
            </>
          ),
          accessor: computeRate(date, 'clicks'),
          id: `${date}-clickRate`,
          aggregate: aggregateRates,
        },
      ],
    })
  }

  return (
    <>
      <PreviewUpdateModal />
      <PreviewNotificationListModal />
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        isHeaderLoading={isHeaderLoading}
      ></Table>
    </>
  )
}
