import React from 'react'
import moment from 'moment-timezone'

const DateCell = ({ date }) => {
  return (
    <span>
      {moment.tz(date, 'America/New_York').format('YYYY-MM-DD HH:mm')}
    </span>
  )
}

export default DateCell
