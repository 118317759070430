module.exports = {
  colorBlue: '#3F49A7',
  colorBlueBright: '#2979FF',
  colorBlueGray: '#465774',

  colorGrayBorder: '#E5E5E5',
  colorGrayBackground: '#F5F7F8',
  colorGrayText: '#727C8D',

  colorBlackMain: '#1A1A1A',
  colorBlackSecondary: '#404040',

  colorWhiteBackground: '#FAFAFA',

  colorRedWarning: '#DC4A3E',
  colorYellowWarning: '#F3D34A',
  colorGreenStatus: '#59CD90',
}
