import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link1, Header1, Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { ConfirmationModal } from 'pharmacy/src/display/modal'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { RawBody } from 'pharmacy/src/display/rawBody'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  FETCH_QUESTION_METADATA,
  fetchQuestionAnswers,
  fetchQuestionMetadata,
} from 'mednet-cns/src/reducers/question'
import { deleteAnswer } from 'mednet-cns/src/reducers/answer'
import { DELETE_ANSWER_MODAL } from 'mednet-util/src/constants/modal'
import { Page } from 'pharmacy/src/display/page'

import MoveAnswerModal from 'pages/manageQuestionAnswers/moveAnswerModal'

import ManageAnswersTable from './manageAnswersTable'

import css from './manageQuestionAnswersPage.scss'

class ManageQuestionAnswersPage extends React.Component {
  componentDidMount() {
    this.props.fetchQuestionMetadata()
    this.props.fetchQuestionAnswers()
  }

  handleDelete = ({ closeModal, answerId }) => {
    const { questionId, deleteAnswer } = this.props
    deleteAnswer(questionId, answerId)
    closeModal()
  }

  render() {
    const { metadataIsLoaded } = this.props

    if (!metadataIsLoaded) {
      return <StarLoader />
    }

    const { questionId, question, detail, created, userId } = this.props

    return (
      <Page>
        <div className={css.content}>
          <Link
            external
            pathname="/question/moderatorpage"
            className={css.backLink}
          >
            <Link1>
              {'< '}
              Back to Moderator Page
            </Link1>
          </Link>

          <Header1>Review Answers</Header1>
          <QuestionLink
            questionId={questionId}
            target="_blank"
            className={css.questionLink}
          >
            <Header2>{question}</Header2>
          </QuestionLink>
          <RawBody body={detail} />
          <Subtitle2 className={css.created}>
            Created on {moment(created).format('LL')} by{' '}
            <UserNameLink userId={userId} className={css.createdBy} />
          </Subtitle2>
          <div className={css.tableContainer}>
            <ManageAnswersTable questionId={questionId} />
          </div>
        </div>
        <ConfirmationModal
          closeText="Cancel"
          closeType="neutral"
          header="Are you sure you want to delete this answer?"
          modalId={DELETE_ANSWER_MODAL.modalId}
          onSubmit={this.handleDelete}
          submitText="Delete"
          submitType="destructive"
        />
        <MoveAnswerModal questionId={questionId} />
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps.match.params
  const { question, detail, created, userId } =
    state.question.questions[questionId] || {}
  const metadataRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_QUESTION_METADATA, questionId)
    ) || {}

  return {
    questionId,
    question,
    detail,
    userId,
    created,
    metadataIsLoaded: metadataRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId } = ownProps.match.params

  return {
    fetchQuestionMetadata: () => dispatch(fetchQuestionMetadata(questionId)),
    fetchQuestionAnswers: () =>
      dispatch(fetchQuestionAnswers(questionId, { includePeerReview: 1 })),
    deleteAnswer: (questionId, answerId) =>
      dispatch(deleteAnswer(questionId, answerId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageQuestionAnswersPage)
