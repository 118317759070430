import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchUpdate as fetchUpdateAction,
  getIsFetchUpdateLoading,
  getUpdate,
} from 'mednet-cns/src/reducers/questionUpdate'

import { SPONSORSHIP_PREVIEW_UPDATE_MODAL } from 'mednet-util/src/constants/modal'

import { Modal } from 'pharmacy/src/display/modal'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import UpdateCard from 'components/updateCard/updateCard'

function PreviewUpdateScreen({ updateId }) {
  const dispatch = useDispatch()

  const isFetchUpdateLoading = useSelector((state) =>
    getIsFetchUpdateLoading(state, updateId)
  )
  const update = useSelector((state) => getUpdate(state, updateId))

  const fetchUpdate = () => dispatch(fetchUpdateAction(updateId))

  useEffect(() => {
    if (!update) {
      fetchUpdate()
    }
  }, [updateId, update])

  if (isFetchUpdateLoading || !update) {
    return <StarLoader />
  }

  return <UpdateCard update={update} hideInfo />
}

const screens = {
  [SPONSORSHIP_PREVIEW_UPDATE_MODAL.screens.preview]: PreviewUpdateScreen,
}

export default function PreviewUpdateModal(props) {
  return (
    <Modal
      modalId={SPONSORSHIP_PREVIEW_UPDATE_MODAL.modalId}
      defaultScreen={SPONSORSHIP_PREVIEW_UPDATE_MODAL.screens.preview}
      screens={screens}
      {...props}
    />
  )
}
