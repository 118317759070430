import React, { useCallback, useState } from 'react'
import moment from 'moment'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { Modal } from 'pharmacy/src/display/modal'
import { Header2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { TextInput } from 'pharmacy/src/input/textInput'
import { DayMonthPicker } from 'pharmacy/src/input/datePicker'

import { CME_CERTIFICATE_MODAL } from 'mednet-util/src/constants/modal'
import { CME_CERTIFICATE_TYPE } from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'

import { useCreateCmeCertificate } from 'mednet-cns/src/hooks/cme'
import { useUserMocProfile } from 'mednet-cns/src/hooks/user'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import css from './cmeCertificateModal.scss'

const validationSchema = yup.object().shape({
  abimNumber: yup.string().when(['types'], {
    is: (types) => types?.includes(CME_CERTIFICATE_TYPE.ABIM_MOC),
    then: yup
      .string()
      .required('Required for ABIM')
      .matches(/^\d{6}$/, 'Invalid ABIM'),
  }),
  abpNumber: yup.string().when(['types'], {
    is: (types) => types?.includes(CME_CERTIFICATE_TYPE.ABP_MOC),
    then: yup
      .string()
      .required('Required for ABP')
      .matches(/^\d{4,}$/, 'Invalid ABP'),
  }),
  birthDate: yup
    .string()
    .required('Required')
    .matches(/^\d{2}\/\d{2}$/, 'Should be MM/DD'),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const TextInputField = ({ name, label, touched, errors, ...restProps }) => (
  <div className={css.textFieldContainer}>
    <div className={css.textFieldLabel}>{label}</div>
    <Field name={name}>
      {({ field }) => (
        <TextInput
          {...field}
          {...restProps}
          typeStyle="secondary"
          showClear={false}
          onChangeHandlesEvent
          placeholder="xxxxxx"
        />
      )}
    </Field>
    <div className={css.fieldError}>
      {getValidationTag(name, touched, errors)}
    </div>
  </div>
)

const CertificateTypeCheckboxField = ({ label, value }) => {
  return (
    <div className={css.certificateTypeFieldContainer}>
      <Field type="checkbox" name="types" value={value} checked />
      {label}
    </div>
  )
}

const BirthDateField = ({ setFieldValue, values, touched, errors }) => {
  const fieldName = 'birthDate'

  const handleBirthDateChange = useCallback(
    (value) => {
      setFieldValue(fieldName, moment(value).format('MM/DD'))
    },
    [setFieldValue]
  )

  return (
    <div className={css.datePicker}>
      <div className={css.textFieldLabel}>Date of Birth</div>
      <Field name={fieldName}>
        {({ field }) => (
          <DayMonthPicker
            inputProps={{ name: field.name, readOnly: true }}
            onChange={handleBirthDateChange}
            stringValue={values[fieldName]}
            noDefaultValue={isEmpty(values[fieldName])}
          />
        )}
      </Field>
      <div className={css.fieldError}>
        {getValidationTag(fieldName, touched, errors)}
      </div>
    </div>
  )
}

const CertificateForm = (props) => {
  const {
    isSubmitting,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    submissionResult,
    certificateTypes,
  } = props

  return (
    <Form>
      {certificateTypes.includes(CME_CERTIFICATE_TYPE.CME) && (
        <div className={css.formOptionGroup}>
          <CertificateTypeCheckboxField
            label={<>CME: AMA PRA Category 1 Credits&trade;</>}
          />
        </div>
      )}

      {certificateTypes.includes(CME_CERTIFICATE_TYPE.ABIM_MOC) && (
        <div className={css.formOptionGroup}>
          <CertificateTypeCheckboxField label="MOC: ABIM" />
          <div className={css.row}>
            <TextInputField
              name="abimNumber"
              label="ABIM Number:"
              touched={touched}
              errors={errors}
            />
            <BirthDateField
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              errors={errors}
            />
          </div>
        </div>
      )}

      {certificateTypes.includes(CME_CERTIFICATE_TYPE.ABP_MOC) && (
        <div className={css.formOptionGroup}>
          <CertificateTypeCheckboxField label="MOC: ABP" />
          <div className={css.row}>
            <TextInputField
              name="abpNumber"
              label="ABP Number:"
              touched={touched}
              errors={errors}
            />
            <BirthDateField
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              errors={errors}
            />
          </div>
        </div>
      )}

      <div className={css.buttonContainer}>
        {submissionResult === false && (
          <div className={css.submittionErrorMessage}>Something went wrong</div>
        )}
        <Button
          className={css.button}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          Create Certificate(s)
        </Button>
      </div>
    </Form>
  )
}

const CertificatePrompt = (props) => {
  const { certificateTypes, certificateActivitiesIds, history } = props

  const [userData, userRequest] = useUserMocProfile()
  const createCmeCertificate = useCreateCmeCertificate()
  const [submissionResult, setSubmissionResult] = useState(undefined)

  const initialValues = {
    abimNumber: userData.abim ?? '',
    abpNumber: userData.abp ?? '',
    birthDate: userData.birthDate ?? '',
    types: certificateTypes,
    updateMocProfile: true,
  }

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      mixpanel.track('Clicked Create CME Certificate From Modal', {})

      createCmeCertificate(values, certificateActivitiesIds, (res) => {
        setSubmitting(false)
        if (res.success) {
          if (values.types.includes(CME_CERTIFICATE_TYPE.CME)) {
            mixpanel.track('created_cme_certificate')
          }

          if (values.types.includes(CME_CERTIFICATE_TYPE.ABIM_MOC)) {
            mixpanel.track('created_abim_moc_certificate')
          }

          if (values.types.includes(CME_CERTIFICATE_TYPE.ABP_MOC)) {
            mixpanel.track('created_abp_moc_certificate')
          }

          history.push('/cme?tab=Certificates')
        } else {
          setSubmissionResult(res.success)
        }
      })
    },
    [createCmeCertificate, history, setSubmissionResult]
  )

  if (!userRequest.isLoaded) {
    return <StarLoader />
  }

  return (
    <div>
      <Header2>Certificate Type(s)</Header2>
      <hr />

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {(props) => (
          <CertificateForm
            {...props}
            submissionResult={submissionResult}
            certificateTypes={certificateTypes}
          />
        )}
      </Formik>
    </div>
  )
}

export const CmeCertificateModal = (props) => {
  return (
    <Modal
      modalId={CME_CERTIFICATE_MODAL.modalId}
      closeOnBackdrop
      {...props}
      contentClassName={css.modalContainer}
    >
      <CertificatePrompt />
    </Modal>
  )
}
