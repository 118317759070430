import React from 'react'

import TabContext from './tabContext'

const createTabsComponent = ({ ComponentsContainer }) => {
  return class TabComponent extends React.Component {
    render() {
      return (
        <TabContext.Consumer>
          {({ tabs, activeTab }) => (
            <ComponentsContainer>
              {tabs[activeTab].component}
            </ComponentsContainer>
          )}
        </TabContext.Consumer>
      )
    }
  }
}

export default createTabsComponent
