import React from 'react'

import { Field, Formik } from 'formik'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { QUESTION_REQUEST_MODAL } from 'mednet-util/src/constants/modal'

import css from './requestTypeScreen.scss'

class RequestTypeScreen extends React.Component {
  constructor(props) {
    super(props)

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit(values) {
    if (values.requestType === 'more') {
      this.props.changeModalScreen(QUESTION_REQUEST_MODAL.screens.moreAnswers)
    } else if (values.requestType === 'outdated') {
      this.props.changeModalScreen(
        QUESTION_REQUEST_MODAL.screens.updatedAnswers
      )
    }
  }

  render() {
    const { closeModal } = this.props
    return (
      <div className={css.container}>
        <Header2>Describe your request</Header2>
        <hr />

        <Formik initialValues={{}} onSubmit={this.handleFormSubmit}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={css.option}>
                <Field
                  type="radio"
                  name="requestType"
                  id="requestType_more"
                  value="more"
                />
                <label htmlFor="requestType_more">
                  <Subtitle2>I want more opinions</Subtitle2>
                </label>
              </div>

              <div className={css.option}>
                <Field
                  type="radio"
                  name="requestType"
                  id="requestType_outdated"
                  value="outdated"
                />
                <label htmlFor="requestType_outdated">
                  <Subtitle2>I want more updated answers</Subtitle2>
                </label>
              </div>

              <div className={css.buttonContainer}>
                <Button type="neutral" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  className={css.submitButton}
                  isDisabled={!values.requestType}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  }
}

export default RequestTypeScreen
