import React from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { overrideStyles } from './components'

class BaseSelect extends React.Component {
  render() {
    const { creatable, async, styleOverrides, ...restProps } = this.props

    let SelectComponent
    if (creatable && async) {
      SelectComponent = AsyncCreatableSelect
    } else if (creatable) {
      SelectComponent = CreatableSelect
    } else if (async) {
      SelectComponent = AsyncSelect
    } else {
      SelectComponent = ReactSelect
    }

    return (
      <SelectComponent styles={overrideStyles(styleOverrides)} {...restProps} />
    )
  }
}

export default BaseSelect
