export const CME_ACTIVITY_TYPE = {
  PUBLICATION_VIEW: 'P',
  QUESTION_VIEW: 'Q',
  QUESTION_ANSWER: 'A',
}

export const CME_ACTIVITY_TYPE_LABEL = {
  [CME_ACTIVITY_TYPE.PUBLICATION_VIEW]: 'Viewed Reference',
  [CME_ACTIVITY_TYPE.QUESTION_VIEW]: 'Viewed Question',
  [CME_ACTIVITY_TYPE.QUESTION_ANSWER]: 'Answered Question',
}

export const CME_REDEMPTION_TYPE = {
  CME: 'C',
  MOC: 'M',
}

export const CME_REDEMPTION_TYPE_LABEL = {
  [CME_REDEMPTION_TYPE.CME]: 'CME',
  [CME_REDEMPTION_TYPE.MOC]: 'MOC',
}

export const CME_CERTIFICATE_TYPE = {
  CME: 'E',
  ABIM_MOC: 'I',
  ABP_MOC: 'P',
}

export const CME_CERTIFICATE_TYPE_LABEL = {
  [CME_CERTIFICATE_TYPE.CME]: 'CME',
  [CME_CERTIFICATE_TYPE.ABIM_MOC]: 'MOC ABIM',
  [CME_CERTIFICATE_TYPE.ABP_MOC]: 'MOC ABP',
}

// Basically all activities must be redeemed by December 31 of each calendar year. However, for any activities
// from October - December, we give users a 3-month buffer to redeem them by March 31 of the following year.
const currentDate = new Date()
export const MIN_ACTIVE_ACTIVITY_DATE =
  currentDate.getMonth() >= 3 // If we passed the first 3 months of the current year
    ? new Date(currentDate.getFullYear(), 0, 1) // 1 Jan of the current year
    : new Date(currentDate.getFullYear() - 1, 9, 1) // 1 Oct of the last year

export const CME_PAGE_VISIT_SOURCE = {
  SOURCE_EMAIL: 'E',
  SOURCE_PROFILE: 'P',
  SOURCE_MENU: 'M',
  SOURCE_STATS: 'S',
  SOURCE_NONE: 'N',
}

export const MOC_STATUS = {
  MOC_DISABLED: 0,
  MOC_ABIM_ENABLED: 1,
  MOC_ABP_ENABLED: 2,
  BOTH_MOC_ENABLED: 3,
}
