import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import classNames from 'classnames'

import {
  fetchSpecialties as fetchSpecialtiesAction,
  getAreSpecialtiesLoading,
  getSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import {
  fetchSpecialtySubspecialties as fetchSubspecialtiesAction,
  getAreSubSpecialtiesLoading,
  getSubSpecialties,
} from 'mednet-cns/src/reducers/subspecialty'
import {
  getSpecialtyTopics,
  fetchTopics as fetchTopicsAction,
} from 'mednet-cns/src/reducers/topic'

import { SimpleSelect } from 'pharmacy/src/input/select'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import { DatePicker } from 'pharmacy/src/input/datePicker'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { TagSearch } from 'pharmacy/src/display/tagSearch'

import css from './injectedContentDashboard.scss'

export default function StatsFilterHeader({
  onChange,
  sponsorships,
  type: initialType,
  noOfDays: initialNoOfDays,
  date: initialDate,
  sponsorshipId: initialSponsorshipId,
  specialtyId: initialSpecialtyId,
  subspecialtiesIds: initialSubspecialtiesIds,
  usersIds: initialUsersIds,
  topicsIsd: initialTopicsIds,
}) {
  const dispatch = useDispatch()

  //Component state
  const [usersFiltering, setUsersFiltering] = useState(0)
  const [usersSecondaryFiltering, setUsersSecondaryFiltering] = useState(0)
  const [type, setType] = useState(initialType)
  const [noOfDays, setNoOfDays] = useState(initialNoOfDays)
  const [date, setDate] = useState(initialDate)
  const [sponsorshipId, setSponsorshipId] = useState(initialSponsorshipId)
  const [specialtyId, setSpecialtyId] = useState(initialSpecialtyId)
  const [subspecialtiesIds, setSubspecialtiesIds] = useState(
    initialSubspecialtiesIds
  )
  const [usersIds, setUsersIds] = useState(initialUsersIds)
  const [topicsIds, setTopicsIds] = useState(initialTopicsIds)

  useEffect(() => setDate(initialDate), [initialDate])

  //Dependencies from state
  const specialties = useSelector((state) => getSpecialties(state))
  const subspecialties = useSelector((state) =>
    getSubSpecialties(state, specialtyId)
  )
  const topics = useSelector((state) => getSpecialtyTopics(state, specialtyId))

  //Options for dropdowns
  const typeOptions = useMemo(() => [
    {
      label: 'Daily digests',
      value: '3',
    },
    {
      label: 'Newsletters',
      value: 'N',
    },
  ])
  const noOfDaysOptions = useMemo(() =>
    _.range(1, 8).map((i) => ({
      value: i,
      label: `${i} day${i === 1 ? '' : 's'}`,
    }))
  )
  const sponsorshipOptions = useMemo(
    () => [
      { label: 'all active sponsorships', value: 0 },
      ..._.map(sponsorships, ({ description, sponsorshipId }) => ({
        label: description,
        value: `${sponsorshipId}`,
      })),
    ],
    [sponsorships]
  )
  const usersFilteringOptions = useMemo(() => [
    { label: 'all users', value: 0 },
    { label: 'users from specialty:', value: 1 },
    { label: 'user:', value: 2 },
  ])
  const usersSecondaryFilteringOptions = useMemo(() => [
    { label: 'all', value: 0 },
    { label: 'in any of subspecialties:', value: 1 },
    { label: 'who follow any of topics:', value: 2 },
    {
      label: 'who saw 3 q&a in the last year with topics:',
      value: 3,
    },
  ])
  const specialtiesOptions = useMemo(
    () => [
      { label: 'any', value: null },
      ..._.sortBy(
        _.map(specialties, (specialty) => ({
          label: specialty.specialty,
          value: specialty.specialtyId,
        })),
        ['label']
      ),
    ],
    [specialties]
  )
  const subspecialtiesOptions = useMemo(
    () =>
      _.sortBy(
        _.map(subspecialties, (subspecialty) => ({
          label: subspecialty.subspecialty,
          value: subspecialty.subspecialtyId,
        })),
        ['label']
      ),
    [subspecialties]
  )
  const topicsOptions = useMemo(
    () =>
      _.sortBy(
        _.map(topics, (topic) => ({
          label: topic.topic,
          value: topic.topicId,
        })),
        ['label']
      ),
    [topics]
  )

  //Values set for dropdowns
  const typeOption = useMemo(
    () => _.find(typeOptions, (option) => option.value === type),
    [type]
  )
  const noOfDaysOption = useMemo(
    () => noOfDaysOptions[noOfDays - 1],
    [noOfDays]
  )
  const sponsorshipOption = useMemo(
    () =>
      _.find(sponsorshipOptions, (option) => option.value === sponsorshipId),
    [sponsorshipId]
  )
  const usersFilteringOption = useMemo(
    () => usersFilteringOptions[usersFiltering],
    [usersFiltering]
  )
  const usersSecondaryFilteringOption = useMemo(
    () => usersSecondaryFilteringOptions[usersSecondaryFiltering],
    [usersSecondaryFiltering]
  )
  const specialtyOption = useMemo(
    () => _.find(specialtiesOptions, (option) => option.value === specialtyId),
    [specialtyId]
  )

  const areSpecialtiesLoading = useSelector((state) =>
    getAreSpecialtiesLoading(state)
  )
  const areSubSpecialtiesLoading = useSelector((state) =>
    !specialtyId ? false : getAreSubSpecialtiesLoading(state, specialtyId)
  )

  const handleOptionChange = (setter, value, params) => {
    setter(value)
    onChange &&
      onChange({
        type,
        noOfDays,
        date,
        sponsorshipId,
        followedTopicsIds:
          params.usersSecondaryFiltering === 2 ? params.topicsIds : undefined,
        viewedTopicsIds:
          params.usersSecondaryFiltering !== 2 ? params.topicsIds : undefined,
        specialtyId,
        subspecialtiesIds,
        usersIds,
        ...params,
      })
  }

  const handleDateChange = useCallback((date) =>
    handleOptionChange(setDate, date, { date })
  )
  const handleTypeChange = useCallback(({ value }) =>
    handleOptionChange(setType, value, { type: value })
  )
  const handleNoOfDaysChange = useCallback(({ value }) => {
    handleOptionChange(setNoOfDays, value, { noOfDays: value })
  })
  const handleSponsorshipChange = useCallback(({ value }) =>
    handleOptionChange(setSponsorshipId, value, { sponsorshipId: value })
  )
  const handleUsersFilteringChange = useCallback(({ value }) => {
    setSpecialtyId(null)
    setUsersIds([])
    setUsersSecondaryFiltering(0)
    setSubspecialtiesIds([])
    setTopicsIds([])
    handleOptionChange(setUsersFiltering, value, {
      usersSecondaryFiltering: 0,
      usersIds: [],
      specialtyId: null,
      subspecialtiesIds: [],
      topicsIds: [],
    })
  })
  const handleSpecialtyChange = useCallback(({ value }) => {
    setUsersSecondaryFiltering(0)
    setSubspecialtiesIds([])
    setTopicsIds([])
    handleOptionChange(setSpecialtyId, value, {
      specialtyId: value,
      subspecialtiesIds: [],
      topicsIds: [],
    })
  })
  const handleUsersSecondaryFilteringChange = useCallback(({ value }) => {
    setSubspecialtiesIds([])
    if (value !== 2 && value !== 3) {
      setTopicsIds([])
    }
    handleOptionChange(setUsersSecondaryFiltering, value, {
      subspecialtiesIds: [],
      followedTopicsIds: value === 2 ? topicsIds : undefined,
      viewedTopicsIds: value === 3 ? topicsIds : undefined,
    })
  })
  const handleSubspecialtyChange = useCallback(
    (selectedSubspecialtiesOptions) => {
      const subspecialtiesIds = _.map(
        selectedSubspecialtiesOptions,
        ({ value }) => value
      )
      handleOptionChange(setSubspecialtiesIds, subspecialtiesIds, {
        subspecialtiesIds,
      })
    }
  )
  const handleTopicChange = useCallback((selectedTopicOptions) => {
    const topicsIds = _.map(selectedTopicOptions, ({ value }) => value)
    handleOptionChange(setTopicsIds, topicsIds, {
      usersSecondaryFiltering,
      topicsIds,
    })
  })
  const handleUsersIdsChange = useCallback((selectedUsers) => {
    const usersIds = _.map(selectedUsers, ({ userId }) => userId)
    handleOptionChange(setUsersIds, usersIds, {
      usersIds,
    })
  })

  const fetchSpecialties = () => dispatch(fetchSpecialtiesAction())
  const fetchSubSpecialties = (specialtyId) =>
    dispatch(fetchSubspecialtiesAction(specialtyId))
  const fetchTopics = (specialtyId) => dispatch(fetchTopicsAction(specialtyId))

  useEffect(() => {
    fetchSpecialties()
  }, [])
  useEffect(() => {
    if (specialtyId) {
      fetchSubSpecialties(specialtyId)
      fetchTopics(specialtyId)
    }
  }, [specialtyId])

  return (
    <div className={css.header}>
      <label className={css.field}>
        <span className={css.labelText}>Show stats for</span>
        <SimpleSelect
          className={css.select}
          options={typeOptions}
          value={typeOption}
          onChange={handleTypeChange}
          components={{
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>for</span>
        <SimpleSelect
          className={classNames(css.select)}
          value={noOfDaysOption}
          options={noOfDaysOptions}
          onChange={handleNoOfDaysChange}
          components={{
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>starting from</span>
        <DatePicker
          className={css.datepicker}
          value={date}
          defaultValue={date}
          onChange={handleDateChange}
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>of sponsorship</span>
        <SimpleSelect
          className={classNames(css.select, css.sponsorshipSelect)}
          options={sponsorshipOptions}
          value={sponsorshipOption}
          onChange={handleSponsorshipChange}
          components={{
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>for</span>
        <SimpleSelect
          className={classNames(css.select)}
          options={usersFilteringOptions}
          value={usersFilteringOption}
          onChange={handleUsersFilteringChange}
          components={{
            DropdownIndicator: SimpleDropdownIndicator,
          }}
        />
      </label>
      {usersFiltering === 1 &&
        (areSpecialtiesLoading ? (
          <label className={css.field}>
            <StarLoader size="small" />
          </label>
        ) : (
          <>
            <label className={css.field}>
              <SimpleSelect
                className={classNames(css.select)}
                options={specialtiesOptions}
                value={specialtyOption}
                onChange={handleSpecialtyChange}
                components={{
                  DropdownIndicator: SimpleDropdownIndicator,
                }}
              />
            </label>
            {specialtyId && (
              <label className={css.field}>
                <SimpleSelect
                  className={classNames(css.select)}
                  options={usersSecondaryFilteringOptions}
                  value={usersSecondaryFilteringOption}
                  onChange={handleUsersSecondaryFilteringChange}
                  components={{
                    DropdownIndicator: SimpleDropdownIndicator,
                  }}
                />
              </label>
            )}
            {usersSecondaryFiltering === 1 &&
              (areSubSpecialtiesLoading ? (
                <label className={css.field}>
                  <StarLoader size="small" />
                </label>
              ) : (
                <label className={css.field}>
                  <SimpleSelect
                    className={classNames(css.select, css.subspecialty)}
                    options={subspecialtiesOptions}
                    onChange={handleSubspecialtyChange}
                    isMulti
                    clearable={false}
                  />
                </label>
              ))}
            {usersSecondaryFiltering !== 2 &&
            usersSecondaryFiltering !== 3 ? null : (
              <label className={css.field}>
                <SimpleSelect
                  className={classNames(css.select, css.topic)}
                  options={topicsOptions}
                  onChange={handleTopicChange}
                  isMulti
                />
              </label>
            )}
          </>
        ))}
      {usersFiltering !== 2 ? null : (
        <label className={css.field}>
          <TagSearch
            index="user"
            name="user"
            className={css.userSelect}
            onChange={handleUsersIdsChange}
            isMulti
            hitsPerPage={10}
            openMenuOnClick
          />
        </label>
      )}
    </div>
  )
}
