const OPEN_BANNER = 'banner/OPEN_BANNER'
const CLOSE_BANNER = 'banner/CLOSE_BANNER'

export function openBanner(bannerId) {
  return {
    type: OPEN_BANNER,
    bannerId,
  }
}

export function closeBanner(bannerId) {
  return {
    type: CLOSE_BANNER,
    bannerId,
  }
}

const initialState = {
  banners: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_BANNER: {
      return {
        ...state,
        banners: {
          ...state.banners,
          [action.bannerId]: true,
        },
      }
    }

    case CLOSE_BANNER: {
      const { [action.bannerId]: bannerObject, ...restBanners } = state.banners

      return {
        ...state,
        banners: {
          ...restBanners,
        },
      }
    }

    default:
      return state
  }
}
