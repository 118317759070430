import React, { useCallback, useMemo, useState } from 'react'
import { components } from 'react-select'
import Autosuggest from 'react-autosuggest'
import classNames from 'classnames'
import moment from 'moment-timezone'
import * as yup from 'yup'
import _, { merge } from 'lodash'

import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import {
  CAMPAIGN_ERRORS_MODAL,
  RECIPIENTS_MODAL,
  SCHEDULE_CAMPAIGN_MODAL,
} from 'mednet-util/src/constants/modal'

import TextInput from 'pharmacy/src/input/textInput/textInput'
import DatePicker from 'pharmacy/src/input/datePicker/datePicker'
import { Label1, Subtext, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { TagSelect } from 'pharmacy/src/input/select'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { ConfirmationModal } from 'pharmacy/src/display/modal'

import {
  JOB_TYPE,
  REMINDER_INTERVAL_TYPE,
} from 'mednet-cns/src/reducers/systemJob'

import { useForcedInviteDomainConfig } from 'mednet-cns/src/hooks/campaign'

import { Icon } from 'pharmacy/src/display/icon'

import { RecipientsModal } from './recipientsModal'
import EmailBodyEditor from './emailBodyEditor'

import * as css from './campaign.scss'
import CampaignErrorsModal from './campaignErrorsModal'

export const CAMPAIGN_TYPE = {
  CAMPAIGN_PUBLICATION: 'P',
  CAMPAIGN_TRIAL: 'T',
  CAMPAIGN_EDIT_PROFILE: 'E',
  CAMPAIGN_TOPIC: 'O',
  CAMPAIGN_JOBS: 'J',
  CAMPAIGN_FB: 'F',
  CAMPAIGN_BATCH_INVITE: 'I',
  CAMPAIGN_INVITE_QUESTION: 'Q',
}

const SENDING_DOMAINS = ['themednet.org', 'mail.themednet.org', 'mednet.org']
const REPLY_TO_DOMAINS = ['themednet.org', 'mednet.org']
const VALID_RECIPIENT_FORMATS = [
  'validEmail@domain.com',
  '<validEmail@domain.com>',
  'User Name <validEmail@domain.com>',
]

const getEmailAddressFromRecipient = (value) => {
  if (!value) {
    return null
  }
  const emailStart = value.lastIndexOf('<')
  const emailEnd = value.lastIndexOf('>')

  if (emailStart !== -1 && emailEnd !== -1) {
    return value.substring(emailStart + 1, emailEnd)
  }

  return value
}
const getEmailDomainFromRecipient = (value) => {
  const email = getEmailAddressFromRecipient(value)
  const domain = email?.split('@')?.[1]
  return domain
}

const validateEmail = (email) => {
  // Regular expression for validating an email
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}

const validateRecipient = (value) => {
  if (!value) {
    return true
  }

  if (value.includes('<') || value.includes('>')) {
    const tokens = value.split('<')

    // This means that < exists more than once
    if (tokens.length > 2) {
      return false
    }

    const lastToken = tokens[tokens.length - 1]

    // This means that > exist more than once, or that there is no > at all
    if (lastToken.indexOf('>') < tokens.length - 1) {
      return false
    }

    const email = lastToken.substring(0, lastToken.length - 1)
    return validateEmail(email)
  }

  return validateEmail(value)
}

const campaignSchema = yup.object().shape({
  description: yup.string().required('Campaign Name is required'),
  schedule: yup.string(),
  sender: yup
    .string()
    .required('Sender (From field) is required')
    .test(
      'is-valid-sender-format',
      `Sender should be in one of these formats ${VALID_RECIPIENT_FORMATS.map(
        (format) => `"${format}"`
      ).join(', ')}`,
      (value) => validateRecipient(value)
    )
    .test(
      'is-valid-sender-domain',
      `Sender domain must be one of ${SENDING_DOMAINS.map(
        (domain) => `"${domain}"`
      ).join(', ')}`,
      (value) => {
        if (!value) {
          return false
        }
        const senderDomain = getEmailDomainFromRecipient(value)

        return SENDING_DOMAINS.includes(senderDomain)
      }
    ),
  subject: yup.string(),
  recipients: yup.array(),
  template: yup.boolean(),
  body: yup.string(),
  saveAs: yup.string(),
  replyTo: yup
    .string()
    .nullable(true)
    .test(
      'is-valid-reply-to-format',
      `Reply-to value should be in one of these formats ${VALID_RECIPIENT_FORMATS.map(
        (format) => `"${format}"`
      ).join(', ')}`,
      (value) => validateRecipient(value)
    )
    .test(
      'required-reply-to',
      'Reply-to is required as replies to the specified sender with domain mail.themednet.org do not go to anyone',
      function isValidReplyTo(value) {
        const { sender } = this.parent
        if (!sender) {
          return true
        }
        const senderDomain = getEmailDomainFromRecipient(sender)

        return (
          !_.isEmpty(value?.trim()) || senderDomain !== 'mail.themednet.org'
        )
      }
    )
    .test(
      'valid-reply-to-domain',
      `Reply-to domain must be one of ${REPLY_TO_DOMAINS.map(
        (domain) => `"${domain}"`
      ).join(', ')}`,
      (value) => {
        if (_.isEmpty(value)) {
          return true
        }

        const replyToDomain = getEmailDomainFromRecipient(value)

        return REPLY_TO_DOMAINS.includes(replyToDomain)
      }
    ),
  useInvitationIpPool: yup.boolean(),
})

const CampaignForm = ({
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  sponsorshipId,
  campaignType,
  isDisabled,
  action,
  errors,
}) => {
  const {
    description,
    schedule,
    sender,
    subject,
    recipients,
    template,
    body,
    sendPreviewTo,
    sendPreview,
    status,
    batchSize,
    reminders,
    remindersBodies,
    intervalType,
    interval,
    intervals,
    sendReminders,
    replyTo,
    useInvitationIpPool,
  } = values

  const findRecipientWith = (id) => (recipient) => recipient.id === id

  const [openRecipientsModal] = useModalDrivers(RECIPIENTS_MODAL.modalId)
  const [openScheduleModal, closeScheduleModal] = useModalDrivers(
    SCHEDULE_CAMPAIGN_MODAL.modalId
  )
  const [forcedInviteDomainConfig, forcedInviteConfigRequest] =
    useForcedInviteDomainConfig()

  const [senderNotesExpanded, setSenderNotesExpanded] = useState(true)
  const [openErrorsModal] = useModalDrivers(CAMPAIGN_ERRORS_MODAL.modalId)

  const setValue = useCallback(
    (fieldName) => (value) => {
      setFieldValue(fieldName, value)
    },
    [setFieldValue]
  )

  const setCheckbox = useCallback((fieldName) => (e) => {
    if (e.target.checked) {
      setFieldValue(fieldName, true)
    } else {
      setFieldValue(fieldName, false)
    }
  })

  const setTime = useCallback(
    (value) => {
      const date = moment(schedule).format('YYYY-MM-DD')
      const time = value
      setFieldValue('schedule', `${date} ${time}`)
    },
    [setFieldValue, schedule]
  )

  const setDate = useCallback(
    (value) => {
      const date = moment(value).format('YYYY-MM-DD')
      const time = moment(schedule).format('HH:mm')
      setFieldValue('schedule', `${date} ${time}`)
    },
    [setFieldValue, schedule]
  )

  const setBody = useCallback((value) => {
    setFieldValue('body', value)
  })

  const setReminderBody = useCallback((reminderNo) => (value) => {
    setFieldValue(`remindersBodies[${reminderNo}]`, value)
  })

  const addRecipients = useCallback(
    ({
      selected = {},
      name,
      tree,
      isFileImport,
      file,
      filterUsersWithoutRecentActivity,
    }) => {
      const count = Object.values(selected).length

      if (count) {
        const label = name ? `${name} (${count})` : `theMednet users (${count})`
        const id = `new-${moment().unix()}`
        const { presetId: _presetId, ...relevantData } = tree

        setFieldValue('recipients', [
          ...recipients,
          {
            id,
            name: name || 'theMednet users',
            tree: relevantData,
            label,
            selected,
            isFileImport,
            file,
            filterUsersWithoutRecentActivity,
          },
        ])
      }
    },
    [recipients]
  )

  const updateRecipients = useCallback(
    ({
      id,
      selected = {},
      name,
      tree,
      isFileImport,
      file,
      filterUsersWithoutRecentActivity,
    }) => {
      const count = Object.values(selected).length
      const index = recipients.findIndex(findRecipientWith(id))
      const { presetId: _presetId, ...relevantData } = tree

      if (count && index > -1) {
        recipients[index] = {
          ...recipients[index],
          name: name || 'theMednet users',
          label: name ? `${name} (${count})` : `theMednet users (${count})`,
          selected,
          tree: relevantData,
          isFileImport,
          file,
          filterUsersWithoutRecentActivity,
        }
        setFieldValue('recipients', [...recipients])
      }
    },
    [recipients]
  )

  const addNewRecipientsModal = useCallback(
    (e) => {
      if (isDisabled) {
        return false
      }

      const nodeName = e.target?.nodeName?.toLowerCase()
      if (
        ['svg', 'path'].includes(nodeName) ||
        (nodeName === 'div' && e.target.getAttribute('role') === 'button')
      ) {
        return false
      }

      openRecipientsModal()
      return true
    },
    [isDisabled]
  )

  const removeRecipients = useCallback(
    (values) => {
      if (
        !isDisabled &&
        status !== 'progress' &&
        campaignType !== JOB_TYPE.REMINDERINVITE
      ) {
        setFieldValue('recipients', values)
      }
    },
    [recipients, isDisabled]
  )

  const RecipientsValueLabel = useCallback(
    (props) => {
      const { id } = props.data
      const handleEdit = (e) => {
        const recipientsGroup = recipients.find(findRecipientWith(id))
        openRecipientsModal({ recipients: recipientsGroup, id })

        e.stopPropagation()
      }

      return (
        <span className={css.recipientsValueLabel} onClick={handleEdit}>
          <components.MultiValueLabel {...props} />
          <Button className={css.editLabel} size="small" icon="pen" />
        </span>
      )
    },
    [recipients]
  )

  const totalUnique = useMemo(
    () =>
      Object.keys(
        recipients.reduce((carry, group) => merge(carry, group.selected), {})
      ).length,
    [recipients]
  )

  const suggestions = useMemo(
    () => [
      'theMednet Journal Club <journalclub@themednet.org>',
      'theMednet Update <update@themednet.org>',
      'ACR / theMednet Journal Club <journalclub@themednet.org>',
      sender,
    ],
    [Boolean(sender)]
  )
  const renderSuggestion = useCallback((suggestion) => (
    <div className={css.suggestion}>{suggestion}</div>
  ))

  const handleRemindersIntervalTypeChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setFieldValue('intervalType', REMINDER_INTERVAL_TYPE.VARIABLE)

        setFieldValue(
          'intervals',
          Array.from({ length: reminders }, () => interval ?? 7)
        )
      } else {
        setFieldValue('intervalType', REMINDER_INTERVAL_TYPE.FIXED)
        setFieldValue('interval', intervals?.[0] ?? 7)
      }
    },
    [setFieldValue, interval, intervals, reminders]
  )

  const handleRemindersNumberChange = useCallback(
    (value) => {
      setFieldValue('reminders', value)
      if (intervalType === REMINDER_INTERVAL_TYPE.VARIABLE) {
        let newIntervals = intervals?.slice(0, value)

        if (newIntervals.length < value) {
          newIntervals = [
            ...newIntervals,
            ...Array.from({ length: value - newIntervals.length }, () =>
              intervals?.length
                ? intervals[intervals.length - 1]
                : interval ?? 7
            ),
          ]
        }

        setFieldValue('intervals', newIntervals)
      }
    },
    [setFieldValue, interval, intervals, intervalType]
  )

  const handleIntervalsItemChange = (value, index) => {
    if (!_.isEmpty(value) && !isNaN(value)) {
      setFieldValue('intervals', [
        ...(intervals?.slice(0, index) ?? []),
        parseInt(value),
        ...(intervals?.slice(index + 1) ?? []),
      ])
    }
  }

  const nameLabel =
    campaignType === JOB_TYPE.INVITE || campaignType === JOB_TYPE.REMINDERINVITE
      ? 'Invitation campaign name:'
      : 'Campaign name:'
  const numberOfChunks = Math.ceil(totalUnique / batchSize)

  const remindersElements = []

  for (let i = 0; i < reminders; i++) {
    remindersElements.push(
      <div key={i} className={css.row}>
        <Label1>Additional text for reminder no. {i + 1}</Label1>
        <div className={classNames(css.row, css.texteditor)}>
          <EmailBodyEditor
            sponsorshipId={sponsorshipId}
            value={remindersBodies[i]}
            onEditorChange={setReminderBody(i)}
            campaignType={campaignType}
            disabled={isDisabled}
          />
        </div>
      </div>
    )
  }

  const scheduleDetails = []

  if (campaignType === JOB_TYPE.INVITE) {
    // create 2D array where each row is a batch and its reminders schedules
    const rowsNum = batchSize ? numberOfChunks : 1
    for (let i = 0; i < rowsNum; i++) {
      const scheduleDate = moment(values.schedule, 'YYYY-MM-DD HH:mm')
        .add(i, 'days')
        .format('ddd - YYYY-MM-DD HH:mm')

      scheduleDetails.push([])
      scheduleDetails[i].push(scheduleDate)

      if (sendReminders) {
        if (values.intervalType === REMINDER_INTERVAL_TYPE.FIXED) {
          for (let j = 0; j < reminders; j++) {
            scheduleDetails[i].push(
              moment(
                scheduleDetails[i][scheduleDetails[i].length - 1],
                'YYYY-MM-DD HH:mm'
              )
                .add(values.interval, 'days')
                .format('ddd - YYYY-MM-DD HH:mm')
            )
          }
        } else {
          for (let j = 0; j < values.intervals.length; j++) {
            scheduleDetails[i].push(
              moment(
                scheduleDetails[i][scheduleDetails[i].length - 1],
                'YYYY-MM-DD HH:mm'
              )
                .add(values.intervals[j], 'days')
                .format('ddd - YYYY-MM-DD HH:mm')
            )
          }
        }
      }
    }
  }

  return (
    <form>
      <div className={classNames(css.row)}>
        <div className={classNames(css.cell, css.statusCell)}>
          <Label1 className={css.label}>Status:</Label1>
          <Label1>{status}</Label1>
        </div>
        <div className={classNames(css.cell, css.actions)}>
          {action === 'edit' ? (
            <Button
              className={css.action}
              rightIcon={!sendPreview ? 'save' : 'eye'}
              isDisabled={!body}
              onClick={() => {
                setFieldValue('saveAs', 'preview', false)
                if (!_.isEmpty(errors)) {
                  openErrorsModal()
                } else {
                  handleSubmit()
                }
              }}
            >
              Save{!sendPreview ? ' and stay' : ', send preview and stay'}
            </Button>
          ) : null}
          <Button
            className={css.action}
            rightIcon="save"
            isLoading={isSubmitting}
            isDisabled={!description || isDisabled}
            onClick={() => {
              setFieldValue('saveAs', 'draft', false)
              if (!_.isEmpty(errors)) {
                openErrorsModal()
              } else {
                handleSubmit()
              }
            }}
          >
            Save as draft
          </Button>
          <Button
            className={css.action}
            rightIcon="paper-plane"
            isLoading={isSubmitting}
            isDisabled={!description || isDisabled}
            onClick={() => {
              setFieldValue('saveAs', 'schedule', false)
              if (!_.isEmpty(errors)) {
                openErrorsModal()
              } else {
                openScheduleModal()
              }
            }}
          >
            Schedule
          </Button>
        </div>
      </div>
      <div className={classNames(css.row, css.header)}>
        <div className={css.campaignNameCell}>
          <Label1 className={classNames(css.label, css.required)}>
            {nameLabel}
          </Label1>
          <TextInput
            className={css.input}
            placeholder="Campaign name..."
            showClear={false}
            value={description}
            name="description"
            onChange={setValue('description')}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className={classNames(css.row, css.campaignSettings)}>
        <div className={css.cell}>
          <Label1 className={css.label}>Schedule:</Label1>
          <div className={css.schedule}>
            <DatePicker
              className={classNames(css.date, css.input)}
              value={moment(schedule).format('YYYY-MM-DD')}
              defaultValue={moment(schedule).format('YYYY-MM-DD')}
              onChange={setDate}
              dayPickerProps={{
                disabledDays: [
                  {
                    before: new Date(),
                  },
                  {
                    daysOfWeek:
                      campaignType === JOB_TYPE.INVITE ? [0, 6] : undefined,
                  },
                ],
              }}
              inputProps={{
                disabled:
                  isDisabled ||
                  campaignType === JOB_TYPE.REMINDERINVITE ||
                  status === 'progress',
              }}
            />
            <TextInput
              className={classNames(css.time, css.input)}
              placeholder="time"
              showClear={false}
              inputType="time"
              value={moment(schedule).format('HH:mm')}
              onChange={setTime}
              disabled={
                isDisabled ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress'
              }
            />
            <div className={css.zone}>
              {moment.tz(schedule, 'America/New_York').format('zz')}
            </div>
          </div>
        </div>
        {campaignType === JOB_TYPE.INVITE && (
          <div className={classNames(css.cell, css.autoReminderCell)}>
            <div className={css.row}>
              <ButtonCheckbox
                className={css.checkbox}
                buttonProps={{ size: 'small' }}
                selectText="Send reminders"
                unselectText="Send reminders"
                inputProps={{
                  checked: sendReminders,
                  onChange: (e) => {
                    setCheckbox('sendReminders')(e)
                    if (sendReminders) {
                      setValue('reminders')(0)
                    } else {
                      setValue('reminders')(1)
                    }
                  },
                  disabled: status !== 'draft' && status !== 'scheduled',
                }}
              />
              {sendReminders && (
                <ButtonCheckbox
                  className={css.checkbox}
                  buttonProps={{ size: 'small' }}
                  selectText={` ${_.capitalize(
                    REMINDER_INTERVAL_TYPE.VARIABLE
                  )} intervals`}
                  unselectText={`${_.capitalize(
                    REMINDER_INTERVAL_TYPE.VARIABLE
                  )} intervals`}
                  inputProps={{
                    checked: intervalType === REMINDER_INTERVAL_TYPE.VARIABLE,
                    onChange: handleRemindersIntervalTypeChange,
                    disabled: status !== 'draft' && status !== 'scheduled',
                  }}
                />
              )}
            </div>

            {sendReminders && (
              <div className={css.autoReminderWrapper}>
                <Label1 className={css.label}>Send</Label1>
                <TextInput
                  className={classNames(css.input, css.autoReminderInput)}
                  showClear={false}
                  inputType="number"
                  value={reminders}
                  min={1}
                  disabled={status !== 'draft' && status !== 'scheduled'}
                  onChange={handleRemindersNumberChange}
                />
                <Label1 className={css.label}>reminder(s)</Label1>
                {intervalType === REMINDER_INTERVAL_TYPE.FIXED ? (
                  <>
                    <Label1 className={css.label}>between</Label1>
                    <TextInput
                      className={classNames(css.input, css.autoReminderInput)}
                      showClear={false}
                      inputType="number"
                      value={interval}
                      min={1}
                      disabled={status !== 'draft' && status !== 'scheduled'}
                      onChange={setValue('interval')}
                    />
                    <Label1 className={css.label}>day(s)</Label1>
                  </>
                ) : null}
              </div>
            )}

            {sendReminders && intervalType === REMINDER_INTERVAL_TYPE.VARIABLE && (
              <div>
                {intervals.map((intervalItem, index) => (
                  <div key={index} className={css.autoReminderWrapper}>
                    <Subtitle2 className={css.label}>
                      - Reminder #{index + 1}
                    </Subtitle2>
                    <TextInput
                      className={classNames(css.input, css.autoReminderInput)}
                      showClear={false}
                      inputType="number"
                      value={intervalItem}
                      min={1}
                      disabled={status !== 'draft' && status !== 'scheduled'}
                      onChange={(value) =>
                        handleIntervalsItemChange(value, index)
                      }
                    />
                    <Subtitle2 className={css.label}>
                      day(s) after the{' '}
                      {index === 0 ? 'main invite' : 'last reminder'}
                    </Subtitle2>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {(campaignType === JOB_TYPE.INVITE ||
          campaignType === JOB_TYPE.REMINDERINVITE) && (
          <div className={css.cell}>
            <Label1 className={css.label}>
              Split users, recipient # per batch:
            </Label1>
            <TextInput
              className={classNames(css.input, css.batchInput)}
              showClear={false}
              placeholder="All in one go"
              inputType="number"
              value={batchSize}
              min={1}
              disabled={
                !(recipients && recipients.length) ||
                (status !== 'draft' && status !== 'scheduled') ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress'
              }
              onChange={setValue('batchSize')}
            ></TextInput>
            <div className={classNames(css.cell, css.batchInfoCell)}>
              {campaignType === JOB_TYPE.INVITE &&
                Boolean(batchSize) &&
                numberOfChunks < 21 &&
                `${numberOfChunks} batches`}
              {campaignType === JOB_TYPE.INVITE &&
                Boolean(batchSize) &&
                numberOfChunks > 20 &&
                'Please change the number of users per batch, invites cannot span more than 20 weekdays'}
            </div>
          </div>
        )}
      </div>
      {campaignType === JOB_TYPE.INVITE && scheduleDetails.length > 0 && (
        <div className={css.row}>
          <div>
            <Label1 className={css.label}>Sending details:</Label1>
            <div>
              <table className={css.sendingDetailsTable}>
                <thead>
                  <tr>
                    <th>Batch #</th>
                    {scheduleDetails[0].map((_schedule, columnIndex) => (
                      <th key={columnIndex}>
                        {columnIndex === 0
                          ? 'Main invite'
                          : `Reminder #${columnIndex}`}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {scheduleDetails.map((schedule, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{rowIndex + 1}</td>
                      {schedule.map((schedule, columnIndex) => (
                        <td key={columnIndex}>{schedule}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {isDisabled ? null : (
        <div className={classNames(css.row, css.previewRecipients)}>
          <div className={css.cell}>
            <ButtonCheckbox
              buttonProps={{ size: 'small' }}
              selectText="Send preview to (semicolon separated email address list):"
              unselectText="Send preview to (semicolon separated email address list):"
              inputProps={{
                checked: sendPreview,
                onChange: setCheckbox('sendPreview'),
              }}
              className={css.checkbox}
            />
            <TextInput
              className={css.input}
              showClear={false}
              value={sendPreviewTo}
              disabled={!sendPreview}
              onChange={setValue('sendPreviewTo')}
            />
          </div>
        </div>
      )}
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>From:</Label1>
          <div className={css.autosuggest}>
            <Autosuggest
              onSuggestionsFetchRequested={() => suggestions}
              onSuggestionsClearRequested={() => suggestions}
              getSuggestionValue={(val) => val}
              renderSuggestion={renderSuggestion}
              renderInputComponent={(props) => (
                <TextInput
                  onChangeHandlesEvent
                  {...props}
                  disabled={
                    isDisabled ||
                    campaignType === JOB_TYPE.REMINDERINVITE ||
                    status === 'progress'
                  }
                />
              )}
              suggestions={suggestions}
              inputProps={{
                className: css.input,
                value: sender,
                onChange: (_e, { newValue }) =>
                  setValue('sender')(newValue?.trim()),
                showClear: false,
              }}
              shouldRenderSuggestions={() => true}
              focusInputOnSuggestionClick={false}
            />
            {campaignType !== JOB_TYPE.INVITE && (
              <Subtitle3 className={css.notes}>
                * It is strongly discouraged to use the domain
                &quot;mednet.org&quot; to send campaigns. This is the primary
                domain our admins and sales team use to contact doctors and
                clients, and campaigns may have negative impact on its
                reputation.
              </Subtitle3>
            )}

            {campaignType === JOB_TYPE.INVITE &&
              forcedInviteConfigRequest?.isLoaded && (
                <Subtitle3 className={css.notes}>
                  <div>
                    <Icon
                      icon={senderNotesExpanded ? 'chevron-up' : 'chevron-down'}
                      className={css.expandNotesIcon}
                      onClick={() => setSenderNotesExpanded((prev) => !prev)}
                    />
                    Notes Regarding invites sender domain
                  </div>
                  {senderNotesExpanded && (
                    <ul>
                      <li>
                        It is strongly discouraged to use the domain
                        &quot;mednet.org&quot; to send invites. This is the
                        primary domain our admins and sales team use to contact
                        doctors and clients, and invites can have negative
                        impact on its reputation.
                      </li>
                      {forcedInviteDomainConfig.enabled &&
                        Boolean(forcedInviteDomainConfig.vendors?.length) && (
                          <li>
                            For users who use{' '}
                            {forcedInviteDomainConfig.vendors.join(' and ')}{' '}
                            email accounts, the sender domain will be enforced
                            to &quot;
                            {forcedInviteDomainConfig.domain}&quot; when sending
                            invites regardless the sender domain you set in this
                            tool.
                          </li>
                        )}
                      <li>
                        If this is the first invite email we send to this group
                        of users, it is encouraged to use
                        &quot;mail.themednet.org&quot; instead of
                        &quot;themednet.org&quot; as we use the later to send
                        site emails to registered users and experts and we
                        should try to maintain its reputation. The system can
                        learn after the first send which users think our emails
                        are welcomed and which don&rsquo;t.
                      </li>
                      <li>
                        When using &quot;mail.themednet.org&quot;, it is
                        recommended to use the invitation IP pool.
                      </li>
                    </ul>
                  )}
                </Subtitle3>
              )}
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>Subject:</Label1>
          <TextInput
            className={css.input}
            showClear={false}
            value={subject}
            onChange={setValue('subject')}
            disabled={
              isDisabled ||
              campaignType === JOB_TYPE.REMINDERINVITE ||
              status === 'progress'
            }
          />
        </div>
      </div>
      <div className={css.row}>
        <div
          className={classNames(css.oneline, css.full)}
          onClick={addNewRecipientsModal}
        >
          <div className={css.label}>
            <Label1>To:</Label1>
            <Subtext># {totalUnique}</Subtext>
          </div>
          <div className={css.select}>
            <TagSelect
              creatable
              isMulti
              value={recipients}
              isClearable={false}
              components={{ MultiValueLabel: RecipientsValueLabel }}
              onChange={removeRecipients}
              getOptionValue={(data) => data.id}
            />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>Reply to:</Label1>

          <div className={css.autosuggest}>
            <Autosuggest
              onSuggestionsFetchRequested={() => suggestions}
              onSuggestionsClearRequested={() => suggestions}
              getSuggestionValue={(val) => val}
              renderSuggestion={renderSuggestion}
              renderInputComponent={(props) => (
                <TextInput
                  onChangeHandlesEvent
                  {...props}
                  disabled={
                    isDisabled ||
                    campaignType === JOB_TYPE.REMINDERINVITE ||
                    status === 'progress'
                  }
                />
              )}
              suggestions={suggestions}
              inputProps={{
                className: css.input,
                value: replyTo || '',
                onChange: (_e, { newValue }) =>
                  setValue('replyTo')(newValue?.trim()),
                showClear: false,
              }}
              shouldRenderSuggestions={() => true}
              focusInputOnSuggestionClick={false}
            />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>In template:</Label1>
          <ButtonCheckbox
            buttonProps={{ size: 'small' }}
            selectText=""
            unselectText=""
            inputProps={{
              checked: template,
              onChange: setCheckbox('template'),
              disabled:
                isDisabled ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress',
            }}
          />
        </div>
      </div>
      {campaignType !== JOB_TYPE.CAMPAIGN && (
        <div className={css.row}>
          <div className={classNames(css.oneline, css.full)}>
            <Label1 className={classNames(css.label, css.wideLabel)}>
              Use Invitations IP Pool:
            </Label1>
            <ButtonCheckbox
              buttonProps={{ size: 'small' }}
              selectText=""
              unselectText=""
              inputProps={{
                checked: useInvitationIpPool,
                onChange: setCheckbox('useInvitationIpPool'),
                disabled:
                  isDisabled ||
                  campaignType === JOB_TYPE.REMINDERINVITE ||
                  status === 'progress',
              }}
            />
          </div>
        </div>
      )}
      <div className={css.row}>
        {campaignType === JOB_TYPE.REMINDERINVITE && (
          <Label1>
            Text added on top of the previous reminder and/or main invite
            campaign:
          </Label1>
        )}
      </div>
      <div className={classNames(css.row, css.texteditor)}>
        <EmailBodyEditor
          sponsorshipId={sponsorshipId}
          value={body}
          onEditorChange={setBody}
          campaignType={campaignType}
          disabled={isDisabled}
        />
      </div>
      {remindersElements.map((reminderElement) => reminderElement)}
      <RecipientsModal
        onAdd={addRecipients}
        onSave={updateRecipients}
        queryUserStatus={
          campaignType === JOB_TYPE.INVITE ||
          campaignType === JOB_TYPE.REMINDERINVITE
            ? 'unregistered'
            : 'active'
        }
        isDisabled={
          isDisabled ||
          campaignType === JOB_TYPE.REMINDERINVITE ||
          status === 'progress'
        }
      />
      <ConfirmationModal
        closeText="Cancel"
        closeType="neutral"
        header={`Are you sure you want to schedule the campaign? (${moment
          .tz(schedule, 'America/New_York')
          .format('YYYY-MM-DD hh:mm a zz')})`}
        modalId={SCHEDULE_CAMPAIGN_MODAL.modalId}
        onSubmit={() => {
          closeScheduleModal()
          if (!_.isEmpty(errors)) {
            openErrorsModal()
          } else {
            handleSubmit()
          }
        }}
        submitText="Schedule"
        submitType="primary"
        size="larger"
      />
      <CampaignErrorsModal errors={errors} />
    </form>
  )
}

export { CampaignForm, campaignSchema }
