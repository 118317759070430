import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { Icon } from 'pharmacy/src/display/icon'
import { Header2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { MocAssessmentForm } from 'pharmacy/src/cme/mocAssessmentForm'

import { useActivitiesAvailableForMocLazy } from 'mednet-cns/src/hooks/cme'

import { mixpanel } from 'mednet-util/src/tracking'

import CmeActivitiesTable from 'components/tables/cmeActivitiesTable'

import { useAvailableActivitiesSelector } from './hooks/availableActivitiesSelector'
import css from './mocAvailableActivitiesCard.scss'

export const MocAvailableActivitiesCard = ({
  closeCard,
  isAbimMocEnabledForUser,
  isAbpMocEnabledForUser,
  maxCreditsPerYear,
  creditsPerActivity,
  redeemedCount,
  reloadFlag,
  setReloadFlag,
}) => {
  const [
    { mocAvailableActivities, redeemedMaxForCurrentYear },
    activitiesRequest,
    loadActivities,
  ] = useActivitiesAvailableForMocLazy()

  const [startAssessment, setStartAssessment] = useState(false)

  useEffect(() => {
    if (reloadFlag) {
      loadActivities()
      setReloadFlag(false)
    } else if (!activitiesRequest.isLoaded && !activitiesRequest.isLoading) {
      loadActivities()
    }
  }, [reloadFlag])

  const {
    selectedRowsIds,
    disabledRowsIds,
    getSelectedCount,
    handleSelect,
    handleSelectAll,
    handleDeselectAll,
  } = useAvailableActivitiesSelector({
    availableActivities: mocAvailableActivities,
    maxCreditsPerYear,
    creditsPerActivity,
    redeemedCount,
  })

  const title = useCallback(
    (board) => {
      return (
        <Header2 className={css.title}>
          Redeem{' '}
          <span className={css.credits}>
            {getSelectedCount() * creditsPerActivity}
          </span>{' '}
          {board} points
        </Header2>
      )
    },
    [
      creditsPerActivity,
      isAbimMocEnabledForUser,
      isAbpMocEnabledForUser,
      getSelectedCount,
    ]
  )

  const tableActivities = useMemo(() => {
    return mocAvailableActivities.map((activity, index) => ({
      ...activity,
      id: index,
    }))
  }, [mocAvailableActivities])

  const activities = useMemo(() => {
    return mocAvailableActivities.filter((_, index) => selectedRowsIds[index])
  }, [mocAvailableActivities, selectedRowsIds])

  const closeMocActivitiesCard = useCallback(() => {
    mixpanel.track('Closed MOC remaining activities Card')
    closeCard()
  }, [closeCard])

  if (startAssessment) {
    return (
      <MocAssessmentForm
        activities={activities}
        creditsPerActivity={creditsPerActivity}
        maxCreditsPerYear={maxCreditsPerYear}
        location="MOC Remaining Activities"
        isAbimMocEnabledForUser={isAbimMocEnabledForUser}
        isAbpMocEnabledForUser={isAbpMocEnabledForUser}
        showRelatedQuestions
        title={title}
        onExit={closeCard}
        showAsCard
      />
    )
  }

  return (
    <div className={css.cardContainer}>
      <Icon
        icon="times"
        className={css.closeIcon}
        onClick={closeMocActivitiesCard}
      />
      <Header2 className={css.title}>
        {title(
          isAbimMocEnabledForUser && isAbpMocEnabledForUser
            ? 'ABIM & ABP'
            : isAbimMocEnabledForUser
            ? 'ABIM'
            : isAbpMocEnabledForUser
            ? 'ABP'
            : ''
        )}
      </Header2>
      <hr />
      <div className={css.tableContainer}>
        <CmeActivitiesTable
          cmeActivities={tableActivities}
          isLoading={activitiesRequest.isLoading}
          loadingDataError={activitiesRequest.isError}
          selectedRowsIds={selectedRowsIds}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          disabledRowsIds={disabledRowsIds}
          emptyDataMessage={
            redeemedMaxForCurrentYear
              ? `You have already redeemed the maximum ${maxCreditsPerYear} MOC credits for this year`
              : 'You have no activities available for MOC'
          }
        />
      </div>
      <div className={css.buttonsContainer}>
        <Button
          type="neutral"
          className={css.button}
          onClick={closeMocActivitiesCard}
        >
          Cancel
        </Button>
        <Button
          className={css.button}
          isDisabled={isEmpty(activities)}
          onClick={() => setStartAssessment(true)}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
