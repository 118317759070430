import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { ConfirmationScreen, Modal } from 'pharmacy/src/display/modal'
import { SPONSORSHIP_UPDATE_MODAL } from 'mednet-util/src/constants/modal'
import { Header1 } from 'pharmacy/src/typography'
import { FETCH_SPONSORS, fetchSponsors } from 'mednet-cns/src/reducers/sponsor'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_EXPERTS,
  fetchExperts,
  updateSponsorship,
} from 'mednet-cns/src/reducers/sponsorship'

import {
  FETCH_SPECIALTIES,
  fetchSpecialties,
  getSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import {
  FETCH_SUBSPECIALTIES,
  fetchSubspecialties,
  getSubSpecialties,
} from 'mednet-cns/src/reducers/subspecialty'

import {
  FETCH_JOURNALS,
  fetchJournals,
  getJournals,
} from 'mednet-cns/src/reducers/journal'

import SponsorshipForm, { SponsorshipSchema } from '../forms/sponsorshipForm'

class FinishedScreen extends React.Component {
  render() {
    const { closeModal } = this.props

    return (
      <ConfirmationScreen
        header="Sponsorship updated"
        closeModal={closeModal}
      />
    )
  }
}

class UpdateScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchSponsors()
    this.props.fetchExperts()
    this.props.fetchSpecialties()
    this.props.fetchSubspecialties()
    this.props.fetchJournals()
  }

  handleSubmit = (values, actions) => {
    const { updateSponsorship, changeModalScreen } = this.props

    const formData = new FormData(this.form.current)
    formData.set('infoText', values.infoText)
    updateSponsorship(formData, () => {
      actions.setSubmitting(false)
      changeModalScreen(SPONSORSHIP_UPDATE_MODAL.screens.finished)
    })
  }

  render() {
    const {
      sponsorsIsLoaded,
      sponsorship,
      expertsIsLoaded,
      specialtiesIsLoaded,
      subspecialtiesIsLoaded,
      journalsIsLoaded,
    } = this.props
    const {
      description,
      sponsor,
      topic,
      topicId,
      topics,
      emblem,
      emblemText,
      editor,
      experts,
      startDate,
      endDate,
      useCustomInfo,
      infoText,
      releaseDate,
      isPaid,
      specialties,
      subspecialties,
      journal,
      programType,
    } = sponsorship

    if (
      !sponsorsIsLoaded ||
      !expertsIsLoaded ||
      !specialtiesIsLoaded ||
      !subspecialtiesIsLoaded ||
      !journalsIsLoaded
    ) {
      return <StarLoader />
    }

    return (
      <div>
        <Header1>Edit sponsorship</Header1>
        <hr />
        <Formik
          initialValues={{
            description,
            sponsor,
            topic: {
              topicId,
              topic,
            },
            topics,
            experts,
            editor,
            emblem,
            emblemText,
            useCustomInfo: Boolean(useCustomInfo),
            infoText,
            dates: {
              from: startDate,
              to: endDate,
            },
            releaseDate,
            isPaid: Boolean(isPaid),
            specialties,
            subspecialties,
            programType,
            journal,
          }}
          onSubmit={this.handleSubmit}
          validationSchema={SponsorshipSchema}
        >
          {(props) => (
            <SponsorshipForm
              {...props}
              {...this.props}
              formRef={this.form}
              submitText="Update"
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps
  const sponsorsRequest = getRequest(state, FETCH_SPONSORS)
  const expertsRequest = getRequest(state, FETCH_EXPERTS, sponsorshipId)
  const specialtiesRequest = getRequest(state, FETCH_SPECIALTIES)
  const specialties = Object.values(getSpecialties(state))
  const subspecialtiesRequest = getRequest(state, FETCH_SUBSPECIALTIES)
  const subspecialties = Object.values(getSubSpecialties(state))
  const journalsRequest = getRequest(state, FETCH_JOURNALS)
  const journals = Object.values(getJournals(state))

  return {
    sponsors: state.sponsor.sponsors,
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorsIsLoaded:
      sponsorsRequest.isLoaded === true && sponsorsRequest.isLoading === false,
    expertsIsLoaded:
      expertsRequest.isLoaded === true && expertsRequest.isLoading === false,
    specialties,
    specialtiesIsLoaded:
      specialtiesRequest.isLoaded === true &&
      specialtiesRequest.isLoading === false,
    subspecialties,
    subspecialtiesIsLoaded:
      subspecialtiesRequest.isLoaded === true &&
      subspecialtiesRequest.isLoading === false,
    journals,
    journalsIsLoaded:
      journalsRequest.isLoaded === true && journalsRequest.isLoading === false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps

  return {
    fetchSponsors: () => dispatch(fetchSponsors()),
    fetchExperts: () => dispatch(fetchExperts(sponsorshipId)),
    fetchSpecialties: () => dispatch(fetchSpecialties()),
    fetchSubspecialties: () => dispatch(fetchSubspecialties()),
    fetchJournals: () => dispatch(fetchJournals()),
    updateSponsorship: (formData, callback) =>
      dispatch(updateSponsorship(sponsorshipId, formData, callback)),
  }
}

const ConnectedUpdateScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateScreen)

const screens = {
  [SPONSORSHIP_UPDATE_MODAL.screens.create]: ConnectedUpdateScreen,
  [SPONSORSHIP_UPDATE_MODAL.screens.finished]: FinishedScreen,
}

class EditSponsorshipModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        defaultScreen={SPONSORSHIP_UPDATE_MODAL.screens.create}
        modalId={SPONSORSHIP_UPDATE_MODAL.modalId}
        size="large"
        screens={screens}
        {...this.props}
      />
    )
  }
}

export default EditSponsorshipModal
