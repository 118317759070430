import React from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'

import { TagSelect, components } from 'pharmacy/src/input/select'
import { transformSearchGeoObject } from 'mednet-util/src/search'
import { Icon } from 'pharmacy/src/display/icon'

import css from './geoTagSelect.scss'

export class GeoOption extends React.Component {
  render() {
    const { geoName } = this.props.data

    return (
      <components.Option {...this.props}>
        <div className={css.optionContainer}>{geoName}</div>
      </components.Option>
    )
  }
}

export class GeoControl extends React.Component {
  render() {
    const { children, ...restProps } = this.props

    return (
      <div className={css.control} {...restProps.innerProps}>
        <Icon className={css.icon} icon="map-marker-alt" />
        {children}
      </div>
    )
  }
}

class GeoTagSelect extends React.Component {
  handleInputChange = (value) => {
    this.props.refine(value)
  }

  getOptionValue = (datum) => {
    return JSON.stringify({ geoId: datum.geoId, geoTypeId: datum.geoTypeId })
  }

  getOptionLabel = (datum) => {
    return datum.geoName
  }

  render() {
    const {
      hits,
      currentRefinement,
      dispatch,
      createURL,
      refine,
      ...restProps
    } = this.props

    const transformedHits = hits.map(transformSearchGeoObject)

    return (
      <TagSelect
        components={{ Option: GeoOption, Control: GeoControl }}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        menuIsOpen={Boolean(currentRefinement)}
        name="geo[]"
        onInputChange={this.handleInputChange}
        options={transformedHits}
        placeholder="Search for States, Regions, or Metro Areas"
        {...restProps}
      />
    )
  }
}

export default connectAutoComplete(GeoTagSelect)
