import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { Page } from 'pharmacy/src/display/page'

import {
  Header1,
  Subtitle1,
  Subtitle2,
  Subtitle4,
} from 'pharmacy/src/typography'

import { CenteredContent } from 'pharmacy/src/display/content'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import {
  FETCH_SPONSORSHIP,
  FETCH_EXPERTS,
  fetchExperts,
  fetchSponsorship,
  unassignQuestion,
  assignQuestion,
} from 'mednet-cns/src/reducers/sponsorship'

import { getRequest } from 'mednet-cns/src/api/v1'
import { Button } from 'pharmacy/src/input/button'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { Card } from 'pharmacy/src/display/card'
import { Icon } from 'pharmacy/src/display/icon'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import css from './emails.scss'

class SponsorshipEmailsPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.sponsorshipIsLoaded) {
      props.fetchSponsorship()
    }

    if (!props.expertsIsLoaded) {
      props.fetchExperts()
    }

    this.form = React.createRef()
  }

  handleQuestionCheckBoxChange = (
    clicked,
    userId,
    questionId,
    callback = () => {}
  ) => {
    const { assignQuestion, unassignQuestion } = this.props

    if (userId && questionId) {
      if (clicked) {
        unassignQuestion(userId, questionId, callback)
      } else {
        assignQuestion(userId, questionId, callback)
      }
    }
  }

  renderQuestionAssignmentStatus = (
    userId,
    questionId,
    expertQuestions,
    expertLoadingQuestion
  ) => {
    if (expertLoadingQuestion === questionId) {
      return <StarLoader isVerticalMargin={false} size="small" />
    }

    const isFromExpertQuestions = expertQuestions.includes(parseInt(questionId))
    const questionCheckBoxHandler = () =>
      this.handleQuestionCheckBoxChange(
        isFromExpertQuestions,
        userId,
        questionId
      )

    return (
      <Icon
        icon={
          isFromExpertQuestions ? ['fas', 'check-square'] : ['far', 'square']
        }
        size="lg"
        onClick={questionCheckBoxHandler}
      />
    )
  }

  renderExperts = () => {
    const { sponsorship } = this.props
    const { experts, questions: allQuestions, sponsorshipId } = sponsorship

    // Filter denied and pending questions
    const questions = isEmpty(allQuestions)
      ? allQuestions
      : Object.fromEntries(
          Object.entries(allQuestions).filter(
            ([_, question]) => question.status !== -1 && question.status !== 0
          )
        )

    return experts.map((expertObject) => {
      const {
        userId,
        questions: expertQuestions,
        email,
        loadingQuestion: expertLoadingQuestion,
      } = expertObject
      const { sendafter } = email

      return (
        <Card key={userId} className={css.card}>
          <div className={css.userHeader}>
            <UserUpdateInformation user={expertObject} theme="extended" />
            <div>
              <Button
                icon={sendafter ? 'reply' : 'inbox-out'}
                type="text"
                route={ROUTES[PAGES.SPONSORSHIP_SEND_EMAIL]}
                routeParams={{ sponsorshipId }}
                search={{ userId }}
              >
                {sendafter ? 'Send Reminder' : 'Send Email'}
              </Button>
              {sendafter && (
                <Subtitle4>
                  Last sent: {moment(sendafter).format('LLL')}
                </Subtitle4>
              )}
            </div>
          </div>
          <Subtitle1 className={css.questionsHeader}>
            {isEmpty(questions)
              ? 'No questions in sponsorship'
              : 'Sponsorship questions'}
          </Subtitle1>
          {isEmpty(questions) ? null : (
            <div className={css.allQuestionsContainer}>
              {Object.keys(questions).map((questionId) => (
                <div key={questionId} className={css.questionContainer}>
                  <div className={css.assignmentStatusContainer}>
                    {this.renderQuestionAssignmentStatus(
                      userId,
                      questionId,
                      expertQuestions,
                      expertLoadingQuestion
                    )}
                  </div>
                  <Subtitle2>{questions[questionId].question}</Subtitle2>
                </div>
              ))}
            </div>
          )}
        </Card>
      )
    })
  }

  render() {
    const { sponsorship, sponsorshipIsLoaded, expertsIsLoaded } = this.props

    if (!sponsorshipIsLoaded || !expertsIsLoaded) {
      return <StarLoader />
    }

    const { description, sponsorshipId } = sponsorship

    return (
      <Page>
        <CenteredContent>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
              {
                ...ROUTES[PAGES.VIEW_SPONSORSHIP],
                routeParams: { sponsorshipId },
                label: description,
              },
              {
                label: 'Manage Expert Emails',
              },
            ]}
          />
          <div className={css.header}>
            <Header1>Manage Expert Emails</Header1>
          </div>
          <div>{this.renderExperts()}</div>
        </CenteredContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const sponsorshipRequest = getRequest(state, FETCH_SPONSORSHIP, sponsorshipId)
  const expertsRequest = getRequest(state, FETCH_EXPERTS, sponsorshipId)

  return {
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorshipIsLoaded: sponsorshipRequest.isLoaded === true,
    expertsIsLoaded:
      expertsRequest.isLoaded === true && expertsRequest.isLoading === false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps.match.params

  return {
    fetchSponsorship: () => dispatch(fetchSponsorship(sponsorshipId)),
    fetchExperts: () => dispatch(fetchExperts(sponsorshipId)),
    unassignQuestion: (userId, questionId, callback) =>
      dispatch(unassignQuestion(sponsorshipId, userId, questionId, callback)),
    assignQuestion: (userId, questionId, callback) =>
      dispatch(assignQuestion(sponsorshipId, userId, questionId, callback)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorshipEmailsPage)
