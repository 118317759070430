import _ from 'lodash'

export const getFeedNameKey = (feedName, feedKey = null) => {
  if (feedKey) {
    return `${feedName}_${feedKey}`
  }

  return `${feedName}`
}

export const getFeedItems = ({ items, mixins }) => {
  const feed = _.map(items, (item, index) => ({ item, index }))

  _.each(mixins, (mixin, mixinIndex) => {
    let currentIndex = mixin.firstIndex || mixin.repeatEvery
    let itemCount = 0
    while (currentIndex < feed.length) {
      feed.splice(currentIndex, 0, { mixinIndex, itemIndex: itemCount })
      itemCount += 1
      currentIndex += mixin.repeatEvery
    }
  })

  return feed
}
