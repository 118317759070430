import React from 'react'

import { Link } from 'pharmacy/src/navigation/link'

import { makeAssetURL } from 'mednet-util/src/router'

import css from './publications.scss'

const PublicationCard = ({
  children,
  date,
  logo,
  logoClassName,
  link,
  assetsPath,
}) => (
  <Link
    className={css.publicationCard}
    pathname={link}
    target="_blank"
    rel="noreferrer"
    external
  >
    <div className={css.publicationCardMainContent}>
      <img
        className={css.publicationCardImage}
        src={makeAssetURL(`${assetsPath}publications icon.svg`)}
      />
      <div className={css.publicationCardContent}>
        {children}
        <div className={css.publicationDate}>{date}</div>
      </div>
    </div>
    <div className={css.publicationCardFooter}>
      <img
        className={css[logoClassName]}
        src={makeAssetURL(`${assetsPath}${logo}`)}
      />
    </div>
  </Link>
)

export const Publications = ({
  assetsPath,
  titleClassName,
  buttonClassName,
}) => (
  <div className={css.publicationsContainer}>
    {/* Need inner div for IE */}
    <div className={titleClassName}>
      <div>PUBLICATIONS & EVENTS</div>
    </div>
    <div className={css.publicationCardsConatiner}>
      <div className={css.publicationCardContainer}>
        <PublicationCard
          date="2020"
          logo="jco-clinical-informatics-logo.png"
          logoClassName="publicationsInstitutionLogo_jco"
          link="https://ascopubs.org/doi/full/10.1200/CCI.20.00073"
          assetsPath={assetsPath}
        >
          Using a Collaborative, Virtual Discussion Platform to Mobilize
          Oncologic Expertise for the COVID-19 Pandemic
        </PublicationCard>
      </div>
      <div className={css.publicationCardContainer}>
        <PublicationCard
          date="2019"
          logo="nih-logo.png"
          link="https://youtu.be/bObw81rWiCw"
          assetsPath={assetsPath}
        >
          Improving Clinical Trial Enrollment via Online Social Network
        </PublicationCard>
      </div>
      <div className={css.publicationCardContainer}>
        <PublicationCard
          date="2018"
          logo="astro-logo.png"
          link="https://www.youtube.com/watch?v=00itwRzVwos"
          assetsPath={assetsPath}
        >
          Improving Cancer Care Through Knowledge Sharing
        </PublicationCard>
      </div>
      <div className={css.publicationCardContainer}>
        <PublicationCard
          date="2017"
          logo="current-oncology-logo.png"
          logoClassName="publicationsInstitutionLogo_current_oncology"
          link="https://www.ncbi.nlm.nih.gov/pubmed/28168606"
          assetsPath={assetsPath}
        >
          Strategies for Translating Evidence-Based Medicine in Lung Cancer into
          Community Practice. Current Oncology Reports
        </PublicationCard>
      </div>
    </div>
    <div className={css.publicationsButtonContainer}>
      <Link className={buttonClassName} pathname="/site/publications" external>
        View List of All Publications
      </Link>
    </div>
  </div>
)
