import React from 'react'
import _ from 'lodash'

import { Button } from 'pharmacy/src/input/button'

import QuestionSearch from 'pages/manageDigest/questionSearch'

import css from './addQuestionForm.scss'

class AddQuestionForm extends React.Component {
  handleSuggestionSelect = (hit) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldValue('questionId', hit.question_id)
    setFieldTouched('questionId')
  }

  render() {
    const {
      closeModal,
      errors,
      formRef,
      handleSubmit,
      isSubmitting,
      touched,
      values,
    } = this.props

    const submitIsDisabled = _.isEmpty(touched) || !_.isEmpty(errors)

    return (
      <form className={css.form} ref={formRef}>
        <QuestionSearch onSuggestionSelect={this.handleSuggestionSelect} />
        <input type="hidden" name="questionId" value={values.questionId} />
        <div className={css.buttonsContainer}>
          <Button onClick={closeModal} type="neutral">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={submitIsDisabled}
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

export default AddQuestionForm
