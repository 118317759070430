import React from 'react'

import { TextEditor } from 'pharmacy/src/input/textEditor'
import { Button } from 'pharmacy/src/input/button'
import {
  Header2,
  Body1,
  Header3,
  Subtitle2,
  Subtitle1,
} from 'pharmacy/src/typography'

import css from './answerQuestionsForm.scss'

class AnswerQuestionsForm extends React.Component {
  constructor(props) {
    super(props)

    const { conflictText } = this.props.defaultConflict || {}
    this.state = {
      conflictText: conflictText ? conflictText : '',
      hasConflicts: true,
    }
  }

  handleConflictTextChange = (evt) => {
    this.setState({
      conflictText: evt.target.value,
    })
  }

  handleConflictExistenceChange = (evt) => {
    this.setState({
      hasConflicts: !evt.target.checked,
    })
  }

  render() {
    const {
      isSubmitting,
      handleSubmit,
      formRef,
      questions,
      values,
      defaultConflict,
    } = this.props

    const { conflictId: defaultConflictId, conflictText: defaultConflictText } =
      defaultConflict || {}

    return (
      <form ref={formRef} className={css.form}>
        {questions.map(
          ({ questionId, question, detail, expertAnswer }, qidx) => (
            <div key={questionId} className={css.questionContainer}>
              <div>
                <Header2 dangerouslySetInnerHTML={{ __html: question }} />
                {detail && (
                  <Body1 dangerouslySetInnerHTML={{ __html: detail }} />
                )}
              </div>
              <div className={css.inputContainer}>
                <TextEditor
                  name={`answer[${questionId}][answer]`}
                  id={`answer[${questionId}][answer]`}
                  value={values.questions[qidx]}
                />
                {expertAnswer && (
                  <input
                    type="hidden"
                    name={`answer[${questionId}][answerId]`}
                    value={expertAnswer.answerId}
                  />
                )}
              </div>
            </div>
          )
        )}
        <div className={css.disclosureContainer}>
          <Header3>Disclosures</Header3>
          {this.state.hasConflicts && (
            <>
              <Subtitle2 className={css.disclosureSubtitle}>
                Enter your conflicts of interest Reelated to these answers
              </Subtitle2>

              <textarea
                className={css.textarea}
                name="conflict_text"
                value={this.state.conflictText}
                onChange={this.handleConflictTextChange}
              />
              {defaultConflictId &&
                defaultConflictText === this.state.conflictText && (
                  <input
                    type="hidden"
                    value={defaultConflictId}
                    name="conflict_id"
                  />
                )}
            </>
          )}

          <Subtitle1 className={css.checkbox}>
            <input
              type="checkbox"
              value={this.state.hasConflicts}
              onChange={this.handleConflictExistenceChange}
            />
            I do not have any conflicts of interest related to these answers
          </Subtitle1>
        </div>
        <div className={css.button}>
          <Button onClick={handleSubmit} isLoading={isSubmitting}>
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

export default AnswerQuestionsForm
