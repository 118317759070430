import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { Link } from 'pharmacy/src/navigation/link'

import css from './paginator.scss'

export class Paginator extends React.Component {
  static defaultProps = {
    maxPages: 10,
    activePageNumber: 0,
  }

  calculatePagesToShow = () => {
    const { maxPages, activePageNumber, pageCount } = this.props

    const leftSteps = Math.floor(maxPages / 2)
    let leftBound = Math.max(0, activePageNumber - leftSteps)

    const rightSteps = activePageNumber - leftBound
    const rightBound = Math.min(
      pageCount - 1,
      activePageNumber - 1 + maxPages - rightSteps
    )

    if (rightBound + 1 - leftBound < maxPages) {
      leftBound = Math.max(leftBound - 1, 0)
    }

    return _.range(leftBound, rightBound + 1)
  }

  render() {
    const { pageCount, activePageNumber, pathname } = this.props
    if (pageCount <= 1) {
      return null
    }

    const containerClasses = classNames(css.container, this.props.className)

    const leftArrowClasses = classNames(css.arrow, {
      [css.arrow_disabled]: activePageNumber === 0,
    })

    const rightArrowClasses = classNames(css.arrow, {
      [css.arrow_disabled]: activePageNumber === pageCount - 1,
    })

    return (
      <div className={containerClasses}>
        <Link
          className={leftArrowClasses}
          pathname={pathname}
          params={{ page: 0 }}
        >
          ◄◄
        </Link>
        <Link
          pathname={pathname}
          className={leftArrowClasses}
          params={{ page: activePageNumber - 1 }}
        >
          ◄
        </Link>
        {this.calculatePagesToShow().map((pageNumber) => {
          const pageClasses = classNames(css.page, {
            [css.page_active]: activePageNumber === pageNumber,
          })

          return (
            <Link
              className={pageClasses}
              key={pageNumber}
              pathname={pathname}
              params={{ page: pageNumber }}
            >
              {pageNumber + 1}
            </Link>
          )
        })}
        <Link
          pathname={pathname}
          className={rightArrowClasses}
          params={{ page: activePageNumber + 1 }}
        >
          ►
        </Link>
        <Link
          pathname={pathname}
          className={rightArrowClasses}
          params={{ page: pageCount - 1 }}
        >
          ►►
        </Link>
      </div>
    )
  }
}
