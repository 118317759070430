import React from 'react'

import FormScreen from './formScreen'

class MoreAnswersScreen extends React.Component {
  render() {
    const options = [
      {
        label: "I want a specific expert's opinion (specify the expert below)",
        value: 'expert',
      },
      {
        label: 'The answers don’t answer the question',
        value: 'answer',
      },
      {
        label: 'Other (specify below)',
        value: 'other',
      },
    ]

    return (
      <FormScreen
        subtitle="Please specify below why you want more opinions."
        options={options}
        requestType="more"
        {...this.props}
      />
    )
  }
}

export default MoreAnswersScreen
