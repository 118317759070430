import React from 'react'

import { Subtitle2 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'

import css from './breadcrumbs.scss'

class Breadcrumbs extends React.Component {
  render() {
    const { links } = this.props

    return (
      <div className={css.container}>
        {links.map((link, index) => {
          const { label, path, routeParams, ...restProps } = link

          const linkProps = path ? { route: link, routeParams } : restProps

          if (index < links.length - 1) {
            return (
              <Subtitle2 key={label}>
                <Link className={css.link} {...linkProps}>
                  {label}
                </Link>
                <span className={css.caret}>&gt;</span>
              </Subtitle2>
            )
          }

          return (
            <Subtitle2 key={label} className={css.current}>
              {label}
            </Subtitle2>
          )
        })}
      </div>
    )
  }
}

export default Breadcrumbs
