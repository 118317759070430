import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import { Button } from 'pharmacy/src/input/button'

import { useSelectUserById } from 'mednet-cns/src/hooks/user'

import { UserPhotoCard } from 'components/userPhotoCard'
import { CollapsedSpecialtyFilters } from 'components/specialtyFilters/specialtyFilters'

import AnimatedList from './animatedList'

import css from './specialtiesUsersPreviewList.scss'

const UserNameComponent = ({ children }) => {
  return <div className={css.userName}>{children}</div>
}

const UserTitleComponent = ({ children }) => {
  return <div className={css.userTitle}>{children}</div>
}

const UserCard = ({ userId, horizontal }) => {
  const user = useSelectUserById(userId)
  return (
    <div className={css.userCardContainer}>
      <div className={css.userCard}>
        <UserPhotoCard
          size="xsmall"
          showInstitution
          user={user}
          viewBio={false}
          CustomNameComponent={UserNameComponent}
          CustomTitleComponent={UserTitleComponent}
          horizontalInSmallScreens={horizontal}
        />
      </div>
    </div>
  )
}

const UsersListItem = ({ data, index, style }) => {
  if (Array.isArray(data[index])) {
    return (
      <div style={style} className={css.usersGroup}>
        {data[index].map((userId) => (
          <UserCard key={userId} userId={userId} horizontal />
        ))}
      </div>
    )
  }

  return (
    <div style={style}>
      <UserCard userId={data[index]} />
    </div>
  )
}

const UsersList = ({
  specialtiesUsersIds,
  filteredSpecialtyId,
  isForSmallScreen,
}) => {
  const cardsContainerRef = useRef(null)
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [numberOfVisibleUsers, setNumberOfVisibleUsers] = useState(4)

  const goNext = useCallback(() => {
    const maxIndex = isForSmallScreen
      ? specialtiesUsersIds[filteredSpecialtyId].length - 1
      : specialtiesUsersIds[filteredSpecialtyId].length - numberOfVisibleUsers

    const nextIndex = Math.min(currentIndex + numberOfVisibleUsers, maxIndex)

    if (nextIndex !== currentIndex) {
      setCurrentIndex(nextIndex)
      setPrevButtonDisabled(true)
      setNextButtonDisabled(true)
    }
  }, [
    setCurrentIndex,
    currentIndex,
    numberOfVisibleUsers,
    setPrevButtonDisabled,
    setNextButtonDisabled,
    specialtiesUsersIds,
    filteredSpecialtyId,
    isForSmallScreen,
  ])

  const goPrevious = useCallback(() => {
    const prevIndex = Math.max(currentIndex - numberOfVisibleUsers, 0)
    if (prevIndex !== currentIndex) {
      setCurrentIndex(prevIndex)
      setPrevButtonDisabled(true)
      setNextButtonDisabled(true)
    }
  }, [
    setCurrentIndex,
    currentIndex,
    numberOfVisibleUsers,
    setPrevButtonDisabled,
    setNextButtonDisabled,
  ])

  const onScrollEnd = useCallback(() => {
    let prevDisabled = false
    let nextDisabled = false
    if (currentIndex <= 0) {
      prevDisabled = true
    }

    if (
      currentIndex >=
      specialtiesUsersIds[filteredSpecialtyId].length - numberOfVisibleUsers
    ) {
      nextDisabled = true
    }

    setPrevButtonDisabled(prevDisabled)
    setNextButtonDisabled(nextDisabled)
  }, [
    currentIndex,
    setPrevButtonDisabled,
    setNextButtonDisabled,
    specialtiesUsersIds,
    filteredSpecialtyId,
    numberOfVisibleUsers,
  ])

  const calcNumberOfVisibleUsers = useCallback(() => {
    if (isForSmallScreen) {
      setNumberOfVisibleUsers(3)
    } else {
      const innerContainer = cardsContainerRef.current.firstChild
      const renderedUsersDivs = innerContainer.children

      const { left: containerLeft, right: containerRight } =
        cardsContainerRef.current.getBoundingClientRect()

      let visibleUsersDivsCount = 0

      for (const userDiv of renderedUsersDivs) {
        const { left: childLeft, right: childRight } =
          userDiv.getBoundingClientRect()
        if (childLeft >= containerLeft && childLeft < containerRight) {
          visibleUsersDivsCount++
        } else if (childRight > containerLeft && childRight <= containerRight) {
          visibleUsersDivsCount++
        }
      }
      setNumberOfVisibleUsers(visibleUsersDivsCount)
    }
  }, [setNumberOfVisibleUsers, cardsContainerRef])

  const listData = useMemo(() => {
    // For small screen each item has a group of 3 users
    if (isForSmallScreen) {
      const result = []
      let subArr = []

      for (
        let i = 0;
        i < specialtiesUsersIds[filteredSpecialtyId].length;
        i++
      ) {
        subArr.push(specialtiesUsersIds[filteredSpecialtyId][i])

        if (subArr.length === 3) {
          result.push(subArr)
          subArr = []
        }
      }

      if (!isEmpty(subArr)) {
        result.push(subArr)
      }

      return result
    }

    return specialtiesUsersIds[filteredSpecialtyId]
  }, [isForSmallScreen, specialtiesUsersIds, filteredSpecialtyId])

  useLayoutEffect(() => {
    calcNumberOfVisibleUsers()
    window.addEventListener('resize', calcNumberOfVisibleUsers)

    return () => {
      window.removeEventListener('resize', calcNumberOfVisibleUsers)
    }
  }, [])

  useLayoutEffect(() => {
    //reset scroll when filteredSpecialtyId changes
    setCurrentIndex(0)
    setPrevButtonDisabled(true)

    if (
      numberOfVisibleUsers >=
      specialtiesUsersIds[filteredSpecialtyId].length - numberOfVisibleUsers
    ) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }, [filteredSpecialtyId])

  return (
    <div
      className={classNames(css.usersContainer, {
        [css.displayForLargeScreen]: !isForSmallScreen,
        [css.displayForSmallScreen]: isForSmallScreen,
      })}
    >
      <Button
        type="dark_blue"
        shape="circle"
        className={css.prevButton}
        icon={['far', 'chevron-left']}
        onClick={goPrevious}
        isDisabled={prevButtonDisabled}
      />
      <AnimatedList
        height={isForSmallScreen ? 333 : 270}
        width={1168}
        duration={800}
        scrollToItem={isForSmallScreen ? currentIndex / 3 : currentIndex}
        itemCount={listData?.length}
        itemSize={isForSmallScreen ? 700 : 300} // for small screen set any large number and control the width with max-width in css
        layout="horizontal"
        itemData={listData}
        className={css.usersCardsContainer}
        outerRef={cardsContainerRef}
        onAnimationComplete={onScrollEnd}
      >
        {UsersListItem}
      </AnimatedList>
      <Button
        type="dark_blue"
        shape="circle"
        className={css.nextButton}
        icon={['far', 'chevron-right']}
        onClick={goNext}
        isDisabled={nextButtonDisabled}
      />
    </div>
  )
}

const SpecialtyFilter = (props) => {
  const { specialties, filteredSpecialtyId, setSpecialtyIdFilter } = props
  return (
    <>
      <div className={css.expandedSpecialtyFilterContainer}>
        {specialties.map((specialty) => {
          return (
            <div key={specialty.specialtyId}>
              <button
                className={classNames(css.specialtyFilterButton, {
                  [css.activeSpecialtyFilterButton]:
                    specialty.specialtyId === filteredSpecialtyId,
                })}
                onClick={() => setSpecialtyIdFilter(specialty.specialtyId)}
              >
                {specialty.specialty}
              </button>
            </div>
          )
        })}
      </div>
      <CollapsedSpecialtyFilters
        {...props}
        setSpecialtyFilter={setSpecialtyIdFilter}
        className={css.collapsedSpecialtyFilter}
        optionsConatinerClassName={css.specialtyOptionsConatinerClassName}
      />
    </>
  )
}

export const SpecialtiesUsersPreviewList = ({
  specialtiesUsersIds,
  specialtyIdFilter,
  setSpecialtyIdFilter,
  specialties,
}) => {
  return (
    <div className={css.usersAndFiltersContainer}>
      <SpecialtyFilter
        specialties={specialties}
        filteredSpecialtyId={specialtyIdFilter}
        setSpecialtyIdFilter={setSpecialtyIdFilter}
      />
      <>
        <UsersList
          specialtiesUsersIds={specialtiesUsersIds}
          filteredSpecialtyId={specialtyIdFilter}
        />
        <UsersList
          specialtiesUsersIds={specialtiesUsersIds}
          filteredSpecialtyId={specialtyIdFilter}
          isForSmallScreen
        />
      </>
    </div>
  )
}
