import _ from 'lodash'

const EXTERNAL_LINKS = {
  press: {
    label: 'Press Coverage',
    pathname: '/site/press',
  },
  work: {
    label: 'Careers',
    pathname: '/careers/index',
  },
  publications: {
    label: 'Publications',
    pathname: '/site/publications',
  },
  projects: {
    label: 'Projects',
    pathname: '/site/projects',
  },
  conduct: {
    label: 'Code of Conduct',
    pathname: '/site/conduct',
  },
  faq: {
    label: 'FAQ',
    pathname: '/topic/94',
  },
  editProfile: {
    label: 'Edit Profile',
    pathname: '/user/profile/edit',
  },
  mailbox: {
    label: 'Mailbox',
    pathname: '/message/inbox',
  },
  moderatorPage: {
    label: 'Moderator Page',
    pathname: '/question/moderatorpage',
  },
  associateLeaderboard: {
    label: 'Associate Leaderboard',
    pathname: '/associate/leaderboard',
  },
  admin: {
    label: 'Admin Page',
    pathname: '/home/admin',
  },
  customizeFeed: {
    label: 'Customize Feed',
    pathname: '/topic/index',
  },
  contact: {
    label: 'Contact Us',
    pathname: '/contact',
  },
  logout: {
    label: 'Logout',
    pathname: '/user/logout',
  },
  blog: {
    label: 'Blog',
    pathname: 'https://blog.themednet.org',
    target: '_blank',
  },
  termsOfUse: {
    label: 'Terms of Use',
    pathname: '/site/tos',
  },
  privacyPolicy: {
    label: 'Privacy Policy',
    pathname: '/site/privacy',
  },
  sitemap: {
    label: 'Sitemap',
    pathname: '/categories',
  },
}

_.each(EXTERNAL_LINKS, (linkObject) => {
  linkObject.external = true
})

export { EXTERNAL_LINKS }
