import React from 'react'

import { PageTitle } from 'components/pageTitle'
import { TitledSection } from 'components/titledSection/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import css from './programs.scss'

export const Programs = () => {
  return (
    <LandingPageContent>
      <PageTitle>Programs</PageTitle>
      <div className={css.mainContent}>
        <TitledSection
          header2="Special Educational Programs"
          headerUnderlined
          headerLeftAligned
          contentLeftAligned
        >
          Occasionally theMednet hosts special educational programs with peer to
          peer Q&A around topics of interest to our community which are
          supported by industry partners. These programs make up &lt;2% of the
          Q&A on theMednet.
          <br /> <br />
          All Q&A from these programs are labeled, to distinguish them from
          standard content on the site. The partner has no input into the Q&A or
          the experts selected to answer the questions. Questions are subjected
          to our standard editorial process. Experts are chosen based on their
          expertise in the given topic and do not receive compensation. In
          recognition of their time, an in-name donation is made to their chosen
          cancer research charity.
          <br /> <br />
          The financial support provided by this sponsorship helps us run
          theMednet, which provides physicians a platform to share their
          experiential knowledge and insights on the topics being discussed. Our
          top priority is that the Q&A is unbiased and truthful. If you have
          feedback or questions about these programs, let us know at
          feedback@themednet.org
        </TitledSection>
      </div>
    </LandingPageContent>
  )
}
