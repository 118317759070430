import React from 'react'
import { connect } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { Link2 } from 'pharmacy/src/typography'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import {
  toggleAgreeAnswer,
  toggleHelpfulAnswer,
} from 'mednet-cns/src/reducers/answer'
import { SHARE_MODAL } from 'mednet-util/src/constants/modal'

import css from './answerActions.scss'

export class AgreeButton extends React.Component {
  handleClick = () => {
    const { isAgreed, toggleAgreeAnswer } = this.props
    toggleAgreeAnswer(isAgreed)
  }

  render() {
    return (
      <Button
        className={css.link}
        icon={[this.props.isAgreed ? 'fas' : 'far', 'check-circle']}
        iconProps={{ size: 'lg' }}
        onClick={this.handleClick}
        type="text"
      >
        Agree
      </Button>
    )
  }
}
const GatedAgreeButton = withPermissions(authItems.createVote)(AgreeButton)

export class HelpfulButton extends React.Component {
  handleClick = () => {
    const { isHelpful, toggleHelpfulAnswer } = this.props
    toggleHelpfulAnswer(isHelpful)
  }

  render() {
    return (
      <Button
        className={css.link}
        icon={[this.props.isHelpful ? 'fas' : 'far', 'thumbs-up']}
        iconProps={{ size: 'lg' }}
        onClick={this.handleClick}
        type="text"
      >
        Helpful
      </Button>
    )
  }
}
const GatedHelpfulButton = withPermissions(authItems.createVote)(HelpfulButton)

export class CommentButton extends React.Component {
  render() {
    return (
      <Button
        className={css.commentLink}
        icon={['far', 'comment']}
        iconProps={{ size: 'lg' }}
        onClick={this.props.onClick}
        type="text"
      >
        Comment
      </Button>
    )
  }
}
const GatedCommentButton = withPermissions(authItems.createComment)(
  CommentButton
)

export class ShareButton extends React.Component {
  render() {
    return (
      <Button
        className={css.link}
        icon={['far', 'share']}
        iconProps={{ size: 'lg' }}
        onClick={this.props.openShareModal}
        type="text"
      >
        Share
      </Button>
    )
  }
}
const GatedShareButton = withPermissions(authItems.share)(ShareButton)

class AnswerActions extends React.Component {
  handleCommentClick = () => {
    this.props.commentInput.current.handleFocus()
  }

  render() {
    const {
      answerId,
      isAgreed,
      isHelpful,
      openShareModal,
      toggleAgreeAnswer,
      toggleHelpfulAnswer,
    } = this.props

    return (
      <Link2 className={css.container}>
        <GatedAgreeButton
          answerId={answerId}
          isAgreed={isAgreed}
          toggleAgreeAnswer={toggleAgreeAnswer}
        />
        <GatedHelpfulButton
          answerId={answerId}
          isHelpful={isHelpful}
          toggleHelpfulAnswer={toggleHelpfulAnswer}
        />
        <GatedCommentButton onClick={this.handleCommentClick} />
        <GatedShareButton openShareModal={openShareModal} />
      </Link2>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const answerObject = state.answer.answers[ownProps.answerId]
  const { isAgreed, isHelpful, questionId } = answerObject

  return {
    isAgreed,
    isHelpful,
    questionId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { answerId, itemIndex } = ownProps

  return {
    toggleAgreeAnswer: (prevAgreedState) =>
      dispatch(toggleAgreeAnswer(answerId, itemIndex, prevAgreedState)),
    toggleHelpfulAnswer: (prevHelpfulState) =>
      dispatch(toggleHelpfulAnswer(answerId, itemIndex, prevHelpfulState)),
    openShareModal: (questionId) => () =>
      dispatch(
        openModal(SHARE_MODAL.modalId, { answerId, questionId, itemIndex })
      ),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    openShareModal: dispatchProps.openShareModal(stateProps.questionId),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AnswerActions)
