import React from 'react'

import { Header2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import css from './finishedScreen.scss'

class FinishedScreen extends React.Component {
  render() {
    const { closeModal } = this.props

    return (
      <div className={css.container}>
        <Header2>Thanks for sharing!</Header2>
        <hr />
        <Button type="primary" onClick={closeModal} className={css.closeButton}>
          Close
        </Button>
      </div>
    )
  }
}

export default FinishedScreen
