import { all, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from 'mednet-cns/src/cns-util/reducer'

import { makeRequestName } from './request'

export const FETCH_EXPERTS = 'experts/FETCH_EXPERTS'
export const FETCH_EXPERTS_ONCOLOGY_MERGED =
  'experts/FETCH_EXPERTS_ONCOLOGY_MERGED'
export const FETCH_EXPERTS_COUNT = 'experts/FETCH_EXPERTS_COUNT'

export function fetchExpertsCount() {
  return {
    type: FETCH_EXPERTS_COUNT,
  }
}

export function fetchExperts() {
  return {
    type: FETCH_EXPERTS,
  }
}

export function fetchExpertOncologyMerged() {
  return {
    type: FETCH_EXPERTS_ONCOLOGY_MERGED,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_EXPERTS, 'Experts/ExpertsPublicJSON')
  yield makeFetchEffect(
    takeLatest,
    FETCH_EXPERTS_COUNT,
    'Experts/getExpertsCountPublicJSON'
  )
  yield makeFetchEffect(
    takeLatest,
    FETCH_EXPERTS_ONCOLOGY_MERGED,
    'Experts/ExpertsOncologyMergedPublicJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  expertsCount: 1200,
  specialtiesExperts: {},
  oncologySpecialtiesIds: [],
}

export function reducer(expertsState = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_EXPERTS_ONCOLOGY_MERGED):
    case receiveAction(FETCH_EXPERTS): {
      const specialtiesExperts = {}

      Object.entries(action.response.specialtiesExperts).forEach(
        ([specialtyId, specialtyExperts]) => {
          specialtiesExperts[specialtyId] = specialtyExperts.map(
            (expert) => expert.userId
          )
        }
      )

      return receiveReducer(expertsState, action, () => ({
        specialtiesExperts: {
          ...expertsState.specialtiesExperts,
          ...specialtiesExperts,
        },
        expertsCount: action.response.expertsCount,
      }))
    }

    case receiveAction(FETCH_EXPERTS_COUNT):
      return receiveReducer(expertsState, action, () => ({
        expertsCount: action.response.expertsCount,
      }))

    default:
      return expertsState
  }
}

export const getExperts = (state) => {
  return state.expert.specialtiesExperts
}

export const getExpertsRequest = (state) => {
  return get(state.request.requests, makeRequestName(FETCH_EXPERTS), {})
}

export const getExpertsSpecialtyFilter = (state) => {
  return state.expert.specialtyFilter
}
