import React from 'react'

const HomeIcon = ({ height, ...props }) => {
  return (
    <div {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4_4382)">
          <path
            d="M20 40V28H28V40H38V24H44L24 6L4 24H10V40H20Z"
            fill="#2F388D"
          />
        </g>
        <defs>
          <clipPath id="clip0_4_4382">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default HomeIcon
