import React from 'react'
import classNames from 'classnames'

import { renderMixed } from 'mednet-util/src/render'
import { Body1 } from 'pharmacy/src/typography'

import css from './baseTableRow.scss'

export class BaseTableRow extends React.Component {
  renderCellData(datum, column) {
    if (column.data.prototype && column.data.prototype.isReactComponent) {
      const SpecComponent = column.data
      return (
        <SpecComponent
          readOnly={this.props.readOnly}
          datum={datum}
          column={column}
          {...this.props.cellProps}
        />
      )
    }

    const renderedDatum = renderMixed(datum, column.data)

    if (column.type === 'raw') {
      return <div dangerouslySetInnerHTML={{ __html: renderedDatum }} />
    }

    return renderedDatum
  }

  render() {
    const { columns, datum, first, highlight, responsive } = this.props
    const cellClasses = classNames(css.cell, {
      [css.cell_first]: first,
      [css.cell_highlight]: highlight,
      [css.cell_responsive]: responsive,
    })

    const rowClasses = classNames(css.row, {
      [css.row_responsive]: responsive,
    })

    const headerClasses = classNames(css.header, {
      [css.header_responsive]: responsive,
    })

    return (
      <tr className={rowClasses}>
        {columns.map((column, index) => {
          const dataClasses = classNames(css.data, {
            [css[`data_${column.type}`]]: column.type,
            [css[`data_responsive_${column.type}`]]: responsive && column.type,
            [css.data_responsive]: responsive,
          })

          return (
            <td key={index} className={cellClasses}>
              <div className={headerClasses}>{column.header}</div>
              <Body1 className={dataClasses}>
                {this.renderCellData(datum, column)}
              </Body1>
            </td>
          )
        })}
      </tr>
    )
  }
}
