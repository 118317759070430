import React from 'react'

import InstitutionFeedItem from './institutionFeedItem'
import Feed from './feed'

class InstitutionFeed extends React.Component {
  render() {
    return (
      <Feed
        FeedItem={InstitutionFeedItem}
        feedURL="institutionRedux/getFeedJSON"
        itemKey="institutionId"
        {...this.props}
      />
    )
  }
}

export default InstitutionFeed
