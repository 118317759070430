import React from 'react'
import classNames from 'classnames'

import DropdownMenu from './dropdownMenu'

import css from './dropdownMenuGroup.scss'

class DropdownMenuGroup extends React.Component {
  render() {
    const {
      HeaderComponent,
      className,
      history,
      fullWidthItemsInSmallScreen,
      displayIcon,
      IconElement,
    } = this.props
    const containerClasses = classNames(className, css.container)

    return (
      <div className={containerClasses}>
        {this.props.menu.map((headerItem) => (
          <DropdownMenu
            key={headerItem.header}
            item={headerItem}
            HeaderComponent={HeaderComponent}
            displayIcon={displayIcon}
            IconElement={IconElement}
            hasArrow
            history={history}
            fullWidthItemsInSmallScreen={fullWidthItemsInSmallScreen}
          />
        ))}
      </div>
    )
  }
}

export default DropdownMenuGroup
