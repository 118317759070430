import React from 'react'

/* eslint-disable */
class Logo extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <svg width="100%" height="100%" viewBox="0 0 20269 4903" version="1.1">
          <defs />
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="mednetlogo-vector-(1)">
              <g id="_2289390479664" transform="translate(0.000000, 8.000000)">
                <polygon
                  id="Shape"
                  fill="#2F358F"
                  points="652 4213 0 3083 1126 2432 0 1782 652 651 1779 1302 1779 0 3084 0 3084 1302 4211 651 4864 1782 3737 2432 4864 3083 4211 4213 3084 3563 3084 4864 1779 4864 1779 3563"
                />
                <g
                  id="Group"
                  transform="translate(1935.000000, 898.000000)"
                  fill="#FEFEFE"
                >
                  <path
                    d="M414,2117 L398,1799 C589,1663 1234,1608 1043,1218 C987,1114 915,1067 780,1051 C780,1114 780,1210 764,1273 C748,1377 740,1361 653,1393 C549,1433 430,1496 350,1560 C175,1695 127,1831 287,2006 C302,2030 382,2101 414,2117 Z"
                    id="Shape"
                  />
                  <path
                    d="M446,1210 C438,1154 446,955 398,931 C390,923 390,923 374,923 C358,915 350,907 334,907 C279,883 247,860 199,820 C167,796 175,780 175,732 C295,653 326,700 541,621 C581,605 605,597 605,525 C541,509 509,485 422,485 C350,477 287,493 223,517 C-32,629 -72,907 127,1074 C199,1130 310,1226 446,1210 Z"
                    id="Shape"
                  />
                  <path
                    d="M748,2412 L557,2531 C486,2571 414,2611 366,2650 C279,2738 247,2857 358,2969 C382,2993 414,3025 446,3041 L446,2786 C517,2746 844,2539 884,2499 C907,2467 931,2436 939,2380 C939,2340 955,2237 947,2205 C939,2133 820,2054 748,2038 L748,2412 Z"
                    id="Shape"
                  />
                  <path
                    d="M501,772 L509,1313 C533,1321 565,1305 589,1297 C613,1289 613,1289 629,1281 C629,1281 653,1258 653,1250 C693,1210 677,1011 708,852 C724,796 708,700 708,637 C693,661 645,708 629,724 C605,732 525,764 501,772 Z"
                    id="Shape"
                  />
                  <path
                    d="M732,501 C740,366 796,64 661,16 C557,-32 486,40 470,127 C462,183 470,231 478,286 C486,366 470,350 549,366 C653,398 645,398 693,462 C708,477 724,485 732,501 Z"
                    id="Shape"
                  />
                  <path
                    d="M517,1855 L517,2364 C533,2356 597,2316 605,2300 C653,2253 621,1902 621,1831 L517,1855 Z"
                    id="Shape"
                  />
                  <path
                    d="M478,3359 C661,3367 772,3263 748,3096 C740,3033 732,2993 669,3009 C645,3311 541,3184 478,3359 Z"
                    id="Shape"
                  />
                </g>
              </g>
              <g
                id="_2289390477584"
                transform="translate(5481.000000, 0.000000)"
                fill="#4D5051"
              >
                <path
                  d="M876,4855 C876,4489 876,4123 876,3757 C876,3486 812,3001 1202,2985 C1608,2969 1528,3422 1528,3757 C1528,4123 1528,4489 1528,4855 L2221,4855 C2221,4489 2221,4123 2221,3757 C2221,3486 2157,3016 2547,2993 C2714,2985 2841,3048 2865,3223 C2889,3367 2873,4648 2873,4855 L3566,4855 C3566,4330 3558,3788 3566,3263 C3566,3112 3574,2937 3510,2794 C3343,2412 2754,2332 2404,2555 C2260,2650 2252,2706 2165,2833 C2149,2817 2085,2714 2061,2674 C1926,2499 1727,2427 1504,2435 C971,2443 891,2817 844,2841 L844,2483 L191,2483 L191,4855 L876,4855 Z"
                  id="Shape"
                />
                <path
                  d="M7004,3868 C6917,2754 7498,2849 7617,3016 C7712,3136 7728,3303 7736,3454 C7744,3613 7744,3836 7728,3995 C7697,4425 7322,4505 7139,4290 C7044,4194 7012,3995 7004,3868 Z M7052,4903 L7203,4903 C7330,4855 7482,4879 7673,4640 C7697,4600 7736,4513 7768,4489 L7768,4855 L8429,4855 L8429,1647 L7736,1647 C7736,1775 7752,2682 7728,2714 C7689,2698 7625,2531 7370,2459 C7203,2412 6988,2427 6829,2491 C6113,2778 6137,4314 6614,4712 C6797,4871 6869,4855 7052,4903 Z"
                  id="Shape"
                />
                <path
                  d="M4561,3375 C4577,3120 4696,2889 4982,2881 C5293,2873 5388,3104 5396,3375 L4561,3375 Z M4855,4903 L5118,4903 C5181,4871 5635,4879 5946,4401 C5985,4322 6073,4171 6073,4083 L5444,4035 C5420,4099 5436,4139 5388,4218 C5349,4266 5325,4298 5269,4330 C5070,4473 4752,4425 4624,4194 C4553,4051 4553,3940 4561,3788 L6089,3788 C6105,3550 6049,3255 5954,3040 C5643,2364 4720,2236 4179,2730 C3733,3152 3645,4171 4218,4648 C4354,4752 4505,4823 4672,4863 C4736,4879 4799,4887 4855,4903 Z"
                  id="Shape"
                />
                <path
                  d="M11541,3422 C11533,3247 11620,3056 11724,2921 C12034,2539 12838,2618 12838,3422 L11541,3422 Z M12066,4903 L12281,4903 C12385,4871 12591,4895 12870,4680 C13013,4568 13157,4393 13204,4210 C13180,4194 12894,4139 12854,4139 C12775,4306 12727,4417 12552,4513 C12377,4600 12186,4616 11995,4561 C11605,4425 11517,4075 11517,3701 L13236,3693 C13276,3263 13093,2746 12695,2531 C12313,2324 11708,2404 11429,2754 C10984,3295 10984,4163 11477,4640 C11692,4847 11859,4839 12066,4903 Z"
                  id="Shape"
                />
                <path
                  d="M9233,2921 L9233,2483 L8890,2483 L8890,4855 L9257,4855 C9257,4632 9257,4409 9257,4186 C9257,3964 9249,3725 9257,3510 C9296,2690 10060,2571 10315,2921 C10419,3064 10403,3343 10403,3510 C10403,3956 10403,4409 10403,4855 L10769,4855 C10769,4481 10769,4115 10769,3741 C10769,3446 10801,3001 10634,2754 C10458,2499 10164,2380 9861,2427 C9726,2451 9583,2515 9479,2603 C9432,2642 9384,2698 9344,2746 C9320,2778 9249,2913 9233,2921 Z"
                  id="Shape"
                />
                <path
                  d="M14327,4903 L14788,4903 L14788,4584 C14621,4592 14390,4648 14247,4545 C14104,4433 14128,4186 14128,4027 C14128,3605 14128,3176 14128,2754 L14661,2754 L14661,2467 L14128,2459 L14128,1838 L13761,1870 L13761,2459 L13348,2459 L13340,2754 L13761,2754 C13761,3207 13761,3653 13761,4107 C13761,4441 13825,4775 14183,4871 C14215,4879 14303,4895 14327,4903 Z"
                  id="Shape"
                />
                <path
                  d="M3478,1066 L2706,1066 C2690,899 2841,637 3088,637 C3335,629 3494,828 3478,1066 Z M3701,1536 L3494,1488 C3399,1703 3224,1823 2985,1743 C2746,1671 2690,1456 2690,1234 L3725,1234 C3757,1098 3677,828 3598,708 C3502,573 3359,485 3192,469 C2810,422 2523,668 2459,1035 C2396,1369 2507,1783 2857,1910 C3287,2077 3685,1735 3701,1536 Z"
                  id="Shape"
                />
                <path
                  d="M1305,0 L1082,0 L1082,1926 L1305,1926 C1313,1878 1297,1257 1305,1122 C1329,629 1783,533 1934,764 C2046,931 1958,1775 1990,1926 L2205,1926 C2213,1862 2213,1361 2213,1257 C2213,1074 2237,820 2133,668 C1974,406 1560,382 1369,661 C1353,684 1321,748 1305,764 L1305,0 Z"
                  id="Shape"
                />
                <path
                  d="M255,135 L255,485 L0,485 L0,661 L247,661 C271,915 231,1385 263,1608 C310,1982 565,1966 860,1934 L868,1767 C756,1751 637,1807 541,1735 C462,1679 470,1520 470,1425 C470,1257 462,796 478,668 L788,661 L788,485 C708,485 533,501 470,485 L470,111 L255,135 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}

export default Logo
