import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from 'pharmacy/src/display/modal'
import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import TextInput from 'pharmacy/src/input/textInput/textInput'

import { SEND_PREVIEW_MODAL } from 'mednet-util/src/constants/modal'

const MainScreen = ({ onSubmit, closeModal }) => {
  const { emailAddress } = useSelector((state) => state.user.data)
  const [to, setTo] = useState(`${emailAddress};`)
  const handleSubmit = () => {
    onSubmit(to)
  }

  return (
    <div>
      <Header2>Send preview of campaign to: (semicolon separated list)</Header2>
      <Subtitle2>
        To receive the preview, email address has to match a user from database
      </Subtitle2>
      <hr />
      <div>
        <TextInput onChange={setTo} value={to} />
      </div>
      <div>
        <Button type="secondary" onClick={closeModal}>
          Cancel
        </Button>
        {onSubmit && (
          <Button type="primary" onClick={handleSubmit}>
            Send!
          </Button>
        )}
      </div>
    </div>
  )
}

const screens = {
  [SEND_PREVIEW_MODAL.screens.sendTo]: MainScreen,
}

const SendPreviewModal = () => {
  return (
    <Modal
      closeOnBackdrop
      modalId={SEND_PREVIEW_MODAL.modalId}
      defaultScreen={SEND_PREVIEW_MODAL.screens.sendTo}
      screens={screens}
      size="large"
    ></Modal>
  )
}

export default SendPreviewModal
