import React from 'react'
import _ from 'lodash'

import { Header4 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'

import TopicSubsection from './topicSubsection'

import css from './topicSection.scss'

class TopicSection extends React.Component {
  static defaultProps = {
    defaultOpen: true,
    numExpected: 2,
  }

  constructor(props) {
    super(props)

    const { defaultOpen } = props

    this.state = {
      childrenOpen: defaultOpen,
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.isLoaded) {
      return Boolean(nextProps.isLoaded)
    }

    return true
  }

  toggleChildren = () => {
    const { childrenOpen } = this.state

    this.setState({
      childrenOpen: !childrenOpen,
    })
  }

  renderLoading() {
    const { header, defaultOpen, numExpected } = this.props

    return (
      <div>
        <Header4 className={css.sectionTitle}>{header}</Header4>
        <div className={css.sectionLinks}>
          {defaultOpen &&
            _.range(numExpected).map((idx) => (
              <div
                key={idx}
                className={css.loadingLink}
                style={{ width: `${_.random(80, 120)}px` }}
              />
            ))}
        </div>
      </div>
    )
  }

  render() {
    const { topics, header, isLoaded } = this.props

    if (!isLoaded) {
      return this.renderLoading()
    }

    if (topics.length === 0) {
      return null
    }

    return (
      <div>
        <Header4 className={css.sectionTitle} onClick={this.toggleChildren}>
          {header}
          <span>
            <Icon
              className={css.icon}
              icon={['fal', this.state.childrenOpen ? 'minus' : 'plus']}
            />
          </span>
        </Header4>
        {this.state.childrenOpen && (
          <div className={css.sectionLinks}>
            {topics.map((topicObject) => {
              return (
                <TopicSubsection
                  key={topicObject.topicId}
                  topicObject={topicObject}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default TopicSection
