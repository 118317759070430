import React, { useMemo } from 'react'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { useDeletePreset, usePresets } from 'mednet-cns/src/hooks/preset'

import { Button } from 'pharmacy/src/input/button'

import Table from 'components/tables/table'
import { ErrorPage } from 'pages/error'

import * as css from './table.scss'
import { ColumnTextFilter } from './cells'

export default function PresetTable({
  bodyHeight: bodyHeight = 250,
  onPresetLoad: handlePresetLoad,
}) {
  const [presets, requestStatus, reload] = usePresets()
  const { isLoading, errorStatus } = requestStatus

  const deletePreset = useDeletePreset(() => reload())
  const handlePresetDelete = (preset) => {
    deletePreset(preset.id)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 600,
        Filter: ColumnTextFilter,
        filter: 'includes',
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: 100,
        Cell: ({ row: { original: preset } }) => (
          <>
            <Button
              className={css.action}
              size="small"
              icon="play"
              onClick={() => handlePresetLoad(preset)}
            />
            <Button
              className={css.action}
              size="small"
              type="destructive"
              icon="trash"
              onClick={() => handlePresetDelete(preset)}
            />
          </>
        ),
      },
    ],
    [handlePresetLoad, handlePresetDelete]
  )

  if (errorStatus) {
    return <ErrorPage />
  }

  if (isLoading) {
    return <StarLoader />
  }

  return (
    <>
      <Table
        id="presets"
        columns={columns}
        data={presets}
        isLoading={isLoading}
        bodyHeight={bodyHeight}
        loadingDataError={Boolean(errorStatus)}
        errorMessage="Couldn't load presets, please contact system administrator."
      />
    </>
  )
}
