import { useEffect, useCallback } from 'react'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequestName } from 'mednet-cns/src/reducers/request'

/**
 * It builds a set of hooks to obtain data from backend, and flags to signalize if the data is loading or is already loaded.
 * First created hook is used to load the data straight away
 * Second created hook is used to lazy load the data
 *
 * Hook's params are used to build action spawning API request (they are passed to the @param action)
 * @param {Function} action action creator
 * @param {Function} selector store accessor
 * @returns {[Function, Function]} hook which returns [
 *  {Object} data,
 * {Object} requestStatus: {
 *  {Boolean} isLoading,
 *  {Boolean} isLoaded,
 *  {Int|Null} errorStatus,
 * }
 *  {Function} reload
 * ]
 */
const createDataHook = (action, selector) => {
  // The actual hook function (useSomeData)
  const hookFactory =
    (isLazy) =>
    (...args) => {
      const dispatch = useDispatch()

      // Action data to build request ID to grab the correct isLoaded/isLoading/errorStatus params
      const actionData = action(...args)

      // Load needed data from store
      const data = useSelector((state) => {
        return selector(state, actionData)
      })
      const requestParamsSelector = createSelector(
        (state) => state.request || {},
        (_state, actionData) =>
          makeRequestName(actionData.type, actionData.requestId),
        (request, requestName) =>
          request?.requests[requestName] || {
            isLoading: false,
            isLoaded: false,
            errorStatus: null,
          }
      )
      const { isLoaded, isLoading, errorStatus } = useSelector((state) =>
        requestParamsSelector(state, actionData)
      )

      // Function to load data
      const load = useCallback(
        (...reloadArgs) => {
          const useArgs = reloadArgs.length ? reloadArgs : args
          return new Promise((resolve) => {
            dispatch(
              action(...useArgs, (_a, _b, state) => {
                resolve(selector(state, actionData))
              })
            )
          })
        },
        [dispatch, actionData.requestId]
      )

      useEffect(() => {
        if (!isLoaded && !isLazy) {
          load()
        }
      }, [actionData.requestId])

      return [data, { isLoading, isLoaded, errorStatus }, load]
    }

  return [hookFactory(false), hookFactory(true)]
}

export default createDataHook
