import React from 'react'

import { Subtitle2 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'

import { EXTERNAL_LINKS } from 'constants/links'

import css from './questionFooter.scss'

const footerLinks = [
  EXTERNAL_LINKS.work,
  EXTERNAL_LINKS.press,
  EXTERNAL_LINKS.publications,
  EXTERNAL_LINKS.projects,
  EXTERNAL_LINKS.faq,
  EXTERNAL_LINKS.conduct,
  EXTERNAL_LINKS.contact,
]

class QuestionFooter extends React.Component {
  render() {
    return (
      <Subtitle2 className={css.container}>
        {footerLinks.map((link) => (
          <Link
            className={css.link}
            key={link.label}
            pathname={link.pathname}
            external
          >
            {link.label}
          </Link>
        ))}
      </Subtitle2>
    )
  }
}

export default QuestionFooter
