import React from 'react'

import { ProximaNova } from 'pharmacy/src/typography/index'

import classNames from 'classnames'

import { SectionHeader } from './sectionHeader'

import css from './titledSection.scss'

export const TitledSection = (props) => {
  const { children, contentLeftAligned, className, ...restProps } = props

  return (
    <div
      className={classNames(
        className,
        contentLeftAligned
          ? css.alignedLeftContainer
          : css.alignedCenterContainer
      )}
    >
      <div>
        <SectionHeader {...restProps} />
        <ProximaNova.Text>{children}</ProximaNova.Text>
      </div>
    </div>
  )
}
