import { all, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from 'mednet-cns/src/cns-util/reducer'

import { makeRequestName } from './request'

export const FETCH_DEPUTY_EDITORS = 'editors/FETCH_DEPUTY_EDITORS'
export const FETCH_ASSOCIATE_EDITORS = 'editors/FETCH_ASSOCIATE_EDITORS'
export const FETCH_FELLOWS = 'editors/FETCH_FELLOWS'
export const SET_ASSOCIATE_EDITORS_SPECIALTY_FILTER =
  'editors/SET_ASSOCIATE_EDITORS_SPECIALTY_FILTER'
export const SET_FELLOWS_SPECIALTY_FILTER =
  'editors/SET_FELLOWS_SPECIALTY_FILTER'

export function fetchDeputyEditors() {
  return {
    type: FETCH_DEPUTY_EDITORS,
  }
}

export function fetchAssociateEditors() {
  return {
    type: FETCH_ASSOCIATE_EDITORS,
  }
}

export function fetchFellows() {
  return {
    type: FETCH_FELLOWS,
  }
}

export function setAssociateEditorsSpecialtyFilter(specialtyId) {
  return {
    type: SET_ASSOCIATE_EDITORS_SPECIALTY_FILTER,
    associateEditorsSpecialtyId: specialtyId,
  }
}

export function setFellowsSpecialtyFilter(specialtyId) {
  return {
    type: SET_FELLOWS_SPECIALTY_FILTER,
    fellowsSpecialtyId: specialtyId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_DEPUTY_EDITORS,
    'Editors/DeputyEditorsPublicJSON'
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_ASSOCIATE_EDITORS,
    'Moderator/ModeratorsPublicJSON'
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_FELLOWS,
    'Associate/AssociatesPublicJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {}

function getSpecialtiesUsersIds(specialtiesUsers) {
  const specialtiesUsersIds = {}

  Object.entries(specialtiesUsers).forEach(([specialtyId, specialtyUsers]) => {
    specialtiesUsersIds[specialtyId] = specialtyUsers.map(
      (expert) => expert.userId
    )
  })

  return specialtiesUsersIds
}

export function reducer(editorsState = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_DEPUTY_EDITORS): {
      return receiveReducer(editorsState, action, () => ({
        deputyEditors: action.response.deputyEditors.map((user) => user.userId),
      }))
    }

    case receiveAction(FETCH_ASSOCIATE_EDITORS): {
      const specialtiesAssociateEditors = getSpecialtiesUsersIds(
        action.response.specialtiesModerators
      )

      return receiveReducer(editorsState, action, () => ({
        specialtiesAssociateEditors,
      }))
    }

    case receiveAction(FETCH_FELLOWS): {
      const specialtiesFellows = getSpecialtiesUsersIds(
        action.response.specialtiesAssociates
      )

      return receiveReducer(editorsState, action, () => ({
        specialtiesFellows,
      }))
    }

    case SET_ASSOCIATE_EDITORS_SPECIALTY_FILTER:
      return {
        ...editorsState,
        associateEditorsSpecialtyFilter: action.associateEditorsSpecialtyId,
      }

    case SET_FELLOWS_SPECIALTY_FILTER:
      return {
        ...editorsState,
        fellowsSpecialtyFilter: action.fellowsSpecialtyId,
      }

    default:
      return editorsState
  }
}

export const getDeputyEditors = (state) => {
  return state.editor.deputyEditors
}

export const getDeputyEditorsRequest = (state) => {
  return get(state.request.requests, makeRequestName(FETCH_DEPUTY_EDITORS), {})
}

export const getAssociateEditors = (state) => {
  return state.editor.specialtiesAssociateEditors
}

export const getAssociateEditorsRequest = (state) => {
  return get(
    state.request.requests,
    makeRequestName(FETCH_ASSOCIATE_EDITORS),
    {}
  )
}

export const getFellows = (state) => {
  return state.editor.specialtiesFellows
}

export const getFellowsRequest = (state) => {
  return get(state.request.requests, makeRequestName(FETCH_FELLOWS), {})
}

export const getAssociateEditorsSpecialtyFilter = (state) => {
  return state.editor.associateEditorsSpecialtyFilter
}

export const getFellowsSpecialtyFilter = (state) => {
  return state.editor.fellowsSpecialtyFilter
}
