import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_JOURNALS = 'journal/FETCH_JOURNALS'

export function fetchJournals(callback) {
  return {
    type: FETCH_JOURNALS,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_JOURNALS, 'journal/getJSON')
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  journals: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_JOURNALS): {
      return receiveReducer(state, action, () => ({
        journals: {
          ...state.journals,
          ..._.keyBy(action.response, 'journalId'),
        },
      }))
    }

    default:
      return state
  }
}

export function getJournals(state) {
  return Object.values(state.journal.journals)
}
