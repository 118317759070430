import React, { useRef, useState } from 'react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import { isEmpty, isEqual } from 'lodash'
import classNames from 'classnames'

import {
  useCmeApplications,
  useCmeGoals,
  useCmeImpacts,
} from 'mednet-cns/src/hooks/cme'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { Button } from 'pharmacy/src/input/button'

import css from './cmeAssessmentForm.scss'

const getValidationSchema = (activityKey) => {
  if (activityKey) {
    return yup.object().shape({
      [`goalId_${activityKey}`]: yup.number().required('Required'),
      [`applicationId_${activityKey}`]: yup.number().required('Required'),
      [`impactId_${activityKey}`]: yup.number().required('Required'),
    })
  }

  return yup.object().shape({
    goalId: yup.number().required('Required'),
    applicationId: yup.number().required('Required'),
    impactId: yup.number().required('Required'),
  })
}

const CmeAssessmentOptionsGroup = ({
  groupTitle,
  options,
  optionMapper,
  fieldName,
  uniqueKey,
  isDisabled,
  setFieldValue,
}) => {
  const uniqueFieldName = uniqueKey ? `${fieldName}_${uniqueKey}` : fieldName
  const refs = options.map((_option) => useRef())

  return (
    <div
      role="group"
      aria-labelledby={
        uniqueKey
          ? `cme-${groupTitle}-group_${uniqueKey}`
          : `cme-${groupTitle}-group`
      }
      className={css.optionsGroup}
    >
      <div className={css.optionsGroupTitle}>{groupTitle}:</div>

      {options.map((option, index) => {
        const { optionId, optionDescription } = optionMapper(option)
        return (
          <div
            key={optionId}
            className={classNames(css.option, {
              [css.disabledOption]: isDisabled,
            })}
          >
            <div>
              <Field
                type="radio"
                name={uniqueFieldName}
                value={optionId}
                disabled={isDisabled}
                id={uniqueFieldName}
                // using checked attribute here causes radio button selection not to work, instead add ref and change checked of that ref programmatically
                innerRef={refs[index]}
              />
              <label
                // htmlFor does not work for touch screen, so we have to handle the click to check the related checkbox
                onClick={() => {
                  setFieldValue(uniqueFieldName, `${optionId}`)
                  refs[index].current.checked = true
                }}
              >
                {optionDescription}
              </label>
            </div>
          </div>
        )
      })}

      <div className={css.separatorLine} />
    </div>
  )
}

const AssessmentForm = (props) => {
  const {
    goals,
    applications,
    impacts,
    vertical,
    activityKey,
    isSubmitting,
    errors,
    values,
    handleSubmit,
    fullWidthText,
    latestSubmittedValues,
    isDisabled,
    submissionError,
    setFieldValue,
  } = props

  const [showErrors, setShowErrors] = useState(false)

  return (
    <div>
      <div
        className={classNames(css.form, {
          [css.verticalForm]: vertical,
          [css.horizontalForm]: !vertical,
        })}
      >
        <CmeAssessmentOptionsGroup
          groupTitle="Goal"
          options={goals}
          optionMapper={({ goalId, description }) => ({
            optionId: goalId,
            optionDescription: description,
          })}
          fieldName="goalId"
          uniqueKey={activityKey}
          isDisabled={isSubmitting}
          setFieldValue={setFieldValue}
          values={values}
        />

        <CmeAssessmentOptionsGroup
          groupTitle="Application"
          options={applications}
          optionMapper={({ applicationId, description }) => ({
            optionId: applicationId,
            optionDescription: description,
          })}
          fieldName="applicationId"
          uniqueKey={activityKey}
          isDisabled={isSubmitting}
          setFieldValue={setFieldValue}
          values={values}
        />

        <CmeAssessmentOptionsGroup
          groupTitle="Impact"
          options={impacts}
          optionMapper={({ impactId, description }) => ({
            optionId: impactId,
            optionDescription: description,
          })}
          fieldName="impactId"
          uniqueKey={activityKey}
          isDisabled={isSubmitting}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>

      <div className={css.verticalContainer}>
        <Field
          name={
            activityKey ? `otherReflections_${activityKey}` : 'otherReflections'
          }
        >
          {({ field }) => (
            <textarea
              className={classNames(css.textarea, {
                [css.fullWidthTextArea]: fullWidthText,
              })}
              {...field}
              placeholder="(Optional): Please provide any additional reflections on learning gained from this activity"
              disabled={isSubmitting}
            />
          )}
        </Field>
        {showErrors && !isEmpty(errors) && (
          <div className={css.errorsMessage}>All checkboxes are required</div>
        )}

        {showErrors && !isEmpty(submissionError) && (
          <div className={css.errorsMessage}>{submissionError}</div>
        )}

        <div className={css.buttonContainer}>
          <Button
            size="small"
            className={css.button}
            isLoading={isSubmitting}
            isDisabled={
              isDisabled ||
              (latestSubmittedValues && isEqual(latestSubmittedValues, values))
            }
            onClick={(e) => {
              setShowErrors(true)
              handleSubmit(e)
            }}
          >
            {latestSubmittedValues ? 'Update' : 'Redeem'}
          </Button>
        </div>
      </div>
    </div>
  )
}

const ERROR_MESSAGES = {
  ALREADY_REDEEMED: 'You already redeemed this activity for this year.',
  MAX_REDEEMED: 'You already redeemed max CME credits for this year.',
  OTHER: 'Something went wrong.',
}

export const CmeAssessmentForm = ({
  vertical,
  handleFormSubmit,
  fullWidthText,
  activityKey, // Useful when form rendered multiple times in same page. Need to give each form group radio buttons different name
  allowUpdate,
  handleFormUpdateSubmit,
  isDisabled,
}) => {
  const [{ goals }, cmeGoalsRequest] = useCmeGoals()
  const [{ applications }, cmeApplicationsRequest] = useCmeApplications()
  const [{ impacts }, cmeImpactsRequest] = useCmeImpacts()
  const [latestSubmittedValues, setLatestSubmittedValues] = useState(undefined)
  const [submissionError, setSubmissionError] = useState(undefined)

  const initialValues = {}

  if (
    !cmeGoalsRequest.isLoaded ||
    !cmeApplicationsRequest.isLoaded ||
    !cmeImpactsRequest.isLoaded
  ) {
    return <StarLoader />
  }

  return (
    <Formik
      validationSchema={getValidationSchema(activityKey)}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        let updatedValues = values

        if (activityKey) {
          updatedValues = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [
              key.split('_')[0],
              value,
            ])
          )
        }

        if (latestSubmittedValues) {
          handleFormUpdateSubmit(
            updatedValues,
            { impacts, goals, applications },
            (res) => {
              setSubmitting(false)
              if (res.success) {
                setLatestSubmittedValues(values)
                setSubmissionError(undefined)
              } else {
                setSubmissionError(ERROR_MESSAGES.OTHER)
              }
            }
          )
        } else {
          handleFormSubmit(
            updatedValues,
            { impacts, goals, applications },
            (res) => {
              setSubmitting(false)
              if (res.success) {
                allowUpdate && setLatestSubmittedValues(values)
                setSubmissionError(undefined)
              } else if (res.alreadyRedeemed) {
                setSubmissionError(ERROR_MESSAGES.ALREADY_REDEEMED)
              } else if (res.redeemedMaxCmeForCurrentYear) {
                setSubmissionError(ERROR_MESSAGES.MAX_REDEEMED)
              } else {
                setSubmissionError(ERROR_MESSAGES.OTHER)
              }
            }
          )
        }
      }}
    >
      {(props) => (
        <AssessmentForm
          goals={goals}
          applications={applications}
          impacts={impacts}
          vertical={vertical}
          fullWidthText={fullWidthText}
          activityKey={activityKey}
          latestSubmittedValues={latestSubmittedValues}
          isDisabled={isDisabled}
          submissionError={submissionError}
          {...props}
        />
      )}
    </Formik>
  )
}
