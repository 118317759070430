import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Modal } from 'pharmacy/src/display/modal'

import { moveAnswer } from 'mednet-cns/src/reducers/answer'
import { MOVE_ANSWER_MODAL } from 'mednet-util/src/constants/modal'

import MoveAnswerForm from './moveAnswerForm'

class MoveAnswerScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(false)
    this.props.moveAnswer(new FormData(this.form.current))
    this.props.closeModal()
  }

  render() {
    const { answers, closeModal } = this.props
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={{
          answerId: null,
        }}
      >
        {(props) => (
          <MoveAnswerForm
            answers={answers}
            closeModal={closeModal}
            formRef={this.form}
            {...props}
          />
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { answers } = state.question.questions[questionId] || {}

  return {
    answers:
      answers &&
      _.filter(answers, (answerId) => answerId !== ownProps.answerId).map(
        (answerId) => {
          const answerObject = state.answer.answers[answerId]
          const answerUser = state.user.users[answerObject.userId]

          return _.assign({ user: answerUser }, answerObject)
        }
      ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    moveAnswer: (formData) =>
      dispatch(moveAnswer(ownProps.questionId, ownProps.answerId, formData)),
  }
}

const ConnectedMoveAnswersScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveAnswerScreen)

class MoveAnswerModal extends React.Component {
  render() {
    return (
      <Modal modalId={MOVE_ANSWER_MODAL.modalId}>
        <ConnectedMoveAnswersScreen {...this.props} />
      </Modal>
    )
  }
}

export default MoveAnswerModal
