import React from 'react'

import { PageHeader, Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import css from './errorPage.scss'

class Error404 extends React.Component {
  render() {
    const { homepageProps, backProps, history } = this.props

    return (
      <div>
        <PageHeader>Oops!</PageHeader>
        <Header1>The page you were looking for doesn’t exist.</Header1>
        <div className={css.buttonContainer}>
          <Button type="neutral" {...backProps(history)}>
            Go back
          </Button>
          <Button {...homepageProps(history)}>Go to homepage</Button>
        </div>
      </div>
    )
  }
}

export default Error404
