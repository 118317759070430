import React from 'react'
import classNames from 'classnames'

import { Button } from 'pharmacy/src/input/button'

import css from './headerRegisterButton.scss'

export const HeaderRegisterButton = ({ pathname }) => {
  const urlSearch = {
    src: pathname.replace(/^\/+/, '') || 'public_home', // remove leading forward slash
    src_path: pathname,
    src_item: 'header',
  }

  return (
    <>
      <Button
        type="dark_blue"
        className={classNames(css.button, css.displayForLargeScreen)}
        pathname="/register"
        params={urlSearch}
      >
        <div className={css.buttonText}>Register</div>
      </Button>
      <Button
        type="orange"
        className={classNames(css.button, css.displayForSmallScreen)}
        pathname="/register"
        params={urlSearch}
      >
        <div className={css.buttonText}>Register</div>
      </Button>
    </>
  )
}
