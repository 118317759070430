import React, { useCallback } from 'react'

import { Modal } from 'pharmacy/src/display/modal'
import { MocAssessmentForm } from 'pharmacy/src/cme/mocAssessmentForm'
import { Icon } from 'pharmacy/src/display/icon'

import { MOC_ASSESSMENT_MODAL } from 'mednet-util/src/constants/modal'
import { mixpanel } from 'mednet-util/src/tracking'

import css from './mocAssessmentModal.scss'

const MocAssessmentPrompt = (props) => {
  const { onExit, closeModal, location } = props
  const handleClosingMocModal = useCallback(() => {
    mixpanel.track('Skipped MOC assessment', {
      location,
    })
    onExit ? onExit() : closeModal()
  }, [location, onExit, closeModal])

  return (
    <>
      <Icon
        icon="times"
        className={css.closeIcon}
        onClick={handleClosingMocModal}
      />
      <MocAssessmentForm {...props} showAsCard={false} />
    </>
  )
}

export const MocAssessmentModal = (props) => {
  return (
    <Modal
      modalId={MOC_ASSESSMENT_MODAL.modalId}
      contentClassName={css.modalContainer}
      closeOnBackdrop
      size="large"
      {...props}
    >
      <MocAssessmentPrompt {...props} />
    </Modal>
  )
}
