import React from 'react'
import classNames from 'classnames'

import { Subtitle4 } from 'pharmacy/src/typography'

import * as css from './questionTopicTags.scss'

export default class QuestionTopicTags extends React.Component {
  onTopicClick = (topicId) => () => {
    const { onTopicClick } = this.props

    if (onTopicClick) {
      onTopicClick(topicId)
    }
  }

  render() {
    const { topics, primary, onTopicClick } = this.props
    const primaryId = primary && primary.topicId ? primary.topicId : primary

    return (
      <ul className={css.container}>
        {topics.map((topic) => (
          <li
            onClick={this.onTopicClick(topic.topicId)}
            key={topic.topicId}
            className={classNames({
              [css.tag]: true,
              [css.primary]: primary && topic.topicId === primaryId,
              [css.pointer]: Boolean(onTopicClick),
            })}
          >
            <Subtitle4>{topic.topic}</Subtitle4>
          </li>
        ))}
      </ul>
    )
  }
}
