import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import {
  DELETE_COMMENT_MODAL,
  MOVE_COMMENT_MODAL,
} from 'mednet-util/src/constants/modal'
import {
  FETCH_QUESTION_METADATA,
  fetchQuestionMetadata,
} from 'mednet-cns/src/reducers/question'
import {
  FETCH_ANSWER,
  fetchAnswer,
  deleteComment,
  moveComment,
} from 'mednet-cns/src/reducers/answer'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { Header1, Header2, Subtitle2, Header4 } from 'pharmacy/src/typography'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { ConfirmationModal } from 'pharmacy/src/display/modal'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { RawBody } from 'pharmacy/src/display/rawBody'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { Page } from 'pharmacy/src/display/page'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import ManageCommentsTable from './manageCommentsTable'

import css from './manageAnswerCommentsPage.scss'

class ManageAnswerCommentsPage extends React.Component {
  componentDidMount() {
    if (!this.props.answerIsLoaded) {
      this.props.fetchAnswer((response) => {
        this.props.fetchQuestionMetadata(response.answer.questionId)
      })
    }
  }

  handleDeleteConfirmationSubmit = ({ closeModal, commentId }) => {
    const { deleteComment } = this.props
    deleteComment(commentId)
    closeModal()
  }

  handleMoveConfirmationSubmit = ({ closeModal, commentId }) => {
    const { moveComment } = this.props
    moveComment(commentId)
    closeModal()
  }

  render() {
    const {
      answer,
      answerCreated,
      answerId,
      answerIsLoaded,
      answerUserId,
      metadataIsLoaded,
      question,
      questionCreated,
      questionId,
      questionUserId,
    } = this.props

    if (!metadataIsLoaded || !answerIsLoaded) {
      return <StarLoader />
    }

    return (
      <Page>
        <div className={css.content}>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              {
                ...ROUTES[PAGES.MANAGE_QUESTION_ANSWERS],
                routeParams: {
                  questionId,
                },
              },
              {
                label: answerId,
              },
            ]}
          />
          <Header1>Review Comments</Header1>
          <QuestionLink
            questionId={questionId}
            target="_blank"
            className={css.questionLink}
          >
            <Header2>{question}</Header2>
          </QuestionLink>
          <Subtitle2 className={css.questionCreated}>
            Created on {moment(questionCreated).format('LL')} by{' '}
            <UserNameLink userId={questionUserId} className={css.createdBy} />
          </Subtitle2>
          <Header4>Answer</Header4>
          <RawBody body={answer} />
          <Subtitle2 className={css.created}>
            Created on {moment(answerCreated).format('LL')} by{' '}
            <UserNameLink userId={answerUserId} className={css.createdBy} />
          </Subtitle2>
          <div className={css.tableContainer}>
            <ManageCommentsTable answerId={answerId} />
          </div>
        </div>
        <ConfirmationModal
          closeText="Cancel"
          closeType="neutral"
          header="Are you sure you want to delete this comment?"
          modalId={DELETE_COMMENT_MODAL.modalId}
          onSubmit={this.handleDeleteConfirmationSubmit}
          submitText="Delete"
          submitType="destructive"
          size="large"
        />
        <ConfirmationModal
          closeText="Cancel"
          closeType="neutral"
          header="Are you sure you want to change this comment to an answer?"
          modalId={MOVE_COMMENT_MODAL.modalId}
          onSubmit={this.handleMoveConfirmationSubmit}
          submitText="Yes"
          submitType="destructive"
          size="large"
        />
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { answerId } = ownProps.match.params

  const {
    questionId,
    answer,
    userId: answerUserId,
    created: answerCreated,
  } = state.answer.answers[answerId] || {}

  const {
    question,
    userId: questionUserId,
    created: questionCreated,
  } = state.question.questions[questionId] || {}
  const metadataRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_QUESTION_METADATA, questionId)
    ) || {}
  const answerRequest =
    _.get(state.request.requests, makeRequestName(FETCH_ANSWER, answerId)) || {}

  return {
    answer,
    answerCreated,
    answerId,
    answerUserId,
    question,
    questionCreated,
    questionId,
    questionUserId,
    metadataIsLoaded: metadataRequest.isLoaded,
    answerIsLoaded: answerRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { answerId } = ownProps.match.params

  return {
    fetchAnswer: (callback) => dispatch(fetchAnswer(answerId, callback)),
    fetchQuestionMetadata: (questionId) =>
      dispatch(fetchQuestionMetadata(questionId)),
    deleteComment: (commentId) => dispatch(deleteComment(answerId, commentId)),
    moveComment: (commentId) => dispatch(moveComment(answerId, commentId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAnswerCommentsPage)
