import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { ProximaNova } from 'pharmacy/src/typography/index'

import css from './sectionHeader.scss'

export const SectionHeader = (props) => {
  const {
    header1,
    header2,
    header3,
    header4,
    header5,
    headerLeftAligned,
    headerUnderlined,
  } = props

  const underLineClasses = classNames(
    css.shortUnderLine,
    headerLeftAligned ? css.LeftUnderLine : css.centeredUnderLine
  )

  return (
    <div
      className={
        headerLeftAligned
          ? css.alignedLeftContainer
          : css.alignedCenterContainer
      }
    >
      {!isEmpty(header1) && (
        <ProximaNova.Header1 className={css.headerText}>
          {header1}
        </ProximaNova.Header1>
      )}

      {!isEmpty(header2) && (
        <ProximaNova.Header2 className={css.headerText}>
          {header2}
        </ProximaNova.Header2>
      )}

      {!isEmpty(header3) && (
        <ProximaNova.Header3 className={css.headerText}>
          {header3}
        </ProximaNova.Header3>
      )}

      {!isEmpty(header4) && (
        <ProximaNova.Header4 className={css.headerText}>
          {header4}
        </ProximaNova.Header4>
      )}

      {!isEmpty(header5) && (
        <ProximaNova.Subtitle1 className={css.headerText}>
          {header5}
        </ProximaNova.Subtitle1>
      )}

      {headerUnderlined && <hr className={underLineClasses} />}
    </div>
  )
}
