import { takeLatest, all, takeLeading } from 'redux-saga/effects'
import assign from 'lodash/assign'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'
import concat from 'lodash/concat'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import reduce from 'lodash/reduce'

import { makeURL } from 'mednet-util/src/router'

import {
  receiveAction,
  requestAction,
  makeFetchEffect,
  receiveReducer,
} from 'mednet-cns/src/cns-util/reducer'
import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'
import { FOLLOWING_TYPE } from 'mednet-util/src/constants/following'

import { MOC_STATUS } from 'mednet-util/src/constants/cme'

import { FETCH_FEED } from './feed'
import { CREATE_CONFLICT, FETCH_ANSWER } from './answer'
import {
  FETCH_QUESTION_ANSWERS,
  FETCH_QUESTION_METADATA,
  FETCH_RELATED_QUESTIONS,
  FETCH_CAMPAIGN_QUESTION_ANSWERS,
  FETCH_CAMPAIGN_QUESTION_METADATA,
  FETCH_CAMPAIGN_RELATED_QUESTIONS,
  FETCH_SUGGESTED_USERS,
  CREATE_ANSWER,
  CREATE_ANSWER_AS_USER,
} from './question'

import { FETCH_TUMOR_BOARD_USERS } from './tumorBoard'
import { FETCH_EXPERTS, FETCH_EXPERTS_ONCOLOGY_MERGED } from './expert'
import {
  FETCH_DEPUTY_EDITORS,
  FETCH_ASSOCIATE_EDITORS,
  FETCH_FELLOWS,
} from './editor'
import { ANSWER_QUESTIONS } from './sponsorship'
import {
  CREATE_CME_CERTIFICATE,
  FETCH_QUESTION_CME_PREVIEW_ITEMS,
  REDEEM_MOC_ACTIVITY,
  UPDATE_MOC_PROFILE,
  UPDATE_MOC_PROFILE_BY_ID,
} from './cme'
import { FETCH_TESTIMONIALS_PUBLIC } from './testimonial'

export const FETCH_USER_WITH_PERMISSIONS = 'user/FETCH_USER_WITH_PERMISSIONS'
export const FETCH_USER_WITH_PERMISSIONS_PUBLIC =
  'user/FETCH_USER_WITH_PERMISSIONS_PUBLIC'
export const FETCH_USER_STATS = 'user/FETCH_USER_STATS'
export const FETCH_USER_PEERS = 'user/FETCH_USER_PEERS'
export const FETCH_USER_PROFILE = 'user/FETCH_USER_PROFILE'
export const INVITE_USER = 'user/INVITE_USER'
export const TOGGLE_FOLLOW_USER = 'user/TOGGLE_FOLLOW_USER'
export const FINISH_ONBOARDING = 'user/FINISH_ONBOARDING'
export const LOGIN = 'user/LOGIN'
export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD'
export const RESET_PASSWORD = 'user/RESET_PASSWORD'
export const FETCH_PUSH_NOTIFICATION_PERMISSIONS =
  'user/FETCH_PUSH_NOTIFICATION_PERMISSIONS'
export const TOGGLE_PUSH_NOTIFICATION_PERMISSION =
  'user/TOGGLE_PUSH_NOTIFICATION_PERMISSION'
export const FETCH_USER_MENTION_PROFILE = 'user/FETCH_USER_MENTION_PROFILE'
export const UPDATE_JOBS_ONBOARDING_STEP = 'user/UPDATE_JOBS_ONBOARDING_STEP'
export const FETCH_EMPLOYEES_BASIC_INFO = 'user/FETCH_EMPLOYEES_BASIC_INFO'
export const FETCH_USER_BIO = 'user/FETCH_USER_BIO'
export const FETCH_USER_BY_INVITE_CODE = 'user/FETCH_USER_BY_INVITE_CODE'
export const FETCH_COUNT_OF_ACTIVE_USERS = 'user/FETCH_COUNT_OF_ACTIVE_USERS'
export const QUERY_USERS = 'user/QUERY_USERS'
export const QUERY_ACTIVE_USERS = 'user/QUERY_ACTIVE_USERS'
export const QUERY_UNREGISTERED_USERS = 'user/QUERY_UNREGISTERED_USERS'
export const FETCH_INTEREST_CONFLICT = 'user/FETCH_INTEREST_CONFLICT'
export const FETCH_USER_MOC_PROFILE = 'user/FETCH_USER_MOC_PROFILE'
export const FETCH_USER_MOC_PROFILE_BY_ID = 'user/FETCH_USER_MOC_PROFILE_BY_ID'
export const FETCH_USER_CME_AND_MOC_AVAILABILITY_BY_ID =
  'user/FETCH_USER_CME_AND_MOC_AVAILABILITY_BY_ID'
export const BLOCK_INVITES = 'user/BLOCK_INVITES'
export const SUBSCRIBE_EXPERT_EMAILS = 'user/SUBSCRIBE_EXPERT_EMAILS'

export function getQueryRequestId(requestId) {
  return requestId ? requestId.split(':')[0] : ''
}

export function fetchCountOfActiveUsers() {
  return {
    type: FETCH_COUNT_OF_ACTIVE_USERS,
  }
}

export function queryUsers(
  requestId,
  query,
  ids,
  isFileImport,
  file,
  filePath,
  callback
) {
  return {
    type: QUERY_USERS,
    requestId,
    query,
    ids,
    isFileImport,
    file,
    filePath,
    callback,
  }
}

export function queryActiveUsers(
  requestId,
  query,
  ids,
  isFileImport,
  file,
  filePath,
  filterUsersWithoutRecentActivity,
  callback
) {
  return {
    type: QUERY_ACTIVE_USERS,
    requestId,
    query,
    ids,
    isFileImport,
    file,
    filePath,
    filterUsersWithoutRecentActivity,
    callback,
  }
}

export function queryUnregisteredUsers(
  requestId,
  query,
  ids,
  isFileImport,
  file,
  filePath,
  callback
) {
  return {
    type: QUERY_UNREGISTERED_USERS,
    requestId,
    query,
    ids,
    isFileImport,
    file,
    filePath,
    callback,
  }
}

export function fetchUserWithPermissions(callback) {
  return {
    type: FETCH_USER_WITH_PERMISSIONS,
    callback,
  }
}

export function fetchUserWithPermissionsPublic(callback) {
  return {
    type: FETCH_USER_WITH_PERMISSIONS_PUBLIC,
    callback,
  }
}

export function fetchUserStats() {
  return {
    type: FETCH_USER_STATS,
  }
}

export function fetchUserPeers() {
  return {
    type: FETCH_USER_PEERS,
  }
}

export function fetchUserProfile(userId, callback) {
  return {
    type: FETCH_USER_PROFILE,
    requestId: userId,
    userId,
    callback,
  }
}

export function fetchUserMOCProfileByID(userId, callback) {
  return {
    type: FETCH_USER_MOC_PROFILE_BY_ID,
    requestId: userId,
    userId,
    callback,
  }
}

export function fetchUserCmeAndMocAvailabilityById(userId, callback) {
  return {
    type: FETCH_USER_CME_AND_MOC_AVAILABILITY_BY_ID,
    requestId: userId,
    userId,
    callback,
  }
}

export function getEmployeesBasicInfo() {
  return {
    type: FETCH_EMPLOYEES_BASIC_INFO,
  }
}

export function getUserBio(userId) {
  return {
    type: FETCH_USER_BIO,
    userId,
  }
}

export function fetchUserByInviteCode(inviteCode) {
  return {
    type: FETCH_USER_BY_INVITE_CODE,
    requestId: inviteCode,
    inviteCode,
  }
}

export function inviteUser(userId) {
  return {
    type: INVITE_USER,
    requestId: userId,
    userId,
  }
}

export function toggleFollowUser(
  userId,
  callLocation,
  prevFollowedState,
  questionId = null
) {
  return {
    type: TOGGLE_FOLLOW_USER,
    requestId: userId,
    userId,
    questionId,
    callLocation,
    prevFollowedState,
  }
}

export function finishOnboarding() {
  return {
    type: FINISH_ONBOARDING,
  }
}

export function login(params, callback) {
  return {
    type: LOGIN,
    params,
    callback,
  }
}

export function forgotPassword(params, callback) {
  return {
    type: FORGOT_PASSWORD,
    params,
    callback,
  }
}

export function resetPassword(params, callback) {
  return {
    type: RESET_PASSWORD,
    params,
    callback,
  }
}

export function blockInvites(params, callback) {
  return {
    type: BLOCK_INVITES,
    params,
    callback,
  }
}

export function subscribeExpertEmails(params, callback) {
  return {
    type: SUBSCRIBE_EXPERT_EMAILS,
    params,
    callback,
  }
}

export function fetchPushNotificationPermissions() {
  return {
    type: FETCH_PUSH_NOTIFICATION_PERMISSIONS,
  }
}

export function togglePushNotificationPermission(params) {
  return {
    type: TOGGLE_PUSH_NOTIFICATION_PERMISSION,
    params,
  }
}

export function fetchUserMentionProfile(userId) {
  return {
    type: FETCH_USER_MENTION_PROFILE,
    requestId: userId,
    userId,
  }
}

export function updateJobsOnboardingStep(step) {
  return {
    type: UPDATE_JOBS_ONBOARDING_STEP,
    step,
  }
}

export function fetchInterestConflict(userId, callback) {
  return {
    type: FETCH_INTEREST_CONFLICT,
    userId,
    callback,
  }
}

export function fetchUserMocProfile(callback) {
  return {
    type: FETCH_USER_MOC_PROFILE,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_USER_WITH_PERMISSIONS,
    'user/user/getUserWithPermissions'
  )
  yield makeFetchEffect(
    takeLatest,
    QUERY_USERS,
    makeURL('user/queryUsersJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        query: JSON.stringify(action.query),
        ids: JSON.stringify(action.ids),
        isFileImport: action.isFileImport,
        file: action.file,
        filePath: action.filePath,
      })
  )
  yield makeFetchEffect(
    takeLatest,
    QUERY_ACTIVE_USERS,
    makeURL('user/queryActiveUsersJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        query: JSON.stringify(action.query),
        ids: JSON.stringify(action.ids),
        isFileImport: action.isFileImport,
        file: action.file,
        filePath: action.filePath,
        filterUsersWithoutRecentActivity:
          action.filterUsersWithoutRecentActivity,
      })
  )
  yield makeFetchEffect(
    takeLatest,
    QUERY_UNREGISTERED_USERS,
    makeURL('user/queryUnregisteredUsersJSON'),
    (action) =>
      makePOSTHeadersFromParams({
        query: JSON.stringify(action.query),
        ids: JSON.stringify(action.ids),
        isFileImport: action.isFileImport,
        file: action.file,
        filePath: action.filePath,
      })
  )
  yield makeFetchEffect(
    takeLatest,
    FETCH_USER_WITH_PERMISSIONS_PUBLIC,
    'user/user/getUserWithPermissionsPublic'
  )
  yield makeFetchEffect(takeLatest, FETCH_USER_STATS, 'user/user/getUserStats')
  yield makeFetchEffect(takeLatest, FETCH_USER_PEERS, 'user/user/getUserPeers')
  yield makeFetchEffect(takeLatest, INVITE_USER, (action) =>
    makeURL('invitation/inviteUserJSON', { userId: action.userId })
  )
  yield makeFetchEffect(takeLatest, TOGGLE_FOLLOW_USER, (action) =>
    makeURL('following/toggleJSON', {
      type: FOLLOWING_TYPE.USER,
      followingId: action.userId,
      questionId: action.questionId,
      callLocation: action.callLocation,
      prevFollowedState: action.prevFollowedState,
    })
  )
  yield makeFetchEffect(
    takeLatest,
    FINISH_ONBOARDING,
    'user/user/finishOnboardingJSON'
  )

  yield makeFetchEffect(
    takeLatest,
    LOGIN,
    makeURL('user/login/loginJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    BLOCK_INVITES,
    makeURL('inviteBlockedUser/addJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    SUBSCRIBE_EXPERT_EMAILS,
    makeURL('notificationPermissions/subscribeToExpertEmailsJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_USER_PROFILE, (action) => {
    return makeURL(`user/user/getProfileJSON/id/${action.userId}`)
  })

  yield makeFetchEffect(takeLatest, FETCH_USER_MOC_PROFILE_BY_ID, (action) => {
    return makeURL(
      `user/mocProfile/getUserMocProfileByIdJSON/id/${action.userId}`
    )
  })

  yield makeFetchEffect(
    takeLatest,
    FETCH_USER_CME_AND_MOC_AVAILABILITY_BY_ID,
    (action) =>
      makeURL(
        `user/mocProfile/getUserCmeAndMocAvailabilityByIdJSON/id/${action.userId}`
      )
  )

  yield makeFetchEffect(takeLatest, FETCH_USER_BIO, (action) =>
    makeURL(`user/user/getBioPublicJSON/id/${action.userId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_EMPLOYEES_BASIC_INFO,
    'user/internalEmployees/getInternalEmployees'
  )

  yield makeFetchEffect(
    takeLatest,
    FORGOT_PASSWORD,
    makeURL('user/recovery/recoveryJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    RESET_PASSWORD,
    makeURL('user/recovery/resetPasswordJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_PUSH_NOTIFICATION_PERMISSIONS,
    makeURL('pushNotificationsPermissions/getJSON')
  )

  yield makeFetchEffect(
    takeLatest,
    TOGGLE_PUSH_NOTIFICATION_PERMISSION,
    makeURL('pushNotificationsPermissions/toggleJSON'),
    (action) => makePOSTHeadersFromParams(action.params)
  )

  yield makeFetchEffect(takeLatest, FETCH_USER_MENTION_PROFILE, (action) =>
    makeURL(`user/user/getMentionProfileJSON/id/${action.userId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    UPDATE_JOBS_ONBOARDING_STEP,
    makeURL('user/profile/updateJobsOnboardingJSON'),
    (action) => makePOSTHeadersFromParams({ step: action.step })
  )

  yield makeFetchEffect(takeLatest, FETCH_USER_BY_INVITE_CODE, (action) =>
    makeURL(`user/user/GetUserByInviteCodeJSON/invitecode/${action.inviteCode}`)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_COUNT_OF_ACTIVE_USERS,
    makeURL(`user/user/getActiveUsersCountPublicJSON`)
  )

  yield makeFetchEffect(takeLatest, FETCH_INTEREST_CONFLICT, (action) =>
    makeURL(
      `conflictsInterest/getUserDefaultConflictJSON/userId/${action.userId}`
    )
  )

  yield makeFetchEffect(
    takeLeading,
    FETCH_USER_MOC_PROFILE,
    'user/mocProfile/getUserMocProfileJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  users: {
    0: {
      userId: 0,
      firstName: 'Community',
      lastName: 'Member',
    },
  },
  data: {},
  queries: {},
  activeUsersCount: 14000,
}

const updateUserObject = (existingObject, newObject) => {
  return assign(existingObject || {}, newObject)
}

const getUpdatedUsers = (existingUsers, newUsers) => {
  return keyBy(
    map(
      filter(newUsers, (item) => item && item.userId),
      (item) => updateUserObject(existingUsers[item.userId], item)
    ),
    'userId'
  )
}

const getAnswerUsers = (existingUsers, answer) => {
  return getUpdatedUsers(
    existingUsers,
    flatten(
      concat(
        answer.user,
        map(['votes', 'opinions', 'comments', 'peerReview.users'], (arrayKey) =>
          map(get(answer, arrayKey, []), (arrayObject) => arrayObject.user)
        )
      )
    )
  )
}

const getNewUsersFromUserData = (state, userData) => {
  const { userId } = userData

  const newUsers = {
    ...state.users,
    [userId]: {
      ...state.users[userId],
      ...userData,
    },
  }

  const { onboardingQuestion } = userData

  if (onboardingQuestion) {
    newUsers[onboardingQuestion.lastUpdate.user.userId] =
      onboardingQuestion.lastUpdate.user
  }

  return newUsers
}

const updateUsers = (users, updatedUsers) => {
  updatedUsers.forEach((user) => {
    users[user.userId] = {
      ...users[user.userId],
      ...user,
      degrees: Array.isArray(user.degrees)
        ? user.degrees
        : user.degrees.split(',').filter((degree) => degree && degree.length),
    }
  })
}

const addConflictToUsers = (userData, users, conflictUserId, conflict) => {
  if (!conflict) {
    return {}
  }

  return {
    users: {
      ...users,
      [conflictUserId]: {
        ...(users[conflictUserId] || {}),
        defaultConflict: conflict,
      },
    },
    data: {
      ...userData,
      defaultConflict:
        conflictUserId !== userData.userId
          ? userData.defaultConflict
          : {
              conflictId: conflict.conflictId,
              conflictText: conflict.conflictText,
            },
    },
  }
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_USER_WITH_PERMISSIONS): {
      const { user, permissions } = action.response

      return receiveReducer(state, action, () => ({
        permissions: {
          items: permissions,
        },
        users: getNewUsersFromUserData(state, user),
        data: {
          ...state.data,
          ...user,
          recaptchaKey: state.data.recaptchaKey,
        },
      }))
    }

    case receiveAction(QUERY_ACTIVE_USERS):
    case receiveAction(QUERY_UNREGISTERED_USERS):
    case receiveAction(QUERY_USERS): {
      const { requestId, response } = action
      const { users } = response

      return receiveReducer(state, action, () => {
        return {
          queries: {
            ...state.queries,
            [getQueryRequestId(requestId)]: users.map((user) => {
              user.firstName = String(user.firstName)
              user.lastName = String(user.lastName)
              return user
            }),
          },
        }
      })
    }

    case receiveAction(FETCH_USER_WITH_PERMISSIONS_PUBLIC): {
      const { user, permissions, recaptchaKey } = action.response

      if (user) {
        return receiveReducer(state, action, () => ({
          permissions: {
            items: permissions,
          },
          users: getNewUsersFromUserData(state, user),
          data: {
            ...state.data,
            ...user,
            recaptchaKey,
          },
        }))
      }

      return receiveReducer(state, action, () => ({
        data: {
          recaptchaKey,
        },
      }))
    }

    case receiveAction(FETCH_USER_PROFILE): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          [action.userId]: {
            ...state.users[action.userId],
            ...action.response.user,
          },
        },
      }))
    }

    case receiveAction(FETCH_USER_STATS): {
      return receiveReducer(state, action, () => ({
        stats: {
          ...action.response,
        },
      }))
    }

    case receiveAction(FETCH_USER_PEERS): {
      return receiveReducer(state, action, () => ({
        peers: uniq([
          ...(state.peers || []),
          ...action.response.peers.map((peer) => peer.userId),
        ]),
        users: {
          ...state.users,
          ...keyBy(action.response.peers, 'userId'),
        },
      }))
    }

    case requestAction(UPDATE_JOBS_ONBOARDING_STEP): {
      return {
        ...state,
        data: {
          ...state.data,
          jobsOnboardingStep: Math.max(
            state.data.jobsOnboardingStep,
            action.step
          ),
        },
      }
    }

    case requestAction(INVITE_USER): {
      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: {
            ...state.users[action.userId],
            isInvited: true,
          },
        },
      }
    }

    case requestAction(TOGGLE_FOLLOW_USER): {
      const userObject = state.users[action.userId]

      return {
        ...state,
        users: {
          ...state.users,
          [action.userId]: {
            ...userObject,
            isFollowed: !userObject.isFollowed,
          },
        },
      }
    }

    case receiveAction(FETCH_FEED): {
      if (action.itemKey === 'userId') {
        return receiveReducer(state, action, () => ({
          users: {
            ...state.users,
            ...getUpdatedUsers(state.users, action.response.items),
          },
        }))
      }

      if (action.itemKey === 'questionId') {
        return receiveReducer(state, action, () => ({
          users: {
            ...state.users,
            ...getUpdatedUsers(
              state.users,
              map(action.response.items, (item) => item.lastUpdate.user)
            ),
          },
        }))
      }

      return state
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_METADATA):
    case receiveAction(FETCH_QUESTION_METADATA): {
      const { user } = action.response
      if (!user) {
        return state
      }

      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          [user.userId]: {
            ...state.users[user.userId],
            ...user,
          },
        },
      }))
    }

    case receiveAction(FETCH_CAMPAIGN_QUESTION_ANSWERS):
    case receiveAction(FETCH_QUESTION_ANSWERS): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...reduce(
            map(action.response.answers, (answer) =>
              getAnswerUsers(state.users, answer)
            ),
            (res, val) => assign(res, val),
            {}
          ),
        },
      }))
    }

    case receiveAction(FETCH_ANSWER): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...getAnswerUsers(state.users, action.response.answer),
        },
      }))
    }

    case receiveAction(FETCH_CAMPAIGN_RELATED_QUESTIONS):
    case receiveAction(FETCH_RELATED_QUESTIONS): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...getUpdatedUsers(
            state.users,
            action.response.relatedQuestions.map(
              (item) => item.lastUpdate && item.lastUpdate.user
            )
          ),
        },
      }))
    }

    case receiveAction(FETCH_SUGGESTED_USERS): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...getUpdatedUsers(state.users, action.response.suggestedUsers),
        },
      }))
    }

    case receiveAction(CREATE_ANSWER_AS_USER):
    case receiveAction(CREATE_ANSWER): {
      return receiveReducer(state, action, () => {
        if (action.response.success === false) {
          return {}
        }

        const updatedUsers = {
          ...state.users,
          ...getAnswerUsers(state.users, action.response.answer),
        }

        if (
          !action.response.conflict ||
          !action.response.answer?.user?.userId
        ) {
          return receiveReducer(state, action, () => updateUsers)
        }

        const answerUserId = parseInt(action.response.answer.user.userId)

        return receiveReducer(state, action, () =>
          addConflictToUsers(
            state.data,
            updatedUsers,
            answerUserId,
            action.response.conflict
          )
        )
      })
    }

    case receiveAction(LOGIN): {
      return receiveReducer(state, action, () => ({
        login: action.response,
      }))
    }

    case receiveAction(FETCH_PUSH_NOTIFICATION_PERMISSIONS): {
      return receiveReducer(state, action, () => ({
        pushNotificationPermissions: action.response.permissions,
      }))
    }

    case requestAction(TOGGLE_PUSH_NOTIFICATION_PERMISSION): {
      return {
        ...state,
        pushNotificationPermissions: state.pushNotificationPermissions.map(
          (permissionObject) => {
            if (permissionObject.permission !== action.params.permission) {
              return permissionObject
            }

            return {
              ...permissionObject,
              value: permissionObject.value === 1 ? 0 : 1,
            }
          }
        ),
      }
    }

    case receiveAction(FETCH_USER_BIO):
    case receiveAction(FETCH_USER_MENTION_PROFILE): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          [action.userId]: {
            ...state.users[action.userId],
            ...action.response,
          },
        },
      }))
    }

    case receiveAction(FETCH_USER_BY_INVITE_CODE): {
      if (action.response) {
        return receiveReducer(state, action, () => ({
          users: {
            ...state.users,
            [action.response.userId]: {
              ...state.users[action.response.userId],
              ...action.response,
            },
          },
        }))
      }

      return state
    }

    case receiveAction(FETCH_EMPLOYEES_BASIC_INFO): {
      const employeesList = action.response.map((employee) => ({
        ...employee,
        degrees: employee.degrees ? employee.degrees.split(',') : [],
        isEmployee: true,
      }))
      const employees = employeesList.reduce(
        (prev, cur) => ({ ...prev, [cur.userId]: cur }),
        {}
      )
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...employees,
        },
      }))
    }

    case receiveAction(FETCH_TUMOR_BOARD_USERS): {
      const users = state.users

      Object.values(action.response.experts).forEach((tumorBoardExperts) =>
        updateUsers(state.users, tumorBoardExperts)
      )

      Object.values(action.response.siteLeaders).forEach(
        (tumorBoardSiteLeader) =>
          updateUsers(state.users, [tumorBoardSiteLeader])
      )

      return receiveReducer(state, action, () => ({
        users,
      }))
    }

    case receiveAction(FETCH_EXPERTS_ONCOLOGY_MERGED):
    case receiveAction(FETCH_EXPERTS): {
      const users = state.users

      Object.values(action.response.specialtiesExperts).forEach(
        (specialityExperts) => updateUsers(users, specialityExperts)
      )

      return receiveReducer(state, action, () => ({
        users,
      }))
    }

    case receiveAction(FETCH_TESTIMONIALS_PUBLIC): {
      const users = state.users

      updateUsers(
        users,
        action.response.map((testimonial) => ({
          userId: testimonial.userId,
          firstName: testimonial.firstName,
          lastName: testimonial.lastName,
          middleName: testimonial.middleName,
          imageVersion: testimonial.imageVersion,
          degrees: testimonial.degrees,
          institutionName: testimonial.institutionName,
        }))
      )

      return receiveReducer(state, action, () => ({
        users,
      }))
    }
    case receiveAction(FETCH_DEPUTY_EDITORS): {
      const users = state.users
      updateUsers(users, action.response.deputyEditors)

      return receiveReducer(state, action, () => ({
        users,
      }))
    }

    case receiveAction(FETCH_ASSOCIATE_EDITORS): {
      const users = state.users

      Object.values(action.response.specialtiesModerators).forEach(
        (specialityAssociateEditors) =>
          updateUsers(users, specialityAssociateEditors)
      )

      return receiveReducer(state, action, () => ({
        users,
      }))
    }

    case receiveAction(FETCH_FELLOWS): {
      const users = state.users

      Object.values(action.response.specialtiesAssociates).forEach(
        (specialityFellows) => updateUsers(users, specialityFellows)
      )

      return receiveReducer(state, action, () => ({
        users,
      }))
    }

    case receiveAction(FETCH_COUNT_OF_ACTIVE_USERS): {
      return receiveReducer(state, action, () => ({
        activeUsersCount: action.response.activeUsersCount,
      }))
    }

    case receiveAction(FINISH_ONBOARDING): {
      return receiveReducer(state, action, () => ({
        data: {
          ...state.data,
          onboardingQuestion: undefined,
        },
      }))
    }

    case receiveAction(ANSWER_QUESTIONS): {
      if (action.response.success === false || !action.response.conflict) {
        return state
      }

      const conflictUserId = parseInt(action.userId)

      return receiveReducer(state, action, () =>
        addConflictToUsers(
          state.data,
          state.users,
          conflictUserId,
          action.response.conflict
        )
      )
    }
    case receiveAction(CREATE_CONFLICT): {
      if (action.response.success === false || !action.response.conflict) {
        return state
      }

      const conflictUserId = parseInt(action.response.conflict.userId)
      return receiveReducer(state, action, () =>
        addConflictToUsers(
          state.data,
          state.users,
          conflictUserId,
          action.response.conflict
        )
      )
    }

    case receiveAction(FETCH_INTEREST_CONFLICT): {
      const conflictUserId = parseInt(action.userId)
      return receiveReducer(state, action, () =>
        addConflictToUsers(
          state.data,
          state.users,
          conflictUserId,
          action.response.conflict
        )
      )
    }

    case receiveAction(CREATE_CME_CERTIFICATE): {
      return receiveReducer(state, action, () => {
        if (!action.response.success || !action.formData.updateMocProfile) {
          return state
        }
        return {
          data: {
            ...state.data,
            abim: action.formData.abimNumber,
            abp: action.formData.abpNumber,
            birthDate: action.formData.birthDate,
          },
        }
      })
    }

    case receiveAction(FETCH_QUESTION_CME_PREVIEW_ITEMS): {
      return receiveReducer(state, action, () => ({
        users: {
          ...state.users,
          ...getUpdatedUsers(
            state.users,
            action.response.updates.map((item) => item.user)
          ),
        },
      }))
    }

    case receiveAction(REDEEM_MOC_ACTIVITY): {
      return receiveReducer(state, action, () => {
        if (!action.response.success) {
          return state
        }

        const updatedData = {
          ...state.data,
          abim: action.formData.abimNumber,
          abp: action.formData.abpNumber,
          birthDate: action.formData.birthDate,
        }

        if (action.formData.optOutMoc) {
          updatedData.cmeAndMocAvailability.mocStatus = MOC_STATUS.MOC_DISABLED
        }

        return {
          data: updatedData,
          users: getNewUsersFromUserData(state, updatedData),
        }
      })
    }

    case receiveAction(UPDATE_MOC_PROFILE): {
      return receiveReducer(state, action, () => {
        if (!action.response.success) {
          return state
        }

        let updatedMocStatus = state.data.cmeAndMocAvailability?.mocStatus

        if (action.formData.optOutMoc) {
          updatedMocStatus = MOC_STATUS.MOC_DISABLED
        } else if (action.formData.abimEnabled && action.formData.abpEnabled) {
          updatedMocStatus = MOC_STATUS.BOTH_MOC_ENABLED
        } else if (action.formData.abimEnabled) {
          updatedMocStatus = MOC_STATUS.MOC_ABIM_ENABLED
        } else if (action.formData.abpEnabled) {
          updatedMocStatus = MOC_STATUS.MOC_ABP_ENABLED
        } else {
          updatedMocStatus = MOC_STATUS.MOC_DISABLED
        }

        const updatedData = {
          ...state.data,
          abim:
            updatedMocStatus & MOC_STATUS.MOC_ABIM_ENABLED // bitwise and
              ? action.formData.abimNumber
              : state.data.abim,
          abp:
            updatedMocStatus & MOC_STATUS.MOC_ABP_ENABLED // bitwise and
              ? action.formData.abpNumber
              : state.data.abp,
          birthDate: action.formData.birthDate,
          cmeAndMocAvailability: {
            ...(state.data.cmeAndMocAvailability || {}),
            mocStatus: parseInt(updatedMocStatus),
          },
        }

        return {
          data: updatedData,
          users: getNewUsersFromUserData(state, updatedData),
        }
      })
    }

    case receiveAction(FETCH_USER_MOC_PROFILE): {
      return receiveReducer(state, action, () => {
        const { mocStatus, ...restResponse } = action.response || {}

        const updatedUserData = {
          ...state.data,
          ...restResponse,
          cmeAndMocAvailability: {
            ...(state.data.cmeAndMocAvailability || {}),
            mocStatus: parseInt(mocStatus),
          },
        }

        return {
          data: {
            ...updatedUserData,
            cmeAndMocAvailability: {
              ...(state.data.cmeAndMocAvailability || {}),
              mocStatus: parseInt(mocStatus),
            },
          },
          users: getNewUsersFromUserData(state, updatedUserData),
        }
      })
    }

    case receiveAction(FETCH_USER_MOC_PROFILE_BY_ID): {
      return receiveReducer(state, action, () => {
        const { mocStatus, ...restResponse } = action.response || {}

        return {
          ...state,
          users: {
            ...state.users,
            [action.userId]: {
              ...(state.users[action.userId] || {}),
              ...restResponse,
              cmeAndMocAvailability: {
                ...(state.cmeAndMocAvailability || {}),
                mocStatus: parseInt(mocStatus),
              },
            },
          },
        }
      })
    }

    case receiveAction(FETCH_USER_CME_AND_MOC_AVAILABILITY_BY_ID): {
      return receiveReducer(state, action, () => {
        return {
          ...state,
          users: {
            ...state.users,
            [action.userId]: {
              ...(state.users[action.userId] || {}),
              cmeAndMocAvailability: {
                ...(state.cmeAndMocAvailability || {}),
                ...(action.response || {}),
              },
            },
          },
        }
      })
    }

    case receiveAction(UPDATE_MOC_PROFILE_BY_ID): {
      return receiveReducer(state, action, () => {
        if (!action.response.success) {
          return state
        }

        let updatedMocStatus = state.data.cmeAndMocAvailability?.mocStatus

        if (action.formData.optOutMoc) {
          updatedMocStatus = MOC_STATUS.MOC_DISABLED
        } else if (action.formData.abimEnabled && action.formData.abpEnabled) {
          updatedMocStatus = MOC_STATUS.BOTH_MOC_ENABLED
        } else if (action.formData.abimEnabled) {
          updatedMocStatus = MOC_STATUS.MOC_ABIM_ENABLED
        } else if (action.formData.abpEnabled) {
          updatedMocStatus = MOC_STATUS.MOC_ABP_ENABLED
        } else {
          updatedMocStatus = MOC_STATUS.MOC_DISABLED
        }

        return {
          ...state,
          users: {
            ...state.users,
            [action.userId]: {
              ...(state.users[action.userId] || {}),
              abim:
                updatedMocStatus & MOC_STATUS.MOC_ABIM_ENABLED // bitwise and
                  ? action.formData.abimNumber
                  : state.data.abim,
              abp:
                updatedMocStatus & MOC_STATUS.MOC_ABP_ENABLED // bitwise and
                  ? action.formData.abpNumber
                  : state.data.abp,
              birthDate: action.formData.birthDate,
              cmeAndMocAvailability: {
                ...(state.data.cmeAndMocAvailability || {}),
                mocStatus: parseInt(updatedMocStatus),
              },
            },
          },
        }
      })
    }

    default:
      return state
  }
}
