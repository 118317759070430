import React from 'react'
import { useDispatch } from 'react-redux'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'
import { Link1 } from 'pharmacy/src/typography'

export default function Stub({ children, className }) {
  const dispatch = useDispatch()
  const openRegistrationModal = () => {
    dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId))
  }

  return (
    <Link1 role="button" className={className} onClick={openRegistrationModal}>
      {children}
    </Link1>
  )
}
