import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeaders } from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_UPDATES = 'expertEmail/FETCH_UPDATES'
export const FETCH_AVAILABLE_SPECIALTIES =
  'expertEmail/FETCH_AVAILABLE_SPECIALTIES'
export const POST_EMAIL_UPDATES = 'expertEmail/POST_EMAIL_UPDATES'
export const FETCH_UPDATE_STATS = 'expertEmail/FETCH_UPDATE_STATS'

export function fetchAvailableSpecialties() {
  return {
    type: FETCH_AVAILABLE_SPECIALTIES,
  }
}

export function fetchUpdates(specialtyId, subspecialtyId, from, to) {
  return {
    type: FETCH_UPDATES,
    specialtyId,
    subspecialtyId,
    from,
    to,
  }
}

export function postEmailUpdates(formData, callback) {
  return {
    type: POST_EMAIL_UPDATES,
    formData,
    callback,
  }
}

export function fetchUpdateStats(updates, specialtyId, from, to) {
  return {
    type: FETCH_UPDATE_STATS,
    updates,
    specialtyId,
    from,
    to,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_AVAILABLE_SPECIALTIES,
    makeURL(`expertEmail/getAvailableSpecialtiesJSON`)
  )

  yield makeFetchEffect(takeLatest, FETCH_UPDATES, (action) =>
    makeURL('expertEmail/getUpdatesJSON', {
      specialtyId: action.specialtyId,
      subspecialtyId: action.subspecialtyId,
      from: action.from,
      to: action.to,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    POST_EMAIL_UPDATES,
    makeURL('expertEmail/edit'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, FETCH_UPDATE_STATS, (action) =>
    makeURL('expertEmail/getUpdateStatsJSON', {
      updateId: action.updates,
      specialtyId: action.specialtyId,
      from: action.from,
      to: action.to,
    })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_AVAILABLE_SPECIALTIES): {
      return receiveReducer(state, action, () => ({
        availableSpecialties: action.response.availableSpecialties,
      }))
    }

    case receiveAction(FETCH_UPDATES): {
      return receiveReducer(state, action, () => ({
        updates: action.response.updates,
        existing: action.response.existing,
      }))
    }

    case receiveAction(FETCH_UPDATE_STATS): {
      return receiveReducer(state, action, () => ({
        stats: action.response,
      }))
    }

    default:
      return state
  }
}
