import React from 'react'

import { getFeedNameKey } from 'mednet-util/src/feed'

import { default as StatefulFeedItem } from './statefulFeedItem'

class QuestionFeedItem extends React.Component {
  static defaultProps = {
    FeedItem: StatefulFeedItem,
  }

  render() {
    const { feedName, feedKey, FeedItem } = this.props

    return (
      <FeedItem
        objectPath="question.questions"
        indexPath={`feed.feeds[${getFeedNameKey(feedName, feedKey)}].items`}
        {...this.props}
      />
    )
  }
}

export default QuestionFeedItem
