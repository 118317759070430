import React from 'react'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { POST_ANSWER_MODAL } from 'mednet-util/src/constants/modal'

import css from './disclosurePrompt.scss'

class DisclosurePrompt extends React.Component {
  handleNoClick = () => {
    if (this.props.pollId) {
      this.props.closeModal()
    } else {
      this.props.changeModalScreen(POST_ANSWER_MODAL.screens.pollPrompt)
    }
  }

  handleYesClick = () => {
    this.props.changeModalScreen(POST_ANSWER_MODAL.screens.disclosureForm)
  }

  render() {
    return (
      <div>
        <Header2>Disclosures</Header2>
        <hr />
        <Subtitle2>
          Do you have any conflicts of interest related to this answer?
        </Subtitle2>
        <div className={css.buttonGroup}>
          <Button
            type="primary"
            className={css.button}
            onClick={this.handleNoClick}
          >
            No
          </Button>
          <Button
            type="secondary"
            className={css.button}
            onClick={this.handleYesClick}
          >
            Yes
          </Button>
        </div>
      </div>
    )
  }
}

export default DisclosurePrompt
