import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

import {
  FETCH_QUESTION_METADATA,
  FETCH_CAMPAIGN_QUESTION_METADATA,
} from './question'

export const VOTE_POLL = 'poll/VOTE_POLL'
export const FETCH_POLL_RESULTS = 'poll/FETCH_POLL_RESULTS'

export function votePoll(pollId, form, questionId, campaignHash, callback) {
  return {
    type: VOTE_POLL,
    requestId: pollId,
    pollId,
    form,
    callback,
    questionId,
    campaignHash,
  }
}

export function fetchPollResults(
  pollId,
  since,
  campaignHash = null,
  guestUUID = null
) {
  return {
    type: FETCH_POLL_RESULTS,
    requestId: pollId,
    pollId,
    since,
    guestUUID,
    campaignHash,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    VOTE_POLL,
    (action) =>
      makeURL(`poll/voteJSON/${action.pollId}`, {
        questionId: action.questionId,
        campaignHash: action.campaignHash,
      }),
    (action) => makePOSTHeadersFromParams(action.form)
  )

  yield makeFetchEffect(takeLatest, FETCH_POLL_RESULTS, (action) =>
    makeURL(`poll/getResultsJSON/${action.pollId}`, {
      since: action.since,
      campaignHash: action.campaignHash,
      guestUUID: action.guestUUID,
    })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  polls: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_CAMPAIGN_QUESTION_METADATA):
    case receiveAction(FETCH_QUESTION_METADATA): {
      const pollObject = state.polls[action.pollId] || {}
      const newPollObject = action.response.poll

      if (!newPollObject) {
        return state
      }

      return receiveReducer(state, action, () => ({
        polls: {
          ...state.polls,
          [newPollObject.pollId]: {
            ...pollObject,
            ...newPollObject,
          },
        },
      }))
    }

    case receiveAction(VOTE_POLL): {
      const pollObject = state.polls[action.pollId] || {}

      return receiveReducer(state, action, () => ({
        polls: {
          ...state.polls,
          [action.pollId]: {
            ...pollObject,
            options: action.response.options,
            showResults: true,
            showVote: false,
          },
        },
      }))
    }

    case receiveAction(FETCH_POLL_RESULTS): {
      const pollObject = state.polls[action.pollId] || {}

      return receiveReducer(state, action, () => ({
        polls: {
          ...state.polls,
          [action.pollId]: {
            ...pollObject,
            options: action.response.options,
          },
        },
      }))
    }

    default:
      return state
  }
}
