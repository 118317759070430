import React from 'react'
import _ from 'lodash'
import { withGlobalEventListener } from 'react-global-event-listener'

import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'

import css from './scrollUpButton.scss'

class ScrollUpButton extends React.Component {
  static defaultProps = {
    buttonMargin: 1500,
  }

  constructor(props) {
    super(props)
    this.scrollUpButton = React.createRef()
  }

  componentDidMount() {
    this.props.subscribeListener(
      window,
      'scroll',
      'ScrollUpButton.onScroll',
      this.onScroll,
      { listenerWrapper: _.partialRight(_.throttle, 200) }
    )
  }

  componentWillUnmount() {
    this.props.unsubscribeListener(window, 'scroll', 'ScrollUpButton.onScroll')
  }

  onScroll = () => {
    const { buttonMargin } = this.props
    const scrollDepth = window.innerHeight + document.documentElement.scrollTop
    const scrollUpButton = this.scrollUpButton.current

    if (!scrollUpButton) {
      return
    }

    if (scrollDepth > buttonMargin && scrollUpButton.style.opacity === '') {
      scrollUpButton.style.opacity = '1'
      scrollUpButton.style.visibility = 'visible'
    } else if (
      scrollDepth < buttonMargin &&
      scrollUpButton.style.opacity === '1'
    ) {
      scrollUpButton.style.opacity = ''
      scrollUpButton.style.visibility = 'hidden'
    }
  }

  scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  render() {
    return (
      <Button
        className={css.scrollUpButton}
        forwardRef={this.scrollUpButton}
        onClick={this.scrollUp}
        shadow
        shape="circle"
        type="tertiary"
      >
        <Icon icon="arrow-up" size="lg" />
      </Button>
    )
  }
}

export default withGlobalEventListener(ScrollUpButton)
