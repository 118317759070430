import React from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import 'core-js/features/url-search-params'

import { withPermissions } from 'mednet-util/src/permission'
import { mixpanel } from 'mednet-util/src/tracking'
import { authItems } from 'mednet-util/src/constants/permission'

import { fetchAnswer, FETCH_ANSWER } from 'mednet-cns/src/reducers/answer'
import { makeRequestName } from 'mednet-cns/src/reducers/request'

import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Page } from 'pharmacy/src/display/page'

import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'

import { Header3 } from 'pharmacy/src/typography'

import { Card } from 'pharmacy/src/display/card'

import { ErrorPage } from 'pages/error'

import css from './editAnswer.scss'

/**
 * Page to edit an answer - currently work on holds
 */
class EditAnswerPage extends React.Component {
  componentDidMount() {
    const { answerId, fetchAnswer } = this.props

    fetchAnswer(answerId, () => {
      this.trackView()
    })
  }

  componentDidUpdate() {}

  trackView = () => {
    mixpanel.track('Update answer', {
      env: 'w',
      answerId: this.props.answerId,
    })
  }

  redirect = (questionId) => {
    const { history } = this.props

    return history.push(`/question/${questionId}`)
  }

  onAuthorChange = (author) => {
    this.setState({ loadingUserProfile: true, author: null, specialtyId: null })
    this.props.fetchUserProfile(author.userId, (response) => {
      this.setState({
        loadingUserProfile: false,
        author: author || null,
        specialtyId: _.get(response, 'user.specialtyId'),
      })
    })
  }

  onPostAsSomebodyElseToggle = () => {
    this.setState((prevState) => ({
      postAsSomebodyElse: !prevState.postAsSomebodyElse,
      author: null,
    }))
  }

  render() {
    const { isAnswerLoaded, isError, errorStatus, answer } = this.props

    if (!isAnswerLoaded) {
      return <StarLoader />
    }

    if (isError) {
      return <ErrorPage errorStatus={errorStatus} />
    }

    const { userId, created, updated } = answer
    const updatedProps = updated && {
      secondaryDateUpdated: updated,
      secondaryActionId: actionIdByName.ANSWER_UPDATED,
    }

    return (
      <Page className={css.page}>
        <div className={css.contents}>
          <Card className={css.card}>
            <UserUpdateInformation
              userId={userId}
              dateUpdated={created}
              actionId={actionIdByName.ANSWER}
              NameComponent={Header3}
              theme="extended"
              {...updatedProps}
            />
          </Card>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { answerId } = ownProps.match.params
  const answerRequest =
    _.get(state.request.requests, makeRequestName(FETCH_ANSWER, answerId)) || {}

  return {
    answerId,
    answer: state.answer.answers[answerId],
    isAnswerLoaded: state.answer.answers[answerId] || answerRequest.isLoaded,
    isError: answerRequest.isError,
    errorStatus: answerRequest.errorStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAnswer: (answerId) => dispatch(fetchAnswer(answerId)),
  }
}

export default compose(
  withPermissions(authItems.updateAnswer),
  connect(mapStateToProps, mapDispatchToProps)
)(EditAnswerPage)
