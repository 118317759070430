import React from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'

import { TagSelect, components } from 'pharmacy/src/input/select'
import { transformTopicObject } from 'mednet-util/src/search'

import css from './geoTagSelect.scss'

export class TopicOption extends React.Component {
  render() {
    const { topic, label, __isNew__ } = this.props.data

    if (__isNew__) {
      return (
        <components.Option {...this.props}>
          <div className={css.optionContainer}>{label}</div>
        </components.Option>
      )
    }

    return (
      <components.Option {...this.props}>
        <div className={css.optionContainer}>{topic}</div>
      </components.Option>
    )
  }
}

class TopicTagSelect extends React.Component {
  handleInputChange = (value) => {
    this.props.refine(value)
  }

  getOptionValue = (datum) => {
    return datum.topicId || datum.label
  }

  getOptionLabel = (datum) => {
    return datum.topic || datum.label
  }

  render() {
    const {
      hits,
      currentRefinement,
      dispatch,
      createURL,
      refine,
      ...restProps
    } = this.props

    const transformedHits = hits.map(transformTopicObject)

    return (
      <TagSelect
        components={{ Option: TopicOption }}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        menuIsOpen={Boolean(currentRefinement)}
        name="topic[]"
        onInputChange={this.handleInputChange}
        options={transformedHits}
        placeholder="Select..."
        {...restProps}
      />
    )
  }
}

export default connectAutoComplete(TopicTagSelect)
