import React, { useEffect, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { Header3 } from 'pharmacy/src/typography'
import { RouterTabs, TabButton } from 'pharmacy/src/navigation/tabs'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { makeAssetURL } from 'mednet-util/src/router'

import {
  useCmeConfig,
  useCmeAndMocAvailabilityCheck,
  useSetCmeRecentCertificates,
  useShouldPromptForMoc,
} from 'mednet-cns/src/hooks/cme'

import { MocProfileModal } from 'components/cmeModal/mocProfileModal'

import { CmeAvailableTab } from './cmeAvailableTab'
import { CmeRedeemedTab } from './cmeRedeemedTab'
import { CmeCertificatesTab } from './cmeCertificatesTab'

import css from './cmeDashBoard.scss'

const renderCmeRightText = (shouldDisplayMoc, className) => {
  return (
    <div className={classNames(css.rightText, className)}>
      <Header3 className={css.title}>
        Earning CME {shouldDisplayMoc ? '& MOC' : ''}
      </Header3>
      <ul>
        <li>Search and read clinically relevant Q&A</li>
        <li>Redeem credit for each question view, paper download, or answer</li>
        <li>All eligible activity is tracked here</li>
      </ul>

      <Header3 className={css.title}>In Partnership with</Header3>
      <div className={css.logoContainer}>
        <img
          className={css.logo}
          // Should get the new logo
          src={makeAssetURL('images/logos/uc_medical%26biological_logo_2.png')}
        />
      </div>
      <p>
        The University of Chicago Pritzker School of Medicine designates this
        internet point-of-care activity for a maximum of 20{' '}
        <span className={css.italic}>AMA PRA Category 1 Credits™</span> per
        calendar year.
      </p>
      <p>
        Physicians should claim only the credit commensurate with the extent of
        their participation in the activity.
      </p>
    </div>
  )
}

const renderCmeFooter = () => (
  <div className={css.cmeFooter}>
    <div className={css.footerParagraph}>
      We are proud to offer theMednet Internet Point of Care -{' '}
      {new Date().getFullYear()}. All activities must be redeemed for credit
      within the calendar year. MOC points, if applicable, will be reported by
      the University of Chicago at year end.
    </div>
    <div className={css.footerMainTitle}>
      Accreditation and Credit Designation Statement
    </div>
    <div className={css.footerParagraph}>
      <div className={css.footerTitle}>Physician Credit</div>
      <p>
        This activity has been planned and implemented in accordance with the
        accreditation requirements and policies of the Accreditation Council for
        Continuing Medical Education (ACCME) through the joint providership of
        the University of Chicago Pritzker School of Medicine and theMednet,
        Inc. The University of Chicago Pritzker School of Medicine is accredited
        by the ACCME to provide continuing medical education for physicians.
      </p>
      <p>
        The University of Chicago Pritzker School of Medicine designates this
        internet point-of-care activity for a maximum of 20 AMA PRA Category 1
        Credits™. Physicians should claim only the credit commensurate with the
        extent of their participation in the activity.
      </p>
    </div>
    <div className={css.footerParagraph}>
      <div className={css.footerTitle}>
        American Board of Internal Medicine MOC Part II Credit
      </div>
      <p>
        Successful completion of this CME activity, which includes participation
        in the evaluation component, enables the participant to earn up to 20
        MOC points in the American Board of Internal Medicine&#39;s (ABIM)
        Maintenance of Certification (MOC) program. Participants will earn MOC
        points equivalent to the amount of CME credits claimed for the activity.
        It is the CME activity provider&#39;s responsibility to submit
        participant completion information to ACCME for the purpose of granting
        ABIM MOC credit.
      </p>
    </div>
    <div className={css.footerParagraph}>
      <div className={css.footerTitle}>
        American Board of Pediatrics MOC Part II Credit
      </div>
      <p>
        Successful completion of this CME activity, which includes participation
        in the evaluation component, enables the learner to earn up to 20 MOC
        points in the American Board of Pediatrics&#39; (ABP) Maintenance of
        Certification (MOC) program. It is the CME activity provider&#39;s
        responsibility to submit learner completion information to ACCME for the
        purpose of granting ABP MOC credit.
      </p>
    </div>
    <div className={css.footerParagraph}>
      <div className={css.footerTitle}>Other Health Professional Credit</div>
      <p>
        Other healthcare professionals will receive a Certificate of
        Participation. For information on the applicability and acceptance of
        Certificates of Participation for educational activities certified for
        AMA PRA Category 1 Credit™ from organizations accredited by the ACCME,
        please consult your professional licensing board.
      </p>
    </div>
  </div>
)

export const CmeDashboard = (props) => {
  const urlParams = new URLSearchParams(props.location.search)
  const currentTab = urlParams.get('tab')

  const [cmeConfig, cmeConfigRequest] = useCmeConfig()
  const [shouldPromptForMoc, shouldPromptForMocRequest] =
    useShouldPromptForMoc()

  // User can do multiple activities in the site and go back to this page, it is better just to reload them
  // only when the main dashboard page mounts not when the available tab mounts (should not relaod on tab change)
  const [reloadAvailableTabDataFlag, setReloadAvailableTabDataFlag] =
    useState(true)

  // User can redeem multiple activities in the site and go back to this page, the redeeming action usually happens
  // in a new tab (question opens in new tab) so the state will not be updated. It is better just to reload them
  // when the main dashboard page mounts, but not when dashboard tab changes
  const [
    reloadRedeemedTabCurrentYearDataFlag,
    setReloadRedeemedTabCurrentYearDataFlag,
  ] = useState(true)

  const setRecentCertificates = useSetCmeRecentCertificates()

  const minDate = moment(cmeConfig.contract_start_date, 'YYYY-MM-DD').toDate()
  const minYear = minDate.getFullYear()

  const {
    isCmeOrMocAvailableForUser,
    isMocEnabledForUser: shouldDisplayMoc,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    isCmeAndMocAvailabilityCheckLoading,
    isCmeAndMocAvailabilityCheckLoaded,
  } = useCmeAndMocAvailabilityCheck()

  useEffect(() => {
    return () => {
      setRecentCertificates([])
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    currentTab !== 'Certificates' && setRecentCertificates([])
  }, [currentTab])

  const tabs = [
    {
      header: (
        <TabButton
          title="Available"
          className={css.tabTitle}
          activeClassName={css.activeTab}
        />
      ),
      component: (
        <CmeAvailableTab
          creditsPerActivity={cmeConfig.credits_per_activity}
          maxCreditsPerYear={cmeConfig.max_credits_per_year}
          minDate={minDate}
          reloadFlag={reloadAvailableTabDataFlag}
          setReloadFlag={setReloadAvailableTabDataFlag}
          isMocEnabledForUser={shouldDisplayMoc}
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          {...props}
        />
      ),
    },
    {
      header: (
        <TabButton
          title="Redeemed"
          className={css.tabTitle}
          activeClassName={css.activeTab}
        />
      ),
      component: (
        <CmeRedeemedTab
          minYear={minYear}
          creditsPerActivity={cmeConfig.credits_per_activity}
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          reloadFlag={reloadRedeemedTabCurrentYearDataFlag}
          setReloadFlag={setReloadRedeemedTabCurrentYearDataFlag}
          maxCreditsPerYear={cmeConfig.max_credits_per_year}
          showMocCertificateModal={
            !shouldPromptForMocRequest.isLoaded || shouldPromptForMoc
          }
          {...props}
        />
      ),
    },
    {
      header: (
        <TabButton
          title="Certificates"
          className={css.tabTitle}
          activeClassName={css.activeTab}
        />
      ),
      component: (
        <CmeCertificatesTab
          creditsPerActivity={cmeConfig.credits_per_activity}
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
          minYear={minYear}
        />
      ),
    },
  ]

  if (
    isCmeAndMocAvailabilityCheckLoading ||
    !isCmeAndMocAvailabilityCheckLoaded
  ) {
    return <StarLoader isFullScreen />
  }

  if (!isCmeOrMocAvailableForUser) {
    return (
      <div className={css.cmeNotAvailableMesssage}>
        CME activities are only available for Doctors based in US or Canada
      </div>
    )
  }

  return (
    <div>
      <div className={css.cmeContainer}>
        <div>
          {cmeConfigRequest.isLoaded && shouldPromptForMocRequest.isLoaded ? (
            <RouterTabs
              tabs={tabs}
              headerProps={{ underlined: true, fullWidthTabs: true }}
            />
          ) : (
            <StarLoader />
          )}
          {renderCmeRightText(shouldDisplayMoc, css.rightTextForSmallScreen)}
          {renderCmeFooter()}
        </div>

        {renderCmeRightText(shouldDisplayMoc, css.rightTextForLargeScreen)}
      </div>
      {shouldPromptForMocRequest.isLoaded && shouldPromptForMoc && (
        <MocProfileModal
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
        />
      )}
    </div>
  )
}
