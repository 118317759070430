import React from 'react'

import { Icon } from 'pharmacy/src/display/icon'
import { Subtitle2 } from 'pharmacy/src/typography'

import { TopicLink } from 'pharmacy/src/navigation/topicLink'

import css from './topicSubsection.scss'

class TopicSubsection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      childrenOpen: false,
    }
  }

  toggleChildren = () => {
    const { childrenOpen } = this.state

    this.setState({
      childrenOpen: !childrenOpen,
    })
  }

  render() {
    const { topicObject } = this.props
    const { children } = topicObject

    return (
      <div>
        <Subtitle2>
          <TopicLink topicObject={topicObject} className={css.topicLink} />
          {children && (
            <Icon
              className={css.childArrow}
              icon={['fal', this.state.childrenOpen ? 'minus' : 'plus']}
              onClick={this.toggleChildren}
            />
          )}
        </Subtitle2>
        {children && this.state.childrenOpen && (
          <div className={css.topicChildren}>
            {children.map((child) => {
              return <TopicSubsection key={child.topicId} topicObject={child} />
            })}
          </div>
        )}
      </div>
    )
  }
}

export default TopicSubsection
