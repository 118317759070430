import { useCallback, useState } from 'react'

export const useAvailableActivitiesSelector = ({
  availableActivities,
  maxCreditsPerYear,
  creditsPerActivity,
  redeemedCount,
}) => {
  const [selectedRowsIds, setSelectedRowsIds] = useState({})
  const [disabledRowsIds, setDisabledRowsIds] = useState({})

  const disabledText = `Up to ${maxCreditsPerYear} credits can be redeemed per year`
  const maxActivitiesCountPerYear = maxCreditsPerYear / creditsPerActivity
  const maxSelectableCount = maxActivitiesCountPerYear - redeemedCount

  const getSelectedCount = useCallback(
    (selectedRows = selectedRowsIds) =>
      Object.entries(selectedRows)
        .filter(([_, isSelected]) => isSelected)
        .map(([key, _]) => key).length,
    [selectedRowsIds]
  )

  const handleSelect = useCallback(
    (updatedSelectedRowsIds) => {
      if (getSelectedCount(updatedSelectedRowsIds) >= maxSelectableCount) {
        setDisabledRowsIds(
          Object.fromEntries(
            availableActivities
              .map((_, i) =>
                !updatedSelectedRowsIds[i] ? [i, disabledText] : undefined
              )
              .filter((x) => x)
          )
        )
      } else {
        setDisabledRowsIds({})
      }

      setSelectedRowsIds(updatedSelectedRowsIds)
    },
    [
      maxSelectableCount,
      getSelectedCount,
      setDisabledRowsIds,
      setSelectedRowsIds,
      availableActivities,
      disabledText,
    ]
  )

  const handleSelectAll = useCallback(() => {
    const selected = Object.fromEntries(
      Array(Math.min(availableActivities.length, maxSelectableCount))
        .keys()
        .map((index) => [index, true])
    )

    const disabled =
      availableActivities.length <= maxSelectableCount
        ? {}
        : Object.fromEntries(
            Array(availableActivities.length - maxSelectableCount)
              .keys()
              .map((index) => [index + maxSelectableCount, disabledText])
          )

    setSelectedRowsIds(selected)
    setDisabledRowsIds(disabled)
  }, [
    setSelectedRowsIds,
    setDisabledRowsIds,
    maxSelectableCount,
    availableActivities,
    disabledText,
  ])

  const handleDeselectAll = useCallback(() => {
    setSelectedRowsIds({})
    setDisabledRowsIds({})
  }, [setSelectedRowsIds, setDisabledRowsIds])

  return {
    selectedRowsIds,
    disabledRowsIds,
    getSelectedCount,
    handleSelect,
    handleSelectAll,
    handleDeselectAll,
  }
}
