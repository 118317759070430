import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import { parseInt } from 'lodash'
import moment from 'moment'

import { Page } from 'pharmacy/src/display/page'
import { Header1 } from 'pharmacy/src/typography'
import { CenteredContent } from 'pharmacy/src/display/content'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import {
  FETCH_EXPERT_QUESTIONS,
  fetchExpertQuestions,
  answerQuestions,
} from 'mednet-cns/src/reducers/sponsorship'
import {
  fetchInterestConflict,
  FETCH_INTEREST_CONFLICT,
  FETCH_USER_WITH_PERMISSIONS,
} from 'mednet-cns/src/reducers/user'
import { openModal } from 'mednet-cns/src/reducers/modal'

import { getRequest } from 'mednet-cns/src/api/v1'

import { getPathnameFromRoute, parseQueryString } from 'mednet-util/src/router'
import { SPONSORSHIP_CME_MODAL } from 'mednet-util/src/constants/modal'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import { SposorshipCmeModal } from 'components/cmeModal'

import AnswerQuestionsForm from './forms/answerQuestionsForm'

import css from './sendEmail.scss'

class AnswerPage extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchExpertQuestions()

    if (!this.props.conflictRequestIsLoaded && !this.props.defaultConflict)
      this.props.fetchDefaultConflict()
  }

  handleSubmit = (values, actions) => {
    const {
      answerQuestions,
      history,
      sponsorship,
      openCmeModal,
      loggedInUserId,
      userId,
      isCmeOrMocAvailableForUser,
    } = this.props

    window.tinyMCE.triggerSave()

    const formData = new FormData(this.form.current)
    answerQuestions(formData, () => {
      actions.setSubmitting(false)
      const endDate = moment(sponsorship.endDate, 'YYYY-MM-DD').toDate()

      // As we ask experts to answer question before the release date the last condition should never be met
      //  unless someone opens an old link (should not be very often)
      if (
        isCmeOrMocAvailableForUser &&
        loggedInUserId === parseInt(userId) &&
        (!sponsorship.isPaid || endDate < new Date())
      ) {
        openCmeModal()
      } else {
        history.push(getPathnameFromRoute(ROUTES[PAGES.HOME]))
      }
    })
  }

  render() {
    const {
      questionsIsLoaded,
      sponsorship,
      questions,
      conflictRequestIsLoaded,
      userIsLoaded,
      loggedInUserId,
      defaultConflict,
      isPermittedToPostAsUser,
      userId,
    } = this.props

    if (
      !questionsIsLoaded ||
      !userIsLoaded ||
      (!conflictRequestIsLoaded && !defaultConflict)
    ) {
      return <StarLoader />
    }

    const { description } = sponsorship

    if (loggedInUserId !== parseInt(userId) && !isPermittedToPostAsUser) {
      return null
    }

    return (
      <Page>
        <CenteredContent>
          <div className={css.header}>
            <Header1>Questions for {description}</Header1>
          </div>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              questions: questions.map((question) =>
                question.expertAnswer ? question.expertAnswer.answer : null
              ),
            }}
          >
            {(props) => (
              <AnswerQuestionsForm
                {...props}
                {...this.props}
                formRef={this.form}
              />
            )}
          </Formik>
        </CenteredContent>
        <SposorshipCmeModal />
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const { userId } = parseQueryString(ownProps.location.search)
  const questionRequest = getRequest(state, FETCH_EXPERT_QUESTIONS)
  const conflictRequest = getRequest(state, FETCH_INTEREST_CONFLICT)
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)
  const loggedInUserId = state.user.data?.userId
  const isCmeOrMocAvailableForUser =
    state.user.data?.cmeAndMocAvailability?.cmeStatus
  const isPermittedToPostAsUser = ownProps.isUserPermittedTo(
    authItems.postAsUser
  )

  return {
    questions: state.sponsorship.questions[userId],
    questionsIsLoaded: questionRequest.isLoaded === true,
    userIsLoaded: userRequest.isLoaded,
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    userId,
    loggedInUserId,
    defaultConflict: state.user.users[userId]?.defaultConflict,
    conflictRequestIsLoaded: conflictRequest.isLoaded === true,
    isPermittedToPostAsUser,
    isCmeOrMocAvailableForUser,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const { userId } = parseQueryString(ownProps.location.search)

  return {
    fetchExpertQuestions: () =>
      dispatch(fetchExpertQuestions(sponsorshipId, userId)),
    answerQuestions: (formData, callback) =>
      dispatch(answerQuestions(sponsorshipId, userId, formData, callback)),
    fetchDefaultConflict: () => dispatch(fetchInterestConflict(userId)),
    openCmeModal: () =>
      dispatch(
        openModal(SPONSORSHIP_CME_MODAL.modalId, {
          onSkip: () => {
            ownProps.history.push(getPathnameFromRoute(ROUTES[PAGES.HOME]))
          },
          onConfirm: () => {
            ownProps.history.push(`/sponsorship/cme/${sponsorshipId}`)
          },
        })
      ),
  }
}

export default compose(
  withPermissions(authItems.createQuestion),
  connect(mapStateToProps, mapDispatchToProps)
)(AnswerPage)
