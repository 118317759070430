import React from 'react'
import isEmail from 'validator/lib/isEmail'
import { connectAutoComplete } from 'react-instantsearch-dom'

import { formatName } from 'mednet-util/src/string'
import { IMAGE_SIZE } from 'mednet-util/src/router'
import { UserImage } from 'pharmacy/src/user/userImage'
import { TagSelect, components } from 'pharmacy/src/input/select'
import { Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { transformSearchUserObject } from 'mednet-util/src/search'

import css from './userTagSelect.scss'

export class UserOption extends React.Component {
  renderNewOption() {
    const { email } = this.props.data

    return (
      <components.Option {...this.props}>
        <Subtitle2>{email}</Subtitle2>
      </components.Option>
    )
  }

  render() {
    const {
      imageVersion,
      userId,
      specialty,
      institution,
      _highlightResult,
      __isNew__,
    } = this.props.data
    if (__isNew__) {
      return this.renderNewOption()
    }

    const { firstName, middleName, lastName } = _highlightResult || {}

    const hitContent = formatName(
      firstName.value,
      middleName.value,
      lastName.value,
      '',
      { useMiddleInitial: false }
    )
    return (
      <components.Option {...this.props}>
        <div className={css.optionContainer}>
          <div className={css.leftContent}>
            <div className={css.optionImageContainer}>
              <UserImage
                imageVersion={imageVersion}
                userId={userId}
                imageSize={IMAGE_SIZE.SMALL}
              />
            </div>
            <div>
              <Subtitle2>
                <div dangerouslySetInnerHTML={{ __html: hitContent }} />
              </Subtitle2>
              <Subtitle3>{institution}</Subtitle3>
            </div>
          </div>
          <Subtitle3 className={css.optionInstitution}>{specialty}</Subtitle3>
        </div>
      </components.Option>
    )
  }
}

class UserTagSelect extends React.Component {
  handleInputChange = (value) => {
    this.props.refine(value)
  }

  getOptionValue = (datum) => {
    return datum.userId || datum.email
  }

  getOptionLabel = (datum) => {
    const { firstName, middleName, lastName, email } = datum
    if (email) {
      return email
    }
    return formatName(firstName, middleName, lastName)
  }

  isValidNewOption = (inputValue) => {
    return isEmail(inputValue)
  }

  getNewOptionData = (inputValue) => {
    return {
      email: inputValue,
      __isNew__: true,
    }
  }

  render() {
    const {
      hits,
      currentRefinement,
      dispatch,
      createURL,
      refine,
      name,
      ...restProps
    } = this.props

    const transformedHits = hits.map(transformSearchUserObject)

    return (
      <TagSelect
        creatable
        createOptionPosition="first"
        getNewOptionData={this.getNewOptionData}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        isValidNewOption={this.isValidNewOption}
        menuIsOpen={Boolean(currentRefinement)}
        name={name === undefined ? 'shareUsers[]' : name}
        onInputChange={this.handleInputChange}
        options={transformedHits}
        components={{ Option: UserOption }}
        placeholder="Search..."
        {...restProps}
      />
    )
  }
}

export default connectAutoComplete(UserTagSelect)
