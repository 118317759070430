import React from 'react'
import _ from 'lodash'

import BaseSelect from './baseSelect'

import './tagSelect.scss'

const STYLE_OVERRIDES = [
  'control',
  'multiValue',
  'option',
  'menu',
  'menuList',
  { name: 'valueContainer', remove: ['padding'] },
  { name: 'multiValueLabel', remove: ['padding', 'color'] },
]

class TagSelect extends React.Component {
  static defaultProps = {
    creatable: false,
    components: {},
  }

  noOptionsMessage = () => {
    return 'No results found'
  }

  render() {
    const {
      components,
      isMulti,
      openMenuOnClick,
      defaultFilterOption,
      ...restProps
    } = this.props

    return (
      <BaseSelect
        classNamePrefix="react-select-tag"
        components={_.assign(components, {
          DropdownIndicator: null,
        })}
        filterOption={defaultFilterOption ? undefined : null}
        isMulti={isMulti !== undefined ? isMulti : true}
        noOptionsMessage={this.noOptionsMessage}
        openMenuOnClick={
          openMenuOnClick !== undefined ? openMenuOnClick : false
        }
        styleOverrides={STYLE_OVERRIDES}
        {...restProps}
      />
    )
  }
}

export default TagSelect
