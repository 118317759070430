import React from 'react'

import { TextEditor } from 'pharmacy/src/input/textEditor'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'

import css from './sendEmailForm.scss'

class SendEmailForm extends React.Component {
  render() {
    const { values, isSubmitting, handleSubmit, formRef } = this.props
    const { body, subject } = values

    return (
      <form ref={formRef}>
        <div className={css.inputContainer}>
          <TextInput value={subject} name="subject" showClear={false} />
        </div>
        <div className={css.inputContainer}>
          <TextEditor value={body} name="body" />
        </div>
        <Button onClick={handleSubmit} isLoading={isSubmitting}>
          Submit
        </Button>
      </form>
    )
  }
}

export default SendEmailForm
