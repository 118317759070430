import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import * as Yup from 'yup'
import 'core-js/features/url-search-params'

import {
  askQuestion,
  askQuestionAsUser,
} from 'mednet-cns/src/reducers/question'

import { withPermissions } from 'mednet-util/src/permission'
import { mixpanel } from 'mednet-util/src/tracking'
import { authItems } from 'mednet-util/src/constants/permission'

import { Page } from 'pharmacy/src/display/page'
import { BaseQuestionLink } from 'pharmacy/src/navigation/questionLink'
import { Card } from 'pharmacy/src/display/card'
import { Icon } from 'pharmacy/src/display/icon'
import { PostOnBehalf } from 'pharmacy/src/question/postOnBehalf'

import {
  Body1,
  Header1,
  Header2,
  PageHeader,
  Subtitle2,
  Subtitle3,
  Subtitle4,
} from 'pharmacy/src/typography'

import CreateQuestionForm from './forms/createQuestionForm'

import css from './askQuestion.scss'

const CreateQuestionSchema = Yup.object().shape({
  question: Yup.string().required(),
  subspecialtyTopic: Yup.object().required(),
})

class AskQuestionPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      author: null,
      specialtyId: null,
    }
  }

  componentDidMount() {
    this.trackView()
  }

  trackView = () => {
    mixpanel.track('viewed_ask_question_page', {
      env: 'w',
    })
  }

  redirect = (target, questionId) => {
    const { history } = this.props

    switch (target) {
      case 'poll':
        history.push(
          `/poll/edit?question_id=${questionId}&action=new_question_poll`
        )
        return history.go()
      case 'question':
      default:
        return history.push(`/question/${questionId}?action=new_question`)
    }
  }

  onAuthorChange = (author, specialtyId) => {
    this.setState({
      author: author || null,
      specialtyId,
    })
  }

  handleSubmit = (values, actions) => {
    const {
      isPermittedToPostAsUser,
      askQuestion,
      askQuestionAsUser,
      sponsorshipId,
    } = this.props
    const { author } = this.state
    const request = isPermittedToPostAsUser ? askQuestionAsUser : askQuestion
    const { redirectTo, subspecialtyTopic, users, ...restValues } = values
    const payload = {
      ...restValues,
      subspecialtyTopic: subspecialtyTopic.subspecialtyTopic,
      users: users && users.map((user) => user.userId),
    }

    if (author) {
      payload.author = author.userId
    }

    if (!payload.users) {
      delete payload.users
    }

    if (sponsorshipId) {
      payload.sponsorshipId = sponsorshipId
    }

    request(payload, (response) => {
      actions.setSubmitting(false)

      if (response.success) {
        mixpanel.track('question_submitted', {
          env: 'w',
          questionId: response.questionId,
          topic: payload.subspecialtyTopic.split(':').pop(), // get only topic from "subspec:topic" format
          is_anonymous: payload.anonymous,
          included_poll: redirectTo === 'poll' ? true : false,
        })
        this.redirect(redirectTo, response.questionId)
      }
    })
  }

  onPostAsSomebodyElseToggle = () => {
    this.setState((prevState) => ({
      postAsSomebodyElse: !prevState.postAsSomebodyElse,
      author: null,
    }))
  }

  render() {
    const { ownSpecialtyId, anon, initialSubspecialtyTopic } = this.props
    const { specialtyId } = this.state
    const derivedSpecialtyId = specialtyId ? specialtyId : ownSpecialtyId

    return (
      <Page className={css.page}>
        <Helmet>
          <title>Ask a question</title>
        </Helmet>

        <div className={[css.info].join(' ')}>
          <Card className={[css.infoCard, css.infoText].join(' ')}>
            <Header2 className={css.infoHeader}>How to post a question</Header2>
            <Subtitle3>
              Questions should be concisely phrased to reflect a clinical
              scenario and not a specific case, for example:
            </Subtitle3>
            <Body1 className={css.infoTextItem}>
              <Icon icon="times" className={css.infoNotOK}></Icon> Should I
              offer chemotherapy to a 36 y/o woman with a 1.5cm ER+/PR+, Her2-
              breast cancer s/p lumpectomy, with widely negative margins, 0/3
              positive SLNs, and an Oncotype DX score of 8?
            </Body1>
            <Body1 className={css.infoTextItem}>
              <Icon icon="check" className={css.infoOK}></Icon> Do you give
              chemotherapy to women with clinical features of high risk breast
              cancer, but a low Oncotype DX score?
            </Body1>
            <Body1 className={css.infoTextItem}>
              <Icon icon="times" className={css.infoNotOK}></Icon> Young female
              of reproductive age with early breast cancer (Invasive lobular
              carcinoma) and ER-positive now completed BCT (lumpectomy,
              radiation)... She wants to stop endocrine therapy for a &ldquo;few
              years&ldquo; so she can get pregnant.. How would you approach this
              clinical scenario?
            </Body1>
            <Body1 className={css.infoTextItem}>
              <Icon icon="check" className={css.infoOK}></Icon> What is the best
              time frame to pause endocrine therapy to allow for pregnancy in a
              pre-menopausal patients with high-risk ER+ disease, and who
              desires pregnancy?
            </Body1>
            <Subtitle4>
              Moderators may modify questions in order to garner the most
              helpful answers for the community.
              <br />
              You can read more about our review process{' '}
              <BaseQuestionLink questionId={2854}>here</BaseQuestionLink>
            </Subtitle4>
          </Card>
          <Card className={[css.infoCard, css.infoVideo].join(' ')}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/G4ryjQNCLi8?rel=0&showinfo=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </Card>
        </div>
        <div className={css.content}>
          <div className={css.header}>
            <PageHeader className={css.main}>
              <Header1>Ask a question</Header1>
            </PageHeader>
            <Subtitle2>
              Questions will be sent to experts in the field
            </Subtitle2>
          </div>
          <Card className={[css.questionCard, css.onBehalf].join(' ')}>
            <PostOnBehalf
              onChange={this.onAuthorChange}
              checkboxProps={{
                selectText: 'Select to ask on behalf of',
              }}
            />
          </Card>
          <Card className={css.questionCard}>
            <Formik
              initialValues={{
                question: '',
                anonymous: anon ? true : false,
              }}
              onSubmit={this.handleSubmit}
              validationSchema={CreateQuestionSchema}
            >
              {(props) => (
                <CreateQuestionForm
                  {...props}
                  handleQuestionSubmit={props.handleSubmit}
                  specialtyId={derivedSpecialtyId}
                  initialSubspecialtyTopic={initialSubspecialtyTopic}
                />
              )}
            </Formik>
          </Card>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const ownSpecialtyId = state.user.data.specialtyId
  const { users } = state.user
  const isPermittedToPostAsUser = ownProps.isUserPermittedTo(
    authItems.postAsUser
  )

  const params = new URLSearchParams(ownProps.location.search)
  const sponsorshipId =
    params.get('sponsorshipId') || params.get('sponsorship_id')
  const anon = params.get('anon')
  const initialSubspecialtyTopic = params.get('initst')

  return {
    ownSpecialtyId,
    sponsorshipId,
    anon,
    isPermittedToPostAsUser,
    users,
    initialSubspecialtyTopic,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    askQuestion: (form, callback) => dispatch(askQuestion(form, callback)),
    askQuestionAsUser: (form, callback) =>
      dispatch(askQuestionAsUser(form, callback)),
  }
}

export default compose(
  withPermissions(authItems.createQuestion),
  connect(mapStateToProps, mapDispatchToProps)
)(AskQuestionPage)
