import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { Utils as QbUtils } from 'react-awesome-query-builder'

import {
  useActiveUsersFromQueryLazy,
  useUsersFromQueryLazy,
  useUnregisteredUsersFromQueryLazy,
} from 'mednet-cns/src/hooks/user'

import { Page } from 'pharmacy/src/display/page'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'

import UserTable from 'components/tables/userTable'
import UserSearchWithPresets from 'components/userSearchQueryBuilder/userSearchWithPresets'
import { useQueryBuilderConfig } from 'components/userSearchQueryBuilder/config'

import * as css from './userSearch.scss'

const treeToLogic = (tree, config) => {
  const initialTree = !isEmpty(tree)
    ? tree
    : { id: QbUtils.uuid(), type: 'group' }
  const immutableTree = QbUtils.checkTree(QbUtils.loadTree(initialTree), config)
  const jsonLogic = QbUtils.jsonLogicFormat(immutableTree, config)
  const query = { query: jsonLogic.logic || {} }
  query.id = initialTree.id
  query.name = tree?.name
  query.presetId = tree?.presetId
  query.isFileImport = false

  return query
}

const FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS = {
  NONE: 'NONE',
  RESTRICTED_VENDORS: 'RESTRICTED_VENDORS',
  ALL: 'ALL',
}

export const UserSearch = ({
  queryUserStatus = 'default',
  activeTab,
  selectable,
  initialSearchQuery,
  filePath,
  fileDownloadUrl,
  onSearch,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [submissionError, setSubmissionError] = useState()
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery)

  const selectedKeys = Object.keys(selectedUsers)

  const config = useQueryBuilderConfig(queryUserStatus)

  useEffect(() => {
    setSearchQuery(initialSearchQuery)
  }, [initialSearchQuery])

  const parsedSearchQuery = useMemo(() => {
    if (searchQuery?.isFileImport) {
      return {
        id: QbUtils.uuid(),
        isFileImport: true,
        file: searchQuery?.file,
      }
    }
    return treeToLogic(searchQuery?.tree, config)
  }, [searchQuery])

  const usersHookMapping = {
    active: useActiveUsersFromQueryLazy,
    unregistered: useUnregisteredUsersFromQueryLazy,
    default: useUsersFromQueryLazy,
  }

  const useUsers = usersHookMapping[queryUserStatus] ?? usersHookMapping.default

  const getQueryUsersParameters = () => {
    const queryUsersParameters = [
      parsedSearchQuery.id,
      parsedSearchQuery.query,
      selectedKeys,
      searchQuery?.isFileImport,
      searchQuery?.file,
      filePath,
    ]

    if (queryUserStatus === 'active') {
      queryUsersParameters.push(searchQuery.filterUsersWithoutRecentActivity)
    }

    return queryUsersParameters
  }

  const [users, usersRequestStatus, queryUsers] = useUsers(
    ...getQueryUsersParameters()
  )

  const { isLoading, isLoaded, errorStatus } = usersRequestStatus

  const handleSelect = useCallback((selected) => {
    setSelectedUsers(selected)
  })

  const handleQueryChange = useCallback(
    (data) => {
      setSelectedUsers({})
      setSearchQuery((oldSearchQuery) => ({
        ...oldSearchQuery,
        tree: { ...data.tree, name: data.name, presetId: data.presetId },
        isFileImport: false,
        file: undefined,
      }))
    },
    [setSearchQuery, setSelectedUsers]
  )

  const handleFileSearch = useCallback(
    (data) => {
      setSelectedUsers({})
      setSearchQuery((oldSearchQuery) => ({
        ...oldSearchQuery,
        tree: {},
        isFileImport: true,
        file: data.file,
      }))
    },
    [setSearchQuery, setSelectedUsers]
  )

  const handleUsersWithActivityFilterChange = useCallback(
    (value) => (e) => {
      setSelectedUsers({})

      if (e.target.checked) {
        setSearchQuery((oldSearchQuery) => ({
          ...oldSearchQuery,
          filterUsersWithoutRecentActivity: value,
        }))
      } else {
        setSearchQuery((oldSearchQuery) => ({
          ...oldSearchQuery,
          filterUsersWithoutRecentActivity:
            FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS.NONE,
        }))
      }
    },
    [setSearchQuery, setSelectedUsers]
  )

  useEffect(() => {
    let shouldQueryUsers = false

    if (parsedSearchQuery?.isFileImport) {
      shouldQueryUsers = parsedSearchQuery?.file || !isEmpty(filePath)
    } else {
      shouldQueryUsers = !isEmpty(parsedSearchQuery?.query)
    }

    if (shouldQueryUsers) {
      onSearch && onSearch(searchQuery)
      const params = getQueryUsersParameters()
      queryUsers(...params, (res) => {
        setSubmissionError(res?.error)
      })
    }
  }, [parsedSearchQuery, queryUsers])

  useEffect(() => {
    if (!isLoading && isLoaded && selectable && parsedSearchQuery.presetId) {
      setSelectedUsers(
        users.reduce((mapping, user) => {
          mapping[user.id] = true
          return mapping
        }, {})
      )
    }
  }, [isLoading, isLoaded, searchQuery?.tree?.presetId])

  return (
    <Page className={css.page}>
      <CenteredContent size="xlarge">
        {queryUserStatus === 'active' && (
          <div className={css.notActiveUsersFilters}>
            <div className={css.row}>
              <div>
                <input
                  type="checkbox"
                  id="filterNotActive_restrictedVendors"
                  onChange={handleUsersWithActivityFilterChange(
                    FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS.RESTRICTED_VENDORS
                  )}
                  checked={
                    searchQuery.filterUsersWithoutRecentActivity ===
                    FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS.RESTRICTED_VENDORS
                  }
                />
              </div>
              <div>
                <label htmlFor="filterNotActive_restrictedVendors">
                  Filter out users with restricted email vendors and have no
                  recent activity (very recommended unless this is a
                  re-engagement campaign)
                </label>
              </div>
            </div>
            <div className={css.row}>
              <div>
                <input
                  type="checkbox"
                  id="filterNotActive_all"
                  onChange={handleUsersWithActivityFilterChange(
                    FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS.ALL
                  )}
                  checked={
                    searchQuery.filterUsersWithoutRecentActivity ===
                    FILTER_USERS_WITHOUT_RECENT_ACTIVITY_STATUS.ALL
                  }
                />
              </div>
              <div>
                <label htmlFor="filterNotActive_all">
                  Filter out all users with no recent activity
                </label>
              </div>
            </div>
          </div>
        )}

        <UserSearchWithPresets
          onQueryChange={handleQueryChange}
          queryUserStatus={queryUserStatus}
          activeTab={activeTab}
          tree={searchQuery?.tree}
          handleFileSearch={handleFileSearch}
          fileDownloadUrl={fileDownloadUrl}
          file={searchQuery?.file}
        />
        <UserTable
          users={users}
          onSelect={selectable && handleSelect}
          selected={selectable && selectedUsers}
          isLoading={isLoading}
          loadingDataFailed={!isEmpty(submissionError) || Boolean(errorStatus)}
          errorMessage={
            submissionError
              ? submissionError
              : "Couldn't load users, please refine your query and try again."
          }
        />
      </CenteredContent>
    </Page>
  )
}

const UserSearchPage = () => {
  const [selectedUsers, setSelectedUsers] = useState({})
  return (
    <div className={css.userSearchPageContainer}>
      <UserSearch
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    </div>
  )
}
export default UserSearchPage
