import React, { useState, useEffect, useRef, useMemo } from 'react'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { useCurriculums } from 'mednet-cns/src/hooks/curriculum'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { useDispatch } from 'react-redux'

import { addCurriculumQuestion } from 'mednet-cns/src/reducers/curriculum'

import css from './styles.scss'
import CurriculumMenuItem from './curriculumMenuItem'

const AddToCurriculumButton = withPermissions(authItems.admin)(
  ({ questionId }) => {
    const curriculumMenuRef = useRef()
    const editionMenuRef = useRef()
    const [showCurriculums, setShowCurriculums] = useState(false)
    const [hoveringCurriculumId, setHoveringCurriculumId] = useState(null)
    const [editionMenuOffsetTop, setEditionMenuOffsetTop] = useState(0)

    const [curriculums, { isLoading: loading, errorStatus: error }] =
      useCurriculums()

    const hoveringCurriculum = useMemo(
      () =>
        hoveringCurriculumId
          ? curriculums.find((x) => x.curriculumId === hoveringCurriculumId)
          : null,
      [curriculums, hoveringCurriculumId]
    )

    const dispatch = useDispatch()
    const createCurriculumQuestion = (
      questionId,
      curriculumId,
      curriculumEditionId
    ) => {
      dispatch(
        addCurriculumQuestion(
          questionId,
          curriculumId,
          curriculumEditionId ?? null
        )
      )
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        const curriculumClicked = curriculumMenuRef.current?.contains(
          event.target
        )
        const editionClicked = editionMenuRef.current?.contains(event.target)

        if (!curriculumClicked && !editionClicked) {
          setShowCurriculums(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
      <div>
        <div className={css.button}>
          <Button onClick={() => setShowCurriculums(true)}>
            + to curriculum
          </Button>
        </div>

        {showCurriculums && (
          <div className={css.menuContainer}>
            <div ref={curriculumMenuRef} className={css.curriculumMenu}>
              {loading && (
                <div className={css.menuItemLoading}>
                  <StarLoader
                    size="small"
                    isVerticalMargin={false}
                    wait={false}
                  />
                </div>
              )}
              {!loading && error && (
                <div className={css.menuItemError}>
                  Error loading curriculums
                </div>
              )}
              {curriculums.map((curriculum) => (
                <CurriculumMenuItem
                  key={`curriculum-${curriculum.curriculumId}`}
                  questionId={questionId}
                  name={curriculum.name}
                  curriculumQuestions={curriculum.curriculumQuestions}
                  loading={curriculum.addingQuestion}
                  disabled={loading || curriculum.addingQuestion}
                  onClick={() =>
                    createCurriculumQuestion(
                      questionId,
                      curriculum.curriculumId
                    )
                  }
                  onMouseEnter={(e) => {
                    setHoveringCurriculumId(curriculum.curriculumId)
                    setEditionMenuOffsetTop(e.target.offsetTop)
                  }}
                />
              ))}
            </div>

            {Boolean(hoveringCurriculum?.curriculumEditions?.length) && (
              <div
                ref={editionMenuRef}
                className={css.editionMenu}
                style={{ top: editionMenuOffsetTop }}
              >
                {hoveringCurriculum.curriculumEditions.map((edition) => (
                  <CurriculumMenuItem
                    key={`edition-${edition.curriculumEditionId}`}
                    questionId={questionId}
                    name={edition.name}
                    curriculumQuestions={hoveringCurriculum.curriculumQuestions.filter(
                      (cq) =>
                        cq.curriculumEditionId === edition.curriculumEditionId
                    )}
                    loading={hoveringCurriculum.addingQuestion}
                    disabled={loading || hoveringCurriculum.addingQuestion}
                    onClick={() =>
                      createCurriculumQuestion(
                        questionId,
                        hoveringCurriculum.curriculumId,
                        edition.curriculumEditionId
                      )
                    }
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)

export default AddToCurriculumButton
