import React from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
    })

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })
  }

  render() {
    const { children, ErrorComponent } = this.props
    const { hasError } = this.state

    if (hasError) {
      if (ErrorComponent) {
        return <ErrorComponent />
      }

      return null
    }

    return children
  }
}

export default ErrorBoundary
