import React from 'react'
import classNames from 'classnames'

import { Footer } from 'components/footer'

import css from './landingPageContent.scss'

export const LandingPageContent = ({ children, noBackgroundImage }) => (
  <div
    className={classNames(css.landingPagesBody, {
      [css.noBackgroundImage]: noBackgroundImage,
    })}
  >
    {/*
      Added div wrapper with display block for IE 11:
      https://stackoverflow.com/questions/31354137/element-is-not-horizontally-centered-in-ie11-with-flexbox-when-parent-has-flex-f
    */}
    <div>{children}</div>
    <Footer />
  </div>
)
