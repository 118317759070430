import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Icon } from 'pharmacy/src/display/icon'
import { Body1 } from 'pharmacy/src/typography'

import { toggleFollowTopic } from 'mednet-cns/src/reducers/topic'

import css from './followTopicButton.scss'

class FollowTopicButton extends React.Component {
  render() {
    const { topic, isFollowed, toggleFollowTopic, className } = this.props
    const starIcon = [isFollowed ? 'fas' : 'far', 'star']
    const containerClasses = classNames(css.container, className)

    return (
      <div className={containerClasses} onClick={toggleFollowTopic}>
        <Icon icon={starIcon} className={css.followIcon} />
        <Body1 className={css.followTopic}>{topic}</Body1>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFollowed, topic } = state.topic.topics[ownProps.topicId]

  return {
    isFollowed,
    topic,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleFollowTopic: () => dispatch(toggleFollowTopic(ownProps.topicId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowTopicButton)
