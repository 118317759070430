import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { EXPERT_EMAIL_MODAL } from 'mednet-util/src/constants/modal'

import EditEmailScreen from './editEmailScreen'
import FinishedScreen from './finishedScreen'

const screens = {
  [EXPERT_EMAIL_MODAL.screens.email]: EditEmailScreen,
  [EXPERT_EMAIL_MODAL.screens.finished]: FinishedScreen,
}

class EmailModal extends React.Component {
  render() {
    return (
      <Modal
        modalId={EXPERT_EMAIL_MODAL.modalId}
        screens={screens}
        defaultScreen={EXPERT_EMAIL_MODAL.screens.email}
        closeOnBackdrop={false}
        {...this.props}
      />
    )
  }
}

export default EmailModal
