import React from 'react'
import classNames from 'classnames'

import { LinkHeader } from 'pharmacy/src/typography'

import css from './headerLink.scss'

class HeaderLink extends React.Component {
  render() {
    const { isActive, className, ...restProps } = this.props

    const classes = classNames(className, css.headerLink)

    return (
      <LinkHeader className={classes} {...restProps}>
        {this.props.children}
      </LinkHeader>
    )
  }
}

export default HeaderLink
