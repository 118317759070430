import React, { useEffect, useState } from 'react'

import { Body1, Header2, Header3 } from 'pharmacy/src/typography'
import { useDispatch } from 'react-redux'

import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'
import { useParams } from 'react-router-dom'

import { subscribeExpertEmails } from 'mednet-cns/src/reducers/user'

import { mixpanel } from 'mednet-util/src/tracking'

import css from './index.scss'

export const ResubscribeExpertEmails = (props) => {
  const searchParams = new URLSearchParams(props.location.search)

  const token = searchParams.get('token')

  const pathParams = useParams()
  const userId = Number(pathParams.userId)

  const dispatch = useDispatch()

  const [isSubmitted, setSubmitted] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = () => {
    setSubmitting(true)
    // resubscribed_expert_emails
    mixpanel.track('resubscribed_expert_emails', { user_id: userId })
    dispatch(
      subscribeExpertEmails(
        {
          userId,
          token,
        },
        () => {
          setSubmitting(false)
          setSubmitted(true)
        }
      )
    )
  }

  useEffect(() => {
    mixpanel.track('viewed_page', {
      page_viewed: 'resubscribe_expert_emails',
      user_id: userId,
    })
  }, [])

  if (isSubmitted) {
    return (
      <div className={css.centeredContainer}>
        <Header2>
          <Icon icon="check-circle" className={css.checkIcon} />
          Thank you for being a part of theMednet community!
        </Header2>
      </div>
    )
  }

  return (
    <div className={css.centeredContainer}>
      <div className={css.row}>
        <Header3>Remain an expert on theMednet</Header3>
        <hr className={css.divider} />
      </div>
      <div className={css.row}>
        <Body1 className={css.message}>
          Please confirm that you would like to continue to be an expert at
          theMednet. As an expert, you will receive answer request emails.
        </Body1>
      </div>

      <div className={css.row}>
        <div className={css.buttonContainer}>
          <Button
            type="primary"
            className={css.button}
            isLoading={isSubmitting}
            onClick={() => handleSubmit()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
