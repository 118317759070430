import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Button } from 'pharmacy/src/input/button'
import { Link1 } from 'pharmacy/src/typography'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { parseQueryString } from 'mednet-util/src/router'

import {
  CREATE_QUESTION_REQUEST,
  createQuestionRequest,
} from 'mednet-cns/src/reducers/question'
import { QUESTION_REQUEST_MODAL } from 'mednet-util/src/constants/modal'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { authItems } from 'mednet-util/src/constants/permission'
import { withPermissions } from 'mednet-util/src/permission'

class RequestAnswersButton extends React.Component {
  handleRequestAnswers = () => {
    const { questionId } = this.props
    this.props.openQuestionRequestModal(
      QUESTION_REQUEST_MODAL.screens.confirmation
    )

    const confirmationFormdata = new FormData()
    confirmationFormdata.append('question_id', questionId)
    confirmationFormdata.append('type', 'confirmation')
    this.props.createQuestionRequest(confirmationFormdata)
  }

  openRequestTypeModel = () => {
    this.props.openQuestionRequestModal(
      QUESTION_REQUEST_MODAL.screens.requestType
    )
  }

  render() {
    const { numAnswers, questionRequestWasCreated, isNewQuestion, className } =
      this.props

    if (questionRequestWasCreated || isNewQuestion) {
      return null
    }

    return (
      <Link1 className={className}>
        <Button
          type="text"
          icon={['far', 'question']}
          onClick={
            numAnswers > 0
              ? this.openRequestTypeModel
              : this.handleRequestAnswers
          }
        >
          Request Answers
        </Button>
      </Link1>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { numAnswers } = state.question.questions[questionId]
  const createQuestionRequest =
    _.get(
      state.request.requests,
      makeRequestName(CREATE_QUESTION_REQUEST, questionId)
    ) || {}

  const params = parseQueryString(state.router.location.search)
  const isNewQuestion = params.action === 'new_question'

  return {
    numAnswers,
    questionRequestWasCreated: createQuestionRequest.isLoaded,
    isNewQuestion,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId } = ownProps

  return {
    openQuestionRequestModal: (screen) =>
      dispatch(
        openModal(QUESTION_REQUEST_MODAL.modalId, { questionId }, screen)
      ),
    createQuestionRequest: (formData) =>
      dispatch(createQuestionRequest(ownProps.questionId, formData)),
  }
}

export default withPermissions(authItems.createAnswer)(
  connect(mapStateToProps, mapDispatchToProps)(RequestAnswersButton)
)
