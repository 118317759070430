import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { parseQueryString } from 'mednet-util/src/router'
import { renderMixed } from 'mednet-util/src/render'

import { BaseTable } from './base/baseTable'
import { Paginator } from './paginator/paginator'
import { SORT_ASC } from './constants'

import css from './table.scss'

class Table extends React.Component {
  static defaultProps = {
    data: [],
    pageSize: 20,
  }

  constructor(props) {
    super(props)

    const normalizedColumns = this.normalizeColumns(this.props.columns)

    this.state = {
      data: this.processData(this.props.data, 0),
      columns: normalizedColumns,
      columnSortComparators: normalizedColumns.reduce((acc, currentColumn) => {
        if (currentColumn.sort === false) {
          return acc
        }
        if (currentColumn.sort) {
          acc[currentColumn.sort.key] = currentColumn.sort.comparator
        } else {
          acc[currentColumn.header.toLowerCase()] = (datum) =>
            renderMixed(datum, currentColumn.data)
        }
        return acc
      }, {}),
      sortOrder: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, pageNumber } = this.props
    const { sortOrder, sortKey } = this.state

    if (!Array.isArray(data)) {
      if (process.env.DEBUG) {
        // eslint-disable-next-line no-console
        console.error('data must be an array', data)
      }
      return
    }

    const dataChanged = data !== prevProps.data
    const pageNumberChanged = pageNumber !== prevProps.pageNumber
    const sortOrderChanged = sortOrder !== prevState.sortOrder
    const sortKeyChanged = sortKey !== prevState.sortKey

    if (
      dataChanged ||
      pageNumberChanged ||
      sortOrderChanged ||
      sortKeyChanged
    ) {
      const processedData = this.processData(
        data,
        pageNumber,
        sortKey,
        sortOrder
      )
      this.setState({
        data: processedData,
      })
    }
  }

  processData = (data, pageNumber, sortKey, sortOrder) => {
    let internalData = data.map((datum, index) => ({ ...datum, _index: index }))

    if (sortKey) {
      internalData = this.sortData(internalData, sortKey, sortOrder)
    }

    return this.paginateData(internalData, pageNumber)
  }

  paginateData = (data, pageNumber) => {
    const { pageSize } = this.props

    if (pageSize === null) {
      return data
    }

    return data.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)
  }

  sortData = (data, sortKey, sortOrder) => {
    const sortComparator = this.state.columnSortComparators[sortKey]
    const sortedData = data.sort((a, b) => {
      const compareA = sortComparator(a)
      const compareB = sortComparator(b)

      if (compareA < compareB) {
        return -1
      } else if (compareA > compareB) {
        return 1
      }

      return 0
    })

    if (sortOrder === SORT_ASC) {
      return sortedData
    }
    return sortedData.reverse()
  }

  normalizeColumns = (columns) => {
    return columns.map((column) => {
      if (column.sort || column.sort === false) {
        return column
      }

      return {
        ...column,
        sort: {
          key: column.header.toLowerCase(),
          comparator: (datum) => renderMixed(datum, column.data),
        },
      }
    })
  }

  handleSort = (sortKey, sortOrder) => {
    this.setState({
      sortKey,
      sortOrder,
    })
  }

  render() {
    const { pageSize, isLoading, pathname, pageNumber } = this.props
    const { sortOrder, sortKey } = this.state

    const pageCount = Math.ceil(this.props.data.length / pageSize)

    return (
      <div className={css.container}>
        <BaseTable
          {...this.props}
          sortOrder={sortOrder}
          sortKey={sortKey}
          onSort={this.handleSort}
          data={this.state.data}
          columns={this.state.columns}
        />
        {!isLoading && pageSize && (
          <Paginator
            className={css.paginator}
            pathname={pathname}
            activePageNumber={pageNumber}
            pageCount={pageCount}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const queryParameters = parseQueryString(state.router.location.search)
  return {
    pathname: state.router.location.pathname,
    pageNumber: Number(queryParameters.page) || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    push: (path) => dispatch(push(path)),
  }
}

const connectedTable = connect(mapStateToProps, mapDispatchToProps)(Table)

export { connectedTable as Table }
