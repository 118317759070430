import { noop } from 'lodash'
import React, {
  useMemo,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useState,
} from 'react'

import css from './tableSelect.scss'

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
})
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

const DisabledMessage = ({ message }) => {
  if (!message || typeof message !== 'string') {
    return null
  }

  return (
    // tabIndex is needed: https://stackoverflow.com/questions/11280379/is-it-possible-to-write-onfocus-lostfocus-handler-for-a-div-using-js-or-jquery
    <div className={css.disabledMessageContainer} tabIndex="100">
      <div className={css.disabledMessage}>{message}</div>
    </div>
  )
}

const useTableSelect = (
  selected,
  disabled,
  data,
  isLoading,
  onSelect,
  onSelectAll,
  onDeselectAll,
  DisabledCheckboxReplacement,
  defaultAllSelected
) => {
  if (!onSelect) {
    return [{}, noop, (state) => state, noop]
  }
  const [constrolledSelectedAll, setControlledSelectedAll] =
    useState(defaultAllSelected)

  // Reset selected all when data changes
  useEffect(() => {
    setControlledSelectedAll(defaultAllSelected)

    if (defaultAllSelected) {
      if (onSelectAll) {
        onSelectAll(data)
      } else {
        const mapping = {}
        data.forEach((row) => (mapping[row.id] = true))
        onSelect(mapping)
      }
    }
  }, [data])

  const translateSelectedRowIndicesToIds = useCallback(
    (data, selectedRowIndices = {}) =>
      Object.keys(selectedRowIndices).reduce((mapping, index) => {
        mapping[data[index].id] = true
        return mapping
      }, {})
  )

  const translateSelectedRowIdsToIndices = useCallback(
    (data, selectedRowIds = {}) => {
      const mapping = {}

      data.forEach((row, i) => {
        if (selectedRowIds[row.id]) {
          mapping[i] = true
        }
      })

      return mapping
    }
  )

  const preselectedRowIds = useMemo(() => {
    if (!isLoading && data && selected) {
      return translateSelectedRowIdsToIndices(data, selected)
    }

    return {}
  }, [selected, data, isLoading])

  const useTableSelectionControlledState = useCallback(
    (state) => {
      if (selected) {
        return React.useMemo(
          () => ({
            ...state,
            selectedRowIds: preselectedRowIds,
            selectedAll: constrolledSelectedAll,
            disabledRowIds: disabled,
            onSelect,
            onSelectAll,
            onDeselectAll,
          }),
          [
            state,
            preselectedRowIds,
            constrolledSelectedAll,
            disabled,
            onSelect,
            onSelectAll,
            onDeselectAll,
          ]
        )
      }

      return state
    },
    [
      preselectedRowIds,
      disabled,
      constrolledSelectedAll,
      onSelect,
      onSelectAll,
      onDeselectAll,
    ]
  )

  const handleSelect = useCallback(
    (onChange, onSelect, data, rowData, selected, isControlledState = false) =>
      (e) => {
        const translated = translateSelectedRowIndicesToIds(data, selected)
        if (onSelect) {
          if (translated[rowData.id]) {
            delete translated[rowData.id]
            onSelect({
              ...translated,
            })
          } else {
            onSelect({
              ...translated,
              [rowData.id]: true,
            })
          }
        }

        setControlledSelectedAll(false)

        if (!isControlledState) {
          onChange(e)
        }
      },
    [setControlledSelectedAll]
  )

  const handleSelectToggleAll = useCallback(
    (
        onChange,
        onSelect,
        onSelectAll,
        onDeselectAll,
        data,
        selectedAll,
        isControlledState = false
      ) =>
      (e) => {
        if (selectedAll) {
          if (onDeselectAll) {
            onDeselectAll(data)
          } else {
            onSelect({})
          }
        } else {
          if (onSelectAll) {
            onSelectAll(data)
          } else {
            const mapping = {}
            data.forEach((row) => (mapping[row.id] = true))
            onSelect(mapping)
          }
        }

        setControlledSelectedAll(!selectedAll)

        if (!isControlledState) {
          onChange(e)
        }
      },
    [setControlledSelectedAll]
  )

  const useSelectionCheckboxes = useCallback(
    (hooks) => {
      if (selected) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps, data, state }) => {
              const cellProps = getToggleAllRowsSelectedProps()
              const { onChange } = cellProps
              const { selectedAll, onSelect, onSelectAll, onDeselectAll } =
                state
              return (
                <div className={css.selectCell}>
                  <IndeterminateCheckbox
                    {...cellProps}
                    onChange={handleSelectToggleAll(
                      onChange,
                      onSelect,
                      onSelectAll,
                      onDeselectAll,
                      data,
                      selectedAll,
                      true
                    )}
                    checked={selectedAll}
                    indeterminate={false}
                  />
                </div>
              )
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row, state, data }) => {
              const { selectedRowIds, disabledRowIds, onSelect } = state
              const { onChange, ...cellProps } = row.getToggleRowSelectedProps()
              return (
                <div className={css.selectCell}>
                  {DisabledCheckboxReplacement &&
                  disabledRowIds[row.original.id] ? (
                    <DisabledCheckboxReplacement />
                  ) : (
                    <IndeterminateCheckbox
                      disabled={disabledRowIds[row.original.id]}
                      onChange={handleSelect(
                        onChange,
                        onSelect,
                        data,
                        row.original,
                        selectedRowIds,
                        true
                      )}
                      {...cellProps}
                    />
                  )}

                  <DisabledMessage message={disabledRowIds[row.original.id]} />
                </div>
              )
            },
            width: 50,
          },
          ...columns,
        ])
      } else {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps, data, state }) => {
              const cellProps = getToggleAllRowsSelectedProps()
              const { onChange } = cellProps
              const { selectedAll, onSelect, onSelectAll, onDeselectAll } =
                state

              return (
                <IndeterminateCheckbox
                  {...cellProps}
                  onChange={handleSelectToggleAll(
                    onChange,
                    onSelect,
                    onSelectAll,
                    onDeselectAll,
                    data,
                    selectedAll,
                    false
                  )}
                  checked={selectedAll}
                  indeterminate={false}
                />
              )
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row, state, data }) => {
              const { selectedRowIds, disabledRowIds, onSelect } = state
              const { onChange, ...cellProps } = row.getToggleRowSelectedProps()
              return (
                <IndeterminateCheckbox
                  disabled={disabledRowIds[row.original.id]}
                  onChange={handleSelect(
                    onChange,
                    onSelect,
                    data,
                    row.original,
                    selectedRowIds
                  )}
                  {...cellProps}
                />
              )
            },
            width: 50,
            headerClassName: css.selectCell,
          },
          ...columns,
        ])
      }
    },
    [selected, DisabledCheckboxReplacement, handleSelectToggleAll, handleSelect]
  )

  return [
    preselectedRowIds,
    useSelectionCheckboxes,
    useTableSelectionControlledState,
    handleSelect,
  ]
}

export default useTableSelect
