import React from 'react'

const createLoader = (WrappedComponent) => {
  class Loader extends React.Component {
    static displayName = `Loader(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static defaultProps = {
      wait: 300,
    }

    constructor(props) {
      super(props)

      this.state = {
        isShowing: !props.wait,
      }
    }

    componentDidMount() {
      if (this.props.wait) {
        this.timeout = setTimeout(() => {
          this.setState({
            isShowing: true,
          })
        }, this.props.wait)
      }
    }

    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    }

    render() {
      const { isShowing } = this.state

      if (!isShowing) {
        return null
      }

      return <WrappedComponent {...this.props} />
    }
  }

  return Loader
}

export default createLoader
