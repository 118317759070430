import React from 'react'
import _ from 'lodash'
import slugify from 'slugify'
import { connect } from 'react-redux'

import { parseQueryString } from 'mednet-util/src/router'

import Tabs from './tabs'

class RouterTabs extends React.Component {
  render() {
    const { tabs, search } = this.props
    const { tab } = search

    const activeSlug = _.findIndex(
      tabs,
      (tabObject) => slugify(tabObject.header.props.title) === tab
    )

    return (
      <Tabs
        {...this.props}
        isInRouter
        activeTab={activeSlug >= 0 ? activeSlug : 0}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const { pathname, search } = state.router.location
  return {
    pathname,
    search: parseQueryString(search),
  }
}

export default connect(mapStateToProps)(RouterTabs)
