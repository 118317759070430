import React from 'react'

import { useServerScriptExample } from 'mednet-cns/src/hooks/campaign'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

const ServerScriptsPage = ({ name }) => {
  const [scriptExample, requestDetails] = useServerScriptExample(name)

  if (!name) {
    return null
  }

  if (requestDetails.isLoading) {
    return <StarLoader />
  }

  return <div dangerouslySetInnerHTML={{ __html: scriptExample }} />
}

export { ServerScriptsPage }
