import React from 'react'

import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'

import css from './headerAutoComplete.scss'

export class AutoCompleteAskQuestionButton extends React.Component {
  render() {
    return (
      <Link className={css.suggestionLink} external pathname="/question/ask">
        <Subtitle2>
          <div
            className={`${css.suggestionLinkContent} ${css.askQuestionSuggestion}`}
          >
            <span className={css.questionIcon}>
              <Icon icon={['fas', 'question']} />
            </span>
            Ask a Question
          </div>
        </Subtitle2>
      </Link>
    )
  }
}

export const GatedAutoCompleteAskQuestionButton = withPermissions(
  authItems.createQuestion
)(AutoCompleteAskQuestionButton)
