export const questionStatus = {
  PENDING: 0,
  APPROVED: 1,
  DENIED: -1,
  HIDDEN: 2,
}

export const STATUS_LABEL = {
  [questionStatus.PENDING]: 'Pending',
  [questionStatus.APPROVED]: 'Approved',
  [questionStatus.DENIED]: 'Denied',
  [questionStatus.HIDDEN]: 'Hidden',
}
