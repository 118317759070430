export const PAGES = {
  // Main
  ASK_QUESTION: 'askQuestion',
  VIEW_TOPIC: 'viewTopic',
  VIEW_QUESTION: 'viewQuestion',
  VIEW_USER: 'viewUser',
  EDIT_ANSWER: 'editAnswer',
  HOME: 'home',
  VIEW_CAMPAIGN_QUESTION: 'viewCampaignQuestion',

  // Moderator manage
  MANAGE_QUESTION_ANSWERS: 'manageQuestionAnswers',
  MANAGE_EXPERT_EMAIL: 'manageExpertEmail',
  MANAGE_ANSWER_COMMENTS: 'manageAnswerComments',
  MANAGE_DIGEST: 'manageDigest',
  USER_SEARCH: 'userSearch',
  SYSTEM_JOBS: 'systemJobs',
  CAMPAIGN: 'campaign',
  INVITATION: 'invitation',
  TEMPLATES_PREVIEW: 'templatesPreview',

  // Sponsorships
  SPONSORSHIP_DASHBOARD: 'sponsorshipDashboard',
  SPONSORSHIP_INJECTED_CONTENT_DASHBOARD: 'sponsorshipInjectedContentDashboard',
  VIEW_SPONSORSHIP: 'viewSponsorship',
  SPONSORSHIP_MANAGE_CAMPAIGNS: 'sponsorshipManageCampaigns',
  SPONSORSHIP_MANAGE_EMAILS: 'sponsorshipManageEmails',
  SPONSORSHIP_SEND_EMAIL: 'sponsorshipSendEmail',
  SPONSORSHIP_ANSWER: 'sponsorshipAnswer',
  SPONSORSHIP_INJECT_CONTENT: 'sponsorshipInjectContent',

  // Jobs
  JOBS_ONBOARD_WELCOME: 'jobsOnboardWelcome',
  JOBS_ONBOARD_PREFERENCE: 'jobsOnboardPrefernce',
  JOBS_ONBOARD_CV: 'jobsOnboardCV',
  JOBS_ONBOARD_SUMMARY: 'jobsOnboardSummary',
  JOBS_DASHBOARD: 'jobsDashboard',
  JOBS_INSTITUTION_DIRECTORY: 'jobsInstitutionDirectory',
  JOBS_VIEW_INSTITUTION: 'jobsViewInstitution',
  JOBS_SAVED_INSTITUTIONS: 'jobsSavedInstitutions',

  // Landing pages
  ABOUT_US: 'About Us',
  TUMORBOARD: 'Tumor Board',
  EXPERTS: 'Experts',
  EDITORS: 'Editors',
  FELLOWS: 'Fellows',
  CONTACT_US: 'Contact Us',
  REGISTRATION: 'Register',
  PUBLIC_HOME: 'Home',
  PARTNERSHIPS: 'Journal Club',
  FAQ: 'FAQs',
  PROGRAMS: 'Programs',
  PASSWORD_RECOVERY: 'Password Recovery',
  LOGIN: 'Sign In',
  COMMUNITY_OVERVIEW: 'Overview',
  NEWS_RELEASES: 'News Releases',

  // CME
  CME_DASHBOARD: 'CME',
  SPONSORSHIP_ANSWERS_CME: 'Sposorship CME',

  MOC_PROFILE: 'MOC Profile',
  MOC_PROFILE_BY_ID: 'MOC Profile By ID',

  // Unregistered user pages
  STOP_INVITES: 'Stop Invites',

  SUBSCRIBE_EXPERT_EMAILS: 'Subscribe Expert Emails',
}
