import React from 'react'
import classNames from 'classnames'
import slugify from 'slugify'

import { Icon } from 'pharmacy/src/display/icon'
import { Subtitle2 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import css from './tabButton.scss'

class TabButton extends React.Component {
  handleClick = () => {
    const { isInRouter, onTabChange } = this.props
    if (isInRouter) {
      return
    }

    onTabChange(this.props.tabIndex)
  }

  render() {
    const {
      icon,
      title,
      active,
      isInRouter,
      isLoading,
      className,
      activeClassName,
    } = this.props
    const linkClasses = classNames({
      [css.link]: !active,
      [css.active]: active && !activeClassName,
      [className]: true,
      [activeClassName]: active,
    })

    let TabComponent = 'div'
    let tabProps = {}
    if (isInRouter && !active) {
      TabComponent = Link
      tabProps = {
        useExistingPathname: true,
        params: {
          tab: slugify(title),
        },
      }
    }

    const LinkTextComponent = active ? Subtitle2 : Subtitle2

    return (
      <LinkTextComponent className={css.header}>
        <TabComponent
          {...tabProps}
          onClick={this.handleClick}
          className={linkClasses}
        >
          <Icon className={css.icon} icon={icon} />
          {title}
          {!isLoading ? null : (
            <StarLoader
              size="small"
              isVerticalMargin={false}
              className={css.loader}
            />
          )}
        </TabComponent>
      </LinkTextComponent>
    )
  }
}

export default TabButton
