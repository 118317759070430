import React from 'react'

import { withNavigationComponents } from 'pharmacy/src/contexts/navigation'

const fromNavigationComponents = (componentName) => {
  class FromNavigationComponents extends React.Component {
    static displayName = `FromNavigationComponents(${componentName})`

    render() {
      const { navigationComponents, ...restProps } = this.props
      const NavigationComponent = navigationComponents[componentName]

      return <NavigationComponent {...restProps} />
    }
  }

  return withNavigationComponents(FromNavigationComponents)
}

export default fromNavigationComponents
