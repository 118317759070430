import React, { useCallback } from 'react'

import { mixpanel } from 'mednet-util/src/tracking'

import css from './invitedExpertChip.scss'

function ExpertStarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0957 2.55897L9 1.125L6.90427 2.55897L4.36475 2.62899L3.5133 5.01922L1.5 6.56649L2.21807 9L1.5 11.4335L3.5133 12.9808L4.36475 15.371L6.90427 15.441L9 16.875L11.0957 15.441L13.6353 15.371L14.4867 12.9808L16.5 11.4335L15.7819 9L16.5 6.56649L14.4867 5.01922L13.6353 2.62899L11.0957 2.55897ZM10.6339 6.75422L9.00001 3.09375L7.36608 6.75422L3.375 7.17487L6.35626 9.85781L5.52356 13.7783L9.00001 11.7759L12.4764 13.7783L11.6438 9.85781L14.625 7.17487L10.6339 6.75422Z"
        fill="white"
      />
    </svg>
  )
}

export default function InvitedExpertChip() {
  const handleClick = useCallback(() => {
    typeof window !== 'undefined' &&
      window.open('/experts', '_blank', 'noopener,noreferrer')
    mixpanel.track('clicked_expert_chip')
  }, [])

  return (
    <div className={css.chip} onClick={handleClick}>
      <ExpertStarIcon />
      <div>Invited Expert</div>
    </div>
  )
}
