import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'
import { makePOSTHeaders } from '../api/v1'

export const UPLOAD_CV = 'userCV/UPLOAD_CV'
export const GET_CV = 'userCV/GET_CV'
export const RESET_CV_RESPONSE = 'userCV/RESET_CV_RESPONSE'

export function uploadCV(formData, callback) {
  return {
    type: UPLOAD_CV,
    formData,
    callback,
  }
}

export function getCV() {
  return {
    type: GET_CV,
  }
}

export function resetCVResponse() {
  return {
    type: RESET_CV_RESPONSE,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    UPLOAD_CV,
    makeURL(`userCV/upload`),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, GET_CV, makeURL('userCV/getJSON'))
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  uploadResponse: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(UPLOAD_CV): {
      return receiveReducer(state, action, () => ({
        uploadResponse: action.response,
      }))
    }

    case receiveAction(GET_CV): {
      return receiveReducer(state, action, () => ({
        cv: action.response.cv,
      }))
    }

    case RESET_CV_RESPONSE: {
      return {
        ...state,
        uploadResponse: {},
      }
    }

    default:
      return state
  }
}
