import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { getEventPath } from 'mednet-util/src/poly'

import MenuHeader from './menuHeader'
import MenuChildren from './menuChildren'

import css from './dropdownMenu.scss'

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props)

    this.container = React.createRef()
    this.header = React.createRef()

    this.state = {
      isOpen: false,
      timeoutId: undefined,
    }
  }

  componentDidMount() {
    if (this.header.current) {
      this.setState({
        headerHeight: this.header.current.getBoundingClientRect().height,
      })
    }
  }

  // This is needed for when screen size changes and header padding changes, the height is not updated witout this
  componentDidUpdate(_, prevState) {
    if (
      this.header.current &&
      this.header.current.getBoundingClientRect().height !==
        prevState.headerHeight
    ) {
      this.setState({
        headerHeight: this.header.current.getBoundingClientRect().height,
      })
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('touchstart', this.onWindowClick)
    document.body.removeEventListener('click', this.onWindowClick)
    this.state.timeoutId && clearTimeout(this.state.timeoutId)
  }

  openMenu = (e) => {
    if (!this.props.item.children) {
      return
    }

    e.preventDefault()

    this.setState(
      {
        isOpen: true,
      },
      () => {
        document.body.addEventListener('touchstart', this.onWindowClick)
        document.body.addEventListener('click', this.onWindowClick)

        if (this.props.history) {
          const unlistenToLocation = this.props.history.listen(() => {
            this.setState(
              {
                isOpen: false,
              },
              () => {
                unlistenToLocation()
              }
            )
          })
        }
      }
    )
  }

  closeMenu = (e) => {
    e.preventDefault()
    this.setState({
      isOpen: false,
    })
  }

  onWindowClick = (event) => {
    if (_.includes(getEventPath(event), this.container.current)) {
      return
    }

    /* 
      Timeout is needed for header in small screen, we need to close the old menu AFTER openning the new one. Othewise, the header height will 
      shrink and the click to open a new menu will instead close all the headermenus, besides that anything behind the header in the screen will
      be clicked and this is the worst effect
     */
    const timeoutId = setTimeout(() => {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          document.body.removeEventListener('touchstart', this.onWindowClick)
          document.body.removeEventListener('click', this.onWindowClick)
        }
      )
    }, 200)

    this.setState({ timeoutId })
  }

  render() {
    const {
      item,
      HeaderComponent,
      headerComponentProps,
      displayIcon,
      IconElement,
      hasArrow,
      fullWidthItemsInSmallScreen,
    } = this.props
    const { children, className: headerClassName } = item

    return (
      <div
        className={classNames(css.container, {
          [css.fullWidthHeaderInSmallScreen]: fullWidthItemsInSmallScreen,
        })}
        ref={this.container}
      >
        <MenuHeader
          isOpen={this.state.isOpen}
          onClick={this.state.isOpen ? this.closeMenu : this.openMenu}
          HeaderComponent={HeaderComponent}
          headerComponentProps={headerComponentProps}
          forwardRef={this.header}
          displayIcon={displayIcon}
          IconElement={IconElement}
          className={headerClassName}
          {...this.props.item}
        />
        <MenuChildren
          headerHeight={this.state.headerHeight}
          isOpen={this.state.isOpen}
          hasArrow={hasArrow}
          fullWidthItemsInSmallScreen={fullWidthItemsInSmallScreen}
        >
          {!children ? children : children.filter((child) => child)}
        </MenuChildren>
      </div>
    )
  }
}

export default DropdownMenu
