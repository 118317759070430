import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Header3, Subtitle1 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import { getSponsorshipStoryModePath } from 'mednet-util/src/sponsorship'
import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { mixpanel } from 'mednet-util/src/tracking'

import { programTypesConfig } from '../configs'

import css from './questionProgramDialog.scss'

export default function QuestionProgramDialog({
  sponsorship,
  questionId,
  stubbed,
}) {
  const dispatch = useDispatch()

  const openRegistrationModal = () => {
    dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId))
  }

  const [showProgramDialog, setShowProgramDialog] = useState(true)

  if (!showProgramDialog) {
    return null
  }

  return (
    <div className={css.programDialog}>
      <div className={css.programDialogRow}>
        <Header3 className={css.programDialogText}>
          This question is part of a program
          <div>
            <Link
              pathname={
                stubbed
                  ? undefined
                  : getSponsorshipStoryModePath(
                      sponsorship.sponsorshipId,
                      questionId
                    )
              }
              external
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                mixpanel.track(
                  'clicked_to_view_question_in_program_story_mode',
                  {
                    sponsorship_id: sponsorship.sponsorshipId,
                    location: 'question_page_program_dialog',
                    sponsorship_topic: sponsorship.topic,
                    sponsorship_public_name: sponsorship.publicName,
                    sponsorship_description: sponsorship.description,
                    program_type: sponsorship.programType,
                    question_id: questionId,
                  }
                )
                if (stubbed) {
                  e.preventDefault()
                  openRegistrationModal()
                }
              }}
              className={css.programDialogLink}
            >
              {sponsorship.publicName}
            </Link>
          </div>
        </Header3>
        <div>
          <Icon
            className={css.programDialogCloseIcon}
            icon={['far', 'times']}
            onClick={() => setShowProgramDialog(false)}
          />
        </div>
      </div>
      <Subtitle1 className={css.programDialogSubtext}>
        {programTypesConfig[sponsorship.programType]?.description}
      </Subtitle1>
    </div>
  )
}
