import { specialtyDescription } from './constants/specialty'
import { institutionTypeName } from './constants/institution'

const SI_SYMBOLS = ['', 'k', 'M', 'G', 'T', 'P', 'E']

export const formatName = (
  firstName,
  middleName,
  lastName,
  degrees = '',
  { useMiddleInitial = true } = {}
) => {
  const spacedDegrees =
    degrees && degrees.length ? `, ${degrees.join(', ')}` : ''

  let middleNameString = middleName ? `${middleName} ` : ''
  if (middleName && useMiddleInitial) {
    middleNameString = `${middleName[0]}. `
  }
  return `${firstName} ${middleNameString}${lastName || ''}${spacedDegrees}`
}

export const formatAnonymousInstitution = (institutionType, region) => {
  const institutionPart = institutionTypeName[institutionType] || ''
  const regionPart = region ? ` (${region})` : ''
  return `${institutionPart}${regionPart}`
}

export const formatAnonymousName = (specialtyId, title) => {
  const titlePart = title ? `, ${title}` : ''

  return `${specialtyDescription[specialtyId]}${titlePart}`
}

export const getUserName = (userObject, options = {}) => {
  if (userObject.firstName) {
    return formatName(
      userObject.firstName,
      userObject.middleName,
      userObject.lastName,
      userObject.degrees,
      options
    )
  }

  return formatAnonymousName(userObject.specialtyId, userObject.title)
}

export const ordinalNumber = (number) => {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const nummod = number % 100
  return (
    number + (suffixes[(nummod - 20) % 10] || suffixes[nummod] || suffixes[0])
  )
}

export const roundStringNumber = (number, unit = '') => {
  const log = Math.log10(number)

  if (log < 4) {
    return number.toLocaleString()
  }

  const tier = (log / 3) | 0
  const suffix = SI_SYMBOLS[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale
  return scaled.toFixed(0) + suffix + unit
}

export const pluralizeWord = (number, word) => {
  return number === 1 ? word : `${word}s`
}

export const numberWord = (number, word) => {
  return `${number} ${pluralizeWord(number, word)}`
}

export const camelCase = (word) => {
  return word.replace(/-([a-z])/g, (token) => token[1].toUpperCase())
}

export const letterFromIndex = (index) => {
  return String.fromCharCode(65 + index)
}
