import React from 'react'
import _ from 'lodash'

import { Subtitle2 } from 'pharmacy/src/typography'

import css from './dashboard.scss'

export default function StatsList(props) {
  const { stats } = props

  const reached = (_.maxBy(stats, 'reached') || {}).reached || 0
  const volume = _.sumBy(stats, 'total') || 1
  const clicks = _.sumBy(stats, 'clicks') || 0
  const openings = _.sumBy(stats, 'openings') || 0

  const OR = _.round((openings / volume) * 100, 2)
  const CR = _.round((clicks / volume) * 100, 2)

  return (
    <ul className={css.statsList}>
      <li>
        <Subtitle2 className={css.statElement}>Reached: </Subtitle2>
        <Subtitle2 className={css.num}>{reached}</Subtitle2>
      </li>
      <li>
        <Subtitle2 className={css.statElement}>Open Rate: </Subtitle2>
        <Subtitle2 className={css.num}>{OR}%</Subtitle2>
      </li>
      <li>
        <Subtitle2 className={css.statElement}>Click Rate: </Subtitle2>
        <Subtitle2 className={css.num}>{CR}%</Subtitle2>
      </li>
    </ul>
  )
}
