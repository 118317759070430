import React, { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'

import { useSpecialtiesExpertsOncologyMerged } from 'mednet-cns/src/hooks/experts'
import { useSpecialties } from 'mednet-cns/src/hooks/specialty'
import { useQuestionsCount } from 'mednet-cns/src/hooks/question'

import { SpecialtiesUsersPreviewList } from 'components/specialtiesUsersPreviewList'

import css from './specialtiesExpertsList.scss'

export const SpecialtiesExpertsList = ({
  textClassName,
  titleClassName,
  buttonClassName,
}) => {
  const [{ expertsCount, specialtiesExpertsIds }, expertsRequest] =
    useSpecialtiesExpertsOncologyMerged()
  const [specialties, specialtiesRequest] = useSpecialties()
  const [questionsCount, questionsCountRequest] = useQuestionsCount()

  const [specialtyIdFilter, setSpecialtyIdFilter] = useState('oncology_merged')

  const modifiedSpecialties = useMemo(() => {
    if (!specialtiesRequest.isLoaded || !expertsRequest.isLoaded) {
      return []
    }

    return [
      {
        specialtyId: 'oncology_merged',
        specialty: 'Oncology',
        active: true,
      },
      ...Object.values(specialties).filter(
        (specialty) =>
          specialty.active &&
          !isEmpty(specialtiesExpertsIds[specialty.specialtyId])
      ),
    ]
  }, [specialties, specialtiesExpertsIds, specialtiesRequest.isLoaded])

  if (
    !specialtiesRequest.isLoaded ||
    !expertsRequest.isLoaded ||
    !questionsCountRequest.isLoaded
  ) {
    return <StarLoader isVerticalMargin />
  }

  return (
    <div className={css.expertsSection}>
      <div className={textClassName}>
        {/* Need inner div for IE */}
        <div className={titleClassName}>
          <div>Our Experts</div>
        </div>
        Access over {expertsCount.toLocaleString()} experts answering{' '}
        {questionsCount.toLocaleString()} clinical questions & counting
      </div>
      <SpecialtiesUsersPreviewList
        specialtiesUsersIds={specialtiesExpertsIds}
        specialtyIdFilter={specialtyIdFilter}
        setSpecialtyIdFilter={setSpecialtyIdFilter}
        specialties={modifiedSpecialties}
      />
      <div className={css.allExpertsButtonContainer}>
        <Link
          className={buttonClassName}
          pathname="/experts"
          search={
            // Need to double check that the filtered specialty id exists in the original not modified specialties
            specialties[specialtyIdFilter]
              ? { specialtyId: specialtyIdFilter }
              : undefined
          }
        >
          View List of All Experts
        </Link>
      </div>
    </div>
  )
}
