export const publicRoutes = [
  'campaign/question',
  'about',
  'tumorboard',
  'experts',
  'editors',
  'contact',
  'register',
  'partnerships',
  'faq',
  'programs',
  'recoverpassword',
  'user/login',
  'fellows',
  'communityOverview',
  'pressRelease',
  'stopInvites',
  'notificationPermissions/resubscribeExpertEmails',
]
