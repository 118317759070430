import React, { useEffect } from 'react'
import initReactFastclick from 'react-fastclick'

import { makeAssetURL } from 'mednet-util/src/router'
import { mixpanel } from 'mednet-util/src/tracking'

import { useExpertsCount } from 'mednet-cns/src/hooks/experts'

import { LandingPageContent } from 'components/landingPageContent'

import { SpecialtiesExpertsList } from './specialtiesExpertsList'

import { Hero } from './hero'
import { PhysiciansSection } from './physicians'
import { Partners } from './partners'
import { Testimonials } from './testimonials'
import { Publications } from './publications'

import css from './publicHome.scss'

const assetsPath = 'images/home/'

// https://stackoverflow.com/questions/17710893/why-when-do-i-have-to-tap-twice-to-trigger-click-on-ios
// https://www.npmjs.com/package/react-fastclick
// This is needed for mobile IOS. I think this may be needed on the app level, I am afraid to cause some not expected behaviour for some component
initReactFastclick()

const AboutUsSection = () => {
  const [expertsCount] = useExpertsCount()

  return (
    <div className={css.aboutUsSection}>
      <div className={css.sectionTitle}>ABOUT US</div>
      <div className={css.aboutUsText}>
        With medical knowledge evolving faster than ever before, theMednet
        addresses the gap between textbooks and real-patient experience. At
        theMednet, more than {expertsCount.toLocaleString()} expert physicians
        across numerous medical specialties help answer questions that go beyond
        textbooks and guidelines. Our platform documents undocumented medical
        knowledge, while making it accessible and easily searchable to all
        physicians.{' '}
        <i>We know patients with access to experts have better outcomes</i>. By
        providing physicians with relevant, up-to-date expert knowledge, their
        patients benefit from the best care possible.
      </div>
    </div>
  )
}

const FundingSection = () => {
  return (
    <div className={css.fundingSection}>
      <div className={css.fundingSectionContent}>
        <div className={css.fundingSectionTitle}>Grant funding from</div>
        <div className={css.logo}>
          <img
            src={makeAssetURL(`${assetsPath}lung-cancer-foundation-logo.png`)}
          />
        </div>
        <div className={css.wideLogo}>
          <img
            src={makeAssetURL(
              `${assetsPath}national-science-foundation-logo.png`
            )}
          />
        </div>
        <div className={css.logo}>
          <img
            src={makeAssetURL(`${assetsPath}the-hope-foundation-logo.png`)}
          />
        </div>
      </div>
    </div>
  )
}

export const PublicHome = ({ history }) => {
  useEffect(() => {
    mixpanel.track('viewed_homepage')
  }, [])
  return (
    <LandingPageContent noBackgroundImage>
      <Hero assetsPath={assetsPath} pathname={history?.location?.pathname} />
      <AboutUsSection />
      <SpecialtiesExpertsList
        textClassName={css.sectionText}
        titleClassName={css.sectionTitle}
        buttonClassName={css.secondaryButton}
      />
      <PhysiciansSection
        assetsPath={assetsPath}
        buttonClassName={css.secondaryButton}
        titleClassName={css.secondarySectionTitle}
      />
      <Partners
        assetsPath={assetsPath}
        titleClassName={css.sectionTitle}
        textClassName={css.sectionText}
        buttonClassName={css.secondaryButton}
      />
      <Testimonials
        assetsPath={assetsPath}
        titleClassName={css.secondarySectionTitle}
      />
      <Publications
        assetsPath={assetsPath}
        titleClassName={css.sectionTitle}
        buttonClassName={css.secondaryButton}
      />
      <FundingSection />
    </LandingPageContent>
  )
}
