import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { Publication } from 'pharmacy/src/display/publication'
import { Button } from 'pharmacy/src/input/button'
import Stub from 'pharmacy/src/navigation/stub/stub'
import StoryModeIcon from 'pharmacy/src/program/icons/storyModeIcon'

import { mednetAPI } from 'mednet-cns/src/api/v1'
import { openModal } from 'mednet-cns/src/reducers/modal'

import { mixpanel } from 'mednet-util/src/tracking'
import { cclickPlace } from 'mednet-util/src/constants/publication'
import {
  getLatestPublishedSponsorship,
  getSponsorshipStoryModePath,
} from 'mednet-util/src/sponsorship'
import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'

import { useUser } from 'mednet-cns/src/hooks/user'

import css from './programInformation.scss'

export default function ProgramInformation({
  questionId,
  stubbed,
  upperBorder,
  sponsorships,
  citations,
  containerClassName,
}) {
  const dispatch = useDispatch()
  const openRegistrationModal = () => {
    dispatch(openModal(CAMPAIGN_REGISTER_MODAL.modalId))
  }

  const [{ userData }] = useUser()

  const latestPublishedSponsorship = getLatestPublishedSponsorship(sponsorships)

  const referenceCitations = _.uniqBy(
    citations?.filter(
      (citation) =>
        citation.sponsorshipId === latestPublishedSponsorship?.sponsorshipId &&
        citation.place === cclickPlace.sponsorship
    ),
    (citation) => citation.publication.publicationId
  )

  const [showHint, setShowHint] = useState(null)

  if (!latestPublishedSponsorship) {
    return null
  }

  return (
    <div>
      {upperBorder && !_.isEmpty(referenceCitations) && (
        <hr className={css.divider} />
      )}

      <div className={containerClassName}>
        {!_.isEmpty(referenceCitations) && (
          <div className={css.referencePapers}>
            <div className={css.referencePapersTitle}>
              Reference Paper{referenceCitations.length > 1 ? 's' : ''}:
            </div>
            <div className={css.referencePapersContent}>
              {referenceCitations.map((referenceCitation) => (
                <div
                  key={referenceCitation.citeId}
                  className={css.referencePaperItem}
                  onMouseOver={() =>
                    setShowHint(
                      referenceCitations.length > 1
                        ? referenceCitation.citeId
                        : null
                    )
                  }
                  onMouseLeave={() => setShowHint(null)}
                >
                  {showHint === referenceCitation.citeId && (
                    <div className={css.referencePaperHint}>
                      {referenceCitation.publication.title}
                    </div>
                  )}
                  <Publication
                    userId={userData.userId}
                    item={referenceCitation.publication}
                    questionId={questionId}
                    citeId={referenceCitation.citeId}
                    place={referenceCitation.place}
                    linesLimit={referenceCitations.length > 1 ? 1 : undefined}
                    LinkComponent={
                      stubbed
                        ? ({ children }) => (
                            <Stub className={css.referencePaperLink}>
                              {children}
                            </Stub>
                          )
                        : undefined
                    }
                    onClick={() => {
                      const { citeId } = referenceCitation
                      mednetAPI(
                        `questionSponsorshipCitedPaper/clickTrackJSON/${citeId}`
                      )
                      mixpanel.track('Clicked Citation', {
                        id: citeId,
                        location: 'question_page_program_information',
                      })
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <Button
          type="quaternary"
          className={css.programButton}
          pathname={getSponsorshipStoryModePath(
            latestPublishedSponsorship.sponsorshipId,
            questionId
          )}
          external
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            mixpanel.track('clicked_to_view_question_in_program_story_mode', {
              sponsorship_id: latestPublishedSponsorship.sponsorshipId,
              sponsorship_topic: latestPublishedSponsorship.topic,
              sponsorship_public_name: latestPublishedSponsorship.publicName,
              sponsorship_description: latestPublishedSponsorship.description,
              program_type: latestPublishedSponsorship.programType,
              question_id: questionId,
              location: 'question_page_program_button',
            })
            if (stubbed) {
              e.preventDefault()
              openRegistrationModal?.()
            }
          }}
        >
          {referenceCitations?.length === 1 ? (
            <span>View all questions for this paper</span>
          ) : (
            <span>View all questions for this program</span>
          )}
          <StoryModeIcon fill="white" />
        </Button>
      </div>
      <hr className={css.divider} />
    </div>
  )
}
