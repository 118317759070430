import React from 'react'

import { ConfirmationScreen } from 'pharmacy/src/display/modal'

class FinishedScreen extends React.Component {
  componentDidMount() {
    this.props.clearForm()
  }

  render() {
    const { closeModal, existing } = this.props

    return (
      <ConfirmationScreen
        header={existing ? 'Email updated' : 'Email created'}
        closeModal={closeModal}
      />
    )
  }
}

export default FinishedScreen
