import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'

import { PROGRAM_TYPE } from 'mednet-util/src/constants/sponsorship'
import { makeAssetURL } from 'mednet-util/src/router'
import { getProgramCollectionPath } from 'mednet-util/src/sponsorship'

import { Link } from 'pharmacy/src/navigation/link'
import Stub from 'pharmacy/src/navigation/stub/stub'

import { mixpanel } from 'mednet-util/src/tracking'

import ProgramChip from '../programChip'

import classes from './programHighlight.scss'

export default function ProgramHighlight({
  sponsorship,
  stubbed,
  trackingProps,
}) {
  const displayJournal = useMemo(
    () =>
      sponsorship?.journal &&
      sponsorship?.programType === PROGRAM_TYPE.JOURNAL_CLUB,
    [sponsorship?.journal, sponsorship?.programType]
  )

  const displaySubspecialties = useMemo(
    () =>
      (sponsorship?.subspecialties?.length ?? 0) > 0 &&
      sponsorship?.programType === PROGRAM_TYPE.TARGETED_TOPIC,
    [sponsorship?.programType, sponsorship?.subspecialties?.length]
  )

  const subspecialtiesLabel = useMemo(() => {
    if (!sponsorship?.subspecialties?.length) {
      return null
    }

    return _.uniq(
      _.flatMap(sponsorship.subspecialties, (subspecialty) =>
        subspecialty?.subspecialty.split(',')
      )
        .map((subspecialty) => subspecialty?.trim())
        .filter((subspecialty) => !_.isEmpty(subspecialty))
    ).join(', ')
  }, [sponsorship?.subspecialties])

  const url = useMemo(
    () =>
      displayJournal
        ? getProgramCollectionPath(PROGRAM_TYPE.JOURNAL_CLUB, {
            journalIds: sponsorship?.journal?.journalId,
          })
        : displaySubspecialties
        ? getProgramCollectionPath(PROGRAM_TYPE.TARGETED_TOPIC, {
            subspecialtyIds: sponsorship?.subspecialties?.map(
              (subspecialty) => subspecialty.subspecialtyId
            ),
          })
        : '',
    [
      displayJournal,
      displaySubspecialties,
      sponsorship?.journal?.journalId,
      sponsorship?.subspecialties,
    ]
  )

  const hint = useMemo(
    () =>
      displayJournal
        ? `Show all ${sponsorship?.journal?.shortName} journal clubs`
        : displaySubspecialties
        ? `Show all targeted topics in (${subspecialtiesLabel})`
        : '',
    [
      displayJournal,
      displaySubspecialties,
      sponsorship?.journal?.shortName,
      subspecialtiesLabel,
    ]
  )

  const trackClick = useCallback(() => {
    let metricName = ''
    let filters = {}

    if (displayJournal) {
      metricName = 'clicked_view_all_journal_clubs_in_journal'
      filters = {
        filtered_journal: sponsorship.journal.journalId,
      }
    } else if (displaySubspecialties) {
      metricName = 'clicked_view_all_targetted_topics_in_subspecialties'
      filters = {
        filtered_subspecialties: sponsorship.subspecialties.map(
          (subspecialty) => subspecialty.subspecialtyId
        ),
      }
    }

    mixpanel.track(metricName, {
      ...filters,
      sponsorship_id: sponsorship.sponsorshipId,
      sponsorship_topic: sponsorship.topic,
      sponsorship_public_name: sponsorship.publicName,
      sponsorship_description: sponsorship.description,
      program_type: sponsorship.programType,
      ...trackingProps,
    })
  }, [displayJournal, displaySubspecialties, sponsorship, trackingProps])

  const LinkComponent = stubbed ? Stub : Link

  return (
    <div className={classes.ProgramHighlight}>
      {displayJournal && (
        <div className={classes.imageContainer}>
          <LinkComponent
            pathname={url}
            external
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackClick}
          >
            <object
              data={makeAssetURL(sponsorship.journal.icon)}
              type="image/png"
            >
              <div className={classes.defaultJournalIcon}>
                <div>{sponsorship.journal.shortName}</div>
              </div>
            </object>
          </LinkComponent>
          <div className={classes.tooltip}>{hint}</div>
        </div>
      )}
      <div>
        <ProgramChip programType={sponsorship.programType} />
        {(displayJournal || displaySubspecialties) && (
          <div className={classes.highlightText}>
            <LinkComponent
              pathname={url}
              external
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              onClick={trackClick}
            >
              {displayJournal
                ? sponsorship.journal.description
                : subspecialtiesLabel}
            </LinkComponent>
            <div className={classes.tooltip}>{hint}</div>
          </div>
        )}
      </div>
    </div>
  )
}
