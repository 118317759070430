import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Modal } from 'pharmacy/src/display/modal'
import { SPONSORSHIP_ADD_QUESTION_MODAL } from 'mednet-util/src/constants/modal'
import { Header1 } from 'pharmacy/src/typography'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { addQuestion } from 'mednet-cns/src/reducers/sponsorship'

import AddQuestionForm from '../forms/addQuestionForm'

const AddQuestionSchema = Yup.object().shape({
  questionId: Yup.number().required(),
})

class CreateScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    const { addQuestion, closeModal } = this.props

    const formData = new FormData(this.form.current)
    addQuestion(formData, () => {
      actions.setSubmitting(false)
      closeModal()
    })
  }

  render() {
    const { sponsorsIsLoading } = this.props

    if (sponsorsIsLoading) {
      return <StarLoader />
    }

    return (
      <div>
        <Header1>Add question</Header1>
        <hr />
        <Formik
          initialValues={{
            questionId: '',
          }}
          onSubmit={this.handleSubmit}
          validationSchema={AddQuestionSchema}
        >
          {(props) => (
            <AddQuestionForm {...props} {...this.props} formRef={this.form} />
          )}
        </Formik>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps

  return {
    addQuestion: (formData, callback) =>
      dispatch(addQuestion(sponsorshipId, formData, callback)),
  }
}

const ConnectedCreateScreen = connect(null, mapDispatchToProps)(CreateScreen)

class AddQuestionModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        modalId={SPONSORSHIP_ADD_QUESTION_MODAL.modalId}
        {...this.props}
      >
        <ConnectedCreateScreen />
      </Modal>
    )
  }
}

export default AddQuestionModal
