import React, { useMemo } from 'react'

import { useSpecialties } from 'mednet-cns/src/hooks/specialty'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'

import Table from 'components/tables/table'
import { ErrorPage } from 'pages/error'

import { ColumnTextFilter } from './cells'

const UserTable = React.memo(
  ({
    bodyHeight: bodyHeight = 500,
    onSelect: handleSelect,
    selected,
    users,
    isLoading,
    loadingDataFailed,
    errorMessage,
  }) => {
    const [specialtiesMap, specialtiesMapRequestStatus] = useSpecialties()
    const {
      isLoading: areSpecialtiesLoading,
      isLoaded: areSpecialtiesLoaded,
      errorStatus: specialtiesErrorStatus,
    } = specialtiesMapRequestStatus
    const isMetaLoading = [areSpecialtiesLoading, !areSpecialtiesLoaded].some(
      (b) => b
    )

    const columns = useMemo(() => {
      const firstUser = users[0] || {}

      const columns = [
        {
          Header: 'ID',
          accessor: 'id',
          width: 80,
          sortable: true,
          Cell: ({ value }) => (
            <span onClick={(e) => e.stopPropagation()}>
              <Link target="_blank" pathname={`/user/user/view/id/${value}`}>
                {value}
              </Link>
            </span>
          ),
        },
        {
          Header: 'Email',
          accessor: 'email',
          sortable: true,
          width: 300,
          Filter: ColumnTextFilter,
          filter: 'text',
        },
        {
          Header: 'First Name',
          accessor: 'firstName',
          sortable: true,
          width: 250,
          Filter: ColumnTextFilter,
          filter: 'text',
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
          sortable: true,
          width: 250,
          Filter: ColumnTextFilter,
          filter: 'text',
        },
        {
          Header: 'Specialty',
          accessor: 'specialtyId',
          sortable: true,
          id: 'specialty',
          width: 180,
          Cell: ({ value }) => specialtiesMap[value]?.specialty || '',
        },
      ]

      if (firstUser.askcount !== undefined) {
        columns.push({
          Header: 'Ask #',
          accessor: 'askcount',
          sortable: true,
          id: 'askcount',
          width: 100,
        })
      }
      if (firstUser.qviewcount !== undefined) {
        columns.push({
          Header: 'Q view #',
          accessor: 'qviewcount',
          sortable: true,
          id: 'qviewcount',
          width: 100,
        })
      }
      if (firstUser.qviewtopiccount !== undefined) {
        columns.push({
          Header: 'Unique Q view #',
          accessor: 'qviewtopiccount',
          sortable: true,
          id: 'qviewtopiccount',
          width: 100,
        })
      }

      return columns
    }, [specialtiesMap, users])

    if (specialtiesErrorStatus) {
      return <ErrorPage />
    }

    if (isMetaLoading) {
      return <StarLoader />
    }

    return (
      <>
        <Table
          id="users"
          columns={columns}
          data={users}
          isLoading={isLoading}
          bodyHeight={bodyHeight}
          loadingDataFailed={loadingDataFailed}
          errorMessage={errorMessage}
          onSelect={handleSelect}
          selected={selected}
          selectOnRowClick
        />
      </>
    )
  }
)
UserTable.displayName = 'UserTable'

export default UserTable
