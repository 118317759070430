import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

import { FlexRow, FlexRowCell } from 'pharmacy/src/display/content'
import CenteredContent from 'pharmacy/src/display/content/centeredContent'
import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography/index'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { mixpanel } from 'mednet-util/src/tracking'

import { getEmployeesBasicInfo } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  fetchExpertsCount,
  FETCH_EXPERTS_COUNT,
} from 'mednet-cns/src/reducers/expert'
import {
  fetchSpecialties,
  FETCH_SPECIALTIES,
} from 'mednet-cns/src/reducers/specialty'
import {
  fetchCountOfQuestions,
  FETCH_COUNT_OF_QUESTIONS,
} from 'mednet-cns/src/reducers/question'

import { TitledSection } from 'components/titledSection'
import { UserPhotoCard } from 'components/userPhotoCard'
import { ViewBioModaL } from 'components/viewBioModal'
import { PageTitle } from 'components/pageTitle'
import { LandingPageContent } from 'components/landingPageContent'

import css from './about.scss'

const numberToText = (number) => {
  const map = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
  ]

  if (number > 0 && number <= map.length) {
    return map[number - 1]
  }

  return number.toLocaleString()
}

const renderIntroductionInformation = (
  notOncolocgySpecialties,
  expertsCount,
  questionsCount
) => {
  let specialtiesText =
    'hematology, rheumatology, neurology, dermatology, pulmonology, cardiology, and nephrology'

  if (!isEmpty(notOncolocgySpecialties)) {
    const specialtiesNames = notOncolocgySpecialties.map((specialty) =>
      specialty.specialty.toLowerCase()
    )
    specialtiesText = `${specialtiesNames.slice(0, -1).join(', ')}, and ${
      specialtiesNames[specialtiesNames.length - 1]
    }`
  }

  return (
    <div>
      <FlexRow verticalMargins={false}>
        <FlexRowCell>
          <TitledSection header2="Who We Are" headerUnderlined>
            A physician-only platform for expert answers to real-world clinical
            situations
          </TitledSection>
        </FlexRowCell>
        <FlexRowCell>
          <TitledSection header2="Our Mission" headerUnderlined>
            Help physicians access expert knowledge to improve patient outcomes
          </TitledSection>
        </FlexRowCell>
      </FlexRow>
      <div className={css.ourStoryContainer}>
        <TitledSection header2="Our Story" headerUnderlined>
          <p>
            When our dad was diagnosed with cancer, Samir and I did what many
            families do and moved quickly to understand his treatment options.
            Unlike most families, my oncology background and network allowed us
            to access many expert physicians around the country.
          </p>
          <p>
            We connected with physicians who had extensive experience in the
            type of cancer our dad had been diagnosed with. Each physician was
            able to provide knowledge that&rsquo;s not always available in a
            textbook. This was knowledge based on their first-hand experiences
            and depth of research in treating like-patients. This was knowledge
            that could inform better decision-making throughout the treatment
            process.
          </p>
          <p>
            In hearing from these experts, we realized that there is a great
            deal of knowledge that often goes undocumented and is inaccessible
            to other physicians who may be treating similar cases. We imagined a
            platform within which this kind of intelligence could be better
            shared amongst physicians. That&rsquo;s how theMednet was formed.
          </p>
          <p>
            Samir&rsquo;s background in software engineering, mine in oncology,
            and our shared experience in finding optimal care for our dad all
            led to the launch of theMednet. We started as a platform for
            oncologists to connect and share clinical knowledge. Since then,
            we&rsquo;ve grown to include{' '}
            {numberToText(Math.max(7, notOncolocgySpecialties.length))}{' '}
            additional specialties: {specialtiesText}.
          </p>
          <p>
            Today, more than 20,000 physicians make up theMednet community.
            These include {expertsCount} Experts from top academic institutions.
            Physicians have access to over {questionsCount.toLocaleString()}{' '}
            questions that are easily searchable on the platform. Physicians can
            receive timely, peer-reviewed answers to clinical questions. All Q&A
            is moderated to meet the highest standards and to help in better
            navigating challenges.
          </p>
          <p>
            Access to collective expertise allowed our dad&rsquo;s physicians to
            make more informed decisions regarding his treatment. As a result,
            he is doing well today. Through theMednet, we are hoping physicians
            everywhere get that same benefit.
          </p>
          <p>- Nadine Housri, M.D. and Samir Housri, Co-founders</p>
        </TitledSection>
      </div>
    </div>
  )
}

const renderEmployeesCards = (employees) => {
  const imagePath = 'images/aboutus/'

  if (!employees || !employees.length) {
    return (
      <TitledSection header2="Our Team" headerUnderlined>
        <StarLoader isVerticalMargin />
      </TitledSection>
    )
  }

  return (
    <TitledSection header2="Our Team" headerUnderlined>
      <FlexRow verticalMargins={false}>
        {employees.map((employee) => (
          <FlexRowCell key={employee.userId}>
            <UserPhotoCard imagePath={imagePath} user={employee} />
          </FlexRowCell>
        ))}
      </FlexRow>
    </TitledSection>
  )
}

const renderEditorsIntroduction = () => {
  return (
    <CenteredContent className={css.centeredText}>
      <TitledSection header2="Editors" headerUnderlined>
        Deputy and Associate Editors maintain quality and professionalism on the
        platform, personally reviewing each question submitted.
      </TitledSection>
      <Button type="orange" className={css.button} pathname="/editors">
        <ProximaNova.Text2>Click to See A List of Editors</ProximaNova.Text2>
      </Button>
    </CenteredContent>
  )
}

const renderExpertsIntroduction = (expertsCount) => {
  return (
    <CenteredContent className={css.centeredText}>
      <TitledSection header2="Experts" headerUnderlined>
        Over {expertsCount.toLocaleString()} academic physicians from every
        major academic medical center in the US answer questions from other
        physicians.
      </TitledSection>
      <Button type="orange" className={css.button} pathname="/experts">
        <ProximaNova.Text2>Click to See A List of Experts</ProximaNova.Text2>
      </Button>
    </CenteredContent>
  )
}

export const About = () => {
  const dispatch = useDispatch()

  const selectAboutData = useCallback(
    createSelector(
      (state) => state.user.users,
      (state) => state.expert.expertsCount,
      (state) => getRequest(state, makeRequestName(FETCH_EXPERTS_COUNT)),
      (state) => state.specialty.specialties,
      (state) => getRequest(state, makeRequestName(FETCH_SPECIALTIES)),
      (state) => state.question.questionsCount,
      (state) => getRequest(state, makeRequestName(FETCH_COUNT_OF_QUESTIONS)),
      (
        users,
        expertsCount,
        expertsCountRequest,
        specialties,
        specialtiesRequest,
        questionsCount,
        questionsCountRequest
      ) => {
        return {
          employees: Object.values(users).filter((user) => user.isEmployee),
          expertsCount,
          expertsCountRequest,
          notOncolocgySpecialties: Object.values(specialties).filter(
            (specialty) =>
              specialty.active && !/oncology/i.test(specialty.specialty)
          ),
          specialtiesRequest,
          questionsCount,
          questionsCountRequest,
        }
      }
    ),
    []
  )

  const {
    employees,
    expertsCount,
    expertsCountRequest,
    notOncolocgySpecialties,
    specialtiesRequest,
    questionsCount,
    questionsCountRequest,
  } = useSelector(selectAboutData)

  useEffect(() => {
    if (!employees || !employees.length) {
      dispatch(getEmployeesBasicInfo())
    }

    if (!expertsCountRequest.isLoaded && !expertsCountRequest.isLoading) {
      dispatch(fetchExpertsCount())
    }

    if (!specialtiesRequest.isLoaded && !specialtiesRequest.isLoading) {
      dispatch(fetchSpecialties())
    }

    if (!questionsCountRequest.isLoaded && !questionsCountRequest.isLoading) {
      dispatch(fetchCountOfQuestions())
    }
  }, [])

  useEffect(() => {
    mixpanel.track('viewed_page', { page_viewed: 'about' })
  }, [])

  return (
    <LandingPageContent>
      <PageTitle>About Us</PageTitle>
      <div className={css.introductionContainer}>
        {renderIntroductionInformation(
          notOncolocgySpecialties,
          expertsCount,
          questionsCount
        )}
      </div>
      <div className={css.mainSectionContainer}>
        {renderEmployeesCards(employees)}
      </div>
      <div className={css.mainSectionContainer}>
        {renderEditorsIntroduction()}
      </div>
      <div className={css.mainSectionContainer}>
        {renderExpertsIntroduction(expertsCount)}
      </div>
      <ViewBioModaL />
    </LandingPageContent>
  )
}
