import { takeLatest, all } from 'redux-saga/effects'
import _ from 'lodash'
import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'

import {
  cancelAction,
  makeFetchEffect,
  receiveAction,
  receiveReducer,
  requestAction,
} from '../cns-util/reducer'

export const FETCH_CURRICULUMS = 'curriculum/FETCH_CURRICULUMS'
export const ADD_CURRICULUM_QUESTION = 'curriculum/ADD_CURRICULUM_QUESTION'

export function fetchCurriculums() {
  return {
    type: FETCH_CURRICULUMS,
  }
}

export function addCurriculumQuestion(
  questionId,
  curriculumId,
  curriculumEditionId
) {
  return {
    type: ADD_CURRICULUM_QUESTION,
    payload: {
      questionId,
      curriculumId,
      curriculumEditionId,
    },
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    ADD_CURRICULUM_QUESTION,
    makeURL('curriculums/addCurriculumQuestion'),
    (action) => makePOSTHeadersFromParams(action.payload)
  )

  yield makeFetchEffect(
    takeLatest,
    FETCH_CURRICULUMS,
    makeURL('curriculums/getAll')
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  curriculums: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_CURRICULUMS): {
      const curriculums = action.response
      return receiveReducer(state, action, () => ({
        curriculums: _.keyBy(curriculums, 'curriculumId'),
      }))
    }
    case receiveAction(ADD_CURRICULUM_QUESTION): {
      const curriculumQuestion = action.response
      return receiveReducer(
        state,
        action,
        () => ({
          curriculums: {
            ...state.curriculums,
            [curriculumQuestion.curriculumId]: {
              ...state.curriculums[curriculumQuestion.curriculumId],
              addingQuestion: false,
              curriculumQuestions: [
                ...state.curriculums[curriculumQuestion.curriculumId]
                  .curriculumQuestions,
                curriculumQuestion,
              ],
            },
          },
        }),
        () => ({
          curriculums: {
            ...state.curriculums,
            [action.payload.curriculumId]: {
              ...state.curriculums[action.payload.curriculumId],
              addingQuestion: false,
            },
          },
        })
      )
    }

    case cancelAction(ADD_CURRICULUM_QUESTION):
      return receiveReducer(state, action, () => ({
        curriculums: {
          ...state.curriculums,
          [action.payload.curriculumId]: {
            ...state.curriculums[action.payload.curriculumId],
            addingQuestion: false,
          },
        },
      }))

    case requestAction(ADD_CURRICULUM_QUESTION):
      return receiveReducer(state, action, () => ({
        curriculums: {
          ...state.curriculums,
          [action.payload.curriculumId]: {
            ...state.curriculums[action.payload.curriculumId],
            addingQuestion: true,
          },
        },
      }))

    default:
      return state
  }
}
