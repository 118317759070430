import React, { useState, useEffect, useMemo } from 'react'
import { useTable } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'

import { Page } from 'pharmacy/src/display/page'
import { CenteredContent } from 'pharmacy/src/display/content'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { CoverLoader, StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { ConfirmationModal } from 'pharmacy/src/display/modal'

import {
  fetchInjectedQuestions,
  getInjectedQuestions,
  getFetchInjectedQuestionsRequest,
  removeInjectedQuestion,
} from 'mednet-cns/src/reducers/sponsorship'

import {
  DELETE_SPONSORSHIP_INJECTION_MODAL,
  SPONSORSHIP_INJECT_QUESTION_MODAL,
  SPONSORSHIP_PREVIEW_UPDATE_MODAL,
} from 'mednet-util/src/constants/modal'

import { openModal } from 'mednet-cns/src/reducers/modal'

import { Link } from 'pharmacy/src/navigation/link'

import { PAGES } from 'constants/pages'
import { ROUTES } from 'constants/routes'
import { EXTERNAL_LINKS } from 'constants/links'

import * as css from './injectionDashboard.scss'
import PreviewUpdateModal from './modals/previewUpdate'
import InjectQuestionModal from './modals/injectQuestion'

export default function InjectionDashboard() {
  const dispatch = useDispatch()
  const [showCoverLoader, setShowCoverLoader] = useState(false)

  const loadInjectedQuestions = () => dispatch(fetchInjectedQuestions())
  const removeInjection = ({ closeModal, injectionId }) => {
    setShowCoverLoader(true)
    dispatch(
      removeInjectedQuestion(injectionId, () => setShowCoverLoader(false))
    )
    closeModal()
  }

  const fetchInjectedQuestionsRequest = useSelector((state) =>
    getFetchInjectedQuestionsRequest(state)
  )
  const injectedQuestions = useSelector((state) => getInjectedQuestions(state))

  const openConfirmationModal = (injectionId) => () =>
    dispatch(
      openModal(DELETE_SPONSORSHIP_INJECTION_MODAL.modalId, { injectionId })
    )
  const openPreviewUpdateModal = (updateId) => () =>
    dispatch(openModal(SPONSORSHIP_PREVIEW_UPDATE_MODAL.modalId, { updateId }))
  const openInjectModal = () =>
    dispatch(openModal(SPONSORSHIP_INJECT_QUESTION_MODAL.modalId))

  useEffect(() => {
    if (
      !fetchInjectedQuestionsRequest.isLoaded &&
      !fetchInjectedQuestionsRequest.isLoading
    ) {
      loadInjectedQuestions()
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'injectionDate',
      },
      {
        Header: 'Specialty',
        accessor: 'specialty',
      },
      {
        Header: 'Sponsorship',
        accessor: 'sponsorship',
      },
      {
        Header: 'Question',
        accessor: 'question',
      },
      {
        Header: 'Update id',
        accessor: 'updateId',
        Cell: ({ value }) => (
          <Link onClick={openPreviewUpdateModal(value)}>{value}</Link>
        ),
      },
      {
        Header: '',
        accessor: '[cell for actions]',
        Cell: (cell) => (
          <Button
            icon="trash"
            type={
              new Date(cell.row.original.injectionDate) < new Date()
                ? 'secondary'
                : 'destructive'
            }
            size="small"
            isDisabled={new Date(cell.row.original.injectionDate) < new Date()}
            onClick={openConfirmationModal(cell.row.original.injectionId)}
          />
        ),
      },
    ],
    []
  )

  if (!fetchInjectedQuestionsRequest.isLoaded) {
    return <StarLoader />
  }

  return (
    <Page>
      {!showCoverLoader ? null : <CoverLoader />}
      <CenteredContent size="xlarge">
        <Breadcrumbs
          links={[
            EXTERNAL_LINKS.moderatorPage,
            ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
            ROUTES[PAGES.SPONSORSHIP_INJECT_CONTENT],
          ]}
        />
        <div className={css.header}>
          <Header1>Forced question injection</Header1>
          <div className={css.actions}>
            <Button
              className={css.action}
              icon="plus"
              type="neutral"
              onClick={openInjectModal}
            >
              Add
            </Button>
          </div>
        </div>
        <Table
          className={css.table}
          columns={columns}
          data={injectedQuestions}
        />
      </CenteredContent>
      <ConfirmationModal
        closeText="Cancel"
        closeType="neutral"
        header="Are you sure you want to delete this forced injection?"
        modalId={DELETE_SPONSORSHIP_INJECTION_MODAL.modalId}
        onSubmit={removeInjection}
        submitText="Delete"
        submitType="destructive"
        size="large"
      />
      <PreviewUpdateModal />
      <InjectQuestionModal setLoader={(value) => setShowCoverLoader(value)} />
    </Page>
  )
}

function Table({ columns, data, className }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  // Render the UI for your table
  return (
    <table className={className} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <th key={j} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <td key={j} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
