import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getIsFetchNotificationsIdsLoading,
  getNotificationIds,
  fetchNotificationIds as actionFetchNotificationIds,
} from 'mednet-cns/src/reducers/sponsorship'

import { SPONSORSHIP_NOTIFICATIONS_MODAL } from 'mednet-util/src/constants/modal'

import { Modal } from 'pharmacy/src/display/modal'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'

import * as css from './previewNotificationList.scss'

function PreviewNotificationList({
  date,
  notificationType,
  sponsorshipId,
  questionId,
  updateId,
  specialtyId,
  subspecialtiesIds,
  viewedTopicsIds,
  followedTopicsIds,
  usersIds,
}) {
  const dispatch = useDispatch()
  const [more, setMore] = useState(50)
  const [id, setId] = useState()
  const incrementMore = () => setMore(more + 50)
  const setUserId = (e) => setId(e.target.value)

  const isFetchNotificationsIdsLoading = useSelector((state) =>
    getIsFetchNotificationsIdsLoading(state)
  )
  const notifications = useSelector((state) => getNotificationIds(state))
  const notificationsLen = notifications.length

  const fetchNotificationIds = () =>
    dispatch(
      actionFetchNotificationIds(
        date,
        notificationType,
        sponsorshipId,
        questionId,
        updateId,
        specialtyId,
        subspecialtiesIds,
        viewedTopicsIds,
        followedTopicsIds,
        usersIds
      )
    )

  useEffect(() => {
    fetchNotificationIds()
  }, [
    date,
    sponsorshipId,
    questionId,
    updateId,
    specialtyId,
    subspecialtiesIds,
    viewedTopicsIds,
    followedTopicsIds,
    usersIds,
  ])

  if (isFetchNotificationsIdsLoading) {
    return <StarLoader />
  }

  const filteredNotifications = notifications.filter(
    (not) => !id || not.userId === Number(id)
  )

  return (
    <>
      <label>
        Search for user id:
        <input onChange={setUserId}></input>
      </label>
      <ul>
        <li>UserID: NotificationID</li>
        {filteredNotifications
          .slice(0, more)
          .map(({ notificationId, userId }) => (
            <li key={notificationId}>
              <Link pathname={`/user/user/view/id/${userId}`} target="_blank">
                {userId}
              </Link>
              :{' '}
              <Link
                pathname={`/emailQueue/viewNotification/${notificationId}`}
                target="_blank"
              >
                {notificationId}
              </Link>
            </li>
          ))}
      </ul>
      {notificationsLen <= more ? null : (
        <div className={css.more}>
          {' '}
          <Link onClick={incrementMore}>show more</Link>
        </div>
      )}
    </>
  )
}

const screens = {
  [SPONSORSHIP_NOTIFICATIONS_MODAL.screens.preview]: PreviewNotificationList,
}

export default function PreviewNotificationListModal(props) {
  return (
    <Modal
      modalId={SPONSORSHIP_NOTIFICATIONS_MODAL.modalId}
      defaultScreen={SPONSORSHIP_NOTIFICATIONS_MODAL.screens.preview}
      screens={screens}
      {...props}
    />
  )
}
