import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'

import { Subtitle2, Subtitle1 } from 'pharmacy/src/typography'

import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './answerFeedback.scss'

class AnswerFeedback extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false,
    }
  }

  toggleExpand = () => {
    const { isExpanded } = this.state
    this.setState({
      isExpanded: !isExpanded,
    })
  }

  renderRest = () => {
    // eslint-disable-next-line no-unused-vars
    const [firstUser, ...restUsers] = this.props.users

    if (restUsers.length === 0) {
      return null
    }

    if (restUsers.length === 1) {
      return (
        <span>
          {' '}
          and{' '}
          <Subtitle1 component="span">
            <UserNameLink className={css.link} userId={restUsers[0].userId} />
          </Subtitle1>
        </span>
      )
    }

    if (!this.state.isExpanded) {
      return (
        <span>
          {' '}
          and{' '}
          <Subtitle1
            component="span"
            onClick={this.toggleExpand}
            className={css.link}
          >
            {restUsers.length} others
          </Subtitle1>
        </span>
      )
    }

    return (
      <span>
        {restUsers.map((user, idx) => (
          <span key={user.userId}>
            , {idx === restUsers.length - 1 && 'and '}
            <Subtitle1 component="span">
              <UserNameLink userId={user.userId} className={css.link} />
            </Subtitle1>
          </span>
        ))}
      </span>
    )
  }

  render() {
    const { users, text, canViewProfile } = this.props

    if (!users.length) {
      return null
    }

    if (!canViewProfile) {
      return (
        <Subtitle2 className={css.text}>
          {users.length} {users.length > 1 ? 'people' : 'person'} {text}
        </Subtitle2>
      )
    }

    return (
      <Subtitle2 className={css.text}>
        <Subtitle1 component="span">
          <UserNameLink className={css.link} userId={users[0].userId} />
        </Subtitle1>
        {this.renderRest()}
        <span> {text}</span>
      </Subtitle2>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const answerObject = state.answer.answers[ownProps.answerId]

  return {
    users: answerObject[ownProps.feedbackKey].map(
      (feedback) => state.user.users[feedback.userId]
    ),
    canViewProfile: _.includes(
      _.get(state.user, 'permissions.items', []),
      authItems.viewProfile
    ),
  }
}

export default connect(mapStateToProps)(AnswerFeedback)
