import React from 'react'
import classNames from 'classnames'

import { SORT_DESC, SORT_ASC } from 'pharmacy/src/display/table/constants'

import css from './baseTableHeaderCell.scss'

export class BaseTableHeaderCell extends React.Component {
  render() {
    const { column, sortOrder, sortKey, readOnly } = this.props
    if (
      column.sort === false ||
      column.sort === undefined ||
      readOnly === true
    ) {
      return column.header
    }

    const isSorted = sortKey === column.sort.key

    const cellClasses = classNames(css.sortableCell, {
      [css.sortableCell_asc]: isSorted && sortOrder === SORT_ASC,
      [css.sortableCell_desc]: isSorted && sortOrder === SORT_DESC,
    })

    const newSortOrder =
      isSorted && sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC

    const onSort = () => {
      this.props.onSort(column.sort.key, newSortOrder)
    }

    return (
      <th
        className={css.header}
        onClick={onSort}
        style={{ width: column.width }}
      >
        <div className={cellClasses}>{column.header}</div>
      </th>
    )
  }
}
