export const publicationAccess = {
  CLOSED: 0,
  OPEN_S3_FILE: 1,
  SPONSORED_S3_FILE: 2,
  SPONSORED_PUBLIC_URL_FILE: 3,
  SPONSORED_NO_FILE: 4,
  PUBLIC_URL_FILE: 5,
}

export const ACCESS_LABEL = {
  [publicationAccess.CLOSED]:
    'Publication has closed access (no file download access)',
  [publicationAccess.OPEN_S3_FILE]:
    'Publication has download file hosted by theMednet with opened access',
  [publicationAccess.SPONSORED_PUBLIC_URL_FILE]:
    'Publication is sponsored with public download URL (file)',
  [publicationAccess.SPONSORED_S3_FILE]:
    'Publication is sponsored with file hosted by theMednet',
  [publicationAccess.PUBLIC_URL_FILE]:
    'Publication has public download URL (file)',
}

export const downloadableStatuses = [
  publicationAccess.SPONSORED_S3_FILE,
  publicationAccess.SPONSORED_PUBLIC_URL_FILE,
  publicationAccess.OPEN_S3_FILE,
  publicationAccess.PUBLIC_URL_FILE,
]

export const cclickPlace = {
  answer: 'A',
  question: 'Q',
  sponsorship: 'S',
}
