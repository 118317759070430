import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import ReactPlayer from 'react-player/vimeo'

import { FlexRow, FlexRowCell } from 'pharmacy/src/display/content'
import { ProximaNova } from 'pharmacy/src/typography'
import ProximaNovaSelect from 'pharmacy/src/input/select/proximaNovaSelect'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { getUserName } from 'mednet-util/src/string'
import { makeAssetURL } from 'mednet-util/src/router'

import { getRequest } from 'mednet-cns/src/api/v1'
import {
  FETCH_TUMOR_BOARD_USERS,
  FETCH_TUMOR_BOARD,
  FetchTumorBoards,
  FetchTumorBoardsUsers,
  SetDiseaseSiteFilter,
  SetInstitutionFilter,
} from 'mednet-cns/src/reducers/tumorBoard'

import { PageTitle } from 'components/pageTitle'
import { SectionHeader } from 'components/titledSection/sectionHeader'
import { TitledSection } from 'components/titledSection/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import css from './tumorBoard.scss'

const getDiseaseSiteOptionLabel = (option) => {
  return option.subspecialty
}

const getDiseaseSiteOptionValue = (option) => {
  return option.subspecialtyId
}

export const getDiseaseSiteOptions = (
  subspecialtiesTumorBoards,
  institutionId
) => {
  return Object.entries(subspecialtiesTumorBoards)
    .map(([subspecialtyId, { subspecialty, tumorBoards }]) =>
      checkIfSubspecialityPassesFilters(
        subspecialtyId,
        undefined,
        institutionId,
        tumorBoards
      )
        ? {
            subspecialtyId,
            subspecialty,
          }
        : undefined
    )
    .filter((option) => option)
}

const handleDiseaseSiteChange = (option, action, dispatch) => {
  if (action === 'select-option') {
    dispatch(SetDiseaseSiteFilter(option.subspecialtyId))
  } else if (action === 'clear') {
    dispatch(SetDiseaseSiteFilter(undefined))
  }
}

const getInstitutionOptionLabel = (option) => {
  return option.institution
}

const getInstitutionOptionValue = (option) => {
  return option.institutionId
}

export const getInstitutionOptions = (
  subspecialtiesTumorBoards,
  diseaseSiteId
) => {
  const uniqueInstitutionsIds = new Set()

  // Get unique tumor boards instituations since two tumor boards can have the same institution
  return Object.entries(subspecialtiesTumorBoards)
    .map(([subspecialtyId, { tumorBoards }]) =>
      checkIfSubspecialityPassesFilters(
        subspecialtyId,
        diseaseSiteId,
        undefined,
        tumorBoards
      )
        ? tumorBoards
            .map((tumorBoard) => {
              if (!uniqueInstitutionsIds.has(tumorBoard.institutionId)) {
                uniqueInstitutionsIds.add(tumorBoard.institutionId)
                return {
                  institutionId: tumorBoard.institutionId,
                  institution: tumorBoard.institution,
                }
              }
              return undefined
            })
            .filter((option) => option)
        : undefined
    )
    .filter((option) => option)
    .flat()
    .sort((institution1, institution2) =>
      institution1.institution.localeCompare(institution2.institution)
    )
}

const handleInstitutionChange = (option, action, dispatch) => {
  if (action === 'select-option') {
    dispatch(SetInstitutionFilter(option.institutionId))
  } else if (action === 'clear') {
    dispatch(SetInstitutionFilter(undefined))
  }
}

const renderFilters = (
  tumorBoardRequestsLoaded,
  subspecialtiesTumorBoards,
  diseaseSiteId,
  institutionId,
  dispatch,
  className
) => {
  return (
    <div className={className}>
      <SectionHeader
        header3="Participating Sites and Site Leaders"
        headerLeftAligned
      />
      {tumorBoardRequestsLoaded && (
        <div className={css.selectContainer}>
          <div className={css.filterLabel}>Filter by:</div>
          <ProximaNovaSelect
            maxMenuHeight={300}
            options={getDiseaseSiteOptions(
              subspecialtiesTumorBoards,
              institutionId
            )}
            getOptionLabel={(option) => getDiseaseSiteOptionLabel(option)}
            getOptionValue={(option) => getDiseaseSiteOptionValue(option)}
            onChange={(option, { action }) => {
              handleDiseaseSiteChange(option, action, dispatch)
            }}
            name="DiseaseSite"
            placeholder="Disease Site"
            isClearable
          />
        </div>
      )}

      {tumorBoardRequestsLoaded && (
        <div className={css.selectContainer}>
          <div className={css.filterLabel}>Filter by:</div>
          <ProximaNovaSelect
            maxMenuHeight={300}
            options={getInstitutionOptions(
              subspecialtiesTumorBoards,
              diseaseSiteId
            )}
            getOptionLabel={(option) => getInstitutionOptionLabel(option)}
            getOptionValue={(option) => getInstitutionOptionValue(option)}
            onChange={(option, { action }) =>
              handleInstitutionChange(option, action, dispatch)
            }
            name="Institution"
            placeholder="Institution"
            isClearable
          />
        </div>
      )}
    </div>
  )
}

const renderInstitutionName = (tumorBoard) => {
  return (
    <div className={css.institutionNameContainer}>
      <div className={css.institutionLogoContainer}>
        <img
          className={css.institutionLogo}
          src={makeAssetURL(tumorBoard.logo)}
        />
      </div>

      <ProximaNova.Header3 className={css.institutionName}>
        {tumorBoard.institution}
      </ProximaNova.Header3>
    </div>
  )
}

const checkIfTumorBoardPassesFilters = (
  institutionFilterId,
  tumorBoardInstitutionId
) => {
  if (institutionFilterId && institutionFilterId !== tumorBoardInstitutionId) {
    return false
  }

  return true
}

const checkIfSubspecialityPassesFilters = (
  subspecialtyId,
  diseaseSiteFilterId,
  institutionFilterId,
  subspecialityTumorBoards
) => {
  if (
    (diseaseSiteFilterId && diseaseSiteFilterId !== subspecialtyId) ||
    (institutionFilterId &&
      !subspecialityTumorBoards.find(
        (tumorBoard) => tumorBoard.institutionId === institutionFilterId
      ))
  ) {
    return false
  }

  return true
}

const renderTumorBoardUser = (tumorBoard, user, isSiteLeader = false) => {
  const userName = getUserName(user)
  return (
    <ProximaNova.Subtitle2 className={css.expertDescription}>
      {isSiteLeader && (
        <span>
          <span className={css.siteLeader}>Site Leader</span>
          &nbsp;
        </span>
      )}{' '}
      {userName}
      <span className={css.expetTitle}>, {user.title}</span>
    </ProximaNova.Subtitle2>
  )
}

const renderSubspecialtyTumorBoards = (
  subspecialtyId,
  subspecialty,
  institutionId,
  subspecialityTumorBoards,
  tumorBoardsExperts,
  tumorBoardsSiteLeaders
) => {
  return (
    <TitledSection key={subspecialtyId} header2={subspecialty} headerUnderlined>
      {subspecialityTumorBoards.map((tumorBoard) => {
        if (
          checkIfTumorBoardPassesFilters(
            institutionId,
            tumorBoard.institutionId
          )
        ) {
          return (
            <FlexRow
              fullWidth
              size="large"
              key={tumorBoard.tumorBoardId}
              centered={false}
            >
              <FlexRowCell>{renderInstitutionName(tumorBoard)}</FlexRowCell>
              <FlexRowCell className={css.containerWithFlexGrow}>
                {tumorBoardsExperts[tumorBoard.tumorBoardId] && (
                  <FlexRow
                    fullWidth
                    size="small"
                    centered={false}
                    verticalMargins={false}
                  >
                    {tumorBoardsSiteLeaders[tumorBoard.tumorBoardId] && (
                      <FlexRowCell>
                        {renderTumorBoardUser(
                          tumorBoard,
                          tumorBoardsSiteLeaders[tumorBoard.tumorBoardId],
                          true
                        )}
                      </FlexRowCell>
                    )}

                    {tumorBoardsExperts[tumorBoard.tumorBoardId].map(
                      (expert) => (
                        <FlexRowCell
                          key={`${tumorBoard.tumorBoardId}_${expert.userId}`}
                        >
                          {renderTumorBoardUser(tumorBoard, expert)}
                        </FlexRowCell>
                      )
                    )}
                  </FlexRow>
                )}
              </FlexRowCell>
            </FlexRow>
          )
        }

        return undefined
      })}
    </TitledSection>
  )
}

const renderTumorBoards = (
  tumorBoardRequestsLoaded,
  subspecialtiesTumorBoards,
  tumorBoardsExperts,
  tumorBoardsSiteLeaders,
  diseaseSiteId,
  institutionId
) => {
  return (
    <div className={css.tumorBoardsContainer}>
      {tumorBoardRequestsLoaded ? (
        Object.entries(subspecialtiesTumorBoards).map(
          ([subspecialtyId, { subspecialty, tumorBoards }]) => {
            if (
              checkIfSubspecialityPassesFilters(
                subspecialtyId,
                diseaseSiteId,
                institutionId,
                tumorBoards
              )
            ) {
              return renderSubspecialtyTumorBoards(
                subspecialtyId,
                subspecialty,
                institutionId,
                tumorBoards,
                tumorBoardsExperts,
                tumorBoardsSiteLeaders
              )
            }

            return undefined
          }
        )
      ) : (
        <StarLoader isVerticalMargin />
      )}
    </div>
  )
}

const makeSelectTumorBoard = () =>
  createSelector(
    (state) => state.tumorBoard.subspecialtiesTumorBoards,
    (state) => state.tumorBoard.tumorBoardsExperts,
    (state) => state.tumorBoard.tumorBoardsSiteLeaders,
    (state) => state.user.users,
    (state) => state.tumorBoard.diseaseSiteFilter,
    (state) => state.tumorBoard.institutionFilter,
    (state) =>
      getRequest(state, FETCH_TUMOR_BOARD).isLoaded &&
      getRequest(state, FETCH_TUMOR_BOARD_USERS).isLoaded,
    (state) =>
      getRequest(state, FETCH_TUMOR_BOARD).isLoading ||
      getRequest(state, FETCH_TUMOR_BOARD_USERS).isLoading,
    (
      tumorBoards,
      tumorBoardsExpertsIds,
      tumorBoardsSiteLeadersIds,
      users,
      filterDiseaseSiteId,
      filterInstitutionId,
      tumorBoardRequestsLoaded,
      tumorBoardRequestsLoading
    ) => {
      const tumorBoardsExperts = {}
      const tumorBoardsSiteLeaders = {}

      if (tumorBoardsExpertsIds) {
        Object.entries(tumorBoardsExpertsIds).map(
          ([tumorBoardId, tumorBoardExpertsIds]) =>
            (tumorBoardsExperts[tumorBoardId] = tumorBoardExpertsIds.map(
              (userId) => users[userId]
            ))
        )
      }

      if (tumorBoardsSiteLeadersIds) {
        Object.entries(tumorBoardsSiteLeadersIds).map(
          ([tumorBoardId, tumorBoardSiteLeaderId]) =>
            (tumorBoardsSiteLeaders[tumorBoardId] = ((userId) => users[userId])(
              tumorBoardSiteLeaderId
            ))
        )
      }

      return {
        tumorBoardRequestsLoaded,
        tumorBoardRequestsLoading,
        tumorBoards,
        tumorBoardsExperts,
        tumorBoardsSiteLeaders,
        diseaseSiteId: filterDiseaseSiteId,
        institutionId: filterInstitutionId,
      }
    }
  )

export const TumorBoard = () => {
  const [videoHeight, setVideoHeight] = useState(360)

  const selectTumorBoard = useMemo(makeSelectTumorBoard, [])
  const {
    tumorBoardRequestsLoaded,
    tumorBoardRequestsLoading,
    tumorBoards,
    tumorBoardsExperts,
    tumorBoardsSiteLeaders,
    diseaseSiteId,
    institutionId,
  } = useSelector((state) => selectTumorBoard(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (!tumorBoardRequestsLoaded && !tumorBoardRequestsLoading) {
      dispatch(FetchTumorBoards())
      dispatch(FetchTumorBoardsUsers())
    }
  })

  const calcVideoHeight = useCallback(() => {
    if (!window?.innerWidth) {
      setVideoHeight(360)
    } else if (window.innerWidth > 670) {
      setVideoHeight(360)
    } else if (window.innerWidth > 550) {
      setVideoHeight(250)
    } else if (window.innerWidth > 350) {
      setVideoHeight(200)
    } else {
      setVideoHeight('fit-content')
    }
  }, [setVideoHeight])

  useEffect(() => {
    calcVideoHeight()
    window.addEventListener('resize', calcVideoHeight, true)

    return () => window.removeEventListener('resize', calcVideoHeight)
  }, [])

  const tumorBoardsVideoUrl = 'https://vimeo.com/703294266'

  const filters = (className) =>
    renderFilters(
      tumorBoardRequestsLoaded,
      tumorBoards,
      diseaseSiteId,
      institutionId,
      dispatch,
      className
    )

  return (
    <LandingPageContent>
      <PageTitle>Programs</PageTitle>
      <div className={css.introductionContainer}>
        <div>
          <ProximaNova.Text>NCI-Comprehensive Cancer Center</ProximaNova.Text>
          <SectionHeader
            header2="Online Tumor Board"
            headerLeftAligned
            headerUnderlined
          />

          {filters(css.displayWhenLargeScreen)}
        </div>

        <div>
          <ReactPlayer
            url={tumorBoardsVideoUrl}
            controls
            className={css.videoPlayer}
            height={videoHeight}
          />
        </div>

        <div className={css.displayWhenSmallScreen}>
          {filters(css.displayWhenSmallScreen)}
        </div>
      </div>

      {renderTumorBoards(
        tumorBoardRequestsLoaded,
        tumorBoards,
        tumorBoardsExperts,
        tumorBoardsSiteLeaders,
        diseaseSiteId,
        institutionId
      )}
    </LandingPageContent>
  )
}
