import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'

import { IMAGE_SIZE } from 'mednet-util/src/router'
import { Body1, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { UserImage } from 'pharmacy/src/user/userImage'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { createComment } from 'mednet-cns/src/reducers/answer'
import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { TextEditor } from 'pharmacy/src/input/textEditor'
import { getRequest } from 'mednet-cns/src/api/v1'

import css from './writeComment.scss'

class WriteComment extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
    this.input = React.createRef()

    this.state = {
      value: '',
      isFocused: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getInputRef = this.getInputRef.bind(this)
    this.focusEditor = this.focusEditor.bind(this)
  }

  handleChange(value) {
    this.setState({
      value,
    })
  }

  handleFocus() {
    this.setState({
      isFocused: true,
    })
  }

  handleBlur() {
    this.setState({
      isFocused: false,
    })
  }

  handleSubmit(values, actions) {
    window.tinyMCE.triggerSave()

    const { createComment } = this.props
    const commentFormData = new FormData(this.form.current)

    createComment(commentFormData, () => {
      actions.setSubmitting(false)
      this.setState({
        value: '',
        isFocused: false,
      })
    })
  }

  focusEditor() {
    if (!this.getInputRef().current) {
      return
    }

    this.getInputRef().current.editor.focus()
  }

  getInputRef() {
    if (this.props.commentInput) {
      return this.props.commentInput
    }

    return this.input
  }

  render() {
    const { userData, userIsLoaded, answerId } = this.props

    if (!userIsLoaded) {
      return null
    }

    const { isFocused, value } = this.state

    const { userId, imageVersion } = userData

    return (
      <Formik onSubmit={this.handleSubmit}>
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            className={css.container}
            ref={this.form}
          >
            <Field type="hidden" name="answer_id" value={answerId} />
            <div className={css.userComment}>
              <div>
                <UserImage
                  userId={userId}
                  imageSize={IMAGE_SIZE.SMALL}
                  imageVersion={imageVersion}
                />
              </div>
              <Body1 className={css.inputContainer}>
                {isFocused || value ? (
                  <TextEditor
                    id={`commentInput_${answerId}`}
                    onEditorChange={this.handleChange}
                    name="comment"
                    innerRef={this.getInputRef()}
                    value={value}
                    excludePlugins={['autosave']}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    onMount={this.focusEditor}
                  />
                ) : (
                  <div onClick={this.handleFocus} className={css.input}>
                    <Subtitle2>Write a comment</Subtitle2>
                  </div>
                )}
              </Body1>
            </div>
            {(isFocused || value) && (
              <Button
                className={css.commentButton}
                isDisabled={!value}
                onClick={props.handleSubmit}
                isLoading={props.isSubmitting}
              >
                Comment
              </Button>
            )}
          </form>
        )}
      </Formik>
    )
  }
}

const mapStateToProps = (state) => {
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    userIsLoaded: userRequest.isLoaded,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createComment: (formData, callback) =>
      dispatch(createComment(ownProps.answerId, formData, callback)),
  }
}

export default compose(
  withPermissions(authItems.createComment),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(WriteComment)
