import React from 'react'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { useUserMocProfile } from 'mednet-cns/src/hooks/user'

import { UserProfileUpdateNavigation } from './userProfileUpdateNavigation'

import css from './userProfileUpdateTemplate.scss'

export const UserProfileUpdateTemplate = (props) => {
  const { pageHeader, location, children } = props

  const [userData, userRequest] = useUserMocProfile()

  const { isMocAvailableByDefault, userId } = userData

  if (!userRequest.isLoaded) {
    return <StarLoader />
  }

  return (
    <div className={css.userProfileUpdatePage}>
      <div className={css.contentContainer}>
        <div className={css.header}>{pageHeader}</div>
        <div className={css.contentWithNavigation}>
          <div className={css.content}>{children}</div>
          <UserProfileUpdateNavigation
            userId={userId}
            isMocAvailableForUser={isMocAvailableByDefault}
            location={location}
          />
        </div>
      </div>
    </div>
  )
}
