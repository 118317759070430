import React from 'react'
import classNames from 'classnames'

import { Icon } from 'pharmacy/src/display/icon'

import css from './textInput.scss'

class TextInput extends React.Component {
  static defaultProps = {
    type: 'contained',
    typeStyle: 'primary',
    inputType: 'text',
    showClear: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.renderClear = this.renderClear.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (prevProps.value !== value) {
      this.setState({
        value,
      })
    }
  }

  updateValue(value, event) {
    const { onChange, onChangeHandlesEvent } = this.props

    this.setState(
      {
        value,
      },
      () => onChange && onChange(onChangeHandlesEvent ? event : value)
    )
  }

  handleChange(event) {
    const { value } = event.target

    this.updateValue(value, event)
  }

  handleClear() {
    this.updateValue('')
  }

  renderClear() {
    const { showClear, type } = this.props
    const { value } = this.state

    if (!showClear || !value) {
      return null
    }

    const clearIconClasses = classNames(css.clearIcon, css[`clearIcon_${type}`])

    return (
      <Icon
        className={clearIconClasses}
        onClick={this.handleClear}
        prefix="fas"
        icon="times-circle"
      />
    )
  }

  render() {
    const {
      className,
      icon,
      type,
      showClear,
      typeStyle,
      inputType,
      tag,
      validationTag,
      onChangeHandlesEvent,
      isPassword,
      ...restProps
    } = this.props
    const { value } = this.state

    const containerClasses = classNames(className, css.inputContainer)
    const inputClasses = classNames(
      css.input,
      css[`input_${type}`],
      css[`input_${type}_${typeStyle}`],
      {
        [css[`inputWithIcon_${type}`]]: Boolean(icon),
      }
    )

    const tagClassName = `input_${type}_${typeStyle}_tag`
    const validationTagClassName = `input_${type}_${typeStyle}_validationTag`

    return (
      <div className={containerClasses}>
        {icon && <Icon className={css.inputIcon} icon={icon} />}
        <input
          {...restProps}
          className={inputClasses}
          onChange={this.handleChange}
          value={value}
          type={isPassword ? 'password' : inputType}
          autoComplete={isPassword ? 'new-password' : 'on'}
        />

        {tag && !value && !validationTag && (
          <div className={css[tagClassName]}>{tag}</div>
        )}

        {validationTag && (
          <div className={css[validationTagClassName]}>{validationTag}</div>
        )}

        {this.renderClear()}
      </div>
    )
  }
}

export default TextInput
