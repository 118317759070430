import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeaders } from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
  requestAction,
} from '../cns-util/reducer'

import {
  FETCH_QUESTION_ANSWERS,
  FETCH_CAMPAIGN_QUESTION_ANSWERS,
  CREATE_ANSWER,
  CREATE_ANSWER_AS_USER,
} from './question'

export const TOGGLE_AGREE_ANSWER = 'answer/TOGGLE_AGREE_ANSWER'
export const TOGGLE_HELPFUL_ANSWER = 'answer/TOGGLE_HELPFUL_ANSWER'
export const SHARE_ANSWER = 'answer/SHARE_ANSWER'
export const CREATE_CONFLICT = 'answer/CREATE_CONFLICT'
export const FETCH_ANSWER = 'answer/FETCH_ANSWER'
export const DELETE_ANSWER = 'answer/DELETE_ANSWER'
export const MOVE_ANSWER = 'answer/MOVE_ANSWER'

// TODO: move these to their own reducer
export const CREATE_COMMENT = 'answer/CREATE_COMMENT'
export const DELETE_COMMENT = 'answer/DELETE_COMMENT'
export const MOVE_COMMENT = 'answer/MOVE_COMMENT'

export function toggleAgreeAnswer(answerId, itemIndex, prevAgreedState) {
  return {
    type: TOGGLE_AGREE_ANSWER,
    requestId: answerId,
    answerId,
    itemIndex,
    prevAgreedState,
  }
}

export function toggleHelpfulAnswer(answerId, itemIndex, prevHelpfulState) {
  return {
    type: TOGGLE_HELPFUL_ANSWER,
    requestId: answerId,
    answerId,
    itemIndex,
    prevHelpfulState,
  }
}

export function shareAnswer(answerId, form) {
  return {
    type: SHARE_ANSWER,
    requestId: answerId,
    answerId,
    form,
  }
}

export function createConflict(answerId, form) {
  return {
    type: CREATE_CONFLICT,
    requestId: answerId,
    answerId,
    form,
  }
}

export function fetchAnswer(answerId, callback) {
  return {
    type: FETCH_ANSWER,
    requestId: answerId,
    answerId,
    callback,
  }
}

export function deleteAnswer(questionId, answerId) {
  return {
    type: DELETE_ANSWER,
    requestId: answerId,
    answerId,
    questionId,
  }
}

export function moveAnswer(questionId, answerId, formData) {
  return {
    type: MOVE_ANSWER,
    requestId: answerId,
    questionId,
    answerId,
    formData,
  }
}

export function createComment(answerId, formData, callback) {
  return {
    type: CREATE_COMMENT,
    requestId: answerId,
    answerId,
    formData,
    callback,
  }
}

export function deleteComment(answerId, commentId) {
  return {
    type: DELETE_COMMENT,
    requestId: commentId,
    answerId,
    commentId,
  }
}

export function moveComment(answerId, commentId) {
  return {
    type: MOVE_COMMENT,
    requestId: commentId,
    answerId,
    commentId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, TOGGLE_AGREE_ANSWER, (action) =>
    makeURL(`answer/opinionJSON/${action.answerId}`, {
      itemIndex: action.itemIndex,
      prevAgreedState: action.prevAgreedState,
    })
  )

  yield makeFetchEffect(takeLatest, TOGGLE_HELPFUL_ANSWER, (action) =>
    makeURL(`answer/voteJSON/${action.answerId}`, {
      itemIndex: action.itemIndex,
      prevHelpfulState: action.prevHelpfulState,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    SHARE_ANSWER,
    (action) => makeURL(`answer/shareJSON/${action.answerId}`),
    (action) => makePOSTHeaders(new FormData(action.form))
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_CONFLICT,
    (action) => makeURL(`answer/createConflictJSON/${action.answerId}`),
    (action) => makePOSTHeaders(new FormData(action.form))
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_COMMENT,
    makeURL('comment/createJSON'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(takeLatest, FETCH_ANSWER, (action) =>
    makeURL(`answer/getJSON/${action.answerId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    DELETE_COMMENT,
    (action) => makeURL(`comment/delete/${action.commentId}`, { ajax: 1 }),
    makePOSTHeaders()
  )

  yield makeFetchEffect(
    takeLatest,
    DELETE_ANSWER,
    (action) => makeURL(`answer/delete/${action.answerId}`, { ajax: 1 }),
    makePOSTHeaders()
  )

  yield makeFetchEffect(
    takeLatest,
    MOVE_COMMENT,
    (action) => makeURL(`comment/move/${action.commentId}`),
    makePOSTHeaders()
  )

  yield makeFetchEffect(
    takeLatest,
    MOVE_ANSWER,
    (action) => makeURL(`answer/move/${action.answerId}`),
    (action) => makePOSTHeaders(action.formData)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  answers: {},
}

const extractUser = (obj) => {
  const { user, ...rest } = obj
  return {
    ...rest,
    userId: user.userId,
  }
}

const updateAnswerObject = (existingObject, newObject) => {
  const { user, votes, opinions, comments, ...rest } = newObject

  const updatedObject = {
    ...rest,
    userId: user.userId,
    votes: votes.map(extractUser),
    opinions: opinions.map(extractUser),
    comments: comments.map(extractUser),
  }

  return _.assign(existingObject || {}, updatedObject)
}

export function reducer(state = initialState, action, fullState) {
  switch (action.type) {
    case receiveAction(FETCH_CAMPAIGN_QUESTION_ANSWERS):
    case receiveAction(FETCH_QUESTION_ANSWERS): {
      return receiveReducer(state, action, () => ({
        answers: {
          ...state.answers,
          ..._.keyBy(
            _.map(action.response.answers, (item) =>
              updateAnswerObject(state.answers[item.answerId], item)
            ),
            'answerId'
          ),
        },
      }))
    }

    case receiveAction(FETCH_ANSWER): {
      return receiveReducer(state, action, () => ({
        answers: {
          ...state.answers,
          [action.answerId]: updateAnswerObject(
            state.answers[action.answerId],
            action.response.answer
          ),
        },
      }))
    }

    case requestAction(TOGGLE_AGREE_ANSWER): {
      const answerObject = state.answers[action.answerId]

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.answerId]: {
            ...answerObject,
            isAgreed: !answerObject.isAgreed,
            opinions: !answerObject.isAgreed
              ? [
                  ...answerObject.opinions,
                  { userId: fullState.user.data.userId },
                ]
              : _.filter(
                  answerObject.opinions,
                  (opinion) => opinion.userId !== fullState.user.data.userId
                ),
          },
        },
      }
    }

    case requestAction(MOVE_COMMENT):
    case requestAction(DELETE_COMMENT): {
      const answerObject = state.answers[action.answerId]

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.answerId]: {
            ...answerObject,
            comments: _.filter(
              answerObject.comments,
              (comment) => comment.commentId !== action.commentId
            ),
          },
        },
      }
    }

    case requestAction(TOGGLE_HELPFUL_ANSWER): {
      const answerObject = state.answers[action.answerId]

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.answerId]: {
            ...answerObject,
            isHelpful: !answerObject.isHelpful,
            votes: !answerObject.isHelpful
              ? [...answerObject.votes, { userId: fullState.user.data.userId }]
              : _.filter(
                  answerObject.votes,
                  (vote) => vote.userId !== fullState.user.data.userId
                ),
          },
        },
      }
    }

    case receiveAction(CREATE_ANSWER_AS_USER):
    case receiveAction(CREATE_ANSWER): {
      return receiveReducer(state, action, () => {
        if (action.response.success === false) {
          return {}
        }

        return {
          answers: {
            ...state.answers,
            [action.response.answer.answerId]: updateAnswerObject(
              {},
              action.response.answer
            ),
          },
        }
      })
    }

    case receiveAction(CREATE_CONFLICT): {
      return receiveReducer(state, action, () => ({
        answers: {
          ...state.answers,
          [action.answerId]: {
            ...state.answers[action.answerId],
            conflict: {
              conflictText: action.form.conflict_text.value,
            },
          },
        },
      }))
    }

    case receiveAction(CREATE_COMMENT): {
      const answerObject = state.answers[action.answerId]

      return receiveReducer(state, action, () => {
        if (action.response.success === false) {
          return {}
        }

        return {
          answers: {
            ...state.answers,
            [action.answerId]: {
              ...answerObject,
              comments: [
                ...answerObject.comments,
                extractUser(action.response.comment),
              ],
            },
          },
        }
      })
    }

    case receiveAction(MOVE_ANSWER): {
      const movedAnswerId = action.formData.get('answerId')
      const answerObject = state.answers[movedAnswerId]

      return {
        ...state,
        answers: {
          ..._.omit(state.answers, action.answerId),
          [movedAnswerId]: {
            ...answerObject,
            comments: [...answerObject.comments, action.response.comment],
          },
        },
      }
    }

    case requestAction(DELETE_ANSWER): {
      return {
        ...state,
        answers: _.omit(state.answers, action.answerId),
      }
    }

    default: {
      return state
    }
  }
}
