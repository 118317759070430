import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Page } from 'pharmacy/src/display/page'
import { Header1 } from 'pharmacy/src/typography'
import { CenteredContent } from 'pharmacy/src/display/content'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  FETCH_EXPERTS,
  FETCH_SPONSORSHIP,
  fetchExperts,
  fetchSponsorship,
  sendEmail,
} from 'mednet-cns/src/reducers/sponsorship'
import { getRequest } from 'mednet-cns/src/api/v1'
import { getPathnameFromRoute, parseQueryString } from 'mednet-util/src/router'
import { getUserName } from 'mednet-util/src/string'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import SendEmailForm from './forms/sendEmailForm'

import css from './sendEmail.scss'

class SendEmailPage extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchExperts()
    this.props.fetchSponsorship()
  }

  handleSubmit = (values, actions) => {
    const { sendEmail, history, sponsorship } = this.props
    const { sponsorshipId } = sponsorship

    window.tinyMCE.triggerSave()

    const formData = new FormData(this.form.current)
    sendEmail(formData, () => {
      actions.setSubmitting(false)

      history.push(
        getPathnameFromRoute(ROUTES[PAGES.SPONSORSHIP_MANAGE_EMAILS], {
          sponsorshipId,
        })
      )
    })
  }

  render() {
    const { sponsorship, sponsorshipIsLoaded, expertsIsLoaded, userId } =
      this.props

    if (!sponsorshipIsLoaded || !expertsIsLoaded) {
      return <StarLoader />
    }

    const { description, sponsorshipId, experts } = sponsorship
    const expert = _.find(experts, { userId: Number(userId) })
    const { email } = expert

    return (
      <Page>
        <CenteredContent>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
              {
                ...ROUTES[PAGES.VIEW_SPONSORSHIP],
                routeParams: { sponsorshipId },
                label: description,
              },
              {
                ...ROUTES[PAGES.SPONSORSHIP_MANAGE_EMAILS],
                routeParams: { sponsorshipId },
              },
              {
                label: 'Send Email',
              },
            ]}
          />
          <div className={css.header}>
            <Header1>Send Email to {getUserName(expert)}</Header1>
          </div>
          <Formik onSubmit={this.handleSubmit} initialValues={email}>
            {(props) => (
              <SendEmailForm {...props} {...this.props} formRef={this.form} />
            )}
          </Formik>
        </CenteredContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const sponsorshipRequest = getRequest(state, FETCH_SPONSORSHIP, sponsorshipId)
  const expertsRequest = getRequest(state, FETCH_EXPERTS, sponsorshipId)

  return {
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorshipIsLoaded: sponsorshipRequest.isLoaded === true,
    expertsIsLoaded: expertsRequest.isLoaded === true,
    ...parseQueryString(ownProps.location.search),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const { userId } = parseQueryString(ownProps.location.search)

  return {
    fetchSponsorship: () => dispatch(fetchSponsorship(sponsorshipId)),
    fetchExperts: () => dispatch(fetchExperts(sponsorshipId)),
    sendEmail: (formData, callback) =>
      dispatch(sendEmail(sponsorshipId, userId, formData, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailPage)
