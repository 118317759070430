import { useEffect } from 'react'

const useNotificationIdScrubbing = (history) => {
  const { replace } = history

  useEffect(() => {
    try {
      if (typeof window === 'undefined') return

      var url = new URL(window.location.href)
      var paramsScrubbed = false

      var notificationId = url.searchParams.get('notification_id')
      if (isNaN(notificationId)) {
        url.searchParams.delete('notification_id')
        paramsScrubbed = true
      }
      var nId = url.searchParams.get('nid')
      if (isNaN(nId)) {
        url.searchParams.delete('nid')
        paramsScrubbed = true
      }

      if (paramsScrubbed) {
        replace(url.pathname + url.search + url.hash)
      }
    } catch (e) {
      console.error(e)
    }
  }, [replace])
}

export default useNotificationIdScrubbing
