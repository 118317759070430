import React, { useMemo } from 'react'

import { DateRangePicker } from 'pharmacy/src/input/datePicker'
import { SimpleSelect } from 'pharmacy/src/input/select'

import {
  CME_CERTIFICATE_TYPE,
  CME_CERTIFICATE_TYPE_LABEL,
  CME_REDEMPTION_TYPE,
  CME_REDEMPTION_TYPE_LABEL,
} from 'mednet-util/src/constants/cme'

import css from './cmeFilters.scss'

export const CmeDateRangePicker = (props) => {
  const { minDate, defaultFrom, defaultTo, handleChange } = props

  return (
    <div className={css.datesFiltersContainer}>
      <div className={css.dateLabel}>From:</div>
      <DateRangePicker
        fromProps={{
          defaultValue: defaultFrom,
          className: css.datePicker,
        }}
        toProps={{
          defaultValue: defaultTo || new Date(),
          className: css.datePicker,
        }}
        onChange={handleChange}
        fromDisabledDays={(_from, to) => ({
          before: minDate,
          after: to,
        })}
        toDisabledDays={(from) => ({ before: from, after: new Date() })}
        fromMonth={minDate}
      />
    </div>
  )
}

export const CmeYearFilter = (props) => {
  const { minYear, handleFilterChange } = props
  const currentYear = new Date().getFullYear()

  const yearsOptions = useMemo(() => {
    return Array.from({ length: currentYear - minYear + 1 }, (_, i) => ({
      label: currentYear - i,
      value: currentYear - i,
    }))
  }, [minYear, currentYear])

  return (
    <SimpleSelect
      options={yearsOptions}
      placeholder={currentYear}
      className={css.select}
      onChange={handleFilterChange}
    />
  )
}

export const CmeCertificateTypeFilter = (props) => {
  const {
    handleFilterChange,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
  } = props

  if (!isAbimMocEnabledForUser && !isAbpMocEnabledForUser) {
    return null
  }

  const typesOptions = useMemo(
    () => [
      { label: 'All', value: 'any' },
      ...[
        CME_CERTIFICATE_TYPE.CME,
        isAbimMocEnabledForUser ? CME_CERTIFICATE_TYPE.ABIM_MOC : false,
        isAbpMocEnabledForUser ? CME_CERTIFICATE_TYPE.ABP_MOC : false,
      ]
        .filter((type) => type)
        .map((type) => ({
          label: CME_CERTIFICATE_TYPE_LABEL[type],
          value: type,
        })),
    ],
    [
      isAbimMocEnabledForUser,
      isAbpMocEnabledForUser,
      CME_CERTIFICATE_TYPE,
      CME_CERTIFICATE_TYPE_LABEL,
    ]
  )

  return (
    <SimpleSelect
      options={typesOptions}
      placeholder="Type: All"
      className={css.select}
      onChange={handleFilterChange}
    />
  )
}

export const CmeRedemptionTypeFilter = (props) => {
  const {
    handleFilterChange,
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
  } = props

  if (!isAbimMocEnabledForUser && !isAbpMocEnabledForUser) {
    return null
  }

  const typesOptions = useMemo(() => {
    const options = [
      {
        label: CME_REDEMPTION_TYPE_LABEL[CME_REDEMPTION_TYPE.CME],
        value: CME_REDEMPTION_TYPE.CME,
      },
    ]

    if (isAbimMocEnabledForUser && isAbpMocEnabledForUser) {
      options.push({
        label: 'MOC ABIM & ABP',
        value: CME_REDEMPTION_TYPE.MOC,
      })
    } else if (isAbimMocEnabledForUser) {
      options.push({
        label: 'MOC ABIM',
        value: CME_REDEMPTION_TYPE.MOC,
      })
    } else if (isAbpMocEnabledForUser) {
      options.push({
        label: 'MOC ABP',
        value: CME_REDEMPTION_TYPE.MOC,
      })
    }

    return options
  }, [
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    CME_REDEMPTION_TYPE,
    CME_REDEMPTION_TYPE_LABEL,
  ])

  return (
    <SimpleSelect
      options={typesOptions}
      placeholder={CME_REDEMPTION_TYPE_LABEL[CME_REDEMPTION_TYPE.CME]}
      className={css.select}
      onChange={handleFilterChange}
    />
  )
}
