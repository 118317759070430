import React from 'react'
import { connect } from 'react-redux'

import { Link1, Subtitle1, Subtitle3 } from 'pharmacy/src/typography'
import { UserImage } from 'pharmacy/src/user/userImage'
import { Link } from 'pharmacy/src/navigation/link'
import { Button } from 'pharmacy/src/input/button'
import { formatName } from 'mednet-util/src/string'
import { inviteUser } from 'mednet-cns/src/reducers/user'

import css from './userInviteCard.scss'

class UserInviteCard extends React.Component {
  renderLoading() {
    return (
      <div className={css.container}>
        <UserImage isLoading className={css.image} />
        <div className={css.loadingName} />
        <div className={css.loadingInvite} />
      </div>
    )
  }

  render() {
    const { peer, inviteUser } = this.props

    if (!peer) {
      return this.renderLoading()
    }

    const { userId, imageVersion, firstName, middleName, lastName, isInvited } =
      peer

    return (
      <div className={css.container}>
        <UserImage
          userId={userId}
          imageVersion={imageVersion}
          className={css.image}
        />
        <div>
          <Link
            external
            className={css.nameLink}
            pathname={`/user/user/view/id/${userId}`}
          >
            <Subtitle1>{formatName(firstName, middleName, lastName)}</Subtitle1>
          </Link>
          {isInvited ? (
            <Subtitle3 className={css.invitedText}>Invited</Subtitle3>
          ) : (
            <Link1>
              <Button
                className={css.inviteLink}
                icon="user-plus"
                type="plain"
                onClick={inviteUser}
              >
                Invite
              </Button>
            </Link1>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    peer: state.user.users[ownProps.userId],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    inviteUser: () => dispatch(inviteUser(ownProps.userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInviteCard)
