import React from 'react'
import _ from 'lodash'

import { programTypesConfig } from 'pharmacy/src/program/configs'
import ProgramListItem from 'pharmacy/src/program/programListItem'
import { Header1 } from 'pharmacy/src/typography'

import classes from './programsList.scss'

export default function ProgramsList() {
  return (
    <div>
      <div className={classes.header}>
        <Header1>Programs</Header1>
      </div>
      <div>
        {_.keys(programTypesConfig).map((programType) => (
          <div key={programType} className={classes.listItem}>
            <ProgramListItem
              programType={programType}
              trackingProps={{ location: 'home_page_programs_list' }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
