import React from 'react'
import moment from 'moment'

import {
  Subtitle1,
  Subtitle2,
  Subtitle3,
  Subtitle4,
} from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { jobType } from 'mednet-util/src/constants/job'
import { makeURL } from 'mednet-util/src/router'

import css from './userJob.scss'

class UserJob extends React.Component {
  getMonthName = (month) => {
    if (!month) {
      return ''
    }

    return moment()
      .month(month - 1)
      .format('MMM')
  }

  getDateString = (month, year) => {
    if (!year) {
      return ''
    }

    if (!month) {
      return year
    }

    return `${this.getMonthName(month)} ${year}`
  }

  renderDates = (job) => {
    const { active, endMonth, endYear, startMonth, startYear } = job

    const startString = this.getDateString(startMonth, startYear)
    const endString = active ? 'Present' : this.getDateString(endMonth, endYear)

    if (startString && endString) {
      return `${startString} - ${endString}`
    }

    if (startString) {
      return startString
    }

    if (endString) {
      return endString
    }

    return ''
  }

  renderButtons = () => {
    const { showEdit, job } = this.props
    const { type, jobId, prime, active } = job

    if (!showEdit) {
      return null
    }

    const editUrl =
      type === jobType.JOB ? `/job/updateWork/${jobId}` : `/job/update/${jobId}`

    return (
      <Subtitle4 className={css.buttonsContainer}>
        {active && !prime && (
          <Button
            className={css.editButton}
            external
            icon="star"
            pathname={makeURL(`/job/prime/${jobId}`, {
              returnUrl: '/user/user/view?tab=Work-History',
            })}
            type="secondaryText"
          >
            Make Primary
          </Button>
        )}
        <Button
          className={css.editButton}
          external
          icon="edit"
          pathname={editUrl}
          type="secondaryText"
        >
          Edit
        </Button>
        <Button
          external
          icon="trash"
          pathname={makeURL(`/job/delete/${jobId}`, {
            returnUrl: '/user/user/view?tab=Work-History',
          })}
          type="secondaryText"
        >
          Delete
        </Button>
      </Subtitle4>
    )
  }

  render() {
    const { job } = this.props
    const { description, name, position } = job

    const dates = this.renderDates(job)

    return (
      <div>
        {position && <Subtitle1 className={css.position}>{position}</Subtitle1>}
        {name && <Subtitle2>{name}</Subtitle2>}
        {dates && (
          <Subtitle3 className={css.subText}>{this.renderDates(job)}</Subtitle3>
        )}
        {description && (
          <Subtitle3 className={css.subText}>{description}</Subtitle3>
        )}
        {this.renderButtons()}
      </div>
    )
  }
}

export default UserJob
