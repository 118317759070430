import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { Header1, Body1 } from 'pharmacy/src/typography'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { parseQueryString } from 'mednet-util/src/router'
import { QuestionCard } from 'pharmacy/src/question/questionCard'
import { Page } from 'pharmacy/src/display/page'
import { QuestionFeed } from 'pharmacy/src/display/feed'
import { fetchTopic, FETCH_TOPIC } from 'mednet-cns/src/reducers/topic'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { mixpanel } from 'mednet-util/src/tracking'

import { ErrorPage } from 'pages/error'

import css from './topic.scss'

class TopicPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.topicIsLoaded) {
      props.fetchTopic()
    }
  }

  componentDidMount() {
    const { topicId } = this.props

    mixpanel.track('viewed_topic_page', {
      topicId,
    })
  }

  render() {
    const {
      topicData,
      topicIsLoaded,
      specialtyId,
      topicIsError,
      topicErrorStatus,
    } = this.props

    if (!(topicData || topicIsLoaded)) {
      return <StarLoader />
    }

    if (topicIsError) {
      return <ErrorPage errorStatus={topicErrorStatus} />
    }

    const { topic, topicId, description } = topicData

    return (
      <Page>
        <Helmet>
          <title>{topic}</title>
        </Helmet>
        <div className={css.content}>
          <div className={css.feed}>
            <Header1 className={css.header}>
              Questions tagged with {topic}
            </Header1>
            {description && (
              <Body1 className={css.descriptionContainer}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Body1>
            )}
            <QuestionFeed
              feedName="topic"
              feedKey={`${topicId}_${specialtyId}`}
              feedParams={{ specialtyId, topicId }}
              itemComponent={QuestionCard}
            />
          </div>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { topicId } = ownProps.match.params
  const { specialtyId } = parseQueryString(ownProps.location.search)
  const topicRequest =
    _.get(state.request.requests, makeRequestName(FETCH_TOPIC, topicId)) || {}

  return {
    topicData: state.topic.topics[topicId],
    topicIsLoaded: topicRequest.isLoaded,
    topicIsError: topicRequest.isError,
    topicErrorStatus: topicRequest.errorStatus,
    topicId,
    specialtyId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { topicId } = ownProps.match.params

  return {
    fetchTopic: () => dispatch(fetchTopic(topicId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicPage)
