import _ from 'lodash'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { getMixpanelReferrerProperties } from 'mednet-util/src/tracking'

export const defaultInit = {
  credentials: 'include',
}

export const makePOSTHeaders = (formData) => ({
  ...defaultInit,
  method: 'POST',
  body: formData,
})

export const createFormData = (formData, key, data) => {
  if (!(data instanceof File) && (_.isArray(data) || _.isObject(data))) {
    _.each(data, (val, idx) =>
      createFormData(formData, `${key}[${idx}]`, data[idx])
    )
  } else {
    formData.append(key, data)
  }
}

export const makePOSTHeadersFromParams = (params) => {
  const formData = new FormData()

  _.each(params, (value, key) => {
    createFormData(formData, key, value)
  })

  return makePOSTHeaders(formData)
}

export const getRequest = (state, type, requestId) => {
  return _.get(state.request.requests, makeRequestName(type, requestId), {})
}

export let mednetAPI = () => {}

export const createAPI = ({
  apiURL = '',
  pathPrefix = '',
  init = defaultInit,
  onLoginRedirect,
  staticHeaders = {},
}) => {
  mednetAPI = (path, fetchInit = init) => {
    const fullPath = `${apiURL}/${pathPrefix}${path}`

    // check for document to check that this is running in web not react-native
    const {
      session_referrer_label,
      session_referrer_search_query,
      direct_referrer_label,
      direct_referrer_search_query,
    } = typeof document !== 'undefined' ? getMixpanelReferrerProperties() : {}

    const mixpanelHeaders = Object.fromEntries(
      Object.entries({
        'x-mp-session-referrer-label': session_referrer_label,
        'x-mp-session-referrer-search-query': session_referrer_search_query,
        'x-mp-direct-referrer-label': direct_referrer_label,
        'x-mp-direct-referrer-search-query': direct_referrer_search_query,
      }).filter(([_key, value]) => value)
    )

    const requestInit = {
      ...fetchInit,
      headers: {
        ...staticHeaders,
        ...fetchInit?.headers,
        ...mixpanelHeaders,
      },
    }

    return fetch(fullPath, requestInit)
      .then((response) => {
        if (
          onLoginRedirect &&
          response.redirected &&
          _.endsWith(response.url, '/user/login')
        ) {
          onLoginRedirect(response)
          return {}
        }

        if (onLoginRedirect && response.status === 401) {
          onLoginRedirect(response)
          return {
            isError: true,
            errorStatus: response.status,
            response,
          }
        }

        if (200 <= response.status && response.status < 300) {
          return { response }
        }

        return {
          isError: true,
          errorStatus: response.status,
          response,
        }
      })
      .catch((error) => {
        return {
          isError: true,
          error,
        }
      })
  }

  // TODO: this should probably be handled in the answer's RawBody
  if (typeof window !== 'undefined') {
    window.citedClick = _.noop
  }
}
