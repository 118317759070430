import React, { useCallback, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { Field } from 'formik'
import isEmpty from 'lodash/isEmpty'
import * as Yup from 'yup'

import { Icon } from 'pharmacy/src/display/icon'
import { ButtonUpload } from 'pharmacy/src/input/button'
import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'

import { publicationAccess } from 'mednet-util/src/constants/publication'

import { TextInput } from 'pharmacy/src/input/textInput'

import { endsWith, includes, keys, some } from 'lodash'

import css from './paperFullDownloadForm.scss'

const SUPPORTED_FILE_FORMATS = {
  '.pdf': 'application/pdf',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx':
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

const fileFormatValidationArgs = [
  'fileFormat',
  `File format is not supported, must be one of: ${keys(
    SUPPORTED_FILE_FORMATS
  ).join(', ')}`,
  (value) => {
    if (!value) {
      return true
    }

    // IE11
    if (!value.type) {
      return some(Object.keys(SUPPORTED_FILE_FORMATS), (extension) =>
        endsWith(value.name, extension)
      )
    }

    return includes(Object.values(SUPPORTED_FILE_FORMATS), value.type)
  },
]

export const fullDownloadValidationSchema = {
  attachment: Yup.mixed()
    .test(...fileFormatValidationArgs)
    .when(['fullDownloadAction', 'pubmedId'], {
      is: (fullDownloadAction, pubmedId) =>
        !pubmedId &&
        ![
          'addDirectLink',
          'replaceWithDirectLink',
          'updateAccess',
          'noChange',
        ].includes(fullDownloadAction),
      then: Yup.mixed()
        .required(
          'A file should be uploaded when there is no Pubmed ID and no direct download link'
        )
        .test(...fileFormatValidationArgs),
    })
    .when(['fullDownloadAction'], {
      is: (fullDownloadAction) =>
        fullDownloadAction === 'replaceWithAttachment',
      then: Yup.mixed()
        .required('A replacement attachment was not uploaded')
        .test(...fileFormatValidationArgs),
    }),
  publicURL: Yup.string()
    .url('Invalid URL for the direct download link')
    .when(['fullDownloadAction', 'pubmedId'], {
      is: (fullDownloadAction, pubmedId) =>
        !pubmedId &&
        ![
          'addAttachment',
          'replaceWithAttachment',
          'updateAccess',
          'noChange',
        ].includes(fullDownloadAction),
      then: Yup.string()
        .url('Invalid URL for the direct download link')
        .required(
          'A direct download URL should be provided when there is no Pubmed ID and no attached file'
        ),
    })
    .when(['fullDownloadAction'], {
      is: (fullDownloadAction) =>
        fullDownloadAction === 'replaceWithDirectLink',
      then: Yup.string()
        .url('Invalid URL for the direct download link')
        .required("A replacement URL wasn't provided"),
    }),
}

export const PaperFullDownloadForm = ({
  publication,
  values,
  setFieldValue,
}) => {
  const handleFileUpload = useCallback(
    (file) => {
      setFieldValue('attachment', file)
    },
    [setFieldValue]
  )

  const hasFullDownload =
    !isEmpty(publication.fileLocation) ||
    (!isEmpty(publication.publicURL) && publication.isPublicUrlFile)

  useEffect(() => {
    if (values.fullDownloadAction === 'delete' && values.noPubmedId) {
      if (hasFullDownload) {
        setFieldValue('fullDownloadAction', 'noChange')
      } else {
        setFieldValue('fullDownloadAction', 'addAttachment')
      }
    }
  }, [
    values.fullDownloadAction,
    values.noPubmedId,
    hasFullDownload,
    setFieldValue,
  ])

  useEffect(() => {
    if (values.fullDownloadAction === 'updateAccess') {
      setFieldValue('openAccess', !publication.openAccess)
    }
  }, [values.fullDownloadAction, publication.openAccess, setFieldValue])

  useEffect(() => {
    setFieldValue('attachment', null)
    setFieldValue('publicURL', '')
  }, [values.fullDownloadAction, setFieldValue])

  const actions = useMemo(() => {
    const hasInternalDownloadFile = !isEmpty(publication.fileLocation)

    const hasExternalDirectDownloadLink =
      isEmpty(publication.fileLocation) &&
      !isEmpty(publication.publicURL) &&
      publication.isPublicUrlFile

    const isOpenAccess =
      hasExternalDirectDownloadLink ||
      publication.access === publicationAccess.OPEN_S3_FILE ||
      publication.openAccess

    if (hasInternalDownloadFile || hasExternalDirectDownloadLink) {
      const existingDownloadDescription = hasInternalDownloadFile
        ? 'attached file'
        : 'external direct download link'

      return [
        {
          value: 'noChange',
          description: `Use the ${existingDownloadDescription} as it is.`,
        },
        {
          value: 'updateAccess',
          description: `Use the ${existingDownloadDescription}, but change the access to ${
            isOpenAccess ? 'closed.' : 'opened.'
          }`,
          disabled:
            hasExternalDirectDownloadLink &&
            ' - invalid as an external public link is used for this paper',
        },
        {
          value: 'delete',
          description: `Delete the ${existingDownloadDescription} and use the pubmed link instead.`,
          disabled:
            !publication.pubmedId &&
            values.noPubmedId !== false && // if the field does not exist or it is true, enable delete
            ' - invalid as there is no pubmed Id for this paper',
        },
        {
          value: 'replaceWithAttachment',
          description: `Replace the ${existingDownloadDescription} with a new file.`,
        },
        {
          value: 'replaceWithDirectLink',
          description: `Replace the ${existingDownloadDescription} with a new external direct download link.`,
        },
      ]
    }

    return [
      {
        value: 'noFullDownload',
        description: 'Full download is not available for this publication.',
      },
      {
        value: 'addAttachment',
        description: 'Attach a download file to this publication.',
      },
      {
        value: 'addDirectLink',
        description: 'Add external direct download link to this publication.',
      },
    ]
  }, [publication, values.noPubmedId])

  return (
    <>
      {hasFullDownload && (
        <div>
          <Subtitle2 className={css.titleText}>
            <div>
              <Link
                external
                pathname={`/publication/moderatorDownload/${publication.publicationId}`}
                target="_blank"
              >
                <Icon
                  icon={['fas', 'file-download']}
                  className={css.fileDownloadIcon}
                />
              </Link>
            </div>
            <div>
              This paper has an attachment with
              {publication.access === publicationAccess.OPEN_S3_FILE ||
              publication.openAccess
                ? ' open access (will be available to download when the sponsorship ends)'
                : ' closed access (will not be available to download when the sponsorship ends)'}
              . Please decide what to do with it:
            </div>
          </Subtitle2>
        </div>
      )}
      {!isEmpty(publication) && !hasFullDownload && (
        <Subtitle2 className={css.titleText}>
          This paper has no full download available. Please decide what to do
          with it:
        </Subtitle2>
      )}
      {isEmpty(publication) && (
        <Subtitle2 className={css.titleText}>
          Please decide how full download will be available for this paper:
        </Subtitle2>
      )}
      {actions.map((action) => {
        if (!action) {
          return null
        }
        return (
          <div
            className={classNames(css.fullDownloadAction, {
              [css.disabledAttachmentAction]: action.disabled,
            })}
            key={`fullDownloadAction_${action.value}`}
          >
            <div>
              <Field
                type="radio"
                name="fullDownloadAction"
                id={`fullDownloadAction_${action.value}`}
                value={action.value}
                onClick={() =>
                  setFieldValue('fullDownloadAction', action.value)
                }
                disabled={action.disabled}
                checked={values.fullDownloadAction === action.value}
              />
            </div>

            <Subtitle2>
              <label htmlFor={`fullDownloadAction_${action.value}`}>
                {action.description} {action.disabled}
              </label>
            </Subtitle2>
          </div>
        )
      })}

      {(values.fullDownloadAction === 'addAttachment' ||
        values.fullDownloadAction === 'replaceWithAttachment') && (
        <div className={css.uploadButtonContainer}>
          <ButtonUpload
            inputProps={{
              name: 'file',
              onChange: handleFileUpload,
            }}
            buttonProps={{
              type: 'secondary',
              className: css.uploadButton,
            }}
          />
          {values.attachment && (
            <div className={css.openAccessInput}>
              <label>
                <Field
                  type="checkbox"
                  name="openAccess"
                  checked={values.openAccess}
                />
                Keep this file available to download when the sponsorship ends
              </label>
            </div>
          )}
        </div>
      )}
      {(values.fullDownloadAction === 'addDirectLink' ||
        values.fullDownloadAction === 'replaceWithDirectLink') && (
        <div className={css.directDownloadLinkInput}>
          <Subtitle2>External Direct Download Link</Subtitle2>
          <Field>
            {({ field }) => (
              <TextInput
                {...field}
                name="publicURL"
                showClear={false}
                onChangeHandlesEvent
                value={values.publicURL}
              />
            )}
          </Field>
        </div>
      )}
    </>
  )
}
