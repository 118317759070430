import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'
import { makeFetchEffect } from 'mednet-cns/src/cns-util/reducer'

export const SEND_CONTACT_MESSAGE = 'contact/SEND_CONTACT_MESSAGE'
export const SEND_PARTNERSHIP_MESSAGE = 'contact/SEND_PARTNERSHIP_MESSAGE'

export function sendContactMessage(formData, callback) {
  return {
    type: SEND_CONTACT_MESSAGE,
    formData,
    callback,
  }
}

export function sendPartnershipMessage(formData, callback) {
  return {
    type: SEND_PARTNERSHIP_MESSAGE,
    formData,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    SEND_CONTACT_MESSAGE,
    makeURL('Contact/CreateContactMessageJSON'),
    (action) => makePOSTHeadersFromParams(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    SEND_PARTNERSHIP_MESSAGE,
    makeURL('Contact/CreatePartnerMessageJSON'),
    (action) => makePOSTHeadersFromParams(action.formData)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  contact: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
