import React from 'react'

import { CAMPAIGN_REGISTER_MODAL } from 'mednet-util/src/constants/modal'

import { Modal } from 'pharmacy/src/display/modal'
import { Body1, Header1, Header3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { useActiveUsersCount } from 'mednet-cns/src/hooks/user'

import * as css from './registerModal.scss'

const RegistrationView = ({ campaignId, history }) => {
  const trackingParamString = new URLSearchParams({
    src: 'public_campaign',
    src_path: history?.location?.pathname,
    src_item: 'registration_modal',
  }).toString()

  const [activeUsersCount] = useActiveUsersCount()

  return (
    <div className={css.modal}>
      <div className={css.section}>
        <Header1>To continue please login or register</Header1>
        <Body1 className={css.buttons}>
          <a
            href={`/user/registration?utm_campaign=${campaignId}&${trackingParamString}`}
          >
            <Button type="primary">Register</Button>
          </a>{' '}
          Or{' '}
          <a href="/user/login">
            <Button type="secondary">Sign in</Button>
          </a>
        </Body1>
      </div>
      <hr />
      <div className={css.section}>
        <Header3>Why do I have to sign in?</Header3>
        <Body1 className={css.expl}>
          theMednet is a private community of{' '}
          {activeUsersCount?.toLocaleString() ?? '33,000'} academic and
          community physicians. To maintain open and collegial dialogue, we
          verify that all members are practicing US based physicians.
        </Body1>
      </div>
    </div>
  )
}

const screens = {
  [CAMPAIGN_REGISTER_MODAL.screens.view]: RegistrationView,
}

export default function RegistrationModal(props) {
  return (
    <Modal
      modalId={CAMPAIGN_REGISTER_MODAL.modalId}
      defaultScreen={CAMPAIGN_REGISTER_MODAL.screens.view}
      screens={screens}
      {...props}
    />
  )
}
