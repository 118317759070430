import React from 'react'

import TabsRoot from './tabsRoot'
import createTabsComponent from './createTabsComponent'
import createTabsHeader from './createTabsHeader'

const createTabs = ({ ComponentsContainer, HeaderContainer, TabContainer }) => {
  const TabHeader = createTabsHeader({ HeaderContainer, TabContainer })
  const TabComponent = createTabsComponent({ ComponentsContainer })

  return class Tabs extends React.Component {
    render() {
      const { headerProps } = this.props || {}
      return (
        <TabsRoot {...this.props}>
          <TabHeader {...headerProps} />
          <TabComponent />
        </TabsRoot>
      )
    }
  }
}

export default createTabs
