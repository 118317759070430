import React from 'react'

import { Icon } from 'pharmacy/src/display/icon'

import { getProgramCollectionPath } from 'mednet-util/src/sponsorship'

import { mixpanel } from 'mednet-util/src/tracking'

import { programTypesConfig } from '../configs'

import classes from './programListItem.scss'

export default function ProgramListItem({ programType, trackingProps }) {
  const programChipConfig = programTypesConfig[programType]

  if (!programChipConfig) {
    return null
  }

  return (
    <div
      style={{
        backgroundColor: programChipConfig.bgColor,
        color: programChipConfig.textColor,
      }}
      className={classes.programListItem}
      onClick={() => {
        mixpanel.track('clicked_to_view_programs_collection', {
          program_type: programType,
          ...trackingProps,
        })
        window.open(
          getProgramCollectionPath(programType),
          '_blank',
          'noopener noreferrer'
        )
      }}
    >
      <div>
        <programChipConfig.Icon />
      </div>

      <div>{programChipConfig.listText}</div>
      <div className={classes.arrow}>
        <Icon prefix="far" icon="arrow-right" />
      </div>
    </div>
  )
}
