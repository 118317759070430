import React from 'react'
import classNames from 'classnames'

import { TagSelect } from 'pharmacy/src/input/select'

import * as css from './widgets.scss'

const QueryBuilderMultiCreatableText = ({ setValue, value, ...props }) => {
  const mappedValue = !Array.isArray(value)
    ? []
    : value.map((v) => ({
        value: v,
        label: v,
      }))

  return (
    <TagSelect
      {...props}
      value={mappedValue}
      onChange={(option) => setValue(option.map((o) => o.value))}
      creatable
      isMulti
      className={classNames(props.className, css.input)}
      formatCreateLabel={(inputText) => `Add "${inputText}"`}
      styles={{
        valueContainer: (provided) => ({
          ...provided,
          width: '750px',
          padding: '0',
        }),
      }}
    />
  )
}

export default QueryBuilderMultiCreatableText
