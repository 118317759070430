export const authItems = {
  accountManagement: 'AccountManagement',
  admin: 'admin',
  consumer: 'consumer',
  createAnswer: 'createAnswer',
  createComment: 'createComment',
  createNewsletter: 'createNewsletter',
  createQuestion: 'createQuestion',
  createVote: 'createVote',
  customerService: 'customerservice',
  deleteAccount: 'deleteAccount',
  deleteAnswer: 'deleteAnswer',
  deleteComment: 'deleteComment',
  deleteQuestion: 'deleteQuestion',
  deleteVote: 'deleteVote',
  editProfile: 'editProfile',
  exportPoll: 'exportPoll',
  manageMailbox: 'manageMailbox',
  manageOwnContent: 'ManageOwnContent',
  manageSponsorship: 'manageSponsorship',
  member: 'member',
  moderator: 'moderator',
  postAsUser: 'postAsUser',
  questionManagement: 'QuestionManagement',
  reader: 'reader',
  sendAnswerRequest: 'sendAnswerRequest',
  share: 'share',
  superModerator: 'super_moderator',
  superModeratorPage: 'super_moderator_page',
  superModeratorStat: 'super_moderator_stat',
  updateAccount: 'updateAccount',
  updateAnswer: 'updateAnswer',
  updateComment: 'updateComment',
  updateOwnAnswer: 'updateOwnAnswer',
  updateQuestion: 'updateQuestion',
  viewAccount: 'viewAccount',
  viewAssociateLeaderboard: 'viewAssociateLeaderboard',
  viewFeed: 'viewfeed',
  viewJobsPromo: 'viewJobsPromo',
  viewProfile: 'viewProfile',
  viewQuestion: 'viewQuestion',
  viewTumorBoardDashboard: 'viewTumorBoardDashboard',
  manageTumorBoards: 'manageTumorBoards',
}
