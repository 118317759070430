import React from 'react'
import algoliasearch from 'algoliasearch'
import { connect } from 'react-redux'
import { Configure, InstantSearch } from 'react-instantsearch-dom'

import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import UserTagSelect from './userTagSelect'
import GeoTagSelect from './geoTagSelect'
import TopicTagSelect from './topicTagSelect'

const INDEX_SELECT_COMPONENT = {
  user: UserTagSelect,
  geo: GeoTagSelect,
  topic: TopicTagSelect,
}

class TagSearch extends React.Component {
  render() {
    const { userData, isLoaded, index, hitsPerPage, ...restProps } = this.props

    if (!isLoaded) {
      return null
    }

    const { algoliaApp, algoliaKey, algoliaNamespace } = userData
    const algoliaClient = algoliasearch(algoliaApp, algoliaKey)
    const SelectComponent = INDEX_SELECT_COMPONENT[index]

    const searchClient = {
      search(requests) {
        const newRequests = requests.filter(
          (request) => request.params.query && request.params.query.length !== 0
        )

        if (!newRequests.length) {
          return false
        }

        return algoliaClient.search(newRequests)
      },
    }

    return (
      <InstantSearch
        indexName={`${algoliaNamespace}_${index}`}
        searchClient={searchClient}
      >
        <SelectComponent {...restProps} />

        <Configure highlightPreTag="<em>" highlightPostTag="</em>" />
        <Configure hitsPerPage={hitsPerPage || 5} />
      </InstantSearch>
    )
  }
}

const mapStateToProps = (state) => {
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    isLoaded: userRequest.isLoaded,
  }
}

export default connect(mapStateToProps)(TagSearch)
