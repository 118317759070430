import { useCallback, useEffect, useState } from 'react'

const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0)

  const getScrollbarWidth = useCallback(() => {
    if (typeof document !== 'undefined') {
      const scrollDiv = document.createElement('div')
      scrollDiv.setAttribute(
        'style',
        'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
      )
      document.body.appendChild(scrollDiv)
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
      document.body.removeChild(scrollDiv)
      setScrollbarWidth(scrollbarWidth)
    } else {
      setScrollbarWidth(15)
    }
  }, [typeof document === 'undefined' ? null : document, setScrollbarWidth])

  // Add listener on mount and remove it on unmount
  useEffect(() => {
    window.addEventListener('resize', getScrollbarWidth)

    return () => window.removeEventListener('resize', getScrollbarWidth)
  }, [])

  // recalculate the width when the document changes
  useEffect(() => {
    getScrollbarWidth()
  }, [typeof document === 'undefined' ? null : document])

  return scrollbarWidth
}

export default useScrollbarWidth
