import React, { useContext } from 'react'
import classNames from 'classnames'

import css from './flexRow.scss'

const FlexRowContext = React.createContext({})
const FlexRowContextProvider = FlexRowContext.Provider

export const FlexRowCell = (props) => {
  const { size } = useContext(FlexRowContext)
  const children = props.children
  const classes = classNames(css[`flexRowCell_${size}`], props.className)

  return <div className={classes}>{children}</div>
}

export const FlexRow = (props) => {
  const {
    className,
    size = 'medium',
    centered = true,
    fullWidth = false,
    verticalMargins = true,
  } = props

  const classes = [css.flexRow]

  if (centered) classes.push(css.centeredContent)
  if (fullWidth) classes.push(css.fullWidth)
  if (!verticalMargins) classes.push(css.noVerticalMargins)

  classes.push(className)

  const children = props.children

  return (
    <div className={classNames(...classes)}>
      <FlexRowContextProvider value={{ size }}>
        {children}
      </FlexRowContextProvider>
    </div>
  )
}
