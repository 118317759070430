import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import _ from 'lodash'

import { Body1 } from '../../typography'

import classes from './storyModeHint.scss'

export default function StoryModeHint({ className }) {
  const ref = useRef(null)
  const [containerLoaded, setContainerLoaded] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    if (!containerLoaded || !ref.current || typeof document === 'undefined') {
      return () => {}
    }
    const checkElementOverflow = _.debounce(() => {
      const element = ref.current
      const elementRightEdge = element.getBoundingClientRect().right
      const viewportWidth = document.documentElement.clientWidth

      if (elementRightEdge > viewportWidth - 100) {
        setIsOverflow(true)
      } else {
        setIsOverflow(false)
      }
    }, 300)

    checkElementOverflow()
    window.addEventListener('resize', checkElementOverflow)

    return () => {
      window.removeEventListener('resize', checkElementOverflow)
    }
  }, [containerLoaded])

  return (
    <div
      ref={(el) => {
        ref.current = el
        setContainerLoaded(true)
      }}
      className={classNames(
        classes.storyModeHintContainer,
        classes.hintWithTopArrow,
        {
          [classes.hintOnWindowRightEdge]: isOverflow,
        },
        className
      )}
    >
      <div className={classes.arrow}></div>
      <div className={classNames(classes.storyModeHint)}>
        <Body1 className={classes.header}>New Story Mode</Body1>
        <Body1 className={classes.content}>
          Explore all of the discussions about this program in our advanced
          story experience
        </Body1>
      </div>
    </div>
  )
}
