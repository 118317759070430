import { PROGRAM_TYPE } from 'mednet-util/src/constants/sponsorship'

import JournalClubIcon from './icons/journalClubIcon'
import TargetedTopicIcon from './icons/targetedTopicIcon'
import MultiSpecialtySpotlightIcon from './icons/multiSpecialtySpotlightIcon'
import InvitedProfessorSeriesIcon from './icons/invitedProfessorSeries'
import AcademicRoundTableIcon from './icons/academicRoundTableIcon'

export const programTypesConfig = {
  [PROGRAM_TYPE.JOURNAL_CLUB]: {
    text: 'Journal Club',
    listText: 'Journal Clubs',
    bgColor: '#CEDBF3',
    textColor: '#262626',
    Icon: JournalClubIcon,
    iconProps: {
      style: {
        marginBottom: '3px',
      },
    },
    description:
      'Journal Clubs feature Q&A around specific paper(s), allowing physicians to ask experts and study authors questions related to the manuscript and learn more about applications to clinical practice.',
  },

  [PROGRAM_TYPE.TARGETED_TOPIC]: {
    text: 'Targeted Topic',
    listText: 'Targeted Topics',
    bgColor: '#EECFC3',
    textColor: '#262626',
    Icon: TargetedTopicIcon,
    description:
      'Targeted topics feature Q&A around specific clinical themes, allowing physicians to ask invited experts questions relevant to the topic being discussed.',
  },
  [PROGRAM_TYPE.MULTI_SPECIALTY_SPOTLIGHT]: {
    text: 'Multi-Specialty Spotlight',
    listText: 'Multi-Specialty Spotlights',
    bgColor: '#D6C3EE',
    textColor: '#262626',
    Icon: MultiSpecialtySpotlightIcon,
    description:
      'Multi-Specialty Spotlights feature Q&A around a specific topic that has relevance to more than one specialty, and therefore allows physicians to interact with colleagues and experts outside their own specialty.',
  },
  [PROGRAM_TYPE.INVITED_PROFESSOR_SERIES]: {
    text: 'Invited Professor Series',
    listText: 'Invited Professor Series',
    bgColor: '#EECFC3',
    textColor: '#262626',
    Icon: InvitedProfessorSeriesIcon,
    description:
      'The Invited Professor Series features educational content, such as a recorded lecture, from an invited expert and then features Q&A relevant to that content.',
  },
  [PROGRAM_TYPE.ACADEMIC_ROUND_TABLE]: {
    text: 'Academic Round Table',
    listText: 'Academic Round Tables',
    bgColor: '#F9DBAE',
    textColor: '#262626',
    Icon: AcademicRoundTableIcon,
    description:
      'Academic Round Tables offer an asynchronous platform for experts to engage in in-depth discussions on the latest research and emerging data, with a focus on translating new findings into practical clinical insights.',
  },
}
