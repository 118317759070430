import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Modal } from 'pharmacy/src/display/modal'
import { ContainedSelect } from 'pharmacy/src/input/select'
import { SPONSORSHIP_IMPORT_CURRICULUM_QUESIONS_MODAL } from 'mednet-util/src/constants/modal'

import { useCurriculums } from 'mednet-cns/src/hooks/curriculum'
import { Header3, Header4 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import StarLoader from 'pharmacy/src/misc/loaders/starLoader/starLoader'
import { bulkAddQuestions } from 'mednet-cns/src/reducers/sponsorship'

import css from './importCurriculumQuestions.scss'

const ModalContent = ({ closeModal, sponsorship }) => {
  const dispatch = useDispatch()
  const [curriculums, { isLoading }] = useCurriculums()
  const [selectedCurriculum, setSelectedCurriculum] = useState()
  const [selectedEdition, setSelectedEdition] = useState()
  const [submittingQuestions, setSubmittingQuestions] = useState(false)

  const { sponsorshipId } = sponsorship

  const onCurriculumChange = (newValue) => {
    setSelectedEdition(null)
    setSelectedCurriculum(newValue)
  }

  const selectedQuestionIds = (() => {
    if (!selectedEdition || !selectedCurriculum) return []

    const existingQuestionIds = Object.keys(sponsorship.questions)

    return (
      selectedCurriculum.curriculumQuestions
        ?.filter(
          ({ curriculumEditionId, questionId }) =>
            curriculumEditionId === selectedEdition.curriculumEditionId &&
            !existingQuestionIds.includes(`${questionId}`)
        )
        .map(({ questionId }) => questionId) ?? []
    )
  })()

  const onSubmit = () => {
    const formData = new FormData()
    selectedQuestionIds.forEach((questionId) =>
      formData.append('questionIds[]', questionId)
    )
    setSubmittingQuestions(true)
    dispatch(bulkAddQuestions(sponsorshipId, formData, closeModal))
  }

  const submitDisabled = selectedQuestionIds.length === 0

  return (
    <div className={css.container}>
      <Header3>Import Questions From Curriculum Edition</Header3>
      <div className={css.content}>
        {!isLoading ? (
          <>
            <ContainedSelect
              placeholder="Select Curriculum"
              name="sponsorship-curriculum-select"
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.curriculumId}
              options={curriculums}
              onChange={onCurriculumChange}
              value={selectedCurriculum}
            />

            <ContainedSelect
              placeholder="Select Edition"
              name="sponsorship-edition-select"
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.curriculumEditionId}
              options={selectedCurriculum?.curriculumEditions ?? []}
              onChange={setSelectedEdition}
              value={selectedEdition}
            />

            {selectedEdition && (
              <Header4>
                {selectedQuestionIds.length} new question
                {selectedQuestionIds.length !== 1 && 's'} available for import.
              </Header4>
            )}
          </>
        ) : (
          <StarLoader />
        )}
      </div>

      <div className={css.buttons}>
        <Button onClick={closeModal} type="neutral">
          Cancel
        </Button>
        <Button
          isDisabled={submitDisabled}
          isLoading={submittingQuestions}
          onClick={onSubmit}
        >
          Import
        </Button>
      </div>
    </div>
  )
}

const ImportCurriculumQuesionsModal = (props) => {
  return (
    <Modal
      closeOnBackdrop
      modalId={SPONSORSHIP_IMPORT_CURRICULUM_QUESIONS_MODAL.modalId}
      {...props}
    >
      <ModalContent />
    </Modal>
  )
}

export default ImportCurriculumQuesionsModal
