export const PROGRAM_TYPE = {
  JOURNAL_CLUB: 'JOURNAL_CLUB',
  TARGETED_TOPIC: 'TARGETED_TOPIC',
  ACADEMIC_ROUND_TABLE: 'ACADEMIC_ROUND_TABLE',
  MULTI_SPECIALTY_SPOTLIGHT: 'MULTI_SPECIALTY_SPOTLIGHT',
  INVITED_PROFESSOR_SERIES: 'INVITED_PROFESSOR_SERIES',
}

export const PROGRAM_PUBLISH_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
}
