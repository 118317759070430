import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  FETCH_HOME_TOPICS,
  fetchHomeTopics,
} from 'mednet-cns/src/reducers/topic'
import { finishOnboarding } from 'mednet-cns/src/reducers/user'

import { mixpanel } from 'mednet-util/src/tracking'

import FollowTopicButton from './followTopicButton'

import css from './topicsScreen.scss'

class TopicsScreen extends React.Component {
  componentDidMount() {
    if (!this.props.homeTopicsIsLoaded) {
      this.props.fetchHomeTopics()
    }
  }

  handleClose = () => {
    mixpanel.track('selected_onboarding_subtopics', {
      topics: this.props.followedDiseaseSitesTopicsIds,
    })
    this.props.finishOnboarding()
    this.props.closeModal()
  }

  render() {
    const { homeTopics, homeTopicsIsLoaded } = this.props

    if (!homeTopicsIsLoaded) {
      return (
        <StarLoader
          wait={false}
          className={css.loading}
          isVerticalMargin={false}
        />
      )
    }

    const { diseaseSites } = homeTopics

    return (
      <div>
        <Header1>Customize your feed</Header1>
        <div className={css.sectionLinks}>
          {diseaseSites.map((topicObject) => (
            <FollowTopicButton
              key={topicObject.topicId}
              topicId={topicObject.topicId}
            />
          ))}
        </div>
        <div className={css.buttonContainer}>
          <Button className={css.continueButton} onClick={this.handleClose}>
            Finish
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const homeTopicsRequest =
    _.get(state.request.requests, makeRequestName(FETCH_HOME_TOPICS)) || {}

  const homeTopics = state.topic.homeTopics || {}
  const followedDiseaseSitesTopicsIds =
    state.topic.topics && homeTopics.diseaseSites
      ? homeTopics.diseaseSites
          .filter(
            (homeTopic) => state.topic.topics[homeTopic.topicId].isFollowed
          )
          .map((topic) => topic.topicId)
      : []

  return {
    homeTopics,
    homeTopicsIsLoaded: homeTopicsRequest.isLoaded,
    followedDiseaseSitesTopicsIds,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHomeTopics: () => dispatch(fetchHomeTopics()),
    finishOnboarding: () => dispatch(finishOnboarding()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicsScreen)
