import React from 'react'
import _ from 'lodash'

import Button from './button'

import css from './buttonCheckbox.scss'

class ButtonCheckbox extends React.Component {
  static defaultProps = {
    selectText: 'Select',
    unselectText: 'Unselect',
    checkedType: 'primary',
    uncheckedType: 'neutral',
  }

  constructor(props) {
    super(props)
    const { inputProps = {} } = props

    this.state = {
      isChecked: inputProps.checked || false,
    }
  }

  componentDidUpdate(prevProps) {
    const prevChecked = _.get(prevProps, 'inputProps.checked')
    const thisChecked = _.get(this.props, 'inputProps.checked')

    if (prevChecked !== thisChecked) {
      this.setState({
        isChecked: thisChecked,
      })
    }
  }

  handleChange = (evt) => {
    const { inputProps = {} } = this.props
    const { onChange } = inputProps

    this.setState({ isChecked: evt.target.checked })

    if (onChange) {
      onChange(evt)
    }
  }

  renderText = () => {
    const { children, selectText, unselectText } = this.props
    const { isChecked } = this.state

    if (children) {
      return children
    }

    return isChecked ? unselectText : selectText
  }

  render() {
    const {
      buttonProps = {},
      checkedType,
      className,
      inputProps = {},
      uncheckedType,
    } = this.props
    const { isChecked } = this.state

    return (
      <div className={className}>
        <label>
          <input
            type="checkbox"
            {...inputProps}
            onChange={this.handleChange}
            className={css.input}
            checked={this.state.isChecked}
          />
          <Button
            type={isChecked ? checkedType : uncheckedType}
            icon={['far', isChecked ? 'check-square' : 'square']}
            {...buttonProps}
          >
            {this.renderText()}
          </Button>
        </label>
      </div>
    )
  }
}

export default ButtonCheckbox
