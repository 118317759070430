import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import classNames from 'classnames'

import { Subtitle2 } from 'pharmacy/src/typography'
import { TextInput } from 'pharmacy/src/input/textInput'
import { SimpleSelect, TagSelect } from 'pharmacy/src/input/select'
import { TagSearch } from 'pharmacy/src/display/tagSearch'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { DatePicker, DateRangePicker } from 'pharmacy/src/input/datePicker'
import { TextEditor } from 'pharmacy/src/input/textEditor'

import { PROGRAM_TYPE } from 'mednet-util/src/constants/sponsorship'

import css from './sponsorshipForm.scss'

export const SponsorshipSchema = Yup.object().shape({
  description: Yup.string().required(),
  sponsor: Yup.mixed().required(),
  topic: Yup.mixed().required(),
  topics: Yup.mixed(),
  emblem: Yup.boolean(),
  useCustomInfo: Yup.boolean(),
  infoText: Yup.mixed(),
  experts: Yup.array().required(),
  editor: Yup.mixed().required(),
  dates: Yup.object().shape({
    from: Yup.string().required(),
    to: Yup.string().required(),
  }),
  specialties: Yup.array().required().min(1),
  subspecialties: Yup.array().required().min(1),
  journal: Yup.mixed().test(
    'journal',
    'Journal is required',
    function validateJounal(value) {
      return this.parent?.programType?.value === PROGRAM_TYPE.JOURNAL_CLUB
        ? Boolean(value)
        : true
    }
  ),
})

class SponsorshipForm extends React.Component {
  handleSelectChange = (fieldName) => (selected) => {
    const { setFieldValue, setFieldTouched } = this.props
    setFieldTouched(fieldName)
    setFieldValue(fieldName, selected)
  }

  handleDateChange = (dates) => {
    const { setFieldValue } = this.props

    setFieldValue('dates', dates)
  }

  handleReleaseDateChange = (date) => {
    const { setFieldValue } = this.props

    setFieldValue('releaseDate', date)
  }

  handleCheckboxChange = (fieldName) => () => {
    const { setFieldValue, values } = this.props

    setFieldValue(fieldName, !values[fieldName])
  }

  handleEditorChange = (value) => {
    const { setFieldValue } = this.props

    setFieldValue('infoText', value)
  }

  getSpecialtiesSubspecialties = () => {
    const { values, subspecialties } = this.props
    const selectedSpecialtiesIds = values.specialties?.map(
      (specialty) => specialty.specialtyId
    )

    if (!selectedSpecialtiesIds) return []
    const availabeSubspecialties = subspecialties?.filter((subspecialty) =>
      selectedSpecialtiesIds.includes(subspecialty.specialtyId)
    )

    return availabeSubspecialties
  }

  handleSpecialtiesChange = (selected) => {
    const { setFieldValue, values } = this.props
    this.handleSelectChange('specialties')(selected)
    const selectedIds = selected.map((specialty) => specialty.specialtyId)
    const updatedSubspecialties = values.subspecialties?.filter(
      (subspecialty) => selectedIds.includes(subspecialty.specialtyId)
    )
    setFieldValue('subspecialties', updatedSubspecialties)
  }

  render() {
    const {
      closeModal,
      handleSubmit,
      isSubmitting,
      sponsors,
      formRef,
      handleChange,
      errors,
      values,
      submitText,
      specialties,
      journals,
    } = this.props
    const submitIsDisabled = _.isEmpty(values) || !_.isEmpty(errors)
    const isJournalClub =
      values.programType === PROGRAM_TYPE.JOURNAL_CLUB ||
      (typeof values.programType === 'object' &&
        values.programType?.value === PROGRAM_TYPE.JOURNAL_CLUB)

    return (
      <form className={css.createContainer} ref={formRef}>
        <div className={css.formInput}>
          <Subtitle2>Sponsorship Name *</Subtitle2>
          <TextInput
            name="description"
            showClear={false}
            onChange={handleChange('description')}
            value={values.description}
          />
        </div>
        <div className={css.formInput}>
          <div className={css.programTypeRow}>
            <div className={css.programTypeCol}>
              <Subtitle2>Program Type *</Subtitle2>
              <SimpleSelect
                options={Object.values(PROGRAM_TYPE).map((value) => ({
                  label: _.startCase(value?.toLowerCase()),
                  value,
                }))}
                isSearchable
                creatable
                className={css.singleSelect}
                name="programType"
                onChange={this.handleSelectChange('programType')}
                defaultValue={
                  values.programType &&
                  typeof values.programType === 'string' && {
                    label: _.startCase(values.programType.toLowerCase()),
                    value: values.programType,
                  }
                }
              />
            </div>

            {isJournalClub && (
              <div className={css.journalCol}>
                <Subtitle2>Journal Club *</Subtitle2>
                <SimpleSelect
                  options={journals}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.journalId}
                  isSearchable
                  creatable
                  className={css.singleSelect}
                  name="journal"
                  onChange={this.handleSelectChange('journal')}
                  value={values.journal}
                />
              </div>
            )}
          </div>
        </div>
        <div className={css.formInput}>
          <Subtitle2>Client *</Subtitle2>
          <SimpleSelect
            options={_.map(sponsors, (sponsor) => ({
              label: sponsor.sponsor,
              value: sponsor.sponsorId,
            }))}
            isSearchable
            creatable
            className={css.singleSelect}
            name="sponsor"
            onChange={this.handleSelectChange('sponsor')}
            defaultValue={
              values.sponsor && {
                label: values.sponsor.sponsor,
                value: values.sponsor.sponsorId,
              }
            }
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Specialties *</Subtitle2>
          <TagSelect
            options={specialties}
            getOptionLabel={(option) => option.specialty}
            getOptionValue={(option) => option.specialtyId}
            isSearchable
            isMulti
            name="specialties[]"
            defaultFilterOption
            openMenuOnClick
            value={values.specialties}
            onChange={this.handleSpecialtiesChange}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Subspecialties *</Subtitle2>
          <TagSelect
            options={this.getSpecialtiesSubspecialties()}
            getOptionLabel={(option) => option.subspecialty}
            getOptionValue={(option) => option.subspecialtyId}
            isSearchable
            isMulti
            name="subspecialties[]"
            defaultFilterOption
            openMenuOnClick
            value={values.subspecialties}
            onChange={this.handleSelectChange('subspecialties')}
            noOptionsMessage={() =>
              !values.specialties?.length
                ? 'Make sure to select a specialty first'
                : 'No results found in the selected specialties'
            }
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Primary Topic *</Subtitle2>
          <TagSearch
            index="topic"
            isMulti={false}
            name="topic"
            value={values.topic}
            creatable
            onChange={this.handleSelectChange('topic')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Automatically Added Topics</Subtitle2>
          <TagSearch
            index="topic"
            isMulti
            name="topics[]"
            value={values.topics}
            creatable
            onChange={this.handleSelectChange('topics')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Experts</Subtitle2>
          <TagSearch
            index="user"
            name="experts[]"
            value={values.experts.map(({ email, ...rest }) => rest)}
            onChange={this.handleSelectChange('experts')}
          />
        </div>
        <div className={css.formInput}>
          <Subtitle2>Editor *</Subtitle2>
          <TagSearch
            index="user"
            isMulti={false}
            name="editor"
            value={values.editor}
            onChange={this.handleSelectChange('editor')}
          />
        </div>
        <div className={classNames(css.formInput, css.emblem)}>
          <ButtonCheckbox
            unselectText="Emblem"
            selectText="Emblem"
            inputProps={{
              name: 'emblem',
              value: true,
              onChange: this.handleCheckboxChange('emblem'),
              checked: values.emblem,
            }}
          />
          <TextInput
            className={css.emblemInput}
            name="emblemText"
            showClear={false}
            onChange={handleChange('emblemText')}
            value={values.emblemText}
            placeholder="Emblem text..."
            disabled={!values.emblem}
          />
        </div>
        <div className={classNames(css.formInput)}>
          <ButtonCheckbox
            unselectText="Paid"
            selectText="Paid"
            inputProps={{
              name: 'isPaid',
              value: true,
              onChange: this.handleCheckboxChange('isPaid'),
              checked: values.isPaid,
            }}
          />
        </div>
        <div className={classNames(css.formInput, css.infoTextWrapper)}>
          <ButtonCheckbox
            className={css.customInfo}
            unselectText="Use custom info text"
            selectText="Use custom info text"
            inputProps={{
              name: 'useCustomInfo',
              value: true,
              onChange: this.handleCheckboxChange('useCustomInfo'),
              checked: values.useCustomInfo,
            }}
          />
          <TextEditor
            name="infoText"
            onEditorChange={this.handleEditorChange}
            value={values.infoText}
            disabled={!values.useCustomInfo}
            autosave_restore_when_empt={false}
          />
        </div>
        <div className={classNames(css.dates, css.formInput)}>
          <div>
            <Subtitle2>Active dates *</Subtitle2>
            <DateRangePicker
              fromProps={{
                inputProps: { name: 'startDate' },
                defaultValue: moment(values.dates.from).format('L'),
              }}
              toProps={{
                inputProps: { name: 'endDate' },
                defaultValue: moment(values.dates.to).format('L'),
              }}
              onChange={this.handleDateChange}
            />
          </div>
          <div className={css.releaseDate}>
            <Subtitle2>Public release date</Subtitle2>
            <DatePicker
              inputProps={{ name: 'releaseDate' }}
              defaultValue={moment(values.releaseDate).format('L')}
              onChange={this.handleReleaseDateChange}
            />
          </div>
        </div>
        <div className={css.buttonsContainer}>
          <Button type="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isDisabled={submitIsDisabled}
            isLoading={isSubmitting}
          >
            {submitText}
          </Button>
        </div>
      </form>
    )
  }
}

export default SponsorshipForm
