import React from 'react'
import classNames from 'classnames'

import { Icon } from 'pharmacy/src/display/icon'
import { Subtitle3 } from 'pharmacy/src/typography'

import * as css from './iconWithPopup.scss'
class IconWithPopup extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  state = {
    opened: false,
  }

  onInfoClick = () => {
    const { onClick } = this.props
    this.setState((state) => ({
      opened: !state.opened,
    }))

    if (onClick) {
      onClick()
    }
  }

  boxClick = () => {
    this.setState({
      opened: false,
    })
  }

  renderBox() {
    const { opened } = this.state
    const { children } = this.props

    if (!opened || !children) {
      return null
    }

    return (
      <div className={css.floatingBox} onClick={this.boxClick}>
        {children}
      </div>
    )
  }

  render() {
    const { iconProps, text, type, className } = this.props

    const icon = type === 'warning' ? 'exclamation-triangle' : 'info-circle'
    const iconClass = type === 'warning' ? css.warning : css.info

    return (
      <div ref={this.ref} className={className}>
        <Subtitle3 onClick={this.onInfoClick} className={css.clickable}>
          <Icon
            className={classNames(css.icon, iconClass)}
            icon={icon}
            {...iconProps}
          />
          <span className={css.text}>{text}</span>
        </Subtitle3>
        {this.renderBox()}
      </div>
    )
  }
}

export default IconWithPopup
