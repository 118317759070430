import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_SHOULD_SHOW = 'survey/FETCH_SHOULD_SHOW'
export const POST_SURVEY_ANSWERED = 'survey/POST_SURVEY_ANSWERED'

export function fetchShouldShow(surveyId, questionId, callback) {
  return {
    type: FETCH_SHOULD_SHOW,
    requestId: surveyId,
    surveyId,
    questionId,
    callback,
  }
}

export function postSurveyAnswered(surveyId, questionId) {
  return {
    type: POST_SURVEY_ANSWERED,
    requestId: surveyId,
    surveyId,
    questionId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_SHOULD_SHOW, (action) =>
    makeURL(
      `survey/shouldShowJSON/${action.surveyId}`,
      action.questionId && { questionId: action.questionId }
    )
  )

  yield makeFetchEffect(
    takeLatest,
    POST_SURVEY_ANSWERED,
    (action) =>
      makeURL(
        `survey/answer/${action.surveyId}`,
        action.questionId && { questionId: action.questionId }
      ),
    { method: 'POST' }
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  surveys: {},
}

export const getSurveyKey = (surveyId, questionId) => {
  return `${surveyId}.${questionId}`
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_SHOULD_SHOW): {
      const surveyKey = getSurveyKey(action.surveyId, action.questionId)

      return receiveReducer(state, action, () => ({
        surveys: {
          ...state.surveys,
          [surveyKey]: {
            ...state.surveys[surveyKey],
            ...action.response,
          },
        },
      }))
    }

    default:
      return state
  }
}
