import React from 'react'
import { formatDate } from 'react-day-picker/moment'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import 'react-day-picker/lib/style.css'

import DatePicker from './datePicker'
import css from './datePicker.scss'

// https://react-day-picker-v7.netlify.app/examples/elements-year-navigation
function MonthCaption({ date, localeUtils }) {
  const months = localeUtils.getMonths()
  return <div className="DayPicker-Caption">{months[date.getMonth()]}</div>
}

export default class DayMonthPicker extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      value: this.getDefaultValue(),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stringValue !== this.props.stringValue) {
      this.setState({
        value: this.parseStringValue(),
      })
    }
  }

  defaultYear = 2012 // https://en.wikipedia.org/wiki/Leap_year_starting_on_Sunday

  minValue = new Date(this.defaultYear, 0, 1)

  maxValue = new Date(this.defaultYear, 11, 31)

  getDefaultValue() {
    return isEmpty(this.props.stringValue)
      ? this.minValue
      : this.parseStringValue()
  }

  parseStringValue() {
    return moment(
      `${this.props.stringValue}/${this.defaultYear}`,
      'MM/DD/YYYY'
    ).toDate()
  }

  handleChange(value) {
    this.setState({ value })
  }

  render() {
    return (
      <DatePicker
        className={css.datePicker}
        formatDateFunction={(date, ..._args) => {
          if (this.props.noDefaultValue && isEmpty(this.state.value)) {
            return 'MM/DD'
          }
          return formatDate(date, 'MM/DD')
        }}
        // Need to be set, because otherwise the component will default to current date no matter how I try to prevent it
        // and the current date is out of range of [fromMonth(2012-01-01) - toMonth(2012-12-31)], which will cause the
        // month navigation not to show any arrows <- ->
        defaultValue={this.getDefaultValue()}
        onChange={this.handleChange}
        placeholder="MM/DD"
        dayPickerProps={{
          showWeekDays: false,
          showOutsideDays: false,
          fromMonth: this.minValue,
          toMonth: this.maxValue,
          selectedDays: this.state.value ? [this.state.value] : [],
          captionElement: ({ date, localeUtils }) => (
            <MonthCaption date={date} localeUtils={localeUtils} />
          ),
        }}
        value={this.state.value}
        {...this.props}
      />
    )
  }
}
