import React from 'react'

import { Header2, Subtitle2, Body1 } from 'pharmacy/src/typography'
import { ContainedSelect, components } from 'pharmacy/src/input/select'
import { Button } from 'pharmacy/src/input/button'
import { formatName } from 'mednet-util/src/string'

import css from './moveAnswerForm.scss'

class AnswerOption extends React.Component {
  render() {
    const { data } = this.props
    const { answerId, answer, user } = data
    const { firstName, middleName, lastName } = user

    return (
      <components.Option {...this.props}>
        <div className={css.optionContainer}>
          <Body1
            dangerouslySetInnerHTML={{ __html: answer }}
            className={css.answerPreview}
          />
          <Subtitle2 className={css.optionSubtitle}>
            #{answerId} by {formatName(firstName, middleName, lastName)}
          </Subtitle2>
        </div>
      </components.Option>
    )
  }
}

class MoveAnswerForm extends React.Component {
  getOptionLabel(option) {
    const { firstName, middleName, lastName } = option.user
    return `#${option.answerId} by ${formatName(
      firstName,
      middleName,
      lastName
    )}`
  }

  getOptionValue(option) {
    return option.answerId
  }

  render() {
    const { closeModal, handleSubmit, answers, formRef } = this.props

    return (
      <form ref={formRef} className={css.container}>
        <div>
          <Header2>Choose which answer this should be a comment for</Header2>
          <hr />
          <div className={css.selectContainer}>
            <ContainedSelect
              maxMenuHeight="200"
              options={answers}
              components={{ Option: AnswerOption }}
              getOptionLabel={this.getOptionLabel}
              getOptionValue={this.getOptionValue}
              name="answerId"
            />
          </div>
        </div>
        <div className={css.buttonsContainer}>
          <Button type="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="destructive" onClick={handleSubmit}>
            Change to Comment
          </Button>
        </div>
      </form>
    )
  }
}

export default MoveAnswerForm
