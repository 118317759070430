import React from 'react'
import classNames from 'classnames'

import { Star } from 'pharmacy/src/style/logo'

import { createLoader } from '../createLoader'

import css from './starLoader.scss'

class StarLoader extends React.Component {
  static defaultProps = {
    size: 'medium',
    isVerticalMargin: true,
    isFullScreen: false,
  }

  render() {
    const { size, className, isVerticalMargin, isFullScreen } = this.props

    const containerClasses = classNames(css.container, className, {
      [css.containerVerticalMargin]: !isFullScreen && isVerticalMargin,
      [css.containerFullScreen]: isFullScreen,
    })

    const starClasses = classNames(css.star, css[`star_${size}`])

    return (
      <div className={containerClasses}>
        <Star className={starClasses} />
      </div>
    )
  }
}

export default createLoader(StarLoader)
