import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_SPONSORS = 'sponsorship/FETCH_SPONSORS'

export function fetchSponsors() {
  return {
    type: FETCH_SPONSORS,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_SPONSORS,
    makeURL(`sponsor/getSponsorsJSON`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  sponsors: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_SPONSORS): {
      const { sponsors } = action.response

      return receiveReducer(state, action, () => ({
        sponsors: _.keyBy(sponsors, 'sponsorId'),
      }))
    }

    default:
      return state
  }
}
