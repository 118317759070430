import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { CREATE_QUESTION_REQUEST } from 'mednet-cns/src/reducers/question'

import css from './confirmationScreen.scss'

class ConfirmationScreen extends React.Component {
  render() {
    const { closeModal, requestIsLoading, requestIsLoaded } = this.props

    if (requestIsLoading || !requestIsLoaded) {
      return <StarLoader wait={false} isVerticalMargin={false} />
    }

    return (
      <div className={css.container}>
        <Header2>Your request has been received!</Header2>
        <hr />
        <Subtitle2>Our moderator team will review your request soon.</Subtitle2>
        <Button type="primary" onClick={closeModal} className={css.closeButton}>
          Close
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const createQuestionRequest =
    _.get(
      state.request.requests,
      makeRequestName(CREATE_QUESTION_REQUEST, ownProps.questionId)
    ) || {}

  return {
    requestIsLoading: createQuestionRequest.isLoading,
    requestIsLoaded: createQuestionRequest.isLoaded,
  }
}

export default connect(mapStateToProps)(ConfirmationScreen)
