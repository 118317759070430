import { merge } from 'lodash'
import { BasicConfig } from 'react-awesome-query-builder'

import useQueryBuilderWidgets from './widgets'
import useQueryBuilderFields from './fields'

const useQueryBuilderConfig = (queryUserStatus) => {
  const InitialConfig = BasicConfig

  const conjunctions = {
    AND: InitialConfig.conjunctions.AND,
  }

  const operators = {
    ...InitialConfig.operators,
    select_any_in: {
      ...InitialConfig.operators.select_any_in,
      label: 'Include any from',
    },
    select_all_in: {
      ...InitialConfig.operators.select_any_in,
      label: 'Include all from',
      jsonLogic: 'all in',
      reversedOp: '',
    },
    select_not_any_in: {
      ...InitialConfig.operators.select_not_any_in,
      label: 'Exclude any from',
    },
    between: {
      ...InitialConfig.operators.between,
      valueLabels: ['From', 'To'],
      textSeparators: ['from', 'to'],
    },
    // Those two below operators don't care about value, only == and != what matters
    is_specified: {
      ...InitialConfig.operators.is_null,
      label: 'Is specified',
    },
    is_not_specified: {
      ...InitialConfig.operators.is_not_null,
      label: 'Is not specified',
    },
    like: {
      ...InitialConfig.operators.equal,
      jsonLogic: 'like',
      label: 'Contains',
    },
    not_like: {
      ...InitialConfig.operators.not_equal,
      jsonLogic: 'not like',
      label: 'Not contains',
    },
  }

  const widgets = useQueryBuilderWidgets()
  const fields = useQueryBuilderFields(queryUserStatus)

  const types = {
    ...InitialConfig.types,
    // examples of  overriding
    multiselect: merge(InitialConfig.types.multiselect, {
      widgets: {
        multiselect: {
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
        },
      },
    }),
    select: merge(InitialConfig.types.select, {
      widgets: {
        multiselect: {
          operators: ['select_any_in', 'select_not_any_in', 'select_all_in'],
        },
      },
    }),
    boolean: merge(InitialConfig.types.boolean, {
      widgets: {
        boolean: {
          widgetProps: {
            hideOperator: true,
            operatorInlineLabel: 'is',
          },
        },
      },
    }),
    multicreatabletext: {
      defaultOperator: 'select_any_in',
      widgets: {
        multicreatabletext: {
          operators: ['select_any_in', 'select_not_any_in'],
        },
      },
    },
    date: {
      widgets: {
        date: {
          operators: ['less', 'less_or_equal', 'greater', 'greater_or_equal'],
        },
      },
    },
  }

  const localeSettings = {
    locale: {
      moment: 'en',
    },
    valueLabel: 'Value',
    valuePlaceholder: 'Value',
    fieldLabel: 'Field',
    operatorLabel: 'Operator',
    fieldPlaceholder: 'Select field',
    operatorPlaceholder: 'Select operator',
    deleteLabel: null,
    addGroupLabel: 'Add group',
    addRuleLabel: 'Add rule',
    addSubRuleLabel: 'Add sub rule',
    delGroupLabel: null,
    notLabel: 'Not',
    valueSourcesPopupTitle: 'Select value source',
    removeRuleConfirmOptions: {
      title: 'Are you sure delete this rule?',
      okText: 'Yes',
      okType: 'danger',
    },
    removeGroupConfirmOptions: {
      title: 'Are you sure delete this group?',
      okText: 'Yes',
      okType: 'danger',
    },
  }

  const settings = {
    ...InitialConfig.settings,
    ...localeSettings,

    valueSourcesInfo: {
      value: {
        label: 'Value',
      },
      field: {
        label: 'Field',
        widget: 'field',
      },
      func: {
        label: 'Function',
        widget: 'func',
      },
    },
    maxNesting: 1,
    canLeaveEmptyGroup: false, //after deletion
    showNot: false,
  }

  const funcs = {}

  return {
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    funcs,
    fields,
  }
}

export default useQueryBuilderConfig
