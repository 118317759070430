export const REQUEST = 'fetch/REQUEST'
export const RECEIVE = 'fetch/RECEIVE'
export const CANCEL = ' fetch/CANCEL'

export const makeRequestName = (type, requestId) => {
  if (!requestId) {
    return type
  }

  return `${type}.${requestId}`
}

const initialState = {
  requests: {},
}

export function reducer(state = initialState, action) {
  switch (action.fetchType) {
    case REQUEST: {
      const requestName = makeRequestName(action.baseType, action.requestId)

      return {
        ...state,
        requests: {
          ...state.requests,
          [requestName]: {
            ...state.requests[requestName],
            isLoading: true,
            isCancelled: false,
          },
        },
      }
    }

    case RECEIVE: {
      const requestName = makeRequestName(action.baseType, action.requestId)

      return {
        ...state,
        requests: {
          ...state.requests,
          [requestName]: {
            ...state.requests[requestName],
            isError: action.response.isError,
            errorStatus: action.response.errorStatus,
            isLoaded: true,
            isCancelled: false,
            isLoading: false,
          },
        },
      }
    }

    case CANCEL: {
      const requestName = makeRequestName(action.baseType, action.requestId)

      return {
        ...state,
        requests: {
          ...state.requests,
          [requestName]: {
            ...state.requests[requestName],
            isLoading: false,
            isCancelled: true,
          },
        },
      }
    }

    default:
      return state
  }
}
