import React from 'react'

import { formatAnonymousInstitution } from 'mednet-util/src/string'

class InstitutionInformation extends React.Component {
  render() {
    const { institutionName, institutionType, region } = this.props.user

    if (institutionName) {
      return institutionName
    }

    if (institutionType) {
      return formatAnonymousInstitution(institutionType, region)
    }

    return null
  }
}

export default InstitutionInformation
