import React, { useState, useMemo } from 'react'
import { Formik, FastField, Form } from 'formik'
import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import Recaptcha from 'react-google-recaptcha'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography'

import { sendPartnershipMessage } from 'mednet-cns/src/reducers/contact'
import { FETCH_USER_WITH_PERMISSIONS_PUBLIC } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import css from './partnershipFrom.scss'

const schema = yup.object().shape({
  firstName: yup.string().max(35, 'Too long').required('Required'),
  lastName: yup.string().max(35, 'Too long').required('Required'),
  email: yup
    .string()
    .email('Invalid email')
    .max(128, 'Too long')
    .required('Required'),
  recaptcha: yup.string().required('It is required to pass the reCAPTCHA test'),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const TextInputField = ({ name, touched, errors, ...restProps }) => (
  <FastField name={name}>
    {({ field }) => (
      <TextInput
        className={css.formField}
        {...field}
        {...restProps}
        typeStyle="proximaNovaPrimary"
        showClear={false}
        onChangeHandlesEvent
        validationTag={getValidationTag(field.name, touched, errors)}
      />
    )}
  </FastField>
)

const RecaptchaVerificationField = ({
  recaptchaKey,
  name,
  touched,
  errors,
}) => (
  <FastField name={name}>
    {({ field, form }) => (
      <div className={css.recaptcha}>
        <Recaptcha
          sitekey={recaptchaKey}
          onChange={(value) => {
            form.setFieldValue(field.name, value)
          }}
        />
        <div
          className={css.recaptchaRequiredMessage}
          hidden={!errors[name] || !touched[name]}
        >
          {errors.recaptcha}
        </div>
      </div>
    )}
  </FastField>
)

const handlePartnershipFormSubmit = (
  values,
  setSubmitting,
  dispatch,
  setMessageSendingStatus,
  setFormSubmissionCompletion
) => {
  dispatch(
    sendPartnershipMessage(values, (res) => {
      setMessageSendingStatus(res.success)
      setFormSubmissionCompletion(true)
      setSubmitting(false)
    })
  )
}

const PartnershipInternalForm = (props) => {
  const { isSubmitting, touched, errors, handleSubmit, hidden, recaptchaKey } =
    props

  return (
    <Form className={css.partnershipForm} hidden={hidden}>
      <TextInputField
        name="firstName"
        placeholder="First Name"
        tag="Required"
        touched={touched}
        errors={errors}
      />

      <TextInputField
        name="lastName"
        placeholder="Last Name"
        tag="Required"
        touched={touched}
        errors={errors}
      />

      <TextInputField
        name="email"
        placeholder="Email"
        tag="Required"
        touched={touched}
        errors={errors}
      />

      <RecaptchaVerificationField
        name="recaptcha"
        recaptchaKey={recaptchaKey}
        touched={touched}
        errors={errors}
      />

      <Button
        type="orange"
        className={css.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
      >
        <ProximaNova.Text2>Submit</ProximaNova.Text2>
      </Button>
    </Form>
  )
}

const renderFormSubmissionResult = (
  isMessageSent,
  isMessageSentSuccessfully
) => {
  return (
    <div
      hidden={!isMessageSent}
      className={!isMessageSentSuccessfully ? css.failureMessage : ''}
    >
      {isMessageSentSuccessfully
        ? 'Thank you for contacting us. We will respond to you as soon as possible.'
        : 'Something went wrong. Please try again.'}
    </div>
  )
}

const makeSelectUser = () =>
  createSelector(
    (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC),
    (state) => (state.user ? state.user.data : undefined),
    (userRequest, userData) => {
      const { recaptchaKey, ...restUserData } = userData
      return {
        userRequest,
        userData: restUserData,
        recaptchaKey,
      }
    }
  )

export const PartnershipFrom = () => {
  const dispatch = useDispatch()
  const selectUser = useMemo(makeSelectUser, [])
  const { userRequest, userData, recaptchaKey } = useSelector((state) =>
    selectUser(state)
  )
  const [isFormSubmissionCompleted, setFormSubmissionCompletion] =
    useState(false)
  const [isMessageSentSuccessfully, setMessageSendingStatus] = useState(false)

  const { firstName, lastName } = userData ? userData : {}

  const initialFormValues = {
    firstName: !isEmpty(userData) ? firstName : '',
    lastName: !isEmpty(userData) ? lastName : '',
    email: '', // Don't auto fill the email
    recaptcha: '',
  }

  const handleFormSubmit = (values, setSubmitting) =>
    handlePartnershipFormSubmit(
      values,
      setSubmitting,
      dispatch,
      setMessageSendingStatus,
      setFormSubmissionCompletion
    )

  return (
    <div>
      {!userRequest.isLoaded ? (
        <StarLoader isVerticalMargin />
      ) : (
        <React.Fragment>
          {renderFormSubmissionResult(
            isFormSubmissionCompleted,
            isMessageSentSuccessfully
          )}

          <Formik
            initialValues={initialFormValues}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) =>
              handleFormSubmit(values, setSubmitting)
            }
          >
            {(props) => (
              <PartnershipInternalForm
                hidden={isFormSubmissionCompleted}
                recaptchaKey={recaptchaKey}
                {...props}
              />
            )}
          </Formik>
        </React.Fragment>
      )}
    </div>
  )
}
