import React from 'react'
import classNames from 'classnames'

import css from './card.scss'

class Card extends React.Component {
  render() {
    const { className, children, onClick, ...restProps } = this.props
    const cardClasses = classNames(className, css.card, {
      [css.cardClick]: Boolean(onClick),
    })

    return (
      <div className={cardClasses} onClick={onClick} {...restProps}>
        {children}
      </div>
    )
  }
}

export default Card
