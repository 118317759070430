import React from 'react'
import classNames from 'classnames'

import { Link } from 'pharmacy/src/navigation/link'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import ButtonIcon from './buttonIcon'

import css from './button.scss'

class Button extends React.Component {
  static defaultProps = {
    type: 'primary',
    shape: 'rectangle',
    size: 'medium',
    justifyChildren: 'center',
  }

  render() {
    const {
      children,
      className,
      forwardRef,
      icon,
      iconProps,
      isActive,
      isDisabled,
      isLoading,
      justifyChildren,
      onClick,
      type,
      shadow,
      shape,
      size,
      responsive,
      rightIcon,
      rightIconProps,
      tooltip,
      submitButton,
      ...linkProps
    } = this.props

    const usesLink =
      linkProps.external ||
      linkProps.pathname ||
      linkProps.hash ||
      linkProps.route

    let Component = 'div'
    let extraProps = {}

    if (!(isDisabled || isLoading)) {
      if (usesLink) {
        Component = Link
        extraProps = linkProps
      } else if (submitButton) {
        Component = 'button'
        extraProps = {
          type: 'submit',
        }
      }
    }

    const classes = classNames(
      className,
      css.button,
      css[`button_${type}`],
      css[`button_${size}`],
      css[`button_${shape}`],
      {
        [css.button_shadow]: shadow,
        [css[`button_${type}_active`]]: isActive,
        [css[`button_disabled`]]: isDisabled || isLoading,
        [css[`button_${type}_disabled`]]: isDisabled || isLoading,
      }
    )

    const childrenClasses = classNames(
      css.childrenContainer,
      css[`childrenContainer_${justifyChildren}`],
      { [css.tooltip]: tooltip }
    )

    return (
      <Component
        onClick={isDisabled ? null : onClick}
        className={classes}
        ref={forwardRef}
        disabled={isDisabled || isLoading}
        {...extraProps}
      >
        <div className={childrenClasses}>
          {icon && (
            <ButtonIcon
              {...iconProps}
              icon={icon}
              responsive={responsive}
              removeMargin={!children}
            />
          )}
          {isLoading && type !== 'text' ? (
            <StarLoader size="small" isVerticalMargin={false} wait={false} />
          ) : (
            children
          )}
          {rightIcon && (
            <ButtonIcon {...rightIconProps} icon={rightIcon} position="right" />
          )}
          {!tooltip ? null : <div className={css.tooltiptext}>{tooltip}</div>}
        </div>
      </Component>
    )
  }
}

export default Button
