import React from 'react'
import { connect } from 'react-redux'

import { Link } from 'pharmacy/src/navigation/link'

class TopicLink extends React.Component {
  render() {
    const { userData, topicObject, ...linkProps } = this.props
    const { topic, topicId } = topicObject

    const linkParams = userData ? { specialtyId: userData.specialtyId } : null

    return (
      <Link pathname={`/topic/${topicId}`} params={linkParams} {...linkProps}>
        {topic}
      </Link>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.data || {},
  }
}

export default connect(mapStateToProps)(TopicLink)
