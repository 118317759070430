import React from 'react'
import { connect } from 'react-redux'

import { Header1, Body1, Header4 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { QuestionCard } from 'pharmacy/src/question/questionCard'

import { ONBOARDING_MODAL } from 'mednet-util/src/constants/modal'
import { mixpanel } from 'mednet-util/src/tracking'

import css from './introScreen.scss'

class IntroScreen extends React.Component {
  componentDidMount() {
    mixpanel.track('viewed_onboarding _example')
  }

  handleContinue = () => {
    this.props.changeModalScreen(ONBOARDING_MODAL.screens.topics)
  }

  render() {
    const { onboardingQuestion } = this.props

    return (
      <div>
        <Header1>Welcome to theMednet</Header1>
        <Body1>
          theMednet shares best practices in a question and answer format
        </Body1>
        <Header4 className={css.exampleHeader}>
          Example of theMednet Q&A format:
        </Header4>
        <div className={css.exampleContainer}>
          <QuestionCard item={onboardingQuestion} />
        </div>
        <Header4 className={css.someThingsHeader}>
          Some things to know before getting started:
        </Header4>
        <ul>
          <li>
            theMednet is currently only open to practicing physicians, residents
            and fellows
          </li>
          <li>
            Anyone can ask a question and questions are directed to leaders in
            the field
          </li>
          <li>Content is moderated by our physician editorial team</li>
        </ul>
        <div className={css.buttonContainer}>
          <Button className={css.continueButton} onClick={this.handleContinue}>
            Continue
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { questionId } = state.user.data.onboardingQuestion
  return {
    onboardingQuestion: state.question.questions[questionId],
  }
}

export default connect(mapStateToProps)(IntroScreen)
