import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { closeModal, openModal } from '../reducers/modal'

export const useModalDrivers = (modalId) => {
  const dispatch = useDispatch()
  const open = useCallback(
    (data, screenName) => {
      dispatch(openModal(modalId, data, screenName))
    },
    [modalId]
  )
  const close = useCallback(() => {
    dispatch(closeModal(modalId))
  }, [modalId])

  const isOpen = useSelector(
    useCallback(
      createSelector(
        (state) => state.modal[modalId]?.isOpen,
        (isOpen) => isOpen
      )
    )
  )

  return [open, close, isOpen]
}
