import { createSelector } from 'reselect'
import _ from 'lodash'
import { createDataHook } from 'mednet-cns/src/hook-factory'

import { fetchCurriculums } from '../reducers/curriculum'

export const [useCurriculums, useCurriculumsLazy] = createDataHook(
  fetchCurriculums,
  createSelector(
    (state) => state.curriculum.curriculums,
    (curriculums) => _.orderBy(Object.values(curriculums), 'createDate', 'desc')
  )
)
