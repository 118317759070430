import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Icon } from 'pharmacy/src/display/icon'
import { Button } from 'pharmacy/src/input/button'
import { Link1 } from 'pharmacy/src/typography'

import { toggleFollowQuestion } from 'mednet-cns/src/reducers/question'

class FollowQuestionButton extends React.Component {
  static defaultProps = {
    type: 'icon',
  }

  handleClick = () => {
    const { isFollowed, toggleFollowQuestion } = this.props
    toggleFollowQuestion(isFollowed)
  }

  render() {
    const { isFollowed, type, stubbed, useStar, className } = this.props

    const followIcon = [
      isFollowed ? 'fas' : 'far',
      useStar ? 'star' : 'bookmark',
    ]
    const title = isFollowed
      ? 'Unstar this question to stop following updates'
      : 'Star this question to follow updates'

    if (stubbed) {
      return null
    }

    if (type === 'icon') {
      return <Icon icon={followIcon} onClick={this.handleClick} title={title} />
    }

    if (type === 'button') {
      return (
        <Link1 className={className}>
          <Button
            icon={followIcon}
            onClick={stubbed ? _.noop : this.handleClick}
            type="text"
          >
            {useStar
              ? isFollowed
                ? 'Unstar'
                : 'Star'
              : isFollowed
              ? 'Unsave'
              : 'Save'}
          </Button>
        </Link1>
      )
    }

    if (process.env.DEBUG) {
      // eslint-disable-next-line no-console
      console.error('unknown button type')
    }
    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFollowed } = state.question.questions[ownProps.questionId]

  return {
    isFollowed,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleFollowQuestion: (prevFollowedState) =>
      dispatch(
        toggleFollowQuestion(
          ownProps.questionId,
          ownProps.itemIndex,
          prevFollowedState
        )
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowQuestionButton)
