import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import Lottie from 'lottie-react'

// Had to use this instead of react-intersection-observer because of IE :(
// https://stackoverflow.com/questions/61970655/intersectionobserver-undefined-for-internet-explorer
import 'intersection-observer'

import { makeAssetURL } from 'mednet-util/src/router'
import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'

import css from './physicians.scss'

const ListItem = ({ isActive, icon, activeIcon, text, setActive }) => {
  return (
    <div
      className={classNames(css.listItemContainer, {
        [css.activeListItemContainer]: isActive,
      })}
      onClick={setActive}
    >
      <div className={css.listItemImage}>
        <img src={isActive ? activeIcon : icon} />
      </div>
      <div className={css.listItemText}>{text}</div>
      <div className={css.arrow}>
        <Icon icon={['fas', 'chevron-down']} />
      </div>
    </div>
  )
}

const ListItemPreview = React.forwardRef(
  ({ previewAnimation, previewImage, isActive, loopAnimation }, lottieRef) => {
    return (
      <>
        {previewAnimation && (
          <Lottie
            animationData={previewAnimation}
            loop={loopAnimation}
            className={isActive ? css.visibleImage : css.hiddenImage}
            lottieRef={lottieRef}
          />
        )}
        {!previewAnimation && (
          <img
            src={previewImage}
            className={
              isActive && !previewAnimation ? css.visibleImage : css.hiddenImage
            }
          />
        )}
      </>
    )
  }
)

ListItemPreview.displayName = 'ListItemPreview'

export const InternalPhysiciansSection = ({
  assetsPath,
  buttonClassName,
  multipleActive,
  listItems,
  titleClassName,
}) => {
  const [activeMap, setActiveMap] = useState(
    multipleActive
      ? Array(listItems.length).fill(true)
      : [true, ...Array(listItems.length - 1).fill(false)]
  )

  const lottieRefs = listItems.map(() => useRef())

  const [timeoutId, setTimeoutId] = useState()
  const [isVisible, setIsVisible] = useState(false)

  const visibilityThresholds = useMemo(() => [0.3, 0.6], [])

  const containerRef = useRef()
  const [entry, setEntry] = useState()

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries && entries.length && setEntry(entries[0])
      },
      {
        threshold: visibilityThresholds,
      }
    )
    observer.observe(containerRef.current)
  }, [])

  useEffect(() => {
    if (!multipleActive) {
      if (!entry) return
      if (entry.intersectionRatio <= visibilityThresholds[0]) {
        clearTimeout(timeoutId)
        setActiveMap([true, ...Array(listItems.length - 1).fill(false)])
        setIsVisible(false)
      } else if (
        entry.intersectionRatio >= visibilityThresholds[1] &&
        !isVisible
      ) {
        // if already visible, it is not convenient to do changes
        setIsVisible(true)
        setActiveMap([true, ...Array(listItems.length - 1).fill(false)])
        setIntervalToUpdateSingleActiveMap()
      }
    }
  }, [entry])

  const getTimeoutDuration = useCallback((activeIndex) => {
    return lottieRefs[activeIndex].current?.getDuration() * 1000 + 50 || 5000 // need default value because we display images not animations in IE
  }, [])

  const setRecursiveTimeout = () => {
    setActiveMap((activeArr) => {
      const lastActiveIndex = activeArr.findIndex((item) => item)
      const updatedMap = Array(listItems.length).fill(false)
      const nextIndex = (lastActiveIndex + 1) % listItems.length
      updatedMap[nextIndex] = true

      setTimeoutId(
        setTimeout(setRecursiveTimeout, getTimeoutDuration(nextIndex))
      )
      return updatedMap
    })
  }

  const setIntervalToUpdateSingleActiveMap = () => {
    // clear timeout id if already set
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(undefined)
    }

    if (!multipleActive) {
      const activeIndex = activeMap.findIndex((item) => item)
      const id = setTimeout(
        setRecursiveTimeout,
        getTimeoutDuration(activeIndex)
      )

      setTimeoutId(id)
    }
  }

  useEffect(() => {
    if (isVisible) {
      setIntervalToUpdateSingleActiveMap()
    }

    return clearTimeout(timeoutId)
  }, [listItems])

  const setActive = useCallback(
    (index) => () => {
      if (multipleActive) {
        const updatedActiveMap = [...activeMap]
        updatedActiveMap[index] = !updatedActiveMap[index]
        setActiveMap(updatedActiveMap)
      } else {
        const updatedActiveMap = Array(listItems.length).fill(false)
        updatedActiveMap[index] = true
        setActiveMap(updatedActiveMap)
        clearTimeout(timeoutId)
        setTimeoutId(undefined)
      }
    },
    [multipleActive, activeMap, setActiveMap, setTimeoutId, timeoutId]
  )

  const updateAnimationStatus = useCallback(() => {
    activeMap.forEach((isActive, index) => {
      lottieRefs[index].current?.stop()

      if (isActive) {
        lottieRefs[index].current?.play()
      }
    })
  }, [activeMap, lottieRefs])

  useEffect(updateAnimationStatus, [activeMap])

  return (
    <div className={css.physiciansContainer} ref={containerRef}>
      <img
        className={css.backgroundImage}
        src={makeAssetURL(`${assetsPath}for physician background.svg`)}
      />
      <div className={css.row}>
        <div className={classNames(css.column, css.listColumn)}>
          {/* Need inner div for IE */}
          <div className={titleClassName}>
            <div>FOR PHYSICIANS</div>
          </div>
          <div className={css.physiciansList}>
            {listItems.map((item, index) => (
              <div key={index} className={css.listItemWithPreviewContainer}>
                <ListItem
                  {...item}
                  isActive={activeMap[index]}
                  setActive={setActive(index)}
                />
                {multipleActive && (
                  <div
                    className={
                      activeMap[index]
                        ? css.visiblePreviewRow
                        : css.hiddenPreviewRow
                    }
                  >
                    <ListItemPreview
                      previewAnimation={item.previewAnimation}
                      previewImage={item.previewImage}
                      isActive={activeMap[index]}
                      ref={lottieRefs[index]}
                      loopAnimation
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={css.learnMoreButtonContainer}>
            <Link className={buttonClassName} pathname="/faq">
              Learn More
            </Link>
          </div>
        </div>
        {!multipleActive && (
          <div className={classNames(css.column, css.previewColumn)}>
            {/* Put the three images and change  opcity to get smooth transition*/}
            {listItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames({
                    [css.previewContainer]: activeMap[index],
                  })}
                >
                  <ListItemPreview
                    previewAnimation={item.previewAnimation}
                    previewImage={item.previewImage}
                    isActive={activeMap[index]}
                    ref={lottieRefs[index]}
                    loopAnimation={!timeoutId}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const PhysiciansSection = (props) => {
  const { assetsPath } = props
  const [animationData, setAnimationData] = useState()
  const [isPreviewDataLoaded, setIsPreviewDataLoaded] = useState(false)

  const listItems = useMemo(
    () => [
      {
        icon: makeAssetURL(`${assetsPath}cme-icon-outline.svg`),
        activeIcon: makeAssetURL(`${assetsPath}cme-icon-color.svg`),
        text: 'Efficiently search thousands of answers to controversial clinical questions',
        previewImage: makeAssetURL(`${assetsPath}search question.png`),
        previewAnimationUrl: makeAssetURL(
          `${assetsPath}search animation screen.json`
        ),
        previewAnimation: animationData && animationData[0],
      },
      {
        icon: makeAssetURL(`${assetsPath}question-icon-outline.svg`),
        activeIcon: makeAssetURL(`${assetsPath}question-icon-color.svg`),
        text: 'Get your questions answered by faculty from all major academic centers',
        previewImage: makeAssetURL(`${assetsPath}ask-faculty.png`),
        previewAnimationUrl: makeAssetURL(
          `${assetsPath}Question-Animation-Desktop.json`
        ),
        previewAnimation: animationData && animationData[1],
      },
      {
        icon: makeAssetURL(`${assetsPath}doctors-icon-outline.svg`),
        activeIcon: makeAssetURL(`${assetsPath}doctor-icon-color.svg`),
        text: 'Compare your practices to physicians at other institutions',
        previewImage: makeAssetURL(`${assetsPath}compare-institutions.png`),
        previewAnimationUrl: makeAssetURL(
          `${assetsPath}Poll-Animation-Desktop.json`
        ),
        previewAnimation: animationData && animationData[2],
      },
      {
        icon: makeAssetURL(`${assetsPath}cme-icon-outline.svg`),
        activeIcon: makeAssetURL(`${assetsPath}cme-icon-color.svg`),
        text: 'Earn CME and MOC credit for learning from experts',
        previewImage: makeAssetURL(`${assetsPath}CME credit.png`),
        previewAnimationUrl: makeAssetURL(`${assetsPath}cmeAnimation.json`),
        previewAnimation: animationData && animationData[3],
      },
    ],
    [animationData]
  )

  useEffect(async () => {
    if (typeof document === 'undefined' || document.documentMode) {
      // don't show animations for IE but show images
      setIsPreviewDataLoaded(true)
      return
    }
    const responses = await Promise.all(
      listItems
        .filter((item) => item.previewAnimationUrl)
        .map((item) => fetch(item.previewAnimationUrl))
    )

    const responsesData = await Promise.all(
      responses.map((response) => response.json())
    )

    let responseIndex = 0

    const itmesAnimationsData = listItems.map((item) => {
      if (item.previewAnimationUrl) {
        return responsesData[responseIndex++]
      }
      return undefined
    })

    setAnimationData(itmesAnimationsData)
    setIsPreviewDataLoaded(true)
  }, [])

  if (!isPreviewDataLoaded) {
    return null
  }

  return (
    <>
      <div className={css.displayForLargeScreen}>
        <InternalPhysiciansSection {...props} listItems={listItems} />
      </div>
      <div className={css.displayForSmallScreen}>
        <InternalPhysiciansSection
          {...props}
          multipleActive
          listItems={listItems}
        />
      </div>
    </>
  )
}
