export const actionIdByName = {
  POST: 1,
  ANSWER: 2,
  COMMENT: 3,
  ANSWER_UPDATED: 4,
  QUESTION_APPROVED: 5,
  POLL_APPROVED: 6,
  POLL_UPDATED: 7,
}

export const actionVerbNames = {
  [actionIdByName.POST]: 'Posted',
  [actionIdByName.ANSWER]: 'Answered',
  [actionIdByName.COMMENT]: 'Commented',
  [actionIdByName.ANSWER_UPDATED]: 'Updated answer',
  [actionIdByName.QUESTION_APPROVED]: 'Posted',
  [actionIdByName.POLL_APPROVED]: 'Posted poll',
  [actionIdByName.POLL_UPDATED]: 'Updated poll',
}
