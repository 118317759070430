export const digestStatus = {
  STATUS_SCHEDULED: 'SCHEDULED',
  STATUS_IN_PROGRESS: 'IN_PROGRESS',
  STATUS_COMPLETED: 'COMPLETED',
  STATUS_FAILED: 'FAILED',
  STATUS_SUSPENDED: 'SUSPENDED',
}

export const digestCohorts = {
  COHORT_A: 'COHORT_A',
  COHORT_B: 'COHORT_B',
}

export const digestCohortsLabel = {
  COHORT_A: 'Cohort A',
  COHORT_B: 'Cohort B',
}
