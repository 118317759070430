import React from 'react'

import ConfirmationScreen from './screens/confirmationScreen'
import Modal from './modal'

class ConfirmationModal extends React.Component {
  render() {
    return (
      <Modal {...this.props}>
        <ConfirmationScreen />
      </Modal>
    )
  }
}

export default ConfirmationModal
