import React from 'react'

import { Publication } from 'pharmacy/src/display/publication'
import { Subtitle1 } from 'pharmacy/src/typography'

import UserPublicationFeedItem from './userPublicationFeedItem'
import Feed from './feed'

import css from './userPublicationFeed.scss'

const PublicationItem = (props) => (
  <Publication {...props} TextComponent={Subtitle1} />
)

class UserPublicationFeed extends React.Component {
  render() {
    return (
      <Feed
        FeedItem={UserPublicationFeedItem}
        itemComponent={PublicationItem}
        feedURL="user/user/getPublicationFeedJSON"
        itemContainerClass={css.itemContainer}
        {...this.props}
      />
    )
  }
}

export default UserPublicationFeed
