import React from 'react'
import classNames from 'classnames'
import assign from 'lodash/assign'

import BaseSelect from './baseSelect'
import { SimpleSolidDropdownIndicator } from './components'

import css from './proximaNovaSelect.scss'

const STYLE_OVERRIDES = [
  'control',
  'indicatorSeparator',
  'dropdownIndicator',
  'clearIndicator',
  'menu',
  'singleValue',
  'valueContainer',
  'option',
  'indicatorsContainer',
  'input',
  'placeholder',
]

const NoOptionMessage = (props) => {
  const classes = classNames({
    [css.noOptionMessageContainer]: true,
    [css.noOptionMessageContainerCentered]: props.centered,
  })

  return <div className={classes}>No matches found</div>
}

const ProximaNovaSelect = (props) => {
  const { components = {}, centered, formInput, ...restProps } = props

  const classes = classNames({
    [css.proximaNovaSelect]: true,
    [css.proximaNovaCenteredSelect]: centered,
    [css.proximaNovaFormSelect]: formInput,
  })

  return (
    <BaseSelect
      className={classes}
      blurInputOnSelect
      classNamePrefix="react-select-contained"
      styleOverrides={STYLE_OVERRIDES}
      components={assign(components, {
        DropdownIndicator: SimpleSolidDropdownIndicator,
        NoOptionsMessage: () => NoOptionMessage(props),
      })}
      {...restProps}
    />
  )
}

export default ProximaNovaSelect
