import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, FastField, Form } from 'formik'
import * as yup from 'yup'

import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'

import { mixpanel } from 'mednet-util/src/tracking'

import { forgotPassword, resetPassword } from 'mednet-cns/src/reducers/user'

import { isEmpty } from 'lodash'

import { TitledSection } from 'components/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import css from './passwordRecovery.scss'

const recoveryFormSchema = yup.object().shape({
  email: yup.string().required('Required').email('Invalid email'),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const TextInputField = ({ name, touched, errors, ...restProps }) => (
  <FastField name={name}>
    {({ field }) => (
      <TextInput
        className={css.formField}
        {...field}
        {...restProps}
        typeStyle="proximaNovaPrimary"
        showClear={false}
        onChangeHandlesEvent
        validationTag={getValidationTag(field.name, touched, errors)}
      />
    )}
  </FastField>
)

const handleRecoveryFormSubmit = (
  values,
  setSubmitting,
  dispatch,
  setRecoveryFirstStepStatus,
  setErrors
) => {
  dispatch(
    forgotPassword(
      { ...values, distinct_id: mixpanel.get_distinct_id() },
      (res) => {
        setRecoveryFirstStepStatus(res.success)
        setSubmitting(false)
        if (!res.success) {
          setErrors({
            email: 'Invalid email',
          })
        }
      }
    )
  )
}

const RecoveryForm = (props) => {
  const { isSubmitting, touched, errors, handleSubmit, values, setErrors } =
    props

  const trackValidationFailure = useCallback(() => {
    if (!isEmpty(errors)) {
      mixpanel.track('Password Recovery Attempt - JS Validation Failure', {
        email: values.email,
        errors,
      })
    }
  }, [errors, values.email])

  useEffect(trackValidationFailure, [!isEmpty(errors) && errors])

  useEffect(() => {
    setErrors({})
  }, [values])

  return (
    <Form className={css.form}>
      <TextInputField
        name="email"
        placeholder="Email"
        tag="Required"
        touched={touched}
        errors={errors}
      />
      <Button
        type="orange"
        className={css.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        submitButton
      >
        <ProximaNova.Text2>Recover</ProximaNova.Text2>
      </Button>
    </Form>
  )
}

const PasswordRecoveryFirstStep = () => {
  const dispatch = useDispatch()
  const [recoveryFistStepStatus, setRecoveryFirstStepStatus] =
    useState(undefined)

  useEffect(() => {
    mixpanel.track('Password recovery page visit')
  }, [])

  const initialRecoveryFormValues = {
    email: '',
  }

  return (
    <TitledSection header2="Recover Password" headerUnderlined>
      {recoveryFistStepStatus ? (
        <div>
          Please check your email for instructions on how to reset your
          password.
        </div>
      ) : (
        <Formik
          initialValues={initialRecoveryFormValues}
          validationSchema={recoveryFormSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) =>
            handleRecoveryFormSubmit(
              values,
              setSubmitting,
              dispatch,
              setRecoveryFirstStepStatus,
              setErrors
            )
          }
        >
          {(props) => <RecoveryForm {...props} />}
        </Formik>
      )}
    </TitledSection>
  )
}

const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .min(4, 'Too short')
    .max(128, 'tooLong')
    .required('Required'),
  verifyPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const handleResetPasswordFormSubmit = (
  values,
  setSubmitting,
  dispatch,
  setRecoverySecondStepStatus
) => {
  dispatch(
    resetPassword(
      { ...values, distinct_id: mixpanel.get_distinct_id() },
      (res) => {
        setRecoverySecondStepStatus(res.success)
        setSubmitting(false)
      }
    )
  )
}

const ResetPasswordForm = (props) => {
  const { isSubmitting, touched, errors, handleSubmit, values, setErrors } =
    props

  const trackValidationFailure = useCallback(() => {
    if (!isEmpty(errors)) {
      mixpanel.track('Password reset - js validation failure', {
        email: values.email,
        errors,
      })
    }
  }, [errors, values.email])

  useEffect(trackValidationFailure, [!isEmpty(errors) && errors])

  useEffect(() => {
    setErrors({})
  }, [values])

  return (
    <Form className={css.form}>
      <FastField type="hidden" name="email" />
      <FastField type="hidden" name="activeKey" />
      <TextInputField
        name="password"
        placeholder="Password"
        tag="Required"
        touched={touched}
        errors={errors}
        isPassword
      />
      <TextInputField
        name="verifyPassword"
        placeholder="Retype Password"
        tag="Required"
        touched={touched}
        errors={errors}
        isPassword
      />
      <Button
        type="orange"
        className={css.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        submitButton
      >
        <ProximaNova.Text2>Save</ProximaNova.Text2>
      </Button>
    </Form>
  )
}

const ResetPasswordResult = ({ success }) => {
  if (success) {
    return (
      <div>
        Your password has been reset.{' '}
        <Link className={css.link} pathname="/">
          Login?
        </Link>
      </div>
    )
  }
  return <div className={css.failureMessage}>Incorrect recovery link.</div>
}

const PasswordRecoverySecondStep = ({ email, activeKey }) => {
  const dispatch = useDispatch()
  const [recoverySecondStepStatus, setRecoverySecondStepStatus] =
    useState(undefined)

  useEffect(() => {
    mixpanel.track('Password reset page visit', {
      email,
    })
  }, [])

  const initialResetPasswordFormValues = {
    email,
    activeKey,
    password: '',
    verifyPassword: '',
  }

  return (
    <TitledSection header2="Reset Password" headerUnderlined>
      {recoverySecondStepStatus !== undefined ? (
        <ResetPasswordResult success={recoverySecondStepStatus} />
      ) : (
        <Formik
          initialValues={initialResetPasswordFormValues}
          validationSchema={resetPasswordFormSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) =>
            handleResetPasswordFormSubmit(
              values,
              setSubmitting,
              dispatch,
              setRecoverySecondStepStatus
            )
          }
        >
          {(props) => <ResetPasswordForm {...props} />}
        </Formik>
      )}
    </TitledSection>
  )
}

export const PasswordRecovery = (props) => {
  const params = new URLSearchParams(props.location.search)
  const activeKey = params.get('activekey')
  const email = params.get('email')
  return (
    <LandingPageContent>
      <div className={css.mainContainer}>
        {email && activeKey ? (
          <PasswordRecoverySecondStep email={email} activeKey={activeKey} />
        ) : (
          <PasswordRecoveryFirstStep />
        )}
      </div>
    </LandingPageContent>
  )
}
