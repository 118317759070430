import React from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchUserProfile } from 'mednet-cns/src/reducers/user'

import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import { ButtonCheckbox } from 'pharmacy/src/input/button'
import { TagSearch } from 'pharmacy/src/display/tagSearch'

import css from './postOnBehalf.scss'

class PostOnBehalf extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingUserProfile: false,
      author: null,
      specialtyId: null,
    }
  }

  onAuthorChange = (author) => {
    this.setState({ loadingUserProfile: true, author: null, specialtyId: null })
    this.props.fetchUserProfile(author.userId, (response) => {
      const specialtyId = _.get(response, 'user.specialtyId')

      this.setState({
        loadingUserProfile: false,
        author: author || null,
        specialtyId,
      })
      this.props.onChange(author, specialtyId)
    })
  }

  onPostAsSomebodyElseToggle = () => {
    this.setState((prevState) => ({
      postAsSomebodyElse: !prevState.postAsSomebodyElse,
      author: null,
    }))
    this.props.onChange(null, null)
  }

  render() {
    const { author, postAsSomebodyElse, loadingUserProfile } = this.state
    const { checkboxProps } = this.props

    return (
      <div className={css.postAsSomebodyElse}>
        <ButtonCheckbox
          buttonProps={{
            isLoading: loadingUserProfile,
            className: css.postAsSomebodyElseButton,
          }}
          className={css.postAsSomebodyElseSwitch}
          unselectText="Post as:"
          selectText="Select to post on behalf of"
          inputProps={{
            onChange: this.onPostAsSomebodyElseToggle,
          }}
          {...checkboxProps}
        />
        {!postAsSomebodyElse ? null : (
          <TagSearch
            disabled={loadingUserProfile}
            index="user"
            name="author"
            onChange={this.onAuthorChange}
            value={author}
            isMulti={false}
            className={css.selectUser}
            hitsPerPage={10}
            openMenuOnClick
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: (userId, callback) =>
      dispatch(fetchUserProfile(userId, callback)),
  }
}

export default compose(
  withPermissions(authItems.postAsUser),
  connect(null, mapDispatchToProps)
)(PostOnBehalf)
