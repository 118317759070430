import React from 'react'
import ReactDOM from 'react-dom'
import createSagaMiddleware from 'redux-saga'
import { loadableReady } from '@loadable/component'
import createSentryMiddleware from 'redux-sentry-middleware'
import * as Sentry from '@sentry/browser'
import { Dedupe as DedupeIntegration } from '@sentry/integrations'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import { hot } from 'react-hot-loader/root'
import { includes } from 'lodash'

import { createAPI } from 'mednet-cns/src/api/v1'

import { reducers, rootSaga } from 'reducers'

import App from './app'
import { publicRoutes } from './constants/publicRoutes'

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.RELEASE_HASH,
    integrations: [new DedupeIntegration()],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })
}

createAPI({
  pathPrefix: process.env.DEBUG ? 'api/' : '',
  onLoginRedirect: (response) => {
    if (
      response &&
      response.url &&
      window.location.href !== response.url &&
      publicRoutes.every(
        (publicPath) =>
          !includes(window.location.href.toLowerCase(), publicPath)
      ) &&
      window.location.pathname &&
      window.location.pathname !== '/'
    ) {
      const redirectUrl = new URL(response.url)
      try {
        const fromUrl = window.location.pathname + window.location.search
        const redirectParams = new URLSearchParams(redirectUrl.search)
        if (fromUrl) {
          redirectParams.set('from', fromUrl)
        }
        redirectUrl.search = redirectParams
      } catch (e) {
        console.error(
          `Error parsing login redirect url: ${window?.location?.pathname}{window?.location?.search}\n${e}`
        )
      }

      window.location.href = redirectUrl.href
    }
  },
})

const HotApp = hot(App)
const history = createBrowserHistory()
const reducersWithHistory = reducers(history)
const sagaMiddleware = createSagaMiddleware()

const preloadedState = window.__PRELOADED_STATE__
const preloadedSession = window.__PRELOADED_SESSION__
delete window.__PRELOADED_STATE__
delete window.__PRELOADED_SESSION__

preloadedSession &&
  Object.entries(preloadedSession).forEach(([key, value]) => {
    sessionStorage.setItem(key, value)
  })

const store = createStore(
  reducersWithHistory,
  preloadedState,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      createSentryMiddleware(Sentry)
    )
  )
)
sagaMiddleware.run(rootSaga)

const hydrateOrRender = process.env.SSR ? ReactDOM.hydrate : ReactDOM.render

const render = () => {
  loadableReady(() => {
    hydrateOrRender(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HotApp history={history} />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root')
    )
  })
}

if (module.hot) {
  module.hot.accept('app', render)
  module.hot.accept('reducers', () => {
    store.replaceReducer(reducersWithHistory)
  })
}

render()
