import React from 'react'

import { Link } from 'pharmacy/src/navigation/link'

class BaseQuestionLink extends React.Component {
  static defaultProps = {
    LinkComponent: Link,
  }

  handleClick = (questionId) => {
    const { onClick } = this.props
    if (!onClick) {
      return null
    }

    return () => {
      onClick(questionId)
    }
  }

  render() {
    const { children, questionId, slug, onClick, LinkComponent, ...restProps } =
      this.props

    const pathname = slug ? `/${slug}` : `/question/${questionId}`

    return (
      <LinkComponent
        pathname={pathname}
        onClick={this.handleClick(questionId)}
        {...restProps}
      >
        {children}
      </LinkComponent>
    )
  }
}

export default BaseQuestionLink
