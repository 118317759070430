import React from 'react'
import algoliasearch from 'algoliasearch'
import { connect } from 'react-redux'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import AutoComplete from './questionAutoComplete'

class QuestionSearch extends React.Component {
  render() {
    const { userData, isLoaded, isError, ...restProps } = this.props

    if (!isLoaded || isError) {
      return null
    }

    const { algoliaApp, algoliaKey, algoliaNamespace } = userData
    const searchClient = algoliasearch(algoliaApp, algoliaKey)

    return (
      <InstantSearch
        indexName={`${algoliaNamespace}_question`}
        searchClient={searchClient}
      >
        <AutoComplete {...restProps} />

        <Configure highlightPreTag="<em>" highlightPostTag="</em>" />
        <Configure hitsPerPage={5} />
      </InstantSearch>
    )
  }
}

const mapStateToProps = (state) => {
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    isLoaded: userRequest.isLoaded,
    isError: userRequest.isError,
  }
}

export default connect(mapStateToProps)(QuestionSearch)
