import { createSelector } from 'reselect'

import { createDataHook } from 'mednet-cns/src/hook-factory'

import { fetchLatestAnnouncmentPublic } from 'mednet-cns/src/reducers/announcment'

export const [useLatestAnnouncement, useLatestAnnouncementLazy] =
  createDataHook(
    fetchLatestAnnouncmentPublic,
    createSelector(
      (state) => state.announcement.latestAnnouncement,
      (latestAnnouncement) => latestAnnouncement || {}
    )
  )
