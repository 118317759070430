import React from 'react'
import classNames from 'classnames'

import css from './page.scss'

class Page extends React.Component {
  render() {
    const { className } = this.props
    const pageClasses = classNames(css.page, className)

    return <div className={pageClasses}>{this.props.children}</div>
  }
}

export default Page
