import { mednetAPI } from 'mednet-cns/src/api/v1'

export class JwtTokenHandler {
  static refreshTokenPromise = undefined

  static async refreshJwtToken() {
    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = mednetAPI('user/login/refreshJwtTokenJSON')
    }

    await this.refreshTokenPromise
    this.refreshTokenPromise = undefined
  }
}
