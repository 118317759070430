import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { formatName } from 'mednet-util/src/string'
import { IMAGE_SIZE } from 'mednet-util/src/router'
import { Header2, Subtitle1, Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { UserImage } from 'pharmacy/src/user/userImage'

import { TagSearch } from 'pharmacy/src/display/tagSearch'
import {
  fetchSuggestedUsers,
  shareQuestion,
} from 'mednet-cns/src/reducers/question'
import { shareAnswer } from 'mednet-cns/src/reducers/answer'
import { SHARE_MODAL } from 'mednet-util/src/constants/modal'

import css from './shareModelScreen.scss'

class ShareModelScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
    this.state = {
      value: [],
    }
  }

  componentDidMount() {
    this.props.fetchSuggestedUsers()
  }

  onChange = (value) => {
    this.setState({ value: value || [] })
  }

  onSuggestedUserClick = (user) => {
    return () => {
      const { value } = this.state

      this.setState({
        value: [...value, user],
      })
    }
  }

  onShare = () => {
    this.props.shareObject(this.form.current)
    this.props.changeModalScreen(SHARE_MODAL.screens.finished)
  }

  render() {
    const { suggestedUsers, answerId, itemIndex } = this.props
    const { value } = this.state

    const shareModel = answerId ? 'answer' : 'question'

    return (
      <form ref={this.form}>
        <div className={css.container}>
          <div>
            <Header2>Share {_.capitalize(shareModel)}</Header2>
            <hr />
            <Subtitle3 className={css.subtitle}>
              Enter the name or email address of the person you’d like to share
              this {shareModel} with
            </Subtitle3>
            <TagSearch index="user" onChange={this.onChange} value={value} />
          </div>
          {suggestedUsers && (
            <div className={css.suggestedUsersContainer}>
              <Subtitle3>
                Suggested people to share this {shareModel} with
              </Subtitle3>
              <div className={css.suggestedUsersList}>
                {suggestedUsers.map((suggestedUser) => {
                  const { firstName, middleName, lastName, userId } =
                    suggestedUser

                  return (
                    <div
                      className={css.suggestedUser}
                      key={userId}
                      onClick={this.onSuggestedUserClick(suggestedUser)}
                    >
                      <UserImage
                        imageSize={IMAGE_SIZE.SMALL}
                        className={css.suggestedUserImage}
                        userId={userId}
                      />
                      <Subtitle1>
                        {formatName(firstName, middleName, lastName)}
                      </Subtitle1>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {itemIndex !== undefined && (
            <input name="itemPosition" value={itemIndex + 1} type="hidden" />
          )}
          <div className={css.buttonContainer}>
            <Button type="neutral" onClick={this.props.closeModal}>
              Cancel
            </Button>
            <Button
              className={css.shareButton}
              isDisabled={!value.length}
              onClick={this.onShare}
            >
              Share
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { suggestedUsers } = state.question.questions[ownProps.questionId] || {}

  return {
    suggestedUsers:
      suggestedUsers &&
      suggestedUsers.map((userId) => state.user.users[userId]),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { questionId, answerId } = ownProps

  const shareFunction = answerId ? shareAnswer : shareQuestion
  const shareId = answerId || questionId

  return {
    fetchSuggestedUsers: () => dispatch(fetchSuggestedUsers(questionId)),
    shareObject: (form) => dispatch(shareFunction(shareId, form)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModelScreen)
