import React from 'react'
import classNames from 'classnames'

import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'

import css from './menuHeader.scss'

class MenuHeader extends React.Component {
  render() {
    const {
      HeaderComponent,
      header,
      children,
      isOpen,
      headerComponentProps,
      displayIcon = true,
      IconElement,
      className,
      ...innerProps
    } = this.props

    const iconClasses = classNames(css.icon, {
      [css.icon_active]: isOpen,
    })

    if (children) {
      return (
        <HeaderComponent
          {...innerProps}
          {...headerComponentProps}
          isActive={isOpen}
          className={className}
        >
          {header}
          {displayIcon && !IconElement && (
            <Icon className={iconClasses} icon={['fal', 'arrow-down']} />
          )}
          {displayIcon && IconElement && <IconElement rotated={isOpen} />}
        </HeaderComponent>
      )
    }

    return (
      <Link {...innerProps}>
        <HeaderComponent className={className}>{header}</HeaderComponent>
      </Link>
    )
  }
}

export default MenuHeader
