import React, { useCallback, useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'

import { DropdownMenuGroup, MenuChild } from 'pharmacy/src/input/dropdownMenu'
import { Icon } from 'pharmacy/src/display/icon'

import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'
import { CME_PAGE_VISIT_SOURCE } from 'mednet-util/src/constants/cme'
import { mixpanel } from 'mednet-util/src/tracking'

import {
  useCmeAndMocAvailabilityCheck,
  useTrackCmePageVisit,
} from 'mednet-cns/src/hooks/cme'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import { getAboutNavigationLinksHierarchy } from 'anterior-util/aboutNavigationLinksHierarchy'

import HeaderLink from './headerLink'

import css from './headerDropdown.scss'

const EditProfilePage = withPermissions(authItems.editProfile)(() => (
  <MenuChild>{EXTERNAL_LINKS.editProfile}</MenuChild>
))

const MailboxPage = withPermissions(authItems.manageMailbox)(() => (
  <MenuChild>{EXTERNAL_LINKS.mailbox}</MenuChild>
))

const ModeratorPage = withPermissions(authItems.moderator)(() => (
  <MenuChild>{EXTERNAL_LINKS.moderatorPage}</MenuChild>
))

const AssociateLeaderboardPage = withPermissions(
  authItems.viewAssociateLeaderboard
)(() => <MenuChild>{EXTERNAL_LINKS.associateLeaderboard}</MenuChild>)

const AdminPage = withPermissions(authItems.admin)(() => (
  <MenuChild>{EXTERNAL_LINKS.admin}</MenuChild>
))

const CmeLink = ({ location }) => {
  const { isCmeOrMocAvailableForUser, isMocEnabledForUser } =
    useCmeAndMocAvailabilityCheck()
  const trackCmePageVisit = useTrackCmePageVisit()

  const handleClick = useCallback(() => {
    if (location.pathname === '/cme') {
      return
    }

    trackCmePageVisit(CME_PAGE_VISIT_SOURCE.SOURCE_MENU)
    mixpanel.track('viewed_redeem_cme_form_page', {
      source: CME_PAGE_VISIT_SOURCE.SOURCE_MENU,
    })
  }, [location.pathname, trackCmePageVisit])

  if (!isCmeOrMocAvailableForUser) {
    return null
  }

  return (
    <MenuChild>
      {{
        label: isMocEnabledForUser ? 'Redeem CME & MOC' : 'Redeem CME',
        pathname: ROUTES[PAGES.CME_DASHBOARD].path,
        external: false,
        onClick: handleClick,
      }}
    </MenuChild>
  )
}

const DropDownIconElement = ({ rotated }) => {
  return (
    <Icon
      className={classNames(css.dropDownIcon, {
        [css.dropDownRotatedIcon]: rotated,
      })}
      icon={['fas', 'chevron-down']}
    />
  )
}

const HeaderDropdown = (props) => {
  const { className, isGuest, history } = props

  const [currentLocation, setCurrentLocation] = useState({
    pathname: history.location.pathname,
    hash: history.location.hash,
  })

  const getActiveClassName = useCallback(
    (targetPathname, targetHash) => {
      return currentLocation.pathname === targetPathname &&
        (((!currentLocation.hash || currentLocation.hash === '#') &&
          !targetHash) ||
          currentLocation.hash === `#${targetHash}`)
        ? css.activePage
        : undefined
    },
    [currentLocation]
  )

  const getInternalPageLink = useCallback(
    (page) => {
      return {
        label: page,
        pathname: ROUTES[page].path,
        external: false,
        labelClassName: getActiveClassName(ROUTES[page].path),
      }
    },
    [getActiveClassName]
  )

  useEffect(() => {
    if (props.history) {
      const unlistenToLocation = props.history.listen(() => {
        setCurrentLocation({
          pathname: history.location.pathname,
          hash: history.location.hash,
        })
      })

      return unlistenToLocation
    }

    return () => {}
  }, [])

  const aboutUsMenu = useMemo(
    () => getAboutNavigationLinksHierarchy(getInternalPageLink, isGuest),
    [isGuest, getInternalPageLink]
  )

  const headerMenu = useMemo(() => {
    let menu = undefined

    if (isGuest) {
      menu = [
        ...aboutUsMenu,
        {
          header: 'Contact Us',
          pathname: ROUTES[PAGES.CONTACT_US].path,
          external: false,
          className: getActiveClassName(ROUTES[PAGES.CONTACT_US].path),
        },
        {
          header: 'Sign In',
          pathname: ROUTES[PAGES.LOGIN].path,
          external: false,
          className: classNames(
            css.signIn,
            getActiveClassName(ROUTES[PAGES.LOGIN].path)
          ),
        },
      ]
    } else {
      menu = [
        { header: 'About', children: aboutUsMenu },
        {
          header: 'Topics',
          pathname: '/topic/index',
          external: true,
        },
        {
          header: 'My Account',
          children: [
            <MailboxPage key="mailboxPage" />,
            <EditProfilePage key="editProfilePage" />,
            <CmeLink key="cmePage" location={history.location} />,
            EXTERNAL_LINKS.customizeFeed,
            <ModeratorPage key="moderatorPage" />,
            <AssociateLeaderboardPage key="associateLeaderboardPage" />,
            <AdminPage key="adminPage" />,
            <hr key="hr" />,
            getInternalPageLink(PAGES.CONTACT_US),
            <hr key="hr2" />,
            EXTERNAL_LINKS.logout,
          ],
        },
      ]
    }

    return menu
  }, [isGuest, getInternalPageLink, getActiveClassName])

  return (
    <DropdownMenuGroup
      HeaderComponent={HeaderLink} // depending on isGeust pass headerComponentProps
      className={className}
      menu={headerMenu}
      isNarrow
      history={history}
      fullWidthItemsInSmallScreen={isGuest}
      displayIcon={isGuest}
      IconElement={DropDownIconElement}
    />
  )
}

export default HeaderDropdown
