import React, { useMemo } from 'react'

import { SimpleSelect } from 'pharmacy/src/input/select'

const ColumnSelectFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    options.add('All')
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return Array.from(options).map((value) => ({
      value,
      label: value,
    }))
  }, [id, preFilteredRows])

  const selectedOption =
    options.find((option) => option.value === filterValue) || options[0]

  return (
    <SimpleSelect
      value={selectedOption}
      options={options}
      onChange={({ value }) => {
        setFilter(value === 'All' ? undefined : value)
      }}
    />
  )
}

export default ColumnSelectFilter
