import React from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import css from './baseModal.scss'

export default class BaseModal extends React.Component {
  static defaultProps = {
    closeOnBackdrop: true,
    size: 'medium',
  }

  componentDidUpdate(prevProps) {
    // this nastiness is necessary for iOS scrolling
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.disableScrolling()
      } else {
        this.enableScrolling()
      }
    }
  }

  componentWillUnmount() {
    this.enableScrolling()
    if (this.props.isOpen) this.props.closeModal()
  }

  disableScrolling() {
    this.lastScrollTop =
      document.body.scrollTop || document.documentElement.scrollTop
    document.documentElement.style.overflow = 'hidden'
    document.documentElement.style.height = '100%'
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100%'
    document.body.scrollTop = this.lastScrollTop
    document.documentElement.scrollTop = this.lastScrollTop
  }

  enableScrolling() {
    document.body.style.overflow = ''
    document.body.style.height = ''
    document.documentElement.style.overflow = ''
    document.documentElement.style.height = ''
    document.documentElement.scrollTop = this.lastScrollTop
    document.body.scrollTop = this.lastScrollTop
  }

  lastScrollTop = 0

  render() {
    const {
      isOpen,
      closeOnBackdrop,
      closeModal,
      children,
      contentClassName,
      size,
    } = this.props

    const contentClasses = classNames(
      css.content,
      contentClassName,
      css[`content_${size}`]
    )

    return (
      <CSSTransition
        classNames={{
          enter: css.container_enter,
          enterActive: css.container_enter_active,
          enterDone: css.container_enter_done,
          exit: css.container_exit,
          exitActive: css.container_exit_active,
        }}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        timeout={150}
      >
        <div className={css.container}>
          {isOpen && <div className={contentClasses}>{children}</div>}
          <div
            className={css.backdrop}
            onClick={closeOnBackdrop ? closeModal : null}
          />
        </div>
      </CSSTransition>
    )
  }
}
