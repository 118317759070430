import React, { useCallback, useEffect, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import { Icon } from 'pharmacy/src/display/icon'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Link } from 'pharmacy/src/navigation/link'
import { Header3 } from 'pharmacy/src/typography'

import { mixpanel } from 'mednet-util/src/tracking'

import { useRedemptionRelatedQuestions } from 'mednet-cns/src/hooks/cme'

import css from './redemptionRelatedQuestions.scss'

export const RedmptionRelatedQuestions = ({
  redeemedCmeActivitiesIds = [], // Normally should be zero or equal to moc redeemed activities.
  redeemedMocActivitiesIds = [], // Normally should be zero or equal to cme redeemed activities.
  /* 
    This is for explaing the difference betwen CME & MOC credits, in case it happended.
    Normally it should not. This may happen if the user did a strange behaviour like openning
    the redeemed page in a new tab in browser, redeeming MOC, and going back to this one.
  */
  discripancyExplainingMessage,
  creditsPerActivity,
  onExit,
  showAsCard,
}) => {
  const [redemptionRelatedQuestions, redemptionRelatedQuestionsRequest] =
    useRedemptionRelatedQuestions(
      isEmpty(redeemedCmeActivitiesIds)
        ? redeemedMocActivitiesIds
        : redeemedCmeActivitiesIds
    )

  useEffect(() => {
    if (redemptionRelatedQuestionsRequest.isLoaded) {
      if (isEmpty(redemptionRelatedQuestions)) {
        mixpanel.track('No CME Related Questions')
      } else {
        redemptionRelatedQuestions.forEach((question, index) => {
          mixpanel.track('Viewed CME Related Question', {
            questionId: question.questionId,
            order: index + 1,
          })
        })
      }
    }
  }, [redemptionRelatedQuestionsRequest.isLoaded])

  const trackQuestionClick = useCallback(
    (questionId, index) => () => {
      mixpanel.track('Clicked CME Related Question', {
        questionId,
        order: index + 1,
      })
    },
    []
  )

  const redeemedCreditsText = useMemo(() => {
    if (redeemedMocActivitiesIds.length === 0) {
      return (
        <>
          <span className={css.credits}>
            {redeemedCmeActivitiesIds.length * creditsPerActivity}
          </span>{' '}
          CME credit
        </>
      )
    } else if (redeemedCmeActivitiesIds.length === 0) {
      return (
        <>
          <span className={css.credits}>
            {redeemedMocActivitiesIds.length * creditsPerActivity}
          </span>{' '}
          MOC credit
        </>
      )
    } else if (
      redeemedMocActivitiesIds.length === redeemedCmeActivitiesIds.length
    ) {
      return (
        <>
          <span className={css.credits}>
            {redeemedCmeActivitiesIds.length * creditsPerActivity}{' '}
          </span>
          CME and MOC credit
        </>
      )
    }
    return (
      <>
        <span className={css.credits}>
          {redeemedCmeActivitiesIds.length * creditsPerActivity}{' '}
        </span>
        CME credit and{' '}
        <span className={css.credits}>
          {redeemedMocActivitiesIds.length * creditsPerActivity}
        </span>{' '}
        MOC credit
      </>
    )
  }, [
    redeemedCmeActivitiesIds.length,
    redeemedMocActivitiesIds.length,
    creditsPerActivity,
  ])

  return (
    <div className={classNames({ [css.cardContainer]: showAsCard })}>
      {showAsCard && (
        <Icon icon="times" className={css.closeIcon} onClick={onExit} />
      )}
      <Header3 className={css.header}>
        You have successfully redeemed {redeemedCreditsText}!
      </Header3>

      {!isEmpty(redemptionRelatedQuestions) && (
        <p>
          Thank you for providing additional reflection on how this activity
          helps your practice moving forward.
        </p>
      )}

      {discripancyExplainingMessage && <p>{discripancyExplainingMessage}</p>}

      {redemptionRelatedQuestionsRequest.isLoading && <StarLoader />}

      {redemptionRelatedQuestionsRequest.isLoaded &&
        !isEmpty(redemptionRelatedQuestions) && (
          <>
            <p>
              For additional learning related to your reflected activities,
              please consider the following Q&A that you may find useful:
            </p>
            <ul
              className={css.questionsList}
              style={{ paddingInlineStart: '25px' }} // Needed to use it inline, even using !important did not work
            >
              {redemptionRelatedQuestions.map((question, index) => (
                <li key={question.questionId}>
                  <Link
                    pathname={`/question/${question.questionId}`}
                    external={false}
                    target="_blank"
                    className={css.questionLink}
                    onClick={trackQuestionClick(question.questionId, index)}
                    search={{ src: 'cme_redeem_related', pos: index + 1 }}
                  >
                    {question.question}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}

      {!isEmpty(redeemedMocActivitiesIds) && (
        <p className={css.footer}>
          MOC credit will be submitted to your board by the University of
          Chicago at the end of the calendar year.
        </p>
      )}
    </div>
  )
}
