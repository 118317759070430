import React from 'react'

import { PageHeader, Header1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'

import css from './errorPage.scss'

class ErrorDefault extends React.Component {
  render() {
    const { homepageProps, backProps, history } = this.props

    return (
      <div>
        <PageHeader>
          <Icon icon="bug" /> Oops.
        </PageHeader>
        <Header1>Something went wrong.</Header1>
        <div className={css.buttonContainer}>
          <Button type="neutral" {...backProps(history)}>
            Go back
          </Button>
          <Button {...homepageProps(history)}>Go to homepage</Button>
        </div>
      </div>
    )
  }
}

export default ErrorDefault
