import React from 'react'

import colors from 'pharmacy/src/style/colors'

/* eslint-disable */
class Star extends React.Component {
  static defaultProps = {
    color: colors.colorBlue,
  }

  render() {
    const { color, ...restProps } = this.props

    return (
      <div {...restProps}>
        <svg width="100%" height="100%" viewBox="0 0 500 500" version="1.1">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="mednetlogo-vector" fill={color}>
              <g id="_2289390479664">
                <polygon
                  id="Path"
                  points="67.0230263 433.07977 0 316.92023 115.748355 250 0 183.182566 67.0230263 66.9202303 182.874178 133.840461 182.874178 0 317.023026 0 317.023026 133.840461 432.874178 66.9202303 500 183.182566 384.148849 250 500 316.92023 432.874178 433.07977 317.023026 366.262336 317.023026 500 182.874178 500 182.874178 366.262336"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}

export default Star
