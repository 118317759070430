import React, { useCallback, useEffect, useState } from 'react'
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder'
import { debounce } from 'lodash'

import { useQueryBuilderConfig } from './config'

import * as css from './queryBuilder.scss'

function QueryBuilder({ onChange, initialTree, queryUserStatus }) {
  // You can load query value from your backend storage (for saving see `Query.onChange()`)
  const queryValue = initialTree || { id: QbUtils.uuid(), type: 'group' }
  const config = useQueryBuilderConfig(queryUserStatus)

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config,
  })

  const notifyAboutChange = useCallback(
    (immutableTree) => {
      onChange && onChange(config, immutableTree)
    },
    [config, onChange]
  )

  useEffect(() => {
    notifyAboutChange(
      QbUtils.checkTree(
        QbUtils.loadTree(initialTree || { id: QbUtils.uuid(), type: 'group' }),
        config
      )
    )
  }, [initialTree])

  const handleChange = useCallback(
    debounce((immutableTree, config) => {
      setState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config,
      }))

      notifyAboutChange(immutableTree)
    }, 200),
    []
  )

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container">
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  )

  return (
    <Query
      {...config}
      value={state.tree}
      onChange={handleChange}
      renderBuilder={renderBuilder}
      className={css.cls}
    />
  )
}

export default QueryBuilder
