import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import { FETCH_NOTIFICATION_USAGE } from 'mednet-cns/src/reducers/question'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { FETCH_UPDATE_IN_NOTIFICATION_USAGE } from 'mednet-cns/src/reducers/questionUpdate'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Subtitle1, Subtitle2 } from 'pharmacy/src/typography'
import { IconWithPopup } from 'pharmacy/src/display/iconWithPopup'

import { ErrorPage } from 'pages/error'

import * as css from './lastUsedInfo.scss'

export const INFO_TYPES = {
  QUESTION: 'question',
  UPDATE: 'update',
}

class LastUsedInfo extends React.Component {
  static defaultProps = {
    type: INFO_TYPES.QUESTION,
  }

  trimDate = (dateStr) => dateStr.substring(0, dateStr.length - 9)

  typeText = () => _.upperFirst(this.props.type)

  renderInfo() {
    const { notifications } = this.props || {}
    const { digests, newsletters } = notifications || {
      digests: [],
      newsletters: [],
    }

    if (!digests.length && !newsletters.length) {
      return null
    }

    return (
      <div>
        {!digests.length ? null : (
          <div>
            <Subtitle1>Last 5 digests</Subtitle1>
            {digests.map((notification, i) => (
              <Subtitle2 key={i} className={css.row}>
                {notification.created}
              </Subtitle2>
            ))}
          </div>
        )}
        {!newsletters.length ? null : (
          <div>
            <Subtitle1>Last 5 newsletters</Subtitle1>
            {newsletters.map((notification, i) => (
              <Subtitle2 key={i} className={css.row}>
                {notification.created}
              </Subtitle2>
            ))}
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      lastUsedIsLoaded,
      lastUsedIsError,
      errorStatus,
      notifications,
      mode,
      className,
    } = this.props

    let text = ''

    if (lastUsedIsError) {
      return <ErrorPage errorStatus={errorStatus} />
    }

    if (!lastUsedIsLoaded) {
      return <StarLoader size="small" isVerticalMargin={false} />
    }

    if (
      !notifications ||
      (_.isEmpty(notifications.digests) && _.isEmpty(notifications.newsletters))
    ) {
      return null
    }
    const newestNewsletter = notifications.newsletters[0] || {}
    const newestDigest = notifications.digests[0] || {}

    const newsletterDateStr = newestNewsletter.created
    const digestDateStr = newestDigest.created

    const newsletterDate = newsletterDateStr
      ? new Date(newsletterDateStr)
      : null
    const digestDate = digestDateStr ? new Date(digestDateStr) : null

    if (mode === 'newsletter') {
      if (newsletterDate) {
        text = `${this.typeText()} previously used in newsletter on ${this.trimDate(
          newsletterDateStr || ''
        )}`
      } else {
        text = 'Not used in any newsletter before'
      }
    } else if (mode === 'dailydigest') {
      if (digestDate) {
        text = `${this.typeText()} previously used in digest on ${this.trimDate(
          digestDateStr || ''
        )}`
      } else {
        text = 'Not used in any digest before'
      }
    } else if (digestDate < newsletterDate) {
      text = `${this.typeText()} previously used in newsletter on ${this.trimDate(
        newsletterDateStr || ''
      )}`
    } else {
      text = `${this.typeText()} previously used in digest on ${this.trimDate(
        digestDateStr || ''
      )}`
    }

    return (
      <IconWithPopup type="info" text={text} className={className}>
        {this.renderInfo()}
      </IconWithPopup>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId, updateId, type, specialtyId } = ownProps
  const lastUsedRequest =
    _.get(state.request.requests, makeRequestName(FETCH_NOTIFICATION_USAGE)) ||
    {}
  const lastUsedUpdateRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_UPDATE_IN_NOTIFICATION_USAGE)
    ) || {}

  const question = state.question.questions[questionId] || {}
  const questionNotifications = question.notifications
    ? question.notifications[specialtyId]
    : {}

  const updateNotifications = state.questionUpdate.notifications[specialtyId]
    ? state.questionUpdate.notifications[specialtyId][updateId] || {}
    : {}

  let obj

  if (type === INFO_TYPES.QUESTION) {
    obj = {
      notifications: questionNotifications,
      lastUsedIsLoaded: lastUsedRequest.isLoaded,
      lastUsedIsError: lastUsedRequest.isError,
      errorStatus: lastUsedRequest.errorStatus,
    }
  } else if (type === INFO_TYPES.UPDATE) {
    obj = {
      notifications: updateNotifications,
      lastUsedIsLoaded: lastUsedUpdateRequest.isLoaded,
      lastUsedIsError: lastUsedUpdateRequest.isError,
      errorStatus: lastUsedUpdateRequest.errorStatus,
    }
  }

  return obj
}

export const LastUsedInfoConnected = connect(mapStateToProps)(LastUsedInfo)
