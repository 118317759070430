import React from 'react'

import TextInput from 'pharmacy/src/input/textInput/textInput'

const ColumnTextFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <TextInput
      type="slim"
      value={filterValue || ''}
      onChange={(value) => {
        setFilter(value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
ColumnTextFilter.displayName = 'ColumnTextFilter'

export default ColumnTextFilter
