import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import { Link } from 'pharmacy/src/navigation/link'
import StoryModeIcon from 'pharmacy/src/program/icons/storyModeIcon'
import StoryModeHint from 'pharmacy/src/program/storyModeHint'

import { getSponsorshipStoryModePath } from 'mednet-util/src/sponsorship'

import { mixpanel } from 'mednet-util/src/tracking'

import classes from './programStoryModeButton.scss'

const STORY_MODE_HINT_SESSION_KEY = 'showedStoryModeHintInFeed'
const STORY_MODE_HINT_HIDE_TIMEOUT = 4000

export default function ProgramStoryModeButton({
  sponsorship,
  isVisible,
  questionId,
  trackingProps,
}) {
  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    if (!isVisible) {
      return () => {}
    }
    const showedAutoHint =
      window &&
      window.sessionStorage &&
      window.sessionStorage.getItem(STORY_MODE_HINT_SESSION_KEY) === 'true'

    if (!showedAutoHint) {
      setShowHint(true)
      window.sessionStorage.setItem(STORY_MODE_HINT_SESSION_KEY, 'true')

      const hideHint = setTimeout(() => {
        setShowHint(false)
      }, STORY_MODE_HINT_HIDE_TIMEOUT)

      return () => {
        clearTimeout(hideHint)
      }
    }

    return () => {}
  }, [isVisible])

  return (
    <div className={classes.buttonContainer}>
      <div
        onMouseOver={() => setShowHint(true)}
        onMouseLeave={() => setShowHint(false)}
      >
        <Link
          pathname={getSponsorshipStoryModePath(
            sponsorship.sponsorshipId,
            questionId
          )}
          external
          target="_blank"
          rel="noopener noreferrer"
          className={classes.button}
          onClick={() => {
            mixpanel.track('clicked_to_view_question_in_program_story_mode', {
              sponsorship_id: sponsorship.sponsorshipId,
              sponsorship_topic: sponsorship.topic,
              sponsorship_public_name: sponsorship.publicName,
              sponsorship_description: sponsorship.description,
              program_type: sponsorship.programType,
              question_id: questionId,
              ...trackingProps,
            })
          }}
        >
          <StoryModeIcon />
        </Link>
      </div>

      <div
        className={classNames(classes.hintContainer, {
          [classes.hidden]: !showHint,
        })}
      >
        <StoryModeHint />
      </div>
    </div>
  )
}
