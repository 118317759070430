import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { ConfirmationScreen, Modal } from 'pharmacy/src/display/modal'
import { SPONSORSHIP_CREATE_MODAL } from 'mednet-util/src/constants/modal'
import { Header1 } from 'pharmacy/src/typography'
import { FETCH_SPONSORS, fetchSponsors } from 'mednet-cns/src/reducers/sponsor'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { getRequest } from 'mednet-cns/src/api/v1'
import { createSponsorship } from 'mednet-cns/src/reducers/sponsorship'

import {
  FETCH_SPECIALTIES,
  fetchSpecialties,
  getSpecialties,
} from 'mednet-cns/src/reducers/specialty'

import {
  FETCH_SUBSPECIALTIES,
  fetchSubspecialties,
  getSubSpecialties,
} from 'mednet-cns/src/reducers/subspecialty'

import {
  FETCH_JOURNALS,
  fetchJournals,
  getJournals,
} from 'mednet-cns/src/reducers/journal'

import SponsorshipForm, { SponsorshipSchema } from '../forms/sponsorshipForm'

class FinishedScreen extends React.Component {
  render() {
    const { closeModal } = this.props

    return (
      <ConfirmationScreen
        header="Sponsorship created"
        closeModal={closeModal}
      />
    )
  }
}

class CreateScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchSponsors()
    this.props.fetchSpecialties()
    this.props.fetchSubspecialties()
    this.props.fetchJournals()
  }

  handleSubmit = (values, actions) => {
    const { createSponsorship, changeModalScreen } = this.props

    const formData = new FormData(this.form.current)
    formData.set('infoText', values.infoText)
    createSponsorship(formData, () => {
      actions.setSubmitting(false)
      changeModalScreen(SPONSORSHIP_CREATE_MODAL.screens.finished)
    })
  }

  render() {
    const {
      sponsorsIsLoading,
      specialtiesIsLoading,
      subspecialtiesIsLoading,
      journalsIsLoading,
    } = this.props

    if (
      sponsorsIsLoading ||
      specialtiesIsLoading ||
      subspecialtiesIsLoading ||
      journalsIsLoading
    ) {
      return <StarLoader />
    }

    return (
      <div>
        <Header1>Create sponsorship</Header1>
        <hr />
        <Formik
          initialValues={{
            description: '',
            sponsor: null,
            topic: null,
            topics: [],
            emblem: false,
            emblemText: '',
            useCustomInfo: false,
            infoText: '',
            experts: [],
            editor: null,
            dates: {},
            releaseDate: new Date(),
            isPaid: false,
          }}
          onSubmit={this.handleSubmit}
          validationSchema={SponsorshipSchema}
        >
          {(props) => (
            <SponsorshipForm
              {...props}
              {...this.props}
              formRef={this.form}
              submitText="Create"
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const sponsorsRequest = getRequest(state, FETCH_SPONSORS)
  const specialtiesRequest = getRequest(state, FETCH_SPECIALTIES)
  const specialties = Object.values(getSpecialties(state))
  const subspecialtiesRequest = getRequest(state, FETCH_SUBSPECIALTIES)
  const subspecialties = Object.values(getSubSpecialties(state))
  const journalsRequest = getRequest(state, FETCH_JOURNALS)
  const journals = Object.values(getJournals(state))

  return {
    sponsors: state.sponsor.sponsors,
    sponsorsIsLoading: sponsorsRequest.isLoading === true,
    specialties,
    specialtiesIsLoading: specialtiesRequest.isLoading === true,
    subspecialties,
    subspecialtiesIsLoading: subspecialtiesRequest.isLoading === true,
    journals,
    journalsIsLoading: journalsRequest.isLoading === true,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSponsors: () => dispatch(fetchSponsors()),
    createSponsorship: (formData, callback) =>
      dispatch(createSponsorship(formData, callback)),
    fetchSpecialties: () => dispatch(fetchSpecialties()),
    fetchSubspecialties: () => dispatch(fetchSubspecialties()),
    fetchJournals: () => dispatch(fetchJournals()),
  }
}

const ConnectedCreateScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateScreen)

const screens = {
  [SPONSORSHIP_CREATE_MODAL.screens.create]: ConnectedCreateScreen,
  [SPONSORSHIP_CREATE_MODAL.screens.finished]: FinishedScreen,
}

class CreateSponsorshipModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        defaultScreen={SPONSORSHIP_CREATE_MODAL.screens.create}
        modalId={SPONSORSHIP_CREATE_MODAL.modalId}
        size="large"
        screens={screens}
      />
    )
  }
}

export default CreateSponsorshipModal
