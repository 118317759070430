import React from 'react'
import classNames from 'classnames'

import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import css from './userProfileUpdateNavigation.scss'

// Note: This Navigation is made to be the same as the legacy php navigation in mednet-web/src/mednet/protected/modules/user/views/profile/_navigation.php
export const UserProfileUpdateNavigation = ({
  userId,
  location,
  isMocAvailableForUser,
}) => {
  const profileLinks = [
    {
      label: 'My Profile',
      path: `/user/user/view/id/${userId}`,
      external: false,
    },
    {
      label: 'Edit Account Details',
      path: '/user/profile/edit',
      external: true,
    },
    isMocAvailableForUser && {
      label: 'Edit CME & MOC details',
      path: '/user/mocProfile',
      external: false,
    },
    {
      label: 'Edit Work Details',
      path: `/user/user/view/id/${userId}?tab=Work-History`,
      external: false,
    },
    {
      label: 'Change Password',
      path: `/user/profile/changepassword`,
      external: true,
    },
    {
      label: 'Update Profile Picture',
      path: '/user/profile/updatePicture',
      external: true,
    },
    {
      label: 'Email Preferences',
      path: '/notificationPermissions/update',
      external: true,
    },
    { label: 'Feed Settings', path: '/following/topics', external: true },
  ]

  const pathname = location.pathname

  return (
    <div className={css.profileNavigationContainer}>
      <div className={css.profileNavigationContent}>
        {profileLinks.map((profileLink, index) => {
          if (!profileLink) {
            return undefined
          }

          return (
            <div
              key={index}
              className={classNames(css.navLink, {
                [css.activeNavLink]: pathname === profileLink.path,
              })}
            >
              {pathname === profileLink.path && (
                <Icon icon={['fas', 'chevron-left']} />
              )}
              <Link pathname={profileLink.path} external={profileLink.external}>
                {profileLink.label}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}
