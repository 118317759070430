import React, { useState, useMemo } from 'react'
import { Formik, FastField, Form } from 'formik'
import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import Recaptcha from 'react-google-recaptcha'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography'
import { ProximaNovaSelect } from 'pharmacy/src/input/select'
import { CenteredContent } from 'pharmacy/src/display/content'

import { formatName } from 'mednet-util/src/string'

import { sendContactMessage } from 'mednet-cns/src/reducers/contact'
import { FETCH_USER_WITH_PERMISSIONS_PUBLIC } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import { TitledSection } from 'components/titledSection'
import { LandingPageContent } from 'components/landingPageContent'

import css from './contact.scss'

const schema = yup.object().shape({
  name: yup.string().max(70, 'Too long').required('Required'),
  email: yup
    .string()
    .email('Invalid email')
    .max(128, 'Too long')
    .required('Required'),
  companyName: yup.string().max(255, 'Too long'),
  companyType: yup.string().max(32, 'Too long'),
  message: yup.string(),
  recaptcha: yup.string().required('It is required to pass the reCAPTCHA test'),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const TextInputField = ({ name, touched, errors, ...restProps }) => (
  <FastField name={name}>
    {({ field }) => (
      <TextInput
        className={css.formField}
        {...field}
        {...restProps}
        typeStyle="proximaNovaPrimary"
        showClear={false}
        onChangeHandlesEvent
        validationTag={getValidationTag(field.name, touched, errors)}
      />
    )}
  </FastField>
)

const CompanyTypeSelectField = () => {
  const companyTypeOptions = [
    { value: 'Physician Practice' },
    { value: 'Academia' },
    { value: 'Non-Profit' },
    { value: 'Healthcare Industry' },
    { value: 'Other' },
  ]

  return (
    <FastField name="companyType">
      {({ field, form }) => (
        <div className={css.formField}>
          <ProximaNovaSelect
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.value}
            placeholder="Company Type"
            options={companyTypeOptions}
            onChange={(option) => form.setFieldValue(field.name, option.value)}
            onBlur={field.onBlur}
            isSearchable={false}
            formInput
          />
        </div>
      )}
    </FastField>
  )
}

const RecaptchaVerificationField = ({
  recaptchaKey,
  name,
  touched,
  errors,
}) => (
  <FastField name={name}>
    {({ field, form }) => (
      <div className={css.recaptcha}>
        <Recaptcha
          sitekey={recaptchaKey}
          onChange={(value) => {
            form.setFieldValue(field.name, value)
          }}
        />
        <div
          className={css.recaptchaRequiredMessage}
          hidden={!errors[name] || !touched[name]}
        >
          {errors.recaptcha}
        </div>
      </div>
    )}
  </FastField>
)

const handleContactFormSubmit = (
  values,
  setSubmitting,
  dispatch,
  setMessageSendingStatus,
  setFormSubmissionCompletion
) => {
  dispatch(
    sendContactMessage(values, (res) => {
      setMessageSendingStatus(res.success)
      setFormSubmissionCompletion(true)
      setSubmitting(false)
    })
  )
}

const ContactUsForm = (props) => {
  const {
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    hidden,
    disableBasicFields,
    recaptchaKey,
  } = props

  return (
    <Form className={css.contactForm} hidden={hidden}>
      <TextInputField
        name="name"
        placeholder="Name"
        tag="Required"
        disabled={disableBasicFields}
        touched={touched}
        errors={errors}
      />

      <TextInputField
        name="email"
        placeholder="Email"
        tag="Required"
        disabled={disableBasicFields}
        touched={touched}
        errors={errors}
      />

      <TextInputField
        name="companyName"
        placeholder="Company Name"
        disabled={disableBasicFields}
        touched={touched}
        errors={errors}
      />

      <CompanyTypeSelectField />

      <FastField name="message">
        {({ field }) => (
          <textarea {...field} placeholder="Message" className={css.message} />
        )}
      </FastField>

      <RecaptchaVerificationField
        name="recaptcha"
        recaptchaKey={recaptchaKey}
        touched={touched}
        errors={errors}
      />

      <Button
        type="orange"
        className={css.button}
        onClick={handleSubmit}
        isLoading={isSubmitting}
      >
        <ProximaNova.Text2>Send Message</ProximaNova.Text2>
      </Button>
    </Form>
  )
}

const renderFormSubmissionResult = (
  isMessageSent,
  isMessageSentSuccessfully
) => {
  return (
    <div
      hidden={!isMessageSent}
      className={!isMessageSentSuccessfully ? css.failureMessage : ''}
    >
      {isMessageSentSuccessfully
        ? 'Thank you for contacting us. We will respond to you as soon as possible.'
        : 'Something went wrong. Please try again.'}
    </div>
  )
}

const makeSelectUser = () =>
  createSelector(
    (state) => getRequest(state, FETCH_USER_WITH_PERMISSIONS_PUBLIC),
    (state) => (state.user ? state.user.data : undefined),
    (userRequest, userData) => {
      const { recaptchaKey, ...restUserData } = userData
      return {
        userRequest,
        userData: restUserData,
        recaptchaKey,
      }
    }
  )

export const Contact = () => {
  const dispatch = useDispatch()
  const selectUser = useMemo(makeSelectUser, [])
  const { userRequest, userData, recaptchaKey } = useSelector((state) =>
    selectUser(state)
  )
  const [isFormSubmissionCompleted, setFormSubmissionCompletion] =
    useState(false)
  const [isMessageSentSuccessfully, setMessageSendingStatus] = useState(false)

  const { firstName, middleName, lastName, institutionName, emailAddress } =
    userData ? userData : {}

  const initialFormValues = {
    name: !isEmpty(userData) ? formatName(firstName, middleName, lastName) : '',
    email: emailAddress ? emailAddress : '',
    companyName: institutionName ? institutionName : '',
    companyType: '',
    message: '',
    recaptcha: '',
  }

  const handleFormSubmit = (values, setSubmitting) =>
    handleContactFormSubmit(
      values,
      setSubmitting,
      dispatch,
      setMessageSendingStatus,
      setFormSubmissionCompletion
    )

  return (
    <LandingPageContent>
      <CenteredContent className={css.mainContainer}>
        <TitledSection header2="Contact Us" headerUnderlined>
          {!userRequest.isLoaded ? (
            <StarLoader isVerticalMargin />
          ) : (
            <React.Fragment>
              {renderFormSubmissionResult(
                isFormSubmissionCompleted,
                isMessageSentSuccessfully
              )}

              <Formik
                initialValues={initialFormValues}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) =>
                  handleFormSubmit(values, setSubmitting)
                }
              >
                {(props) => (
                  <ContactUsForm
                    hidden={isFormSubmissionCompleted}
                    disableBasicFields={!isEmpty(userData)}
                    recaptchaKey={recaptchaKey}
                    {...props}
                  />
                )}
              </Formik>
            </React.Fragment>
          )}
        </TitledSection>
      </CenteredContent>
    </LandingPageContent>
  )
}
