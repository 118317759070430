import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { QUESTION_REQUEST_MODAL } from 'mednet-util/src/constants/modal'

import ConfirmationScreen from './confirmationScreen'
import MoreAnswersScreen from './moreAnswersScreen'
import UpdatedAnswersScreen from './updatedAnswersScreen'
import RequestTypeScreen from './requestTypeScreen'

const screens = {
  [QUESTION_REQUEST_MODAL.screens.confirmation]: ConfirmationScreen,
  [QUESTION_REQUEST_MODAL.screens.moreAnswers]: MoreAnswersScreen,
  [QUESTION_REQUEST_MODAL.screens.updatedAnswers]: UpdatedAnswersScreen,
  [QUESTION_REQUEST_MODAL.screens.requestType]: RequestTypeScreen,
}

class QuestionRequestModal extends React.Component {
  render() {
    return <Modal modalId={QUESTION_REQUEST_MODAL.modalId} screens={screens} />
  }
}

export default QuestionRequestModal
