import React from 'react'

import TabContext from './tabContext'

const getFirstTabIndex = (tabs) => {
  return tabs
    .map((tab, index) => ({
      tab,
      index,
    }))
    .filter((obj) => obj.tab.show !== false)
    .map((obj) => obj.index)[0]
}

class TabsRoot extends React.Component {
  constructor(props) {
    super(props)

    const { activeTab: active } = props
    const activeTab = Array.isArray(active) ? active[0] : active

    this.state = {
      activeTab: activeTab || getFirstTabIndex(props.tabs),
    }
  }

  componentDidMount() {
    const { onTabChange } = this.props

    const { activeTab: active } = this.props
    const activeTab = Array.isArray(active) ? active[0] : active

    if (onTabChange) {
      onTabChange(activeTab)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onTabChange } = this.props
    const { activeTab: active } = this.props
    const activeTab = Array.isArray(active) ? active[0] : active

    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        activeTab,
      })
    } else if (onTabChange && prevState.activeTab !== this.state.activeTab) {
      onTabChange(activeTab)
    }
  }

  handleTabChange = (activeTab) => {
    this.setState({
      activeTab,
    })
  }

  render() {
    const { tabs, isInRouter, children } = this.props
    const { activeTab } = this.state

    return (
      <TabContext.Provider
        value={{
          tabs,
          isInRouter,
          activeTab,
          onTabChange: this.handleTabChange,
        }}
      >
        {children}
      </TabContext.Provider>
    )
  }
}

export default TabsRoot
