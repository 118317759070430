import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_LATEST_ANNOUNCEMENT_PUBLIC =
  'announcement/FETCH_LATEST_ANNOUNCEMENT_PUBLIC'

export function fetchLatestAnnouncmentPublic(callback) {
  return {
    type: FETCH_LATEST_ANNOUNCEMENT_PUBLIC,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_LATEST_ANNOUNCEMENT_PUBLIC,
    'announcement/getLatestAnnouncementPublicJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  latestAnnouncement: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_LATEST_ANNOUNCEMENT_PUBLIC): {
      return receiveReducer(state, action, () => ({
        ...state,
        latestAnnouncement: action.response,
      }))
    }

    default:
      return state
  }
}
