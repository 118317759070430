import React from 'react'
import _ from 'lodash'

import { jobType } from 'mednet-util/src/constants/job'
import { Button } from 'pharmacy/src/input/button'
import { Subtitle3 } from 'pharmacy/src/typography'

import UserJob from './userJob'

import css from './userWorkHistory.scss'

class UserWorkHistory extends React.Component {
  render() {
    const { jobs, showEdit } = this.props

    return (
      <div>
        {_.get(jobs, jobType.JOB, []).map((job, index) => (
          <div key={index} className={css.jobContainer}>
            <UserJob job={job} showEdit={showEdit} />
          </div>
        ))}
        {showEdit && (
          <Subtitle3>
            <Button
              type="secondaryText"
              pathname="/job/createWork"
              icon="plus"
              external
            >
              Add a position
            </Button>
          </Subtitle3>
        )}
      </div>
    )
  }
}

export default UserWorkHistory
