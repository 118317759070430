import _ from 'lodash'
import QueryString from 'qs'

import { PROGRAM_PUBLISH_STATUS } from './constants/sponsorship'

export const getLatestPublishedSponsorship = (sponsorships) => {
  const publishedSponsorships = sponsorships?.filter(
    (sponsorship) =>
      sponsorship.publishedToProgramsCollection ===
        PROGRAM_PUBLISH_STATUS.PUBLISHED && !_.isEmpty(sponsorship.publicName)
  )

  return publishedSponsorships?.length
    ? _.orderBy(publishedSponsorships, ['endDate'], ['desc'])[0]
    : undefined
}

export const getSponsorshipStoryModePath = (
  sponsorshipId,
  questionId = null
) => {
  if (questionId) {
    return `/v2/programs/${sponsorshipId}/questions?mode=question&questionId=${questionId}`
  }

  return `/v2/programs/${sponsorshipId}`
}

export const getProgramCollectionPath = (programType, filters) => {
  if (!programType) {
    return `/v2/programs`
  }

  return `/v2/programs/collection?${QueryString.stringify(
    {
      programType,
      ...filters,
    },
    { indices: false }
  )}`
}
