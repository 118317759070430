import React from 'react'

import { Page } from 'pharmacy/src/display/page'
import { CenteredContent } from 'pharmacy/src/display/content'

import { Button } from 'pharmacy/src/input/button'

import SystemJobsTable from 'components/tables/systemJobsTable'

import * as css from './systemJobs.scss'

const SystemJobsPage = () => {
  return (
    <Page className={css.page}>
      <CenteredContent size="xlarge">
        <div className={css.actions}>
          <Button icon="plus" pathname="/campaign/new">
            Add new campaign
          </Button>
          <Button icon="plus" pathname="/invitation/new">
            Add new invite campaign
          </Button>
        </div>
        <SystemJobsTable />
      </CenteredContent>
    </Page>
  )
}

export default SystemJobsPage
