import React from 'react'
import { withRouter } from 'react-router-dom'

import { Page } from 'pharmacy/src/display/page'

import ErrorDefault from './errorDefault'
import Error404 from './error404'

import css from './errorPage.scss'

const ERROR_HANDLERS = {
  404: Error404,
  400: Error404,
}

class ErrorPage extends React.Component {
  render() {
    const { errorStatus } = this.props

    const ErrorHandler = ERROR_HANDLERS[errorStatus] || ErrorDefault

    return (
      <Page>
        <div className={css.container}>
          <ErrorHandler {...this.props} />
        </div>
      </Page>
    )
  }
}

export default withRouter(ErrorPage)
