import React from 'react'
import _ from 'lodash'

import { Button } from 'pharmacy/src/input/button'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Subtitle2 } from 'pharmacy/src/typography'
import { TagSearch } from 'pharmacy/src/display/tagSearch'

import css from './createQuestionForm.scss'

class CreateQuestionForm extends React.Component {
  handleSelectChange = (fieldName) => (selected) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched(fieldName)
    setFieldValue(fieldName, selected)
  }

  handleAuthorChange = (hit) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched('author')
    setFieldValue('author', hit.userId)
  }

  handleTextChange = (fieldName) => (val) => {
    const { setFieldValue } = this.props

    setFieldValue(fieldName, val)
  }

  render() {
    const {
      closeModal,
      errors,
      formRef,
      handleSubmit,
      isSubmitting,
      touched,
      values,
    } = this.props

    const submitIsDisabled = _.isEmpty(touched) || !_.isEmpty(errors)

    return (
      <form className={css.form} ref={formRef}>
        <div className={css.inputContainer}>
          <Subtitle2>Question:</Subtitle2>
          <TextInput
            name="question"
            showClear={false}
            onChange={this.handleTextChange('question')}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>Detail:</Subtitle2>
          <TextInput
            name="detail"
            showClear={false}
            onChange={this.handleTextChange('detail')}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>Topic</Subtitle2>
          <TagSearch
            index="topic"
            isMulti
            name="topics[]"
            value={values.topics}
            creatable
            onChange={this.handleSelectChange('topics')}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>Author:</Subtitle2>
          <TagSearch
            index="user"
            name="author"
            isMulti={false}
            onChange={this.handleAuthorChange}
          />
        </div>
        <div className={css.buttonsContainer}>
          <Button onClick={closeModal} type="neutral">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={submitIsDisabled}
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

export default CreateQuestionForm
