import React from 'react'

import { getFeedNameKey } from 'mednet-util/src/feed'

import { default as StatefulFeedItem } from './statefulFeedItem'

class UserActivityFeedItem extends React.Component {
  render() {
    const { feedName, feedKey } = this.props

    return (
      <StatefulFeedItem
        objectPath="question.updates"
        indexPath={`feed.feeds[${getFeedNameKey(feedName, feedKey)}].items`}
        {...this.props}
      />
    )
  }
}

export default UserActivityFeedItem
