import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { mixpanel } from 'mednet-util/src/tracking'

import {
  FETCH_USER_PROFILE,
  FETCH_USER_WITH_PERMISSIONS,
  fetchUserProfile,
  inviteUser,
} from 'mednet-cns/src/reducers/user'
import { formatName, roundStringNumber } from 'mednet-util/src/string'
import { IMAGE_SIZE } from 'mednet-util/src/router'
import { userStatus, statName } from 'mednet-util/src/constants/user'
import { Page } from 'pharmacy/src/display/page'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  Header3,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  PageHeader,
} from 'pharmacy/src/typography'
import { RouterTabs, TabButton } from 'pharmacy/src/navigation/tabs'
import { UserImageContainer } from 'pharmacy/src/user/userImage'
import { UserDescription } from 'pharmacy/src/user/userDescription'
import { FollowUserButton } from 'pharmacy/src/user/followUserButton'
import { Button } from 'pharmacy/src/input/button'
import { RawBody } from 'pharmacy/src/display/rawBody'
import {
  UserActivityFeed,
  UserPublicationFeed,
} from 'pharmacy/src/display/feed'
import { getRequest } from 'mednet-cns/src/api/v1'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import UserTraining from './userTraining'
import UserWorkHistory from './userWorkHistory'

import css from './user.scss'

const UserAdminButton = withPermissions(authItems.admin)(({ userId }) => (
  <div className={css.editButtonContainer}>
    <Subtitle3>
      <Button
        pathname={`/user/user/admin/id/${userId}`}
        icon="user-edit"
        type="secondaryText"
        external
      >
        Manage
      </Button>
    </Subtitle3>
  </div>
))

class UserPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.profileIsLoaded) {
      props.fetchUserProfile()
    }
  }

  mixpanelTrackNextPageStart = () => {
    mixpanel.track('viewed_publication_input', {
      source: 'updating',
    })
  }

  onTabChange = (activeTab) => {
    // Mixpanel
    const title = this.getTabs()[activeTab].header.props.title
    let tab_title = 'activity'
    if (title === 'Training & Education') tab_title = 'education'
    else if (title.includes('Publications')) tab_title = 'publications'
    else if (title === 'Work History') tab_title = 'work'

    mixpanel.track('viewed_profile', {
      tab_viewed: tab_title,
      profile_id: this.getTabs()[activeTab].component.props.feedKey,
    })
  }

  getTabs = () => {
    const { userData, selfUserId, showEdit } = this.props
    const { userId, hasActivity, numPublications, jobs } = userData
    const isSelfPage = userId === selfUserId

    return [
      {
        header: <TabButton title="Recent Activity" />,
        component: (
          <UserActivityFeed
            feedName="userActivity"
            feedKey={userId}
            feedParams={{ userId }}
            initialLoaderProps={{ wait: false }}
          />
        ),
        show: hasActivity,
      },
      {
        header: <TabButton title="Training & Education" />,
        component: (
          <UserTraining jobs={jobs} showEdit={isSelfPage && showEdit} />
        ),
      },
      {
        header: <TabButton title={`Publications (${numPublications})`} />,
        component: (
          <div>
            {isSelfPage && showEdit && (
              <Subtitle3 className={css.updatePublications}>
                <Button
                  icon="sync"
                  external
                  pathname="/publication/updatePubs"
                  type="secondaryText"
                  onClick={this.mixpanelTrackNextPageStart}
                >
                  Update Publications
                </Button>
              </Subtitle3>
            )}
            <UserPublicationFeed
              feedName="publications"
              feedKey={userId}
              feedParams={{ userId }}
              initialLoaderProps={{ wait: false }}
            />
          </div>
        ),
      },
      {
        header: <TabButton title="Work History" />,
        component: (
          <UserWorkHistory jobs={jobs} showEdit={isSelfPage && showEdit} />
        ),
      },
    ]
  }

  renderButtons = () => {
    const { showMessage, selfUserId, userData, inviteUser } = this.props

    const { userId, status, isInvited, deceasedMessage } = userData
    const isSelfPage = userId === selfUserId

    if (isSelfPage || deceasedMessage) {
      return null
    }

    if (status === userStatus.UNREGISTERED) {
      return (
        <Button
          type="secondary"
          size="small"
          icon={['fal', isInvited ? 'check' : 'user-plus']}
          onClick={inviteUser}
          isDisabled={isInvited}
        >
          {isInvited ? 'Invitation Sent' : 'Invite'}
        </Button>
      )
    } else if (status === userStatus.UNREGISTERED_NO_EMAIL_LICENSE) {
      return null
    }

    return (
      <div className={css.buttonsContainer}>
        <FollowUserButton
          userId={userId}
          callLocation="profile"
          buttonProps={{
            type: 'secondary',
            size: 'small',
            className: [css.followButton, css.followButtonPrint].join(' '),
          }}
        />
        {showMessage && (
          <Button
            type="secondary"
            size="small"
            external
            pathname={`/message/compose/${userId}`}
            icon={['fal', 'envelope']}
          >
            Send Message
          </Button>
        )}
      </div>
    )
  }

  renderSubspecialties = () => {
    const { userData } = this.props
    const { subspecialties } = userData

    if (!subspecialties || !subspecialties.length) {
      return null
    }

    return (
      <div>
        <hr className={css.separator} />
        <Subtitle2 className={css.subspecialtyLabel}>Subspecialties</Subtitle2>
        <div className={css.subspecialtiesContainer}>
          {subspecialties.map((subspecialty) => (
            <Subtitle2
              key={subspecialty.subspecialtyId}
              className={css.subspecialty}
            >
              {subspecialty.subspecialty}
            </Subtitle2>
          ))}
        </div>
      </div>
    )
  }

  renderStat = (title, stat) => {
    if (stat === 0) {
      return null
    }

    return (
      <div className={css.statContainer}>
        <PageHeader className={css.statNum}>
          {roundStringNumber(stat)}
        </PageHeader>
        <Subtitle2 className={css.statTitle}>{title}</Subtitle2>
      </div>
    )
  }

  renderStats = () => {
    const { userData } = this.props
    const { stats } = userData
    if (!stats) {
      return null
    }
    const { numAnswers, numViews, numReach } = stats

    if ((numAnswers === 0 && numViews === 0, numReach === 0)) {
      return null
    }

    return (
      <div>
        <hr className={css.separator} />
        <div className={css.statsContainer}>
          {this.renderStat(statName.ANSWERS, numAnswers)}
          {this.renderStat(statName.VIEWS, numViews)}
          {this.renderStat(statName.REACH, numReach)}
        </div>
      </div>
    )
  }

  renderDeceasedMessage = () => {
    const { userData } = this.props
    const { deceasedMessage } = userData

    if (!deceasedMessage) {
      return null
    }

    return (
      <Subtitle1 className={css.deceasedContainer}>{deceasedMessage}</Subtitle1>
    )
  }

  render() {
    const { userData, profileIsLoaded, selfUserId, showEdit, userIsLoaded } =
      this.props

    if (!profileIsLoaded || !userIsLoaded) {
      return <StarLoader isFullScreen />
    }

    const { userId, bio, firstName, lastName, middleName, specialty } = userData
    const isSelfPage = userId === selfUserId

    return (
      <Page className={css.page}>
        <Helmet>
          <title>{formatName(firstName, middleName, lastName)}</title>
        </Helmet>
        <div className={css.content}>
          <div className={css.userContainer}>
            {this.renderDeceasedMessage()}
            {isSelfPage && showEdit && (
              <div className={css.editButtonContainer}>
                <Subtitle3>
                  <Button
                    pathname="/user/profile/edit"
                    icon="edit"
                    type="secondaryText"
                    external
                  >
                    Edit
                  </Button>
                </Subtitle3>
              </div>
            )}
            {showEdit && <UserAdminButton userId={userId} />}
            <UserImageContainer
              user={userData}
              imageSize={IMAGE_SIZE.HEADER}
              highlightIfExpert
            >
              <div>
                <UserDescription
                  user={userData}
                  theme="extended"
                  NameComponent={PageHeader}
                  useUserNameLink={false}
                  highlightIfExpert
                />
                <Subtitle1 className={css.specialty}>
                  {specialty?.specialty}
                </Subtitle1>
                {this.renderButtons()}
              </div>
            </UserImageContainer>
          </div>
          {this.renderStats()}
          {this.renderSubspecialties()}
          {bio && (
            <div>
              <hr className={css.separator} />
              <Header3>Bio</Header3>
              <RawBody body={bio} />
            </div>
          )}
        </div>
        <div className={css.bottomContainer}>
          <div className={css.content}>
            <RouterTabs tabs={this.getTabs()} onTabChange={this.onTabChange} />
          </div>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps.match.params
  const userProfileRequest = getRequest(state, FETCH_USER_PROFILE, userId)
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    selfUserId: _.get(state.user, 'data.userId'),
    userData: state.user.users[userId] || {},
    profileIsLoaded: userProfileRequest.isLoaded === true,
    userIsLoaded: userRequest.isLoaded === true,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { userId } = ownProps.match.params

  return {
    fetchUserProfile: () => dispatch(fetchUserProfile(userId)),
    inviteUser: () => dispatch(inviteUser(userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
