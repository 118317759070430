import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeadersFromParams } from 'mednet-cns/src/api/v1'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_PRESETS = 'preset/FETCH_PRESETS'
export const CREATE_PRESET = 'preset/CREATE_PRESET'
export const DELETE_PRESET = 'preset/DELETE_PRESET'

export function fetchPresets(callback) {
  return {
    type: FETCH_PRESETS,
    callback,
  }
}

export function createPreset(preset, callback) {
  return {
    type: CREATE_PRESET,
    callback,
    preset,
  }
}

export function deletePreset(presetId, callback) {
  return {
    type: DELETE_PRESET,
    callback,
    presetId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_PRESETS,
    makeURL(`preset/getPresetsJSON`)
  )

  yield makeFetchEffect(
    takeLatest,
    CREATE_PRESET,
    makeURL('preset/createJSON'),
    (action) => makePOSTHeadersFromParams(action.preset)
  )

  yield makeFetchEffect(takeLatest, DELETE_PRESET, (action) =>
    makeURL(`preset/deleteJSON/${action.presetId}`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  presets: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(CREATE_PRESET): {
      return receiveReducer(state, action, () => ({
        presets: [...state.presets, action.response.preset],
      }))
    }

    case receiveAction(DELETE_PRESET): {
      return receiveReducer(state, action, () => ({
        presets: state.presets.filter(
          (preset) => preset.presetId !== action.presetId
        ),
      }))
    }

    case receiveAction(FETCH_PRESETS): {
      const { presets } = action.response

      return receiveReducer(state, action, () => ({
        presets,
      }))
    }

    default:
      return state
  }
}
