import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
} from '../cns-util/reducer'
import { makePOSTHeaders } from '../api/v1'

export const SAVE_PREFERENCES = 'jobPreference/SAVE_PREFERENCES'
export const SAVE_SUMMARY = 'jobPreference/SAVE_SUMMARY'
export const GET_PREFERENCES = 'jobPreference/GET_PREFERENCES'

export function savePreferences(formData, callback) {
  return {
    type: SAVE_PREFERENCES,
    formData,
    callback,
  }
}

export function saveSummary(formData, callback) {
  return {
    type: SAVE_SUMMARY,
    formData,
    callback,
  }
}

export function getPreferences() {
  return {
    type: GET_PREFERENCES,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    SAVE_PREFERENCES,
    makeURL('jobPreference/savePreferences'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    SAVE_SUMMARY,
    makeURL('jobPreference/saveSummary'),
    (action) => makePOSTHeaders(action.formData)
  )

  yield makeFetchEffect(
    takeLatest,
    GET_PREFERENCES,
    makeURL(`jobPreference/getPreferences`)
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(SAVE_PREFERENCES): {
      return receiveReducer(state, action, () => {
        const { success, ...preferences } = action.response
        return {
          savePreferencesSuccess: success,
          preferences: {
            ...state.preferences,
            ...preferences,
          },
        }
      })
    }

    case receiveAction(SAVE_SUMMARY): {
      return receiveReducer(state, action, () => ({
        summaryResponseSuccess: action.response.success,
        preferences: {
          ...state.preferences,
          summary: action.response.summary,
        },
      }))
    }

    case receiveAction(GET_PREFERENCES): {
      return receiveReducer(state, action, () => ({
        preferences: action.response,
      }))
    }

    default:
      return state
  }
}
