import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { mixpanel } from 'mednet-util/src/tracking'
import { CME_CERTIFICATE_TYPE } from 'mednet-util/src/constants/cme'

import {
  useCmeCertificates,
  useCmeRecentCertificates,
  useSetCmeRecentCertificates,
} from 'mednet-cns/src/hooks/cme'

import CmeCertificatesTable from 'components/tables/cmeCertificatesTable'

import { CmeCertificateTypeFilter, CmeYearFilter } from './cmeFilters'
import css from './cmeCertificatesTab.scss'

export const CmeCertificatesTab = ({
  creditsPerActivity,
  minYear,
  isAbimMocEnabledForUser,
  isAbpMocEnabledForUser,
}) => {
  const [filterType, setFilterType] = useState(
    isAbimMocEnabledForUser || isAbpMocEnabledForUser
      ? 'any'
      : CME_CERTIFICATE_TYPE.CME
  )
  const [filterYear, setFilterYear] = useState(new Date().getFullYear())

  const [certificates, certificatesRequest] = useCmeCertificates(filterYear)
  const recentCertificates = useCmeRecentCertificates()
  const setRecentCertificates = useSetCmeRecentCertificates()

  const resetRecentActivities = useCallback(() => {
    const timer = setTimeout(() => {
      setRecentCertificates([])
    }, 3000)

    return () => {
      clearTimeout(timer) // clean up runs when component updates/unmounts
      setRecentCertificates([])
    }
  }, [setRecentCertificates])

  useEffect(resetRecentActivities, [])

  useEffect(() => {
    mixpanel.track('CME Certificates Tab Load', {})
  }, [])

  const handleYearFilterChange = useCallback(
    (selectedOption) => {
      setFilterYear(selectedOption.value)
    },
    [setFilterYear]
  )

  const handleTypeFilterChange = useCallback(
    (selectedOption) => {
      setFilterType(selectedOption.value)
    },
    [setFilterType]
  )

  const filteredCertificates = useMemo(() => {
    if (filterType === 'any') {
      return certificates
    }

    return certificates.filter((certificate) => certificate.type === filterType)
  }, [filterType, certificates])

  const tableCertificates = useMemo(() => {
    return filteredCertificates.map((certificate, index) => ({
      ...certificate,
      id: index,
    }))
  }, [filteredCertificates])

  return (
    <div className={css.mainContainer}>
      <div className={css.filtersContainer}>
        <CmeYearFilter
          minYear={minYear}
          handleFilterChange={handleYearFilterChange}
        />
        <CmeCertificateTypeFilter
          handleFilterChange={handleTypeFilterChange}
          isAbimMocEnabledForUser={isAbimMocEnabledForUser}
          isAbpMocEnabledForUser={isAbpMocEnabledForUser}
        />
      </div>

      <div className={css.tableContainer}>
        <CmeCertificatesTable
          certificates={tableCertificates}
          isLoading={certificatesRequest.isLoading}
          loadingDataError={certificatesRequest.isError}
          creditsPerActivity={creditsPerActivity}
          recentCertificates={recentCertificates}
        />
      </div>
    </div>
  )
}
