import React from 'react'
import classNames from 'classnames'

import { FlexRow, FlexRowCell } from 'pharmacy/src/display/content'
import ProximaNovaSelect from 'pharmacy/src/input/select/proximaNovaSelect'

import css from './specialtyFilters.scss'

const medicalOncologySpecialtyId = 2

const getSpecialyOptionLabel = (specialty) => {
  return specialty.specialty
}

const getSpecialtyOptionValue = (specialty) => {
  return specialty.specialtyId
}

const handleSpecialtyFilterChange = (option, action, setSpecialtyFilter) => {
  if (action === 'select-option') {
    setSpecialtyFilter(option.specialtyId)
  } else if (action === 'clear') {
    setSpecialtyFilter(undefined)
  }
}

const checkIfOncologySpecialty = (oncologySpecialties, specialtyId) => {
  return oncologySpecialties
    .map((specialty) => specialty.specialtyId)
    .includes(specialtyId)
}

const ExpandedSpecialtyFilters = ({
  specialties,
  setSpecialtyFilter,
  filteredSpecialtyId,
}) => {
  const [oncologySpecialties, notOncologySpecialties] = specialties.reduce(
    ([oncology, notOncology], specialty) => {
      return /oncology/i.test(specialty.specialty)
        ? [[...oncology, specialty], notOncology]
        : [oncology, [...notOncology, specialty]]
    },
    [[], []]
  )

  const handleFilterChange = (specialty) => {
    handleSpecialtyFilterChange(specialty, 'select-option', setSpecialtyFilter)
  }

  const oncologyButtonClasses = classNames(css.specialtyFilterSubtitle, {
    [css.specialtyFilterSubtitleChosen]: checkIfOncologySpecialty(
      oncologySpecialties,
      filteredSpecialtyId
    ),
  })

  return (
    <div className={css.displayForNotLargeMedia}>
      <FlexRow
        size="xsmall"
        verticalMargins={false}
        fullWidth
        className={css.specialtyFiltersContainer}
      >
        <FlexRowCell>
          <div className={css.specialtyFilterContainer}>
            <button
              className={oncologyButtonClasses}
              onClick={() => {
                setSpecialtyFilter(medicalOncologySpecialtyId)
              }}
            >
              Oncology
            </button>
          </div>
        </FlexRowCell>

        {notOncologySpecialties.map((specialty) => {
          const buttonClasses = classNames(css.specialtyFilterSubtitle, {
            [css.specialtyFilterSubtitleChosen]:
              filteredSpecialtyId === specialty.specialtyId,
          })

          return (
            <FlexRowCell key={specialty.specialtyId}>
              <div className={css.specialtyFilterContainer}>
                <button
                  className={buttonClasses}
                  onClick={() => {
                    handleFilterChange(specialty)
                  }}
                >
                  {specialty.specialty}
                </button>
              </div>
            </FlexRowCell>
          )
        })}
      </FlexRow>

      {checkIfOncologySpecialty(oncologySpecialties, filteredSpecialtyId) && (
        <div className={css.OncologyFiltersContainer}>
          {oncologySpecialties.map((specialty) => {
            const buttonClasses = classNames(css.OncologyFilterSubtitle, {
              [css.specialtyFilterSubtitleChosen]:
                filteredSpecialtyId === specialty.specialtyId,
            })

            return (
              <div
                key={specialty.specialtyId}
                className={css.OncologyFilterButton}
              >
                <button
                  className={buttonClasses}
                  onClick={() => handleFilterChange(specialty)}
                >
                  {specialty.specialty}
                </button>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const CollapsedSpecialtyFilters = ({
  specialties,
  setSpecialtyFilter,
  filteredSpecialtyId,
  className,
  optionsConatinerClassName,
}) => {
  return (
    <div className={classNames(css.displayForLargeMedia, className)}>
      <div
        className={classNames(
          css.specialtyOptionContainer,
          optionsConatinerClassName
        )}
      >
        <ProximaNovaSelect
          maxMenuHeight={300}
          options={specialties}
          getOptionLabel={(option) => getSpecialyOptionLabel(option)}
          getOptionValue={(option) => getSpecialtyOptionValue(option)}
          onChange={(option, { action }) => {
            handleSpecialtyFilterChange(option, action, setSpecialtyFilter)
          }}
          name="Specialty"
          value={specialties.find(
            (specialty) => specialty.specialtyId === filteredSpecialtyId
          )}
          centered
        />
      </div>
    </div>
  )
}

export const SpecialtyFilters = (props) => {
  return (
    <React.Fragment>
      <ExpandedSpecialtyFilters {...props} />
      <CollapsedSpecialtyFilters {...props} />
    </React.Fragment>
  )
}
