import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { FOLLOWING_TYPE } from 'mednet-util/src/constants/following'
import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeaders } from 'mednet-cns/src/api/v1'
import { FETCH_FEED } from 'mednet-cns/src/reducers/feed'

import {
  receiveAction,
  requestAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_INSTITUTION = 'institution/FETCH_INSTITUTION'
export const TOGGLE_FOLLOW_INSTITUTION = 'institution/TOGGLE_FOLLOW_INSTITUTION'
export const CONTACT_INSTITUTION = 'institution/CONTACT_INSTITUTION'

export function fetchInstitution(institutionId) {
  return {
    type: FETCH_INSTITUTION,
    requestId: institutionId,
    institutionId,
  }
}

export function toggleFollowInstitution(institutionId) {
  return {
    type: TOGGLE_FOLLOW_INSTITUTION,
    requestId: institutionId,
    institutionId,
  }
}

export function contactInstitution(form, callback) {
  return {
    type: CONTACT_INSTITUTION,
    form,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_INSTITUTION,
    (action) => `institutionRedux/getJSON/${action.institutionId}`
  )

  yield makeFetchEffect(takeLatest, TOGGLE_FOLLOW_INSTITUTION, (action) =>
    makeURL('following/toggleJSON', {
      type: FOLLOWING_TYPE.INSTITUTION,
      followingId: action.institutionId,
    })
  )

  yield makeFetchEffect(
    takeLatest,
    CONTACT_INSTITUTION,
    makeURL('institutionRedux/contactJSON'),
    (action) => makePOSTHeaders(new FormData(action.form))
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  institutions: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_INSTITUTION): {
      return receiveReducer(state, action, () => ({
        institutions: {
          ...state.institutions,
          [action.institutionId]: {
            ...action.response.institution,
            zipcode: _.pad(action.response.institution.zipcode, 5, '0'),
          },
        },
      }))
    }

    case requestAction(TOGGLE_FOLLOW_INSTITUTION): {
      const institutionData = state.institutions[action.institutionId]
      const { isFollowed } = institutionData

      return {
        ...state,
        institutions: {
          ...state.institutions,
          [action.institutionId]: {
            ...institutionData,
            isFollowed: !isFollowed,
          },
        },
      }
    }

    case receiveAction(FETCH_FEED): {
      if (action.itemKey === 'institutionId') {
        return receiveReducer(state, action, () => ({
          institutions: {
            ...state.institutions,
            ..._.keyBy(action.response.items, 'institutionId'),
          },
        }))
      }

      return state
    }

    default:
      return state
  }
}
