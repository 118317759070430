import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Page } from 'pharmacy/src/display/page'
import { Header1 } from 'pharmacy/src/typography'
import { CenteredContent } from 'pharmacy/src/display/content'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  FETCH_SPONSORSHIP,
  fetchSponsorship,
  FETCH_CAMPAIGNS,
  fetchCampaigns,
  saveCampaigns,
} from 'mednet-cns/src/reducers/sponsorship'
import { getRequest } from 'mednet-cns/src/api/v1'
import { getPathnameFromRoute } from 'mednet-util/src/router'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import CampaignsForm from './forms/campaignsForm'

import css from './campaigns.scss'

class SponsorshipCampaignsPage extends React.Component {
  constructor(props) {
    super(props)

    if (!props.sponsorshipIsLoaded) {
      props.fetchSponsorship()
    }

    if (!props.campaignsIsLoaded) {
      props.fetchCampaigns()
    }

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    const { saveCampaigns, history, sponsorship } = this.props
    const { sponsorshipId } = sponsorship

    window.tinyMCE.triggerSave()

    const formData = new FormData(this.form.current)
    saveCampaigns(formData, () => {
      actions.setSubmitting(false)

      history.push(
        getPathnameFromRoute(ROUTES[PAGES.VIEW_SPONSORSHIP], { sponsorshipId })
      )
    })
  }

  render() {
    const { sponsorship, sponsorshipIsLoaded, campaignsIsLoaded } = this.props

    if (!sponsorshipIsLoaded || !campaignsIsLoaded) {
      return <StarLoader />
    }

    const { description, sponsorshipId, campaigns } = sponsorship

    return (
      <Page>
        <CenteredContent>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
              {
                ...ROUTES[PAGES.VIEW_SPONSORSHIP],
                routeParams: { sponsorshipId },
                label: description,
              },
              {
                label: 'Manage Campaigns',
              },
            ]}
          />
          <div className={css.header}>
            <Header1>Manage Campaigns</Header1>
          </div>
          <Formik onSubmit={this.handleSubmit} initialValues={campaigns}>
            {(props) => (
              <CampaignsForm {...props} {...this.props} formRef={this.form} />
            )}
          </Formik>
        </CenteredContent>
      </Page>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sponsorshipId } = ownProps.match.params
  const sponsorshipRequest = getRequest(state, FETCH_SPONSORSHIP, sponsorshipId)
  const campaignsRequest = getRequest(state, FETCH_CAMPAIGNS, sponsorshipId)

  return {
    sponsorship: state.sponsorship.sponsorships[sponsorshipId],
    sponsorshipIsLoaded: sponsorshipRequest.isLoaded === true,
    campaignsIsLoaded: campaignsRequest.isLoaded === true,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorshipId } = ownProps.match.params

  return {
    fetchSponsorship: () => dispatch(fetchSponsorship(sponsorshipId)),
    fetchCampaigns: () => dispatch(fetchCampaigns(sponsorshipId)),
    saveCampaigns: (formData, callback) =>
      dispatch(saveCampaigns(sponsorshipId, formData, callback)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SponsorshipCampaignsPage)
