import React, { useMemo, useCallback } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Button } from 'pharmacy/src/input/button'

import {
  useAbandonSystemJob,
  useSendPreviewTo,
  useSystemJobs,
} from 'mednet-cns/src/hooks/systemJob'
import { JOB_TYPE } from 'mednet-cns/src/reducers/systemJob'

import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import { SEND_PREVIEW_MODAL } from 'mednet-util/src/constants/modal'

import SendPreviewModal from '../sendPreviewModal/sendPreviewModal'

import Table from './table'
import { DateCell, ColumnSelectFilter, ColumnTextFilter } from './cells'

import * as css from './systemJobsTable.scss'

const STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PREPARE: 'prepare',
  PROGRESS: 'progress',
  SUCCESS: 'success',
  FAILED: 'failed',
  ABANDONED: 'abandoned',
}

const jobTypeLabels = {
  [JOB_TYPE.CAMPAIGN]: 'Campaign',
  [JOB_TYPE.INVITE]: 'Invitation',
  [JOB_TYPE.CHILDINVITE]: 'Invitation chunk',
  [JOB_TYPE.REMINDERINVITE]: 'Invitation auto-reminder',
}

const jobStatusLabels = {
  [STATUS.DRAFT]: 'draft',
  [STATUS.SCHEDULED]: 'scheduled',
  [STATUS.PREPARE]: 'prepare',
  [STATUS.PROGRESS]: 'progress',
  [STATUS.SUCCESS]: 'success',
  [STATUS.FAILED]: 'failed',
  [STATUS.ABANDONED]: 'abandoned',
}

const jobStatusClassNames = {
  [STATUS.DRAFT]: css.draft,
  [STATUS.SCHEDULED]: css.scheduled,
  [STATUS.PREPARE]: css.prepare,
  [STATUS.PROGRESS]: css.progress,
  [STATUS.SUCCESS]: css.success,
  [STATUS.FAILED]: css.failed,
  [STATUS.ABANDONED]: css.abandoned,
}

const ActionsCell = React.memo(
  ({
    row: {
      original: { id, type, status, parentId },
    },
  }) => {
    const [abandonSystemJob] = useAbandonSystemJob()
    const [sendJobPreviewTo] = useSendPreviewTo()
    const [openModal, closeModal] = useModalDrivers(SEND_PREVIEW_MODAL.modalId)
    const abandonJob = useCallback((id) => () => {
      abandonSystemJob(id)
    })
    const openSendPreviewToModal = useCallback((id) => () => {
      openModal({
        onSubmit: (to) => {
          sendJobPreviewTo(id, to)
          closeModal()
        },
      })
    })

    const actions = [
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED],
        types: [JOB_TYPE.CAMPAIGN],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="edit"
            tooltip="edit"
            className={css.action}
            pathname={`/campaign/edit/${id}`}
            key="edit"
          />
        ),
      },
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED, STATUS.PROGRESS],
        types: [JOB_TYPE.INVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="edit"
            tooltip="edit"
            className={css.action}
            pathname={`/campaign/edit/${id}`}
            key="edit"
          />
        ),
      },
      {
        statuses: [
          STATUS.ABANDONED,
          STATUS.FAILED,
          STATUS.PREPARE,
          STATUS.PROGRESS,
          STATUS.SUCCESS,
        ],
        types: [JOB_TYPE.CAMPAIGN, JOB_TYPE.INVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="eye"
            tooltip="view"
            className={css.action}
            pathname={`/campaign/view/${id}`}
            key="view"
          />
        ),
      },
      {
        statuses: null,
        types: [JOB_TYPE.REMINDERINVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="eye"
            tooltip="view"
            className={css.action}
            pathname={`/campaign/view/${parentId}`}
            key="view"
          />
        ),
      },
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED],
        types: [JOB_TYPE.CAMPAIGN],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="ban"
            tooltip="abandon"
            className={css.action}
            onClick={abandonJob(id)}
            key="abandon"
          />
        ),
      },
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED, STATUS.PROGRESS],
        types: [JOB_TYPE.INVITE, JOB_TYPE.REMINDERINVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="ban"
            tooltip="abandon"
            className={css.action}
            onClick={abandonJob(id)}
            key="abandon"
          />
        ),
      },
      {
        statuses: [
          STATUS.DRAFT,
          STATUS.SCHEDULED,
          STATUS.PREPARE,
          STATUS.PROGRESS,
          STATUS.SUCCESS,
        ],
        types: [JOB_TYPE.CAMPAIGN, JOB_TYPE.INVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="reply"
            tooltip="reply/reminder"
            className={css.action}
            pathname={`/campaign/reply/${id}`}
            key="reply"
          />
        ),
      },
      {
        statuses: null,
        types: [JOB_TYPE.CAMPAIGN, JOB_TYPE.INVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="copy"
            tooltip="make copy"
            className={css.action}
            pathname={`/campaign/copy/${id}`}
            key="copy"
          />
        ),
      },
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED, STATUS.PROGRESS],
        types: [JOB_TYPE.INVITE, JOB_TYPE.REMINDERINVITE],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="file-import"
            tooltip="send preview"
            className={css.action}
            onClick={openSendPreviewToModal(id)}
            key="preview"
          />
        ),
      },
      {
        statuses: [STATUS.DRAFT, STATUS.SCHEDULED],
        types: [JOB_TYPE.CAMPAIGN],
        action: (
          <Button
            type="neutral"
            size="small"
            icon="file-import"
            tooltip="send preview"
            className={css.action}
            onClick={openSendPreviewToModal(id)}
            key="preview"
          />
        ),
      },
    ]

    return (
      <div className={css.actions}>
        {actions.map(({ statuses, types, action }) => {
          if (statuses === null || statuses.includes(status)) {
            if (types === null || types.includes(type)) {
              return action
            }
          }

          return null
        })}
      </div>
    )
  }
)
ActionsCell.displayName = 'ActionsCell'

export default function SystemJobsTable({ bodyHeight: bodyHeight = 500 }) {
  const isMetaLoading = false

  const [systemJobs, requestDetails] = useSystemJobs()
  const { isLoading } = requestDetails

  const dateCell = useCallback(
    ({ value }) => (!value ? '' : <DateCell date={value} />),
    []
  )

  const nameCell = useCallback(
    ({ value }) => (!value ? '' : <div className={css.nameCell}>{value}</div>),
    []
  )

  const sortDate = useCallback(
    ({ values: valuesA }, { values: valuesB }, columnId) => {
      const valueA = valuesA[columnId]
      const valueB = valuesB[columnId]
      const timeA = valueA instanceof Date ? valueA.getTime() : 0
      const timeB = valueB instanceof Date ? valueB.getTime() : 0

      return timeA - timeB
    }
  )

  const statusCell = useCallback(
    ({ value }) => (
      <span className={classNames(css.status, jobStatusClassNames[value])}>
        {jobStatusLabels[value]}
      </span>
    ),
    []
  )

  const timezone = moment().tz('America/New_York').format('zz')

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        width: 50,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? '▲' : '▼'}
          </span>
        ),
        Cell: ({ row }) =>
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? '▲' : '▼'}
            </span>
          ) : null,
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
        disableFilters: true,
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: ({ type }) => jobTypeLabels[type] || type,
        width: 120,
        Filter: ColumnSelectFilter,
        filter: 'includes',
      },
      {
        Header: 'Content',
        accessor: 'contentId',
        width: 80,
        disableFilters: true,
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ name, attributes }) =>
          !attributes?.reminderNo
            ? name
            : `Auto reminder #${attributes?.reminderNo}: ${name}`,
        Cell: nameCell,
        width: 350,
        Filter: ColumnTextFilter,
        filter: 'includes',
      },
      {
        Header: `Schedule (${timezone})`,
        accessor: 'schedule',
        width: 150,
        Cell: dateCell,
        sortType: sortDate,
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 120,
        Cell: statusCell,
        Filter: ColumnSelectFilter,
        filter: 'includes',
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: () => '',
        width: 200,
        Cell: ActionsCell,
        disableFilters: true,
      },
      {
        Header: 'Author',
        id: 'author',
        accessor: 'author',
        width: 200,
        Filter: ColumnSelectFilter,
        filter: 'includes',
      },
    ],
    []
  )

  if (isMetaLoading) {
    return <StarLoader />
  }

  return (
    <>
      <Table
        columns={columns}
        data={systemJobs}
        isLoading={isLoading}
        bodyHeight={bodyHeight}
        className={css.systemJobsTable}
        itemSize={60}
        expandable
      />
      <SendPreviewModal />
    </>
  )
}
