import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import { formatName } from 'mednet-util/src/string'
import { Table } from 'pharmacy/src/display/table'
import { Icon } from 'pharmacy/src/display/icon'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import {
  DELETE_COMMENT_MODAL,
  MOVE_COMMENT_MODAL,
} from 'mednet-util/src/constants/modal'

import css from './manageCommentsTable.scss'

const AuthorCell = (datum) => (
  <UserNameLink className={css.userLink} userId={datum.userId} />
)

class ReviewCell extends React.Component {
  render() {
    const { datum, openDeleteConfirmationModal, openMoveConfirmationModal } =
      this.props
    const { commentId } = datum

    return (
      <div className={css.reviewButtons}>
        <Button
          type="neutral"
          size="small"
          className={css.reviewButton}
          onClick={openDeleteConfirmationModal(commentId)}
        >
          <Icon icon={['far', 'trash-alt']} title="Delete" />
        </Button>
        <Button
          pathname={`/comment/update/${commentId}`}
          external
          type="neutral"
          size="small"
          className={css.reviewButton}
        >
          <Icon icon={['far', 'edit']} title="Edit" />
        </Button>
        <Button
          type="neutral"
          size="small"
          className={css.reviewButton}
          onClick={openMoveConfirmationModal(commentId)}
        >
          <Icon icon={['far', 'exchange']} title="Change to Answer" />
        </Button>
      </div>
    )
  }
}

class ManageCommentsTable extends React.Component {
  static columns = [
    {
      header: 'Comment ID',
      data: 'commentId',
      type: 'number',
    },
    {
      header: 'Author',
      data: AuthorCell,
      sort: {
        key: 'name',
        comparator: (datum) =>
          formatName(
            datum.lastname,
            datum.firstname,
            datum.lastname
          ).toLowerCase(),
      },
    },
    {
      header: 'Comment',
      width: '40%',
      data: 'comment',
      type: 'raw',
    },
    {
      header: 'Created',
      data: (datum) => moment(datum.created).format('LL'),
    },
    {
      header: 'Review',
      data: ReviewCell,
    },
  ]

  render() {
    const { comments, openDeleteConfirmationModal, openMoveConfirmationModal } =
      this.props

    return (
      <Table
        columns={ManageCommentsTable.columns}
        data={comments}
        getRowKey="commentId"
        emptyState="There are no comments"
        cellProps={{ openDeleteConfirmationModal, openMoveConfirmationModal }}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { answerId } = ownProps
  const { comments } = state.answer.answers[answerId] || {}

  return {
    comments,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openDeleteConfirmationModal: (commentId) => () =>
      dispatch(openModal(DELETE_COMMENT_MODAL.modalId, { commentId })),
    openMoveConfirmationModal: (commentId) => () =>
      dispatch(openModal(MOVE_COMMENT_MODAL.modalId, { commentId })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCommentsTable)
