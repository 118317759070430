import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'
import { Button } from 'pharmacy/src/input/button'
import { Body1, Header3 } from 'pharmacy/src/typography'

import { CAMPAIGN_ERRORS_MODAL } from 'mednet-util/src/constants/modal'

import _ from 'lodash'

import css from './campaign.scss'

function CampaignErrorsScreen({ closeModal, errors }) {
  return (
    <div>
      <Header3>Please fix these issues:</Header3>

      <ul>
        {_.entries(errors).map(([key, value]) => {
          return (
            <li key={key}>
              <Body1>
                <b>{key}:</b> {value}
              </Body1>
            </li>
          )
        })}
      </ul>
      <div className={css.errorsModalButtonContainer}>
        <Button onClick={() => closeModal()} type="neutral">
          Close
        </Button>
      </div>
    </div>
  )
}

export default function CampaignErrorsModal(props) {
  return (
    <Modal modalId={CAMPAIGN_ERRORS_MODAL.modalId} {...props}>
      <CampaignErrorsScreen />
    </Modal>
  )
}
