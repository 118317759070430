import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import CommentInformation from './commentInformation'
import WriteComment from './writeComment'

import css from './answerComments.scss'

class AnswerComments extends React.Component {
  render() {
    const { comments, answerId, commentInput, enableEdit, commentProps } =
      this.props
    const containerClasses = classNames(
      comments.length > 0 && css.container_withComments,
      comments.length === 0 && css.container_noComments
    )

    return (
      <div className={containerClasses}>
        {comments.map((commentObject) => {
          return (
            <CommentInformation
              className={css.commentInformation}
              commentObject={commentObject}
              enableEdit={enableEdit}
              key={commentObject.commentId}
              {...commentProps}
            />
          )
        })}
        <div className={css.writeComment}>
          <WriteComment answerId={answerId} ref={commentInput} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { comments } = state.answer.answers[ownProps.answerId]
  return {
    comments,
  }
}

export default connect(mapStateToProps)(AnswerComments)
