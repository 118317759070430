import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import { formatName } from 'mednet-util/src/string'
import { Table } from 'pharmacy/src/display/table'
import { Icon } from 'pharmacy/src/display/icon'
import { Button } from 'pharmacy/src/input/button'
import { openModal } from 'mednet-cns/src/reducers/modal'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { FETCH_QUESTION_ANSWERS } from 'mednet-cns/src/reducers/question'
import {
  DELETE_ANSWER_MODAL,
  MOVE_ANSWER_MODAL,
} from 'mednet-util/src/constants/modal'

import css from './manageAnswersTable.scss'

const AuthorCell = (datum) => (
  <UserNameLink className={css.userLink} userId={datum.userId} />
)

const ExpertReviewCell = (datum) => (
  <div>
    {datum.peerReview.users.map((user) => (
      <p key={user.userId}>
        <UserNameLink user={user} className={css.userLink} />
      </p>
    ))}
  </div>
)

class ReviewCell extends React.Component {
  render() {
    const {
      datum,
      openDeleteConfirmationModal,
      numAnswers,
      openMoveConfirmationModal,
    } = this.props
    const { answerId } = datum

    return (
      <div className={css.reviewButtons}>
        <Button
          type="neutral"
          size="small"
          className={css.reviewButton}
          onClick={openDeleteConfirmationModal(answerId)}
        >
          <Icon icon={['far', 'trash-alt']} title="Delete" />
        </Button>
        <Button
          pathname={`/answer/modupdate/${answerId}`}
          external
          type="neutral"
          size="small"
          className={css.reviewButton}
        >
          <Icon icon={['far', 'edit']} title="Edit" />
        </Button>
        {datum.comments.length > 0 && (
          <Button
            pathname={`/answer/manageComments/${datum.answerId}`}
            type="neutral"
            size="small"
            className={css.reviewButton}
          >
            <Icon icon="comment-edit" />
          </Button>
        )}
        {numAnswers > 1 && (
          <Button
            type="neutral"
            size="small"
            className={css.reviewButton}
            onClick={openMoveConfirmationModal(answerId)}
          >
            <Icon icon={['far', 'exchange']} title="Change to Comment" />
          </Button>
        )}
      </div>
    )
  }
}

class ManageAnswersTable extends React.Component {
  static columns = [
    {
      header: 'Answer ID',
      data: 'answerId',
      type: 'number',
    },
    {
      header: 'Author',
      data: AuthorCell,
      sort: {
        key: 'name',
        comparator: (datum) =>
          formatName(
            datum.lastname,
            datum.firstname,
            datum.lastname
          ).toLowerCase(),
      },
    },
    {
      header: 'Answer',
      width: '40%',
      data: 'answer',
      type: 'raw',
    },
    {
      header: 'Created',
      data: (datum) => moment(datum.created).format('LL'),
    },
    {
      header: 'Agrees',
      data: (datum) => datum.opinions.length,
      type: 'number',
    },
    {
      header: 'Peer Review Experts',
      data: ExpertReviewCell,
    },
    {
      header: 'Peer Review Date',
      data: (datum) =>
        datum.peerReview.lastDate &&
        moment(datum.peerReview.lastDate).format('LL'),
    },
    {
      header: 'Review',
      data: ReviewCell,
      width: '15%',
    },
  ]

  render() {
    const {
      answers,
      answersIsLoading,
      openDeleteConfirmationModal,
      openMoveConfirmationModal,
    } = this.props

    return (
      <Table
        columns={ManageAnswersTable.columns}
        data={answers}
        isLoading={answersIsLoading}
        getRowKey="answerId"
        cellProps={{
          openDeleteConfirmationModal,
          openMoveConfirmationModal,
          numAnswers: answers && answers.length,
        }}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const { answers } = state.question.questions[questionId] || {}
  const answersRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_QUESTION_ANSWERS, questionId)
    ) || {}

  return {
    answers:
      answers && answers.map((answerId) => state.answer.answers[answerId]),
    answersIsLoading: answersRequest.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openDeleteConfirmationModal: (answerId) => () =>
      dispatch(openModal(DELETE_ANSWER_MODAL.modalId, { answerId })),
    openMoveConfirmationModal: (answerId) => () =>
      dispatch(openModal(MOVE_ANSWER_MODAL.modalId, { answerId })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAnswersTable)
