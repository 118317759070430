import React from 'react'

export default function StoryModeIcon({ fill = '#2979FF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M7.5 19H17.5V4H7.5V19ZM2.5 17H6.5V6H2.5V17ZM18.5 6V17H22.5V6H18.5Z"
        fill={fill}
      />
    </svg>
  )
}
