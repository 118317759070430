import React from 'react'

import { connect } from 'react-redux'

import {
  FETCH_USER_WITH_PERMISSIONS,
  FETCH_USER_WITH_PERMISSIONS_PUBLIC,
} from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import AutoComplete from './headerAutoComplete'

class HeaderSearch extends React.Component {
  render() {
    const { userData, isLoaded, isError } = this.props

    if (!isLoaded || isError) {
      return null
    }

    const { userId } = userData

    // Guest user
    if (!userId) {
      return null
    }

    return <AutoComplete />
  }
}

const mapStateToProps = (state) => {
  const publicUserRequest = getRequest(
    state,
    FETCH_USER_WITH_PERMISSIONS_PUBLIC
  )
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data,
    isLoaded: userRequest.isLoaded || publicUserRequest.isLoaded,
    isError: userRequest.isError || publicUserRequest.isError,
  }
}

export default connect(mapStateToProps)(HeaderSearch)
