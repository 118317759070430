import React from 'react'
import classNames from 'classnames'

import { Icon } from 'pharmacy/src/display/icon'

import css from './buttonIcon.scss'

class ButtonIcon extends React.Component {
  static defaultProps = {
    position: 'left',
  }

  render() {
    const { responsive, removeMargin, position, className, ...restProps } =
      this.props

    const classes = classNames({
      [css.container_responsive]: responsive,
      [css[`container_${position}`]]: removeMargin !== true,
      [className]: Boolean(className),
    })

    return <Icon className={classes} {...restProps} />
  }
}

export default ButtonIcon
