import React from 'react'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { Button } from 'pharmacy/src/input/button'
import { Link1 } from 'pharmacy/src/typography'

import { toggleFollowUser } from 'mednet-cns/src/reducers/user'

import Stub from '../../navigation/stub/stub'

import css from './followUserButton.scss'

class followUserButton extends React.Component {
  static defaultProps = {
    buttonProps: {
      type: 'text',
    },
  }

  render() {
    const { isFollowed, toggleFollowUser, buttonProps, className, stubbed } =
      this.props

    const icon = isFollowed ? 'user-minus' : ['fal', 'user-plus']
    const text = isFollowed ? 'Unfollow' : 'Follow'

    const LinkComponent = stubbed ? Stub : Link1

    return (
      <LinkComponent className={[className, css.link].join(' ')}>
        <Button
          icon={icon}
          onClick={() => {
            stubbed ? noop : toggleFollowUser(!isFollowed)
          }}
          {...buttonProps}
        >
          {text}
        </Button>
      </LinkComponent>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFollowed: state.user.users[ownProps.userId].isFollowed,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleFollowUser: (prevFollowedState) =>
      dispatch(
        toggleFollowUser(
          ownProps.userId,
          ownProps.callLocation,
          prevFollowedState,
          ownProps.questionId
        )
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(followUserButton)
