import React from 'react'

import { Header1 } from 'pharmacy/src/typography'
import { Modal } from 'pharmacy/src/display/modal'
import { Button } from 'pharmacy/src/input/button'

import { SPONSORSHIP_EDIT_PAPER_MODAL } from 'mednet-util/src/constants/modal'

import PaperForm from '../forms/paperForm'

import css from './editPaper.scss'

const CreateScreen = (props) => {
  const { closeModal } = props

  return (
    <div>
      <Header1>Edit Paper</Header1>
      <hr />
      <PaperForm {...props} />

      <div className={css.footer}>
        <Button type="neutral" onClick={closeModal} className={css.button}>
          Close
        </Button>
      </div>
    </div>
  )
}

const EditPaperModal = (props) => {
  return (
    <Modal
      closeOnBackdrop={false}
      modalId={SPONSORSHIP_EDIT_PAPER_MODAL.modalId}
      size="large"
      contentClassName={css.modalContainer}
      {...props}
    >
      <CreateScreen />
    </Modal>
  )
}

export default EditPaperModal
