import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { ContainedSelect } from 'pharmacy/src/input/select'
import { DateRangePicker } from 'pharmacy/src/input/datePicker'
import { Header1, Link1 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'
import { Page } from 'pharmacy/src/display/page'
import {
  fetchAvailableSpecialties,
  fetchUpdates,
  FETCH_UPDATES,
  FETCH_AVAILABLE_SPECIALTIES,
} from 'mednet-cns/src/reducers/expertEmail'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { getRequest } from 'mednet-cns/src/api/v1'

import UpdatesForm from './updatesForm'
import EmailModal from './emailModal'

import css from './manageMonthlyExpertEmail.scss'

class ManageCustomExpertEmailPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.form = React.createRef()
  }

  componentDidMount() {
    this.props.fetchAvailableSpecialties()
  }

  componentDidUpdate(prevProps) {
    const { availableSpecialtiesIsLoaded, userData, userDataIsLoaded } =
      this.props
    if (
      (!prevProps.userDataIsLoaded &&
        userDataIsLoaded &&
        availableSpecialtiesIsLoaded) ||
      (!prevProps.availableSpecialtiesIsLoaded &&
        availableSpecialtiesIsLoaded &&
        userDataIsLoaded)
    ) {
      this.setState({
        selectedSpecialtyId: userData.specialtyId,
      })
    }
  }

  fetchUpdates = () => {
    const { selectedDateRange, selectedSpecialtyId, selectedSubspecialtyId } =
      this.state
    const { to, from } = selectedDateRange || {}

    if (!selectedSpecialtyId || !selectedSubspecialtyId || !to || !from) {
      return
    }

    this.props.fetchUpdates(
      selectedSpecialtyId,
      selectedSubspecialtyId,
      moment(from).format('YYYY-MM-DD'),
      moment(to).format('YYYY-MM-DD')
    )
  }

  handleChangeSpecialty = (option) => {
    this.setState(
      {
        selectedSpecialtyId: option.value,
      },
      this.fetchUpdates
    )
  }

  handleChangeSubspecialty = (option) => {
    this.setState(
      {
        selectedSubspecialtyId: option.value,
      },
      this.fetchUpdates
    )
  }

  handleChangeDate = (range) => {
    this.setState(
      {
        selectedDateRange: range,
      },
      this.fetchUpdates
    )
  }

  renderForm = () => {
    const { updatesIsLoaded, updatesIsLoading, updates, existing } = this.props
    const { selectedSubspecialtyId } = this.state

    if (updatesIsLoading) {
      return <StarLoader />
    }

    if (!updatesIsLoaded || !selectedSubspecialtyId) {
      return 'Select a subspecialty and date range to see top questions'
    }

    return (
      <Formik
        initialValues={{
          updateId: _.map(updates, (update) => update.selected),
        }}
      >
        {(props) => (
          <UpdatesForm
            {...props}
            innerRef={this.form}
            updates={updates}
            existing={existing}
          />
        )}
      </Formik>
    )
  }

  clearForm = () => {
    this.setState({
      selectedSubspecialtyId: null,
    })
  }

  render() {
    const {
      availableSpecialtiesIsLoaded,
      availableSpecialties,
      existing,
      userDataIsLoaded,
      userData,
    } = this.props
    const { selectedSpecialtyId, selectedSubspecialtyId, selectedDateRange } =
      this.state

    if (!availableSpecialtiesIsLoaded || !userDataIsLoaded) {
      return <StarLoader />
    }

    const selectedSpecialty =
      availableSpecialties[selectedSpecialtyId || userData.specialtyId]
    const selectedSubspecialty =
      selectedSpecialty &&
      selectedSpecialty.subspecialties[selectedSubspecialtyId]
    const defaultSpecialty = availableSpecialties[userData.specialtyId]

    return (
      <Page>
        <div className={css.content}>
          <Link
            external
            pathname="/question/moderatorpage"
            className={css.backLink}
          >
            <Link1>
              {'< '}
              Back to Moderator Page
            </Link1>
          </Link>
          <Header1>Manage expert reach emails</Header1>
          <div className={css.filtersContainer}>
            <div className={css.specialtyContainer}>
              <div className={css.selectContainer}>
                <ContainedSelect
                  options={_.map(
                    _.sortBy(availableSpecialties, 'specialty'),
                    (specialty) => ({
                      label: specialty.specialty,
                      value: specialty.specialtyId,
                    })
                  )}
                  defaultValue={{
                    label: defaultSpecialty.specialty,
                    value: defaultSpecialty.specialtyId,
                  }}
                  onChange={this.handleChangeSpecialty}
                />
              </div>
              <div className={css.selectContainer}>
                <ContainedSelect
                  options={_.map(
                    _.sortBy(selectedSpecialty.subspecialties, 'subspecialty'),
                    (subspecialty) => ({
                      label: subspecialty.subspecialty,
                      value: subspecialty.subspecialtyId,
                    })
                  )}
                  value={
                    selectedSubspecialty
                      ? {
                          label: selectedSubspecialty.subspecialty,
                          value: selectedSubspecialty.subspecialtyId,
                        }
                      : null
                  }
                  placeholder="Select subspecialty"
                  onChange={this.handleChangeSubspecialty}
                />
              </div>
            </div>
            <DateRangePicker
              onChange={this.handleChangeDate}
              toDisabledDays={(from) => ({ before: from, after: new Date() })}
            />
          </div>
          {this.renderForm()}
        </div>
        <EmailModal
          clearForm={this.clearForm}
          existing={existing}
          firstForm={this.form}
          handleSubmit={this.handleSubmit}
          selectedDateRange={selectedDateRange}
          selectedSpecialty={selectedSpecialty}
          selectedSubspecialty={selectedSubspecialty}
        />
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  const fetchAvailableSpecialtiesRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_AVAILABLE_SPECIALTIES)
    ) || {}

  const fetchUpdatesRequest =
    _.get(
      state.request.requests,
      FETCH_UPDATES,
      makeRequestName(FETCH_UPDATES)
    ) || {}

  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)

  return {
    userData: state.user.data || {},
    userDataIsLoaded: userRequest.isLoaded,
    availableSpecialties: state.expertEmail.availableSpecialties,
    availableSpecialtiesIsLoaded: fetchAvailableSpecialtiesRequest.isLoaded,
    updates: state.expertEmail.updates,
    existing: state.expertEmail.existing,
    updatesIsLoaded: fetchUpdatesRequest.isLoaded,
    updatesIsLoading: fetchUpdatesRequest.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableSpecialties: () => dispatch(fetchAvailableSpecialties()),
    fetchUpdates: (specialtyId, subspecialtyId, from, to) =>
      dispatch(fetchUpdates(specialtyId, subspecialtyId, from, to)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCustomExpertEmailPage)
