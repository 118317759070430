import { createSelector } from 'reselect'

import { createDataHook } from 'mednet-cns/src/hook-factory'

import { fetchTestimonialsPublic } from 'mednet-cns/src/reducers/testimonial'

export const [usePublicTestimonials, usePublicTestimonialsLazy] =
  createDataHook(
    fetchTestimonialsPublic,
    createSelector(
      (state) => state.testimonial.testimonials,
      (testimonials) => testimonials || []
    )
  )
