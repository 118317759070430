import React from 'react'

import { Modal } from 'pharmacy/src/display/modal'

import { SHARE_MODAL } from 'mednet-util/src/constants/modal'

import ShareModelScreen from './shareModelScreen'
import FinishedScreen from './finishedScreen'

const screens = {
  [SHARE_MODAL.screens.share]: ShareModelScreen,
  [SHARE_MODAL.screens.finished]: FinishedScreen,
}

class ShareModal extends React.Component {
  render() {
    return (
      <Modal
        modalId={SHARE_MODAL.modalId}
        screens={screens}
        defaultScreen={SHARE_MODAL.screens.share}
      />
    )
  }
}

export default ShareModal
