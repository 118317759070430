import React from 'react'

import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2 } from 'pharmacy/src/typography'

import css from './headerAutoComplete.scss'

const AutoCompleteSeeAllResultsButton = ({ onClick }) => {
  return (
    <div>
      <Link className={css.seeAllResultsLink}>
        <span onClick={onClick}>
          <Subtitle2>
            <div
              className={`${css.seeAllResultsLinkContent} ${css.suggestionLinkContent}`}
            >
              See all results
            </div>
          </Subtitle2>
        </span>
      </Link>
    </div>
  )
}

export default AutoCompleteSeeAllResultsButton
