import React from 'react'
import { BasicConfig } from 'react-awesome-query-builder'
import moment from 'moment'

import TextInput from 'pharmacy/src/input/textInput/textInput'
import DatePicker from 'pharmacy/src/input/datePicker/datePicker'

import QueryBuilderSelect from './widgets/queryBuilderSelect'
import QueryBuilderMultiCreatableText from './widgets/queryBuilderMultiCreatableText'

function useQueryBuilderWidgets() {
  const InitialConfig = BasicConfig

  const widgets = {
    ...InitialConfig.widgets,
    select: {
      ...InitialConfig.widgets.select,
      factory: QueryBuilderSelect,
    },
    multiselect: {
      ...InitialConfig.widgets.multiselect,
      factory: QueryBuilderSelect,
    },
    multicreatabletext: {
      ...InitialConfig.widgets.multiselect,
      factory: QueryBuilderMultiCreatableText,
    },
    slider: {
      ...InitialConfig.widgets.slider,
      customProps: {
        width: '300px',
      },
    },
    rangeslider: {
      ...InitialConfig.widgets.rangeslider,
      customProps: {
        width: '300px',
      },
    },
    text: {
      ...InitialConfig.widgets.text,
      factory: ({ setValue, ...props }) => (
        <TextInput {...props} onChange={setValue} />
      ),
    },
    date: {
      type: 'date',
      factory: ({ setValue, value, ...props }) => (
        <DatePicker
          {...props}
          onChange={(val) => {
            setValue(moment(val).format('YYYY-MM-DD'))
          }}
          value={moment(value).toDate()}
          defaultValue=""
        />
      ),
    },
  }

  return widgets
}

export default useQueryBuilderWidgets
