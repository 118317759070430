import React from 'react'

import { BaseTableHeaderCell } from './baseTableHeaderCell'

import css from './baseTableHeader.scss'

export class BaseTableHeader extends React.Component {
  render() {
    const { columns, sortOrder, sortKey, readOnly, onSort } = this.props

    return (
      <tr className={css.row}>
        {columns.map((column) => (
          <BaseTableHeaderCell
            column={column}
            key={column.header}
            sortOrder={sortOrder}
            sortKey={sortKey}
            onSort={onSort}
            readOnly={readOnly}
          />
        ))}
      </tr>
    )
  }
}
