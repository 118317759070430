import React from 'react'
import classNames from 'classnames'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Icon } from 'pharmacy/src/display/icon'

import css from './styles.scss'

const CurriculumMenuItem = ({
  questionId,
  name,
  curriculumQuestions,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading,
  disabled,
}) => {
  const alreadyAdded = curriculumQuestions.some(
    (curriculumQuestion) => `${curriculumQuestion.questionId}` === questionId
  )

  const clickable = !disabled && !loading && !alreadyAdded

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: disabled,
  })

  const checkmarkIconClasses = classNames('fa-check', css.checkmarkIcon)

  return (
    <div
      onClick={clickable ? onClick : undefined}
      className={menuItemClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={css.menuItemIconContainer}>
        {loading ? (
          <StarLoader size="small" isVerticalMargin={false} wait={false} />
        ) : (
          alreadyAdded && <Icon className={checkmarkIconClasses} />
        )}
      </div>
      <span>{name}</span>
    </div>
  )
}

export default CurriculumMenuItem
