import React from 'react'

import StarLoader from './starLoader'

import css from './starLoader.scss'

export default function CoverLoader() {
  return (
    <div className={css.cover}>
      <StarLoader isFullScreen />
    </div>
  )
}
