import { createSelector } from 'reselect'

import { createDataHook } from 'mednet-cns/src/hook-factory'

import { useDispatch } from 'react-redux'

import {
  addPublicationToSponsorship,
  fetchExperts,
  fetchSponsorship,
} from '../reducers/sponsorship'

export const [useSponsorship, useSponsorshipLazy] = createDataHook(
  fetchSponsorship,
  createSelector(
    (state) => state.sponsorship?.sponsorships || {},
    (_state, { sponsorshipId }) => sponsorshipId,
    (sponsorships, sponsorshipId) => sponsorships[sponsorshipId] || null
  )
)

export const [useSponsorshipExperts, useSponsorshipExpertsLazy] =
  createDataHook(
    fetchExperts,
    createSelector(
      (state) => state.sponsorship?.sponsorships || {},
      (_state, { sponsorshipId }) => sponsorshipId,
      (sponsorships, sponsorshipId) =>
        sponsorships[sponsorshipId]?.experts || null
    )
  )

export const useAddPaperToSponsorship = () => {
  const dispatch = useDispatch()

  return (formData, callback) => {
    dispatch(addPublicationToSponsorship(formData, callback))
  }
}
