import {
  all,
  call,
  put,
  take,
  fork,
  spawn,
  join,
  race,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects'

import { receiveAction } from 'mednet-cns/src/cns-util/reducer'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { mixpanel } from 'mednet-util/src/tracking'

import { FETCH_USER_MENTION_PROFILE, fetchUserMentionProfile } from './user'

export const MOUSE_ENTER = 'mention/MOUSE_ENTER'
export const MOUSE_LEAVE = 'mention/MOUSE_LEAVE'
export const SHOW_MENTION = 'mention/SHOW_MENTION'
export const HIDE_MENTION = 'mention/HIDE_MENTION'
export const ACTIVE_MENTION = 'mention/ACTIVE_MENTION'

const TRANSITION_DELAY = 200

export function mouseEnter(userId, rect, sourceType) {
  return {
    type: MOUSE_ENTER,
    userId,
    rect,
    sourceType,
  }
}

export function mouseLeave(force) {
  return {
    type: MOUSE_LEAVE,
    force,
  }
}

function showMention(action, isLoaded = true) {
  return {
    ...action,
    isLoaded,
    type: SHOW_MENTION,
  }
}

function hideMention(action) {
  return {
    ...action,
    type: HIDE_MENTION,
  }
}

export function activeMention() {
  return {
    type: ACTIVE_MENTION,
  }
}

function* yieldTakeAction(action) {
  yield take(action)
}

function* showSaga(action) {
  const { userId, sourceType } = action

  const fetchAction = fetchUserMentionProfile(userId)
  const requestName = makeRequestName(FETCH_USER_MENTION_PROFILE, userId)

  const request = yield select(
    (state) => state.request.requests[requestName] || {}
  )
  const { isLoaded, isLoading } = request

  let forkTake
  if (!isLoaded) {
    forkTake = yield fork(yieldTakeAction, receiveAction(fetchAction.type))

    if (!isLoading) {
      yield put(fetchAction)
    }
  }

  yield delay(TRANSITION_DELAY)

  if (!isLoaded) {
    yield put(showMention(action, false))
    yield join(forkTake)
  }

  yield put(showMention(action, true))

  yield spawn(() =>
    mixpanel.track('Popover View', {
      user_id: userId,
      source_type: sourceType,
    })
  )
}

function* hideSaga(action) {
  yield delay(TRANSITION_DELAY)
  yield put(hideMention(action))
}

function* watchMouse() {
  yield takeEvery(MOUSE_LEAVE, hideSaga)

  while (true) {
    const action = yield take(MOUSE_ENTER)
    yield race({
      show: call(showSaga, action),
      hide: take(MOUSE_LEAVE),
    })
  }
}

export function* rootSaga() {
  yield all([watchMouse()])
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_MENTION: {
      return {
        ...state,
        isActive: true,
      }
    }

    case SHOW_MENTION: {
      return {
        ...state,
        isOpen: true,
        current: {
          ...action,
          scrollTop: (document.scrollingElement || document.documentElement)
            .scrollTop,
        },
      }
    }

    case HIDE_MENTION: {
      if (!action.force && state.isOpen && state.isActive) {
        return state
      }

      return {
        ...state,
        isOpen: false,
        isActive: false,
      }
    }

    default:
      return state
  }
}
