import React from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import classNames from 'classnames'

import { getUserName } from 'mednet-util/src/string'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { Subtitle1, Subtitle2 } from 'pharmacy/src/typography'

import InvitedExpertChip from './invitedExpertChip'

import InstitutionInformation from './institutionInformation'

import css from './userDescription.scss'

class UserDescription extends React.Component {
  static defaultProps = {
    NameComponent: Subtitle1,
    useUserNameLink: true,
  }

  render() {
    const {
      NameComponent,
      theme,
      userId,
      user,
      useUserNameLink,
      onProfileClick = noop,
      highlightIfExpert,
    } = this.props

    if (isEmpty(user)) {
      return null
    }

    const { title } = user

    const nameContainerClasses = classNames(
      css.nameContainer,
      css[`nameContainer_${theme}`]
    )
    const addExpertBadge = highlightIfExpert && user?.isExpert
    const nameClasses = classNames(css.name, css[`name_${theme}`], {
      [css[`nameWithBadge`]]: addExpertBadge,
    })

    return (
      <div className={nameContainerClasses}>
        <div>
          <NameComponent className={nameClasses}>
            {useUserNameLink ? (
              <UserNameLink
                userId={userId}
                user={user}
                external
                target="_blank"
                className={css.userLink}
                onClick={onProfileClick}
              />
            ) : (
              <div className={css.userText}>{getUserName(user)}</div>
            )}
          </NameComponent>
          {theme !== 'extended' && addExpertBadge && <InvitedExpertChip />}
        </div>

        {theme === 'extended' && title && (
          <>
            {addExpertBadge && <InvitedExpertChip />}
            <Subtitle2 className={css.institutionInformation}>
              {title}
            </Subtitle2>
          </>
        )}
        <Subtitle2
          className={classNames(css.institutionInformation, {
            [css.notExtendedInstitutionInformation]: theme !== 'extended',
          })}
        >
          <InstitutionInformation user={user} />
        </Subtitle2>
      </div>
    )
  }
}

export default UserDescription
