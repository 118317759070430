import _ from 'lodash'

export const renderMixed = (datum, spec) => {
  switch (typeof spec) {
    case 'string': {
      return _.get(datum, spec)
    }
    case 'function': {
      return spec(datum)
    }
    default: {
      if (process.env.DEBUG) {
        // eslint-disable-next-line no-console
        console.error('undefined behavior on rendering: ', typeof spec)
      }
      return null
    }
  }
}
