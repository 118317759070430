import React from 'react'

import { ProximaNova } from 'pharmacy/src/typography'

import css from './pageTitle.scss'

export const PageTitle = (props) => {
  const { children } = props

  return (
    <div className={css.titleLabel}>
      <ProximaNova.LabelWhiteText>{children}</ProximaNova.LabelWhiteText>
    </div>
  )
}
