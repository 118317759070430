import _ from 'lodash'
import { takeEvery, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_FEED = 'feed/FETCH_FEED'

export function fetchFeed(
  feedName,
  feedURL,
  feedNameKey,
  itemKey,
  { pageNumber = 0, pageSize = 20, feedParams = null } = {}
) {
  return {
    type: FETCH_FEED,
    requestId: feedNameKey,
    feedName,
    feedURL,
    feedNameKey,
    pageNumber,
    pageSize,
    feedParams,
    itemKey,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeEvery, FETCH_FEED, (action) =>
    makeURL(action.feedURL, {
      feed: action.feedName,
      pageNumber: action.pageNumber || 0,
      pageSize: action.pageSize,
      ...action.feedParams,
    })
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  feeds: {},
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_FEED): {
      const feed = state.feeds[action.feedNameKey] || {
        items: [],
        pageNumber: 0,
      }

      return receiveReducer(state, action, () => {
        let newFeed

        if (action.response.items.length) {
          newFeed = {
            ...feed,
            pageNumber: Math.max(action.pageNumber, feed.pageNumber),
            items: _.uniq([
              ...feed.items.slice(0, action.pageNumber * action.pageSize),
              ...action.response.items.map((item) =>
                action.itemKey ? item[action.itemKey] : item
              ),
              ...feed.items.slice((action.pageNumber + 1) * action.pageSize),
            ]),
            hasMorePages: action.response.items.length === action.pageSize,
          }
        } else {
          newFeed = {
            ...feed,
            hasMorePages: false,
          }
        }

        return {
          feeds: {
            ...state.feeds,
            [action.feedNameKey]: newFeed,
          },
        }
      })
    }

    default:
      return state
  }
}
