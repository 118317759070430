import React from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'

import { UserLink } from 'pharmacy/src/navigation/userLink'

import { makeImageURL } from 'mednet-util/src/router'

import css from './userImage.scss'

class UserImage extends React.Component {
  renderLoading() {
    const classes = classNames(css.imageLoading, this.props.className)

    return <div className={classes} />
  }

  render() {
    const {
      userId,
      imageVersion,
      imageSize,
      className,
      isLoading,
      useUserProfileLink,
      addHalo,
      onProfileClick = noop,
    } = this.props
    const classes = classNames(css.image, className, {
      [css.withHalo]: addHalo,
    })

    if (isLoading) {
      return this.renderLoading()
    }

    if (useUserProfileLink) {
      return (
        <UserLink
          userId={userId}
          external
          target="_blank"
          className={css.imageLink}
          onClick={onProfileClick}
        >
          <img
            className={classes}
            src={makeImageURL(userId || 'nophoto', imageVersion, imageSize)}
          />
        </UserLink>
      )
    }
    return (
      <img
        className={classes}
        src={makeImageURL(userId || 'nophoto', imageVersion, imageSize)}
      />
    )
  }
}

export default UserImage
