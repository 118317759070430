import React from 'react'

import BaseSelect from './baseSelect'
import { SimpleDropdownIndicator } from './components'

import './simpleSelect.scss'

const STYLE_OVERRIDES = [
  'control',
  'indicatorSeparator',
  'dropdownIndicator',
  'menu',
  'singleValue',
  'valueContainer',
  'option',
  'indicatorsContainer',
]

class SimpleSelect extends React.Component {
  render() {
    return (
      <BaseSelect
        isSearchable={false}
        blurInputOnSelect
        classNamePrefix="react-select-simple"
        styleOverrides={STYLE_OVERRIDES}
        components={{
          DropdownIndicator: SimpleDropdownIndicator,
        }}
        {...this.props}
      />
    )
  }
}

export default SimpleSelect
