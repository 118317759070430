import { all, takeLatest } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from 'mednet-cns/src/cns-util/reducer'

export const FETCH_TUMOR_BOARD = 'tumorboard/FETCH_TUMOR_BOARD'
export const FETCH_TUMOR_BOARD_USERS = 'tumorboard/FETCH_TUMOR_BOARD_USERS'
export const SET_TUMOR_BOARD_DISEASE_SITE_FILTER =
  'tumorboard/SET_TUMOR_BOARD_DISEASE_SITE_FILTER'
export const SET_TUMOR_BOARD_INSTITUTION_FILTER =
  'tumorboard/SET_TUMOR_BOARD_INSTITUTION_FILTER'

export function FetchTumorBoards() {
  return {
    type: FETCH_TUMOR_BOARD,
  }
}

export function FetchTumorBoardsUsers() {
  return {
    type: FETCH_TUMOR_BOARD_USERS,
  }
}

export function SetDiseaseSiteFilter(diseaseSiteId) {
  return {
    type: SET_TUMOR_BOARD_DISEASE_SITE_FILTER,
    diseaseSiteId,
  }
}

export function SetInstitutionFilter(institutionId) {
  return {
    type: SET_TUMOR_BOARD_INSTITUTION_FILTER,
    institutionId,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_TUMOR_BOARD,
    'Tumorboard/TumorboardPublicJSON'
  )
  yield makeFetchEffect(
    takeLatest,
    FETCH_TUMOR_BOARD_USERS,
    'Tumorboard/TumorboardUsersPublicJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {}

export function reducer(tumorBoardState = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_TUMOR_BOARD):
      return receiveReducer(tumorBoardState, action, () => ({
        subspecialtiesTumorBoards: action.response,
      }))

    case receiveAction(FETCH_TUMOR_BOARD_USERS): {
      const tumorBoardsExperts = {}
      const tumorBoardsSiteLeaders = {}

      Object.entries(action.response.experts).forEach(
        ([tumorBoardId, tumorBoardExperts]) => {
          tumorBoardsExperts[tumorBoardId] = tumorBoardExperts.map(
            (expert) => expert.userId
          )
        }
      )

      Object.entries(action.response.siteLeaders).forEach(
        ([tumorBoardId, tumorBoardSiteLeader]) => {
          tumorBoardsSiteLeaders[tumorBoardId] = ((siteLeader) =>
            siteLeader.userId)(tumorBoardSiteLeader)
        }
      )

      return receiveReducer(tumorBoardState, action, () => ({
        tumorBoardsExperts,
        tumorBoardsSiteLeaders,
      }))
    }

    case SET_TUMOR_BOARD_DISEASE_SITE_FILTER:
      return {
        ...tumorBoardState,
        diseaseSiteFilter: action.diseaseSiteId,
      }

    case SET_TUMOR_BOARD_INSTITUTION_FILTER:
      return {
        ...tumorBoardState,
        institutionFilter: action.institutionId,
      }

    default:
      return tumorBoardState
  }
}
