import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'formik'
import _ from 'lodash'

import { Button } from 'pharmacy/src/input/button'
import { Subtitle2 } from 'pharmacy/src/typography'
import { TagSearch } from 'pharmacy/src/display/tagSearch'
import { TextEditor } from 'pharmacy/src/input/textEditor'
import { ContainedSelect } from 'pharmacy/src/input/select'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { makeRequestName } from 'mednet-cns/src/reducers/request'
import {
  fetchSubspecialtyTopics,
  FETCH_SUBSPECIALTY_TOPIC_MAPPINGS,
} from 'mednet-cns/src/reducers/subspecialtyTopic'

import { HintedQuestionInput } from 'components/hintedQuestionInput'

import { ErrorPage } from 'pages/error'

import css from './createQuestionForm.scss'

class CreateQuestionForm extends React.Component {
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.specialtyId !== this.props.specialtyId) {
      this.fetchData()
    }
  }

  fetchData = (callback) => {
    this.props.fetchSubspecialtyTopics(callback, this.props.specialtyId)
  }

  handleSelectChange = (fieldName) => (selected) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched(fieldName)
    setFieldValue(fieldName, selected)
  }

  handleQuestionChange = (value) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched('question')
    setFieldValue('question', value)
  }

  handleTopicChange = (value) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched('subspecialtyTopic')
    setFieldValue('subspecialtyTopic', value)
  }

  handleTextChange = (fieldName) => (val) => {
    const { setFieldValue, setFieldTouched } = this.props

    setFieldTouched(fieldName)
    setFieldValue(fieldName, val)
  }

  handleUserChange = (users) => {
    const { setFieldValue, setFieldTouched } = this.props
    setFieldTouched('users')
    setFieldValue('users', users)
  }

  getOptionLabel = (option) => option.topic

  getOptionValue = (option) => option.subspecialtyTopic

  render() {
    const {
      errors,
      formRef,
      isSubmitting,
      touched,
      values,
      subspecialtyTopics,
      subspecialtyTopicsLoaded,
      isError,
      errorStatus,
      handleQuestionSubmit,
      submitCount,
      userData,
    } = this.props

    const { anonymous, question, subspecialtyTopic, detail, users } = values

    const submitIsDisabled = _.isEmpty(touched) || !_.isEmpty(errors)

    if (!subspecialtyTopicsLoaded) {
      return <StarLoader />
    }

    if (isError) {
      return <ErrorPage errorStatus={errorStatus} />
    }

    const questionCSS = [css.textArea]
    const topicCSS = []
    submitCount > 0 && errors.question && questionCSS.push(css.requiredErr)
    submitCount > 0 &&
      errors.subspecialtyTopic &&
      topicCSS.push(css.requiredErr)

    return (
      <form className={css.form} ref={formRef}>
        <div className={css.inputContainer}>
          <Subtitle2 className={css.required}>Question:</Subtitle2>
          <HintedQuestionInput
            userData={userData}
            onSuggestionSelect={this.onSuggestionSelect}
            inputProps={{
              name: 'question',
              className: questionCSS.join(' '),
            }}
            value={question || ''}
            onChange={this.handleQuestionChange}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2 className={css.required}>Topic:</Subtitle2>
          <ContainedSelect
            maxMenuHeight="150"
            options={subspecialtyTopics}
            getOptionLabel={this.getOptionLabel}
            getOptionValue={this.getOptionValue}
            onChange={this.handleTopicChange}
            name="subspecialtyTopic"
            value={subspecialtyTopic}
            className={topicCSS.join(' ')}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>Detail: (optional)</Subtitle2>
          <TextEditor
            id="detail"
            name="detail"
            onEditorChange={this.handleTextChange('detail')}
            value={detail}
          />
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>
            <label>
              <Field
                type="checkbox"
                name="anonymous"
                value="true"
                checked={anonymous}
              />
              Post anonymously
            </label>
          </Subtitle2>
        </div>
        <div className={css.inputContainer}>
          <Subtitle2>
            Send question link to following users: (optional)
          </Subtitle2>
          <TagSearch
            index="user"
            name="users"
            onChange={this.handleUserChange}
            value={users}
            isMulti
            className={css.selectUser}
            openMenuOnClick
          />
        </div>
        <div className={css.buttonsContainer}>
          <Button
            onClick={(e) => {
              this.props.values.redirectTo = 'poll'
              handleQuestionSubmit(e)
            }}
            isLoading={isSubmitting}
            isDisabled={submitIsDisabled}
            type="secondary"
          >
            Submit and add poll
          </Button>
          <Button
            onClick={(e) => {
              this.props.values.redirectTo = 'question'
              handleQuestionSubmit(e)
            }}
            isLoading={isSubmitting}
            isDisabled={submitIsDisabled}
          >
            Submit question
          </Button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { specialtyId, values, initialSubspecialtyTopic } = ownProps
  var { subspecialtyTopic } = values
  const subspecialtyTopics = state.subspecialtyTopic[ownProps.specialtyId]

  if (
    subspecialtyTopics !== undefined &&
    initialSubspecialtyTopic !== undefined &&
    subspecialtyTopic === undefined
  ) {
    subspecialtyTopics.forEach((item) => {
      const { subspecialtyTopic: st } = item
      if (st === initialSubspecialtyTopic) {
        subspecialtyTopic = item
      }
    })
  }

  const userData = state.user.data
  const specialtyTopicsRequest =
    _.get(
      state.request.requests,
      makeRequestName(FETCH_SUBSPECIALTY_TOPIC_MAPPINGS, specialtyId)
    ) || {}

  values.subspecialtyTopic = subspecialtyTopic
  return {
    isError: specialtyTopicsRequest.isError,
    errorStatus: specialtyTopicsRequest.errorStatus,
    subspecialtyTopicsLoaded: !specialtyTopicsRequest.isLoading,
    subspecialtyTopics,
    userData,
    subspecialtyTopic,
    values,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { specialtyId } = ownProps

  return {
    fetchSubspecialtyTopics: (callback) =>
      dispatch(fetchSubspecialtyTopics(callback, specialtyId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionForm)
