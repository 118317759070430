import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { components } from 'react-select'

import { Icon } from 'pharmacy/src/display/icon'

import css from './components.scss'

export class SimpleDropdownIndicator extends React.Component {
  render() {
    const classes = classNames(this.props.className, {
      [css.indicator_focused]: this.props.isFocused,
    })

    return (
      <components.DropdownIndicator {...this.props} className={classes}>
        <Icon icon={['fal', 'arrow-down']} size="xs" />
      </components.DropdownIndicator>
    )
  }
}

export class SimpleSolidDropdownIndicator extends React.Component {
  render() {
    const classes = classNames(this.props.className, {
      [css.indicator_focused]: this.props.isFocused,
    })

    return (
      <components.DropdownIndicator {...this.props} className={classes}>
        <Icon icon={['fas', 'caret-down']} size="sm" />
      </components.DropdownIndicator>
    )
  }
}

export const overrideStyles = (styles) => {
  return _.transform(
    styles,
    (acc, style) => {
      if (_.isString(style)) {
        acc[style] = () => ({})
      }
      if (_.isObject(style)) {
        acc[style.name] = (base) => {
          const styles = { ...base }

          style.remove && style.remove.forEach((name) => delete styles[name])

          return styles
        }
      }
    },
    {}
  )
}
