import { useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import { createDataHook } from 'mednet-cns/src/hook-factory'
import {
  createPreset,
  deletePreset,
  fetchPresets,
} from 'mednet-cns/src/reducers/preset'

export const useCreatePreset = (callback) => {
  const dispatch = useDispatch()

  return (data) => dispatch(createPreset(data, callback))
}

export const useDeletePreset = (callback) => {
  const dispatch = useDispatch()

  return (data) => dispatch(deletePreset(data, callback))
}

export const [usePresets, usePresetsLazy] = createDataHook(
  fetchPresets,
  createSelector(
    (state) => state.preset || {},
    (preset) => {
      const presets = preset.presets || []
      return sortBy(presets, 'name')
    }
  )
)
