import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import css from './index.scss'

const withStyle = (styleName) => {
  return class Typography extends React.Component {
    static displayName = _.upperFirst(styleName)

    static defaultProps = {
      component: 'div',
    }

    render() {
      const { component, className, forwardRef, important, ...innerProps } =
        this.props
      const Component = component
      const classes = classNames(
        important ? css[`${styleName}_important`] : css[styleName],
        className
      )

      return (
        <Component className={classes} ref={forwardRef} {...innerProps}>
          {this.props.children}
        </Component>
      )
    }
  }
}

const Link1 = withStyle('link1')
const Link2 = withStyle('link2')
const LinkHeader = withStyle('linkHeader')
const Subtitle1 = withStyle('subtitle1')
const Subtitle2 = withStyle('subtitle2')
const Subtitle3 = withStyle('subtitle3')
const Subtitle4 = withStyle('subtitle4')
const Body1 = withStyle('body1')
const Label1 = withStyle('label1')
const PageHeader = withStyle('pageHeader')
const Header1 = withStyle('header1')
const Header2 = withStyle('header2')
const Header3 = withStyle('header3')
const Header4 = withStyle('header4')
const Hero = withStyle('hero')
const HeroBody = withStyle('heroBody')
const Subtext = withStyle('subtext')
const ProximaNova = {
  Header1: withStyle('proximaNovaheader1'),
  Header2: withStyle('proximaNovaheader2'),
  Header3: withStyle('proximaNovaheader3'),
  Header4: withStyle('proximaNovaheader4'),
  Text: withStyle('proximaNovatext'),
  Text2: withStyle('proximaNovatext2'),
  Subtitle1: withStyle('proximaNovasubtitle1'),
  Subtitle2: withStyle('proximaNovasubtitle2'),
  Subtitle3: withStyle('proximaNovasubtitle3'),
  LabelWhiteText: withStyle('proximaNovalabelWhiteText'),
  InputText: withStyle('proximaNovalabelinputText'),
}

export {
  Hero,
  PageHeader,
  Header1,
  Header2,
  Header3,
  Header4,
  HeroBody,
  Body1,
  Label1,
  LinkHeader,
  Link1,
  Link2,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  Subtitle4,
  Subtext,
  ProximaNova,
}
