import React from 'react'
import { connect } from 'react-redux'

import { Subtitle3, Subtitle1 } from 'pharmacy/src/typography'
import { UserNameLink } from 'pharmacy/src/navigation/userNameLink'
import { FollowUserButton } from 'pharmacy/src/user/followUserButton'

import css from './questionAuthor.scss'

class QuestionAuthor extends React.Component {
  render() {
    const { questionId, isAnonymous, userId, stubbed, fullScreen } = this.props

    if (!isAnonymous) {
      return (
        <div>
          <div
            className={
              fullScreen ? css.inlinePostedByContainer : css.postedByContainer
            }
          >
            <Subtitle3 className={css.postedBy}> Posted by: </Subtitle3>
            <Subtitle1>
              <UserNameLink userId={userId} className={css.name} />
            </Subtitle1>
            <FollowUserButton
              userId={userId}
              className={css.followButtonPrint}
              callLocation="question"
              questionId={questionId}
              stubbed={stubbed}
            />
          </div>
          {!fullScreen && <hr className={css.divider} />}
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const questionData = state.question.questions[questionId]
  const { userId, isAnonymous } = questionData

  return {
    userId,
    isAnonymous,
  }
}

export default connect(mapStateToProps)(QuestionAuthor)
