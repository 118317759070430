import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import { Formik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'
import moment from 'moment'

import { SPONSORSHIP_INJECT_QUESTION_MODAL } from 'mednet-util/src/constants/modal'
import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'

import { Modal } from 'pharmacy/src/display/modal'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { ContainedSelect } from 'pharmacy/src/input/select'
import { DatePicker } from 'pharmacy/src/input/datePicker'
import { Button } from 'pharmacy/src/input/button'

import { closeModal } from 'mednet-cns/src/reducers/modal'
import {
  fetchSpecialties as fetchSpecialtiesAction,
  getSpecialties,
} from 'mednet-cns/src/reducers/specialty'
import {
  fetchSponsorships as fetchSponsorshipsAction,
  fetchSponsorship as fetchSponsorshipAction,
  getAllSponsorships,
  getAreSponsorshipsLoading,
  getSponsorship,
  getSponsorshipRequest,
  addQuestionInjection,
} from 'mednet-cns/src/reducers/sponsorship'
import {
  fetchQuestionMetadata as fetchQuestionMetadataAction,
  fetchQuestionMetadataRequest,
  fetchQuestionUpdates as fetchQuestionUpdatesAction,
  fetchQuestionUpdatesRequest,
  getQuestion,
  getUpdates,
} from 'mednet-cns/src/reducers/question'

import UpdateCard from 'components/updateCard/updateCard'

import * as css from './injectQuestion.scss'

function InjectScreen({ setLoader }) {
  const dispatch = useDispatch()
  const closeInjectionModal = () =>
    dispatch(closeModal(SPONSORSHIP_INJECT_QUESTION_MODAL.modalId))
  const injectQuestion = (values) => {
    setLoader && setLoader(true)
    dispatch(
      addQuestionInjection(values, () => {
        setLoader && setLoader(false)
      })
    )
    closeInjectionModal()
  }

  return (
    <Formik
      initialValues={{
        sponsorshipId: null,
        questionId: null,
        injectionDate: moment(new Date()).format('YYYY-MM-DD'),
        updateId: null,
      }}
      validationSchema={injectionSchema}
      onSubmit={injectQuestion}
    >
      {(props) => <InjectionForm {...props} />}
    </Formik>
  )
}

const injectionSchema = Yup.object().shape({
  sponsorshipId: Yup.string().required(),
  specialtyId: Yup.string().required(),
  questionId: Yup.string().required(),
  injectionDate: Yup.string().required(),
  updateId: Yup.string().required(),
})

function InjectionForm({ setFieldValue, values, handleSubmit, errors }) {
  const dispatch = useDispatch()

  const fetchSpecialties = () => dispatch(fetchSpecialtiesAction())
  const specialties = useSelector((state) => getSpecialties(state))
  const specialtiesOptions = useMemo(
    () => [
      { label: 'any', value: null },
      ...sortBy(
        map(specialties, (specialty) => ({
          label: specialty.specialty,
          value: specialty.specialtyId,
        })),
        ['label']
      ),
    ],
    [specialties]
  )

  const fetchSponsorships = () => dispatch(fetchSponsorshipsAction())
  const fetchSponsorship = (sponsorshipId) =>
    dispatch(fetchSponsorshipAction(sponsorshipId))

  const sponsorships = useSelector((state) => getAllSponsorships(state))
  const sponsorshipsAreLoading = useSelector((state) =>
    getAreSponsorshipsLoading(state)
  )
  const sponsorshipOptions = useMemo(
    () => [
      ...map(sponsorships, ({ description, sponsorshipId }) => ({
        label: description,
        value: `${sponsorshipId}`,
      })),
    ],
    [sponsorships]
  )

  const sponsorship = useSelector((state) =>
    getSponsorship(state, values.sponsorshipId)
  )
  const sponsorshipIsLoading = useSelector((state) =>
    getSponsorshipRequest(state, values.sponsorshipId)
  ).isLoading
  const questionOptions = useMemo(
    () => [
      ...map(sponsorship.questions, ({ question, questionId }) => ({
        label: question,
        value: `${questionId}`,
      })),
    ],
    [sponsorship]
  )

  const updates = useSelector((state) => getUpdates(state, values.questionId))
  const question = useSelector((state) => getQuestion(state, values.questionId))
  const questionUpdatesRequest = useSelector((state) =>
    fetchQuestionUpdatesRequest(state, values.questionId)
  )
  const questionMetadataRequest = useSelector((state) =>
    fetchQuestionMetadataRequest(state, values.questionId)
  )
  const fetchQuestionUpdates = () =>
    dispatch(
      fetchQuestionUpdatesAction(values.questionId, [
        actionIdByName.ANSWER,
        actionIdByName.ANSWER_UPDATED,
      ])
    )
  const fetchQuestion = () =>
    dispatch(fetchQuestionMetadataAction(values.questionId))

  useEffect(() => {
    fetchSpecialties()
    fetchSponsorships()
  }, [])

  useEffect(() => {
    values.sponsorshipId && fetchSponsorship(values.sponsorshipId)
  }, [values.sponsorshipId])

  useEffect(() => {
    values.questionId && fetchQuestionUpdates(values.questionId)
    values.questionId && fetchQuestion(values.questionId)
  }, [values.questionId])

  if (sponsorshipsAreLoading) {
    return <StarLoader />
  }

  return (
    <form className={css.form}>
      <label className={css.field}>
        <span className={css.labelText}>Date</span>
        <DatePicker
          className={css.datepicker}
          value={values.injectionDate}
          onChange={(value) =>
            setFieldValue('injectionDate', moment(value).format('YYYY-MM-DD'))
          }
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>Specialty</span>
        <ContainedSelect
          searchable
          className={classNames(css.select)}
          options={specialtiesOptions}
          value={specialtiesOptions.find(
            (option) => option.value === Number(values.specialtyId)
          )}
          onChange={({ value }) => setFieldValue('specialtyId', `${value}`)}
        />
      </label>
      <label className={css.field}>
        <span className={css.labelText}>Sponsorship</span>
        <ContainedSelect
          searchable
          className={css.select}
          options={sponsorshipOptions}
          value={sponsorshipOptions.find(
            (option) => option.value === values.sponsorshipId
          )}
          onChange={({ value }) => {
            setFieldValue('sponsorshipId', value)
            setFieldValue('questionId', null)
            setFieldValue('updateId', null)
          }}
        />
      </label>
      {!values.sponsorshipId ? null : sponsorshipIsLoading ? (
        <StarLoader size="small" />
      ) : (
        <>
          <label className={css.field}>
            <span className={css.labelText}>Question</span>
            <ContainedSelect
              searchable
              className={css.select}
              options={questionOptions}
              value={questionOptions.find(
                (option) => option.value === values.questionId
              )}
              onChange={({ value }) => {
                setFieldValue('questionId', value)
                setFieldValue('updateId', null)
              }}
            />
          </label>
          {questionUpdatesRequest.isLoading ||
          questionMetadataRequest.isLoading ? (
            <StarLoader size="small" />
          ) : (
            updates.map((update) => (
              <div
                key={update.questionUpdateId}
                className={classNames(css.update, {
                  [css.selected]: values.updateId === update.questionUpdateId,
                })}
              >
                <UpdateCard update={update} question={question} hideInfo />
                <Button
                  icon="check"
                  size="small"
                  type={
                    values.updateId === update.questionUpdateId
                      ? 'primary'
                      : 'secondary'
                  }
                  onClick={() =>
                    setFieldValue('updateId', update.questionUpdateId)
                  }
                  className={css.selectUpdate}
                >
                  {values.updateId === update.questionUpdateId
                    ? 'Selected'
                    : 'Select'}
                </Button>
              </div>
            ))
          )}
        </>
      )}
      <Button
        type="primary"
        onClick={handleSubmit}
        isDisabled={!isEmpty(errors) || !values.sponsorshipId}
      >
        Submit
      </Button>
    </form>
  )
}

const screens = {
  [SPONSORSHIP_INJECT_QUESTION_MODAL.screens.inject]: InjectScreen,
}

export default function InjectQuestionModal(props) {
  return (
    <Modal
      modalId={SPONSORSHIP_INJECT_QUESTION_MODAL.modalId}
      defaultScreen={SPONSORSHIP_INJECT_QUESTION_MODAL.screens.inject}
      screens={screens}
      {...props}
    />
  )
}
