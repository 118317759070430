import React from 'react'
import _ from 'lodash'
import { decode } from 'html-entities'

import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'
import { Body1 } from 'pharmacy/src/typography'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'

class UpdatePreview extends React.Component {
  static defaultProps = {
    TextComponent: Body1,
    showMore: true,
  }

  render() {
    const { item, questionLinkProps, TextComponent, showMore } = this.props
    const { actionId, answer, comment, questionId } = item

    const useAnswer =
      actionId === actionIdByName.ANSWER ||
      actionId === actionIdByName.ANSWER_UPDATED

    if (useAnswer) {
      return (
        <TextComponent>
          {decode(answer.answerPreview)}{' '}
          {showMore && _.endsWith(answer.answerPreview, '...') && (
            <QuestionLink questionId={questionId} {...questionLinkProps}>
              more
            </QuestionLink>
          )}
        </TextComponent>
      )
    }

    if (actionId === actionIdByName.COMMENT) {
      return (
        <TextComponent>
          {decode(comment.commentPreview)}{' '}
          {showMore && _.endsWith(comment.commentPreview, '...') && (
            <QuestionLink questionId={questionId} {...questionLinkProps}>
              {' '}
              more
            </QuestionLink>
          )}
        </TextComponent>
      )
    }

    return null
  }
}

export default UpdatePreview
