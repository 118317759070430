import React from 'react'
import _ from 'lodash'

import { Icon } from 'pharmacy/src/display/icon'
import { Subtitle2 } from 'pharmacy/src/typography'

import Button from './button'

import css from './buttonUpload.scss'

const FILE_ICONS = {
  pdf: 'file-pdf',
  doc: 'file-word',
  docx: 'file-word',
  ppt: 'file-powerpoint',
  pptx: 'file-powerpoint',
  csv: 'file-spreadsheet',
}

const FILE_ICONS_COLORS = {
  pdf: '#c13429',
  doc: '#0064e7',
  docx: '#0064e7',
  ppt: '#d94102',
  pptx: '#d94102',
  csv: '#009e5d',
  ['']: '#0064e7',
}

class ButtonUpload extends React.Component {
  constructor(props) {
    super(props)

    this.handleUpload = this.handleUpload.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.inputRef = React.createRef()

    this.state = {
      file: null,
    }
  }

  componentDidMount() {
    this.setState({
      file: this.props.file,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.setState({
        file: this.props.file,
      })
    }
  }

  handleUpload(event) {
    const { inputProps } = this.props
    const { maxFileSize, onChange } = inputProps

    const file = event.currentTarget.files[0]

    if (maxFileSize) {
      const fileSize = file.size
      const maxFileSizeInBytes = maxFileSize * 1024
      if (fileSize > maxFileSizeInBytes) {
        alert(
          `File size exceeds ${maxFileSize}KB limit, please choose a smaller file.`
        )
        return
      }
    }

    this.setState({ file }, () => onChange && onChange(file))
  }

  handleClear(event) {
    event.preventDefault() // To prevent showing the file browser
    this.inputRef.current.value = ''
    const { inputProps } = this.props
    const { onChange } = inputProps
    this.setState({ file: null }, () => onChange && onChange(undefined))
  }

  render() {
    const { inputProps = {}, buttonProps = {} } = this.props
    const { file } = this.state

    const icon = file
      ? _.get(FILE_ICONS, file.name.split('.')[1], 'file')
      : 'file-upload'

    const iconColor = file
      ? _.get(FILE_ICONS_COLORS, file.name.split('.')[1], 'file')
      : FILE_ICONS_COLORS['']

    return (
      <div className={css.container}>
        <label>
          <input
            type="file"
            className={css.input}
            {...inputProps}
            onChange={this.handleUpload}
            ref={this.inputRef}
          />
          <div className={css.iconContainer}>
            <Icon
              prefix="far"
              icon={icon}
              size="3x"
              style={{ color: iconColor }}
            />
          </div>
          {file && (
            <Subtitle2 className={css.fileName}>
              {file.name}{' '}
              <Icon
                className={css.clearIcon}
                onClick={this.handleClear}
                prefix="fas"
                icon="times-circle"
              />
            </Subtitle2>
          )}
          <Button {...buttonProps}>Upload File</Button>
        </label>
      </div>
    )
  }
}

export default ButtonUpload
