import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'

import { Button } from 'pharmacy/src/input/button'
import StarLoader from 'pharmacy/src/misc/loaders/starLoader/starLoader'

import { makeAssetURL, makeImageURL } from 'mednet-util/src/router'
import { getUserName } from 'mednet-util/src/string'

import { usePublicTestimonials } from 'mednet-cns/src/hooks/testimonial'
import { useSelectUserById } from 'mednet-cns/src/hooks/user'

import css from './testimonials.scss'

const TestimonialCard = React.forwardRef(({ testimonial, className }, ref) => {
  const user = useSelectUserById(testimonial.userId)

  const { userId, institutionName, imageVersion } = user
  const imgUrl = makeImageURL(userId || 'nophoto', imageVersion)

  const userName = getUserName(user)
  return (
    <div
      className={classNames(css.testimonialCard, { [className]: className })}
      ref={ref}
    >
      <div className={css.userImageContainer}>
        <img src={imgUrl} className={css.userImage} loading="lazy" />
      </div>
      <div className={css.testimonialContent}>
        <div className={css.testimonialContentMainText}>
          &quot;{testimonial.testimonial}&quot;
        </div>
        <div className={css.userDetails}>
          <div className={css.userName}>{userName}</div>
          {institutionName && (
            <>
              <span className={css.userDetailsSeparator}>
                &nbsp;|&nbsp;&nbsp;
              </span>
              <div className={css.userInstitution}>{institutionName}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

TestimonialCard.displayName = 'TestimonialCard'

// should call useRef only after fetching data when we know how many refs we need, should not change order of hooks called in react,
// therefore move this to separate component
const TestimonialCards = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const cardsRefs = testimonials.map(() => useRef())

  const goNext = useCallback(() => {
    const nextIndex = Math.min(currentIndex + 1, testimonials.length - 1)
    // Scroll for all browsers but IE
    if (typeof document !== 'undefined' && !document.documentMode) {
      cardsRefs[nextIndex].current.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'nearest',
      })
    }

    setCurrentIndex(nextIndex)
  }, [currentIndex, setCurrentIndex, testimonials.length])

  const goPrev = useCallback(() => {
    const prevIndex = Math.max(currentIndex - 1, 0)
    // Scroll for all browsers but IE
    if (typeof document !== 'undefined' && !document.documentMode) {
      cardsRefs[prevIndex].current.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'nearest',
      })
    }

    setCurrentIndex(prevIndex)
  }, [currentIndex, setCurrentIndex])

  return (
    <div className={css.testimonialsWithControls}>
      <div className={css.testimonialCardsContainer}>
        {/* scrolling behaviour for all browsers but IE */}
        {typeof document !== 'undefined' &&
          !document.documentMode &&
          testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={testimonial.testimonialId}
              testimonial={testimonial}
              ref={cardsRefs[index]}
            />
          ))}
        {/* Only have one card for IE */}
        {(typeof document === 'undefined' || document.documentMode) &&
          testimonials[currentIndex] && (
            <TestimonialCard
              testimonial={testimonials[currentIndex]}
              className={css.testimonialCardWithPadding}
            />
          )}
        <div className={css.positionIndicator}>
          {testimonials.map((testimonial, index) => (
            <div
              key={testimonial.testimonialId}
              className={classNames({
                [css.activePosition]: currentIndex === index,
              })}
            />
          ))}
        </div>
        <Button
          type="dark_blue"
          shape="circle"
          className={css.prevButton}
          icon={['far', 'chevron-left']}
          onClick={goPrev}
          isDisabled={currentIndex <= 0}
        />
        <Button
          type="dark_blue"
          shape="circle"
          className={css.nextButton}
          icon={['far', 'chevron-right']}
          onClick={goNext}
          isDisabled={currentIndex >= testimonials.length - 1}
        />
      </div>
    </div>
  )
}

export const Testimonials = ({ assetsPath, titleClassName }) => {
  const [testimonials, testimonialsRequest] = usePublicTestimonials()

  if (testimonialsRequest.isLoading) {
    return <StarLoader isVerticalMargin />
  }

  return (
    <div
      className={css.testimonialsContainer}
      style={{
        backgroundImage: `url("${makeAssetURL(
          `${assetsPath}testimonials-background.svg`
        )}")`,
      }}
    >
      <div className={css.internalTestimonialsContainer}>
        {/* Need inner div for IE */}
        <div className={titleClassName}>
          <div>What physicians are saying</div>
        </div>
        <TestimonialCards testimonials={testimonials} />
      </div>
    </div>
  )
}
