import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import { fetchTopicsTree } from 'mednet-cns/src/reducers/topic'
import { createDataHook } from 'mednet-cns/src/hook-factory'

const renameTreeParams = (parent) => (topic) => {
  const node = {}
  node.title = topic.topic
  node.value = topic.topicId

  if (topic.children) {
    const children = sortBy(
      topic.children.map(renameTreeParams(topic)),
      'title'
    )
    children.unshift({
      title: node.title,
      value: node.value,
    })
    node.options = children
  }

  if (parent) {
    node.title = `${topic.topic} (${parent.topic})`
  }

  return node
}

export const [useTopicsTreeOptions, useTopicsTreeOptionsLazy] = createDataHook(
  fetchTopicsTree,
  createSelector(
    (state) => state.topic || {},
    (topic) => {
      const tree = topic.tree || []
      return sortBy(tree.map(renameTreeParams()), 'title')
    }
  ),
  true
)
