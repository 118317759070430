import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { Modal } from 'pharmacy/src/display/modal'
import { SPONSORSHIP_CREATE_QUESTION_MODAL } from 'mednet-util/src/constants/modal'
import { Header1 } from 'pharmacy/src/typography'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { createQuestion } from 'mednet-cns/src/reducers/sponsorship'

import _ from 'lodash'

import CreateQuestionForm from '../forms/createQuestionForm'

const CreateQuestionSchema = Yup.object().shape({
  question: Yup.string().required(),
  detail: Yup.string(),
  author: Yup.number(),
  topics: Yup.array(),
})

class CreateScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  handleSubmit = (values, actions) => {
    const { createQuestion, closeModal } = this.props

    const formData = new FormData(this.form.current)
    createQuestion(formData, () => {
      actions.setSubmitting(false)
      closeModal()
    })
  }

  render() {
    const { sponsorsIsLoading, sponsorship } = this.props
    const { topicId, topic, topics } = sponsorship

    if (sponsorsIsLoading) {
      return <StarLoader />
    }

    return (
      <div>
        <Header1>Create question</Header1>
        <hr />
        <Formik
          initialValues={{
            question: '',
            detail: '',
            author: '',
            topics: _.uniqWith(
              _.concat([], topics, [
                {
                  topicId,
                  topic,
                },
              ]),
              _.isEqual
            ),
          }}
          onSubmit={this.handleSubmit}
          validationSchema={CreateQuestionSchema}
        >
          {(props) => (
            <CreateQuestionForm
              {...props}
              {...this.props}
              formRef={this.form}
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { sponsorship } = ownProps

  return {
    createQuestion: (formData, callback) =>
      dispatch(createQuestion(sponsorship.sponsorshipId, formData, callback)),
  }
}

const ConnectedCreateScreen = connect(null, mapDispatchToProps)(CreateScreen)

class CreateQuestionModal extends React.Component {
  render() {
    return (
      <Modal
        closeOnBackdrop={false}
        modalId={SPONSORSHIP_CREATE_QUESTION_MODAL.modalId}
        {...this.props}
      >
        <ConnectedCreateScreen />
      </Modal>
    )
  }
}

export default CreateQuestionModal
