import React, { useState, useCallback } from 'react'
import { components } from 'react-select'

import { useUserProfile, useUserProfileLazy } from 'mednet-cns/src/hooks/user'

import { TagSelect } from 'pharmacy/src/input/select'
import { TextInput } from 'pharmacy/src/input/textInput'
import { ButtonCheckbox } from 'pharmacy/src/input/button'
import { Label1 } from 'pharmacy/src/typography'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import { makeAssetURL, makeImageURL } from 'mednet-util/src/router'

import { JOB_TYPE } from 'mednet-cns/src/reducers/systemJob'

import removeHTMLTags from 'anterior-util/removeHTMLTags'

import * as css from './dynamic.scss'
const baseUrl = `${process.env.WEB_SCHEME}://${process.env.WEB_DOMAIN}/`

const UserTemplate = ({
  user: {
    userId,
    imageVersion,
    firstName,
    lastName,
    institutionName,
    title,
    degrees,
  },
}) => {
  const [userWithProfile, { isLoading }] = useUserProfile(userId)

  if (isLoading) return <StarLoader size="small" />

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <img
              style={{
                marginRight: '8px',
                maxWidth: '50px',
                maxHeight: '50px',
                borderRadius: '50%',
              }}
              src={makeImageURL(userId || 'nophoto', imageVersion)}
            />
          </td>
          <td>
            <div style={{ float: 'left' }}>
              <div
                style={{
                  fontSize: '16px',
                  color: '#696e6f',
                  fontWeight: 'bold',
                }}
              >
                {firstName} {lastName}
                {!degrees.length ? null : `, ${degrees.join(',')}`}
              </div>
              <div style={{ fontSize: '12px', color: '#696e6f' }}>
                {title}
                {!institutionName ? null : `, ${institutionName}`}
              </div>
              <table style={{ fontSize: '12px', color: '#696e6f' }}>
                <tbody>
                  <tr>
                    <td>
                      <span style={{ fontSize: '16px' }}>
                        {userWithProfile.stats?.numAnswers}{' '}
                      </span>
                      Questions Answered
                    </td>
                    <td style={{ width: '10px' }}></td>
                    <td>
                      <span style={{ fontSize: '16px' }}>
                        {userWithProfile.stats?.numViews}{' '}
                      </span>
                      Content Views
                    </td>
                    <td style={{ width: '10px' }}></td>
                    <td>
                      <span style={{ fontSize: '16px' }}>
                        {userWithProfile.stats?.numReach}{' '}
                      </span>
                      Doctors Reached
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ clear: 'both' }}></div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const AnswerTemplate = ({
  questionId,
  answer: { answerId, answer, author, limited },
  campaignType,
  inviteQuestionHash,
  inviteQuestionCampaignId,
}) => {
  let link = baseUrl
  switch (campaignType) {
    case JOB_TYPE.INVITE:
      link += `campaign/question/${inviteQuestionHash}/${inviteQuestionCampaignId}/${questionId}?invitation_id=%inv%`
      break
    case JOB_TYPE.CAMPAIGN:
    default:
      link += `question/${questionId}?notification_id=%nid%`
  }
  link += `&answer_id=${answerId}`

  function unEscapeHtml(text) {
    return text
      .replace(/&amp;/g, '&')
      .replace(/&rsquo;/g, "'")
      .replace(/&quot/g, '"')
  }

  // Certain characters are being html escaped incorrectly, so we need to unescape them to render properly
  // https://themednet.atlassian.net/browse/ENG-1473

  const unEscapedAnswer = unEscapeHtml(answer)

  return (
    <>
      <UserTemplate user={author} />
      <p style={{ fontSize: '14px', lineHeight: '20px' }}>
        {unEscapedAnswer}{' '}
        {!limited ? null : (
          <a style={{ color: '#800000', fontWeight: 'bold' }} href={link}>
            (more)
          </a>
        )}
      </p>
    </>
  )
}

const PollOptionTemplate = ({
  campaignType,
  questionId,
  option,
  inviteQuestionCampaignId,
  inviteQuestionHash,
}) => {
  let link = baseUrl
  switch (campaignType) {
    case JOB_TYPE.INVITE:
      link += `campaign/question/${inviteQuestionHash}/${inviteQuestionCampaignId}/${questionId}?invitation_id=%inv%&option_id=${option?.optionId}`
      break
    case JOB_TYPE.CAMPAIGN:
    default:
      link += `question/${questionId}?notification_id=%nid%&option_id=${option?.optionId}`
  }
  link += `&poll=1`

  return (
    <div style={{ marginBottom: '10px', fontSize: '14px', lineHeight: '18px' }}>
      <a
        href={link}
        style={{ color: '#333333', textDecoration: 'none' }}
        target="_blank"
        rel="noreferrer"
      >
        <input
          name="vote"
          type="radio"
          style={{
            display: 'inline-block',
            borderRadius: '50%',
            width: '13px',
            height: '13px',
            float: 'left',
            marginTop: '2px',
            border: '1px solid gray',
            marginRight: '10px',
          }}
        ></input>
        {option.option}
        <br />
      </a>
    </div>
  )
}

const PollTemplate = ({
  poll: { options },
  questionId,
  campaignType,
  inviteQuestionCampaignId,
  inviteQuestionHash,
}) => {
  let link = baseUrl
  switch (campaignType) {
    case JOB_TYPE.INVITE:
      link += `campaign/question/${inviteQuestionHash}/${inviteQuestionCampaignId}/${questionId}?invitation_id=%inv%`
      break
    case JOB_TYPE.CAMPAIGN:
    default:
      link += `question/${questionId}?notification_id=%nid%`
  }
  link += `&poll=1`

  return (
    <div style={{ marginTop: '10px' }}>
      <div
        style={{
          color: '#2e3591',
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '12px',
        }}
      >
        Compare your practice with your peers
      </div>
      <div style={{ color: '#333333', textDecoration: 'none' }}>
        {options.map((option) => (
          <PollOptionTemplate
            key={option.optionId}
            questionId={questionId}
            option={option}
            campaignType={campaignType}
            inviteQuestionCampaignId={inviteQuestionCampaignId}
            inviteQuestionHash={inviteQuestionHash}
          />
        ))}
      </div>
      <div style={{ margin: '20px 0' }}>
        <a
          href={link}
          style={{
            borderRadius: '5px',
            backgroundColor: '#1b1f50',
            padding: '10px',
            color: '#fff',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            lineHeight: '70px',
          }}
          target="_blank"
          rel="noreferrer"
        >
          Click here to vote and see results
        </a>
      </div>
      <div style={{ fontStyle: 'italic', marginTop: '8px' }}>
        All votes are anonymous
      </div>
    </div>
  )
}

const EmblemTemplate = ({ tumorboard, journal, sponsorship }) => {
  let icon = ''
  let description = ''

  if (tumorboard) {
    icon = tumorboard.icon
    description = tumorboard.description
  } else if (journal) {
    icon = journal.icon
    description = journal.description
  } else if (sponsorship) {
    icon = sponsorship.icon
    description = sponsorship.description
  }

  if (!icon) {
    return null
  }

  return (
    <div style={{ marginBottom: '5px' }}>
      <div style={{ width: '160px', marginBottom: '5px' }}></div>
      <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 'bold' }}>
        <img
          src={makeAssetURL(icon)}
          width="20px"
          height="20px"
          style={{ marginRight: '5px', float: 'left' }}
        />
        <span>{description}</span>
      </div>
    </div>
  )
}

const QuestionTemplate = ({
  campaignType,
  question: { question, questionId, poll, tumorBoard, journal, sponsorship },
  answers,
  poll: usePoll,
  pollQuestion: usePollQuestion,
  inviteQuestionHash,
  inviteQuestionCampaignId,
}) => {
  let link = baseUrl
  switch (campaignType) {
    case JOB_TYPE.INVITE:
      link += `campaign/question/${inviteQuestionHash}/${inviteQuestionCampaignId}/${questionId}?invitation_id=%inv%`
      break
    case JOB_TYPE.CAMPAIGN:
    default:
      link += `question/${questionId}?notification_id=%nid%`
  }

  return (
    <>
      <div>
        <EmblemTemplate
          tumorboard={tumorBoard}
          journal={journal}
          sponsorship={sponsorship}
        />
        <div
          style={{
            fontSize: '18px',
            color: '#1b1f50',
            fontWeight: 'bold',
            marginBottom: '16px',
          }}
        >
          <a
            style={{ textDecoration: 'none', color: '#1b1f50' }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {usePollQuestion ? poll.pollQuestion : question}
          </a>
        </div>
      </div>
      {answers.map((answer) => (
        <AnswerTemplate
          key={answer.answerId}
          questionId={questionId}
          answer={answer}
          campaignType={campaignType}
          inviteQuestionHash={inviteQuestionHash}
          inviteQuestionCampaignId={inviteQuestionCampaignId}
        />
      ))}
      {!usePoll || !poll ? null : (
        <PollTemplate
          campaignType={campaignType}
          poll={poll}
          questionId={questionId}
          inviteQuestionHash={inviteQuestionHash}
          inviteQuestionCampaignId={inviteQuestionCampaignId}
        />
      )}
      <hr />
      <br />
      <br />
    </>
  )
}

const PollSelect = ({ poll, setPoll, pollQuestion, setPollQuestion }) => {
  return (
    <>
      <div className={css.settingsRow}>
        <Label1 className={css.tagSelectLabel}>Use poll:</Label1>
        <ButtonCheckbox
          buttonProps={{ size: 'small' }}
          selectText=""
          unselectText=""
          inputProps={{
            checked: poll,
            onChange: setPoll,
          }}
        />
      </div>
      <div className={css.settingsRow}>
        <Label1 className={css.tagSelectLabel}>Use poll question:</Label1>
        <ButtonCheckbox
          buttonProps={{ size: 'small' }}
          selectText=""
          unselectText=""
          inputProps={{
            checked: pollQuestion,
            onChange: setPollQuestion,
          }}
        />
      </div>
    </>
  )
}

const AnswersSelect = ({ answers, onAnswerSelect }) => {
  const [charLimit, setCharLimit] = useState(250)
  const [selectedMapped, setSelected] = useState([])
  const [recentAuthorId, setRecentAuthorId] = useState()
  // eslint-disable-next-line no-unused-vars
  const [_userWithProfile, { isLoading }] = useUserProfileLazy(recentAuthorId)

  const getOptionLabel = (answer) => {
    return answer.answerId
  }

  const getOptionValue = (answer) => {
    return answer.answerId
  }

  const Option = useCallback((props) => {
    const {
      answer,
      updated,
      created,
      user: { firstName, lastName },
    } = props.data
    const answerText = removeHTMLTags(answer)
    return (
      <components.Option {...props}>
        <div className={css.answerOptionAuthor}>
          Answer by {firstName} {lastName} on {updated ? updated : created}
        </div>
        <div className={css.answerOption}>{answerText}</div>
      </components.Option>
    )
  })

  const handleAnswerSelect = useCallback(
    (selected) => {
      const mapped = selected.map(({ answer, answerId, user }) => ({
        answerId,
        answer: charLimit
          ? `${removeHTMLTags(answer).substr(0, charLimit)}...`
          : removeHTMLTags(answer),
        originalAnwer: answer,
        author: user,
        limited: Boolean(charLimit),
      }))
      setSelected(mapped)
      onAnswerSelect && onAnswerSelect(mapped)
      setRecentAuthorId(mapped[mapped.length - 1]?.author.userId)
    },
    [onAnswerSelect, charLimit]
  )

  const handleCharLimitChange = useCallback(
    (value) => {
      setCharLimit(value)
      const mapped = selectedMapped.map(
        ({ answer: originalAnwer, answerId, author }) => ({
          answerId,
          answer: value
            ? `${removeHTMLTags(originalAnwer).substr(0, value)}...`
            : removeHTMLTags(originalAnwer),
          originalAnwer,
          author,
          limited: Boolean(value),
        })
      )
      onAnswerSelect && onAnswerSelect(mapped)
    },
    [selectedMapped]
  )

  return (
    <>
      <div className={css.settingsRow}>
        <Label1 className={css.tagSelectLabel}>Limit answer characters:</Label1>
        <TextInput
          className={css.input}
          showClear={false}
          type="none"
          value={charLimit}
          onChange={handleCharLimitChange}
          placeholder="Specify limit, empty fiend = no limt"
        />
      </div>
      <div className={css.settingsRow}>
        <Label1 className={css.tagSelectLabel}>Pick answers:</Label1>
        <TagSelect
          Input={{ isDisabled: isLoading }}
          isDisabled={isLoading}
          placeholder="Select answers"
          className={css.input}
          options={answers}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          components={{ Option }}
          openMenuOnClick={!isLoading}
          onChange={handleAnswerSelect}
          showClear={false}
        />
      </div>
    </>
  )
}

export { QuestionTemplate, PollSelect, AnswersSelect }
