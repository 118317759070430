import React from 'react'

import { UserCard } from 'pharmacy/src/user/userCard'

import UserFeedItem from './userFeedItem'
import Feed from './feed'

class UserFeed extends React.Component {
  render() {
    return (
      <Feed
        FeedItem={UserFeedItem}
        itemKey="userId"
        itemComponent={UserCard}
        feedURL="user/user/getFeedJSON"
        {...this.props}
      />
    )
  }
}

export default UserFeed
