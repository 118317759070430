export const institutionType = {
  ACADEMIC: 'academic',
  COMMUNITY: 'community',
  NCI: 'nci',
}

export const institutionTypeId = {
  ACADEMIC: 1,
  COMMUNITY: 2,
}

export const institutionTypeName = {
  [institutionType.ACADEMIC]: 'Academic Institution',
  [institutionType.COMMUNITY]: 'Community Practice',
  [institutionType.NCI]: 'NCI-Designated Cancer Center',
}
