import { Buffer } from 'buffer'

import _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import smartlookClient from 'smartlook-client'
import isBot from 'isbot'
import UAParser from 'ua-parser-js'

const MIXPANEL_COOKIE_NAME = 'mixpanel-session'
const MIXPANEL_COOKIE_MAX_INACTIVE_TIME = 60 * 40 // 40 min

const guardedMixpanel = (() => {
  if (process.env.MIXPANEL_TOKEN) {
    return mixpanel
  }

  return {
    track: _.noop,
    track_forms: _.noop,
    identify: _.noop,
    get_distinct_id: _.noop,
    init: _.noop,
    register: _.noop,
    reset: _.noop,
  }
})()

const guardedSmartlook = (() => {
  if (process.env.SMARTLOOK_API_KEY) {
    return smartlookClient
  }

  return {
    init: _.noop,
    identify: _.noop,
  }
})()

const setMixpanelReferrerProperties = () => {
  mixpanel.register(calcMixpanelReferrerProperties())
}

const calcMixpanelReferrerProperties = () => {
  try {
    if (typeof document === 'undefined') {
      return {}
    }

    const { referrerLabel, searchQuery } = calcMixpanelReferrerLabel()
    let mixpanelSessionProps = getMixpanelSessionProps()

    const props = {
      session_referrer_label: referrerLabel,
      session_referrer_search_query: searchQuery,
      direct_referrer_label: referrerLabel,
      direct_referrer_search_query: searchQuery,
    }

    if (_.isEmpty(mixpanelSessionProps)) {
      updateMixpanelSessionProps({
        sessionReferrerLabel: referrerLabel,
        sessionReferrerSearchQuery: searchQuery,
        changedPageInSession: false,
        pathOfFirstPageInSession: document.location.pathname,
      })

      mixpanelSessionProps = getMixpanelSessionProps()
    } else if (
      !mixpanelSessionProps.changedPageInSession &&
      _.isEmpty(mixpanelSessionProps.pathOfFirstPageInSession)
    ) {
      updateMixpanelSessionProps({
        pathOfFirstPageInSession: document.location.pathname,
      })

      mixpanelSessionProps = getMixpanelSessionProps()
    }

    props.session_referrer_label = mixpanelSessionProps.sessionReferrerLabel
    props.session_referrer_search_query =
      mixpanelSessionProps.sessionReferrerSearchQuery

    const isInFirstPageInSession =
      !mixpanelSessionProps.changedPageInSession &&
      document.location.pathname ===
        mixpanelSessionProps.pathOfFirstPageInSession

    // if this is not the first page in the session, use theMednet as the referrer, react router does not change the document referrer so don't use it
    if (!isInFirstPageInSession && !referrerLabel.includes('theMednet')) {
      props.direct_referrer_label = 'theMednet'
      props.direct_referrer_search_query = null

      const params = new URLSearchParams(document.location?.search ?? '')
      const src = params.get('src')

      if (src === 'popover-search') {
        props.direct_referrer_label = 'theMednet Popover Search'
        props.direct_referrer_search_query = params.get('query')
      }
    }
    // if this is the first page in the session, use the referrer from the session
    else if (isInFirstPageInSession) {
      props.direct_referrer_label = mixpanelSessionProps['sessionReferrerLabel']
      props.direct_referrer_search_query =
        mixpanelSessionProps['sessionReferrerSearchQuery']
    }

    return props
  } catch (error) {
    return {}
  }
}

const getMixpanelReferrerProperties = () => {
  try {
    if (document?.mixpanel_loaded) {
      return {
        session_referrer_label: mixpanel.get_property('session_referrer_label'),
        session_referrer_search_query: mixpanel.get_property(
          'session_referrer_search_query'
        ),
        direct_referrer_label: mixpanel.get_property('direct_referrer_label'),
        direct_referrer_search_query: mixpanel.get_property(
          'direct_referrer_search_query'
        ),
      }
    }

    return calcMixpanelReferrerProperties()
  } catch (error) {
    return {}
  }
}

export const getTrackingHeadersFromSearchString = (search) => {
  const encodedHeaders = new URLSearchParams(search)?.get('mp_headers')
  if (encodedHeaders) {
    try {
      return JSON.parse(Buffer.from(encodedHeaders ?? '', 'base64'))
    } catch {
      return {}
    }
  }
  return {}
}

const calcMixpanelReferrerLabel = () => {
  if (typeof document === 'undefined') {
    return {}
  }

  let referrerLabel = mapReferrerUrlToLabel(document.referrer)
  let searchQuery = null

  if (referrerLabel === 'theMednet Search') {
    const referrerUrl = new URL(document.referrer)
    searchQuery = referrerUrl.searchParams.get('query')
  }

  // Check that the referrer is the site itself, otherwise someone could have copied the link and sent it to another person
  if (referrerLabel === 'theMednet') {
    const currentUrlParams = new URLSearchParams(
      document.location?.search ?? ''
    )

    if (currentUrlParams.get('src') === 'popover-search') {
      referrerLabel = 'theMednet Popover Search'
      searchQuery = currentUrlParams.get('query')
    }
  }

  return {
    referrerLabel,
    searchQuery,
  }
}

const mapReferrerUrlToLabel = (referrerUrl) => {
  const referrerMap = {
    // put internal urls first, to skip checking all the list when moving to other pages in the site
    'theMednet Search': [/(the)?mednet\.(com|org)\/search\/siteSearch/i],

    theMednet: [
      /(the)?mednet\.(com|org)/i,
      /mednetassets\.(com|org)/i, // http://micro.production.mednetassets.com micro backend
    ],

    Email: [
      // https://stackoverflow.com/questions/41026078/what-is-com-google-android-gm
      /com\.google\.android\.gm/i, // Gmail app for android

      /^https?:\/\/(z|my|web|e)?mail.?\./i,
    ],

    'Google Search': [
      /www\.google\..*/i,

      // https://carloseo.com/what-is-com-google-android-googlequicksearchbox-referral-google-analytics/#:~:text=com.google.android.googlequicksearchbox%20is%20part%20of%20the%20Google,of%20naming%20apps%20in%20Android.
      /com\.google\.android\.googlequicksearchbox/i, // Quick Google search bar for android
    ],

    'Bing Search': [/www\.bing\.com/i],

    'Yahoo Search': [/search\.yahoo\.co(m)?/i],

    Facebook: [/facebook\.com/i],

    Twitter: [/^https?:\/\/t.co/i, /twitter\.com/i],

    LinkedIn: [/linkedin\.com/i],
  }

  if (_.isEmpty(referrerUrl)) {
    return 'Direct'
  }

  for (const [label, urls] of Object.entries(referrerMap)) {
    for (const url of urls) {
      if (url.test(referrerUrl)) {
        return label
      }
    }
  }

  return 'Other'
}

const setMixpanelAgentProperties = () => {
  const parser = new UAParser()
  const agent = parser.getResult()

  mixpanel.register({
    'Agent Robot': !_.isEmpty(window?.navigator?.userAgent)
      ? isBot(window?.navigator?.userAgent)
      : 'unknown',
    'Agent Device': agent.device
      ? {
          ...agent.device,
          type: agent.device.type || 'desktop',
        }
      : null,
    'Agent Browser': agent?.browser?.name,
    'Agent Platform': agent.os.name,
  })
}

function getMixpanelSessionProps() {
  const mixpanelSessionProps = JSON.parse(
    getCookie(MIXPANEL_COOKIE_NAME) ?? '{}'
  )
  const now = Math.floor(new Date().getTime() / 1000)
  if (
    mixpanelSessionProps.lastUpdatedUTC &&
    now - mixpanelSessionProps.lastUpdatedUTC >
      MIXPANEL_COOKIE_MAX_INACTIVE_TIME
  ) {
    return {}
  }

  return mixpanelSessionProps
}

function updateMixpanelSessionProps(updates) {
  const now = Math.floor(new Date().getTime() / 1000)

  let mixpanelSessionProps = getMixpanelSessionProps()

  if (
    mixpanelSessionProps.lastUpdatedUTC &&
    now - mixpanelSessionProps.lastUpdatedUTC >
      MIXPANEL_COOKIE_MAX_INACTIVE_TIME
  ) {
    // make sure referrer props are recomputed when session is reset
    setMixpanelReferrerProperties()
    mixpanelSessionProps = getMixpanelSessionProps()
  }

  const value = {
    ...mixpanelSessionProps,
    ...updates,
    lastUpdatedUTC: Math.floor(new Date().getTime() / 1000),
  }

  setSessionCookie(MIXPANEL_COOKIE_NAME, JSON.stringify(value))
}

function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2)
    return decodeURIComponent(parts?.pop()?.split(';')?.shift() ?? '')
  return null
}

// Function to set a cookie
const setSessionCookie = (name, value) => {
  document.cookie = `${name}=${value || ''}; expires=0; path=/`
}

export {
  guardedMixpanel as mixpanel,
  guardedSmartlook as smartlookClient,
  setMixpanelReferrerProperties,
  setMixpanelAgentProperties,
  getMixpanelReferrerProperties,
  updateMixpanelSessionProps,
}
