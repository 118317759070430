import React, { useEffect } from 'react'
import { event as gaEvent } from 'react-ga'

import { LoginForm } from 'components/LoginForm/loginForm'
import { LandingPageContent } from 'components/landingPageContent'

import css from './login.scss'

export const Login = ({ history }) => {
  useEffect(() => {
    if (process.env.GOOGLE_ANALYTICS_ID) {
      gaEvent({
        category: 'SignIn',
        action: 'VisitedSignInPage',
      })
    }
  }, [])
  return (
    <LandingPageContent>
      <div className={css.centeredContainer}>
        <LoginForm history={history} />
      </div>
    </LandingPageContent>
  )
}
