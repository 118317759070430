import React from 'react'

import { Button } from 'pharmacy/src/input/button'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './headerAskQuestionButton.scss'

export class AskQuestionButton extends React.Component {
  render() {
    return (
      <Button
        type="dark_blue"
        className={css.button}
        external
        pathname="/question/ask"
        icon={['fal', 'question-circle']}
      >
        <span className={css.buttonText}>
          Ask <span className={css.buttonTextRest}>a Question</span>
        </span>
      </Button>
    )
  }
}

export const GatedAskQuestionButton = withPermissions(authItems.createQuestion)(
  AskQuestionButton
)
