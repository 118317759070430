import React from 'react'

import { UserActivityItem } from 'pharmacy/src/user/userActivityItem'

import UserActivityFeedItem from './userActivityFeedItem'
import Feed from './feed'

import css from './userActivityFeed.scss'

class UserActivityFeed extends React.Component {
  render() {
    return (
      <Feed
        FeedItem={UserActivityFeedItem}
        itemComponent={UserActivityItem}
        itemKey="questionUpdateId"
        feedURL="user/user/getActivityFeedJSON"
        itemContainerClass={css.itemContainer}
        {...this.props}
      />
    )
  }
}

export default UserActivityFeed
