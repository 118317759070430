import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

export const FETCH_TESTIMONIALS_PUBLIC = 'testimonial/FETCH_TESTIMONIALS_PUBLIC'

export function fetchTestimonialsPublic(callback) {
  return {
    type: FETCH_TESTIMONIALS_PUBLIC,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_TESTIMONIALS_PUBLIC,
    'testimonial/GetTestimonialsPublicJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  testimonials: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_TESTIMONIALS_PUBLIC): {
      return receiveReducer(state, action, () => ({
        testimonials: action.response.map((testimonial) => ({
          testimonialId: testimonial.testimonialId,
          testimonial: testimonial.testimonial,
          order: testimonial.order,
          userId: testimonial.userId,
        })),
      }))
    }

    default:
      return state
  }
}
