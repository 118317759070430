import React from 'react'
import _ from 'lodash'

import BaseSelect from './baseSelect'
import { SimpleDropdownIndicator } from './components'

import './containedSelect.scss'

const STYLE_OVERRIDES = [
  'control',
  'indicatorSeparator',
  'dropdownIndicator',
  'menu',
  'singleValue',
  'valueContainer',
  'option',
  'indicatorsContainer',
  'input',
  'placeholder',
]

class ContainedSelect extends React.Component {
  static defaultProps = {
    components: {},
  }

  render() {
    const { components, ...restProps } = this.props
    return (
      <BaseSelect
        blurInputOnSelect
        classNamePrefix="react-select-contained"
        styleOverrides={STYLE_OVERRIDES}
        components={_.assign(components, {
          DropdownIndicator:
            components.DropdownIndicator === undefined
              ? SimpleDropdownIndicator
              : components.DropdownIndicator,
        })}
        {...restProps}
      />
    )
  }
}

export default ContainedSelect
