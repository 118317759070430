import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Utils as QbUtils } from 'react-awesome-query-builder'
import { debounce, isEmpty, endsWith } from 'lodash'

import { TabButton, Tabs } from 'pharmacy/src/navigation/tabs'
import TextInput from 'pharmacy/src/input/textInput/textInput'
import { Button, ButtonUpload } from 'pharmacy/src/input/button'
import { Subtitle1 } from 'pharmacy/src/typography'
import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'

import { useCreatePreset } from 'mednet-cns/src/hooks/preset'

import { useQueryBuilderConfig } from 'components/userSearchQueryBuilder/config'
import QueryBuilder from 'components/userSearchQueryBuilder/queryBuilder'
import PresetTable from 'components/tables/presetTable'

import * as css from './userSearchWithPresets.scss'

const QueryBuilderWithPreset = React.memo(
  ({ onQueryChange, preset, queryUserStatus, tree }) => {
    const config = useQueryBuilderConfig(queryUserStatus)
    const initialTree = useMemo(() => {
      return QbUtils.checkTree(
        QbUtils.loadTree(
          tree?.id ? tree : { id: QbUtils.uuid(), type: 'group' }
        ),
        config
      )
    }, [tree])

    const [jsonTree, setJsonTree] = useState(initialTree)
    const [presetName, setPresetName] = useState('')
    const [saving, setSaving] = useState(false)

    const createPreset = useCreatePreset(() => {
      setSaving(false)
      setPresetName('')
    })

    const handleQueryChange = useCallback(
      (tree) => {
        const id = `${jsonTree.id}:${Date.now()}`
        onQueryChange &&
          onQueryChange({
            name: preset?.name,
            presetId: preset?.id,
            id,
            tree,
          })
      },
      [onQueryChange]
    )

    const handleSearchClick = useCallback(() => {
      handleQueryChange(jsonTree)
    }, [jsonTree, handleQueryChange])

    const handlePresetCreate = useCallback(() => {
      setSaving(true)
      createPreset({
        name: presetName,
        tree: JSON.stringify(jsonTree),
      })
    }, [presetName, jsonTree])

    const handleTreeChange = useCallback((_config, immutableTree) => {
      const jsonTree = QbUtils.getTree(immutableTree)
      setJsonTree(jsonTree)
    })

    const handlePresetNameChange = useCallback(
      debounce((value) => {
        setPresetName(value)
      }, 200),
      [setPresetName]
    )

    return (
      <>
        <QueryBuilder
          onChange={handleTreeChange}
          initialTree={initialTree}
          queryUserStatus={queryUserStatus}
        />

        <div className={css.actions}>
          <TextInput
            onChange={handlePresetNameChange}
            value={presetName}
            showClear={false}
            className={css.presetName}
          />
          <Button
            isLoading={saving}
            isDisabled={!presetName}
            onClick={handlePresetCreate}
            size="small"
            className={css.action}
          >
            Save as preset
          </Button>
          <Button
            onClick={handleSearchClick}
            size="small"
            className={css.action}
          >
            Search
          </Button>
        </div>
      </>
    )
  }
)
QueryBuilderWithPreset.displayName = 'QueryBuilderWithPreset'

const fileImportValidationSchema = Yup.object().shape({
  useExistingFile: Yup.bool(),
  file: Yup.mixed()
    .when(['useExistingFile'], {
      is: (useExistingFile) => !useExistingFile,
      then: Yup.mixed().required('File is required'),
    })
    .test('fileFormat', `File must be CSV`, (value) => {
      if (!value) {
        return true
      }

      return endsWith(value.name, '.csv')
    }),
})

const FileImportForm = ({
  setFieldValue,
  handleSubmit,
  errors,
  values,
  fileDownloadUrl,
  file,
}) => {
  useEffect(() => {
    setFieldValue('useExistingFile', !isEmpty(fileDownloadUrl) && !file)
  }, [setFieldValue, fileDownloadUrl, file])

  useEffect(() => {
    if (values.useExistingFile) {
      setFieldValue('file', undefined)
    }
  }, [setFieldValue, values.useExistingFile])

  const toggleUseExistingFile = () => {
    setFieldValue('useExistingFile', !values.useExistingFile)
  }

  return (
    <div className={css.importForm}>
      {!isEmpty(fileDownloadUrl) && (
        <div className={css.importFormOptionContainer}>
          <div className={css.importFormOption}>
            <Field
              type="radio"
              id="useExistingFile_true"
              checked={values.useExistingFile}
              onChange={toggleUseExistingFile}
            />
            <label htmlFor="useExistingFile_true">Use Existing File:</label>
          </div>
          <div className={css.downloadButtonContainer}>
            <div className={css.downloadIcon}>
              <Link pathname={fileDownloadUrl} external>
                <Icon icon={['far', 'file-download']} size="3x" />
              </Link>
            </div>
            <Button
              type="secondary"
              className={css.fileButton}
              pathname={fileDownloadUrl}
              external
            >
              Download File
            </Button>
          </div>
        </div>
      )}
      <div className={css.importFormOptionContainer}>
        <div className={css.importFormOption}>
          <Field
            type="radio"
            checked={!values.useExistingFile}
            id="useExistingFile_false"
            onChange={toggleUseExistingFile}
          />
          <label htmlFor="useExistingFile_false">Upload new file:</label>
        </div>
        <ButtonUpload
          inputProps={{
            name: 'file',
            disabled: values.useExistingFile,
            onChange: (file) => {
              setFieldValue('file', file)
            },
          }}
          buttonProps={{
            type: 'secondary',
            className: css.fileButton,
            isDisabled: values.useExistingFile,
          }}
          file={file}
        />
        {errors && errors.file && (
          <Subtitle1 className={css.errorMessage}>{errors.file}</Subtitle1>
        )}
      </div>

      <div className={css.actions}>
        <Button onClick={handleSubmit} size="small" className={css.action}>
          Search
        </Button>
      </div>
    </div>
  )
}

const FileImport = ({ handleFileSearch, fileDownloadUrl, file }) => {
  const initialValues = {
    file,
    useExistingFile: !isEmpty(fileDownloadUrl) && !file,
  }

  return (
    <Formik
      validationSchema={fileImportValidationSchema}
      initialValues={initialValues}
      onSubmit={handleFileSearch}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (
        <FileImportForm
          fileDownloadUrl={fileDownloadUrl}
          file={file}
          {...props}
        />
      )}
    </Formik>
  )
}

const UserSearchWithPresets = ({
  tree,
  onQueryChange: handleQueryChange,
  queryUserStatus,
  activeTab = 0,
  handleFileSearch,
  fileDownloadUrl,
  file,
}) => {
  const [preset, setPreset] = useState()
  const [currentActiveTab, setCurrentActiveTab] = useState([activeTab])

  const handlePresetLoad = (preset) => {
    setPreset(preset)
    setCurrentActiveTab([1])
    handleQueryChange &&
      handleQueryChange({
        name: preset?.name,
        presetId: preset?.id,
        tree: preset?.tree,
        id: preset?.tree?.id,
      })
  }

  const tabs = useMemo(
    () => [
      {
        header: <TabButton title="Presets" icon={['far', 'save']} />,
        component: <PresetTable onPresetLoad={handlePresetLoad} />,
      },
      {
        header: <TabButton title="Query" icon={['far', 'search']} />,
        component: (
          <QueryBuilderWithPreset
            tree={tree}
            onQueryChange={handleQueryChange}
            preset={preset}
            queryUserStatus={queryUserStatus}
          />
        ),
      },
      {
        header: <TabButton title="File Upload" icon={['far', 'file-upload']} />,
        component: (
          <FileImport
            handleFileSearch={handleFileSearch}
            fileDownloadUrl={fileDownloadUrl}
            file={file}
          />
        ),
      },
    ],
    [handleQueryChange, preset]
  )

  return (
    <div className={css.container}>
      <Tabs tabs={tabs} activeTab={currentActiveTab} />
    </div>
  )
}

export default UserSearchWithPresets
