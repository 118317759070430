import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { IMAGE_SIZE } from 'mednet-util/src/router'
import { UserImage } from 'pharmacy/src/user/userImage'
import { RawBody } from 'pharmacy/src/display/rawBody'
import { Link1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { UserUpdateText } from 'pharmacy/src/user/userUpdateInformation'
import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './commentInformation.scss'

class CommentInformation extends React.Component {
  renderEditButton = () => {
    const { commentObject, enableEdit, permissions } = this.props
    const { commentId } = commentObject

    if (enableEdit === false) {
      return false
    }

    if (permissions && _.includes(permissions.items, authItems.updateComment)) {
      return (
        <Link1 className={css.actionLink}>
          <Button
            icon={['far', 'edit']}
            external
            className={css.editButton}
            pathname={`/comment/update/${commentId}`}
            type="text"
          >
            Edit
          </Button>
        </Link1>
      )
    }

    return null
  }

  render() {
    const { user, commentObject, className, bodyProps } = this.props
    const { userId, imageVersion } = user || {}
    const { comment, created } = commentObject

    const containerClasses = classNames(css.container, className)

    return (
      <div className={containerClasses}>
        <div>
          <UserImage
            userId={userId}
            imageVersion={imageVersion}
            imageSize={IMAGE_SIZE.SMALL}
            className={css.userImage}
            addHalo={user?.isExpert}
          />
        </div>
        <div className={css.textContainer}>
          <div className={css.updateContainer}>
            <UserUpdateText
              userId={userId}
              dateUpdated={created}
              actionId={actionIdByName.COMMENT}
              highlightIfExpert
            />
            {this.renderEditButton()}
          </div>
          <RawBody body={comment} {...bodyProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.users[ownProps.commentObject.userId],
    permissions: state.user.permissions,
  }
}

export default connect(mapStateToProps)(CommentInformation)
