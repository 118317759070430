import React from 'react'
import { connect } from 'react-redux'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { createConflict } from 'mednet-cns/src/reducers/answer'
import { POST_ANSWER_MODAL } from 'mednet-util/src/constants/modal'

import css from './disclosureForm.scss'

class DisclosureForm extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()

    const { conflict } = props.response
    this.state = {
      value: conflict ? conflict.conflictText : '',
    }
  }

  handleClose = () => {
    if (this.props.pollId) {
      this.props.closeModal()
    } else {
      this.props.changeModalScreen(POST_ANSWER_MODAL.screens.pollPrompt)
    }
  }

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
    })
  }

  handleSubmit = () => {
    this.props.createConflict(this.form.current)
    this.handleClose()
  }

  render() {
    const { response } = this.props
    const { value } = this.state
    const { conflict } = response

    return (
      <div>
        <Header2>Disclosures</Header2>
        <hr />
        <form ref={this.form}>
          <Subtitle2>Enter your conflicts of interest</Subtitle2>
          <textarea
            className={css.textarea}
            name="conflict_text"
            value={value}
            onChange={this.handleChange}
          />
          {conflict && value === conflict.conflictText && (
            <input
              type="hidden"
              value={conflict.conflictId}
              name="conflict_id"
            />
          )}
          <div className={css.buttonGroup}>
            <Button
              type="neutral"
              className={css.button}
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={css.button}
              isDisabled={!this.state.value}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createConflict: (form) =>
      dispatch(createConflict(ownProps.response.answer.answerId, form)),
  }
}

export default connect(null, mapDispatchToProps)(DisclosureForm)
