import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Link } from 'pharmacy/src/navigation/link'
import { authItems } from 'mednet-util/src/constants/permission'
import { mouseEnter, mouseLeave } from 'mednet-cns/src/reducers/mention'
import { SOURCE_TYPE } from 'mednet-util/src/constants/mention'

class BaseUserLink extends React.Component {
  static defaultProps = {
    LinkComponent: Link,
  }

  constructor(props) {
    super(props)

    this.link = React.createRef()
  }

  getLink = () => {
    const { innerRef } = this.props

    if (innerRef) {
      return innerRef
    }

    return this.link
  }

  handleMouseEnter = () => {
    const { mouseEnter, disableMention, user } = this.props
    const { userId } = user

    if (
      disableMention ||
      !this.getLink().current ||
      'ontouchstart' in document.documentElement // always disable on mobile
    ) {
      return
    }

    const rect = this.getLink().current.getBoundingClientRect()
    mouseEnter(userId, rect, SOURCE_TYPE.LINK)
  }

  handleMouseLeave = () => {
    const { mouseLeave, disableMention } = this.props

    if (disableMention) {
      return
    }

    mouseLeave()
  }

  handleClick = (user) => {
    const { onClick } = this.props
    if (!onClick) {
      return null
    }

    return () => {
      onClick(user)
    }
  }

  render() {
    const {
      userId: __userId,
      user,
      canViewProfile,
      LinkComponent,
      mouseEnter,
      disableMention,
      mouseLeave,
      onClick,
      children,
      ...linkProps
    } = this.props
    const { userId } = user

    if (canViewProfile) {
      return (
        <LinkComponent
          external
          pathname={`/user/user/view/id/${userId}`}
          onClick={this.handleClick(user)}
          forwardRef={this.getLink()}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          {...linkProps}
        >
          {children}
        </LinkComponent>
      )
    }

    return <span>{children}</span>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: ownProps.user || state.user.users[ownProps.userId],
    canViewProfile: _.includes(
      _.get(state.user, 'permissions.items', []),
      authItems.viewProfile
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mouseEnter: (userId, rect, sourceType) =>
      dispatch(mouseEnter(userId, rect, sourceType)),
    mouseLeave: () => dispatch(mouseLeave()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseUserLink)
