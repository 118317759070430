import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'

import { DatePicker } from 'pharmacy/src/input/datePicker'
import { Header1, Subtitle1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import {
  postEmailUpdates,
  fetchUpdateStats,
  FETCH_UPDATE_STATS,
} from 'mednet-cns/src/reducers/expertEmail'
import { TextEditor } from 'pharmacy/src/input/textEditor'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { EXPERT_EMAIL_MODAL } from 'mednet-util/src/constants/modal'

import css from './editEmailScreen.scss'

class EditEmailForm extends React.Component {
  render() {
    const { handleSubmit, innerRef, isSubmitting, closeModal } = this.props
    return (
      <form ref={innerRef} className={css.formContainer}>
        <div className={css.inputContainer}>
          <label>
            <Subtitle1>Subject: </Subtitle1>
            <Field
              className={css.textArea}
              name="subject"
              component="textarea"
            />
          </label>
        </div>
        <div className={css.inputContainer}>
          <label>
            <Subtitle1>Send after:</Subtitle1>
            <div className={css.datePickerContainer}>
              <DatePicker inputProps={{ name: 'send_after' }} />
            </div>
          </label>
        </div>
        <div className={css.inputContainer}>
          <Field name="body">
            {({ field }) => (
              <label>
                <Subtitle1>Body:</Subtitle1>
                <TextEditor {...field} />
              </label>
            )}
          </Field>
        </div>
        <div className={css.buttonGroup}>
          <Button type="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={css.submitButton}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

class EditEmailScreen extends React.Component {
  constructor(props) {
    super(props)

    this.form = React.createRef()
  }

  componentDidMount() {
    const { selectedDateRange, selectedSpecialty, firstForm } = this.props
    const { from, to } = selectedDateRange
    const formData = new FormData(firstForm.current)
    const updates = Array.from(formData).map((entry) => entry[1])
    this.props.fetchUpdateStats(
      updates,
      selectedSpecialty.specialtyId,
      moment(from).format('YYYY-MM-DD'),
      moment(to).format('YYYY-MM-DD')
    )
  }

  handleSubmit = (values, actions) => {
    window.tinyMCE.triggerSave()

    const { changeModalScreen, existing, firstForm, selectedSubspecialty } =
      this.props
    const { subspecialtyId } = selectedSubspecialty

    const formData = new FormData(this.form.current)
    const firstFormData = new FormData(firstForm.current)

    _.each(Array.from(firstFormData.entries()), (entry) => {
      formData.append(entry[0], entry[1])
    })

    formData.append('subspecialtyId', subspecialtyId)

    if (existing) {
      formData.append('expertEmailId', existing.expertEmailId)
    }

    const formattedDate = moment(
      formData.get('send_after'),
      'MM/DD/YYYY'
    ).format('YYYY-MM-DD')
    formData.set('send_after', formattedDate)

    this.props.postEmailUpdates(formData, () => {
      actions.setSubmitting(false)
      changeModalScreen(EXPERT_EMAIL_MODAL.screens.finished)
    })
  }

  render() {
    const { statsIsLoaded, statsIsLoading } = this.props

    if (!statsIsLoaded || statsIsLoading) {
      return <StarLoader wait={false} isVerticalMargin={false} />
    }

    const {
      selectedSpecialty,
      selectedSubspecialty,
      selectedDateRange,
      stats,
      firstForm,
      existing,
      closeModal,
    } = this.props
    const { views, uniques, reach, experts } = stats || {}
    const { from, to } = selectedDateRange

    const formData = new FormData(firstForm.current)
    const updates = Array.from(formData)

    return (
      <div>
        <Header1>Edit email properties</Header1>
        <Formik
          initialValues={{
            subject: existing
              ? existing.subject
              : `Stats for the top Q/A in ${selectedSubspecialty.subspecialty}`,
            send_after: '',
            // prettier-ignore
            body: existing
              ? existing.body
              : `
              <div>
                <p>
                  Thank you for your contributions as ${selectedSubspecialty.subspecialty} experts on theMednet.
                </p>
                <p>
                  The following ${updates.length} questions have been viewed
                  <b>${views.toLocaleString()} times</b> by
                  <b>${uniques.toLocaleString()} people</b> at
                  <b>${reach.toLocaleString()} institutions</b>.
                </p>
                <p>
                  <b>${experts} experts</b> have contributed to the ${selectedSpecialty.specialty} portion of
                  the site from ${moment(from).format('LL')} to ${moment(to).format('LL')}.
                  We look forward to your continued involvement!
                </p>
              </div>
            `,
          }}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <EditEmailForm
              {...props}
              innerRef={this.form}
              closeModal={closeModal}
            />
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const statsRequest =
    _.get(state.request.requests, makeRequestName(FETCH_UPDATE_STATS)) || {}

  return {
    statsIsLoaded: statsRequest.isLoaded,
    statsIsLoading: statsRequest.isLoading,
    stats: state.expertEmail.stats,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postEmailUpdates: (formData, callback) =>
      dispatch(postEmailUpdates(formData, callback)),
    fetchUpdateStats: (updates, specialtyId, from, to) =>
      dispatch(fetchUpdateStats(updates, specialtyId, from, to)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailScreen)
