import React from 'react'

import NavigationContext from './navigation'

const withNavigationComponents = (WrappedComponent) => {
  class WithNavigationComponents extends React.Component {
    static displayName = `WithNavigationComponents(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    render() {
      return (
        <NavigationContext.Consumer>
          {(value) => (
            <WrappedComponent navigationComponents={value} {...this.props} />
          )}
        </NavigationContext.Consumer>
      )
    }
  }

  return WithNavigationComponents
}

export default withNavigationComponents
