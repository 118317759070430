import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import {
  receiveAction,
  makeFetchEffect,
  receiveReducer,
} from '../cns-util/reducer'

import { FETCH_EXPERTS_ONCOLOGY_MERGED } from './expert'

import { makeRequestName } from './request'

export const FETCH_SPECIALTIES = 'specialty/FETCH_SPECIALTIES'
export const FETCH_SPECIALTIES_TREE = 'specialty/FETCH_SPECIALTIES_TREE'

export function fetchSpecialties(callback) {
  return {
    type: FETCH_SPECIALTIES,
    callback,
  }
}

export function fetchSpecialtiesTree(callback) {
  return {
    type: FETCH_SPECIALTIES_TREE,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(takeLatest, FETCH_SPECIALTIES, 'specialty/getJSON')
  yield makeFetchEffect(
    takeLatest,
    FETCH_SPECIALTIES_TREE,
    'specialty/getTreeJSON'
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  specialties: {},
  tree: [],
  oncologySpecialtiesIds: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case receiveAction(FETCH_SPECIALTIES): {
      return receiveReducer(state, action, () => ({
        specialties: {
          ...state.specialties,
          ..._.keyBy(action.response.specialties, 'specialtyId'),
        },
      }))
    }
    case receiveAction(FETCH_SPECIALTIES_TREE): {
      return receiveReducer(state, action, () => ({
        tree: action.response || [],
      }))
    }

    case receiveAction(FETCH_EXPERTS_ONCOLOGY_MERGED): {
      return receiveReducer(state, action, () => ({
        oncologySpecialtiesIds: action.response.oncologySpecialtiesIds || [],
      }))
    }

    default:
      return state
  }
}

export function getSpecialties(state) {
  return state.specialty.specialties
}

export function getAreSpecialtiesLoading(state) {
  return _.get(state.request.requests, makeRequestName(FETCH_SPECIALTIES), {})
    .isLoading
}

export function getSpecialtiesRequest(state) {
  return _.get(state.request.requests, makeRequestName(FETCH_SPECIALTIES), {})
}
