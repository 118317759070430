import React from 'react'
import _ from 'lodash'

import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { Link1, Subtitle3 } from 'pharmacy/src/typography'
import {
  cclickPlace,
  downloadableStatuses,
} from 'mednet-util/src/constants/publication'

import { mixpanel } from 'mednet-util/src/tracking'

import css from './publication.scss'

class Publication extends React.Component {
  static defaultProps = {
    LinkComponent: Link,
    TextComponent: Link1,
  }

  progress = 0

  playing = false

  handlePlay = () => {
    const { item, questionId, userId } = this.props
    const { publicationId } = item
    mixpanel.track('Video play', {
      place: 'Q',
      userId,
      questionId,
      publicationId,
    })

    this.playing = true
  }

  handleStop = () => {
    this.playing = false
  }

  handleProgress = _.debounce(() => {
    const { item, questionId, userId } = this.props
    const { publicationId } = item

    if (this.playing) {
      this.progress += 1
      mixpanel.track('Video progress', {
        place: 'Q',
        userId,
        questionId,
        publicationId,
        totalSecondsPlayed: this.progress,
      })
    }
  }, 1000)

  render() {
    const {
      item,
      TextComponent,
      LinkComponent,
      onClick,
      citeId,
      VideoComponent,
      place,
      linesLimit,
    } = this.props
    const {
      publicationId,
      title,
      journal,
      date,
      pubmedId,
      access,
      videoURL,
      videoSubtitle,
      publicURL,
    } = item
    const onMouseDownHandler = (event) => {
      if (event.button === 0 || event.button === 1) {
        onClick(event)
      }
    }

    const downloadable = downloadableStatuses.includes(access)
    const className = downloadable ? css.sponsored : ''
    const videoClassName = VideoComponent && videoURL ? css.withVideo : ''
    const openedLink =
      place === cclickPlace.question
        ? `/questionCitedPaper/download/${citeId}`
        : place === cclickPlace.answer
        ? `/answerCitedpaper/download/${citeId}`
        : `/questionSponsorshipCitedPaper/download/${citeId}`

    const publicationLink = publicURL
      ? publicURL
      : pubmedId
      ? `https://www.ncbi.nlm.nih.gov/pubmed/${pubmedId}`
      : `/publication/${publicationId}`

    return (
      <div className={videoClassName}>
        <TextComponent
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: linesLimit,
            WebkitBoxOrient: 'vertical',
          }}
        >
          <LinkComponent
            className={[css.paperTitle, className].join(' ')}
            external
            pathname={downloadable ? openedLink : publicationLink}
            target="_blank"
            onMouseDown={onMouseDownHandler}
          >
            {downloadable ? <Icon icon="fas fa-file-download" /> : null} {title}
          </LinkComponent>
        </TextComponent>
        {_.isNil(linesLimit) && (
          <Subtitle3 className={css.journal}>
            {journal} {date}
          </Subtitle3>
        )}
        {!(videoURL && VideoComponent) ? null : (
          <>
            <div className={css.videoWrapper}>
              <VideoComponent
                className={css.videoPlayer}
                url={videoURL}
                controls
                width="100%"
                height="100%"
                onPlay={this.handlePlay}
                onPause={this.handleStop}
                onProgress={this.handleProgress}
              />
            </div>
            <Subtitle3 className={css.videoSubtitle}>{videoSubtitle}</Subtitle3>
          </>
        )}
      </div>
    )
  }
}

export default Publication
