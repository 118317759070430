import React from 'react'

import { Link } from 'pharmacy/src/navigation/link'

import { makeAssetURL } from 'mednet-util/src/router'

import css from './partners.scss'

export const Partners = ({
  assetsPath,
  titleClassName,
  textClassName,
  buttonClassName,
}) => {
  return (
    <div
      className={css.partnersContainer}
      style={{
        backgroundImage: `url("${makeAssetURL(
          `${assetsPath}for-partners-background.svg`
        )}")`,
      }}
    >
      <div className={css.partnersInnerContainer}>
        {/* Need inner div for IE */}
        <div className={titleClassName}>
          <div>For Partners</div>
        </div>
        <div className={css.iconContainer}>
          <img src={makeAssetURL(`${assetsPath}for-partners-icon.svg`)} />
        </div>
        <div className={textClassName}>
          Engage practicing physicians through our Partnership Programs to bring
          awareness to trials, educate on new data, understand real-world
          implications, and more.
        </div>
        <div className={css.buttonContainer}>
          <Link className={buttonClassName} pathname="/partnerships">
            See All Programs
          </Link>
        </div>
      </div>
    </div>
  )
}
