import _ from 'lodash'
import { takeLatest, all } from 'redux-saga/effects'

import { makeURL } from 'mednet-util/src/router'
import { makePOSTHeaders } from 'mednet-cns/src/api/v1'

import { digestStatus } from 'mednet-util/src/constants/digest'

import {
  makeFetchEffect,
  receiveAction,
  receiveReducer,
  requestAction,
} from '../cns-util/reducer'

export const FETCH_AVAILABLE_SPECIALTIES = 'digest/FETCH_AVAILABLE_SPECIALTIES'
export const FETCH_DIGEST_PREVIEW = 'digest/FETCH_DIGEST_PREVIEW'
export const FETCH_DIGEST_SUBJECTS_PREVIEW =
  'digest/FETCH_DIGEST_SUBJECTS_PREVIEW'
export const FETCH_UPDATES = 'digest/FETCH_UPDATES'
export const CREATE_DIGEST = 'digest/CREATE_DIGEST'
export const DELETE_DIGEST_UPDATE = 'digest/REMOVE_DIGEST_UPDATE'
export const DELETE_DIGEST = 'digest/DELETE_DIGEST'
export const ADD_DIGEST_UPDATE = 'digest/ADD_DIGEST_UPDATE'
export const SET_DIGEST_ORDER = 'digest/SET_DIGEST_ORDER'
export const SET_UPDATE_PRIMARY_TOPIC = 'digest/SET_UPDATE_PRIMARY_TOPIC'
export const SET_SUBJECT = 'digest/SET_SUBJECT'
export const SET_DESCRIPTION = 'digest/SET_DESCRIPTION'
export const SET_TEMPLATE_FLAG = 'digest/SET_TEMPLATE_FLAG'
export const SET_INCLUDE_ASK_EXPERT_FLAG = 'digest/SET_INCLUDE_ASK_EXPERT_FLAG'
export const FETCH_TODAY_DIGEST = 'digest/FETCH_TODAY_DIGEST'
export const SUSPEND_DIGEST = 'digest/SUSPEND_DIGEST'
export const RESUME_DIGEST = 'digest/RESUME_DIGEST'
export const SPLIT_DIGEST_INTO_TWO_COHORTS =
  'digest/SPLIT_DIGEST_INTO_TWO_COHORTS'
export const MERGE_TODAY_DIGEST_COHORTS = 'digest/MERGE_TODAY_DIGEST_COHORTS'

export function fetchAvailableSpecialties() {
  return {
    type: FETCH_AVAILABLE_SPECIALTIES,
  }
}

export function fetchDigestPreview(digestId, subspecialtyId) {
  return {
    type: FETCH_DIGEST_PREVIEW,
    digestId,
    subspecialtyId,
  }
}

export function fetchDigestSubjectsPreview(digestId, specialtyId) {
  return {
    type: FETCH_DIGEST_SUBJECTS_PREVIEW,
    digestId,
    specialtyId,
  }
}

export function fetchUpdates(digestId) {
  return {
    type: FETCH_UPDATES,
    digestId,
  }
}

export function createDigest(specialtyId) {
  return {
    type: CREATE_DIGEST,
    specialtyId,
  }
}

export function fetchTodayDigest(specialtyId) {
  return {
    type: FETCH_TODAY_DIGEST,
    specialtyId,
  }
}

export function deleteDigest(digestId, callback) {
  return {
    type: DELETE_DIGEST,
    digestId,
    callback,
  }
}

export function suspendDigest(digestId, reason = '') {
  return {
    type: SUSPEND_DIGEST,
    digestId,
    reason,
  }
}

export function resumeDigest(digestId, callback) {
  return {
    type: RESUME_DIGEST,
    digestId,
    callback,
  }
}

export function deleteDigestUpdate(
  specialtyId,
  digestId,
  updateIds,
  questionId,
  callback
) {
  return {
    type: DELETE_DIGEST_UPDATE,
    specialtyId,
    digestId,
    updateIds,
    questionId,
    callback,
  }
}

export function addDigestUpdate(
  specialtyId,
  digestId,
  updateId,
  questionId,
  callback
) {
  return {
    type: ADD_DIGEST_UPDATE,
    specialtyId,
    digestId,
    updateId,
    questionId,
    callback,
  }
}

export function setDigestOrder(digestId, questions, callback) {
  return {
    type: SET_DIGEST_ORDER,
    digestId,
    questions,
    callback,
  }
}

export function setDigestSubject(digestId, subject, callback) {
  return {
    type: SET_SUBJECT,
    digestId,
    subject,
    callback,
  }
}

export function setDigestDescription(digestId, description, callback) {
  return {
    type: SET_DESCRIPTION,
    digestId,
    description,
    callback,
  }
}

export function setTemplateFlag(digestId, inTemplate, callback) {
  return {
    type: SET_TEMPLATE_FLAG,
    digestId,
    inTemplate,
    callback,
  }
}

export function setIncludeAskExpertFlag(digestId, includeAskExpert, callback) {
  return {
    type: SET_INCLUDE_ASK_EXPERT_FLAG,
    digestId,
    includeAskExpert,
    callback,
  }
}

export function setPrimaryTopic(digestId, questionUpdateId, topicId, callback) {
  return {
    type: SET_UPDATE_PRIMARY_TOPIC,
    digestId,
    questionUpdateId,
    topicId,
    callback,
  }
}

export function splitIntoTwoCohorts(digestId, splitMethod, callback) {
  return {
    type: SPLIT_DIGEST_INTO_TWO_COHORTS,
    digestId,
    splitMethod,
    callback,
  }
}

export function mergeTodayCohorts(specialtyId, callback) {
  return {
    type: MERGE_TODAY_DIGEST_COHORTS,
    specialtyId,
    callback,
  }
}

function* watchFetch() {
  yield makeFetchEffect(
    takeLatest,
    FETCH_AVAILABLE_SPECIALTIES,
    makeURL(`digest/getAvailableSpecialtiesJSON`)
  )

  yield makeFetchEffect(takeLatest, FETCH_UPDATES, (action) =>
    makeURL(`digest/getUpdatesJSON/${action.digestId}`)
  )

  yield makeFetchEffect(takeLatest, FETCH_DIGEST_PREVIEW, (action) =>
    makeURL(`digest/preview/${action.digestId}`, {
      subspecialtyId: action.subspecialtyId,
    })
  )

  yield makeFetchEffect(takeLatest, FETCH_DIGEST_SUBJECTS_PREVIEW, (action) =>
    makeURL(`digest/subjectsPreview/${action.digestId}`, {
      specialtyId: action.specialtyId,
    })
  )

  yield makeFetchEffect(takeLatest, CREATE_DIGEST, (action) =>
    makeURL('digest/create', { specialtyId: action.specialtyId })
  )

  yield makeFetchEffect(takeLatest, FETCH_TODAY_DIGEST, (action) =>
    makeURL(`digest/today/${action.specialtyId}`)
  )

  yield makeFetchEffect(
    takeLatest,
    DELETE_DIGEST,
    (action) => makeURL(`digest/delete/${action.digestId}`),
    makePOSTHeaders()
  )

  yield makeFetchEffect(
    takeLatest,
    RESUME_DIGEST,
    (action) => makeURL(`digest/resume/${action.digestId}`),
    makePOSTHeaders()
  )

  yield makeFetchEffect(
    takeLatest,
    SUSPEND_DIGEST,
    (action) => makeURL(`digest/suspend/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('reason', action.reason)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    DELETE_DIGEST_UPDATE,
    (action) => makeURL(`digest/deleteUpdate/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('updateId', JSON.stringify(action.updateIds))
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    ADD_DIGEST_UPDATE,
    (action) => makeURL(`digest/addUpdate/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('updateId', action.updateId)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_DIGEST_ORDER,
    (action) => makeURL(`digest/order/${action.digestId}`),
    (action) => {
      const updateIds = _.flatMap(
        action.questions,
        (question) => question.updates
      )
      const formData = new FormData()
      formData.append('order', JSON.stringify(updateIds))
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_SUBJECT,
    (action) => makeURL(`digest/setSubject/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      if (action.subject === null) {
        formData.append('clear', true)
      } else {
        formData.append('subject', action.subject)
      }
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_DESCRIPTION,
    (action) => makeURL(`digest/setDescription/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      if (!action.description) {
        formData.append('clear', true)
      } else {
        formData.append('description', action.description)
      }
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_TEMPLATE_FLAG,
    (action) => makeURL(`digest/SetTemplateFlag/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('inTemplate', action.inTemplate)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_INCLUDE_ASK_EXPERT_FLAG,
    (action) => makeURL(`digest/SetIncludeAskExpertFlag/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('includeAskExpert', action.includeAskExpert)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SET_UPDATE_PRIMARY_TOPIC,
    (action) => makeURL(`digest/setPrimaryTopic/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append(
        'questionUpdateId',
        JSON.stringify(action.questionUpdateId)
      )
      formData.append('topicId', action.topicId)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    SPLIT_DIGEST_INTO_TWO_COHORTS,
    (action) => makeURL(`digest/splitIntoTwoCohorts/${action.digestId}`),
    (action) => {
      const formData = new FormData()
      formData.append('splitMethod', action.splitMethod)
      return makePOSTHeaders(formData)
    }
  )

  yield makeFetchEffect(
    takeLatest,
    MERGE_TODAY_DIGEST_COHORTS,
    (action) => makeURL(`digest/mergeTodayCohorts/${action.specialtyId}`),
    () => {
      return makePOSTHeaders()
    }
  )
}

export function* rootSaga() {
  yield all([watchFetch()])
}

const initialState = {
  digests: {},
  availableSpecialties: {},
}

export const getDigest = (state, digestId) => {
  return _.values(state.digests)
    .filter((specialtyDigests) => !_.isNil(specialtyDigests))
    .flat()
    .find((digest) => digest.digest_id === digestId)
}

const updateDigestInState = (state, digestId, updatesToDigest) => {
  const digest = getDigest(state, digestId)

  const updatedDigest = {
    ...digest,
    ...updatesToDigest,
  }

  return {
    ...state,
    digests: {
      ...(state.digests ?? {}),
      [digest.specialty_id]: _.uniqBy(
        [updatedDigest, ...(state.digests[digest.specialty_id] ?? [])],
        'digest_id'
      ),
    },
  }
}

const getTrimmedDigest = (digest) => {
  return {
    ...digest,
    questions: digest.questions.map((question) => ({
      questionId: question.questionId,
      updates: (digest.updates ?? [])
        .filter((update) => update.questionId === question.questionId)
        .map((update) => update.questionUpdateId),
    })),
  }
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DIGEST_PREVIEW:
      return updateDigestInState(state, action.digestId, { preview: '' })

    case FETCH_DIGEST_SUBJECTS_PREVIEW:
      return updateDigestInState(state, action.digestId, {
        subjectsPreview: [],
      })

    case SET_DIGEST_ORDER: {
      return updateDigestInState(state, action.digestId, {
        oldOrder: _.cloneDeep(state.questions),
        questions: [...action.questions],
      })
    }

    case receiveAction(SET_DIGEST_ORDER): {
      return receiveReducer(
        state,
        action,
        () => {
          if (!action.response || !action.response.success) {
            return updateDigestInState(state, action.digestId, {
              questions: _.cloneDeep(state.oldOrder),
            })
          }
          return state
        },
        () =>
          updateDigestInState(state, action.digestId, {
            questions: _.cloneDeep(state.oldOrder),
          })
      )
    }

    case receiveAction(SET_SUBJECT): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.success) {
          return updateDigestInState(state, action.digestId, {
            subject: action.subject,
          })
        }

        return state
      })
    }

    case receiveAction(SET_DESCRIPTION): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.success) {
          return updateDigestInState(state, action.digestId, {
            description: action.description,
          })
        }

        return state
      })
    }

    case receiveAction(SET_TEMPLATE_FLAG): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.success) {
          return updateDigestInState(state, action.digestId, {
            in_template: action.inTemplate,
          })
        }

        return state
      })
    }

    case receiveAction(SET_INCLUDE_ASK_EXPERT_FLAG): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.success) {
          return updateDigestInState(state, action.digestId, {
            include_ask_expert: action.includeAskExpert,
          })
        }

        return state
      })
    }

    case receiveAction(SET_UPDATE_PRIMARY_TOPIC): {
      return receiveReducer(state, action, () => {
        const primaryTopics = {
          ...(getDigest(state, action.digestId).primaryTopics ?? {}),
        }

        const questionUpdateIds = _.isArray(action.questionUpdateId)
          ? action.questionUpdateId
          : [action.questionUpdateId]

        questionUpdateIds.reduce((obj, questionUpdateId) => {
          obj[questionUpdateId] = action.topicId
          return obj
        }, primaryTopics)

        return updateDigestInState(state, action.digestId, {
          primaryTopics,
        })
      })
    }

    case receiveAction(FETCH_AVAILABLE_SPECIALTIES): {
      return receiveReducer(state, action, () => ({
        availableSpecialties: action.response.availableSpecialties,
      }))
    }

    case receiveAction(FETCH_DIGEST_PREVIEW): {
      return receiveReducer(state, action, () =>
        updateDigestInState(state, action.digestId, {
          preview: action.response.html,
        })
      )
    }

    case receiveAction(FETCH_DIGEST_SUBJECTS_PREVIEW): {
      return receiveReducer(state, action, () =>
        updateDigestInState(state, action.digestId, {
          subjectsPreview: action.response[action.specialtyId],
        })
      )
    }

    case receiveAction(FETCH_UPDATES): {
      return receiveReducer(state, action, () =>
        updateDigestInState(state, action.digestId, {
          updates: action.response.updates,
        })
      )
    }

    case receiveAction(ADD_DIGEST_UPDATE):
    case receiveAction(DELETE_DIGEST_UPDATE): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.digest) {
          return {
            digests: {
              ...(state.digests ?? {}),
              [action.response.digest.specialty_id]: _.uniqBy(
                [
                  getTrimmedDigest(action.response.digest),
                  ...(state.digests[action.specialtyId] ?? []),
                ],
                'digest_id'
              ),
            },
          }
        }
        return {
          digestId: null,
          digests: {},
          updates: [],
          questions: [],
        }
      })
    }
    case receiveAction(MERGE_TODAY_DIGEST_COHORTS):
    case receiveAction(SPLIT_DIGEST_INTO_TWO_COHORTS):
    case receiveAction(CREATE_DIGEST):
    case receiveAction(FETCH_TODAY_DIGEST): {
      return receiveReducer(state, action, () => {
        if (action.response && action.response.digests?.length) {
          const firstDigest = action.response.digests[0]

          return {
            digests: {
              ...(state.digests ?? {}),
              [firstDigest.specialty_id]: action.response.digests.map(
                (digest) => getTrimmedDigest(digest)
              ),
            },
          }
        }
        return {
          digests: {},
          updates: [],
          questions: [],
        }
      })
    }

    case requestAction(DELETE_DIGEST): {
      return updateDigestInState(state, action.digestId, { updates: false })
    }

    case requestAction(SUSPEND_DIGEST): {
      return updateDigestInState(state, action.digestId, {
        status: digestStatus.STATUS_SUSPENDED,
      })
    }

    case receiveAction(RESUME_DIGEST): {
      return receiveReducer(state, action, () => {
        return updateDigestInState(state, action.digestId, {
          status: digestStatus.STATUS_SCHEDULED,
          summary: {
            notifications: action.response.data,
          },
        })
      })
    }

    default:
      return state
  }
}
