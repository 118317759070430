import React from 'react'
import Autosuggest from 'react-autosuggest'
import _ from 'lodash'

import { makeURL } from 'mednet-util/src/router'
import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { Subtitle2, Subtitle4 } from 'pharmacy/src/typography'
import { formatName } from 'mednet-util/src/string'
import { mixpanel } from 'mednet-util/src/tracking'

import { mednetAPI, makePOSTHeaders } from 'mednet-cns/src/api/v1'

import { nodeApiSearchItems } from 'mednet-util/src/search'

import { GatedAutoCompleteAskQuestionButton } from './headerAutoCompleteAskQuestionButton'
import AutoCompleteSeeAllResultsButton from './headerAutoCompleteSeeAllResultsButton'

import css from './headerAutoComplete.scss'

const askQuestionSuggestionPlaceholder = 'ask_question_placeholder'
const seeAllResultsPlaceholder = 'see_all_results_placeholder'

class HeaderAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.onEnterOrIconPress = this.onEnterOrIconPress.bind(this)
  }

  state = {
    value: '',
    suggestions: [],
    typingTimer: undefined,
  }

  onChange = (event, { newValue }) => {
    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer)
    }

    // Track when stopped typing for 2 sec
    const typingTimer = setTimeout(() => {
      const { suggestions } = this.state
      const questionsSuggestionsCount =
        suggestions.find((suggestion) => suggestion.type === 'question')
          ?.suggestions?.length || 0
      const usersSuggestionsCount =
        suggestions.find((suggestion) => suggestion.type.endsWith('user'))
          ?.suggestions?.length || 0
      const topicsSuggestionsCount =
        suggestions.find((suggestion) => suggestion.type.endsWith('topic'))
          ?.suggestions?.length || 0

      mixpanel.track('entered_search_query_web', {
        search_query: this.state.value,
        result_count:
          questionsSuggestionsCount +
          usersSuggestionsCount +
          topicsSuggestionsCount,
        user_result_count: usersSuggestionsCount,
        topics_result_count: topicsSuggestionsCount,
        question_result_count: questionsSuggestionsCount,
      })
    }, 2000)

    this.setState({
      value: newValue,
      typingTimer,
    })
  }

  onFocus = (event) => {
    // matches mediaMedium in constants.scss
    if (window.innerWidth < 1050) {
      event.target.scrollIntoView(true, { behavior: 'smooth' })
    }
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    if (_.isEmpty(value)) {
      this.setState({
        suggestions: [],
      })
      return
    }

    const searchQuestions = async () => {
      const data = await nodeApiSearchItems('questions', {
        query: value,
        limit: 5,
        statuses: [1],
        searchPlatform: 'ALGOLIA',
        trackSearchQuery: false,
      })

      return data.items
    }

    const searchUsers = async () => {
      const data = await nodeApiSearchItems('users', {
        query: value,
        limit: 5,
        searchPlatform: 'ALGOLIA',
        trackSearchQuery: false,
        subresources: [
          'profile',
          'profile.specialty',
          'primaryJob.organization',
        ],
      })

      return data.items
    }

    const searchTopics = async () => {
      const data = await nodeApiSearchItems('topics', {
        query: value,
        limit: 5,
        searchPlatform: 'ALGOLIA',
        trackSearchQuery: false,
      })
      return data.items
    }

    const data = await Promise.all([
      searchQuestions(),
      searchUsers(),
      searchTopics(),
    ])

    this.setState({
      suggestions: [
        {
          type: 'question',
          suggestions: data[0],
        },
        {
          type: 'user',
          suggestions: data[1],
        },
        {
          type: 'topic',
          suggestions: data[2],
        },
      ],
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({ value: '' })
    this.setState({
      suggestions: [],
    })
  }

  getSuggestionValue(suggestion) {
    if (suggestion.questionId) {
      return suggestion.question
    } else if (suggestion.profile) {
      const { firstName, middleName, lastName } = suggestion.profile
      return formatName(firstName, middleName, lastName)
    } else if (suggestion.topicId) {
      return suggestion.topic
    }

    return null
  }

  onSuggestionClick = (suggestion) => (e) => {
    // Added this for mobile, without this the event propagates to the window and when clicking a reult in mobile,
    // the search results just disappear instead of redirecting to the clicked result
    e.stopPropagation()
    const formData = new FormData()
    formData.append('term', this.state.value)

    if (suggestion.questionId) {
      formData.append('result_type', 'question')
      formData.append('result_id', suggestion.questionId)
    } else if (suggestion.topicId) {
      formData.append('result_type', 'topic')
      formData.append('result_id', suggestion.topicId)
    } else if (suggestion.profile?.userId) {
      formData.append('result_type', 'user')
      formData.append('result_id', suggestion.profile.userId)
    }

    mednetAPI('search/registerSearch', makePOSTHeaders(formData))
  }

  getSuggestionID(suggestion) {
    return (
      suggestion.questionId || suggestion.topicId || suggestion.profile?.userId
    )
  }

  getSuggestionURL(suggestion, params = null) {
    let object = 'question'
    const objectId = this.getSuggestionID(suggestion)

    if (suggestion.topicId) {
      object = 'topic'
    } else if (suggestion.profile?.userId) {
      object = 'user/user/view/id'
    }
    const suggestionURL = `/${object}/${objectId}`

    return makeURL(suggestionURL, params)
  }

  renderSuggestion = (suggestion) => {
    if (suggestion === askQuestionSuggestionPlaceholder) {
      return <GatedAutoCompleteAskQuestionButton />
    } else if (suggestion === seeAllResultsPlaceholder) {
      return (
        <AutoCompleteSeeAllResultsButton onClick={this.onEnterOrIconPress} />
      )
    }

    let suggestionContent
    let suggestionPosition
    let suggestionSubcontent = ''
    const { suggestions } = this.state

    if (suggestion.questionId) {
      suggestionContent = suggestion.question
      const questionSuggestions = suggestions.find(
        (item) => item.type === 'question'
      )?.suggestions

      suggestionPosition =
        1 +
        questionSuggestions?.findIndex(
          (item) => item.questionId === suggestion.questionId
        )
    } else if (suggestion.profile) {
      const { firstName, middleName, lastName } = suggestion.profile ?? {}
      suggestionContent = formatName(firstName, middleName, lastName, '', {
        useMiddleInitial: false,
      })

      suggestionSubcontent = (
        <div>
          <div>{suggestion.profile?.specialty?.specialty}</div>
          <div>{suggestion.primaryJob?.organization?.name}</div>
        </div>
      )
    } else if (suggestion.topicId) {
      suggestionContent = suggestion.topic
    }

    return (
      <Link
        className={css.suggestionLink}
        external
        pathname={this.getSuggestionURL(suggestion, {
          src: 'popover-search',
          query: this.state.value,
          pos: suggestionPosition,
        })}
        onClick={this.onSuggestionClick(suggestion)}
      >
        <div className={css.suggestionLinkContent}>
          <Subtitle2>
            <div>{suggestionContent}</div>
          </Subtitle2>
          <Subtitle4 className={css.suggestionSubcontent}>
            {suggestionSubcontent}
          </Subtitle4>
        </div>
      </Link>
    )
  }

  renderSuggestionsContainer({ containerProps, children, query }) {
    // we need to do this because of https://github.com/moroshko/react-autosuggest/issues/570
    const filteredChildren =
      children &&
      children.filter((child) => {
        // eslint-disable-next-line no-unused-vars
        const [title, items] = child.props.children
        return items.props.items.length
      })
    return (
      <div {...containerProps}>
        {query && query.length && <div>{filteredChildren}</div>}
      </div>
    )
  }

  renderSectionTitle(section) {
    const sectionTitle = section.type ? `${section.type}s` : null
    if (!section.suggestions?.length && sectionTitle !== 'questions') {
      return null
    }

    return sectionTitle
  }

  getSectionSuggestions(section) {
    if (section.type && section.type === 'question') {
      return [
        ...(section.suggestions ?? []),
        askQuestionSuggestionPlaceholder,
        seeAllResultsPlaceholder,
      ]
    }

    return section.suggestions ?? []
  }

  onKeyUp(event) {
    if (
      event.keyCode === 13 &&
      event.target.value &&
      event.target.value.trim().length
    ) {
      window.location.assign(
        `/search/siteSearch?query=${encodeURIComponent(
          event.target.value
        )}&src=popover-search`
      )
    }
  }

  onEnterOrIconPress(e) {
    e.preventDefault()

    window.location.assign(
      `/search/siteSearch?query=${encodeURIComponent(
        this.state.value
      )}&src=popover-search`
    )
  }

  renderInputComponent = (inputProps) => {
    return (
      <div className={css.inputContainer}>
        <input {...inputProps} onKeyUp={this.onKeyUp} />
        <div onPointerDown={this.onEnterOrIconPress}>
          <Icon className={css.inputIcon} icon={['far', 'search']} />
        </div>
      </div>
    )
  }

  render() {
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: 'Search',
      onChange: this.onChange,
      onFocus: this.onFocus,
      value,
    }

    const theme = {
      sectionContainer: css.sectionContainer,
      sectionContainerFirst: css.sectionContainerFirst,
      sectionTitle: css.sectionTitle,
      suggestion: css.suggestion,
      suggestionsContainer: css.suggestionsContainer,
      suggestionsList: css.suggestionsList,
      input: css.input,
    }

    return (
      <Autosuggest
        getSectionSuggestions={this.getSectionSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        multiSection
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderInputComponent={this.renderInputComponent}
        renderSectionTitle={this.renderSectionTitle}
        renderSuggestion={this.renderSuggestion}
        suggestions={suggestions}
        theme={theme}
      />
    )
  }
}

export default HeaderAutoComplete
