import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Header3, Subtitle3, Subtitle2, Link1 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'

import { RawBody } from 'pharmacy/src/display/rawBody'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'
import { authItems } from 'mednet-util/src/constants/permission'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'

import AnswerFeedback from './answerFeedback'
import AnswerActions from './answerActions'
import AnswerComments from './answerComments'

import css from './answerInformation.scss'

class AnswerInformation extends React.Component {
  constructor(props) {
    super(props)

    this.commentInput = React.createRef()
  }

  renderEditButton = () => {
    const { answerId, userId, currentUserId, enableEdit, permissions } =
      this.props

    if (enableEdit === false) {
      return null
    }

    const isAuthor = currentUserId === userId
    const isModerator =
      permissions && _.includes(permissions.items, authItems.updateAnswer)

    if (isAuthor || isModerator) {
      const editURL = isAuthor
        ? `/answer/authorupdate/${answerId}`
        : `/answer/modupdate/${answerId}`

      return (
        <Link1 className={css.actionLink}>
          <Button
            icon={['far', 'edit']}
            external
            className={css.editButton}
            pathname={editURL}
            type="text"
          >
            Edit
          </Button>
        </Link1>
      )
    }

    return null
  }

  render() {
    const {
      answer,
      answerId,
      bodyProps,
      enableEdit,
      className,
      conflictText,
      created,
      updated,
      userId,
      forwardedRef,
      stubbed,
      commentProps,
      itemIndex,
    } = this.props

    const containerClasses = classNames(css.container, className)
    const updatedProps = updated && {
      secondaryDateUpdated: updated,
      secondaryActionId: actionIdByName.ANSWER_UPDATED,
    }

    return (
      <div className={containerClasses} ref={forwardedRef}>
        <div className={css.content}>
          <div className={css.updateHeader}>
            <UserUpdateInformation
              userId={userId}
              dateUpdated={created}
              actionId={actionIdByName.ANSWER}
              NameComponent={Header3}
              theme="extended"
              highlightIfExpert
              {...updatedProps}
            />
            {this.renderEditButton()}
          </div>
          <div className={css.bodyContainer}>
            <RawBody body={answer} className={css.answerBody} {...bodyProps} />
            {conflictText && (
              <div className={css.conflictContainer}>
                <Subtitle2>Disclosure:</Subtitle2>
                <Subtitle3 className={css.conflictText}>
                  {conflictText}
                </Subtitle3>
              </div>
            )}
            <div className={css.answerFeedbackContainer}>
              <AnswerFeedback
                answerId={answerId}
                feedbackKey="opinions"
                text="agreed with this answer"
                stubbed={stubbed}
              />
              <AnswerFeedback
                answerId={answerId}
                feedbackKey="votes"
                text="found this answer helpful"
                stubbed={stubbed}
              />
            </div>
            <AnswerActions
              answerId={answerId}
              commentInput={this.commentInput}
              stubbed={stubbed}
              itemIndex={itemIndex}
            />
          </div>
        </div>
        <hr className={css.divider} />
        <ErrorBoundary>
          <AnswerComments
            answerId={answerId}
            enableEdit={enableEdit}
            commentProps={commentProps}
            commentInput={this.commentInput}
            stubbed={stubbed}
          />
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userId, answer, created, conflict, updated } =
    state.answer.answers[ownProps.answerId] || {}
  const { data: userData, permissions } = state.user
  const { userId: currentUserId } = userData || {}
  const { forwardedRef } = ownProps

  return {
    answer,
    created,
    currentUserId,
    permissions,
    updated,
    userId,
    forwardedRef,
    conflictText: conflict && conflict.conflictText,
  }
}

export default connect(mapStateToProps)(AnswerInformation)
