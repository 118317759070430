import { createSelector } from 'reselect'

import { createDataHook } from 'mednet-cns/src/hook-factory'

import {
  fetchRecentCampaigns,
  fetchRecentInvitationCampaigns,
  fetchServerScriptExample,
  fetchServerScripts,
  fetchUnsentCampaigns,
  fetchUnsentInvitationCampaigns,
  fetchForcedInviteDomainConfig,
} from '../reducers/campaign'

export const [useRecentCampaigns, useRecentCampaignsLazy] = createDataHook(
  fetchRecentCampaigns,
  createSelector(
    (state) => state.campaign || {},
    (campaign) => {
      return Object.values(campaign?.campaigns)
    }
  )
)

export const [useUnsentCampaigns, useUnsentCampaignsLazy] = createDataHook(
  fetchUnsentCampaigns,
  createSelector(
    (state) => state.campaign || {},
    (campaign) => {
      return Object.values(campaign?.unsentCampaigns)
    }
  )
)

export const [useRecentInvitationCampaigns, useRecentInvitationCampaignsLazy] =
  createDataHook(
    fetchRecentInvitationCampaigns,
    createSelector(
      (state) => state.campaign || {},
      (campaign) => {
        return Object.values(campaign?.invites)
      }
    )
  )

export const [useUnsentInvitationCampaigns, useUnsentInvitationCampaignsLazy] =
  createDataHook(
    fetchUnsentInvitationCampaigns,
    createSelector(
      (state) => state.campaign || {},
      (campaign) => {
        return Object.values(campaign?.unsentInvites)
      }
    )
  )

export const [useServerScripts, useServerScriptsLazy] = createDataHook(
  fetchServerScripts,
  createSelector(
    (state) => state.campaign || {},
    (campaign) => {
      return campaign?.scripts
    }
  )
)

export const [useServerScriptExample, useServerScriptExampleLazy] =
  createDataHook(
    fetchServerScriptExample,
    createSelector(
      (state) => state.campaign || {},
      (_state, { name }) => name,
      (campaign, name) => {
        return campaign?.scriptExamples[name]
      }
    )
  )

export const [useForcedInviteDomainConfig, useForcedInviteDomainConfigLazy] =
  createDataHook(
    fetchForcedInviteDomainConfig,
    createSelector(
      (state) => state.campaign || {},
      (campaign) => {
        return campaign?.forcedInviteDomainConfig ?? {}
      }
    )
  )
