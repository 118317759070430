import { createSelector } from 'reselect'
import { createDataHook } from 'mednet-cns/src/hook-factory'

import {
  fetchExpertOncologyMerged,
  fetchExperts,
  fetchExpertsCount,
} from '../reducers/expert'

export const [useSpecialtiesExperts, useSpecialtiesExpertsLazy] =
  createDataHook(
    fetchExperts,
    createSelector(
      (state) => state.expert.specialtiesExperts,
      (state) => state.expert.expertsCount,
      (specialtiesExpertsIds, expertsCount) => {
        // merged oncology request gets specialty id 'oncology_merged'
        const filteredSpecialtiesExpertsIds = Object.fromEntries(
          Object.entries(specialtiesExpertsIds).filter(
            ([specialtyId, _specialtyExperts]) => !isNaN(specialtyId)
          )
        )

        return {
          expertsCount,
          specialtiesExpertsIds: filteredSpecialtiesExpertsIds,
        }
      }
    )
  )

export const [
  useSpecialtiesExpertsOncologyMerged,
  useSpecialtiesExpertsOncologyMergedLazy,
] = createDataHook(
  fetchExpertOncologyMerged,
  createSelector(
    (state) => state.expert.specialtiesExperts,
    (state) => state.specialty.oncologySpecialtiesIds,
    (state) => state.expert.expertsCount,
    (specialtiesExpertsIds, oncologySpecialtiesIds, expertsCount) => {
      const filteredSpecialtiesExpertsIds = Object.fromEntries(
        Object.entries(specialtiesExpertsIds).filter(
          ([specialtyId, _specialtyExperts]) => {
            return !oncologySpecialtiesIds.includes(parseInt(specialtyId))
          }
        )
      )

      return {
        expertsCount,
        specialtiesExpertsIds: filteredSpecialtiesExpertsIds,
      }
    }
  )
)

export const [useExpertsCount, useExpertsCountLazy] = createDataHook(
  fetchExpertsCount,
  createSelector(
    (state) => state.expert.expertsCount,
    (expertsCount) => expertsCount
  )
)
