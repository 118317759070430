const OPEN_MODAL = 'modal/OPEN_MODAL'
const CLOSE_MODAL = 'modal/CLOSE_MODAL'
const CHANGE_SCREEN = 'modal/CHANGE_SCREEN'

export function openModal(modalId, data = {}, screenName) {
  return {
    type: OPEN_MODAL,
    modalId,
    screenName,
    data,
  }
}

export function changeModalScreen(modalId, screenName) {
  return {
    type: CHANGE_SCREEN,
    modalId,
    screenName,
  }
}

export function closeModal(modalId) {
  return {
    type: CLOSE_MODAL,
    modalId,
  }
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        [action.modalId]: {
          isOpen: true,
          data: action.data,
          screenName: action.screenName,
        },
      }
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        [action.modalId]: {
          isOpen: false,
        },
      }
    }

    case CHANGE_SCREEN: {
      return {
        ...state,
        [action.modalId]: {
          ...state[action.modalId],
          screenName: action.screenName,
        },
      }
    }

    default:
      return state
  }
}
