import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import * as Yup from 'yup'

import { Button } from 'pharmacy/src/input/button'
import { Header3, Subtitle2, Subtitle4 } from 'pharmacy/src/typography'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Icon } from 'pharmacy/src/display/icon'

import {
  usePaperUpdate,
  usePubmedInfoLazy,
  useSponsoredPaperUpload,
} from 'mednet-cns/src/hooks/publication'

import { isNil, omit } from 'lodash'

import css from './paperForm.scss'
import {
  PaperFullDownloadForm,
  fullDownloadValidationSchema,
} from './paperFullDownloadForm'

const validationSchema = Yup.object().shape({
  pubmedId: Yup.string().when(['noPubmedId'], {
    is: (noPubmedId) => !noPubmedId,
    then: Yup.string()
      .matches(/^\d+$/, 'Pubmed ID should be a number')
      .required('If no Pubmed ID exists, please check the option'),
  }),
  noPubmedId: Yup.boolean(),
  ...fullDownloadValidationSchema,
})

const TextInputField = ({ name, errors, setErrors, ...restProps }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  return (
    <div className={css.textInputContainer}>
      <Field name={name}>
        {({ field }) => (
          <TextInput
            {...field}
            {...restProps}
            className={classNames(css.textInput, {
              [css.textInputWithError]: errors[name],
            })}
            showClear={false}
            onChangeHandlesEvent
          />
        )}
      </Field>
      {errors[name] && (
        <Icon
          icon={['fas', 'exclamation-triangle']}
          className={css.errorIcon}
          onClick={() => setShowErrorMessage(!showErrorMessage)}
        />
      )}

      {errors[name] && showErrorMessage && (
        <div className={css.errorMessagePopup}>{errors[name]}</div>
      )}
    </div>
  )
}

const InternalPaperForm = ({
  isSubmitting,
  touched,
  errors,
  values,
  handleSubmit,
  setFieldValue,
  setErrors,
  publication, // if publication is provided then it is in edit mode, and publication has the initial values
}) => {
  const [pubmedInfo, pubmedInfoRequest, loadPubmedInfo] = usePubmedInfoLazy(
    values.pubmedId
  )

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formUpdatedFromPubmed, setFormUpdatedFromPubmed] = useState(
    publication?.pubmedId ? true : false
  )

  const updateFieldsFromPubmedInfo = useCallback(
    (updatedPubmedInfo, excludeVisibleFields) => {
      Object.keys(updatedPubmedInfo).forEach((fieldName) => {
        const visibleFields = ['title', 'journal', 'date']
        const excludedFields = ['pubmedIdExists', 'publicationId']
        if (
          !excludedFields.includes(fieldName) &&
          (!excludeVisibleFields || !visibleFields.includes(fieldName))
        ) {
          setFieldValue(
            fieldName,
            updatedPubmedInfo[fieldName] === null
              ? ''
              : updatedPubmedInfo[fieldName]
          )
        }
      })
    },
    [setFieldValue]
  )

  const getPubmedInfo = useCallback(
    (excludeVisibleFields = false, callback = undefined) => {
      setFormUpdatedFromPubmed(true)

      if (!pubmedInfoRequest.isLoaded) {
        loadPubmedInfo(values.pubmedId, (res) => {
          updateFieldsFromPubmedInfo(res, excludeVisibleFields)
          callback && callback(res)
        })
      } else {
        updateFieldsFromPubmedInfo(pubmedInfo, excludeVisibleFields)
        callback && callback(pubmedInfo)
      }
    },
    [
      pubmedInfo,
      pubmedInfoRequest,
      loadPubmedInfo,
      setFormUpdatedFromPubmed,
      updateFieldsFromPubmedInfo,
      values,
    ]
  )

  const handleUpdateButtonClick = useCallback(() => {
    getPubmedInfo()
  }, [getPubmedInfo])

  const handleSubmitButtonClick = useCallback(
    (...args) => {
      setFormSubmitted(true)
      if (isEmpty(errors) && !formUpdatedFromPubmed && !values.noPubmedId) {
        getPubmedInfo(true, (res) => res.pubmedIdExists && handleSubmit(args))
      } else {
        handleSubmit(args)
      }
    },
    [setFormSubmitted, handleSubmit, pubmedInfoRequest, errors, getPubmedInfo]
  )

  useEffect(() => {
    setFormSubmitted(false)
  }, [values])

  useEffect(() => {
    if (
      formUpdatedFromPubmed &&
      publication?.pubmedId &&
      values.pubmedId === publication?.pubmedId
    ) {
      return
    }

    setFormUpdatedFromPubmed(false)
  }, [values.pubmedId])

  useEffect(() => {
    if (values.noPubmedId) {
      setFieldValue('pubmedId', '')
    }
  }, [values.noPubmedId])

  let pubmedIdServerError = undefined

  if (
    !values.noPubmedId &&
    pubmedInfoRequest.isLoaded &&
    formUpdatedFromPubmed
  ) {
    if (!pubmedInfo.pubmedIdExists) {
      pubmedIdServerError = 'The Pubmed ID does not exist'
    }

    if (
      pubmedInfo.publicationId &&
      publication?.publicationId !== pubmedInfo.publicationId
    ) {
      pubmedIdServerError = `The Pubmed ID is already in the system for another paper.`
    }
  }

  const disableDetailsFields =
    !values.noPubmedId &&
    (!isEmpty(pubmedIdServerError) ||
      pubmedInfoRequest.isLoading ||
      !values.pubmedId ||
      (values.pubmedId && !formUpdatedFromPubmed))

  return (
    <Form className={css.form}>
      <Header3 className={css.uploadTitle}>Upload a new paper</Header3>
      <div className={css.pubmedInputContainer}>
        <div className={classNames(css.formInput, css.pubmedInput)}>
          <div>
            <Subtitle2>PubMed ID</Subtitle2>
            <TextInputField
              name="pubmedId"
              disabled={values.noPubmedId}
              errors={{
                pubmedId:
                  (formSubmitted && errors['pubmedId']) ||
                  (formUpdatedFromPubmed && pubmedIdServerError),
              }}
              setErrors={setErrors}
            />
          </div>
          <Button
            onClick={handleUpdateButtonClick}
            isDisabled={isEmpty(values.pubmedId) || isNaN(values.pubmedId)}
            isLoading={pubmedInfoRequest.isLoading}
            type="secondary"
            className={css.button}
          >
            Update
          </Button>
        </div>
        <Subtitle4 className={css.labelNote}>
          Adding a pubmed ID will pull other data that is not visible in this
          form.
        </Subtitle4>
        <label>
          <Field
            type="checkbox"
            name="noPubmedId"
            checked={values.noPubmedId}
          />
          No Pubmed ID exists
        </label>
      </div>

      <div className={css.formInput}>
        <Subtitle2>Title</Subtitle2>
        <TextInputField
          name="title"
          errors={formSubmitted ? errors : {}}
          setErrors={setErrors}
          disabled={disableDetailsFields}
        />
      </div>
      <div className={css.formInput}>
        <Subtitle2>Journal</Subtitle2>
        <TextInputField
          name="journal"
          errors={formSubmitted ? errors : {}}
          setErrors={setErrors}
          disabled={disableDetailsFields}
        />
      </div>
      <div className={css.formInput}>
        <Subtitle2>Date</Subtitle2>
        <TextInputField
          name="date"
          touched={touched}
          errors={formSubmitted ? errors : {}}
          setErrors={setErrors}
          disabled={disableDetailsFields}
        />
      </div>

      <div className={css.attachmentForm}>
        <PaperFullDownloadForm
          publication={publication || {}}
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>

      <div className={css.footer}>
        {isEmpty(pubmedIdServerError) && !isEmpty(errors) && formSubmitted && (
          <div className={css.errorsMessage}>
            <div>{Object.values(errors)[0]}</div>
          </div>
        )}
        {!isEmpty(pubmedIdServerError) && (
          <div className={css.errorsMessage}>
            <div>{pubmedIdServerError}</div>
          </div>
        )}
        <Button
          onClick={handleSubmitButtonClick}
          isLoading={isSubmitting}
          isDisabled={disableDetailsFields || !isEmpty(pubmedIdServerError)}
          className={css.button}
        >
          {isEmpty(publication) ? 'Upload & Add Paper' : 'Update Paper'}
        </Button>
      </div>
    </Form>
  )
}

const PaperForm = ({ sponsorshipId, closeModal, publication }) => {
  const [submissionResultFailed, setSubmissionResultFailed] = useState(false)
  const uploadSponsoredPaper = useSponsoredPaperUpload()
  const updatePaper = usePaperUpdate()

  const handleFormSubmit = (values, { setSubmitting }) => {
    if (publication) {
      // Edit mode
      updatePaper(values, (res) => {
        setSubmitting(false)
        if (res.success) {
          closeModal()
        } else {
          setSubmissionResultFailed(true)
        }
      })
    } else {
      // New paper mode
      const valuesToSubmit = omit(values, isNil)

      uploadSponsoredPaper(valuesToSubmit, (res) => {
        setSubmitting(false)
        if (res.success) {
          closeModal()
        } else {
          setSubmissionResultFailed(true)
        }
      })
    }
  }

  let initialValues = undefined

  if (publication) {
    const publicationHasFullDownload =
      !isEmpty(publication.fileLocation) ||
      (!isEmpty(publication.publicURL) && publication.isPublicUrlFile)

    initialValues = {
      publicationId: publication.publicationId,
      pubmedId: publication.pubmedId,
      noPubmedId: publication.pubmedId ? false : true,
      title: publication.title,
      journal: publication.journal,
      date: publication.date,
      attachment: undefined,
      openAccess: publication.openAccess,
      abstract: undefined,
      doi: undefined,
      country: undefined,
      keywords: undefined,
      medline_ta: undefined,
      nlm_unique_id: undefined,
      pmc: undefined,
      publication_types: undefined,
      fullDownloadAction: publicationHasFullDownload
        ? 'noChange'
        : 'noFullDownload',
      fileLocation: publication.fileLocation,
      publicURL: publication.publicURL,
      isPublicUrlFile: true, // in this form we are not using public URLs that are not direct download links
    }
  } else {
    initialValues = {
      sponsorshipId,
      pubmedId: undefined,
      noPubmedId: false,
      title: undefined,
      journal: undefined,
      date: undefined,
      attachment: undefined,
      openAccess: false,
      publicURL: undefined,
      abstract: undefined,
      doi: undefined,
      country: undefined,
      keywords: undefined,
      medline_ta: undefined,
      nlm_unique_id: undefined,
      pmc: undefined,
      publication_types: undefined,
      author_text: undefined,
      fullDownloadAction: 'noFullDownload',
      isPublicUrlFile: true, // in this form we are not using public URLs that are not direct download links
    }
  }

  if (submissionResultFailed) {
    return (
      <div className={css.submissionFailureResult}>
        Something went wrong. Please try again
      </div>
    )
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
    >
      {(formProps) => (
        <InternalPaperForm {...formProps} publication={publication} />
      )}
    </Formik>
  )
}

export default PaperForm
