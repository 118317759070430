import { useEffect, useState } from 'react'

const SEARCH_UPDATE_LAST_SHOWN_LOCAL_STORAGE_KEY =
  'question-update-tooltip-last-shown'
const SEARCH_UPDATE_SHOWN_COUNT_LOCAL_STORAGE_KEY =
  'question-update-tooltip-shown-count'
const MAX_TOOLTIP_SHOWN_COUNT = 2
const HOURS_BETWEEN_TOOLTIP = 24

function useShowSearchUpdateTooltip(tooltipId, pathname) {
  const [showTooltip, setShowTooltip] = useState(false)

  const isOnQuestionPage = pathname?.toLowerCase().startsWith('/question/')
  useEffect(() => {
    if (!isOnQuestionPage || typeof window === 'undefined') {
      setShowTooltip(false)
      return () => {}
    }

    const tooltipLastShown = localStorage.getItem(
      SEARCH_UPDATE_LAST_SHOWN_LOCAL_STORAGE_KEY
    )
    const tooltipShownCount = localStorage.getItem(
      SEARCH_UPDATE_SHOWN_COUNT_LOCAL_STORAGE_KEY
    )

    const shownCount = parseInt(tooltipShownCount) || 0
    if (shownCount >= MAX_TOOLTIP_SHOWN_COUNT) {
      setShowTooltip(false)
      return () => {}
    }

    let showTooltip = false
    try {
      if (tooltipLastShown !== null && tooltipLastShown?.trim() !== '') {
        const lastShown = new Date(tooltipLastShown)
        if (!isNaN(lastShown?.getTime())) {
          showTooltip =
            new Date().getTime() - lastShown.getTime() >
            1000 * 60 * 60 * HOURS_BETWEEN_TOOLTIP
        }
      } else {
        showTooltip = true
      }
    } catch {
      showTooltip = true
    }

    if (showTooltip) {
      localStorage.setItem(
        SEARCH_UPDATE_LAST_SHOWN_LOCAL_STORAGE_KEY,
        new Date().toISOString()
      )
      localStorage.setItem(
        SEARCH_UPDATE_SHOWN_COUNT_LOCAL_STORAGE_KEY,
        shownCount + 1
      )
      setShowTooltip(true)
    }

    // Handle close on clicking outside
    const handleClickOutside = (event) => {
      const tooltipElement = document.getElementById(tooltipId)
      if (tooltipElement && !tooltipElement.contains(event.target)) {
        setShowTooltip(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    // Remove event listener when the tooltip is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOnQuestionPage])

  return [showTooltip, setShowTooltip]
}

export default useShowSearchUpdateTooltip
