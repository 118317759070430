import React from 'react'

import { Header1 } from 'pharmacy/src/typography'

import css from './outdatedBrowser.scss'

class OutdatedBrowserPage extends React.Component {
  render() {
    return (
      <div className={css.container}>
        <Header1>Your browser has reached end of life!</Header1>
        <Header1>
          Please update your browser to view this website correctly.
        </Header1>
      </div>
    )
  }
}

export default OutdatedBrowserPage
