import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import _ from 'lodash'
import moment from 'moment'

import {
  fetchInjectionStats as fetchInjectionStatsAction,
  fetchActiveSponsorships as fetchSponsorshipsAction,
  fetchNotificaitonsVolume as fetchNotificaitonsVolumeAction,
  getInjectionStats,
  getIsInjectionStatsLoading,
  getAllSponsorships,
  getAreSponsorshipsLoading,
  getNotificationsVolume,
  getIsNotificationsVolumeLoading,
} from 'mednet-cns/src/reducers/sponsorship'

import { Page } from 'pharmacy/src/display/page'
import { Breadcrumbs } from 'pharmacy/src/navigation/breadcrumbs'
import { Icon } from 'pharmacy/src/display/icon'
import { Link } from 'pharmacy/src/navigation/link'
import { ButtonCheckbox } from 'pharmacy/src/input/button'

import classNames from 'classnames'

import { EXTERNAL_LINKS } from 'constants/links'
import { ROUTES } from 'constants/routes'
import { PAGES } from 'constants/pages'

import InjectedContentStats from './tables/injectedContentStats'

import css from './injectedContentDashboard.scss'
import StatsFilterHeader from './statsFilterHeader'

export default function InjectedContentDashboard() {
  const dispatch = useDispatch()

  const search = useLocation().search
  const initialSelectedSponsorshipId =
    new URLSearchParams(search).get('sponsorshipId') || '0'
  const initialNoOfDays = 3
  const initialDate = new Date()
  initialDate.setDate(initialDate.getDate() - initialNoOfDays + 1)

  const sponsorships = useSelector((state) => getAllSponsorships(state))
  const stats = useSelector((state) => getInjectionStats(state))
  const volume = useSelector((state) => getNotificationsVolume(state))

  const [from, setFrom] = useState(initialDate)
  const [type, setType] = useState('3')
  const [noOfDays, setNoOfDays] = useState(initialNoOfDays)
  const [selectedSponsorshipId, setSponsorshipId] = useState(
    Number(initialSelectedSponsorshipId)
  )
  const [hideEmpty, setHideEmpty] = useState(true)

  const [specialtyId, setSpecialtyId] = useState()
  const [subspecialtiesIds, setSubspecialtiesIds] = useState()
  const [viewedTopicsIds, setViewedTopicsIds] = useState()
  const [followedTopicsIds, setFollowedTopicsIds] = useState()
  const [usersIds, setUsersIds] = useState()

  const handleOnChange = useCallback(
    ({
      date,
      type,
      noOfDays,
      sponsorshipId,
      specialtyId,
      subspecialtiesIds,
      viewedTopicsIds,
      followedTopicsIds,
      usersIds,
    }) => {
      setFrom(date)
      setType(type)
      setNoOfDays(noOfDays)
      setSponsorshipId(Number(sponsorshipId))
      setSpecialtyId(specialtyId ? specialtyId : undefined)
      setSubspecialtiesIds(
        subspecialtiesIds && subspecialtiesIds.length
          ? subspecialtiesIds
          : undefined
      )
      setViewedTopicsIds(
        viewedTopicsIds && viewedTopicsIds.length ? viewedTopicsIds : undefined
      )
      setFollowedTopicsIds(
        followedTopicsIds && followedTopicsIds.length
          ? followedTopicsIds
          : undefined
      )
      setUsersIds(usersIds && usersIds.length ? usersIds : undefined)
    },
    []
  )

  const to = new Date(from)
  to.setDate(to.getDate() + noOfDays - 1)

  const fromFormatted = moment(from).format('YYYY-MM-DD')
  const toFormatted = moment(to).format('YYYY-MM-DD')

  const areInjectionStatsLoading = useSelector((state) =>
    getIsInjectionStatsLoading(state, fromFormatted, toFormatted)
  )
  const areSponsorshipsLoading = useSelector((state) =>
    getAreSponsorshipsLoading(state, fromFormatted, toFormatted)
  )
  const areNotificationVolumeLoading = useSelector((state) =>
    getIsNotificationsVolumeLoading(state, fromFormatted, toFormatted)
  )

  const isLoading = areInjectionStatsLoading || areSponsorshipsLoading
  const isHeaderLoading = areNotificationVolumeLoading

  const handleCheckboxChange = () => setHideEmpty(!hideEmpty)
  const handlePrev = () => {
    const datetime = new Date(from)
    datetime.setDate(datetime.getDate() - 1)
    setFrom(datetime)
  }
  const handleNext = () => {
    const datetime = new Date(from)
    datetime.setDate(datetime.getDate() + 1)
    setFrom(datetime)
  }

  const fetchInjectionStats = useRef(
    _.debounce(
      (from, to, params) =>
        dispatch(fetchInjectionStatsAction(from, to, params)),
      500
    )
  )
  const fetchSponsorships = useRef(
    _.debounce((from, to) => dispatch(fetchSponsorshipsAction(from, to)), 500)
  )
  const fetchNotificationVolume = useRef(
    _.debounce(
      (from, to, params) =>
        dispatch(fetchNotificaitonsVolumeAction(from, to, params)),
      500
    )
  )

  const params = {}

  if (usersIds) {
    params.usersIds = usersIds
  } else if (specialtyId) {
    params.specialtyId = specialtyId
    if (subspecialtiesIds && subspecialtiesIds.length) {
      params.subspecialtiesIds = subspecialtiesIds
    } else if (followedTopicsIds && followedTopicsIds.length) {
      params.followedTopicsIds = followedTopicsIds
    } else if (viewedTopicsIds && viewedTopicsIds.length) {
      params.viewedTopicsIds = viewedTopicsIds
    }
  }

  useEffect(() => {
    fetchSponsorships.current(fromFormatted, toFormatted)
  }, [fromFormatted, toFormatted])
  useEffect(() => {
    fetchInjectionStats.current(fromFormatted, toFormatted, params)
    fetchNotificationVolume.current(fromFormatted, toFormatted, params)
  }, [
    fromFormatted,
    toFormatted,
    specialtyId,
    subspecialtiesIds,
    viewedTopicsIds,
    followedTopicsIds,
    usersIds,
  ])

  const orderedSponsorships = _.sortBy(_.values(sponsorships), 'releaseDate')

  const questionOrderMap = {}
  const emptyData = _.reduce(
    orderedSponsorships,
    (arr, { sponsorshipId, releaseDate, order }) => {
      _.forEach(order, (questionId, i) => {
        questionOrderMap[questionId] = `${i + 1}`
        if (
          selectedSponsorshipId === 0 ||
          selectedSponsorshipId === Number(sponsorshipId)
        ) {
          arr.push({
            sponsorshipId,
            releaseDate,
            order: `${i + 1}`,
            questionId,
          })
        }
      })
      return arr
    },
    []
  )

  const data = _.reduce(
    stats[type],
    (arr, statsType, sponsorshipIdStr) => {
      const sponsorshipId = Number(sponsorshipIdStr)

      if (
        selectedSponsorshipId !== 0 &&
        selectedSponsorshipId !== sponsorshipId
      ) {
        return arr
      }

      const sponsorshipStats = statsType || []

      sponsorshipStats.forEach((row) => {
        const questionId = row.questionId
        const updateId = row.updateId
        const poll = row.poll
        const total = row.total
        const clicks = row.clicks
        const openings = row.openings
        const order = questionOrderMap[questionId]
        const releaseDate =
          sponsorships[sponsorshipId] && sponsorships[sponsorshipId].releaseDate

        const existingRow = _.find(
          arr,
          (item) =>
            item.sponsorshipId === sponsorshipId &&
            item.questionId === questionId &&
            item.updateId === updateId
        )

        if (existingRow) {
          existingRow['dates'][row.date] = {
            clicks,
            openings,
            total,
          }
        } else {
          const newRow = {
            sponsorshipId,
            questionId,
            updateId,
            poll,
            order,
            releaseDate,
            dates: {
              [row.date]: {
                clicks,
                openings,
                total,
              },
            },
          }
          arr.push(newRow)
        }
      })

      return arr
    },
    emptyData
  )

  const filteredData = !hideEmpty
    ? data
    : _.filter(data, (row) => {
        if (!row.dates) {
          return false
        }

        return true
      })

  return (
    <Page className={css.page}>
      <div className={css.contents}>
        <div className={css.breadcrumbs}>
          <Breadcrumbs
            links={[
              EXTERNAL_LINKS.moderatorPage,
              ROUTES[PAGES.SPONSORSHIP_DASHBOARD],
              ROUTES[PAGES.SPONSORSHIP_INJECTED_CONTENT_DASHBOARD],
            ]}
          />
        </div>
        <StatsFilterHeader
          onChange={handleOnChange}
          sponsorships={sponsorships}
          date={from}
          sponsorshipId={selectedSponsorshipId}
          type={type}
          noOfDays={noOfDays}
        />
        <div className={css.controls}>
          <Link
            className={classNames(css.prev, css.controlLink)}
            onClick={handlePrev}
          >
            <Icon icon="chevron-circle-left" />
          </Link>
          <div className={css.filters}>
            <ButtonCheckbox
              selectText="Hide empty"
              unselectText="Hide empty"
              inputProps={{
                checked: hideEmpty,
                onChange: handleCheckboxChange,
              }}
              buttonProps={{
                size: 'small',
                type: 'secondary',
              }}
            />
          </div>
          <Link
            className={classNames(css.next, css.controlLink)}
            onClick={handleNext}
          >
            <Icon icon="chevron-circle-right" />
          </Link>
        </div>
        <InjectedContentStats
          data={filteredData}
          headerData={{ volume }}
          from={from}
          sponsorships={sponsorships}
          notificationType={type}
          isLoading={isLoading}
          isHeaderLoading={isHeaderLoading}
          noOfDays={noOfDays}
          params={params}
        />
      </div>
    </Page>
  )
}
