import React from 'react'
import classNames from 'classnames'

import css from './centeredContent.scss'

class CenteredContent extends React.Component {
  static defaultProps = {
    size: 'medium',
  }

  render() {
    const { children, size, className } = this.props

    const contentClasses = classNames(
      css.content,
      css[`content_${size}`],
      className
    )

    return (
      <div className={css.container}>
        <div className={contentClasses}>{children}</div>
      </div>
    )
  }
}

export default CenteredContent
