export const jobType = {
  RESIDENCY: 'R',
  INTERNSHIP: 'I',
  FELLOWSHIP: 'F',
  SCHOOL: 'S',
  JOB: 'J',
  UNKNOWN: 'U',
  TEMP: 'X',
}

export const jobDescriptions = {
  [jobType.FELLOWSHIP]: 'Fellowships',
  [jobType.RESIDENCY]: 'Residencies',
  [jobType.INTERNSHIP]: 'Internships',
  [jobType.SCHOOL]: 'Medical Schools',
  [jobType.UNKNOWN]: 'Training',
}
