import React from 'react'
import Autosuggest from 'react-autosuggest'
import algoliasearch from 'algoliasearch'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import {
  Configure,
  connectAutoComplete,
  InstantSearch,
} from 'react-instantsearch-dom'

import { Subtitle2 } from 'pharmacy/src/typography'

import { isUserPermittedTo } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './publicationSearch.scss'

class PublicationInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || '',
      suggestionSelected: false,
    }
    props.refine(props.value)
  }

  onChange = (_event, { method, newValue }) => {
    if (method === 'type') {
      this.setState({
        value: newValue,
        suggestionSelected: false,
      })
      this.props.onChange(newValue)
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value)
  }

  getSuggestionValue(hit) {
    return hit.title
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { className, ...restProps } = containerProps
    const classes = [className]

    !children && classes.push(css.suggestionsContainerHidden)

    return (
      <div {...restProps} className={classes.join(' ')}>
        {children}
      </div>
    )
  }

  renderSuggestion = (hit, { isHighlighted }) => {
    const highlightResult = hit._highlightResult
    const hitContent = highlightResult.title.value
    const { moderatorMode, permissions } = this.props

    const url =
      moderatorMode &&
      isUserPermittedTo(permissions, [authItems.manageSponsorship])
        ? `/publication/moderatorDownload/${hit.publication_id}`
        : `/publication/view/${hit.publication_id}`

    return (
      <Subtitle2>
        <div
          className={classNames(css.suggestionContent, {
            [css.highlightedSuggestion]: isHighlighted,
          })}
          dangerouslySetInnerHTML={{
            __html: `${hitContent}<a href="${url}" target="_blank" onclick="event.stopPropagation()"><i class="fas fa-link ${css.link}"/i></a>`,
          }}
        />
      </Subtitle2>
    )
  }

  getSectionSuggestions(section) {
    return section.hits
  }

  renderInputComponent = (inputProps) => {
    return <input type="text" autoComplete="off" {...inputProps} />
  }

  onSuggestionSelected = (event, { suggestionValue, suggestion }) => {
    if (suggestionValue) {
      this.setState({
        value: suggestionValue,
        suggestionSelected: true,
      })

      this.props.onSuggestionSelect(event, suggestion)
    }
  }

  onSuggestionsClearRequested = () => {}

  shouldRenderSuggestions = (value, reason) => {
    return (
      !this.props.hideSuggestions &&
      !this.state.suggestionSelected &&
      !isEmpty(value?.trim()) &&
      reason !== 'escape-pressed'
    )
  }

  render() {
    const { hits, inputProps } = this.props
    const { value } = this.state

    const theme = {
      sectionContainer: css.sectionContainer,
      suggestion: css.suggestion,
      suggestionsContainer: css.suggestionsContainer,
      suggestionsList: css.suggestionsList,
      input: css.input,
    }

    return (
      <>
        <div>
          <Autosuggest
            getSectionSuggestions={this.getSectionSuggestions}
            getSuggestionValue={this.getSuggestionValue}
            inputProps={{
              ...inputProps,
              value,
              onChange: this.onChange,
            }}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            suggestions={hits}
            theme={theme}
            focusInputOnSuggestionClick
            shouldRenderSuggestions={this.shouldRenderSuggestions}
          />
        </div>
      </>
    )
  }
}
const ConnectedPublicationInput = connectAutoComplete(PublicationInput)

export class PublicationSearch extends React.Component {
  searchClient = undefined

  indexName = undefined

  render() {
    const { userData, ...restProps } = this.props

    if (!this.searchClient || !this.indexName) {
      const { algoliaApp, algoliaKey, algoliaNamespace } = userData
      this.searchClient = algoliasearch(algoliaApp, algoliaKey)
      this.indexName = `${algoliaNamespace}_publication`
    }

    return (
      <InstantSearch
        indexName={this.indexName}
        searchClient={this.searchClient}
      >
        <ConnectedPublicationInput {...restProps} />

        <Configure highlightPreTag="<em>" highlightPostTag="</em>" />
        <Configure hitsPerPage={5} />
      </InstantSearch>
    )
  }
}
