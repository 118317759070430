import _ from 'lodash'
import { call, put, spawn, cancelled, select } from 'redux-saga/effects'

import { mednetAPI } from 'mednet-cns/src/api/v1'
import { REQUEST, RECEIVE, CANCEL } from 'mednet-cns/src/reducers/request'

export const requestAction = (type) => {
  return `${type}_REQUEST`
}

export const receiveAction = (type) => {
  return `${type}_RECEIVE`
}

export const cancelAction = (type) => {
  return `${type}_CANCEL`
}

export const makeRequestAction = (action) => {
  return {
    ...action,
    type: requestAction(action.type),
    baseType: action.type,
    fetchType: REQUEST,
  }
}

export const makeReceiveAction = (action, response) => {
  return {
    ...action,
    response,
    type: receiveAction(action.type),
    baseType: action.type,
    fetchType: RECEIVE,
  }
}

export const makeCancelAction = (action) => {
  return {
    ...action,
    type: cancelAction(action.type),
    baseType: action.type,
    fetchType: CANCEL,
  }
}

export function* makeRequestCallReceive(type, url, headers, action) {
  try {
    yield put(makeRequestAction(action))
    const apiResponse = yield call(
      mednetAPI,
      _.isFunction(url) ? url(action) : url,
      _.isFunction(headers) ? headers(action) : headers
    )

    const { response, isError } = apiResponse

    if (!response) {
      // eslint-disable-next-line no-console
      console.warn('No response received')
      return
    }

    const responseJson = isError ? apiResponse : yield call([response, 'json'])
    yield put(makeReceiveAction(action, responseJson))

    if (action.callback && isError !== true) {
      const state = yield select((state) => state)
      yield spawn((response) => {
        action.callback(responseJson, response, state)
      }, response)
    } else if (action.errorCallback && isError) {
      const state = yield select((state) => state)
      yield spawn((response) => {
        action.errorCallback(responseJson, response, state)
      }, response)
    }
  } finally {
    if (yield cancelled()) {
      yield put(makeCancelAction(action))
    }
  }
}

export const makeFetchSaga = (type, url, headers) => {
  return function* generatedFetchSaga(action) {
    yield* makeRequestCallReceive(type, url, headers, action)
  }
}

export const makeFetchEffect = (effect, type, url, headers) => {
  return effect(type, makeFetchSaga(type, url, headers))
}

export const receiveReducer = (
  state,
  action,
  successUpdate,
  errorUpdate = null
) => {
  if (action.response && action.response.isError === true) {
    if (errorUpdate) {
      return {
        ...state,
        ...errorUpdate(),
      }
    }

    return state
  }

  return {
    ...state,
    ...successUpdate(),
  }
}
