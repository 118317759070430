const OPEN_POPUP = 'popup/OPEN_POPUP'
const CLOSE_POPUP = 'popup/CLOSE_POPUP'

export function openPopup(popupId) {
  return {
    type: OPEN_POPUP,
    popupId,
  }
}

export function closePopup(popupId) {
  return {
    type: CLOSE_POPUP,
    popupId,
  }
}

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP: {
      return {
        ...state,
        [action.popupId]: {
          isOpen: true,
        },
      }
    }

    case CLOSE_POPUP: {
      return {
        ...state,
        [action.popupId]: {
          isOpen: false,
        },
      }
    }

    default:
      return state
  }
}
