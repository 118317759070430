import { createSelector } from 'reselect'
import { useDispatch } from 'react-redux'

import { createDataHook } from 'mednet-cns/src/hook-factory'

import {
  fetchCountOfQuestions,
  fetchQuestionAnswers,
  fetchQuestionMetadata,
  toggleFollowQuestion,
} from '../reducers/question'

export const [useQuestionMeta, useQuestionMetaLazy] = createDataHook(
  fetchQuestionMetadata,
  createSelector(
    (state) => state.question?.questions || {},
    (state) => state.poll?.polls || {},
    (_state, { questionId }) => questionId,
    (questions, polls, questionId) => {
      const question = questions[questionId] || {}

      if (question.pollId) {
        question.poll = polls[question.pollId]
      }

      return question
    }
  )
)

export const [useQuestionAnswers, useQuestionAnswersLazy] = createDataHook(
  fetchQuestionAnswers,
  createSelector(
    (state) => state.question?.questions || {},
    (state) => state.answer?.answers || {},
    (state) => state.user?.users || {},
    (_state, { questionId }) => questionId,
    (questions, answers, users, questionId) => {
      const answerIds = questions[questionId]?.answers || []
      return answerIds.map((id) => {
        const answer = answers[id]
        answer.user = users[answer.userId]
        return answer
      })
    }
  )
)

export const useToggleFollowQuestion = () => {
  const dispatch = useDispatch()

  return (questionId) => {
    dispatch(toggleFollowQuestion(questionId))
  }
}

export const [useQuestionsCount, useQuestionsCountLazy] = createDataHook(
  fetchCountOfQuestions,
  createSelector(
    (state) => state.question?.questionsCount,
    (questionsCount) => questionsCount
  )
)
