import React from 'react'

import { Link } from 'pharmacy/src/navigation/link'

import { CME_ACTIVITY_TYPE } from 'mednet-util/src/constants/cme'

export const CmeActivityLink = ({
  activity,
  children,
  position,
  ...restProps
}) => {
  let path = undefined
  let external = false
  let search = {}

  if (activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW) {
    path = `/publication/view/${activity.contentId}`
    external = true
  } else {
    path = `/question/${activity.contentId}`
    search = { src: 'cme_redeem_page', pos: position }
  }

  return (
    <Link
      pathname={path}
      search={search}
      external={external}
      target="_blank"
      {...restProps}
    >
      {children}
    </Link>
  )
}
