import React from 'react'

import { Header2, Subtitle2 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'

import css from './confirmationScreen.scss'

class ConfirmationScreen extends React.Component {
  static defaultProps = {
    closeText: 'Close',
    closeType: 'primary',
    submitText: 'Submit',
  }

  handleSubmit = () => {
    this.props.onSubmit(this.props)
  }

  render() {
    const {
      closeModal,
      closeText,
      closeType,
      header,
      isLoading,
      onSubmit,
      submitText,
      submitType,
      subtitle,
    } = this.props

    if (isLoading) {
      return <StarLoader wait={false} isVerticalMargin={false} />
    }

    return (
      <div className={css.container}>
        <Header2>{header}</Header2>
        <hr />
        {subtitle && <Subtitle2>{subtitle}</Subtitle2>}
        <div className={css.buttonGroup}>
          <Button type={closeType} onClick={closeModal} className={css.button}>
            {closeText}
          </Button>
          {onSubmit && (
            <Button
              type={submitType}
              onClick={this.handleSubmit}
              className={css.submitButton}
            >
              {submitText}
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default ConfirmationScreen
