import { createSelector } from 'reselect'

import { createDataHook } from 'mednet-cns/src/hook-factory'
import { fetchPressReleases } from 'mednet-cns/src/reducers/pressRelease'

export const [usePressReleases, usePressReleasesLazy] = createDataHook(
  fetchPressReleases,
  createSelector(
    (state) => state.pressRelease.pressReleases,
    (pressReleases) => pressReleases || []
  )
)
