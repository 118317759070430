import React from 'react'

import { Button } from 'pharmacy/src/input/button'
import { CenteredContent } from 'pharmacy/src/display/content'

import css from './headerBanner.scss'

class HeaderBanner extends React.Component {
  render() {
    const { children, isOpen, onClose } = this.props

    if (!isOpen) {
      return null
    }

    return (
      <div className={css.container}>
        <div className={css.children}>
          <CenteredContent>{children}</CenteredContent>
        </div>
        <Button
          shape="circle"
          icon={['fal', 'times']}
          type="tertiary"
          size="small"
          onClick={onClose}
        />
      </div>
    )
  }
}

export default HeaderBanner
