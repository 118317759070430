import React from 'react'
import { connect } from 'react-redux'
import { HashLink } from 'react-router-hash-link'

import {
  makeQueryString,
  parseQueryString,
  updateURL,
  getPathnameFromRoute,
} from 'mednet-util/src/router'

const DEFAULT_SCROLL = (el) =>
  el.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })

class Link extends React.Component {
  render() {
    const {
      dispatch,
      existingPathname,
      existingSearch,
      external,
      forwardRef,
      hash,
      params,
      pathname,
      route,
      routeParams,
      search,
      useExistingPathname,
      useExistingSearch,
      ...linkProps
    } = this.props

    const pathnameToUse = useExistingPathname
      ? existingPathname
      : route
      ? getPathnameFromRoute(route, routeParams)
      : pathname
    const searchToUse = useExistingSearch
      ? parseQueryString(existingSearch)
      : search

    if (external) {
      return (
        <a
          href={updateURL(pathnameToUse, params, searchToUse)}
          ref={forwardRef}
          {...linkProps}
        >
          {this.props.children}
        </a>
      )
    }

    return (
      <HashLink
        to={{
          pathname: pathnameToUse,
          search: makeQueryString(params, searchToUse),
          // To scroll to the top of the page set the hash fragment to # (empty) or #top - empty hash will be ommited from url
          hash: hash ? hash : '#',
        }}
        innerRef={forwardRef}
        scroll={hash ? DEFAULT_SCROLL : undefined}
        {...linkProps}
      >
        {this.props.children}
      </HashLink>
    )
  }
}

const mapStateToProps = (state) => {
  const { pathname, search } = state.router.location

  return {
    existingPathname: pathname,
    existingSearch: search,
  }
}

const connectedLink = connect(mapStateToProps)(Link)

export { connectedLink as Link }
