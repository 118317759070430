import SponsorshipDashboard from './dashboard'
import InjectedContentDashboard from './injectedContentDashboard'
import InjectionDashboard from './injectionDashboard'
import ViewSponsorshipPage from './view'
import SponsorshipCampaignsPage from './campaigns'
import SponsorshipEmailsPage from './emails'
import SponsorshipSendEmailsPage from './sendEmail'
import SponsorshipAnswerPage from './answer'

export {
  SponsorshipDashboard,
  InjectedContentDashboard,
  InjectionDashboard,
  ViewSponsorshipPage,
  SponsorshipCampaignsPage,
  SponsorshipEmailsPage,
  SponsorshipSendEmailsPage,
  SponsorshipAnswerPage,
}
